import React, { useEffect, useState } from "react";
import TimePicker from "react-time-picker";
import { useDetectClickOutside } from "react-detect-click-outside";

const RenderTimePicker = ({
  time,
  selectedAmPm,
  onEditmodeHandler,
  index,
  timeList,
  onTimeListHandler,
  selectedtimeSlotHandler,
}) => {
  const [value, onChange] = useState("");
  const [selectAmPm, setSelectAmPm] = useState("");
  const [checkOutSideClicked, setCheckOutSideClicked] = useState(false);
  //   console.log("value", value, selectAmPm);
  const clickedOutSideHandler = () => {
    setCheckOutSideClicked(() => true);
    onEditmodeHandler(false);

    // console.log("Clicled Outside");
  };
  //   console.log("Passedtime", time, value, selectAmPm);

  //   console.log("checkOutSideClicked", checkOutSideClicked);
  useEffect(() => {
    if (checkOutSideClicked) {
      // Usecase of if (value)
      // If we make the time null and click the outside of the timepicker , then we will not update the time
      if (!value) {
        return;
      }
      let updatedTime = `${value} ${selectAmPm}`;
      //   console.log("updatedTime", updatedTime);
      let updatedTimeList = timeList;
      updatedTimeList[index] = updatedTime;
      onTimeListHandler([...updatedTimeList]);
      selectedtimeSlotHandler([...updatedTimeList]);
      setCheckOutSideClicked(false);
    }
  }, [checkOutSideClicked]);

  useEffect(() => {
    setSelectAmPm(selectedAmPm);
    onChange(time);
  }, []);

  const ref = useDetectClickOutside({ onTriggered: clickedOutSideHandler });

  const timeChangeHandler = (selectedTime) => {
    // console.log("selectedTime", selectedTime);
    onChange(selectedTime);
  };

  const selectedAmPmHandler = (selectedAmPm) => {
    setSelectAmPm(selectedAmPm);
  };

  return (
    <div className="customeTimePicker" ref={ref}>
      <TimePicker
        onChange={timeChangeHandler}
        value={value}
        amPmAriaLabel={true}
        clearIcon={null}
        clockIcon={null}
        // format="h:m:s a"
        openClockOnFocus={false}
        hourPlaceholder="hh"
        format={"h:mm"}
        minutePlaceholder="mm"
        // disabled={true}
        // amPmAriaLabel="AM/PM"
      />
      <select
        className="select_dropdown"
        value={selectAmPm}
        onChange={(event) => selectedAmPmHandler(event.target.value)}
        // disabled={true}
      >
        <option value="am"> AM</option>
        <option value="pm"> PM</option>
      </select>
    </div>
  );
};

export default RenderTimePicker;
