import React, { Fragment, useState } from "react";

import Drawer from "devextreme-react/drawer";
import NavigationList from "./NavigationList";
import { Route, Switch } from "react-router-dom";
import "./NavigationList.css";
import DischargeContext from "./Components/DischargeContext";
import SNSQuestions from "./Components/SNSQuestions";
import MedicalInserts from "./Components/MedicalInserts";
import Exercise from "./Components/Exercise";
import Diet from "./Components/Diet";
import MonitoringSymptoms from "./Components/MonitoringSymptoms";
import SnSConversationBuilder from "./Components/ConversationBuilders/SnSConversationBuilder";
import BulkUploader from "./Components/BulkUploader/BulkUploader";
import SnsEntity from "./Components/SnsEntity/SnsEntity";
import ClinicalEntity from "./Components/ClinicalEntity/ClinicalEntity";
import SnstoClinicalEntity from "./Components/SnstoClinicalEntity/SnstoClinicalEntity";
import ClinicalEntitySuggestions from "./Components/ClinicalEntitySuggestions/ClinicalEntitySuggestions";
import DischargeContextRecommendations from "./Components/DischargeContextRecommendations/DischargeContextRecommendations";
import MonitoringQuestions from "./Components/MonitoringQuestions/MonitoringQuestions";
import ActiontoSnsEntity from "./Components/ActiontoSnsEntity/ActiontoSnsEntity";
import TranslateUtterance from "./Components/TranslateUtterance/TranslateUtterance";
import TaskConversationTemplate from "./Components/TaskConversationTemplate/TaskConversationTemplate";
import DischargeAllTasks from "./Components/DischargeAllTasks/DischargeAllTasks";
import MediaMapping from "./Components/MediaMapping/MediaMapping";
import Vitals from './Components/Vitals/Vitals';
import SnsVitalMapping from './Components/SnsVitalMapping/SnsVitalMapping';
import DischargeAlertVitals from './Components/DischargeAlertVitals/DischargeAlertVitals';
import { Button } from "@mui/material";
import "./Home.css";
export const Home = () => {
  const [opened, setOpened] = useState(true);
  const [openedStateMode, setOpenedStateMode] = useState("shrink");
  const [position, setPosition] = useState("left");
  const [revealMode, setRevealMode] = useState("slide");

  const [route, setRoute] = useState("/admin/home/discharge-context");

  let route_to = null;
  if (route === "/admin/home/discharge-context") {
    route_to = (
      <Switch>
        <Route path={route} component={DischargeContext}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/sns-questions") {
    route_to = (
      <Switch>
        <Route path={route} component={SNSQuestions}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/medical-inserts") {
    route_to = (
      <Switch>
        <Route path={route} component={MedicalInserts}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/exercise") {
    route_to = (
      <Switch>
        <Route path={route} component={Exercise}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/diet") {
    route_to = (
      <Switch>
        <Route path={route} component={Diet}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/alerts") {
    route_to = (
      <Switch>
        <Route path={route} component={MonitoringSymptoms}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/snsconversations") {
    route_to = (
      <Switch>
        <Route path={route} component={SnSConversationBuilder}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/bulkUpload") {
    route_to = (
      <Switch>
        <Route path={route} component={BulkUploader}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/sns-entity") {
    route_to = (
      <Switch>
        <Route path={route} component={SnsEntity}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/clinical-entity") {
    route_to = (
      <Switch>
        <Route path={route} component={ClinicalEntity}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/monitoring-questions") {
    route_to = (
      <Switch>
        <Route path={route} component={MonitoringQuestions}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/sns-to-clinical-entity") {
    route_to = (
      <Switch>
        <Route path={route} component={SnstoClinicalEntity}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/clinical-entity-suggestions") {
    route_to = (
      <Switch>
        <Route path={route} component={ClinicalEntitySuggestions}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/discharge-context-recommendations") {
    route_to = (
      <Switch>
        <Route path={route} component={DischargeContextRecommendations}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/action-to-sns-entity") {
    route_to = (
      <Switch>
        <Route path={route} component={ActiontoSnsEntity}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/translate") {
    route_to = (
      <Switch>
        <Route path={route} component={TranslateUtterance}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/taskConversation") {
    route_to = (
      <Switch>
        <Route path={route} component={TaskConversationTemplate}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/dischargeAllTasks") {
    route_to = (
      <Switch>
        <Route path={route} component={DischargeAllTasks}></Route>
        <Route exact path="/" />
      </Switch>
    );
  } else if (route === "/admin/home/mediaMapping") {
    route_to = (
      <Switch>
        <Route path={route} component={MediaMapping}></Route>
        <Route exact path="/" />
      </Switch>
    );
  
  } else if (route === "/admin/home/vitals") {
    route_to = (
      <Switch>
        <Route path={route} component={Vitals}></Route>
        <Route exact path="/" />
      </Switch>
    );
  
  } else if (route === "/admin/home/snsVitalMapping") {
    route_to = (
      <Switch>
        <Route path={route} component={SnsVitalMapping}></Route>
        <Route exact path="/" />
      </Switch>
    );
  
  } else if (route === "/admin/home/dischargeAlertVitals") {
    route_to = (
      <Switch>
        <Route path={route} component={DischargeAlertVitals}></Route>
        <Route exact path="/" />
      </Switch>
    );
  }

  const renderNavigation = () => {
    return <NavigationList setRoute={setRoute}></NavigationList>;
  };

  return (
    <Fragment>
      <div style={{ paddingBottom: "10px", paddingLeft: "10px" }}>
        <Button
          className="layoutNavbar__button"
          variant="contained"
          color="success"
          // startIcon={<AutoFixHighIcon />}
          onClick={() => window.open("https://json-form-builder.web.app/")}
          style={{
            backgroundColor: "#F6F6F6",
            color: "black",
          }}
        >
          JSON File Editor
        </Button>
      </div>
      <Drawer
        opened={opened}
        openedStateMode={openedStateMode}
        position={position}
        revealMode={revealMode}
        render={renderNavigation}
        closeOnOutsideClick={false}
        activeStateEnabled={true}
        className="home__drawer"
      >
        <div className="home__routes">{route_to}</div>
      </Drawer>
    </Fragment>
  );
};
