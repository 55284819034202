import React from "react";
import { useHistory } from "react-router-dom";
import { stringToDateFunc } from "../../../../../HighOrderFunctions/stringToDateFunc";
import { connect } from "react-redux";
import * as actions from "../../../../../Store/actions/index";
import "./AMSNameBody.css";
import { dischargeSinceDate } from "../../../../../HighOrderFunctions/dischargeSinceDate";
import { returnYearOnly,ageModifier } from "../../../../../HighOrderFunctions/ageModifier";
const AMSNameBody = (props) => {
  const history = useHistory();

  const nameClicked = () => {
    props.setQRCodeToStoreFunc(props.qrCode);
    props.setCardNumberToStoreFunc(1);
    props.approveButtonStatusFuncToStore(false);
    props.showNavigationFuncToStore(true);
    history.push("/patientAlerts/DischargeDetails/caseDetails/" + props.qrCode);
  };
  let {
    seconds,
    minutes,
    hours,
    days,
    months,
    years,
  } = props.timeSinceDischarge;
  let timeSincePatientDischarge = dischargeSinceDate(
    seconds,
    minutes,
    hours,
    days,
    months,
    years
  );
  let ageToBeShown = props.age && ageModifier(props.age);
  return (
    <div
      className={`amsNameBody__container 
      `}
    >
      <div className="amsNameBody__patientName" onClick={nameClicked}>
        {props.name}
          
          {ageToBeShown && <span>,{ageToBeShown} 
          </span>}
          {props.gender ? `, ${props.gender.charAt(0)}` : "M"}
        <div className="amsNameBody__patientQRCode">{props.hospitalId}</div>
      </div>
      <div className="amsNameBody__discharge">
        <div className="amsNameBody__dischargeDateDiagnosis">
          {props.dischargeDiagnosis}
        </div>
        <div className="amsNameBody__dischargeDate">
          {stringToDateFunc(props.dischargeDate)}
        </div>
      </div>
      <div className="amsNameBody__status">
        {timeSincePatientDischarge}
        </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
    setCardNumberToStoreFunc: (number) =>
      dispatch(actions.setCurrentCardNumberFunc(number)),
    approveButtonStatusFuncToStore: (status) =>
      dispatch(actions.approveButtonStatusFunc(status)),
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(null, mapDispatchToProps)(AMSNameBody);
