import React, { useState, useEffect } from "react";
import "./MonitoringStatusSidebar.css";
import MSSituationHighlights from "../../../Components/screensComponent/MonitoringStatus/MSSituationHighlights/MSSituationHighlights";

import MonitoringStatusAccordian from './MonitoringStatusSidebarAccordian';

const MonitoringStatusSidebar = () => {
  return (
    <div className="monitoringStatusSidebar__container">
      <div className="monitoringStatusSidebar__situationHighlight">
        {/* Component to show  Alerts raised in highlighted  */}
        <MSSituationHighlights />
      </div>
      <MonitoringStatusAccordian />
      
    </div>
  );
};

export default MonitoringStatusSidebar;
