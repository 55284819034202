import React, { useEffect } from "react";
import ModalCustomButton from "../../Components/Buttons/ModalCustomButton/ModalCustomButton";
import "./ErrorModal.css";
const ErrorModal = (props) => {
  const handleClose = () => {
      props.setOnlineStatus(true)
  };
  useEffect(() => {
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);
  return (
    <div className="error__container">
      <div className="error__modal">
        <div className="error_text">Error message: </div>
        <div className="error_textMsg">
          {/* {props.message}Network Error Occured!! */}
          <div>{props.message}</div>
          <div> Try Again</div>
        </div>
        <ModalCustomButton
        status={false}
        name="Close" clicked={handleClose} />
      </div>
    </div>
  );
};

export default ErrorModal;
