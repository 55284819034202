import React,{useState} from "react";
import { connect,useSelector } from "react-redux";

import "./MSContinue.css";
import NurseUpdatingCard from "../../../../Card/MonitoringStatusCards/NurseUpdatingCard/NurseUpdatingCard";
import { postFetchFunction } from "../../../../../HighOrderFunctions/FetchMethod/postFetchFunction";
import { postSendPatientNotesEndPoint } from "../../../../../HighOrderFunctions/APIs/allPostAPI";
import {useHistory} from 'react-router-dom';
import * as actions from "../../../../../Store/actions/index";

const MSContinue = (props) => {
  console.log("Props",props)
  const history = useHistory();
  const accessToken = useSelector(state=>state.auth.accessToken)
  const [patientStatus, setPatientStatus] = useState("");
  const [noteForPatient, setNoteForPatient] = useState("");
  const [noteForMedicalRecord, setNoteForMedicalRecord] = useState("");
  const [errorOccured,setErrorOccured] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");

  console.log("noteForPatient",noteForPatient)
  const updateContinueProcessDetails = async () => {
    try {

      if(props.Identifier==="DM_PATIENT"){
        let urlEndPoint = `${postSendPatientNotesEndPoint}`;
        let formToBeSend = {
          qr_code: `${props.qrCodeFromStore}`,
          date: new Date().toISOString().split("T")[0],
          source: "PATIENT_DM",
          reason_to_visit_hospital:patientStatus ,
          notes_text: noteForPatient,
          medical_records: noteForMedicalRecord,
          create_task:false,
        };
        await postFetchFunction(urlEndPoint, formToBeSend,accessToken);

      }else if (props.Identifier==="NURSING_NOTE"){

        let urlEndPoint = `${postSendPatientNotesEndPoint}`;
        let formToBeSend = {
          qr_code: `${props.qrCodeFromStore}`,
          date: new Date().toISOString().split("T")[0],
          source: "NURSE_NOTE_TO_PATIENT",
          reason_to_visit_hospital:patientStatus ,
          notes_text: noteForPatient,
          medical_records: noteForMedicalRecord,
          create_task:true,
        };
        await postFetchFunction(urlEndPoint, formToBeSend,accessToken);
      }
      setErrorMessage("")
      setPatientStatus("");
      setNoteForMedicalRecord("");
      setNoteForPatient("");
      props.clicked();
      props.showNavigationFuncToStore(true)
      setErrorOccured(false);
      history.push("/dashboard");


    } catch (err) {
      console.log("Error: ",err)
      setErrorMessage(err.detail)
      setErrorOccured(true);

    }
  };
  return (
    <div className="msContinue__container">
      <NurseUpdatingCard
        // header="Continue Monitoring"
        header={props.header}
        nameHeader1="Patient status"
        placeholder1="Type here..."
        value1={patientStatus}
        setValue1={setPatientStatus}
        nameHeader2="Note for patient"
        placeholder2="Type here"
        value2={noteForPatient}
        setValue2={setNoteForPatient}
        nameHeader3="Note for Medical Record"
        placeholder3="Type here"
        value3={noteForMedicalRecord}
        setValue3={setNoteForMedicalRecord}
        key="3"
        submitFunc={updateContinueProcessDetails}
        clicked={props.clicked}
        errorOccured={errorOccured}
        errorMessage={errorMessage}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MSContinue);
