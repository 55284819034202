import React from "react";
import "./PainChartLegendDot.css";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Fade } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 100,
    backgroundColor: "white",
    color: "black",
  },
}));
const fontFamily = "Calibri, Candara, Segoe, Segoe UI, Optima, Arial";
const PainChartLegendDot = (props) => {
  const longText = (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "bold", fontWeight: 800 }}>{props.date}</div>
      {props.onset && (
        <div>
          onset:{" "}
          <span style={{ fontSize: "bold", fontWeight: 800 }}>
            {props.onset}
          </span>
        </div>
      )}

      {props.site && (
        <div>
          {" "}
          site:{" "}
          <span style={{ fontSize: "bold", fontWeight: 800 }}>
            {props.site}
          </span>
        </div>
      )}
      {props.character && (
        <div>
          {" "}
          character:{" "}
          <span style={{ fontSize: "bold", fontWeight: 800 }}>
            {props.character}
          </span>
        </div>
      )}
    </div>
  );

  const classes = useStyles();
  return (
    <Tooltip
      title={longText}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      classes={{ tooltip: classes.customWidth }}
      placement="top"
    >
      <div>
        <div
          className={`chartDate ${props.severityStatus == "mild" && "mildDate"}
        ${props.severityStatus == "moderate" && "moderateDate"} ${
            props.severityStatus == "severe" && "severeDate"
          }
        `}
          style={{ fontFamily: props.downloadPainChart && fontFamily }}
        >
          {props.severityStatus !== "none" && props.time}
        </div>
        <div
          style={{
            width: props.dotWidth && props.dotWidth,
            height: props.dotHeight && props.dotHeight,
          }}
          className={`painChartLegendDot__container ${
            props.severityStatus == "mild" && "mildDot"
          }
        ${props.severityStatus == "moderate" && "moderateDot"} ${
            props.severityStatus == "severe" && "severeDot"
          }
        `}
        ></div>
      </div>
    </Tooltip>
  );
};

export default PainChartLegendDot;
