import React, { useState, useEffect } from "react";
import "./MSNurseNoteComponent.css";
const MSNurseNoteComponent = (props) => {
  let fontFamily = "Calibri, Candara, Segoe, Segoe UI, Optima, Arial";
  let renderedData = null;
  renderedData = props.data.map((item, index) => {
    return (
      <div
        className="msNurseNoteComponent__timeMessage"
        style={{ fontFamily: props.backgroundColor && fontFamily }}
        key={`${item} - ${index}`}
      >
        <div
          className="msNurseNoteComponent__number"
          style={{ fontFamily: props.backgroundColor && fontFamily }}
        >
          {item.source_detail}
        </div>
        <div
          className="msNurseNoteComponent__time"
          style={{ fontFamily: props.backgroundColor && fontFamily }}
        >
          {" "}
          |{item.time} :{" "}
        </div>
        <div
          className="msNurseNoteComponent__message"
          style={{ fontFamily: props.backgroundColor && fontFamily }}
        >
          {" "}
          {` ${item.message}`}
        </div>
      </div>
    );
  });
  return (
    <div className="msNurseNoteComponent__container">
      <div className="msNurseNoteComponent__dayNumberDay">
        <div
          className="msNurseNoteComponent__dayNumber"
          style={{
            fontSize: props.heading2 && props.heading2,
            background: props.backgroundColor && props.backgroundColor,
            padding: props.backgroundColor && "5px",
            verticalAlign: props.backgroundColor && "center",
            fontFamily: props.backgroundColor && fontFamily,
          }}
        >
          Day {props.dayNumber}{" "}
          <span className="msNurseComponent__day">({props.day})</span>
        </div>
      </div>
      <div className="msNurseNoteComponent__timeAndMessage">{renderedData}</div>
    </div>
  );
};

export default MSNurseNoteComponent;
