import React from 'react';
import './NavbarPatientPanel.css';
const NavbarPatientPanel = () => {
    return (
        <div className="navbarPatientPanel__container">
            Patient Panel
        </div>
    )
}

export default NavbarPatientPanel;