import {
  FETCH_COVIDPATIENTLIST_AGAIN,
  FETCH_ONCALLREGISTRATION_AGAIN,
  SHOW_REGISTRATION_ONCALLREGISTRATION,
  FETCH_NURSELIST_AGAIN
} from "./actionTypes";

export const fetchCovidPatientListAgain = (status) => {
  return {
    type: FETCH_COVIDPATIENTLIST_AGAIN,
    status: status,
  };
};

export const fetchOnCallRegistrationAgain = (status) => {
  return {
    type: FETCH_ONCALLREGISTRATION_AGAIN,
    status: status,
  };
};

export const showRegistrationInOnCallRegistration = (status) => {
  return {
    type: SHOW_REGISTRATION_ONCALLREGISTRATION,
    status: status,
  };
};

export const fetchNurseListOnCallRegistrationAgain = (status) => {
  return {
    type: FETCH_NURSELIST_AGAIN,
    status: status,
  };
};
