import React from "react";
import UploadDischargeScreen from "./UploadDischargeScreen";
import NavbarWardNurseScreen from "../../Components/Navbar/NavbarScreens/NavbarWardNurseScreen/NavbarWardNurseScreen";
import './UploadDischargeWithNavbar.css'
const UploadDischargeWithNavbar = () => {
  return (
    <div className="uploadDischargeWithNavbar__container">
      <div className="uploadDischargeWithNavbar__vEngageNavbarHeader">
        <NavbarWardNurseScreen />
      </div>
      <div className="uploadDischargeWithNavbar__bodyContent">
        <UploadDischargeScreen />
      </div>
      <div className="uploadDischargeWithNavbar__footer"></div>
    </div>
  );
};

export default UploadDischargeWithNavbar;
