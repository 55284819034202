import React, { useState, useEffect } from "react";
import "./MSAlertRaisedList.css";
import { getAlertRaisedLists } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction,useFetch } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { connect } from "react-redux";
import MSAlertRaisedListComponent from "../../../../Components/screensComponent/MonitoringStatus/MSAlertRaisedListComponent/MSAlertRaisedListComponent";
import { dynamicSortDesc } from "../../../../HighOrderFunctions/dynamicSort";
import noAlertRaisedListIcon from "../../../../AllIcons/noAlertRaisedListIcon.png";
const MSAlertRaisedList = (props) => {
  let {response,isLoading} = useFetch( `${getAlertRaisedLists}/${props.qrCodeFromStore}/all`)

  if(Array.isArray(response) && response.length > 0){
    let sortedData = response.sort(dynamicSortDesc("day_number"));
    response = sortedData

  }
 
  let renderedData = null;
  renderedData =Array.isArray(response) &&  response.map((item, index) => {
    return (
      <MSAlertRaisedListComponent
        key={`${item} - ${index}`}
        dayNumber={item["day_number"]}
        data={item["data"]}
        day={item["day"]}
      />
    );
  });
  if (response.length === 0) {
    return (
      <div className="msAlertRaisedList__container">
        <div className="msAlertRaisedList__header">Patient Alerts</div>
        <div className="msAlertRaisedList__noAlertRaised">
          <img src={noAlertRaisedListIcon} alt="no Alert Raised" />
          <div className="msAlertRaisedList__noAlertRaisedText">No alerts  raised so far</div>
        </div>
      </div>
    );
  }
  return (
    <div className="msAlertRaisedList__container">
      <div className="msAlertRaisedList__header">Patient Alerts</div>
      <div className="msAlertRaisedList__body">{renderedData}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};

export default connect(mapStateToProps,null)(MSAlertRaisedList);
