import React from "react";
import { connect } from "react-redux";
import SearchDevExtreme from "./SearchDevExtreme";
function SearchDropdown(props) {



  const updateData = (data) => {
    props.setValue(data);
   
  };
  
  return (
    <div
      className="devextremeDropdown__container"
      style={{ width: props.width }}
      disabled={props.disabled}
    >
  
      <SearchDevExtreme
        placeholder={props.placeholder}
        optionsFetched={props.dataList}
        displayName={props.displayName}
        displayExpr={props.displayExpr}
        valueExpr={props.valueExpr}
        defaultValue={props.value}
        updatedSelectedValue={updateData}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(SearchDropdown);
