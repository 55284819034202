import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import "./CheckBox.scss";
export default function ControlledCheckbox(props) {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    // setChecked(event.target.checked);
    let valueToBeChecked = event.target.checked;
    props.setValue(valueToBeChecked);
  };
  useEffect(() => {
   setChecked(props.value);
  }, [props.value]);

  return (
    <div>
      <div className="checkbox__container">
        <Checkbox
          checked={props.value}
          inputProps={{ "aria-label": "controlled" }}
          name="controlled-radio-buttons-group"
          onChange={handleChange}
          color="success"
          disabled={props.disabled}
        />
       {props.showManagedCareText && <div className="checkbox__text">Managed care patient</div>}
      </div>
    </div>
  );
}
