import React from "react";
import "./NoChat.css";
import noChatImage from "../../../../AllIcons/NoChatImage.png";
const NoChat = (props) => {
  return (
    <div className="noChat__container">
      <div className="noChat__image">
        <img src={noChatImage} alt="Conversation hasn't started yet.!!!" />
      </div>
      <div className="noChat__text">
      No chat transcripts available
      </div>
    </div>
  );
};

export default NoChat;
