import React from "react";
import "./SituationHighlightCard.css";
import ButtonWBorder from "../../../Buttons/ButtonWBorder/ButtonWBorder";
import Loader from "../../../../Loader/LoaderThreeDot";
const SituationHighlightCard = (props) => {
  if (props.loading) {
    return (
      <div className="situationHighlightCard__loader">
        <Loader />
      </div>
    );
  }
  return (
    <div
      className={`situationHighlightCard__container ${
        props.label == "Alert Raised"
          ? "situationHighlightCard__alertRaised"
          : ""
      }`}
    >
      <div className={`situationHighlightCard__alertText `}>
        <div className="situationHighlightCard__headerNResponse">
          <div
            className={`situationHighlightCard__alertTextHeader ${
              props.label == "Alert Raised"
                ? "situationHighlightCard__alertRaisedTextHeader"
                : ""
            }`}
          >
            {props.label && props.label}
          </div>
          <div
            className={`situationHighlightCard__response ${
              props.label == "Alert Raised"
                ? "situationHighlightCard__alertRaisedResponse"
                : ""
            }`}
          >
            <div className="situationHighlightCard__responseHeading">
              Last Response:  
            </div>
            <div className="situationHighlightCard__responseBody">
              {props.lastResponse}
            </div>
          </div>
        </div>
        {props.message ? (
          <div
            className={`situationHighlightCard__alertTextBody ${
              props.label == "Alert Raised"
                ? "situationHighlightCard__alertRaisedTextBody"
                : ""
            }`}
            dangerouslySetInnerHTML={{ __html: `${props.message}` }}
          >
          </div>
        ) : (
          <div className="situationHighlightCard__noAlert"> No Alerts !</div>
        )}
      </div>
    </div>
  );
};

export default SituationHighlightCard;
