import React from "react";
import "./BotResponse.css";
import vengaggeLogo from "../../../../AllIcons/vengageLogo.png";
const BotResponse = (props) => {
  return (
    <div className="botResponse__container">
      <div className="botResponse__image">
        <img src={vengaggeLogo} />
      </div>
      <div className="botResponse__msgContainer">
        <div className="botResponse__TextContainer">
          <div className="botResponse__text">{props.utterance}</div>
        </div>
        <div className="botResponse__dateContainer">{props.time} | {props.dayNumber} | ({props.date}) | {props.status}</div>
      </div>
    </div>
  );
};

export default BotResponse;
