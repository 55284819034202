import React from "react";
import "./CircularIcon.css";
const CircularIconForDate = (props) => {
  return (
    <div
      className={`circularIcon ${props.call ? "callstatus" :""} props.passedClass `}
      style={{
        backgroundColor: props.bgcolor,
        width: props.size,
        height: props.size,
        borderRadius: `${props.size / 2} !important`,
        // border:props.border
      }}
      onClick={props.clicked}
    >
     {props.children}
    </div>
  );
};

export default CircularIconForDate;