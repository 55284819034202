import React, { useState, useEffect, useRef, Fragment } from "react";
import "./MedicationForPatient.css";
import MFPHeader from "./MFPHeader/MFPHeader";
import MFPBodyRender from "./MFPBodyRender/MFPBodyRender";
import ModalCustomButton from "../../../Components/Buttons/ModalCustomButton/ModalCustomButton";
import { getPatientMedicineDetailsEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { connect, useSelector } from "react-redux";
import { getFetchFunction } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import {
    fetchMedicineAgain,
    setMedicationNavlinkApproveStatus,
} from "../../../Store/actions";
import { useAuth0 } from "@auth0/auth0-react";

import { setUpdatedMedicineAfterApproved } from "../../../Store/actions/MonitoringCapsule";
import { dayDifference } from "../../../HighOrderFunctions/shared/dayDifference";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import {
    getAllFrequenciesEndPoint,
    getAllInstructionsEndPoint,
    getAllSymptomsEndPoint,
    createMedicines,
    getLastModifiedDetails,
} from "../../../HighOrderFunctions/APIs/allGetAPI";
import Loader from "../../../Loader/LoaderThreeDot";
import { Prompt } from "react-router";
import { showNotificationWithMessage } from "../../../Store/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import axios from "axios";
var jmespath = require("jmespath");

// objective of this function is to make alternate rows of alternate color
const calculateLengthOfFlattenArray = (arrayList) => {
    let tempArrayForRendering = [];
    let currentAccumulatedLength = 0;
    arrayList.map((item, index) => {
        let itemLength = item["data"].length;
        let tempArray = new Array(itemLength)
            .fill(0)
            .map((itemElement, indexOfItemElement) => {
                return currentAccumulatedLength + indexOfItemElement + 1;
            });
        tempArrayForRendering.push(tempArray);
        currentAccumulatedLength = currentAccumulatedLength + item["data"].length;
    });
    return tempArrayForRendering;
};
// let urlofRenderedPage = null;
// MedicationForPatient Component
const MedicationForPatient = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [urlofRenderedPage, setUrlofRenderedPage] = useState(null);
    const { user } = useAuth0();

    // console.log("renderedPageUrlPath", renderedPageUrlPath);
    const medicationPageEndRef = useRef(null);

    const accessToken = useSelector((state) => state.auth.accessToken);
    const [loading, setLoading] = useState(false);
    const [medicineList, setMedicineList] = useState([]);
    const [fetchSuccessful, setFetchSuccessful] = useState(false);
    const [medicineForPatientArrayCheck, setMedicineForPatientArrayCheck] =
        useState([]);
    const [allMedicinesFilledValid, setAllMedicinesFilledValid] = useState(false);

    // fetching all the frequency on the page render first time using the custom use Hook
    let { response: frequencyDataWithTimeSlot, isLoading: frequencyLoading } =
        useFetch(`${getAllFrequenciesEndPoint}`, false);

    let { response: lastModifiedDetails, isLoading: detailsLoading } = useFetch(
        `${getLastModifiedDetails}/${props.qrCodeFromStore}`,
        false
    );

    // Collecting all the frequency in the list
    const [frequencyData, setFrequencyData] = useState({ frequency: [] });

    const [isMedicineCreated, setIsMedicineCreated] = useState(false);

    // Spliting the Frequency from the frequencyDataWithTimeSlot
    useEffect(() => {
        if (frequencyDataWithTimeSlot) {
            let data = frequencyDataWithTimeSlot.map((item) => item.schedule);
            // console.log("FrequencyData", data);
            setFrequencyData({
                frequency: [...data],
            });
        }
    }, [frequencyDataWithTimeSlot]);

    // fetching the Instruction data on the page render using  custom use Hook
    let { response: instructionData, isLoading: instructionLoading } = useFetch(
        `${getAllInstructionsEndPoint}`,
        false
    );

    // fetching the clinicalEntitiesData on the page render using the custom use hook
    const { response: clinicalEntitiesData, isLoading: symptomsLoading } =
        useFetch(`${getAllSymptomsEndPoint}`, false);

    // state containing list of objects for alternate colored rows
    const [accumulatedNumbersOfElements, setAccumulatedNumbersOfElements] =
        useState([]);

    // fetching list of medicines
    const fetchedDataFunc = async () => {
        setFetchSuccessful(false);
        setLoading(true);
        let fetchedValue = await getFetchFunction(
            `${getPatientMedicineDetailsEndPoint}/${props.qrCodeFromStore}`,
            accessToken
        );
        setLoading(false);
        if (Array.isArray(fetchedValue) == true) {
            let list_of_brand_name = jmespath.search(fetchedValue, "[].brand_name");

            list_of_brand_name = [...new Set(list_of_brand_name)];
            // console.log("list_of_brand_name", list_of_brand_name);
            let new_result = [];
            // adding medicine data of those medicines whose brandname is not empty
            list_of_brand_name.forEach((brand_name) => {
                if (brand_name !== "") {
                    let temp = {
                        brandName: brand_name,
                        genericName: "",
                        duration: "",
                        data: [],
                    };
                    fetchedValue.forEach((medicineData) => {
                        if (medicineData.brand_name === brand_name) {
                            temp.data.push(medicineData);
                            temp["genericName"] = medicineData["generic_name"];
                            temp["duration"] = dayDifference(
                                0,
                                medicineData["day_start"],
                                medicineData["day_end"]
                            );
                            
                        }
                    });
                    new_result.push(temp);
                }
            });

            // handling empty brandName in Medicine
            fetchedValue.forEach((medicineData) => {
                if (medicineData["brand_name"] == "") {
                    let temp = {
                        brandName: medicineData["brand_name"],
                        genericName: medicineData["generic_name"],
                        duration: dayDifference(
                            0,
                            medicineData["day_start"],
                            medicineData["day_end"]
                        ),
                        data: [],
                        is_enabled:medicineData['is_enabled']
                    };
                    temp.data.push(medicineData);
                    new_result.push(temp);
                }
            });

            // console.log("new_result", new_result);
            let tempMedicineForPatientArrayCheck = new Array(new_result.length).fill(
                false
            );
            // updating list of medicines to local state (setMedicineList) and updating array of medicine list
            setMedicineForPatientArrayCheck(tempMedicineForPatientArrayCheck);
            setMedicineList(new_result);

            // updating redux state
            if (new_result.length === 0) {
                props.setMedicationNavlinkApproveStatusFunc(true);
                setAllMedicinesFilledValid(true);
            }
            //calling function for alternate rows having alternate colors
            setAccumulatedNumbersOfElements(
                calculateLengthOfFlattenArray(new_result)
            );
            setFetchSuccessful(true);
        }
    };

    useEffect(() => {
        if (props.qrCodeFromStore !== "") {
            fetchedDataFunc();
        }
        // if (!props.isMonitoringApproved) {
        //   props.setIsMedicationSchedulesPageAfterApprovedFunc(false);
        // }
    }, [props.qrCodeFromStore]);
    useEffect(() => {
        if (props.isMonitoringApproved) {
            props.setUpdatedMedicineAfterApprovedFunc(false);
        }
    }, []);

    // check all medicine -field activated
    const checkAllMedicineFieldsValidated = (index, status) => {
        let temp = medicineForPatientArrayCheck;
        temp[index] = status;
        let tempAllMedicinesApproveCheck = temp.every((item) => {
            return item == true;
        });
        setMedicineForPatientArrayCheck(temp);
        setAllMedicinesFilledValid(tempAllMedicinesApproveCheck);
        // updating redux if all medicines are filled
        if (tempAllMedicinesApproveCheck) {
            props.setMedicationNavlinkApproveStatusFunc(true);
        } else {
            props.setMedicationNavlinkApproveStatusFunc(false);
        }
    };

    // function to add completely new medicine
    const addNewMedicineForPatient = () => {
        let newMedicine = {
            brandName: "",
            genericName: "",
            duration: "",
            data: [
                {
                    brand_name: "",
                    day_end: "",
                    day_start: "",
                    dosage: "",
                    frequency: "",
                    generic_name: "",
                    instruction: { text: "", value: "" },
                    medicine_id: "newMedicine-" + uuidv4(),
                    entity: { symptomName: "", measureType: "" },
                    newMedicine: true,
                    is_enabled:true,
                },
            ],
        };
        let tempMedicineList = medicineList;
        tempMedicineList.push(newMedicine);
        setMedicineList((prev) => [...tempMedicineList]);
        let tempMedicineForPatientArray = medicineForPatientArrayCheck;
        tempMedicineForPatientArray.push(false);
        setMedicineForPatientArrayCheck((prev) => [...tempMedicineForPatientArray]);
        setAccumulatedNumbersOfElements(
            calculateLengthOfFlattenArray(tempMedicineList)
        );

        props.setMedicationNavlinkApproveStatusFunc(false);
    };

    // function to
    const updateAllMedicinesList = (updatedMedicine, index) => {
        console.log()
        let tempMedicinesList = medicineList;
        tempMedicinesList[index] = updatedMedicine;
        setMedicineList((prev) => [...tempMedicinesList]);
        setAccumulatedNumbersOfElements(
            calculateLengthOfFlattenArray(tempMedicinesList)
        );
    };

    let renderedList = [];
    // console.log("MedicationSchedules__medicineList", medicineList);
    if (loading == true) {
        renderedList = (
            <div className="noMedicines">
                <Loader />
            </div>
        );
    } else if (medicineList.length == 0 && fetchSuccessful && !loading) {
        renderedList = (
            <div className="noFollowupActions">No Medicine Recommended</div>
        );
    } else {
        renderedList =
            Array.isArray(medicineList) &&
            !loading &&
            !frequencyLoading &&
            !instructionLoading &&
            !symptomsLoading &&
            medicineList.map((item, index) => {
                return (
                    <MFPBodyRender
                        key={uuidv4()}
                        brandName={item["brandName"]}
                        genericName={item["genericName"]}
                        duration={item["duration"]}
                        data={item["data"]}
                        is_enabled={item['is_enabled']}
                        medicineIndex={index}
                        checkAllMedicineFieldsValidated={checkAllMedicineFieldsValidated}
                        updateAllMedicinesList={updateAllMedicinesList}
                        frequencyData={frequencyData}
                        frequencyDataWithTimeSlot={frequencyDataWithTimeSlot}
                        instructionData={instructionData}
                        clinicalEntitiesData={clinicalEntitiesData}
                        allMedicinesFilledValid={allMedicinesFilledValid}
                        medicineForPatientArrayCheck={medicineForPatientArrayCheck}
                        renderingForAlternateArray={accumulatedNumbersOfElements[index]}
                    />
                );
            });
    }

    //Enabling the edit mode
    const modifyMedicinehandler = () => {
        props.setUpdatedMedicineAfterApprovedFunc(true);
        // setUrlofRenderedPage(window.location.hash);
    };

    // function to get back to Dashboard Screen
    const getBackTodashboardPageFunc = () => {
        history.push("/dashboard");
    };

    const medicineCreateHandler = () => {
        axios
            .get(
                `${window["getConfig"].BACKEND_SERVICE_URL}/${createMedicines}/${props.qrCodeFromStore}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken} `,
                    },
                }
            )
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                if (data.status === "200") {
                    setIsMedicineCreated(true);
                    getBackTodashboardPageFunc();
                    dispatch(
                        showNotificationWithMessage({
                            variant: "success",
                            message: data.message,
                        })
                    );
                } else {
                    dispatch(
                        showNotificationWithMessage({
                            variant: "warning",
                            message: "Failed to create medicine",
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(
                    showNotificationWithMessage({
                        variant: "warning",
                        message: "Failed to create medicine",
                    })
                );
                console.log(error);
            });
    };

    const RenderTheButtons = () => {
        return (
            <Fragment>
                {!props.medicineUpdateAfterApproved ? (
                    <div>
                        <ModalCustomButton
                            title="Modify the medicines"
                            status={true}
                            name="Modify medicines"
                            clicked={modifyMedicinehandler}
                            customStyle={{ marginBottom: "5px" }}
                        // disabled={!allMedicinesFilledValid || props.isMonitoringApproved}
                        // passedClass={`${
                        //   props.isMonitoringApproved && "button__disabledDisplayNone"
                        // }`}
                        />
                        {lastModifiedDetails.result && (
                            <div>
                                {" "}
                                <p>Last Modified:</p>
                                <p>On:{lastModifiedDetails.result} </p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="MedicationForPatient__buttonGroup">
                        <ModalCustomButton
                            title="You can add new Medicine only when all fields are completed"
                            status={true}
                            name="Add New Drug"
                            clicked={addNewMedicineForPatient}
                        // disabled={!allMedicinesFilledValid}
                        // passedClass={`${
                        //   props.isMonitoringApproved && "button__disabledDisplayNone"
                        // }`}
                        />
                        <ModalCustomButton
                            title="Final Confirmation to update the medicine"
                            status={true}
                            name="Update Medicines"
                            clicked={medicineCreateHandler}
                            customStyle={{ marginLeft: "10px" }}
                        // color="green !important"
                        // disabled={!allMedicinesFilledValid || props.isMonitoringApproved}
                        // passedClass={`${
                        //   props.isMonitoringApproved && "button__disabledDisplayNone"
                        // }`}
                        />
                    </div>
                )}
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Prompt
                when={
                    props.isMonitoringApproved &&
                    props.medicineUpdateAfterApproved &&
                    !isMedicineCreated
                }
                message="You have not created reminders for the medicines.Are you sure you want to leave?"
            />
            <div className="medicationForPatient__container">
                <div className="medicationForPatient__header">
                    <MFPHeader />
                </div>
                <div
                    className="medicationForPatient__body"
                    style={{
                        height:
                            props.isMonitoringApproved &&
                            !props.medicineUpdateAfterApproved &&
                            lastModifiedDetails.result &&
                            "calc(100vh - 460px)",
                    }}
                >
                    {renderedList}
                </div>
                <div ref={medicationPageEndRef} />
                <div className="medicationForPatient__bottom">
                    {props.isMonitoringApproved ? (
                        <RenderTheButtons />
                    ) : (
                        <ModalCustomButton
                            title="You can add new Medicine only when all fields are completed"
                            status={true}
                            name="Add New Drug"
                            clicked={addNewMedicineForPatient}
                            disabled={!allMedicinesFilledValid || props.isMonitoringApproved}
                            passedClass={`${props.isMonitoringApproved && "button__disabledDisplayNone"
                                }`}
                        />
                    )}
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        qrCodeFromStore: state.patient.QRCode,
        fetchMedicine: state.patient.fetchMedicine,
        isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,
        medicineUpdateAfterApproved:
            state.monitoringCapsule.medicineUpdateAfterApproved,

        shallCreateReminder: state.monitoringCapsule.shallCreateReminder,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMedicineAgain: (status) => dispatch(fetchMedicineAgain(status)),
        setMedicationNavlinkApproveStatusFunc: (status) =>
            dispatch(setMedicationNavlinkApproveStatus(status)),
        setUpdatedMedicineAfterApprovedFunc: (status) =>
            dispatch(setUpdatedMedicineAfterApproved(status)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MedicationForPatient);
