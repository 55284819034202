import React, { useState, useEffect, useRef } from "react";
import "devextreme/data/odata/store";
import axios from "axios";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  RequiredRule,
  FormItem,
  MasterDetail,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { measureTypes, symptomAttributes } from "../data";
import AttributesTagBoxComponent from "./AttributesTagBoxComponent";
import RemedialSuggestionComponent from "./RemedialSuggestionComponent";
import AttributesConversationTemplate from "./AttributesConversationTemplate";
import { useFetch } from "../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { getAllLanguagesEndPoint } from "../../HighOrderFunctions/APIs/allGetAPI";


// This component is used for CRUD in SNS Questions ( combination of sns-entity,language and bot_utterances )
function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};

  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }

  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}

const SNSQuestions = () => {
  const snsQuestionRef = useRef();
  const [searchText, setSearchText] = useState("");

  const [allSNSSymptoms, setAllSNSSymptoms] = useState([]);
  let { response: languageList, isLoading } = useFetch(
    `${getAllLanguagesEndPoint}`,
    false
  );
  const fetchAll = async () => {
    axios
      .all([
        axios.get(`${window["getConfig"].BACKEND_SERVICE_URL}/sns-entity/all`),
      ])
      .then((responseArray) => {
        setAllSNSSymptoms(responseArray[0].data);
      });
  };
  const store = new CustomStore({
    key: "symptom_question_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/sns-questions?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())

        .then((data) => {
          return {
            data: data.data,
            totalCount: data.totalCount,
            // summary: data.summary,
            // groupCount: data.groupCount,
          };
        })
        .catch((error) => {
          // alert(error);
          throw "Data Loading Error";
        });
    },
    insert: (values) => {
      console.log("sns-question-Insert", values);
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/sns-questions`,
        "POST",
        values
      );
    },
    update: (key, values) => {
      console.log("sns-question-update", values);
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/sns-questions/${key}`,
        "PUT",
        values
      );
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/sns-questions/${key}`,
        "DELETE"
      ),
  });
  useEffect(() => {
    fetchAll();
  }, []);

  const cellTemplate = (container, options) => {
    var noBreakSpace = "\u00A0",
      text = (options.value || [])
        .map((element) => {
          return options.column.lookup.calculateCellValue(
            element.toLowerCase()
          );
        })
        .join(", ");

    container.textContent = text || noBreakSpace;
    container.title = text;
  };

  const cellTemplate2 = (container, options) => {
    var noBreakSpace = "\u00A0",
      text = (options.value || []).join("\n");
    // .map((element) => {
    //   return options.column.lookup.calculateCellValue(element);
    // })
    // .join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  };
  // const linkToSnSConversationBuilder=(container, options)=>{
  //     // return <a href="#">${options.value}</a>
  //     return options.value;
  //     // return $("<a>", { "href": options.value, "target": "_blank" }).text('Link');
  // }

  // const gotoConversationBuilder = (url) =>{
  //   alert(url)
  // }
  // const snsConversationCellRender = (cellData)=>{
  //   let url = `/admin/snsconversation/${cellData.value}`
  //   return <div><a href="javascript:void(0)" onClick={gotoConversationBuilder(url)}>{cellData.value}</a></div>
  // }

  const onOptionChanged = (e) => {
    console.log("EVent on option changed: ", e);
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  if (allSNSSymptoms.length === 0) return null;
  return (
    <DataGrid
      ref={snsQuestionRef}
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnResizing={true}
      allowColumnReordering={false}
      onOptionChanged={onOptionChanged}

      // sorting={false}
    >
      <SearchPanel visible={true} text={searchText} />

      <Editing
        refreshMode="reshape"
        mode="popup"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />
      <Column dataField="t_ref_sns_entity_id" dataType="string">
        <Lookup
          dataSource={allSNSSymptoms}
          displayExpr="sns_entity_name"
          valueExpr="t_ref_sns_entity_id"
        />
        <RequiredRule />

        <FormItem colSpan={2}></FormItem>
      </Column>
      <Column dataField="measure_type" dataType="string">
        <Lookup
          dataSource={measureTypes}
          displayExpr="name"
          valueExpr="name"
        />
        <RequiredRule />

        <FormItem colSpan={2}></FormItem>
      </Column>

      <Column
        dataField="attributes"
        dataType="string"
        editCellComponent={AttributesTagBoxComponent}
        cellTemplate={cellTemplate}
      >
        <Lookup
          dataSource={symptomAttributes}
          displayExpr="name"
          valueExpr="name"
        />
        <FormItem colSpan={2}></FormItem>
        <RequiredRule />
      </Column>
      <Column dataField="ref_language_id" caption="Language" dataType="string">
        <Lookup
          dataSource={languageList}
          displayExpr="language_name"
          valueExpr="id"
        />
        <RequiredRule />

        <FormItem colSpan={2}></FormItem>
      </Column>
      {/* <Column
        dataField="remedial_suggestions"
        dataType="string"
        editCellComponent={RemedialSuggestionComponent}
      >
        <FormItem colSpan={2}></FormItem>
      </Column> */}
      <MasterDetail enabled={true} component={AttributesConversationTemplate} />
      <Paging defaultPageSize={15} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[10, 15, 20]} />
    </DataGrid>
  );
};

export default SNSQuestions;
