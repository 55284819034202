import React, { useEffect, useRef } from "react";
import "./PainChartBody.css";
import PainChartLegendDot from "../PainChartLegendDot/PainChartLegendDot";
import { connect } from "react-redux";
import * as actions from "../../../../../../../Store/actions/MonitoringStatusAction";
const PainChartBody = (props) => {
  const messagesEndRef = useRef(null);

  const scrollToRight = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  let capsules = null;

  capsules =
    props.takenTimes &&
    props.takenTimes.map((item, index) => {
      return (
        <div className="capsuleDate" key={index}>
          <div className="painChartBody__capsule">
            {item.severity.map((severityStatus, indexOfStatus) => {
              return (
                <div
                  className="painChartBody__severityPosition"
                  key={`${item.time}-${indexOfStatus}`}
                >
                  <PainChartLegendDot
                    severityStatus={severityStatus}
                    time={item.time}
                    date={item.date}
                    site={item.site}
                    onset={item.onset}
                    character={item.character}
                    dotWidth={props.dotWidth}
                    dotHeight={props.dotHeight}
                    downloadPainChart={props.downloadPainChart}
                  />
                </div>
              );
            })}
          </div>
          <div className="painChartBody__dayAxis">
            <div className="painChartBody__borderLine"></div>
            <div
              className={
                props.downloadPainChart
                  ? `painChartBody__dayNumber__download painChartBody__dayNumber`
                  : "painChartBody__dayNumber"
              }
            >
              {index + 1 == Math.round(props.takenTimes.length / 2) &&
                `D${props.day_number}`}
            </div>
            <div
              className={
                props.downloadPainChart
                  ? `painChartBody__weekDay__download painChartBody__weekDay`
                  : "painChartBody__weekDay"
              }
            >
              {index + 1 == Math.round(props.takenTimes.length / 2) &&
                `${props.dayName}`}
            </div>
          </div>
        </div>
      );
    });
  useEffect(() => {
    scrollToRight();
    if (props.identifier === "vitals_charts") {
      props.collectRenderedVitalsCharts(props.noOfVitalsChartRendered + 1);
    }
    if (props.identifier === "symptoms_charts") {
      props.collectRenderedSymptomsCharts(props.noOfSymptomsChartRendered + 1);
    }
  }, []);
  return (
    <div className="painChartBody__container">
      <div className="painChartBody__capsules">{capsules}</div>
      <div ref={messagesEndRef} />
      {/* <div className="painChartBody__dayAxis">
        <div className="painChartBody__borderLine"></div>
        <div className="painChartBody__dayNumber"> D{props.day_number}</div>
        <div className="painChartBody__weekDay">{props.dayName}</div>
      </div> */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    noOfVitalsChartRendered: state.monitoringStatus.noOfVitalsChart,
    noOfSymptomsChartRendered: state.monitoringStatus.noOfSymptomsChart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    collectRenderedVitalsCharts: (count) =>
      dispatch(actions.getVitalsChartRendered(count)),
    collectRenderedSymptomsCharts: (count) =>
      dispatch(actions.getSymptomsChartRendered(count)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PainChartBody);
