import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getFetchFunction } from "../../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import Chart, {
  CommonSeriesSettings,
  Series,
  ValueAxis,
  Legend,
  Point,
  Tooltip,
  Label,
  Size,
  Title,
  Font,
  ArgumentAxis,
  Connector,
  Grid,
} from "devextreme-react/chart";
import { getFeverChartEndPoint } from "../../../../../../HighOrderFunctions/APIs/allGetAPI";
import {
  getVitalsChartRendered,
  getSymptomsChartRendered,
} from "../../../../../../Store/actions/MonitoringStatusAction";
import * as actions from "../../../../../../Store/actions/MonitoringStatusAction";
import "./RangeChart.css";
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feverDataSets: [],
      height: props.height,
      identifier: props.identifier,
    };
    this.customizeLabel = this.customizeLabel.bind(this);
    this.customizePoint = this.customizePoint.bind(this);
    this.customizeTooltip = this.customizeTooltip.bind(this);
  }
  async getfeverDataSets() {
    const fetchFunc = async () => {
      const response = await getFetchFunction(
        `${getFeverChartEndPoint}/${this.props.qrCodeFromStore}/${this.props.name}`,
        `${this.props.accessToken}`
      );
      return response;
    };
    let result = await fetchFunc();
    let temp = [];
    let idforEachField = 0;
    Array.isArray(result?.daywise_data) &&
      result?.daywise_data.map((item, index) => {
        let dayNumber = item.day_number;
        // let date = item.date;
        let daywiseArray = [];
        item.data.map((itemData) => {
          let valueRequired = itemData?.measure?.split("/");
          daywiseArray.push({
            day: `D${dayNumber}`,
            aVal1: valueRequired ? +valueRequired[0] : "",
            aVal2: valueRequired ? +valueRequired[1] : "",
            date: itemData.date,
            time: itemData.time,

            id: ++idforEachField,
          });
        });
        let earlierArray = [...temp];
        temp = [...earlierArray, ...daywiseArray];
      });
    this.setState({
      ...this.state,
      feverDataSets: temp,
    });
    if (this.state.identifier === "vitals_charts") {
      this.props.collectRenderedVitalsCharts(
        this.props.noOfVitalsChartRendered + 1
      );
    }
    if (this.state.identifier === "symptoms_charts") {
      alert("Hello");
      this.props.collectRenderedSymptomsCharts(
        this.props.noOfSymptomsChartRendered + 1
      );
    }
    return result;
  }
  componentDidMount() {
    this.getfeverDataSets();
  }
  // componentWillUnmount(){
  //   this.setState({})
  // }
  render() {
    console.log("identifier", this.props.noOfVitalsChartRendered);
    return (
      <Fragment>
        {this.state.feverDataSets && (
          <Chart
            className="rangeChart__container"
            id="chart"
            dataSource={this.state.feverDataSets}
            // resolveLabelOverlapping="shift"
          >
            <Size
              height={this.state.height ? this.state.height : 210}
              width={`${this.state.width}`}
            />
            <CommonSeriesSettings
              customizeLabel={this.customizeLabel}
              customizeTooltip={this.customizeTooltip}
              argumentField="id"
              customizePoint={this.customizePoint}
            >
              <Point hoverMode="allArgumentPoints" />
            </CommonSeriesSettings>
            <Series
              type="rangeBar"
              rangeValue1Field="aVal1"
              rangeValue2Field="aVal2"
              name="BP1"
              barWidth="2"
            />

            <Series
              valueField="aVal1"
              name="BP2"
              type="spline"
              color="yellowgreen"
            />
            <Series valueField="aVal2" name="BP3" type="spline" />
            <ArgumentAxis
              type="discrete"
              argumentType="string"
              strokeWidth="1px"
              // axisDivisionFactor={20}
            >
              <Grid visible={false} />
              <Title text={this.props.name.toString().toUpperCase()}>
                <Font color="whitesmoke" size={this.state.height && "10"} />
              </Title>
              <Label customizeText={this.customizeText} />
            </ArgumentAxis>
            <ValueAxis
            // defaultVisualRange={[40, 200]}
            >
              <Grid visible={false} />
              <Title text={this.props.unitOfMeasurement}>
                <Font
                  color="rgb(251,230,244)"
                  size={this.state.height && "12"}
                />
              </Title>
            </ValueAxis>
            <Legend visible={false}></Legend>
            <Label
              customizeLabel={this.customizeLabel}
              horizontalOffset={-30}
            />
            <Connector visible={true} />
            <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} />
          </Chart>
        )}
      </Fragment>
    );
  }
  customizePoint(arg) {
    if (arg.value) {
      return {
        size: this.state.height && "10",
        hoverStyle: { color: "#ff7c7c" },
      };
    }
  }

  customizeLabel(arg) {
    if (arg.value) {
      if (arg.seriesName == "BP2") {
        return {
          visible: true,
          backgroundColor: "transparent",
          horizontalOffset: 20,
          verticalOffset: 30,
          customizeText: function (e) {
            if (e.seriesName !== "BP1") {
              let valueText = `<p>${Math.floor(+e.originalValue)} \n ${
                arg.data.day
              }</p>`;

              return valueText;
            }
          },
        };
      } else if (arg.seriesName == "BP3") {
        return {
          visible: true,
          backgroundColor: "transparent",
          horizontalOffset: -20,
          verticalOffset: 20,
          customizeText: function (e) {
            if (e.seriesName !== "BP1") {
              let valueText = `<p>${Math.floor(+e.originalValue)} \n ${
                arg.data.day
              }</p>`;

              return valueText;
            }
          },
        };
      }
    }
  }
  customizeTooltip(pointInfo) {
    let valueForLabel = `${Math.floor(+pointInfo.originalValue)}-${Math.floor(
      +pointInfo.originalMinValue
    )} `;
    if (pointInfo.seriesName !== "BP1") {
      valueForLabel = `${Math.floor(+pointInfo.value)}`;
    }
    return {
      text: `<b>${valueForLabel}</b> <br /> ${pointInfo.point.data.time}<br/>${pointInfo.point.data.date} `,
    };
  }
}

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    accessToken: state.auth.accessToken,
    noOfVitalsChartRendered: state.monitoringStatus.noOfVitalsChart,
    noOfSymptomsChartRendered: state.monitoringStatus.noOfSymptomsChart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    collectRenderedVitalsCharts: (count) =>
      dispatch(actions.getVitalsChartRendered(count)),
    collectRenderedSymptomsCharts: (count) =>
      dispatch(actions.getSymptomsChartRendered(count)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
