import React, { useState } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  RequiredRule,
  FormItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { measureTypes, relationTypes } from "../../data";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import {
  getAllDischargeContextsEndPoint,
  getAllVitalsEndPoint
} from "../../../HighOrderFunctions/APIs/allGetAPI";

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};

  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }

  // const params = Object.keys(data).map((key) => {
  //   return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
  // }).join('&');

  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
const MediaMapping = () => {
  const [searchText, setSearchText] = useState("");
  
  
  let { response: dischargeContextList } = useFetch(
    `${getAllDischargeContextsEndPoint}`,
    false
  );
  let { response: allVitalsList } = useFetch(
    `${getAllVitalsEndPoint}`,
    false
  );

  const store = new CustomStore({
    key: "alert_vitals_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-alert-vitals?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Task conversation Template", data);
          return {
            data: data.data,
            totalCount: data.totalCount,
          };
        })
        .catch(() => {
          throw "Data Loading Error";
        });
    },
    insert: (values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-alert-vitals`,
        "POST",
        values
      ),
    update: (key, values) => {
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-alert-vitals/${key}`,
        "PUT",
        values
      );
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-alert-vitals/${key}`,
        "DELETE"
      ),
  });

  const onOptionChanged = (e) => {
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  return (
    <>
      <DataGrid
        // width={"50%"}
        dataSource={store}
        showBorders={true}
        remoteOperations={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={false}
        onOptionChanged={onOptionChanged}
      >
        <SearchPanel visible={true} text={searchText} />
        <Editing
          refreshMode="reshape"
          mode="popup"
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Column
          width={35}
          dataField="alert_vitals_id"
          dataType="number"
          caption="id"
          allowEditing={false}
        >
          <FormItem colSpan={1}></FormItem>
        </Column>
        <Column dataField="vitals_name" dataType="string">
        
        <Lookup
            dataSource={allVitalsList}
            displayExpr="name"
            valueExpr="name"
          />
        </Column>
        <Column dataField="context" dataType="string">
        <Lookup
            dataSource={dischargeContextList}
            displayExpr="name"
            valueExpr="name"
          />
        </Column>
        <Column dataField="day_start" dataType="string">
         
        </Column>
        <Column dataField="day_end" dataType="string"></Column>
        <Column dataField="relation" dataType="string">
        <Lookup dataSource={relationTypes} displayExpr="name" valueExpr="name" />

        </Column>
        <Column dataField="threshold" dataType="string"></Column>

        <Paging defaultPageSize={15} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[12, 15, 20]} />
      </DataGrid>
    </>
  );
};
// calculateCellValue={calculateCellValueFunc}
export default MediaMapping;
