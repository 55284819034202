import React,{useState,useEffect} from 'react';
import './PicturesUploaded.css';
import {getPicturesUploaded} from '../../../../HighOrderFunctions/APIs/allGetAPI';
import { useFetch } from '../../../../HighOrderFunctions/FetchMethod/getFetchFunction';
import { useSelector } from 'react-redux';
import PictureButtonItem from './PictureButtonItem/PictureButtonItem';
import {v4 as uuidv4} from 'uuid';
import PreviewPopup from '../../../../Components/PreviewComponent/PreviewPopup';
const PicturesUploaded = () => {
    const [visibility,setVisibility] = useState(false);
    const [imageDetails,setImageDetails] = useState({});
    const qrCodeFromStore = useSelector((state) => state.patient.QRCode);

    const {response:picturesUploaded,isLoading}=useFetch(`${getPicturesUploaded}/${qrCodeFromStore}`);
    console.log("PICTURES UPLOADED: ",picturesUploaded)
    const updateImageUrl = (imageData)=>{
        setVisibility(true);
        setImageDetails(prev=>{return{...imageData}});
        
    }
    let renderedData = null;
    renderedData = Array.isArray(picturesUploaded) && picturesUploaded.map((item,index)=>{
        return <PictureButtonItem key={uuidv4}
        visibility={visibility}
        updateImageUrl={updateImageUrl}
        number={index+1}
        imageDetail={item}
        />
    
    })
    if(Array.isArray(renderedData) && renderedData.length==0){
        return <div className="">No Image Uploaded!</div>
    }
    return (
        <div className="picturesUploaded__container">
            {renderedData}
           {visibility && <PreviewPopup 
           visibility={visibility}
           setVisibility={setVisibility}
           width="500px"
           imageDetails={imageDetails}
           />}
        </div>
    )
}

export default PicturesUploaded
