import React, { useState, useEffect } from "react";
import CaseCard from "../../../../../Card/DischargeDetailCard/CaseCard";
import Plus from "../../../../../../AllIcons/plus.png";
import DietSearchDropdown from "../../../../SearchDropdown/DietSearchDropdown";
import ExerciseSearchDropdown from "../../../../SearchDropdown/ExerciseSearchDropdown";
import MedicalDeviceSearchDropdownNew from "../../../../SearchDropdown/MedicalDeviceSearchDropdownNew";
import "./DischargeDetailsMainContainer.css";
import { connect } from "react-redux";
import {
    setDischargeContextApproveButton,
    setDischargeNavlinkApproveStatus,
} from "../../../../../../Store/actions/index";

const DischargeDetailsMainContainer = (props) => {

    console.log(props)

    // local states to store boolean value on basis of whether diet, exercise, medicalDevices are empty or not.
    const [dietSelected, setDietSelected] = useState(false);
    const [exerciseSelected, setExerciseSelected] = useState(false);
    const [medicalDevicesSelected, setMedicalDeviceSelected] = useState(false);
    const [diagnosisUpdated, setDiagnosisUpdated] = useState(false);

    const [clinicalSuggestions, setClinicalSuggestions] = useState({
        diet: {
            value: props.diet ? props.diet : "",
        },
        exercise: {
            value: props.exercise ? props.exercise : "",
        },
        medicalDevices: {
            value: props.medicalDevices ? props.medicalDevices : "",
        },
        diagnosis:{
            value:props.diagnosis?props.diagnosis:"",
        }
    });

    // functions to update medicalDevices,exercise and diet respectively.
    const updateMedicalDevicesFunc = (value) => {
        let temp = { ...clinicalSuggestions };
        temp.medicalDevices.value = value;
        checkValuesInDischarge(temp);
        setClinicalSuggestions(temp);
        // setMedicalDevices(value?.value);
        setMedicalDeviceSelected(true);
    };
    const updateExerciseFunc = async (value) => {
        let temp = { ...clinicalSuggestions };
        temp.exercise.value = value;
        checkValuesInDischarge(temp);
        await setExerciseSelected(true);
    };
    const updateDietFunc = async (value) => {
        let temp = { ...clinicalSuggestions };
        temp.diet.value = value;
        checkValuesInDischarge(temp);
        await setDietSelected(true);
    };

    const updateDischargeSummaryDiagnosis = async(value)=>{
        alert("here");
        let temp = {...clinicalSuggestions};
        temp.diagnosis.value = value;
        alert(value);

        checkValuesInDischarge(temp);
        await setDiagnosisUpdated(true);
    }

    // function to check and ensure if all fields are filled then only green tick to Discharge Details will be updated
    const checkValuesInDischarge = (tempClinical) => {
        let tempCheckedValue = true;
        if (
            tempClinical["diet"].value == "" ||
            tempClinical["diet"].value == null ||
            tempClinical["diet"].value == undefined ||
            tempClinical["diet"].value == "None"
        ) {
            tempCheckedValue = false;
            setDietSelected(false);
        } else {
            setDietSelected(true);
        }
        if (
            tempClinical["exercise"].value == "" ||
            tempClinical["exercise"].value == null ||
            tempClinical["exercise"].value == undefined ||
            tempClinical["exercise"].value == "None"
        ) {
            tempCheckedValue = false;
            setExerciseSelected(false);
        } else {
            setExerciseSelected(true);
        }
        if (
            tempClinical["medicalDevices"].value == "" ||
            tempClinical["medicalDevices"].value == null ||
            tempClinical["medicalDevices"].value == undefined ||
            tempClinical["medicalDevices"].value == "None"
        ) {
            // tempCheckedValue = false;
            setMedicalDeviceSelected(false);
        } else {
            setMedicalDeviceSelected(true);
        }

        if (
            tempClinical["diagnosis"].value == "" ||
            tempClinical["diagnosis"].value == null ||
            tempClinical["diagnosis"].value == undefined ||
            tempClinical["diagnosis"].value == "None"
        ) {
            tempCheckedValue = false;
            setDiagnosisUpdated(false);
        } else {
            setDiagnosisUpdated(true);
        }


        props.setDischargeNavlinkApproveStatus(tempCheckedValue);
        props.setAllParametersChecked(true);
    };
    useEffect(() => {
        checkValuesInDischarge(clinicalSuggestions);
    }, []);


    return (
        <div className="dischargeDetailsMainContainer">
            <div className="caseDetails__diagnosis">
                <CaseCard
                    width="100%"
                    caseCardName="Diagnosis"
                    diagnosis={clinicalSuggestions["diagnosis"] && clinicalSuggestions["diagnosis"].value}
                    disabled={props.isMonitoringApproved}
                    setValue={updateDischargeSummaryDiagnosis}
                />
                {!diagnosisUpdated && (
                        <div className="error-message">Please update diagnosis.</div>
                    )}
            </div>
            <div className="caseDetails__medicalDevices">
                <div className="caseDetails__medicalDevicesName">
                    Medical Devices in place
                </div>
                <MedicalDeviceSearchDropdownNew
                    allowSendingToBackend={true}
                    width="100%"
                    value={clinicalSuggestions["medicalDevices"] && clinicalSuggestions["medicalDevices"].value}
                    setValue={updateMedicalDevicesFunc}
                    disabled={props.isMonitoringApproved}
                />
                {/* {!medicalDevicesSelected && (
          <div className="error-message">Please Select Medical Devices.</div>
        )} */}
            </div>
            <div className="caseDetails__diet">
                <div className="caseDetails__dietName">Diet</div>
                <DietSearchDropdown value={clinicalSuggestions["diet"] && clinicalSuggestions["diet"].value} setValue={updateDietFunc}
                    disabled={props.isMonitoringApproved}

                />
                {!dietSelected && (
                    <div className="error-message">Please Select Diet.</div>
                )}
            </div>
            <div className="caseDetails__exerciseRecoveryTools">
                <div className="caseDetails__exercise">
                    <div className="caseDetails__exerciseName">Exercise</div>

                    <ExerciseSearchDropdown
                        value={clinicalSuggestions["exercise"] && clinicalSuggestions["exercise"].value}
                        setValue={updateExerciseFunc}
                        disabled={props.isMonitoringApproved}
                    />
                    {!exerciseSelected && (
                        <div className="error-message">Please Select Exercise.</div>
                    )}
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setDischargeContextApproveButton: (status) =>
            dispatch(setDischargeContextApproveButton(status)),
        setDischargeNavlinkApproveStatus: (status) =>
            dispatch(setDischargeNavlinkApproveStatus(status)),
    };
};
const mapStateToProps = (state) => {
    return {
        dischargeContextApproveButton: state.monitoringCapsule.dischargeApproveButton,
        isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DischargeDetailsMainContainer);
