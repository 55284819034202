import React, { useEffect, useRef } from "react";
import "./RashChartBody.css";
import RashChartCapsule from "../RashChartCapsule/RashChartCapsule";
import * as actions from "../../../../../../../Store/actions/MonitoringStatusAction";
import { connect } from "react-redux";
const RashChartBody = (props) => {
  const messagesEndRef = useRef(null);

  const scrollToRight = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  let capsules = null;

  capsules =
    props.takenTimes &&
    props.takenTimes.map((item, index) => {
      return (
        <div className="rashChart__bodyDate" key={index}>
          <div className="rashChartBody__capsule" key={index}>
            <RashChartCapsule
              iconWidth={props.iconWidth && props.iconWidth}
              iconHeight={props.iconHeight && props.iconHeight}
              time={item.time}
              severity={item.severity}
              dayNumber={props.day_number}
              index={props.index}
              onset={item.onset}
              site={item.site}
              character={item.character}
              date={item.date}
              downloadBinaryChart={props.downloadBinaryChart}
            />
          </div>
          <div className="rashChartBody__dayAxis">
            <div className="rashChartBody__borderLine"></div>
            <div
              className={
                props.downloadBinaryChart
                  ? `rashChartBody__dayNumber rashChartBody__dayNumber__download`
                  : "rashChartBody__dayNumber"
              }
            >
              {index + 1 == Math.round(props.takenTimes.length / 2) &&
                `D${props.day_number}`}
            </div>
            <div
              className={
                props.downloadBinaryChart
                  ? `rashChartBody__weekDay rashChartBody__weekDay__download`
                  : "rashChartBody__weekDay"
              }
            >
              {index + 1 == Math.round(props.takenTimes.length / 2) &&
                `${props.dayName}`}
            </div>
          </div>
        </div>
      );
    });
  useEffect(() => {
    scrollToRight();
    if (props.identifier === "vitals_charts") {
      props.collectRenderedVitalsCharts(props.noOfVitalsChartRendered + 1);
    }
    if (props.identifier === "symptoms_charts") {
      // alert("Hello");
      props.collectRenderedSymptomsCharts(props.noOfSymptomsChartRendered + 1);
    }
  }, []);
  return (
    <div className="rashChartBody__container">
      <div className="rashChartBody__capsules">{capsules}</div>
      <div ref={messagesEndRef} />
      {/* <div className="rashChartBody__dayAxis">
        <div className="rashChartBody__borderLine"></div>
        <div className="rashChartBody__dayNumber"> D{props.day_number}</div>
        <div className="rashChartBody__weekDay">{props.dayName}</div>
      </div> */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    noOfVitalsChartRendered: state.monitoringStatus.noOfVitalsChart,
    noOfSymptomsChartRendered: state.monitoringStatus.noOfSymptomsChart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    collectRenderedVitalsCharts: (count) =>
      dispatch(actions.getVitalsChartRendered(count)),
    collectRenderedSymptomsCharts: (count) =>
      dispatch(actions.getSymptomsChartRendered(count)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RashChartBody);
