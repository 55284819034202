import React from "react";
import "./NavbarDischargeUnderMonitoring.css";
import { useHistory } from "react-router-dom";
import * as actions from "../../../Store/actions/index";
import { connect } from "react-redux";
import NavbarDashboardDischargeNavigateButtons from "../NavbarDashboardDischargeNavigateButtons/NavbarDashboardDischargeNavigateButtons";
const NavbarDischargeUnderMonitoring = (props) => {
  const history = useHistory();
  const navigateToDashboard = () => {
    history.push("/dashboard");
    props.setSearchedInputFunc("");
  };
  const navigateToWardNurseScreen = () => {
    props.setWardNurseRowNumber(-1);
    history.push("/ds-upload");
  };
  return (
    <div className="navbarDischargeUnderMonitoring__container">
      <NavbarDashboardDischargeNavigateButtons
        header="Post Discharge Nurse Worklist"
        text1="Dashboard"
        clicked1={navigateToDashboard}
        text2="Enrol Patient"
        clicked2={navigateToWardNurseScreen}
      />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSearchedInputFunc: (value) => dispatch(actions.setInputFunc(value)),
    setWardNurseRowNumber: (rowNumber) =>
      dispatch(actions.setWardNurseRowNumber(rowNumber)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(NavbarDischargeUnderMonitoring);
