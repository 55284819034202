import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../Components/screensComponent/Dashboard/DashboardHeader/DashboardHeader";
import DashboardNameHeader from "../../../Components/screensComponent/Dashboard/DashboardNameHeader/DashboardNameHeader";
import RCFDNameBody from "../../../Components/screensComponent/Dashboard/ReviewCallForDay/RCFDNameBody/RCFDNameBody";
import * as actions from "../../../Store/actions/index";
import { connect } from "react-redux";
import { formatDate } from "../../../HighOrderFunctions/formatDate";
import { getPatientToBeCalledEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import Loader from "../../../Loader/LoaderThreeDot";
import "./ReviewCallForDay.css";
const ReviewCallForDay = (props) => {

  // function to fetch list of patients who are supposed to be called by nurse today
  const {response,isLoading} = useFetch(`${getPatientToBeCalledEndPoint}/${formatDate(new Date())}`,props.fetchReviewCallForDay);
  useEffect(() => {
    if (props.reviewCallForDay === true) {
      props.fetchReviewCallForDay(false);
    }
  }, [props.reviewCallForDay]);


  let renderedData = null;
  renderedData = Array.isArray(response) && response.map((item, index) => {
    return (
      <RCFDNameBody
        key={index}
        rowNumber={index}
        name={item["name"]}
        dischargeDate={item["discharge_date"]}
        dischargeDiagnosis={item["context"]}
        qrCode={item["qr_code"]}
        hospitalId={item["hospital_id"]}
        timeSinceDischarge={item["time_since_discharge"]}
        age={item["age"]}
        gender={item["gender"]}
        status={[`${item["summary_status"]}`]}
      />
    );
  });

  return (
    <div className="reviewCallForDay__container">
      <div className="reviewCallForDay__header">
        <DashboardHeader
          headerName="Patient calls for the day"
          allPatientInfo={`${response.length} calls pending for the day`}
        />
      </div>
      <div className="reviewCallForDay__nameHeader">
        <DashboardNameHeader status="Monitoring Status" />
      </div>
      <div className="reviewCallForDay__nameBody">
        {isLoading ? (
          <div className="loaderbar">
            {" "}
            <Loader />{" "}
          </div>
        ) : (
          <div>{renderedData}</div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    reviewCallForDay: state.dashboard.reviewCallForDay,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchReviewCallForDay: (status) =>
      dispatch(actions.fetchReviewCallForDay(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCallForDay);
