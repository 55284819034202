import React, { useEffect, useState, useRef } from "react";
import './MFPFrequencyDropdown.css';
const MFPFrequencyDropdown = (props) => {
  const [frequencyDataFetched,setFrequencyDataFetched] = useState([]);
  useEffect(()=>{
    setFrequencyDataFetched(props.frequencyData);
  },[])
  const [open, setOpen] = useState(false);
  const reference = useRef(null);
  function toggle(e) {
    setOpen(e && e.target ==reference.current);
  }
 
  function selectOption(option) {
    setOpen(false);
    props.setSelectedValue(option)
  }
  // important for 
  useEffect(() => {
    ["click", "blur"].forEach((e) => {
      document.addEventListener(e, toggle);
    });
    return () =>
      ["click", "blur"].forEach((e) => {
        document.removeEventListener(e, toggle);
      });
  }, []);
  return (
      <div className="mfpDropdown__container">
        <button
          className={`mfpDropdown__selectedValue ${ props.selectedValue.length ==0 ? "mfpDropdown__emptyValue" :""}` }
          ref={reference}
          onClick={toggle}
          onBlur={toggle}
          disabled={props.disabled}
        >
          {props.selectedValue}
        </button>
        <div
          className={`mfpDropdownOptions ${
            open ? "showOptions" : "dontShowOptions"
          }`}
          style={{ width: props.width }}
        >
          {frequencyDataFetched["frequency"] && frequencyDataFetched["frequency"].filter(item=>item !=="None").map((option, index) => (
            <div
              key={index}
              className={`mfpDropdownOption 
                 
                `}
              onClick={() => selectOption(option)}
              onTouchEnd={() => selectOption(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
  );
};

export default MFPFrequencyDropdown;

// selectedOption1 === option["nurse_duty_mapping_id"]
