import React from "react";
import addfiles from "../../../../../AllIcons/addFilesS2.png";
import swipeS2 from "../../../../../AllIcons/swipeS2.png";

import "./FileSelectPageLogo.css";
const FileSelectPageLogo = (props) => {
 
  return (
    <div className="fileSelectS2__container ">
      <div className="fileSelectS2_addfiles" >
        <img src={addfiles} alt="addFiles" style={{
          width:"120px",
          height:"120px",
          boxShadow:"0px 0px 0px white"
        }} />
      </div>
      <div className="fileSelectS2_chooseFile">
        <p className="fileSelectS2__chooseFileText">Choose a file or drag it here  <span>
          <img src={swipeS2} alt="swipe" />
        </span></p>
        <p className="fileSelect__info">Supported Formats: .docx, .pdf, .txt</p>
       
      </div>

      <div>
       
      </div>
    </div>
  );
};


export default (FileSelectPageLogo);
