import React, { useState } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  FormItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import {
  getAllSitesEndPoint,
  getAllSiteMediaFileTypesEndPoint,
} from "../../../HighOrderFunctions/APIs/allGetAPI";


function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};

  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }



  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
const MediaMapping = () => {
  const [searchText, setSearchText] = useState("");
  
  let { response: allSitesList } = useFetch(
    `${getAllSitesEndPoint}`,
    false
  );
  let { response: allSiteMediaFileList } = useFetch(
    `${getAllSiteMediaFileTypesEndPoint}`,
    false
  );

  
  const store = new CustomStore({
    key: "site_media_mapping_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/site-media?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Task conversation Template", data);
          return {
            data: data.data,
            totalCount: data.totalCount,
          };
        })
        .catch(() => {
          throw "Data Loading Error";
        });
    },
    insert: (values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/site-media`,
        "POST",
        values
      ),
    update: (key, values) => {
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/site-media/${key}`,
        "PUT",
        values
      );
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/site-media/${key}`,
        "DELETE"
      ),
  });

  const onOptionChanged = (e) => {
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  return (
    <>
      <DataGrid
        // width={"50%"}
        dataSource={store}
        showBorders={true}
        remoteOperations={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={false}
        onOptionChanged={onOptionChanged}
      >
        <SearchPanel visible={true} text={searchText} />
        <Editing
          refreshMode="reshape"
          mode="popup"
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Column
          width={35}
          dataField="site_media_mapping_id"
          dataType="number"
          caption="id"
          allowEditing={false}
        >
          <FormItem colSpan={1}></FormItem>
        </Column>
        <Column dataField="site_name" dataType="string">
        <Lookup
            dataSource={allSitesList}
            displayExpr="name"
            valueExpr="name"
          />
        </Column>
        <Column dataField="media_key_name" dataType="string">
          
        </Column>
        <Column dataField="media_file_type" dataType="string">
          <Lookup
            dataSource={allSiteMediaFileList}
            displayExpr="name"
            valueExpr="name"
          />
        </Column>
        <Column dataField="media_file_display_name" dataType="string"></Column>

        <Paging defaultPageSize={15} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[12, 15, 20]} />
      </DataGrid>
    </>
  );
};
export default MediaMapping;
