import React from "react";
import "./NoChartData.css";
import noChartDataImage from "../../../../../../AllIcons/noChartDataImage.png";
const NoChartData = (props) => {
  return (
    <div className="noChartData__container">
      <div className="noChartData__image">
        <img src={props.imgsrc} alt="No Symptoms Alert Reported.!!!" 
        style={{width:props.size,height:props.size}}
        />
      </div>
      <div className="noChartData__text">
     {props.message}
      </div>
    </div>
  );
};

export default NoChartData;
