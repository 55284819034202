import React, { useState, useEffect } from "react";
import "./MSSituationHighlights.css";
import SituationHighlightCard from "../../../Card/MonitoringStatusCards/SituationHighlightCard/SituationHighlightCard";
import { getCurrentSituation } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction, useFetch } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { connect,useSelector } from "react-redux";
const MSSituationHighlights = (props) => {
  const accessToken =useSelector(state=>state.auth.accessToken)
  const [isLoading,setIsLoading] = useState(false);
  const [response,setResponse] =useState({});
  const fetchDataFunc = async() =>{
    setIsLoading(true);
    const fetchedData = await getFetchFunction(`${getCurrentSituation}/${props.qrCodeFromStore}`,accessToken)
    setIsLoading(false);
    setResponse(fetchedData)
  }
  useEffect(() => {
    fetchDataFunc();
  }, [])
  let renderedData = null;
  renderedData = response && (
    <SituationHighlightCard
      label={response["label"]}
      loading={isLoading}
      message={response["message"]}
      lastResponse = {response["last_response"]}
    />
  );
  return (
    <div className="msSituationHighlights__container">
      {renderedData}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(MSSituationHighlights);
