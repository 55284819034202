import React from "react";
import CircularIcon from "../../../CircularIcon/CircularIcon";
import VengageLogo from "../../../../AllIcons/transparentVEngageLogo.png";
import { connect } from "react-redux";
import NavbarPostAdmin from '../../NavbarPostAdmin/NavbarPostAdmin'
import NavbarUserLogout from "../../NavbarUserLogout/NavbarUserLogout";
const NavbarWardNurseScreen = (props) => {
  return (
    <div className="vEngageNavbar__container">
      <div className="vEngageNavbar__Logo">
        <CircularIcon imgsrc={VengageLogo} imgsize="50px" size="60px" />
      </div>
      <div className="navBarNavigationApproval">
        <NavbarPostAdmin />
      </div>
      <NavbarUserLogout />
     
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showNavigationFromStore: state.patient.showNavigation,
  };
};

export default connect(mapStateToProps, null)(NavbarWardNurseScreen);
