import React from "react";
import FollowupCard from "./FollowupCard/FollowupCard";
import FollowUpIcon from "../../../AllIcons/followUpIcon.png";

import "./FollowupActionsCard.css";

// this component is collection of arrow and Card 
const FollowupActionsCard = (props) => {
  return (
    <div className="followUpActionsCard">
      <div className="folllowUpActionsCard__leftEmptySpace"></div>

      <div className="followUpActionCard__arrowWithCard">
        <div className="followUpActionCard__arrow">
          <img src={FollowUpIcon} alt="arrowIcon" className="followUpIcon" />
        </div>
        <div className="followUpActionCard__card">
          <FollowupCard {...props}/>   
        </div>
      </div>
    </div>
  );
};

export default FollowupActionsCard;





// dfdf 