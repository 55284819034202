export const ageModifier = (age) => {
  if (age) {
    // age = age.replaceAll(" ", "");
    age = age.replace(/" "/g, "");

    age = age.split("");
    const findY = (element) => element === "Y" || element === "y";
    const findM = (element) => element === "m" || element === "M";
    const findD = (element) => element === "d" || element === "D";
    let indexOfY = age.findIndex(findY);
    let indexOfM = age.findIndex(findM);
    let indexOfD = age.findIndex(findD);
    let year = "";
    let month = "";
    let day = "";
    if(indexOfY == -1 && indexOfM == -1 && indexOfD == -1){
      return age;
    }
    if (indexOfY !== -1) {
      for (let i = 0; i < indexOfY; i++) {
        year += age[i];
      }
    }
    
    if (indexOfM !== -1) {
      for (let j = 1 + indexOfY; j < indexOfM; j++) {
        month += age[j];
      }
    }
    if (indexOfD !== -1) {
      for (let k = 1 + indexOfM; k < indexOfD; k++) {
        day += age[k];
      }
    }
    if (year && month) {
      if (month !== "" || month !== "0")
        return (
          <span>
            {year}Y {month}M
          </span>
        );
    } else if (year && (month === "" || month === "0")) {
      return <span>{year}Y</span>;
    } else if (year == "" && month && day) {
      return (
        <span>
          {month}M {day}D
        </span>
      );
    } else if (year === "" && month === "" && day) {
      return <span>{day}D</span>;
    } else if (year === "" && month && day === "") {
      return <span>{month}M </span>;
    } else {

    }
  }
};

export const returnYearOnly = (age)=>{
  if (age) {
    // age = age.replaceAll(" ", "");
    age = age.replace(/" "/g, "");
    age = age.split("");
    const findY = (element) => element === "Y" || element === "y";
    const findM = (element) => element === "m" || element === "M";
    const findD = (element) => element === "d" || element === "D";
    if(findY == -1 && findD === -1 && findM === -1){
      return <span>{age}</span>
    }
    let indexOfY = age.findIndex(findY);
    let indexOfM = age.findIndex(findM);
    let indexOfD = age.findIndex(findD);
    let year = "";
    let month = "";
    let day = "";
    if (indexOfY !== -1) {
      for (let i = 0; i < indexOfY; i++) {
        year += age[i];
      }
    }
    return <span>{year}</span>
  }
}