import React, { useEffect, useState, Fragment } from "react";

import {
  getClinicalEntitiesEndPoint,
  getPatientDetailsEndPoint,
} from "../../../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";

import { connect } from "react-redux";
import backgroundImage from "../../../../../AllIcons/Cover.png";
import AsterCoverImage from "../../../../../AllIcons/AsterCoverImage.png";

import "./PatientDetails.css";
import Footer from "./Footer";
const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const PatientDetails = (props) => {
  const [patientDetails, setPatientDetails] = useState([]);
  const [patientDischargeDate, setPatientDischargeDate] = useState("");
  const [patientMonitoringEnd, setPatientMonitoringEnd] = useState("");

  const { response: patientData, isLoading: loading } = useFetch(
    `${getPatientDetailsEndPoint}/${props.qrCodeFromStore}`
  );

  let DischargeDate = null;

  useEffect(() => {
    if (Array.isArray(patientData) && patientData.length > 0) {
      console.log("patientData", patientData);
      let fileName = `${patientData[0].patientName}_${patientData[0].age}`;
      if (patientData[0].gender) {
        fileName = `${fileName}_${patientData[0].gender}`;
      }
      console.log("fileName", fileName);
      props.setPatientData(fileName);
      let date = patientData[0].dischargeDate;
      let DATE = new Date(date);
      let getmonth = month[DATE.getMonth()];
      setPatientDischargeDate(
        `${DATE.getDate()} ${getmonth} ${DATE.getFullYear()}`
      );
      function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

      let monitoringDate = addDays(
        patientData[0].dischargeDate,
        patientData[0].daysToMonitor
      );
      let Getmonth = month[monitoringDate.getMonth()];
      setPatientMonitoringEnd(
        ` ${monitoringDate.getDate()} ${Getmonth} ${monitoringDate.getFullYear()}`
      );

      // console.log("addDays", monitoringDate);
      // console.log("DischargeDate", DischargeDate);
      // console.log("Discharge Date", DATE.getMonth());
      setPatientDetails(() => patientData);
    }

    return () => {
      setPatientDetails([]);
      setPatientDischargeDate("");
      setPatientMonitoringEnd("");
    };
  }, [patientData]);

  return (
    <Fragment>
      <div className="patientDetails" style={{ height: "820px" }}>
        <div className="patientDetails__background">
          <img
            src={backgroundImage}
            // src={AsterCoverImage}
            alt="background-image"
            className="patientDetails__image"
          />
        </div>
        {patientDetails.length > 0 && (
          <div className="patientDetails__details">
            <div className="patientDetails__left">
              <div className="patientDetails__patientName">
                {patientDetails[0].patientName}
              </div>
              <div className="patientDetails__ageNgender">
                {patientDetails[0].age} {patientDetails[0].gender}
              </div>
              <div className="patientDetails__mrnNo">
                <span style={{ fontSize: "16px" }}> MRN No</span> :
                <span style={{ fontSize: "20px" }}>
                  {" "}
                  {patientDetails[0].hospitalId}
                </span>
              </div>
            </div>
            <div className="patientDetails__right">
              <div className="patientDetails__monitoringStart">
                <span style={{ fontSize: "16px" }}> Monitoring Started</span> :
                <span style={{ fontSize: "20px" }}>{patientDischargeDate}</span>
              </div>
              <div className="patientDetails__monitoringEnd">
                <span style={{ fontSize: "16px", marginRight: "23.5px" }}>
                  Monitoring End{" "}
                </span>
                :
                <span style={{ fontSize: "20px", marginLeft: "-4px" }}>
                  {patientMonitoringEnd}
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="patientDetails__footer">
          <Footer hospitalNo={props.hospitalNo} showMaxLogo={true} />
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(PatientDetails);
