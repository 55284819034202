import React from "react";
import NavbarPatientPanelScreen from "../Components/Navbar/NavbarScreens/NavbarPatientPanelScreen/NavbarPatientPanelScreen";
import './PatientPanelWithNavbar.css';
import PatientPanel from '../PatientPanel/PatientPanel'
const PatientPanelWithNavbar = () => {
  return (
    <div className="patientPanelWithNavbar__container">
      <div className="patientPanelWithNavbar__vEngageNavbarHeader">
        <NavbarPatientPanelScreen />
      </div>
      <div className="patientPanelWithNavbar__bodyContent">
        <PatientPanel />
      </div>
      <div className="patientPanelWithNavbar__footer"></div>
    </div>
  );
};

export default PatientPanelWithNavbar;
