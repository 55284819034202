export const newDateFromNumberFunc = (dischargedDate,number) => {
    if (
      dischargedDate === "" ||
      dischargedDate === null ||
      dischargedDate === undefined
    ) {
        dischargedDate = new Date(dischargedDate);
        dischargedDate.setDate(dischargedDate.getDate() + number);
        dischargedDate = new Date().toString();
    }
    dischargedDate = new Date(dischargedDate);
    dischargedDate.setDate(dischargedDate.getDate() + number);
    dischargedDate = new Date(dischargedDate).toString();
    dischargedDate = dischargedDate.split(" ");
    let dischargedDateRequired = `${dischargedDate[2]}-${dischargedDate[1]}-${dischargedDate[3]}`;
    return dischargedDateRequired;
  };
  