import React from "react";
import "./DUMNameBody.css";
import DischargeCapsules from "../../../Capsule/DischargeCapsules/DischargeCapsules";
import { useHistory } from "react-router-dom";
import { stringToDateFunc } from "../../../../HighOrderFunctions/stringToDateFunc";
import { connect } from "react-redux";
import * as actions from "../../../../Store/actions/index";
import { returnYearOnly } from "../../../../HighOrderFunctions/ageModifier";
import { dischargeSinceDate } from "../../../../HighOrderFunctions/dischargeSinceDate";
import monthChange from "../../../../HighOrderFunctions/MonthChange";
const DUMNameBody = (props) => {
  const history = useHistory();
  const nameClicked = () => {
    props.setQRCodeToStoreFunc(props.qrCode);
    props.setCardNumberToStoreFunc(1);
    props.approveButtonStatusFuncToStore(false);
    props.showNavigationFuncToStore(true);
    if(props.dischargeStatus ==="Pending Approval"){
      history.push("/patientAlerts/DischargeDetails/caseDetails/" + props.qrCode);

    }else{
      history.push("/monitoringStatus/" + props.qrCode);
    }
  };
  let ageToBeShown = props.age && returnYearOnly(props.age);
  // let { seconds, minutes, hours, days, months, years } =
  //   props.timeSinceDischarge;
  // let timeSincePatientDischarge = dischargeSinceDate(
  //   seconds,
  //   minutes,
  //   hours,
  //   days,
  //   months,
  //   years
  // );
  return (
    <div
      className="dumNameBody__container"
      style={{
        backgroundColor: props.rowNumber % 2 !== 0 ? "#F9F7FD" : "white",
      }}
    >
      <div className="dumNameBody__patientName" onClick={nameClicked}>
        {props.name}, { props.age && props.age},
        {props.gender && ` ${props.gender.charAt(0)}`}
        <div className="dumNameBody__patientQRCode">
          {props.hospitalId}
        </div>
      </div>
      <div className="dumNameBody__dischargeDate">
        <div className="dumNameBody__dischargeDate__date">
          {monthChange(props.dischargeDate,"covidGetDOB")}
        </div>
        <div className="dumNameBody__dischargeDate__timeSinceDischarge">
          {props.timeSinceDischarge}
        </div>
      </div>
      <div className="dumNameBody__dischargeDiagnosis">
        {props.dischargeDiagnosis}
      </div>
      <div className="dumNameBody__department">{props.department}</div>
      <div className="dumNameBody__status">
        <DischargeCapsules capsuleName={`${props.dischargeStatus}`} />
        {/* <div className="dumNameBody__statusAlerts">
          <div className="dumNameBody__statusAlertCondition">12hr 35 min</div>
          <div className="dumNameBody__statusAlertTime">Since discharge</div>
        </div> */}
        {/* <DischargeCapsules capsuleName="pendingApproval" /> */}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
    setCardNumberToStoreFunc: (number) =>
      dispatch(actions.setCurrentCardNumberFunc(number)),
    approveButtonStatusFuncToStore: (status) =>
      dispatch(actions.approveButtonStatusFunc(status)),
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(null, mapDispatchToProps)(DUMNameBody);
