import React, { useState, useEffect } from "react";
import * as actions from "../../../Store/actions/index";

import { connect } from "react-redux";
import { getPatientDetailsEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import "./UDPatientPersonalDetails.css";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import PatientDetailsBody from "../../../Components/screensComponent/uploadDischarge/patientDetails/PatientDetailsBody/PatientDetailsBody";
const UDPatientPersonalDetails = (props) => {
  const {response,isLoading} = useFetch(`${getPatientDetailsEndPoint}/${props.qrCodeFromStore}`,false);

  let renderedData = [];
  renderedData = Array.isArray(response) && response.map((item, index) => {
    if (item !== undefined) {
      let dateOfBirthObject = item["dob"] && item["dob"] !=="1900-01-01" && item["dob"].split("-"); // YYYY-MM-DD
      return (
        <PatientDetailsBody
          key={index}
          name={item["patientName"]}
          hospitalId={item["hospitalId"]}
          age={item["age"]}
          dob={item["dob"]}
          tempAge={item["age"]}
          tempDOB={item["dob"]}
          day={dateOfBirthObject ? dateOfBirthObject[2] :""}
          month={dateOfBirthObject ? dateOfBirthObject[1]:""}
          year={dateOfBirthObject ? dateOfBirthObject[0]:""}
          inPatientId={item["ip_number"]}
          patientVerificationIdentifier = {item["patient_verification_identifier"]}
        />
      );
    }
  });
  return (
    <div className="udPatientPersonalDetails__container">{renderedData}</div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDischargeContextToStore: (dischargeContext) =>
      dispatch(actions.setDischargeContextFunc(dischargeContext)),
    setPrimaryContactNumberFunc: (number) =>
      dispatch(actions.setPrimaryContactNumber(number)),
  };
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UDPatientPersonalDetails);
