import React, { useEffect, useState, useRef } from "react";
import './MFPInstructionDropdown.css';
const MFPInstructionDropdown = (props) => {
    const [instructionDataFetched,setInstructionDataFetched] = useState([]);
    useEffect(()=>{
      setInstructionDataFetched(props.instructionData);
    },[])
  const [open, setOpen] = useState(false);
  const instructionReference = useRef(null);
  function toggle(e) {
    setOpen(e && e.target ==instructionReference.current);
  }
 
  function selectOption(option) {
    setOpen(false);
    props.setSelectedValue(option)
  }
  // important for 
  useEffect(() => {
    ["click", "blur"].forEach((e) => {
      document.addEventListener(e, toggle);
    });
    return () =>
      ["click", "blur"].forEach((e) => {
        document.removeEventListener(e, toggle);
      });
  }, []);
  return (
      <div className="mfpDropdown__container">
        <button
          className={`mfpDropdown__selectedValue
          ${ (!props.selectedValue["value"]) ? "mfpDropdown__emptyValue" :""}
           ` }
          ref={instructionReference}
          onClick={toggle}
          onBlur={toggle}
          disabled={props.disabled}
        >
          {props.selectedValue["text"]}
        </button>
        <div
          className={`mfpDropdownOptions ${
            open ? "showOptions" : "dontShowOptions"
          }`}
          style={{ width: props.width }}
        >
          { instructionDataFetched && instructionDataFetched.map((option, index) => (
            <div
              key={index}
              className={`mfpDropdownOption 
                 
                `}
              onClick={() => selectOption(option)}
              onTouchEnd={() => selectOption(option)}
            >
              {option["text"]}
            </div>
          ))}
        </div>
      </div>
  );
};

export default MFPInstructionDropdown;

// selectedOption1 === option["nurse_duty_mapping_id"]
