export const FETCH_AGAIN = "FETCH_AGAIN";
// uploading
export const UPLOAD_DONE = "UPLOAD_DONE";

// QR CODE
export const SET_QRCODE = "SET_QRCODE";

// current card number
export const SET_CURRENTCARDNUMBER = "SET_CURRENTCARDNUMBER";
export const SET_CARDNUMBER_ARRAY = "SET_CARDNUMBERARRAY";
export const SET_APPROVE_BUTTON_STATUS = "SET_APPROVE_BUTTON_STATUS";
// medicine
export const SET_CURRENTMEDICINE = "SET_CURRENTMEDICINE";
export const SET_REPLACEDMEDICINE = "SET_REPLACEDMEDICINE";
export const SET_DATEDURATION = "SET_DATEDURATION";

// reset state
export const RESET_PATIENT_STATE = "RESET_PATIENT_STATE";

// discharge context
export const SET_DISCHARGE_CONTEXT = "SET_DISCHARGE_CONTEXT";

//set Hospital ID
export const SET_PATIENTDETAILS = "SET_PATIENTDETAILS";

// set contact Number
export const SET_PRIMARY_CONTACT_NUMBER = "SET_PRIMARY_CONTACT_NUMBER";

// show navigation
export const SHOW_NAVIGATION = "SHOW_NAVIGATION";

// fetch in alertThreshold
export const FETCHTHRESHOLDAGAIN = "FETCHTHRESHOLDAGAIN";

// fetch in MedicationSchedules
export const FETCHMEDICINEAGAIN = "FETCHMEDICINEAGAIN";

// fetch in dischargePatientAlert
export const FETCHDISCHARGEPATIENTALERT = "FETCHDISCHARGEPATIENTALERT";

// fetch in Approve Monitoring Schedules
export const FETCHAPPROVEMONITORINGSCHEDULES =
  "FETCHAPPROVEMONITORINGSCHEDULES";

// fetch in Cases For Review
export const FETCHREVIEWCALLFORDAY = "FETCHREVIEWCALLFORDAY";

// fetch in Routine Schedules Calls
export const FETCHPATIENTUNDERMONITORING = "FETCHPATIENTUNDERMONITORING";

//QRCODE ON upload
export const SETQRCODEONUPLOAD = "SETQRCODEONUPLOAD";

// MEDICATION DATES
export const MEDICATIONDATES = "MEDICATIONDATES";

// Monitoring Status

// summary of symptoms
export const SUMMARYOFSYMPTOMS = "SUMMARYOFSYMPTOMS";

// summary of Events
export const SUMMARYOFEVENTS = "SUMMARYOFEVENTS";

//summary of Compliance
export const SUMMARYOFCOMPLIANCE = "SUMMARYOFCOMPLIANCE";

export const SYMPTOMLIST = "SYMPTOMLIST";
// online status
export const SETONLINESTATUS = "SETONLINESTATUS";

// search input dashboard.js and passing to
export const SET_INPUT = "SET_INPUT";

// PatientAlert (MonitoringCapsule)  - Each Approve Button
export const SETDISCHARGECONTEXT_APPROVE_BUTTON =
  "SETDISCHARGECONTEXT_APPROVE_BUTTON";
export const SETALERTTHRESHOLD_APPROVE_BUTTON =
  "SETALERTTHRESHOLD_APPROVE_BUTTON";
export const SETFOLLOWUPSCHEDULES_APPROVE_BUTTON =
  "SETFOLLOWUPSCHEDULES_APPROVE_BUTTON";
export const SETMEDICATION_APPROVE_BUTTON = "SETMEDICATION_APPROVE_BUTTON";

// PatientAlert For NavLink - For approve
export const SETDISCHARGE_NAVLINK_APPROVESTATUS =
  "SETDISCHARGE_NAVLINK_APPROVESTATUS";
export const SETALERTTHRESHOLD_NAVLINK_APPROVESTATUS =
  "SETALERTTHRESHOLD_NAVLINK_APPROVESTATUS";
export const SETFOLLOWUPSCHEDULES_NAVLINK_APPROVESTATUS =
  "SETFOLLOWUPSCHEDULES_NAVLINK_APPROVESTATUS ";
export const SETMEDICATION_NAVLINK_APPROVESTATUS =
  "SETMEDICATION_NAVLINK_APPROVESTATUS ";

// reset ALL NAVLINK STATUS TO false
export const RESET_NAVLINK_APPROVESTATUS = "RESET_NAVLINK_APPROVESTATUS";

// refresh ALL Covid Patient Screen
export const FETCH_COVIDPATIENTLIST_AGAIN = "FETCH_COVIDPATIENTLIST_AGAIN";

// refresh ALL ONCALLREGISTRATION screen
export const FETCH_ONCALLREGISTRATION_AGAIN = "FETCH_ONCALLREGISTRATION_AGAIN";

export const SHOW_REGISTRATION_ONCALLREGISTRATION =
  "SHOW_REGISTRATION_ONCALLREGISTRATION";

export const FETCH_NURSELIST_AGAIN = "FETCH_NURSELIST_AGAIN";

// ROW SELECTION REDUX
// ... Ward Nurse Row Selection
export const WN_ROW_SELECTION = "WN_ROW_SELECTION";

export const SET_DISCHARGE_SUMMARY_SELECTED = "SET_DISCHARGE_SUMMARY_SELECTED";

// App.js
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";

// caseDetails.js
export const FETCH_PATIENT_DETAILS = "FETCH_PATIENT_DETAILS";

export const NOTIFICATION_WITH_MESSAGE = "NOTIFICATION_WITH_MESSAGE";
export const IS_MONITORING_APPROVED = "IS_MONITORING_APPROVED";

//AdminDrawerButton
export const ADMIN_DRAWER_BUTTON_ACTIVE = "ADMIN_DRAWER_BUTTON_ACTIVE";

//To count the rendered vitals charts
export const RENDERED_VITALS_CHARTS = "RENDERED_VITALS_CHARTS";

// To count the rendered symptoms charts

export const RENDERED_SYMPTOMS_CHARTS = "RENDERED_SYMPTOMS_CHARTS";

// To check the Medication schedule page is rendered or not
export const IS_MEDICATION_SCHEDULE_PAGE_RENDERED =
  "IS_MEDICATION_SCHEDULE_PAGE_RENDERED";

export const SHALL_CREATE_REMINDER = "SHALL_CREATE_REMINDER";
