import {
  SUMMARYOFSYMPTOMS,
  SUMMARYOFEVENTS,
  SUMMARYOFCOMPLIANCE,
  RENDERED_VITALS_CHARTS,
  RENDERED_SYMPTOMS_CHARTS,
} from "./actionTypes";

export const fetchSummaryOfSymptoms = (fetchingStatus) => {
  return {
    type: SUMMARYOFSYMPTOMS,
    fetchingStatus: fetchingStatus,
  };
};
export const fetchSummaryOfCompliance = (fetchingStatus) => {
  return {
    type: SUMMARYOFCOMPLIANCE,
    fetchingStatus: fetchingStatus,
  };
};
export const fetchSummaryOfEvents = (fetchingStatus) => {
  return {
    type: SUMMARYOFEVENTS,
    fetchingStatus: fetchingStatus,
  };
};

export const getVitalsChartRendered = (count) => {
  return {
    type: RENDERED_VITALS_CHARTS,
    renderedChart: count,
  };
};

export const getSymptomsChartRendered = (count) => {
  return {
    type: RENDERED_SYMPTOMS_CHARTS,
    renderedChart: count,
  };
};
