import React, { useState, useEffect } from "react";
import { getNurseNotes } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import {
  getFetchFunction,
  useFetch,
} from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { connect } from "react-redux";
// import {nurseNoteDataSet} from '../../../../Charts/Data'
import MSNurseNoteComponent from "../../../../Components/screensComponent/MonitoringStatus/MSNurseNoteComponent/MSNurseNoteComponent";
import "./MSNurseNotes.css";
import { dynamicSortDesc } from "../../../../HighOrderFunctions/dynamicSort";
import noNurseNoteRecordedIcon from "../../../../AllIcons/noNurseNoteRecordedIcon.png";
const MSNurseNotes = (props) => {
  let fontFamily = "Calibri, Candara, Segoe, Segoe UI, Optima, Arial";

  let { response, isLoading } = useFetch(
    `${getNurseNotes}/${props.qrCodeFromStore}`
  );

  if (Array.isArray(response) && response.length > 0) {
    let sortedData = response.sort(dynamicSortDesc("day_number"));
    response = sortedData;
  }

  let renderedData = null;
  console.log("renderedData", response);
  renderedData =
    Array.isArray(response) &&
    response.map((item, index) => {
      return (
        <MSNurseNoteComponent
          key={`${item} - ${index}`}
          dayNumber={item["day_number"]}
          data={item["data"]}
          day={item["day"]}
          heading2={props.heading2 && props.heading2}
          backgroundColor={props.backgroundColor && props.backgroundColor}
        />
      );
    });
  if (response.length === 0) {
    return (
      <div className="msNurseNotes__container">
        <div
          className="msNurseNotes__header"
          style={{ fontSize: props.heading1 && props.heading1 }}
        >
          Nursing Notes
        </div>
        <div className="msNurseNotes__noNurseNote">
          <img src={noNurseNoteRecordedIcon} />
        </div>
      </div>
    );
  }
  return (
    <div className="msNurseNotes__container">
      <div
        className="msNurseNotes__header"
        style={{
          fontSize: props.heading1 && props.heading1,
          fontFamily: props.heading1 && fontFamily,
        }}
      >
        Nursing Notes
      </div>
      <div className="msNurseNotes__body">{renderedData}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};

export default connect(mapStateToProps)(MSNurseNotes);
