import React, { useState, useEffect } from "react";
import "./OnCallRegistrationBodyNameBody.css";
import CircularIcon from "../../../../CircularIcon/CircularIcon";
import mobilephoneS2 from "../../../../../AllIcons/mobilephoneS2.png";
import SendInvitation from "../../../../sendInvitation/SendInvitation";
import closeCrossIcon from "../../../../../AllIcons/closeCrossIcon.png";
import {
  checkValidity,
  updateObject,
} from "../../../../../HighOrderFunctions/shared/utility";
import { sentWhatsappMessageEndPoint } from "../../../../../HighOrderFunctions/APIs/allPostAPI";
import { removeCovidNurseEndPoint } from "../../../../../HighOrderFunctions/APIs/allPostAPI";
import { connect, useSelector} from "react-redux";
import * as actions from "../../../../../Store/actions/index";
import ModalDelete from "../../../../../modals/ModalDelete/ModalDelete";
const OnCallRegistrationBodyNameBody = (props) => {
  const accessToken = useSelector(state=>state.auth.accessToken);
  const [showSendInvitation, setShowSendInvitation] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [removeRegisteredNurse, setRemoveRegisteredNurse] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [covidPatientDetails, setCovidPatientDetails] = useState({
    contactNumber: {
      value: props.phone_no,
      valid: false,
      clicked: false,
      validations: {
        required: true,
        isNumeric: true,
        maxLength: 10,
        minLength: 10,
      },
    },
  });
  const removeNurseFromListCondition = () => {
    setShowModal((prev) => !prev);
  };
  const toggleShowSendInvitation = () => {
    setShowSendInvitation((prev) => !prev);
    props.setSelectedSendInvitation(props.index);
  };
  const setContactNumberEntered = (value, inputIdentifier) => {
    let valuePassed = value;
    let valueForValidityCheck = value;
    if (Number.isInteger(+value) && value.length <= 10) {
      valuePassed = value;
    } else {
      valueForValidityCheck = covidPatientDetails["contactNumber"].value;
      valuePassed = covidPatientDetails["contactNumber"].value;
    }
    const updatedFormElement = updateObject(
      covidPatientDetails[inputIdentifier],
      {
        value: valuePassed,
        clicked: true,
        valid: checkValidity(
          valueForValidityCheck,
          covidPatientDetails[inputIdentifier].validations
        ),
      }
    );
    const updatedCovidPatientDetails = updateObject(covidPatientDetails, {
      [inputIdentifier]: updatedFormElement,
    });

    // checking validation of covid Form

    let formIsValid = true;
    for (let inputIdentifier in updatedCovidPatientDetails) {
      formIsValid =
        updatedCovidPatientDetails[inputIdentifier].valid && formIsValid;
    }
    setCovidPatientDetails(updatedCovidPatientDetails);
    setFormIsValid(formIsValid);

  };
  const resendMessageToPatient = () => {
    var formdata = new FormData();
    formdata.append(
      "To",
      `whatsapp:+91${covidPatientDetails["contactNumber"].value}`
    );
    formdata.append("id", props.whatsappId);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken} `,

      }
    };
    fetch(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${sentWhatsappMessageEndPoint}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setShowSendInvitation(false);
      })
      .catch((error) => console.log("error", error));
  };
  const removeNurseFromList = () => {
    let requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken} `,

      }

    };
    try {
      fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/${removeCovidNurseEndPoint}/${props.id}`,
        requestOptions
      ).then((res) => {
        if (res.status === 200) {
          props.fetchingOnCallRegistrationList(true);
        }
      });
    } catch (err) {}
  };
  useEffect(() => {
    if (removeRegisteredNurse === true) {
      setRemoveRegisteredNurse(false);
      removeNurseFromList();
    }
  }, [removeRegisteredNurse]);
  const cancelNurseDelete =()=>{
    setShowModal(false);
    setRemoveRegisteredNurse(false);
  }
  const confirmNurseDelete = () =>{
    setShowModal(false);
    setRemoveRegisteredNurse(true);
    
  }
  return (
    <div
      className={`onCallRegistrationBodyNameBody__container ${
        props.rowNumber % 2 !== 0
          ? "onCallRegistrationBodyNameBody__containerOdd"
          : ""
      }`}
    >
      <div className="onCallRegistrationBodyNameBody__name">
        {props.nurseName}
      </div>
      <div className="onCallRegistrationBodyNameBody__registrationDate">
        {props.registrationDate}
      </div>
      <div className="onCallRegistrationBodyNameBody__consent">
        {props.consent}
      </div>
      <div className="onCallRegistrationBodyNameBody__phoneNumber">
        {props.phone_no}
      </div>
      <div className="onCallRegistrationBodyNameBody__actions">
        <div className="onCallRegistrationBodyNameBody__actionsResendMessage">
          <CircularIcon
            size="30px"
            imgsrc={mobilephoneS2}
            clicked={toggleShowSendInvitation}
          />
          {showSendInvitation && props.index == props.selectedSendInvitation && (
            <div className="onCallRegistrationBodyNameBody__sendInvitation">
              <SendInvitation
                toggleSendInvitation={toggleShowSendInvitation}
                contactNumber={covidPatientDetails["contactNumber"].value}
                setContactNumberEntered={(value) =>
                  setContactNumberEntered(value, "contactNumber")
                }
                touchedNotValid={
                  covidPatientDetails["contactNumber"].clicked &&
                  !covidPatientDetails["contactNumber"].valid
                }
                // disabled={!formIsValid}
                disabled={true}
                resendMessageToPatient={resendMessageToPatient}
              />
            </div>
          )}
        </div>
        <div className="onCallRegistrationBodyNameBody__actionsDeleteList">
          <CircularIcon
            size="30px"
            imgsrc={closeCrossIcon}
            clicked={removeNurseFromListCondition}
          />
        </div>
      </div>
      {showModal && (
        <div className="onCallRegistrationBodyNameBody__modal">
          <ModalDelete 
          cancel="Cancel"
          select="Confirm"
          messageHeading="Nurse De-Activation"
          messageBody="Are you sure you want to de-activate the Nurse ?"
          cancelNurseDelete={cancelNurseDelete}
          confirmNurseDelete={confirmNurseDelete}
          />
        </div>
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchingOnCallRegistrationList: (status) =>
      dispatch(actions.fetchOnCallRegistrationAgain(status)),
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(OnCallRegistrationBodyNameBody);
