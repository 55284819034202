import React from "react";
import phoneImage from "../../../../../AllIcons/Phone.png";
import maxhomelogo from "../../../../../AllIcons/maxhomelogo.png";
import AsterLogo from "../../../../../AllIcons/aster_logo.png";

import { FaPhoneAlt } from "react-icons/fa";
import "./Footer.css";
const Footer = (props) => {
  return (
    <div
      className="footer"
      style={{ paddingBottom: props.paddingBottom && props.paddingBottom }}
    >
      <div
        className={
          props.class_name ? props.class_name : "footer__mobilenoNlogo"
        }
      >
        <div className="footer__phoneIcon">
          <FaPhoneAlt color="#1F4E79" />
        </div>
        <p className="footer__mobileno">{props.hospitalNo}</p>
      </div>
      <div
        className="footer__maxhome__logo"
        style={{
          width: props.showMaxLogo && "0%",
          height: props.showMaxLogo && "0px",
        }}
      >
        {!props.showMaxLogo && (
          <img
            src={maxhomelogo}
            // src={AsterLogo}
            className="footer__logo"
          />
        )}
      </div>
    </div>
  );
};

export default Footer;
