import React, { useEffect, useState } from "react";
import "./OnCallRegistrationBody.css";
import OnCallRegistrationBodyNameHeader from "./OnCallRegistrationBodyNameHeader/OnCallRegistrationBodyNameHeader";
import OnCallRegistrationBodyNameBody from "./OnCallRegistrationBodyNameBody/OnCallRegistrationBodyNameBody";
import { getRegisteredNurseListEndPoint } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { dynamicSortDesc } from "../../../../HighOrderFunctions/dynamicSort";
import { connect } from "react-redux";
import * as actions from "../../../../Store/actions/index";
import LoaderThreeDot from "../../../../Loader/LoaderThreeDot";
import { useSelector } from "react-redux";
const OnCallRegistrationBody = (props) => {
  const token = useSelector((state) => state.auth.accessToken);
  const [selectedSendInvitation, setSelectedSendInvitation] = useState(-1);
  const [fetchedData, setFetchedData] = useState([]);
  const [totalCovidPatients, setTotalCovidPatients] = useState(0);
  const [loading, setLoading] = useState(false);

  // list of parameters on basis of which we can filter list of nurse using search box
  const [searchColumns, setSearchColumns] = useState([
    "id",
    "nurseName",
    "registrationDate",
    "consent",
    "phone_no",
  ]);
  const [q, setQ] = useState("");

  // function to fetch list of nurses
  const fetchedDataFunc = async () => {
    setLoading(true);
    let fetchedValue = await getFetchFunction(
      `${getRegisteredNurseListEndPoint}/${window["getConfig"].SITE_ID}`,
      token
    );
    if (Array.isArray(fetchedValue) == true) {
      let sortedData = fetchedValue.sort(dynamicSortDesc("registrationDate"));

      setFetchedData(sortedData);

      setTotalCovidPatients(fetchedValue.length);
    }
    setLoading(false);
  };
  let fetchingStatus = props.onCallRegistrationListFlag;

  // useEffect to search list of nurse on change of redux state -> onCallRegistrationListFlag (i.e fetchOnCallRegistrationList in redux)
  useEffect(() => {
    if (props.onCallRegistrationListFlag === true) {
      fetchedDataFunc();
      props.fetchingOnCallRegistrationList(false);
    }
  }, [fetchingStatus]);

  //  initially fetching data
  useEffect(() => {
    fetchedDataFunc();
  }, []);


  // function to filter list of nurses on basis of above defined parameters,used in search box 
  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] &&
          row[column]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("-", "")
            .indexOf(
              props.searchedValue
                .toLowerCase()
                .replaceAll(" ", "")
                .replaceAll("-", "")
            ) > -1
      )
    );
  }

  let renderedData = [];
  renderedData = search(fetchedData).map((item, index) => {
    return (
      <OnCallRegistrationBodyNameBody
        key={index}
        index={index}
        nurseName={item["nurseName"]}
        registrationDate={item["registrationDate"]}
        consent={item["consent"]}
        phone_no={item["phone_no"]}
        id={item["id"]}
        selectedSendInvitation={selectedSendInvitation}
        setSelectedSendInvitation={setSelectedSendInvitation}
      />
    );
  });

  return (
    <div className="onCallRegistrationBody__container">
      <div className="onCallRegistrationBody__nameHeader">
        <OnCallRegistrationBodyNameHeader />
      </div>
      <div className="onCallRegistrationBody__nameBody">
        {loading ? (
          <div className="loaderbar">
            {" "}
            <LoaderThreeDot />{" "}
          </div>
        ) : (
          <div>{renderedData}</div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    onCallRegistrationListFlag: state.covidPatient.fetchOnCallRegistrationList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchingOnCallRegistrationList: (status) =>
      dispatch(actions.fetchOnCallRegistrationAgain(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnCallRegistrationBody);
