import React, { useRef } from "react";
import "./AppointmentCard.css";
import plusIcon from "../../../../../../../AllIcons/plus.png";
import editIcon from "../../../../../../../AllIcons/editIcon.png";
import mdiClose from "../../../../../../../AllIcons/mdiCloseS3.png";
import CircularButtonWithTextBelow from "../../../../../../Buttons/CircularButtonWithTextBelow/CircularButtonWithTextBelow";
import CircularIconForDate from "../../../../../../CircularIcon/CircularIconForDate";
import { newDateFromNumberFunc } from "../../../../../../../HighOrderFunctions/newDateFromNumberFunc";
import { connect } from "react-redux";
import EditableNurseCall from "../../../../../../../HighOrderFunctions/EditableNurseCalendar/EditableNurseCalendar";
const AppointmentCard = (props) => {
  const calendarAddDataRef = useRef();
  return (
    <div className="appointmentCard__mainContainer">
      <div className="appointmentCard__addCall">
        <div className="appointmentCardAddCall__container">
          <div className="appointmentCardAddCall__icon">
            <CircularIconForDate bgcolor="#F6F6F6">
              <EditableNurseCall childRef={calendarAddDataRef}>
                <input
                  ref={calendarAddDataRef}
                  type="date"
                  className="addDatePickerInput"
                  style={{ display: "hidden " }}
                  onChange={(e) => props.updateDateFunc(e)}
                  // min="14-05-2021"
                  max={`${new Date(
                    newDateFromNumberFunc(
                      props.dischargeDate,
                      props.monitoringDuration
                    )
                  )}`}
                  name="trip-start"
                />
              </EditableNurseCall>
            </CircularIconForDate>
          </div>
          <div className="appointmentCardAddCall__text">Add Call</div>
        </div>
      </div>

      {/* <div className="appointmentCard__editDate">
        <CircularButtonWithTextBelow
          imgSrc={editIcon}
          name="Edit date"
          clicked={props.editCall}
        />
      </div> */}
      <div className="appointmentCard__delete">
        <CircularButtonWithTextBelow
          imgSrc={mdiClose}
          name="Delete"
          clicked={props.deleteCall}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    monitoringDuration:
      state.patient.patientDetails &&
      state.patient.patientDetails[0]["daysToMonitor"],
    dischargeDate:
      state.patient.patientDetails &&
      state.patient.patientDetails[0]["dischargeDate"],
  };
};
export default connect(mapStateToProps, null)(AppointmentCard);
