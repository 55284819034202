import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export function handleResponse(response) {
  return response.json().then((json) => {
    if (!response.ok) {
      const error = Object.assign({}, json, {
        status: response.status,
        statusText: response.statusText,
      });
      return Promise.reject(error);
    }
    return json;
  });
}

export const getFetchFunction = async (urlEndpoint, accessToken,method="GET") => {
  if (!navigator.onLine) {
    alert("Please connect to Network.")
    return null;
  }
  try {
    let valueFetched = fetch(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${urlEndpoint}`,
      {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      });
    return valueFetched;
  } catch (err) {
    alert(err);
    console.log("ERROR OCCURED", err);
    return null;
  }
};

export const useFetch = (urlEndpoint, fetchAgain = false) => {
  const { getAccessTokenSilently,logout } = useAuth0();
  const [response, setResponse] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const getAuthToken = async () => {
    const token = await getAccessTokenSilently();
    return token;
  };
  
  React.useEffect(() => {
    const ac = new AbortController();
    const fetchData = async () => {
      const token = await getAuthToken();
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      let requestOptions = {
        method: "GET",
        signal: ac.signal,
        headers: {
          Authorization: `Bearer ${token} `,
        },
      };
      setIsLoading(true);
      try {
        const res = await fetch(
          // `${window["getConfig"].BACKEND_SERVICE_URL}/${urlEndpoint}`,
          `${window["getConfig"].BACKEND_SERVICE_URL}/${urlEndpoint}`,
          requestOptions
        )
          .then((response) => {
            if(response.status == 401){
              alert("un-Authorised!!!.Please Login Again")
                localStorage.removeItem("state");
                logout({ returnTo: window.location.origin });
            }
            return response.json()
          })
          .then((result) => {
            setIsLoading(false);
            setResponse(result);
            return result;
          });
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
    return () => ac.abort();
  }, [fetchAgain]);

  return { response, error, isLoading };
};
