import React from "react";
import "./MonitoringOtherTasks.css";
import { useSelector } from "react-redux";
const MonitoringOtherTasks = () => {
  const qrCodeFromStore = useSelector((state) => state.patient.QRCode);
  const navigateToMonitoringCapsules = () => {
    window.open(
      "/#/patientAlerts/DischargeDetails/caseDetails/" + qrCodeFromStore
    );
  };
  return (
    <div
      className="monitoringOtherTasks__container"
      onClick={navigateToMonitoringCapsules}
    >
      Monitoring Capsule{" "}
      <span className="monitoringOtherTasks__borderRight">|</span>
    </div>
  );
};

export default MonitoringOtherTasks;
