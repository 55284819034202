import React from "react";
import { connect } from "react-redux";
import SearchDevExtreme from "./SearchDevExtreme";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { dynamicSort } from "../../../HighOrderFunctions/dynamicSort";
import {getAllLanguagesEndPoint} from '../../../HighOrderFunctions/APIs/allGetAPI'
function LanguageSearchDropdown(props) {

  let {response,isLoading} = useFetch(`${getAllLanguagesEndPoint}`,false)
  if(Array.isArray(response) && response.length > 0){
    let sortedData = response.sort(dynamicSort("language_name"));
    response = sortedData;
  }else{
    response = [];
  }

  const updateLanguage = (language) => {
    props.setValue(language);
   
  };
  return (
    <div
      className="devextremeDropdown__container"
      style={{ width: props.width }}
      disabled={props.disabled}
    >
  
      <SearchDevExtreme
        placeholder="Select Language"
        optionsFetched={response}
        displayName="language_name"
        displayExpr="language_name"
        valueExpr="language_name"
        defaultValue={props.value}
        updatedSelectedValue={updateLanguage}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(LanguageSearchDropdown);
