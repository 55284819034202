import React, { useState, useEffect } from "react";
import { stringToDateFunc } from "../../../../HighOrderFunctions/stringToDateFunc";
import { formatDate } from "../../../../HighOrderFunctions/formatDate";
import EditIcon from "../../../../AllIcons/editIcon.png";
import FollowupScheduleSearchDropdown from "../../../screensComponent/SearchDropdown/FollowupsListDropdown";
import saveFileIcon from "../../../../AllIcons/saveFileIcon.png";
import { connect, useSelector } from "react-redux";
import { Prompt } from "react-router";
import DeleteIcon from "../../../../AllIcons/deleteIcon.png";
import CircularIcon from "../../../CircularIcon/CircularIcon";
import ModalDelete from "../../../../modals/ModalDelete/ModalDelete";
import monthChange from "../../../../HighOrderFunctions/MonthChange";
import "./FollowupCard.css";
import {
  checkValidity,
  updateObject,
} from "../../../../HighOrderFunctions/shared/utility";
import { newDateFromNumberFunc } from "../../../../HighOrderFunctions/newDateFromNumberFunc";
import { Calendar } from "devextreme-react";
const FollowupCard = (props) => {
  const { date:dischargeDate, duration:monitoringDuration } = props.dateDurationFromStore;
  
  let monitoringDate = newDateFromNumberFunc(dischargeDate, monitoringDuration);

  const [newScheduleCard, setNewScheduleCard] = useState(
    props.newScheduleCard ? props.newScheduleCard : false
  );
  const [formIsValid, setFormIsValid] = useState(false);
  const [followupInEditMode, setFollowupInEditMode] = useState( props.newScheduleCard ? props.newScheduleCard : false);
  const [currentDateValue, setCurrentDateValue] = useState(new Date(newDateFromNumberFunc(dischargeDate, -1)));

  const [showModal, setShowModal] = useState(false);
  const [calendarIsVisible, setCalendarIsVisible] = useState(false);
  const [followupForm, setFollowupForm] = useState({
    followupLabel: {
      value: "",
      valid: true,
      clicked: false,
      validations: {
        required: true,
      },
    },
    date: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
      },
    },

    followupDetails: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
      },
    },
    followupId: {
      value: "",
      valid: true,
      clicked: false,
      validations: {
        // required: true,
      },
    },
  });

  const updateFollowupForm = (value, inputIdentifier) => {
    let valuePassed = value;
    let valueForValidityCheck = value;
    if (inputIdentifier == "followupLabel") {
      valuePassed = valuePassed["label"];
      valueForValidityCheck = value["label"];
      setFollowupInEditMode(true);
    }
    if (inputIdentifier == "date") {
      valuePassed = stringToDateFunc(value);
      valueForValidityCheck = monthChange(valuePassed, "covidRegistrationForm");
      setCurrentDateValue(value);
      setCalendarIsVisible(false);
    }

    if (inputIdentifier == "followupDetails") {
      valuePassed = valuePassed;
    }
    if (inputIdentifier == "followupId") {
      valuePassed = valuePassed;
    }

    const updatedFormElement = updateObject(followupForm[inputIdentifier], {
      value: valuePassed,
      clicked: true,
      valid: checkValidity(
        valueForValidityCheck,
        followupForm[inputIdentifier].validations
      ),
    });
    const tempUpdatedFollowupForm = updateObject(followupForm, {
      [inputIdentifier]: updatedFormElement,
    });

    // checking validation of covid Form
    let formIsValid = true;
    for (let inputIdentifier in tempUpdatedFollowupForm) {
      formIsValid =
        tempUpdatedFollowupForm[inputIdentifier].valid && formIsValid;
    }
    setFollowupForm(tempUpdatedFollowupForm);
    setFormIsValid(formIsValid);
  };


  // initially passing values like followupLabel,date, and followupDetails to each card on mounting 
  useEffect(() => {
    let tempFollowupForm = { ...followupForm };
    let tempFollowupForm1 = {
      ...tempFollowupForm,
      followupLabel: {
        ...tempFollowupForm["followupLabel"],
        value: props.followupLabel,
        valid:
          props.followupLabel &&
          checkValidity(
            props.followupLabel,
            tempFollowupForm["followupLabel"].validations
          ),
      },

      date: {
        ...tempFollowupForm["date"],
        value: stringToDateFunc(props.date),
        valid: checkValidity(props.date, tempFollowupForm["date"].validations),
      },
      followupDetails: {
        ...tempFollowupForm["followupDetails"],
        value: props.followupDetails,
        valid:
          props.followupDetails &&
          checkValidity(
            props.followupDetails,
            tempFollowupForm["followupDetails"].validations
          ),
      },
      followupId: {
        ...tempFollowupForm["followupId"],
        value: props.followupId,
        valid:
          props.followupId &&
          checkValidity(
            props.followupId,
            tempFollowupForm["followupId"].validations
          ),
      },
    };
    setFollowupForm(tempFollowupForm1);

    let tempFormIsValid = true;
    for (let inputIdentifier in tempFollowupForm1) {
      tempFormIsValid =
        tempFollowupForm1[inputIdentifier].valid && tempFormIsValid;
    }
    setFormIsValid(tempFormIsValid);
    props.checkArrayUpdate(props.index, tempFormIsValid);
    var timestamp = Date.parse(props.date);
    if (isNaN(timestamp) == false) {
      setCurrentDateValue(new Date(props.date))
    }
  }, []);
  
  // followup in edit mode 
  const editFollowupFunc = () => {
    if (followupInEditMode == false) {
      setFollowupInEditMode(true);
    } else {
      if (!formIsValid) {
      } else {
        let formToBeSend = {
          followupLabel: followupForm["followupLabel"].value,
          followupDetails: followupForm["followupDetails"].value,
          date: formatDate(followupForm["date"].value),
          id: followupForm["followupId"].value,
          qr_code: props.qrCodeFromStore,
        };
        props.checkArrayUpdate(props.index, formIsValid);

        const newFollowupId = props.saveFollowupFunc(
          formToBeSend,
          followupForm["followupId"].value,
          props.index
        );

        setFollowupInEditMode((prev) => !prev);
        setNewScheduleCard(false);
      }
    }
  };

// functions for confirmation of deletion of followup card
  const cancelDeleteFollowupCard = () => {
    setShowModal(false);
  };

  const confirmDeleteFollowupCard = () => {
    props.deleteScheduleCardFunc(followupForm["followupId"].value, props.index);
    setShowModal(false);
  };
 

  return (
    <React.Fragment>
      <Prompt
        when={followupInEditMode}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <div className="followupCard__cardAndError">
        <div className="followupCard">
          <div className="followupCard__top">
            <div className="followupCard__topTest">
              <div className="followupCard__topName">
                {!newScheduleCard ? (
                  <div className="followupCard__topTestDocName">
                    {!followupInEditMode ? (
                      <div>{followupForm["followupLabel"].value}</div>
                    ) : (
                      <div>
                        <FollowupScheduleSearchDropdown
                          value={
                            followupForm["followupLabel"] &&
                            followupForm["followupLabel"].value
                          }
                          setValue={(value) =>
                            updateFollowupForm(value, "followupLabel")
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <FollowupScheduleSearchDropdown
                      value={
                        followupForm["followupLabel"] &&
                        followupForm["followupLabel"].value
                      }
                      setValue={(value) =>
                        updateFollowupForm(value, "followupLabel")
                      }
                    />
                  </div>
                )}
                <div className="followupLabel__saveEditDelete">
                  {!followupInEditMode ? (
                    <CircularIcon
                      imgsrc={EditIcon}
                      alt="edit"
                      clicked={editFollowupFunc}
                      imgsize="20px"
                      size="38px"
                      disabled={props.isMonitoringApproved}
                      passedClass={`${props.isMonitoringApproved && "editingSavingFollowUp__disabled"}`}
                      
                      />
                      ) : (
                        <CircularIcon
                        passedClass={!formIsValid ? "formNotValid" : ""}
                        disabled={!formIsValid}
                        imgsrc={saveFileIcon}
                        alt="save"
                        clicked={editFollowupFunc}
                        imgsize="24px"
                        size="38px"
                        />
                        )}
                  <div className="">
                    <CircularIcon
                      imgsrc={DeleteIcon}
                      imgsize="30px"
                      clicked={() => setShowModal(true)}
                      disabled={props.isMonitoringApproved}
                      passedClass={`${props.isMonitoringApproved && "editingSavingFollowUp__disabled"}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="followupCard__topDateAndTime">
              <div className="followupCard__topDate">
                {followupInEditMode ? (
                
                  <div className="followupCard__topDateSelector">
                    <div
                      onClick={() => setCalendarIsVisible((prev) => !prev)}
                      className="followupCard__topDateSelectorText"
                    >
                      {followupForm["date"].value !=="-" ? followupForm["date"].value :"dd-mm-yyyy" }
                    </div>
                    <Calendar
                      className="follwupCard__topDateSelectorCalendar"
                      onValueChange={(e) => updateFollowupForm(e, "date")}
                      value={currentDateValue}
                      visible={calendarIsVisible}
                      min={new Date(dischargeDate)}
                      max = {new Date(newDateFromNumberFunc(dischargeDate, monitoringDuration))}
                    />
                  </div>
                ) : (
                  <div className="">
                    {stringToDateFunc(followupForm["date"].value) !== "-"  ? (
                      stringToDateFunc(followupForm["date"].value)
                    ) : (
                      <span className="info-message">Date Required</span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="followupCard__bottom">
            <div className="followupCard__bottomInstructions">Instructions</div>
            <div className="followupCard__bottomTypeOfInstruction">
              {followupInEditMode ? (
                <textarea
                  className="followupCard__textarea"
                  name="followupDetails"
                  value={followupForm["followupDetails"].value}
                  onChange={(event) =>
                    updateFollowupForm(event.target.value, "followupDetails")
                  }
                />
              ) : (
                <div>{followupForm["followupDetails"].value}</div>
              )}
            </div>
          </div>
        </div>
        {followupInEditMode && newScheduleCard && (
          <div className="error-message">Please complete the details.</div>
        )}
        {followupInEditMode && !formIsValid && !newScheduleCard && (
          <div className="error-message">Please complete the details.</div>
        )}
        {!followupInEditMode && !formIsValid && (
          <div className="error-message">Please complete missing details.</div>
        )}

        {showModal && (
          <div className="followupCard__modal">
            <ModalDelete
              cancel="Cancel"
              select="Confirm"
              messageHeading="Delete Followup"
              messageBody="Are you sure you want to Delete Followup ?"
              cancelNurseDelete={() => {
                cancelDeleteFollowupCard();
              }}
              confirmNurseDelete={() => {
                confirmDeleteFollowupCard();
              }}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    dateDurationFromStore: state.patient.dateDuration,
    isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,


  };
};
export default connect(mapStateToProps, null)(FollowupCard);
