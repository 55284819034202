import React, { useState, useEffect, useRef } from "react";
import "./MFPBody.css";
import InlineEditableComponent from "../InlineEditableComponent/InlineEditableComponent";
import {
    checkValidity,
    updateObject,
} from "../../../../HighOrderFunctions/shared/utility";
import { capitalizeFirstLetter } from "../../../../HighOrderFunctions/shared/capitalizeFirstLetter";
import { dayDifference } from "../../../../HighOrderFunctions/shared/dayDifference";
import ModalDelete from "../../../../modals/ModalDelete/ModalDelete";
import MFPFrequencyDropdown from "../MFPDropdown/MFPFrequencyDropdown/MFPFrequencyDropdown";
import MFPInstructionDropdown from "../MFPDropdown/MFPInstructionDropdown/MFPInstructionDropdown";
import saveFileIcon from "../../../../AllIcons/saveFileIcon.png";
import { SelectBox, Switch } from "devextreme-react";
import CircularIcon from "../../../../Components/CircularIcon/CircularIcon";
import { Prompt } from "react-router";
import deleteIcon from "../../../../AllIcons/deleteIcon.png";
import plusIconBlueBorder from "../../../../AllIcons/plusIconBlueBorder.png";
import { connect, useDispatch } from "react-redux";
import { showNotificationWithMessage } from "../../../../Store/actions";
import { setCreateReminders } from "../../../../Store/actions/MonitoringCapsule";
import TimePicker from "react-time-picker";
import CustomTimePicker from "../InlineEditTime/CustomTimePicker";
import InlineEditTime from "../InlineEditTime/InlineEditTime";

const MFPBody = (props) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const brandNameRef = useRef();
    const genericNameRef = useRef();
    const dosageRef = useRef();
    const timeRef = useRef();
    const startDayRef = useRef();
    const endDayRef = useRef();
    const patientEntityRef = useRef();
    const [isFrequencySOS, setIsFrequencySOS] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);
    const [buttonsClicked, setButtonsClicked] = useState(false);
    // console.log("buttonsClicked", buttonsClicked);

    const [selectedTimeSlots, setSelectedTimeSlot] = useState([]);
    const [previousTimeSlots, setPeviousTimeSlots] = useState([]);

    const [timeSlotsAfterModify, setTimeSlotsAfterModify] = useState([]);
    const [timeSlotId, setTimeSlotId] = useState("");
    const [isMedicineOnHold, setIsMedicineOnHold] = useState(null);
    // console.log("timeSlotId", timeSlotId);

    // console.log("frequencyData", props.frequencyData);

    //  initializing state containing medicine details of one medicine
    const [medicineForPatientDetails, setMedicineForPatientDetails] = useState({
        brandName: {
            value: "",
            valid: false,
            clicked: false,
            validations: {
                required: true,
            },
        },
        genericName: {
            value: "",
            valid: true,
            clicked: false,
            validations: {},
        },
        duration: {
            value: "",
            valid: true,
            clicked: false,
            validations: {},
        },
        startDay: {
            value: "",
            valid: false,
            clicked: false,
            validations: {
                required: true,
                isNumeric: true,
            },
        },
        endDay: {
            value: "",
            valid: false,
            clicked: false,
            validations: {
                required: true,
                isNumeric: true,
            },
        },

        dosage: {
            value: "",
            valid: false,
            clicked: false,
            validations: {
                required: true,
            },
        },
        frequency: {
            value: "",
            valid: false,
            clicked: false,
            validations: {
                required: true,
            },
        },

        instruction: {
            value: { text: "", value: "" },
            valid: false,
            clicked: false,
            validations: {
                isRequired: true,
            },
        },
        entity: {
            value: { symptomName: "", measureType: "" },
            valid: false,
            clicked: false,
            validations: {
                isRequired: true,
            },
        },
    });
    // console.log(medicineForPatientDetails.frequency.value);
    // To check the two are equal
    function arrayEquals(a, b) {
        return (
            Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index])
        );
    }
    // On changing the timeSlots , we are showing the save button
    useEffect(() => {
        let checkTimeSlotsAreEqaul = arrayEquals(
            selectedTimeSlots,
            previousTimeSlots
        );
        if (!checkTimeSlotsAreEqaul) {
            console.log("ButtonClicked");
            setButtonsClicked(true);
            // props.setCreateRemindersFunc(true);
        } else {
            setButtonsClicked(false);
            // props.setCreateRemindersFunc(false);
        }
    }, [selectedTimeSlots]);

    // running useEffect to update values in medicine at first rendering on mount
    useEffect(() => {
        //Storing the time slot
        if (props.timeSlots && Array.isArray(props.timeSlots)) {
            setSelectedTimeSlot([...props.timeSlots]);
            setPeviousTimeSlots([...props.timeSlots]);
        }
        //Storing the timeSlot id for Particular time slot time
        setTimeSlotId(props.timeSlotId);

        let tempMedicinePatientForm = { ...medicineForPatientDetails };
        // console.log("tempMedicinePatientForm", tempMedicinePatientForm);
        let tempCurrentMedicinePatientForm = { ...tempMedicinePatientForm };
        if (props.frequency == "SOS") {
            setIsFrequencySOS(true);
            tempCurrentMedicinePatientForm = {
                ...tempMedicinePatientForm,
                startDay: {
                    ...tempMedicinePatientForm["startDay"],
                    valid: true,
                    value: "",
                },
                endDay: {
                    ...tempMedicinePatientForm["endDay"],
                    valid: true,

                    value: "",
                },
                instruction: {
                    ...tempMedicinePatientForm["instruction"],
                    valid: true,

                    value: {
                        text: "",
                        value: "",
                    },
                },
                entity: {
                    ...tempMedicinePatientForm["entity"],
                    value: props.entity,
                    valid:
                        props.entity &&
                        checkValidity(
                            props.entity["symptomName"],
                            tempMedicinePatientForm["entity"].validations
                        ),
                },
                brandName: {
                    ...tempMedicinePatientForm["brandName"],
                    value: props.brandName,
                    valid: props.brandName
                        ? checkValidity(
                            props.brandName,
                            tempMedicinePatientForm["brandName"].validations
                        )
                        : false,
                },
                genericName: {
                    ...tempMedicinePatientForm["genericName"],
                    value: props.genericName,
                    valid: true,
                    // props.genericName &&
                    // checkValidity(
                    //   props.genericName,
                    //   tempMedicinePatientForm["genericName"].validations
                    // ),
                },
                dosage: {
                    ...tempMedicinePatientForm["dosage"],
                    value: props.dosage,
                    valid: props.dosage
                        ? checkValidity(
                            props.dosage,
                            tempMedicinePatientForm["dosage"].validations
                        )
                        : false,
                },
                frequency: {
                    ...tempMedicinePatientForm["frequency"],
                    value: props.frequency,
                    valid: props.frequency
                        ? checkValidity(
                            props.frequency,
                            tempMedicinePatientForm["frequency"].validations
                        )
                        : false,
                },

                duration: {
                    ...tempMedicinePatientForm["duration"],
                    value: props.duration,
                    valid: true,
                },
            };
        } else {
            setIsFrequencySOS(false);
            tempCurrentMedicinePatientForm = {
                ...tempMedicinePatientForm,
                brandName: {
                    ...tempMedicinePatientForm["brandName"],
                    value: props.brandName,
                    valid:
                        props.brandName &&
                        checkValidity(
                            props.brandName,
                            tempMedicinePatientForm["brandName"].validations
                        ),
                },
                genericName: {
                    ...tempMedicinePatientForm["genericName"],
                    value: capitalizeFirstLetter(props.genericName),
                    valid: true,
                    // props.genericName &&
                    // checkValidity(
                    //   props.genericName,
                    //   tempMedicinePatientForm["genericName"].validations
                    // ),
                },
                duration: {
                    ...tempMedicinePatientForm["duration"],
                    value: dayDifference(0, props.startDay, props.endDay),
                    valid: true,
                    // props.duration &&
                    // checkValidity(
                    //   props.duration,
                    //   tempMedicinePatientForm["duration"].validations
                    // ),
                },
                dosage: {
                    ...tempMedicinePatientForm["dosage"],
                    value: props.dosage,
                    valid:
                        props.dosage &&
                        checkValidity(
                            props.dosage,
                            tempMedicinePatientForm["dosage"].validations
                        ),
                },
                startDay: {
                    ...tempMedicinePatientForm["startDay"],
                    value: props.startDay,
                    valid:
                        props.startDay &&
                        checkValidity(
                            props.startDay,
                            tempMedicinePatientForm["startDay"].validations
                        ),
                },
                endDay: {
                    ...tempMedicinePatientForm["endDay"],
                    value: props.endDay,
                    valid:
                        props.endDay &&
                        checkValidity(
                            props.endDay,
                            tempMedicinePatientForm["endDay"].validations
                        ),
                },
                frequency: {
                    ...tempMedicinePatientForm["frequency"],
                    value: props.frequency,
                    valid:
                        props.frequency &&
                        checkValidity(
                            props.frequency,
                            tempMedicinePatientForm["frequency"].validations
                        ),
                },
                instruction: {
                    ...tempMedicinePatientForm["instruction"],
                    value: props.instruction,
                    valid: props.instruction["text"]
                        ? checkValidity(
                            props.instruction["text"],
                            tempMedicinePatientForm["instruction"].validations
                        )
                        : false,
                },

                entity: {
                    ...tempMedicinePatientForm["entity"],
                    valid: true,

                    value: {
                        symptomName: "",
                        measureType: "",
                    },
                },
            };
        }
        setMedicineForPatientDetails(tempCurrentMedicinePatientForm);

        let tempFormIsValid = true;
        for (let inputIdentifier in tempCurrentMedicinePatientForm) {
            tempFormIsValid =
                tempCurrentMedicinePatientForm[inputIdentifier].valid &&
                tempFormIsValid;
        }

        setFormIsValid(tempFormIsValid);
        props.checkMedicineFieldsValidated(
            props.index,
            tempFormIsValid,
            props.errorMessage && props.errorMessage.length > 0 ? true : false
        );
    }, []);

    // function to update Medicine Details whenever any details in row of a medicine changes
    const updateMedicineForPatientDetails = (value, inputIdentifier, index) => {
        // console.log(
        //   "updateMedicineForPatientDetails",
        //   value,
        //   inputIdentifier,
        //   index
        // );

        if (inputIdentifier === "frequency") {
            let timeSlotBasedOnFrequency =
                props.frequencyDataWithTimeSlot &&
                props.frequencyDataWithTimeSlot.filter(
                    (item) => item.schedule === value
                );

            setSelectedTimeSlot([...timeSlotBasedOnFrequency[0].time_slot]);
            setTimeSlotId(timeSlotBasedOnFrequency[0].time_slot_id);
            // console.log("timeSlotBasedOnFrequency", timeSlotBasedOnFrequency);
        }

        let valuePassed = value;
        let valueForValidityCheck = value;
        let durationForDayDifference = dayDifference(
            0,
            +medicineForPatientDetails["startDay"].value,
            +medicineForPatientDetails["endDay"].value
        );
        if (inputIdentifier == "brandName") {
            valuePassed = valuePassed.toString().toUpperCase();
        }
        if (inputIdentifier == "genericName") {
            valuePassed = capitalizeFirstLetter(valuePassed);
        }
        if (inputIdentifier == "durations") {
            valuePassed = valuePassed;
        }
        if (inputIdentifier == "dosage") {
            valuePassed = valuePassed;
        }
        if (inputIdentifier == "startDay") {
            if (Number.isInteger(+value)) {
                valuePassed = value;
                durationForDayDifference = dayDifference(
                    0,
                    +value,
                    +medicineForPatientDetails["endDay"].value
                );
            } else {
                valuePassed = medicineForPatientDetails["startDay"].value;
                valueForValidityCheck = medicineForPatientDetails["startDay"].value;
                durationForDayDifference = dayDifference(
                    0,
                    +medicineForPatientDetails["startDay"].value,
                    +medicineForPatientDetails["endDay"].value
                );
            }
        }
        if (inputIdentifier == "endDay") {
            if (Number.isInteger(+value)) {
                valuePassed = value;
                durationForDayDifference = dayDifference(
                    0,
                    +medicineForPatientDetails["startDay"].value,
                    +value
                );
            } else {
                valuePassed = medicineForPatientDetails["endDay"].value;
                valueForValidityCheck = medicineForPatientDetails["endDay"].value;
                durationForDayDifference = dayDifference(
                    0,
                    +medicineForPatientDetails["startDay"].value,
                    +medicineForPatientDetails["endDay"].value
                );
            }
        }
        if (inputIdentifier == "frequency") {
            valuePassed = valuePassed;
        }

        // if (inputIdentifier === "timeslot") {
        //   let timeSlots = selectedTimeSlots;
        //   timeSlots[index] = value;
        //   valuePassed = timeSlots;
        //   setSelectedTimeSlot([...timeSlots]);
        // }
        if (inputIdentifier == "instruction") {
            valuePassed = value;
            valueForValidityCheck = value["value"];
        }
        if (inputIdentifier == "entity") {
            valuePassed = value ? value : medicineForPatientDetails["entity"].value;
            valueForValidityCheck = value?.["symptomName"]
                ? value["symptomName"]
                : "";
        }

        const updatedFormElement = updateObject(
            medicineForPatientDetails[inputIdentifier],
            {
                value: valuePassed,
                clicked: true,
                valid: checkValidity(
                    valueForValidityCheck,
                    medicineForPatientDetails[inputIdentifier].validations
                ),
            }
        );
        let updatedmedicineForPatientDetails = updateObject(
            medicineForPatientDetails,
            {
                [inputIdentifier]: updatedFormElement,
                duration: {
                    ...medicineForPatientDetails["duration"],
                    value: durationForDayDifference,
                    valid: true,
                },
            }
        );

        //
        if (inputIdentifier == "frequency") {
            let tempUpdatedMedicineForPatientDetails = {
                ...updatedmedicineForPatientDetails,
            };

            if (value == "SOS") {
                setIsFrequencySOS(true);
                updatedmedicineForPatientDetails = {
                    ...tempUpdatedMedicineForPatientDetails,
                    startDay: {
                        ...tempUpdatedMedicineForPatientDetails["startDay"],
                        valid: true,
                        value: "",
                    },
                    endDay: {
                        ...tempUpdatedMedicineForPatientDetails["endDay"],
                        valid: true,
                        value: "",
                    },
                    instruction: {
                        ...tempUpdatedMedicineForPatientDetails["instruction"],
                        valid: true,
                        value: {
                            text: "",
                            value: "",
                        },
                    },
                    entity: {
                        ...tempUpdatedMedicineForPatientDetails["entity"],
                        valid: false,
                        value: {
                            symptomName: "",
                            measureType: "",
                        },
                    },
                };
            } else {
                setIsFrequencySOS(false);
                // handling instruction when earlier frequency was SOS. It's for converting instruction to false from true
                if (medicineForPatientDetails["frequency"].value == "SOS") {
                    updatedmedicineForPatientDetails = {
                        ...tempUpdatedMedicineForPatientDetails,
                        entity: {
                            ...tempUpdatedMedicineForPatientDetails["entity"],
                            valid: true,
                            value: {
                                symptomName: "",
                                measureType: "",
                            },
                        },
                        instruction: {
                            ...tempUpdatedMedicineForPatientDetails["instruction"],
                            valid: false,
                            value: {
                                text: "",
                                value: "",
                            },
                        },
                    };
                } else {
                    updatedmedicineForPatientDetails = {
                        ...tempUpdatedMedicineForPatientDetails,
                        entity: {
                            ...tempUpdatedMedicineForPatientDetails["entity"],
                            valid: true,
                            value: {
                                symptomName: "",
                                measureType: "",
                            },
                        },
                    };
                }
            }
        }
        let tempFormIsValid = true;
        let tempButtonsClicked = false;

        for (let inputIdentifier in updatedmedicineForPatientDetails) {
            tempFormIsValid =
                updatedmedicineForPatientDetails[inputIdentifier].valid &&
                tempFormIsValid;
            tempButtonsClicked =
                updatedmedicineForPatientDetails[inputIdentifier].clicked ||
                tempButtonsClicked;
        }

        // console.log(
        //   "updatedmedicineForPatientDetails",
        //   updatedmedicineForPatientDetails
        // );

        setMedicineForPatientDetails((prev) => {
            return { ...prev, ...updatedmedicineForPatientDetails };
        });

        // value, inputIdentifier
        // if (
        //   inputIdentifier === "frequency" &&
        //   value != "1-0-0" &&
        //   medicineForPatientDetails.instruction.value.value === "wake_up"
        // ) {
        //   setFormIsValid("");
        // } else if (
        //   inputIdentifier === "instruction" &&
        //   value.value === "wake_up" &&
        //   medicineForPatientDetails.frequency.value !== "1-0-0"
        // ) {
        //   setFormIsValid("");
        // } else {
        // }
        setFormIsValid(tempFormIsValid);

        console.log("tempFormIsValid", tempFormIsValid);
        // props.setCreateRemindersFunc(tempButtonsClicked);

        setButtonsClicked(tempButtonsClicked);
    };

    console.log("formIsValid", formIsValid);

    // const timeSlothandler = (value, inputIdentifier, index) => {
    //   let data = {
    //     value: "",
    //     valid: false,
    //     clicked: false,
    //     validations: {
    //       required: true,
    //     },
    //   };
    //   let timeSlots = selectedTimeSlots;
    //   timeSlots[index] = value;
    //   data.value = timeSlots;
    //   medicineForPatientDetails["timeSlots"] = data;
    //   setMedicineForPatientDetails((prev) => {
    //     return { ...prev, ...medicineForPatientDetails };
    //   });

    //   setSelectedTimeSlot([...timeSlots]);
    // };

    // console.log(
    //   "medicineForPatientDetails__aftertime",
    //   medicineForPatientDetails
    // );
    // functions to handle deletion of medicine using modal
    const deleteMedicine = () => {
        setShowModal(true);
    };
    const cancelMedicineDelete = () => {
        setShowModal(false);
    };

    const confirmMedicineDelete = () => {
        props.deleteMedicine(props.medicineId, props.index);
        setShowModal(false);
    };

    const confirmMedicineEnable = () => {
        props.enableMedicine(props.medicineId, props.index);
    };
    const confirmMedicineDisable = () => {
        props.disableMedicine(props.medicineId, props.index);
    };

    // function to save medicine to backend using api
    const saveMedicineToBackend = () => {
        if (
            formIsValid &&
            +medicineForPatientDetails["startDay"].value <=
            +medicineForPatientDetails["endDay"].value
        ) {
            //Sending the timeslots and timeslotid using data1 and data2
            let data1 = {
                value: "",
                valid: true,
                clicked: false,
                validations: {
                    required: true,
                },
            };
            let data2 = {
                value: "",
                valid: true,
                clicked: false,
                validations: {
                    required: true,
                },
            };

            data1.value = selectedTimeSlots;
            data2.value = timeSlotId;
            medicineForPatientDetails["timeSlots"] = data1;
            medicineForPatientDetails["timeSlotsId"] = data2;
            setMedicineForPatientDetails((prev) => {
                return { ...prev, ...medicineForPatientDetails };
            });

            // setMedicineForPatientDetails((prev) => {
            //   return { ...prev, ...medicineForPatientDetails };
            // });

            props.saveMedicine(
                medicineForPatientDetails,
                props.medicineId,
                props.index
            );
        } else {
            dispatch(
                showNotificationWithMessage({
                    variant: "warning",
                    message: "Day Start should be less than day End",
                })
            );
        }
    };

    const timeSlothandlerCallBack = (data) => {
        // console.log("timeSlothandlerCallBack", data);
        // setTimeSlotsAfterModify([...data]);
        setSelectedTimeSlot((prev) => [...data]);
    };

    //rendering the time slots

    const RenderingCustomTimePicker = ({
        data,
        selectedTimeSlots,
        setSelectedTimeSlot,
        index,
        timeSlothandlerCallBack,
    }) => {
        let time_slot = data.split(" ");
        // console.log("time_slot", time_slot);
        return (
            <CustomTimePicker
                time={time_slot[0]}
                selectedAmPm={time_slot[1]}
                selectedTimeSlots={selectedTimeSlots}
                setSelectedTimeSlot={setSelectedTimeSlot}
                index={index}
                timeSlothandlerCallBack={timeSlothandlerCallBack}
            />
        );
    };
    console.log("props:", props)
    console.log("buttonsClicked:", buttonsClicked)
    const TimeSchedules = ({ selectedTimeSlots, selectedtimeSlotHandler }) => {
        // console.log("selectedTimeSlots", selectedTimeSlots);
        return (
            <div className="medicineForPatient__timeSchedules">
                {selectedTimeSlots && (
                    <InlineEditTime
                        selectedTimeSlots={selectedTimeSlots}
                        selectedtimeSlotHandler={selectedtimeSlotHandler}
                    />
                )}
            </div>
        );
    };

    return (
        <React.Fragment>
            <Prompt
                when={buttonsClicked && !formIsValid}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <div
                className={`mfpBody__container
  
     ${props.keyForRenderingMedicine % 2 == 0 ? "mfpBody__containerWhite" : ""}
      `}
            >
                <div className="mfpBody__brandGenericDuration">
                    {props.showMedicine && (
                        <>
                            <div className="mfpBody__brandName">
                                <InlineEditableComponent
                                    type="input"
                                    childref={brandNameRef}
                                    text={medicineForPatientDetails["brandName"].value}
                                    placeholder="Brand-Name"
                                    valid={medicineForPatientDetails["brandName"].valid}
                                >
                                    <input
                                        type="text"
                                        className="medicineForPatient__medicineNameInput"
                                        ref={brandNameRef}
                                        value={medicineForPatientDetails["brandName"].value}
                                        onChange={(event) =>
                                            updateMedicineForPatientDetails(
                                                event.target.value,
                                                "brandName"
                                            )
                                        }
                                    />
                                </InlineEditableComponent>
                            </div>
                            <div className="mfpBody__genericName">
                                {" "}
                                <InlineEditableComponent
                                    type="input"
                                    childref={genericNameRef}
                                    text={capitalizeFirstLetter(
                                        medicineForPatientDetails["genericName"].value
                                    )}
                                    placeholder="Generic-Name"
                                    valid={medicineForPatientDetails["genericName"].valid}
                                >
                                    <input
                                        type="text"
                                        className="medicineForPatient__medicineNameInput"
                                        ref={genericNameRef}
                                        value={capitalizeFirstLetter(
                                            medicineForPatientDetails["genericName"].value
                                        )}
                                        onChange={(event) =>
                                            updateMedicineForPatientDetails(
                                                event.target.value,
                                                "genericName"
                                            )
                                        }
                                    />
                                </InlineEditableComponent>
                            </div>
                        </>
                    )}
                </div>
                <div className="mfpBody__dosage">
                    {" "}
                    <InlineEditableComponent
                        type="input"
                        childref={dosageRef}
                        text={medicineForPatientDetails["dosage"].value}
                        placeholder="Dosage"
                        valid={medicineForPatientDetails["dosage"].valid}
                    >
                        <input
                            type="text"
                            className="medicineForPatient__dosageInput"
                            ref={dosageRef}
                            value={medicineForPatientDetails["dosage"].value}
                            onChange={(event) =>
                                updateMedicineForPatientDetails(event.target.value, "dosage")
                            }
                        />
                    </InlineEditableComponent>
                </div>
                <div className="mfpBody__scheduling">
                    <div className="mfpBody__schedule">
                        {!isFrequencySOS && (
                            <>
                                <div className="mfpBody__scheduleStartDayEditText">
                                    <div className="mfpBody__scheduleStartDayText">D</div>
                                    <InlineEditableComponent
                                        type="input"
                                        childref={startDayRef}
                                        text={`${medicineForPatientDetails["startDay"].value}`}
                                        placeholder=""
                                        valid={medicineForPatientDetails["startDay"].valid}
                                    >
                                        <input
                                            type="text"
                                            className={`mfpBody__startDayInput ${medicineForPatientDetails["startDay"].value.length == 0
                                                ? "mfpBody__emptyStartDayInput"
                                                : ""
                                                }`}
                                            ref={startDayRef}
                                            value={medicineForPatientDetails["startDay"].value}
                                            onChange={(event) =>
                                                updateMedicineForPatientDetails(
                                                    event.target.value,
                                                    "startDay"
                                                )
                                            }
                                        />
                                    </InlineEditableComponent>
                                </div>
                                <div className="mfpBody__scheduleToText">to</div>
                                <div className="mfpBody__scheduleEndDayEditText">
                                    <div className="mfpBody__scheduleEndDayText">D</div>
                                    <InlineEditableComponent
                                        type="input"
                                        childref={endDayRef}
                                        text={`${medicineForPatientDetails["endDay"].value}`}
                                        placeholder=""
                                        valid={medicineForPatientDetails["endDay"].valid}
                                    >
                                        <input
                                            type="text"
                                            className="mfpBody__endDayInput"
                                            ref={endDayRef}
                                            value={medicineForPatientDetails["endDay"].value}
                                            onChange={(event) =>
                                                updateMedicineForPatientDetails(
                                                    event.target.value,
                                                    "endDay"
                                                )
                                            }
                                        />
                                    </InlineEditableComponent>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="mfpBody__scheduleDuration">
                        {props.showAddNewMedicine &&
                            props.mfpBodyRenderDataLength > 0 &&
                            !props.isMonitoringApproved && (
                                <button
                                    className={`mfpBody__addNewDataForMedicine ${!props.formForExistingMedicineIsValid
                                        ? "mfpBodyRender__showButtonBorder"
                                        : ""
                                        }`}
                                    onClick={() =>
                                        props.addNewExistingMedicineFunc(
                                            medicineForPatientDetails["brandName"].value,
                                            medicineForPatientDetails["genericName"].value
                                        )
                                    }
                                    disabled={!props.formForExistingMedicineIsValid}
                                >
                                    <img
                                        src={plusIconBlueBorder}
                                        alt="add"
                                        className={`${!props.formForExistingMedicineIsValid
                                            ? "mfpBodyRender__imgShowBorder"
                                            : ""
                                            }`}
                                    />
                                </button>
                            )}
                        <div className="mfpBody__duration">
                            {!isFrequencySOS && (
                                <span>{medicineForPatientDetails["duration"].value} days</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mfpBody__frequency">
                    <MFPFrequencyDropdown
                        frequencyData={props.frequencyData}
                        selectedValue={
                            medicineForPatientDetails["frequency"].value &&
                                medicineForPatientDetails["frequency"].value !== "None"
                                ? medicineForPatientDetails["frequency"].value
                                : ""
                        }
                        setSelectedValue={(value) =>
                            updateMedicineForPatientDetails(value, "frequency")
                        }
                        disabled={
                            props.medicineUpdateAfterApproved && props.isMonitoringApproved
                                ? false
                                : props.isMonitoringApproved
                        }
                    // disabled={props.isMonitoringApproved}
                    />
                    {medicineForPatientDetails.frequency.value !== "2 hourly" && (
                        <TimeSchedules
                            selectedTimeSlots={selectedTimeSlots}
                            selectedtimeSlotHandler={(val) => setSelectedTimeSlot(val)}
                        />
                    )}
                </div>
                <div className="mfpBody__instruction">
                    {!isFrequencySOS ? (
                        <MFPInstructionDropdown
                            instructionData={props.instructionData}
                            selectedValue={medicineForPatientDetails["instruction"].value}
                            setSelectedValue={(value) =>
                                updateMedicineForPatientDetails(value, "instruction")
                            }
                            disabled={
                                props.medicineUpdateAfterApproved && props.isMonitoringApproved
                                    ? false
                                    : props.isMonitoringApproved
                            }
                        />
                    ) : (
                        <div className="mfpBody__instructionSOS">
                            <div className="mfpBody__instructionSOSText">If patient has</div>

                            <InlineEditableComponent
                                type="input"
                                text={`${medicineForPatientDetails["entity"].value &&
                                    medicineForPatientDetails["entity"].value["symptomName"]
                                    }`}
                                placeholder=""
                                valid={medicineForPatientDetails["entity"].valid}
                            >
                                <SelectBox
                                    ref={patientEntityRef}
                                    className="searchDropdown__container"
                                    dataSource={props.clinicalEntitiesData}
                                    valueExpr="symptomName"
                                    displayExpr="symptomName"
                                    wrapItemText={true}
                                    searchEnabled={true}
                                    onValueChanged={({ component }) =>
                                        updateMedicineForPatientDetails(
                                            component.option("selectedItem"),
                                            "entity"
                                        )
                                    }
                                    defaultValue={
                                        medicineForPatientDetails["entity"].value["symptomName"]
                                    }
                                    width="100%"
                                    height="30px"
                                    itemRender={(item) => (
                                        <div classname="mfpBody__symptomRender">
                                            {item["symptomName"]}
                                        </div>
                                    )}
                                />
                            </InlineEditableComponent>
                        </div>
                    )}
                    <div>
                        <Switch defaultValue={props.is_enabled} switchedOffText="On Hold" switchedOnText="Active" width={70}  disabled={
                            props.medicineUpdateAfterApproved && props.isMonitoringApproved
                                ? false
                                : props.isMonitoringApproved
                        } onValueChanged={(e) => {
                            console.log(e);
                            if (e.value === false) {
                                confirmMedicineDisable();
                            }
                            else {
                                confirmMedicineEnable()
                            }
                            setIsMedicineOnHold(e.value)
                        }}>
                        </Switch>
                    </div>
                </div>
                <div className="mfpBody__saveMedicine">
                    {(buttonsClicked ||
                        (props.errorMessage && props.errorMessage?.length > 0)) && (
                            <CircularIcon
                                passedClass={!formIsValid ? "formNotValid" : ""}
                                disabled={!formIsValid}
                                imgsrc={saveFileIcon}
                                alt="save"
                                clicked={saveMedicineToBackend}
                                imgsize="20px"
                                size="28px"
                                bgColor="transparent"
                            />
                        )}

                </div>
                {props.isMonitoringApproved}

                {props.isMonitoringApproved===false && <button
                    className={`mfpBody__deleteMedicineButtonNotClicked ${(buttonsClicked ||
                        (props.errorMessage && props.errorMessage?.length > 0)) &&
                        "mfpBody__deleteMedicine"
                        }`}
                    onClick={deleteMedicine}
                >
                    <img
                        src={deleteIcon}
                        alt="delete"
                        style={{ width: "28px", backgroundColor: "transparent" }}
                    />
                </button>}
                {showModal && (
                    <div
                        className={`mfpBody__modal ${props.keyForRenderingMedicine == 1 && "mfpBody__firstElementModal"
                            }`}
                    >
                        <ModalDelete
                            cancel="Cancel"
                            select="Confirm"
                            messageHeading="Delete Medicine"
                            messageBody={`Are you sure you want to Delete this Medicine ? `}
                            newRowElement={`(${medicineForPatientDetails["brandName"].value}) `}
                            cancelNurseDelete={cancelMedicineDelete}
                            confirmNurseDelete={confirmMedicineDelete}
                        />
                    </div>
                )}
                <div className="error-message mfpBody__errorMessagePosition ">
                    {props.errorMessage}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,
        medicineUpdateAfterApproved:
            state.monitoringCapsule.medicineUpdateAfterApproved,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCreateRemindersFunc: (data) => dispatch(setCreateReminders(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MFPBody);
