import React, { useState, useEffect } from "react";
import classes from "./Card.module.css";
import { connect } from "react-redux";
import okTickApproval from "../../AllIcons/okTickApproval.png";
const Card = (props) => {
  const [navContains, setNavContains] = useState(props.navContains);
  let cardContainerClass = [classes.card__container];
  let hashPath = window.location.hash.toString().split("/")[2];
  if (hashPath == props.navContains) {
    cardContainerClass.push(classes.card__active);
  }

  let cardNumberFromStore = props.cardNumber;
  useEffect(() => {}, [hashPath]);
  return (
    <div className={cardContainerClass.join(" ")}>
      <div className={classes.card__icon}>
        <img
          src={`${hashPath == props.navContains ? props.activeSrc : props.SRC}`}
          alt="card"
        />
      </div>
      <div className={classes.card__font}>{props.name}</div>
      {props.approved && (
        <div className={classes.cardContainerClass__approveStatus}>
          <img src={okTickApproval} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCardNumber: state.patient.currentCardNumber,
  };
};
export default connect(mapStateToProps, null)(Card);
