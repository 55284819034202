import React, { Fragment } from "react";

import List from "devextreme-react/list.js";
import { navigation } from "./data.js";
import "./NavigationList.css";
import {useHistory} from "react-router-dom";

const NavigationList =  (props) => {
    const history = useHistory();

  const handleClick = (e) => {
    props.setRoute(e.itemData.route);
    history.push(e.itemData.route);
  };
  return (
    <Fragment>
      <div className="list drawer__navigationList" >
        <List
          dataSource={navigation}
          selectionMode="none"
          hoverStateEnabled={true}
          activeStateEnabled={true}
          focusStateEnabled={true}
          // elementAttr={{
          //   class: "panel-list dx-theme-accent-as-background-color",
          // }}
          displayExpr="text"
          onItemClick={handleClick}
        />
      </div>
    </Fragment>
  );
};

export default NavigationList;
