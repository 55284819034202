import React from "react";
import CircularIcon from "../../CircularIcon/CircularIcon";
import "./ToggleButton.css";
const ToggleButton = (props) => {
  return (
    <div className="toggleButton" onClick={props.clicked}
    disabled={props.disabled}
    >
      {!props.active ? (
        <CircularIcon
        disabled={props.disabled}
        imgsrc={props.saveIcon}
        imgsize={`${props.okImgSize ?  props.okImgSize : "30px"}`}
        className="CloseIcon"
        clicked={props.editButtonClickedFunc}
        passedClass={`${props.dontShowPassedClass ? "":"toggleButton__container"}`}
        
        />
        ) : (
          <CircularIcon
          disabled={props.disabled}
          imgsize="20px"
          imgsrc={props.editIcon}
          className="CloseIcon"
          clicked={ props.editButtonClickedFunc}
          passedClass="toggleButton__container"
        />
      )}
    </div>
  );
};

export default ToggleButton;
