import React, { useState, useEffect } from "react";
import medicalActiveIcon from "../../../AllIcons/medicalActive.png";
import CircularIcon from "../../../Components/CircularIcon/CircularIcon";
import * as actions from "../../../Store/actions/index";
import { useHistory } from "react-router-dom";
import { stringToDateFunc } from "../../../HighOrderFunctions/stringToDateFunc";
import { connect } from "react-redux";
import { ageModifier } from "../../../HighOrderFunctions/ageModifier";
import {getPatientDetailsEndPoint} from '../../../HighOrderFunctions/APIs/allGetAPI'
import "./NavbarPatientDetails.css";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
const NavbarPatientDetails = (props) => {
 
  const history = useHistory();
  const {response:patientData,isLoading} = useFetch(`${getPatientDetailsEndPoint}/${props.qrCodeFromStore}`)
  if(Array.isArray(patientData) && patientData.length > 0){
    props.setPatientDetailsToStore(patientData);
    props.setPrimaryContactNumberFunc(patientData[0]["contacts"][0]);
  }

 
  const getBackTodashboardPageFunc = () => {
    props.resetPatientStore();

    history.push("/dashboard");
  };


  return (
    <div className="navbarPatientDetails__singlePatientDetails">
      <div className="navbarPatientDetails__container">
        <div className="navbarPatientDetails__medicalHistoryIcon">
          <CircularIcon imgsrc={medicalActiveIcon} imgsize="30px" size="50px" />
        </div>
        <div className="navbarPatientDetails__details1">
          <div className="patientAlign">
            <div className="navbarPatientDetailsNameHeader__name">
              Full Name:{" "}
            </div>
            <div className="navbarPatientDetailsNameBody__fullName">
              <p>{patientData[0]?.patientName}</p>
            </div>
          </div>

          <div className="patientAlign">
            <div className="navbarPatientDetailsNameHeader__dob">Age: </div>
            <div className="navbarPatientDetailsNameBody__dob">
              <p>{ageModifier(patientData[0]?.age)}</p>
            </div>
          </div>
          <div className="patientAlign">
            <div className="navbarPatientDetailsNameHeader__mrn">MRN: </div>
            <div className="navbarPatientDetailsNameBody__mrn">
              {patientData[0]?.hospitalId}
            </div>
          </div>
          {/* <div className="patientAlign">
            <div className="navbarPatientDetailsNameHeader__contactNumber">
              Contact:
            </div>
            <div className="navbarPatientDetailsNameBody__contactNumber">
              {patientData[0]?.["contacts"][0]}
            </div>
          </div> */}
        </div>
        <div className="navbarPatientDetails__details2">
          <div className="patientAlign">
            <div className="navbarPatientDetailsNameHeader__dischargeContext">
              Discharge Context:
            </div>
            <div className="navbarPatientDetailsNameBody__dischargeContext">
              {patientData[0]?.dischargeContext}
            </div>
          </div>
          <div className="patientAlign">
            <div className="navbarPatientDetailsNameHeader__medicalDevices">
              Medical Devices:
            </div>
            <div className="navbarPatientDetailsNameBody__medicalDevices">
            {Array.isArray(patientData[0]?.medicalDevices) && patientData[0]?.medicalDevices.map((item,index)=>{
              if(index ==0){
                return <span key={index}>{item}</span>  
              }else{
                return <span key={index}> , {item} </span>  
                 
              }
            })
          }
            </div>
          </div>

          <div className="patientAlign">
            <div className="navbarPatientDetailsNameHeader__medicalDevices">
              Monitoring Duration:
            </div>
            <div className="navbarPatientDetailsNameBody__medicalDevices">
              {patientData[0]?.daysToMonitor}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPatientStore: () => dispatch(actions.resetPatientState()),
    setPrimaryContactNumberFunc: (number) =>
      dispatch(actions.setPrimaryContactNumber(number)),
    setPatientDetailsToStore: (details) =>
      dispatch(actions.setPatientDetails(details)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarPatientDetails);
