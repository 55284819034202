export function dischargeSinceDate (second,minute,hour,day,month=0,year=0){
    if(year===0 && month ===0 && day===0 && hour===0 && minute ===0){
        return <span>{second} seconds ago</span>
    }
    if(year === 0 && month === 0 && day ===0 && hour === 0 && second !== 0 ){
        return <span>{minute} minutes ago</span>
    }else if(year === 0 && month ===0 && day ===0){
       return <span>{hour}:{minute} hours ago</span>
    }else if(year ===0 && month === 0){
        return <span>{day} days ago</span>
    } else if(year === 0 ){
        return <span>{month} months ago</span>
    }else if(year && month ===0){
        return <span>{year} year ago</span>
    }else{
        return <span>{year} years, {month} months ago</span>
    }
}

export function dischargeSinceDateMonitoring (second,minute,hour,day,month=5,year=5){
    if(year===0 && month ===0 && day===0 && hour===0 && minute ===0){
        return `${second} seconds ago`
    }
    if(year === 0 && month === 0 && day ===0 && hour === 0 ){
        return `${minute} ago`
    }else if(year === 0 && month ===0 && day ===0){
       return `${hour} hours ${minute} minutes ago`
    }else if(year ===0 && month === 0){
        return `${day} days ago`
    } else if(year === 0 ){
        return `${month} months ago`
    }else if(year && month ===0){
        return `${year} year ago`
    }else{
        return `${year} years, ${month} months ago`
    }
}



