import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import "./MSLastRecordedVitals.css";
import { getLastVitalsEndPoint } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";

const MSLastRecordedVitals = (props) => {

// function to get last recoded vitals (temperature,oxygenSaturation,bloodPressure etc.)
 const {response:lastRecordedVitals,isLoading} = useFetch( `${getLastVitalsEndPoint}/${props.qrCodeFromStore}`)
  return (
    <div className="msLastRecorderVitals__container">
      <div className="msLastRecorderVitals__Heading">
        <div>Last Recorded Vitals</div>
      </div>
      <div className="msLastRecorderVitals__Body">
        <div className="msLastRecorderVitals__bodyFirstLine">
          <div className="patientAlign">
            <div className="msLastRecorderVitals__temperatureHeader">
              Temperature:
            </div>
            <div className="msLastRecorderVitals__temperatureBody">
              <div>{lastRecordedVitals["temperature"]} </div>
            </div>
          </div>
          <div className="patientAlign">
            <div className="msLastRecorderVitals__oxygenSaturationHeader">
              Oxygen Saturation:
            </div>
            <div className="msLastRecorderVitals__oxygenSaturationBody">
              {lastRecordedVitals["oxygen_saturation"]}
            </div>
          </div>

          <div className="patientAlign paddingLeft">
            <div className="msLastRecorderVitals__bloodPressureHeader">
              Blood Pressure:
            </div>
            <div className="msLastRecorderVitals__bloodPressureBody">
              {lastRecordedVitals["blood_pressure"]}
            </div>
          </div>
        </div>

        <div className="msLastRecorderVitals__bodySecondLine">
          <div className="patientAlign">
            <div className="msLastRecorderVitals__respiratoryRateHeader">
              Blood Glucose:
            </div>
            <div className="msLastRecorderVitals__respiratoryRateBody">
              {lastRecordedVitals["fasting_blood_glucose"]}
            </div>
          </div>
          <div className="patientAlign pulseRateAlignment">
            <div className="msLastRecorderVitals__pulseHeader">Pulse: </div>
            <div className="msLastRecorderVitals__pulseBody">
              <p>{lastRecordedVitals["heart_rate"]}</p>
            </div>
          </div>
          <div className="patientAlign">
            <div className="msLastRecorderVitals__blankHeader"> </div>
            <div className="msLastRecorderVitals__blankBody">
              <p>{}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="msLastRecorderVitals__textBottom"> */}
      {/* <p style={{display:"hidden"}}> Regular Compliance to Vitals Monitoring</p> */}
      {/* <p> </p> */}
      {/* </div> */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(MSLastRecordedVitals);
