import React from "react";
import "./CircularIcon.css";
const CircularIcon = (props) => {
    return (
        <>
            <button
                className={`circularIcon ${props.call ? "callstatus" : ""} 
      ${props.disabled && "circularIcon__disabled"}
      ${props.passedClass && `${props.passedClass}`}
      `}
                style={{
                    backgroundColor: props.bgcolor,
                    width: props.size,
                    height: props.size,
                    borderRadius: `${props.size / 2} !important`,
                    // border:props.border
                }}
                disabled={props.disabled}
                onClick={props.clicked}
            >
                {props.imgsrc && (
                    <img
                        className={props.rotateImgY ? "rotateImage" : ""}
                        title={props.title}
                        style={{ height: props.imgsize, objectFit: "contain", backgroundColor: props.imgbgcolor }}
                        src={props.imgsrc}
                        // {...props}
                        alt="icon"
                        disabled={props.disabled}

                    />
                )}
            </button>
            <div  className="circularIconWTextBelow__textBottom" style={{marginTop:"3px"}}>{props.text}</div>
        </>

    );
};

export default CircularIcon;