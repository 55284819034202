import React, { useState, useEffect } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import axios from "axios";

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};

  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }

  // const params = Object.keys(data).map((key) => {
  //   return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
  // }).join('&');

  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
const ClinicalSuggestions = () => {
  const [searchText, setSearchText] = useState("");
  const [allClinicalEntity, setAllClinicalEntity] = useState([]);
  const fetchClinicalData = async () => {
    axios
      .get(`${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity/all`)
      .then((responseArray) => {
        setAllClinicalEntity(responseArray.data);
      });
  };
  useEffect(() => {
    fetchClinicalData();
  }, []);
  const store = new CustomStore({
    key: "clinical_entity_suggestions_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity-suggestions?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          return {
            data: data.data,
            totalCount: data.totalCount,
            // summary: data.summary,
            // groupCount: data.groupCount,
            // {data:[{},{}],
            // totalCount:10}
          };
        })
        .catch((error) => {
          throw "Data Loading Error";
        });
    },
    insert: (values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity-suggestions`,
        "POST",
        values
      ),
    update: (key, values) => {
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity-suggestions/${key}`,
        "PUT",
        values
      ).catch((error) => {
        console.log("Error Received", error);
        throw "Data Loading Error";
      });
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity-suggestions/${key}`,
        "DELETE"
      ),
  });
  const calculateCellValueFunc = (data) => {
    if (data.sns_entity_measure_type == 1) {
      return "NUMBER_SCALE";
    } else if (data.sns_entity_measure_type == 2) {
      return "BINARY";
    } else if (data.sns_entity_measure_type == 3) {
      return "THREE_POINT_SCALE";
    }
  };
  const onOptionChanged = (e) => {
    console.log("EVent on option changed: ", e);
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  return (
    <DataGrid
      // width={"50%"}
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnResizing={true}
      allowColumnReordering={false}
      sorting={false}
      onOptionChanged={onOptionChanged}
    >
      <SearchPanel visible={true} text={searchText} />

      <Editing
        refreshMode="reshape"
        mode="popup"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />

      <Column
        width={40}
        dataField="clinical_entity_suggestions_id"
        dataType="number"
        caption="id"
        allowEditing={false}
      ></Column>

      <Column
        dataField="entity_name"
        dataType="string"
        caption="Clinical Entity"
        allowEditing={true}
      >
        <Lookup
          dataSource={allClinicalEntity}
          displayExpr="entity_name"
          valueExpr="entity_name"
        />
      </Column>
      <Column dataField="remedy_suggestion_subtopic" dataType="string"></Column>
      <Column
        dataField="remedy_suggestion_utterance"
        dataType="string"
      ></Column>

      <Paging defaultPageSize={15} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[15, 20, 25]} />
    </DataGrid>
  );
};
// calculateCellValue={calculateCellValueFunc}
export default ClinicalSuggestions;
