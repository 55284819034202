import React, { useState, Fragment, useEffect } from "react";
import CustomTimePicker from "./CustomTimePicker";
import { connect, useSelector } from "react-redux";
import { setUpdatedMedicineAfterApproved } from "../../../../Store/actions/MonitoringCapsule";
import { useDispatch } from "react-redux";

const InlineEditTime = (props) => {
  const dispatch = useDispatch();
  // console.log("selectedTimeSlots", props.selectedTimeSlots);
  // const [timeList, setTimeList] = useState(["8:00 am", "10:00 am", "12:00 pm"]);
  const [timeList, setTimeList] = useState([...props.selectedTimeSlots]);

  useEffect(() => {
    // console.log("timeList", timeList);
    // selectedtimeSlotHandler(timeList);
  }, [timeList]);

  useEffect(() => {
    if (!props.isMonitoringApproved) {
      props.setUpdatedMedicineAfterApprovedFunc(false);
    }
  }, []);
  // console.log("timeList", timeList);

  const RenderTimePicker = ({
    item,
    index,
    timeList,
    onTimeListHandler,
    selectedtimeSlotHandler,
  }) => {
    let Time = "";
    if (item) {
      Time = item.split(" ");
    }

    if (!props.isMonitoringApproved && !props.medicineUpdateAfterApproved) {
      return (
        <div style={{ marginRight: "5px" }}>
          {item ? (
            <CustomTimePicker
              time={item ? Time[0] : "hh:mm"}
              selectedAmPm={item ? Time[1] : ""}
              index={index}
              timeList={timeList}
              onTimeListHandler={onTimeListHandler}
              selectedtimeSlotHandler={selectedtimeSlotHandler}
            />
          ) : (
            <button
              disabled={true}
              style={{ fontSize: "12px", width: "72px", color: "black" }}
            >
              hh:mm
            </button>
          )}
        </div>
      );
    } else if (
      props.isMonitoringApproved &&
      props.medicineUpdateAfterApproved
    ) {
      return (
        <div style={{ marginRight: "5px" }}>
          {item ? (
            <CustomTimePicker
              time={item ? Time[0] : "hh:mm"}
              selectedAmPm={item ? Time[1] : ""}
              index={index}
              timeList={timeList}
              onTimeListHandler={onTimeListHandler}
              selectedtimeSlotHandler={selectedtimeSlotHandler}
            />
          ) : (
            <button disabled={true} style={{ fontSize: "12px", width: "72px" }}>
              hh:mm
            </button>
          )}
        </div>
      );
    } else {
      let time = item ? `${Time[0]} ${Time[1]}` : "hh:mm";
      return (
        <button
          style={{ marginRight: "5px", fontSize: "12px", width: "72px" }}
          disabled={true}
        >
          {time}
        </button>
      );
    }
  };

  return (
    <Fragment>
      {timeList.map((item, index) => {
        return (
          <RenderTimePicker
            item={item}
            timeList={timeList}
            index={index}
            onTimeListHandler={(val) => setTimeList(val)}
            selectedtimeSlotHandler={props.selectedtimeSlotHandler}
          />
        );
      })}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    fetchMedicine: state.patient.fetchMedicine,
    isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,
    medicineUpdateAfterApproved:
      state.monitoringCapsule.medicineUpdateAfterApproved,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUpdatedMedicineAfterApprovedFunc: (status) =>
      dispatch(setUpdatedMedicineAfterApproved(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InlineEditTime);
