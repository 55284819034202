import React, { useState } from "react";
import "./DischargeContextUpload.css";
import DischargeContextSearchDropdown from "../../../Components/screensComponent/SearchDropdown/dischargeContextSearchDropdown";
import NewDischargeHeader from "../NewDischargeHeader/NewDischargeHeader";
import ModalCustomButton from "../../../Components/Buttons/ModalCustomButton/ModalCustomButton";
import { useHistory } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { setDischargeContextFunc } from "../../../Store/actions";
import InputCard from "../../../Components/Card/InputCard/InputCard";
import { showNotificationWithMessage } from "../../../Store/actions";
const DischargeContextUpload = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [monitoringDuration,setMonitoringDuration] = useState("");
  const [dischargeContext, setDischargeContext] = useState({});
  const updateSelectedDischargeContext = (chosenDischargeContext) =>{
    setMonitoringDuration(+chosenDischargeContext["days_to_monitor"]);
  
    setDischargeContext(chosenDischargeContext);
    dispatch(setDischargeContextFunc(chosenDischargeContext));
  }

  const updateMonitoringDuration = (duration)=>{
    // alert(duration)
   let tempDischargeContext = {...dischargeContext};
   console.log(tempDischargeContext)
   
   if(Object.keys(tempDischargeContext).length > 0){
     if(Number.isInteger(+duration) ){

       tempDischargeContext["days_to_monitor"] = +duration;
       updateSelectedDischargeContext(tempDischargeContext);
      }else{
        dispatch(
          showNotificationWithMessage({
            variant: "warning",
            message: "Please enter valid duration",
          })
        );
      }

   }

  }
 
  const uploadFileAndDischargeContext = () => {
    history.push("/ds-upload/readingThroughSummary")
  };
  const navigateBackToUploadFileScreen = () => {
    history.push("/ds-upload/fileUpload")
  };

  // function to navigate to next screen for reading through discharge summary
  const takeActionOnBasisOfDischargeContext = () =>{
    
    if(Object.keys(dischargeContext).length > 0 && +monitoringDuration> 0){
      uploadFileAndDischargeContext();
    }
    else{
      navigateBackToUploadFileScreen()
    }
  }
  return (
    <div className="dischargeContextUpload__container">
      <div className="dischargeContextUpload__newDischargeHeader">
        <NewDischargeHeader />
      </div>
      <div className="dischargeContextUpload__uploadProcess">
        <div className="dischargeContextUpload__uploadProcessText">
          Complete Upload Process
        </div>
        <div className="dischargeContextUpload__dischargeDropdown">
          <div className="dischargeContextUpload__monitoringDurationText">
            Monitoring Days
          </div>
          <div className="dischargeContextUpload__monitoringDurationInput">
           <InputCard 
              className="patientHospitalDetails__patientContactInput"
              placeHolder="Days"
              width="30%"
              bgcolor="#FFFFFF"
              value={monitoringDuration}
              disabled={monitoringDuration.toString().length ==0}
              onChange={(val) =>updateMonitoringDuration(val)}
              // touchedNotValid={!patientHospitalDetails["contactNumber"]["valid"]} 
              />
          </div>
          <div className="dischargeContextUpload__dischargeDropdownText">
            Discharge Context
          </div>
          <div className="dischargeContextUpload__dropdown">
            <DischargeContextSearchDropdown
              value={dischargeContext}
              setValue={updateSelectedDischargeContext}
            />
          </div>
          <div className="dischargeContextUpload__submit">
            <ModalCustomButton
             status={true}
              name={` ${(Object.keys(dischargeContext).length>0  && +monitoringDuration> 0) ? "Next" : "Back"}`}
              clicked={ takeActionOnBasisOfDischargeContext
            }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DischargeContextUpload;
