import React, { useState, useEffect } from "react";
import DUMHeader from "../../Components/screensComponent/DischargeUnderMonitoring/DUMHeader/DUMHeader";
import DUMNameHeader from "../../Components/screensComponent/DischargeUnderMonitoring/DUMNameHeader/DUMNameHeader";
import DUMNameBody from "../../Components/screensComponent/DischargeUnderMonitoring/DUMNameBody/DUMNameBody";
import {  useSelector } from "react-redux";
import { getFetchFunction } from "../../HighOrderFunctions/FetchMethod/getFetchFunction";
import "./DischargeUnderMonitoring.css";
import Loader from "../../Loader/LoaderThreeDot";
import { dynamicSortDesc } from "../../HighOrderFunctions/dynamicSort";
import ErrorModal from "../../modals/ErrorModal/ErrorModal";
import {
  stringToDateFunc,
} from "../../HighOrderFunctions/stringToDateFunc";
import { dischargeSinceDateMonitoring } from "../../HighOrderFunctions/dischargeSinceDate";

import { dischargeUnderMonitoringEndPoint } from "../../HighOrderFunctions/APIs/allGetAPI";
const DischargeUnderMonitoring = (props) => {
  const searchedValue = useSelector((state) => state.dashboard.searchedInput);
  const token = useSelector(state=>state.auth.accessToken);

  const [fetchedData, setFetchedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorInFetching, setErrorInFetching] = useState(false);

  const [dashboardOnlineStatus, setDashboardOnlineStatus] = useState(
    navigator.onLine
  );
 
  // these are the list of parameters on basis of which we can search patients list
  const [searchColumns, setSearchColumns] = useState([
    "name",
    "qr_code",
    "discharge_date",
    "diagnosis",
    "discharge_status",
    "department",
    "hospital_id",
    "age",
    "gender",
    "time_since_discharge",
  ]);
  const [q, setQ] = useState(searchedValue ? searchedValue : "");

 

 
  const fetchedDataFunc = async () => {
    setIsLoading(true);

    let fetchedData = await getFetchFunction(dischargeUnderMonitoringEndPoint,token);
      setIsLoading(false);
      setErrorInFetching(true);
    if (Array.isArray(fetchedData) == true) {
      if (fetchedData.length > 0) {
        setIsLoading(false);
      }
      let sortedData = fetchedData.sort(dynamicSortDesc("discharge_date"));
      let tempSortedData = sortedData.map((item) => {
        let timeSincePatientDischarge = "";
        if (item["time_since_discharge"]) {
          let { seconds, minutes, hours, days, months, years } =
            item["time_since_discharge"];
          timeSincePatientDischarge = dischargeSinceDateMonitoring(
            seconds,
            minutes,
            hours,
            days,
            months,
            years
          );
        }
        return {
          ...item,
          ["discharge_date"]: stringToDateFunc(item["discharge_date"]),
          ["time_since_discharge"]: timeSincePatientDischarge
            ? timeSincePatientDischarge
            : "",
        };
      });
      setFetchedData(tempSortedData);
    }
  };

  useEffect(() => {
    fetchedDataFunc();
    return ()=>{
      setFetchedData([])
    }
  }, []);


// function to filter rows of list of patients on basis of allowed searchable parameters assigned above
  function search(rows) {
    let columns = [];

    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] &&
          row[column]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("-", "")
            .indexOf(q.toLowerCase().replaceAll(" ", "").replaceAll("-", "")) >
            -1
      )
    );
  }
  const setInputValueFunc = (e) => {
    setQ(e.target.value);
  };


  // rendering list of all patients existing in database
  let renderedData = null;
  renderedData =
    fetchedData &&
    search(fetchedData).map((item, index) => {
      return (
        <DUMNameBody
          key={index}
          name={item["name"]}
          dischargeDate={item["discharge_date"]}
          dischargeDiagnosis={item["diagnosis"]}
          department={item["department"]}
          qrCode={item["qr_code"]}
          rowNumber={index}
          dischargeStatus={item["discharge_status"]}
          age={item["age"]}
          gender={item["gender"]}
          hospitalId={item["hospital_id"]}
          timeSinceDischarge={item["time_since_discharge"]}
        />
      );
    });
  if (!dashboardOnlineStatus) {
    return (
      <ErrorModal
        setOnlineStatus={setDashboardOnlineStatus}
        message="Please check your Network Connection !!"
      />
    );
  }
  return (
    <div className="dischargeUnderMonitoring__container">
      <div className="dischargeUnderMonitoring__header">
        <DUMHeader setInputFunc={setInputValueFunc} value={q} />
      </div>
      <div className="dischargeUnderMonitoring__nameHeader">
        <DUMNameHeader />
      </div>
      <div className="dischargeUnderMonitoring__nameBody">
        {isLoading ? (
          <div className="loaderbar">
            {" "}
            <Loader />{" "}
          </div>
        ) : (
          <div>{renderedData}</div>
        )}
      </div>
    </div>
  );
};



export default (DischargeUnderMonitoring);
