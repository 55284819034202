import React from 'react'
import './CircularButtonWithTextBelow.css';
import CircularIcon from '../../CircularIcon/CircularIcon';
const CircularButtonWithTextBelow = (props) => {
    return (
        <div className="circularButtonWithTextBelow__container">
            <div className="circularButtonWithTextBelow__icon">
            <CircularIcon imgsrc={props.imgSrc}
            bgcolor="#F6F6F6"
            clicked={props.clicked}
            />
            </div>
            <div className="circularButtonWithTextBelow__text">
                {props.name}
            </div>
        </div>
    )
}

export default CircularButtonWithTextBelow
