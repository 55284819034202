import React, { useState, useRef } from "react";
import EditIcon from "../../../../../../../AllIcons/editIcon.png";
import ok from "../../../../../../../AllIcons/ok.png";
import EditSaveButton from "../../../../../../Buttons/ToggleButton/ToggleButton";
import * as actions from "../../../../../../../Store/actions/index";
import { connect, useSelector } from "react-redux";
import Editable from "../../../../../../../HighOrderFunctions/Editable/Editable";
import AlertThresholdNewCardList from "../../../../../SearchDropdown/SymptomsListDropdown";
import CircularIcon from "../../../../../../CircularIcon/CircularIcon";
import "./AlertThresholdMainContainerTop.css";
import crossWhiteIcon from "../../../../../../../AllIcons/cross.png";
import minimizeIcon from "../../../../../../../AllIcons/minimize-icon.png";
import DeleteIcon from "../../../../../../../AllIcons/deleteIcon.png";
import ModalDelete from "../../../../../../../modals/ModalDelete/ModalDelete";
import { capitalizeFirstLetter } from "../../../../../../../HighOrderFunctions/shared/capitalizeFirstLetter";

const AlertThresholdMainContainerTop = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [showModal, setShowModal] = useState(false);
  const symptomNameRef = useRef();
  let symptomValue = {
    symptomName: props.symptomName,
    measureType: "",
  };
  const getAllIdsAndDelete = () => {
    props.deleteSymptomFromList(props.alertConditions, props.symptomIndex);
  };
  const deleteAlertThrehsoldSymptom = () => {
    setShowModal(true);
  };
  const confirmDeleteAlertThresholdSymptom = () => {
    getAllIdsAndDelete();
    setShowModal(false);
  };
  const cancelDeleteAlertThrehsoldSymptom = () => {
    setShowModal(false);
  };

  return (
    <div className="alertThresholdMainContainerTop">
      <div className="alertThresholdMainContainerTop__leftPart">
        <div
          className={`alertCondtionName ${
            props.isAvailable
              ? ""
              : props.newCard
              ? "newCard"
              : "alertConditionNameHighlight"
          }`}
          style={{ textTransform: "capitalize" }}
        >
          {props.isAvailable ? (
            props.name
          ) : (
            <Editable
              text={props.symptomName}
              placeholder="symptomName"
              childRef={symptomNameRef}
              type="select"
            >
              <AlertThresholdNewCardList
                ref={symptomNameRef}
                value={symptomValue}
                setValue={(val) => {
                  val["symptomName"] && props.setSymptomNameFunc(val);
                }}
              />
            </Editable>
          )}
        </div>
      </div>
      <div className="alertThresholdMainContainerTop__rightPart">
        <CircularIcon
          imgsrc={DeleteIcon}
          imgsize="26px"
          passedClass={`${"toggleButton__container"}`}
          clicked={deleteAlertThrehsoldSymptom}
          disabled={props.isMonitoringApproved}
        />
        {props.editMode && props.showMinimiseButton && (
          <CircularIcon
            imgsrc={minimizeIcon}
            imgsize="24px"
            passedClass={`${"toggleButton__container"}`}
            clicked={() => {
              props.editButtonClickedFunc();
            }}
          />
        )}

        <EditSaveButton
          active={!props.editMode}
          disabled={props.isMonitoringApproved}
          clicked={() => {
            if (!props.isMonitoringApproved) {
              props.editButtonClickedFunc();
              if (props.editMode) {
                props.setFetchThresholdAgain(true);
              }
            }
          }}
          editIcon={EditIcon}
          saveIcon={ok}
          okImgSize="18px"
        />
      </div>
      {showModal && (
        <div className={`alertThresholdMainContainerTop__modal`}>
          <ModalDelete
            cancel="No"
            select="Yes"
            messageHeading="Delete Alert Symptom"
            messageBody={`Do you want to delete "${capitalizeFirstLetter(
              props.symptomName
            )}" from the alert list ? `}
            cancelNurseDelete={cancelDeleteAlertThrehsoldSymptom}
            confirmNurseDelete={confirmDeleteAlertThresholdSymptom}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFetchThresholdAgain: (status) =>
      dispatch(actions.fetchThresholdAgain(status)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertThresholdMainContainerTop);
