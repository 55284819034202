import React, { useState } from "react";
import { connect,useSelector } from "react-redux";

import CircularIcon from "../../CircularIcon/CircularIcon";
import "./CaseCard.css";
import { TextArea } from "devextreme-react";
import { updateDiagnosisEndPoint } from "../../../HighOrderFunctions/APIs/allPostAPI";
import { postFetchFunction } from "../../../HighOrderFunctions/FetchMethod/postFetchFunction";
import * as actions from '../../../Store/actions/index';

const CaseCard = (props) => {
    console.log(props)
    const [diagnosis, setDiagnosis] = useState(props.diagnosis)
    const accessToken = useSelector(state=>state.auth.accessToken);

    const updateDiagnosis = async (value) => {
        console.log("Diagnosis: ", value)
        setDiagnosis(value);

        
        try {
            let requestOptions = {
                qr_code: props.qrCodeFromStore,
                diagnosis: value
            }
            await postFetchFunction(updateDiagnosisEndPoint, requestOptions, accessToken);
            props.fetchPatientDetails(true);
            // setDiagnosis(value);
        }
        catch (err) {

        }
    };

    return (
        <div className="caseCard" style={{ width: props.width }}>
            <div className="caseCard__name">Diagnosis</div>
            <div
                className="caseCard__Container"
                style={{ display: "flex", alignItems: "center", }}
            >

                {props.disabled === true && <div className="caseCard__details__body">
                    <div className="caseCard__details">{props.diagnosis}</div>

                </div>}

                {/* <div className="caseCard__icon">
          {Plus && <CircularIcon imgsrc={Plus} size="24px" />}
        </div> */}
            </div>
            {props.disabled === false && <TextArea value={diagnosis} onValueChange={(e) => { updateDiagnosis(e) }}></TextArea>}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        qrCodeFromStore: state.patient.QRCode,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
      fetchPatientDetails: (status) =>
        dispatch(actions.fetchPatientDetails(status)),
  
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(CaseCard);
