import React from 'react'
import './CovidRegistrationNameHeader.css'
const CovidRegistrationNameHeader = (props) => {
    return (
        <div className="crNameHeader__container">
            <div className="crNameHeader__textShowingOrder">Records in descending order of Registration Date</div>
            <div className="crNameHeader__patientName">
             PATIENT NAME
             <div className="crNameHeader__hospitalId">Date of birth</div>

            </div>
            <div className="crNameHeader__dischargeDate">
                <div className="crNameHeader__dischargeDateDate">DATE OF REGISTRATION</div>
                <div className="crNameHeader__dischargeDateTimeSinceDischarge">Hospital ID</div>

            </div>
            <div className="crNameHeader__status">
            STATUS
            {/* Hospital ID */}
            </div>
            <div   className="crNameHeader__department">PATIENT MOBILE NO.</div>
        </div>
    )
}

export default CovidRegistrationNameHeader;
