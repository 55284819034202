import React, { useState, useEffect } from "react";
import "./NumberScaleChart.css";
import NumberScaleLineChart from "../../../../../../Charts/LineChart/LineChartFever";

const NumberScaleChart = (props) => {
  const [chartWidth, setChartWidth] = useState("99.99%");

  return (
    <div className="numberScaleChart__container">
      <NumberScaleLineChart {...props} width={chartWidth} />
    </div>
  );
};

export default NumberScaleChart;
