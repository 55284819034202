import React, { useState, useEffect } from "react";
import SearchDevExtreme from "./SearchDevExtreme";
import { getFollowupsEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { dynamicSort } from "../../../HighOrderFunctions/dynamicSort";

function FollowupsListDropdown(props) {
 // [
  //     {
  //       "label":"Dressing"
  //     },
  // ]
  
  let { response, isLoading } = useFetch(`${getFollowupsEndPoint}`, false);
  useEffect(()=>{

    if (Array.isArray(response) && response.length > 0) {
      let sortedData = response.sort(dynamicSort("label"));
      response = sortedData;
    } else {
      response = [];
    }
    return ()=>{
      
    }
  },[])

  const updateFollowup = (updatedFollowup) => {
    props.setValue(updatedFollowup);
  };

  return (
    <div className="dropdownMainFile" style={{ width: props.width }}>
      <SearchDevExtreme
        placeholder="Select Followup ..."
        optionsFetched={response}
        displayName="label"
        displayExpr="label"
        valueExpr="label"
        updatedSelectedValue={updateFollowup}
        defaultValue={props.value}
      />
    </div>
  );
}

export default FollowupsListDropdown;
