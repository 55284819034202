import React from "react";
import "./MonitoringStatusBody.css";
import SymptomComplianceCharts from "./SymptomComplianceCharts/SymptomComplianceCharts";
import { Route, Switch, Redirect } from "react-router-dom";
import MSLastRecordedVitals from "../../../Components/screensComponent/MonitoringStatus/MSLastRecordedVitals/MSLastRecordedVitals";
import SummaryOfSymptoms from "../../../Components/screensComponent/MonitoringStatus/MSSummaryDescription/SummaryOfSymptoms/SummaryOfSymptoms";
const MonitoringStatusBody = () => {
  return (
    <div className="monitoringStatusBody__container">
      <div className="monitoringStatusBody__lastRecordedVitals">
        <MSLastRecordedVitals />
      </div>
      <div className="monitoringStatusBody__currentSymptoms">
       <SummaryOfSymptoms duration={3} />
      </div>
      <div className="monitoringStatusBody__symptomComplianceCharts">
      <SymptomComplianceCharts />

      </div>
    </div>
  );
};
let routes = (
  <Switch>
    <Route
      path="/monitoringStatus/symptomComplianceCharts"
      component={SymptomComplianceCharts}
    />

    <Redirect to="/monitoringStatus/symptomComplianceCharts" />
  </Switch>
);

export default MonitoringStatusBody;
