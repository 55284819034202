import React from "react";
import AppointmentCallBody from "../AppointmentCallBody/AppointmentCallBody";
import "./DischargeDetailsFooter.css";
import { connect } from "react-redux";

const DischargeDetailsFooter = (props) => {
  const { date, duration } = props.dateDurationFromStore;
  return (
    <div className="dischargeDetailsFooter__container">
      <p className="dischargeDetailsFooter__appointmentCallName">
        Appointment call
      </p>
      <div className="dischargeDetailsFooter__appointmentCallBody">
        <AppointmentCallBody
          date={props.dateDurationFromStore["date"]}
          duration={props.dateDurationFromStore["duration"]}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    dateDurationFromStore: state.patient.dateDuration,
  };
};
export default connect(mapStateToProps, null)(DischargeDetailsFooter);
