import React, { useState, useEffect } from "react";
import "./InlineEditableComponent.css";
import { connect } from "react-redux";
const InlineEditableComponent = ({
  children,
  childref,
  text,
  placeholder,
  type,
  timeScheduleClassName,
  style,
  setSelectedTimeSlot,
  timeSlotsAfterModify,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  // console.log("isEditing", isEditing);
  useEffect(() => {
    if (childref && childref.current && isEditing) {
      childref.current.focus();
    }
  }, [childref, isEditing]);
  const handleBlur = () => {
    setIsEditing(false);
    // setSelectedTimeSlot(timeSlotsAfterModify);
  };
  const handleKeyDown = (event, type) => {
    const { key } = event;
    const allKeys = ["Esc", "Enter"];
    if (allKeys.indexOf(key) > -1) {
      setIsEditing(false);
    }
  };

  // Disabling the input
  const DisableInlineInput = () => {
    if (
      props.isMonitoringApproved &&
      props.medicineUpdateAfterApproved &&
      !props.disableTimeInput
    ) {
      // alert("false");
      return false;
    } else if (
      props.isMonitoringApproved &&
      !props.medicineUpdateAfterApproved
    ) {
      return true;
    } else if (props.disableTimeInput && props.medicineUpdateAfterApproved) {
      return true;
    } else if (!props.disableTimeInput && props.isMonitoringApproved) {
      return true;
    }
  };

  return (
    <div
      className="inLineEditableComponent__container"
      style={{ width: timeScheduleClassName && "100%" }}
    >
      {isEditing ? (
        <div
          className="inLineEditableComponent__text"
          onBlur={handleBlur}
          onKeyDown={(e) => handleKeyDown(e, type)}
        >
          {children}
        </div>
      ) : (
        <button
          className={`inLineEditableComponent__containerText ${
            text.length == 0 ? "inLineEditableComponent__emptyValue" : ""
          }`}
          onClick={() => setIsEditing(true)}
          // disabled={
          //   props.isMonitoringApproved &&
          //   props.medicineUpdateAfterApproved
          //     ? false
          //     : props.isMonitoringApproved
          // }
          disabled={DisableInlineInput()}
          style={{
            fontWeight: timeScheduleClassName && "normal",
            fontSize: timeScheduleClassName && "13px",
          }}
        >
          {text && text !== "Brand-Name" ? (
            <span className="inlineEditableComponent__textText">{text}</span>
          ) : (
            <span
              className={`inlineEditableComponent__emptyText ${
                placeholder == "Generic-Name" && "genericNamePlaceholder"
              }`}
            >
              {placeholder}
            </span>
          )}
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,
    medicineUpdateAfterApproved:
      state.monitoringCapsule.medicineUpdateAfterApproved,
  };
};
export default connect(mapStateToProps, null)(InlineEditableComponent);
