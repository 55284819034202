import React, { useState, useEffect, useRef } from "react";
import FileInputButton from "./FileInputButton/FileInputButton";
import './BulkUploader.css';
const BulkUploader = () => {
  
  const [dischargeFileInput, setDischargeFileInput] = useState("");
  const [symptomFileInput,setSymptomFileInput] = useState("");
  const [monitoringFileInput,setMonitoringFileInput] = useState("");
  const dischargeCaptureHandler = (e) => {
    console.log("capture handler", e.target.files);
  };
  const symptomCaptureHandler=(e)=>{
      console.log("SymptomCaptureHandler",e.target.files);
  }
  const monitoringCaptureHandler =(e)=>{
      console.log("MonitoringCaptureHandler",e.target.files);
  }

  return (
    <div className="bulkUploader__container">
     
      <div className="symptoms">
        <FileInputButton
          fileInput={symptomFileInput}
          setFileInput={setSymptomFileInput}
          CaptureHandler={symptomCaptureHandler}
        />
      </div>
      <div className="monitoring">
        <FileInputButton
          fileInput={monitoringFileInput}
          setFileInput={setMonitoringFileInput}
          CaptureHandler={monitoringCaptureHandler}
        />
      </div>
     
    </div>
  );
};

export default BulkUploader;
