import React, { useState } from "react";
import ApprovalCustomButton from "../../../../Buttons/ApprovalCustomButton/ApprovalCustomButton";
import "./PatientAlertsApproval.css";
import { useHistory } from "react-router-dom";
import { getApproveDischargeSummaryEndPoint } from "../../../../../HighOrderFunctions/APIs/allGetAPI";

import { connect, useSelector } from "react-redux";

import * as actions from "../../../../../Store/actions/index";
import { getFetchFunction } from "../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import "./PatientAlertsApproval.css";
const PatientAlertsApproval = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [approveStatus, setApproveStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approvalButtonClicked, setApprovalButtonClicked] = useState(false);
  const history = useHistory();

  const sendApprovalStatusFunc = async () => {
    setLoading(true);
    const approvalSentStatus = await getFetchFunction(
      `${getApproveDischargeSummaryEndPoint}/${props.qrCodeFromStore}`,
      accessToken
    );
    setLoading(false);
    setApprovalButtonClicked(true);

    setApproveStatus(approvalSentStatus["isApproved"]);
    if (approvalSentStatus["isApproved"] === true) {
      history.push("/patientAlerts/UserApproved");
    } else {
    }
  };
  // here props.isMonitoringApproved is being used to check whether patient has already approved or not ?
  
  return (
    <div className="patientAlertsApproval__container">
      {!props.isMonitoringApproved && (
        <ApprovalCustomButton
          disabled={props.approveButtonStatus === false || approveStatus}
          name={`${approveStatus ? "Approved" : "Approve"}`}
          approvalErrorOccured={!approveStatus && approvalButtonClicked}
          clicked={() => {
            sendApprovalStatusFunc();
          }}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    approveButtonStatus: state.patient.approveButtonStatus,
    qrCodeFromStore: state.patient.QRCode,
    primaryContactNumberFromStore: state.patient.primaryContactNumber,
    isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPatientStore: () => dispatch(actions.resetPatientState()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientAlertsApproval);
