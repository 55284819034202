import React, { useEffect, useState } from "react";
import AlertThresholdMainContainer from "../../../../Components/screensComponent/patientAlerts/PatientAlertsBody/AlertThreshold/AlertThresholdMainContainer/AlertThresholdMainContainer";
import ButtonWBorder from "../../../../Components/Buttons/ButtonWBorder/ButtonWBorder";
import "./AlertThresholdConditions.css";
import * as actions from "../../../../Store/actions/index";
import { getAlertsEndPoint } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";

import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { checkValidity } from "../../../../HighOrderFunctions/shared/utility";
import { useFetch } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { getPatientDetailsEndPoint } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import axios from "axios";
import { deleteAlertEndPoint } from "../../../../HighOrderFunctions/APIs/allPostAPI";
import { v4 as uuidv4 } from "uuid";
import { dynamicSort } from "../../../../HighOrderFunctions/dynamicSort";
import Loader from "../../../../Loader/LoaderThreeDot";
var jmespath = require("jmespath");

const AlertThresholdConditions = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alertThresholdIsValid, setAlertThresholdIsValid] = useState(true);
  const [fetchSuccessful, setFetchSuccessful] = useState(false);

  // array to store boolean value corresponding to each fetched Symptoms to check symptom isAvailable 
  const [alertThresholdArrayCheck, setAlertThresholdArrayCheck] = useState([]);

  // calling this api to have access to monitoring duration
  const { response: patientData, isLoading } = useFetch(
    `${getPatientDetailsEndPoint}/${props.qrCodeFromStore}`
  );


  // function to fetch list of alerts and converting that into desired format
  const fetchedDataFunc = async () => {
    setLoading(true);
    setFetchSuccessful(false);
    let fetchedValue = await getFetchFunction(
      `${getAlertsEndPoint}/${props.qrCodeFromStore}`,
      accessToken
    );
    if (Array.isArray(fetchedValue) == true) {
      let list_of_symptoms = jmespath.search(fetchedValue, "[].symptom");
      list_of_symptoms = [...new Set(list_of_symptoms)];

      let new_result = [];
      list_of_symptoms.forEach((symptom) => {
        let temp = { symptom_name: symptom, data: [] };
        fetchedValue.forEach((data) => {
          if (data.symptom === symptom) {
            temp.data.push(data);
          }
        });
        new_result.push(temp);
      });
      let sortedData = new_result.sort(dynamicSort("symptom_name"));
      setFetchedData((prev) => [...new_result]);
      if (new_result.length === 0) {
        props.setAlertThresholdNavlinkApproveStatus(true);
      }
      let tempArray = new_result.map((item) => {
        if (item["data"][0]?.isAvailable === 1) {
          return true;
        } else {
          return false;
        }
      });

      setAlertThresholdArrayCheck(tempArray);
      setFetchSuccessful(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.qrCodeFromStore !== "") {
      fetchedDataFunc();
    }
  }, []);

  //  function to fetch alert threshold again on basis of props.fetchThreshold in redux
  useEffect(() => { 
    if (props.fetchThreshold === true) {
      fetchedDataFunc();
      props.setFetchThresholdAgain(false);
    }
  }, [props.fetchThreshold]);

  // new alert threshold symptom on click of add New Alert button
  const addNewAlertCardFunc = () => {
    let newAlertThresholdForm = {
      symptom_name: "",
      data: [],
      newCard: true,
      added: true,
    };
    setAlertThresholdArrayCheck((prev) => [...prev, false]);
    setFetchedData((prev) => [...prev, newAlertThresholdForm]);
    checkAlertThresholdValidity();
    props.setAlertThresholdNavlinkApproveStatus(false);
    setAlertThresholdIsValid(false);
  };

  // function to check if all symptoms are added i.e no red symptoms
  const checkAlertThresholdValidity = () => {
    let tempAlertArray = alertThresholdArrayCheck;
    let tempApproveCheck = tempAlertArray.every((item) => item === true);
    setAlertThresholdIsValid(tempApproveCheck);
    props.setAlertThresholdNavlinkApproveStatus(tempApproveCheck);
  };
  useEffect(() => {
    if (fetchSuccessful === true) {
      checkAlertThresholdValidity();
    }
  }, [fetchSuccessful]);

  //  2nd function for validation with parameter;
  const updateAlertThresholdValidity = (symptomIndex) => {
    let tempAlertThresholdArrayCheck = [...alertThresholdArrayCheck];
    let filteredAlertThresholdArrayCheck = tempAlertThresholdArrayCheck.filter(
      (item, index) => index !== symptomIndex
    );
    setAlertThresholdArrayCheck((prev) => [
      ...filteredAlertThresholdArrayCheck,
    ]);
    let tempApproveCheck = filteredAlertThresholdArrayCheck.every(
      (item) => item === true
    );
    setAlertThresholdIsValid(tempApproveCheck);
    props.setAlertThresholdNavlinkApproveStatus(tempApproveCheck);
  };


  // function to delete sns-entity (symptom ) from alert threshold
  const deleteSymptomFromList = (symptomData, symptomIndex) => {
    let tempConditionArray = [];
    tempConditionArray = symptomData.map((item) => {
      return item.id;
    });
    tempConditionArray.map((id) => {
      axios.delete(
        `${window["getConfig"].BACKEND_SERVICE_URL}/${deleteAlertEndPoint}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken} `,
          },
        }
      );
    });
    let tempFetchedData = [...fetchedData];
    let filteredTempData = tempFetchedData.filter((item, index) => {
      return index !== symptomIndex;
    });
    setFetchedData((prev) => [...filteredTempData]);

    updateAlertThresholdValidity(symptomIndex);
  };


  
  // rendering list of each symptoms in alert threshold and passing into AlertThresholdMainContainer.js

  let renderedData = [];
  if (loading == true) {
    renderedData = (
      <div className="noAlertAvailable">
        <Loader />
      </div>
    );
  } else if (fetchedData.length == 0 && fetchSuccessful && !loading) {
    renderedData = <div className="noAlertAvailable">No Alert Available</div>;
  } else {
    renderedData =
      fetchSuccessful &&
      fetchedData.map((item, index) => {
        return (
          <AlertThresholdMainContainer
            key={uuidv4()}
            symptomName={item.symptom_name}
            // alertNotification="You will be notified if"
            alertNotification=""
            alertConditions={item["data"]}
            measureType={item["data"][0]?.["measure_type"]}
            isAvailable={item["data"][0]?.["isAvailable"]}
            alertCardId={index}
            newCard={item["newCard"]}
            addedTrue={item["added"]}
            checkAlertThresholdValidity={checkAlertThresholdValidity}
            symptomIndex={index}
            monitoringDuration={
              patientData &&
              patientData.length > 0 &&
              patientData[0]["daysToMonitor"]
            }
            deleteSymptomFromList={deleteSymptomFromList}
          />
        );
      });
  }

  return (
    <div className="alertThresholdConditions">
      <div className="alertThresholdConditions__conditionNames">
        <div className="alertThresholdConditions__conditionText">
          Modify, add or delete alerts
        </div>
        <div className="alertThresholdConditions__errorMessage">
          {fetchSuccessful &&
            !alertThresholdIsValid &&
            fetchedData.length > 0 && (
              <div className="error-message">
                Some important details are missing. Please complete the details.
              </div>
            )}
        </div>
        <div
          className="
          alertThresholdConditions__addAlertsButton"
        >
          <ButtonWBorder name="Add Alerts" clicked={addNewAlertCardFunc}
          disabled={props.isMonitoringApproved}
          passedClass={`${props.isMonitoringApproved && "button__disabledDisplayNone"}`}
          />
        </div>
      </div>
      <div className="alertThresholdConditions__listOfAlertConditions">
        {renderedData}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    fetchThreshold: state.patient.fetchThreshold,
    isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setFetchThresholdAgain: (status) =>
      dispatch(actions.fetchThresholdAgain(status)),
    setAlertThresholdNavlinkApproveStatus: (status) =>
      dispatch(actions.setAlertThresholdNavlinkApproveStatus(status)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertThresholdConditions);

{
  /* <div className="alertThresholdConditions__setAlertButton">
        <ButtonWIconName name="Done" imgsrc={donePAtick} />
      </div> */
}
