import React, { useState, useEffect } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import axios from "axios";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};

  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }

  // const params = Object.keys(data).map((key) => {
  //   return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
  // }).join('&');
  
  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
const SNS2Clinical = () => {
  const [allSNSEntity, setAllSNSEntity] = useState([]);
  const [allClinicalEntity, setAllClinicalEntity] = useState([]);
  const [searchText, setSearchText] = useState("");

  const fetchAll = async () => {
    axios
    .all([
      axios.get(`${window["getConfig"].BACKEND_SERVICE_URL}/sns-entity/all`),
      axios.get(
        `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity/all`
        ),
      ])
      .then((responseArray) => {
        setAllSNSEntity(responseArray[0].data);
        // setAllClinicalEntity(responseArray[1].data);
      });
  };
  const store = new CustomStore({
    key: "entity_to_sns_mapping_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/action-sns-entity-mapping?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          return {
            data: data.data,
            totalCount: data.totalCount,
            // summary: data.summary,
            // groupCount: data.groupCount,
            // {data:[{},{}],
            // totalCount:10}
          };
        })
        .catch((error) => {
          console.log("Error Received", error);
          throw "Data Loading Error";
        });
    },
    insert: (values) =>{
      
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/action-sns-entity-mapping`,
        "POST",
        values
        )
      },
    update: (key, values) => {
      console.log("KEY: ",key);
      console.log("ACTION TO SNS ENTITY: ",values)
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/action-sns-entity-mapping/${key}`,
        "PUT",
        values
      ).catch((error) => {
        console.log("Error Received", error);
        throw "Data Loading Error";
      });
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/action-sns-entity-mapping/${key}`,
        "DELETE"
      ),
  });
  const calculateCellValueFunc = (data) => {
    if (data.sns_entity_measure_type == 1) {
      return "NUMBER_SCALE";
    } else if (data.sns_entity_measure_type == 2) {
      return "BINARY";
    } else if (data.sns_entity_measure_type == 3) {
      return "THREE_POINT_SCALE";
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const onOptionChanged = (e) => {
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  if (allClinicalEntity.length === 0 && allSNSEntity.length === 0) return null;
  return (
    <DataGrid
      // width={"50%"}
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnResizing={true}
      allowColumnReordering={false}
      sorting={false}
      onOptionChanged={onOptionChanged}
    >
      <SearchPanel visible={true} text={searchText} />

      <Editing
        refreshMode="reshape"
        mode="popup"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />
      <SearchPanel visible={true} width={240} placeholder="Search..." />
      <Column
        width={40}
        dataField="entity_to_sns_mapping_id"
        dataType="number"
        caption="id"
        allowEditing={false}
      ></Column>
      <Column dataField="entity_name" dataType="string"
      caption="entity Name"
      >
        <Lookup
          dataSource={[{id:1,name:"Negate"},{id:2,name:"Uneasy_Feeling"}]}
          displayExpr="name"
          valueExpr="name"
        />
      </Column>
     

     

      <Column
        dataField="entity_action"
        caption="entity Action"
        dataType="string"
      ></Column>
      <Column
        caption="SNS Entity"
        dataField="t_ref_sns_entity_id"
        dataType="string"
        width="250"
      >
        <Lookup
          dataSource={allSNSEntity}
          displayExpr="sns_entity_name"
          valueExpr="t_ref_sns_entity_id"
        />
        <RequiredRule />
      </Column>

      <Paging defaultPageSize={12} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[12, 15, 20]} />
    </DataGrid>
  );
};
// calculateCellValue={calculateCellValueFunc}
export default SNS2Clinical;
