import React, { useState, useEffect } from "react";
import "./AlertThresholdNotificationEdit.css";
import EditSaveButton from "../../../../../../../Buttons/ToggleButton/ToggleButton";
import CircularIcon from "../../../../../../../CircularIcon/CircularIcon";  
import EditIcon from "../../../../../../../../AllIcons/editIcon.png";

import DeleteIcon from "../../../../../../../../AllIcons/deleteIcon.png";
import saveFileIcon from "../../../../../../../../AllIcons/saveFileIcon.png";
import SearchDevExtreme from "../../../../../../SearchDropdown/SearchDevExtreme";

import relationdata from "../../../../../../../../data/relationData.json";
import thresholdData from "../../../../../../../../data/thresholdData.json";
import { Prompt } from "react-router";

const AlertThresholdNotificationEdit = (props) => {
  let idForUseEffect = props.id;
  const [tempRelation, setTempRelation] = useState({
    name: props.relation,
    value: props.relation,
  });
  const [tempThreshold, setTempThreshold] = useState({
    name: props.threshold,
    value: props.threshold,
  });
  const [formCssAlert, setFormCssAlert] = useState({
    day_start: false,
    day_end: false,
    threshold: false,
  });
  const [saveAlertActive, setSaveAlertActive] = useState(false);

  useEffect(() => {
    if(props.rejectedSaving){
      setSaveAlertActive(!props.rejectedSaving)
    }
  }, [props.rejectedSaving])
 

  // initializing row containing day_start,day_end etc. used for editing 
  const [form, setForm] = useState({
    day_start: "",
    day_end: "",
    relation: "",
    threshold: "",
    measure_type: "",
    symptom: "",
    id: props.id,
    index: props.index,
  });
  useEffect(() => {
    setForm({
      day_start: props.day_start,
      day_end: props.day_end,
      relation: `${
        props.measure_type === "THREE_POINT_SCALE"
          ? "EQUAL_TO"
          : props.measure_type === "BINARY"
          ? "EQUAL_TO"
          : props.relation
      }`,
      threshold: `${
        props.measure_type === "BINARY" ? "Present" : props.threshold
      }`,
      measure_type: props.measure_type,
      id: props.id,
      index: props.index,
      symptom: props.symptom,
    });
  }, [idForUseEffect]);

  // function to edit day_start && day_end
  const setInputValueFunc = (e) => {
    if (Number.isInteger(+e.target.value)) {
      if (e.target.name === "day_end" || e.target.name === "day_start") {
        if (+e.target.value <= props.monitoringDuration) {
          setForm((prev) => {
            return { ...prev, [e.target.name]: e.target.value.toLowerCase() };
          });
          setFormCssAlert({
            ...formCssAlert,
            [e.target.name]: false,
          });
        } else {
          setFormCssAlert({
            ...formCssAlert,
            [e.target.name]: true,
          });
        }
      } else {
        setForm((prev) => {
          return { ...prev, [e.target.name]: e.target.value.toLowerCase() };
        });
      }
    }
  };

  // function to edit relation (less than, greater than etc.)
  const setFormRelationFunc = (val) => {
    setTempRelation(val);
    if (val !== null) {
      setForm({
        ...form,
        relation: val["value"],
      });
    }
  };

  // function to edit threshold
  const setFormThresholdFunc = (val) => {
    setTempThreshold(val);
    if (val !== null) {
      setForm({
        ...form,
        threshold: val["value"],
      });
    }
  };
  return (
    <React.Fragment>
     {props.newCard && <Prompt
        // when={shouldBlockNavigation}
        message="You have unsaved changes, are you sure you want to leave?"
      />
     }
      <div className="alertThresholdOnEdit">
        <div className="alertThresholdNotificationEdit__container">
          <div className="alertThresholdNotificationEdit__editContainer">
            <div className="alertThresholdNotificationEdit__dayInput">
              <div className="form__fieldName">Day: </div>
              <div className="alertThresholdNotificationEdit__inputDay">
                <input
                  className={`alertThresholdNotificationEdit__inputField 
                ${formCssAlert["day_start"] ? "wrongEntryAlertCSS" : ""}
                ${props.rejectedSaving ? "entrySavingRejected":""}
                `}
                  type="text"
                  name="day_start"
                  value={form["day_start"]}
                  onChange={setInputValueFunc}
                  placeholder="-"
                  disabled={saveAlertActive}

                  required
                />
              </div>
            </div>
            <div className="form__fieldName">to </div>
            <div className="alertThresholdNotificationEdit__dayInput">
              <div className="form__fieldName"> Day: </div>
              <div className="alertThresholdNotificationEdit__inputDay">
                <input
                  className={`alertThresholdNotificationEdit__inputField 
                ${formCssAlert["day_end"] ? "wrongEntryAlertCSS" : ""}
                ${props.rejectedSaving ? "entrySavingRejected":""}
                `}
                  type="text"
                  name="day_end"
                  value={form["day_end"]}
                  onChange={setInputValueFunc}
                  placeholder="-"
                  disabled={saveAlertActive}
                  required
                />
              </div>
            </div>
            <div className="form__fieldName">is</div>
            <div>
              {props.measure_type == "NUMBER_SCALE" && (
                <div className="alertThresholdNotificationEdit__inputRelation">
                  <SearchDevExtreme
                    optionsFetched={relationdata}
                    value={tempRelation}
                    displayName="name"
                    defaultValue={tempRelation.value}
                    valueExpr="value"
                    updatedSelectedValue={setFormRelationFunc}
                    heightpassed="40px"
                  />
                </div>
              )}
            </div>
            {props.measure_type === "THREE_POINT_SCALE" && (
              <div className="">equal to</div>
            )}
            {props.measure_type === "NUMBER_SCALE" && (
              <div className="alertThresholdNotificationEdit__inputTempVal">
                <input
                  className="alertThresholdNotificationEdit__inputField"
                  type="text"
                  name="threshold"
                  value={form["threshold"]}
                  onChange={setInputValueFunc}
                  placeholder="-"
                  disabled={saveAlertActive}

                />
              </div>
            )}
            {props.measure_type === "THREE_POINT_SCALE" && (
              <div className="alertThresholdNotificationEdit__inputRelation">
                <SearchDevExtreme
                  optionsFetched={thresholdData}
                  value={tempThreshold}
                  displayName="name"
                  defaultValue={tempThreshold.value}
                  valueExpr="value"
                  updatedSelectedValue={setFormThresholdFunc}
                  heightpassed="40px"
                  disabled={saveAlertActive}
                />
              </div>
            )}
            {props.measure_type === "BINARY" && (
              <div className="form__fieldName">Present</div>
            )}
            <div>{props.measure_type === "BINARY" && <div></div>}</div>
          </div>

          <div className="buttonBorderAlert">
            <EditSaveButton
              active={saveAlertActive}
              clicked={() => {
                if (!saveAlertActive) {
                  props.saveAlertFunc(form, props.id, props.index);
                }
                
                   setSaveAlertActive((prev) => !prev);

                
              }}
              editIcon={EditIcon}
              saveIcon={saveFileIcon}
              dontShowPassedClass = {true}
            />
          </div>
          <div
            className="editButton"
            onClick={() => props.deleteAlertFunc(props.id, props.index)}
          >
            <CircularIcon imgsize="38px" imgsrc={DeleteIcon} />
          </div>
        </div>
        {(formCssAlert["day_start"] || formCssAlert["day_end"]) && (
          <div className="has-wrongEntry">
            {" "}
            Day Entered should be less than {props.monitoringDuration}
          </div>
        )}
        {(+form["day_start"] > +form["day_end"]) && (
          <div className="has-wrongEntry">
            {" "}
            Start Day should be less than end day.
          </div>
        )}
        <div>
          {props.rejectedSaving &&  <div className="has-wrongEntry">
            {" "}
            You cannot have overlapping days
          </div>}
        </div>
        <div >
          {props.newCard && <div className="error-message">Please complete the details to move forward</div>} </div>
      </div>
    </React.Fragment>
  );
};

export default AlertThresholdNotificationEdit;
