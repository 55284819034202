import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ColorToggleButton(props) {
  const [alignment, setAlignment] = React.useState('original');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    props.setChatShowLanguage(newAlignment)
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      style={{borderRadius:"20px"}}
    >
      <ToggleButton value="original">Original</ToggleButton>
      <ToggleButton value="english">English</ToggleButton>
    </ToggleButtonGroup>
  );
}
