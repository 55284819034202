import React from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";



function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};


  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }

  // const params = Object.keys(data).map((key) => {
  //   return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
  // }).join('&');

  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
const Diet = () => {
  const store = new CustomStore({
    key: "diet_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/diet?limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount,
          };
        })
        .catch(() => {
          throw "Data Loading Error";
        });
    },
    insert: (values) =>
      sendRequest(`${window["getConfig"].BACKEND_SERVICE_URL}/diet`, "POST", values),
    update: (key, values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/diet/${key}`,
        "PUT",
        values
      ),
    remove: (key) =>
      sendRequest(`${window["getConfig"].BACKEND_SERVICE_URL}/diet/${key}`, "DELETE"),
  });

  return (
    <DataGrid
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnResizing={true}
      allowColumnReordering={false}
      sorting={false}
    >
      <Editing
        refreshMode="reshape"
        mode="row"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />

      <Column dataField="diet_name" dataType="string"></Column>
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[10, 15, 20]} />
    </DataGrid>
  );
};

export default Diet;
