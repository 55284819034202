import React from 'react'
import MonthClass from './MonthClass';

const monthChange = (date,identifier) => {
    if(date ==undefined){
        return;
    }
    let months = [
        new MonthClass('January',0),
        new MonthClass('February',1),
        new MonthClass('March',2),
        new MonthClass('April',3),
        new MonthClass('May',4),
        new MonthClass('June',5),
        new MonthClass('July',6),
        new MonthClass('August',7),
        new MonthClass('September',8),
        new MonthClass('October',9),
        new MonthClass('November',10),
        new MonthClass('December',11)
    ]
    // month date,year 
    if(identifier == "covidRegistrationForm"){
    
        let dateModified = new Date(date).toDateString().split(" ");

        let monthId = new Date(date).getMonth();
        

        let fullDateChosen =  `${months[monthId]["month"]} ${dateModified[2]}, ${dateModified[3]}`;
        return fullDateChosen;
    }
    if(identifier =="covidGetDOB"){
        let monthId = new Date(date).getMonth();
        let dateModified = new Date(date).toDateString().split(" ")
        let year = dateModified[3];
        if(year.toString().includes("-")){
            year = year.toString().substring(1,5)
        }
        let fullDateChosen =  `${months[monthId]["month"]} ${dateModified[2]}, ${year}`;
        return fullDateChosen;
        
    }
    // it's different YYYY-MM-DD
    if(identifier=="updateCovidDOB"){
        let monthId = new Date(date).getMonth();
        if(+monthId < 9){
            monthId ="0" + ++monthId;
        }else{
            monthId = ++monthId;
        }
        let dateModified = new Date(date).toDateString().split(" ")
        let fullDateChosen =  `${dateModified[3]}-${monthId}-${dateModified[2]}`;
        return fullDateChosen;
    }
    // if(identifier=="getCovidRegistrationDate"){

    // }

}

export default monthChange;
