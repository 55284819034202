import React from 'react'
import './DashboardNameHeader.css'
const DashboardNameHeader = (props) => {
    return (
        <div className="dashboardNameHeader__container">
            <div className="dashboardNameHeader__patientName">
             PATIENT NAME
                <div className="dashboardNameHeader__dischargeDateTimeSinceDischarge">(Hospital ID)</div>
            </div>
            <div className="dashboardNameHeader__dischargeDate">
                <div className="dashboardNameHeader__dischargeDateDate">Discharge Context</div>
                <div className="dashboardNameHeader__dischargeDateTimeSinceDischarge">(discharge date)</div>
            </div>
            <div className="dashboardNameHeader__status">
            {props.status} {props.arrowUp && <span className="dashboard__arrowUp"></span>}
            </div>
        </div>
    )
}

export default DashboardNameHeader
