import React from "react";
import "./RichedTextScreen.css";
import HtmlEditor, { MediaResizing } from "devextreme-react/html-editor";
import ButtonBorder from "../../../Components/Buttons/ButtonBorder/ButtonBorder";
import ModalCustomButton from "../../../Components/Buttons/ModalCustomButton/ModalCustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const RichedTextScreen = (props) => {
  const history = useHistory();
  const navigateBackToUploadScreen = () =>{
    history.push("/ds-upload/fileUpload")
  }
  return (
    <div className="richedTextScreen__container">
      <div className="richedText__textAccept">
        <HtmlEditor className="richedText__inputText" 
        // defaultValue={"please paste here"}
        placeholder={`Please Paste Riched Text Here!! \n Press ctrl + V to Paste here.`}>
          <MediaResizing enabled={true} />
        </HtmlEditor>
      </div>
      <div className="richedTextScreen__buttons">
        <div className="richedTextScreen__button">
          <ButtonBorder clicked={() => {navigateBackToUploadScreen()}} text="Back" />
        </div>
        <div className="richedTextScreen__button">
          <ModalCustomButton
          status={true}
            name="Save"
            bgcolor="rgba(57, 129, 233, 0.68)"
            textColor="white"
            clicked={() => {}}
            // disabled={!correctContactNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default RichedTextScreen;
