import React from "react";
import "./MFPHeader.css";
const MFPHeader = () => {
  return (
    <div className="mfpHeader__container">
      <div className="mfpHeader__brandGenericDuration">
        <div className="mfpHeader__brandName">BRAND NAME</div>
        <div className="mfpHeader__genericName">Generic Name</div>
      </div>
      <div className="mfpHeader__dosage">DOSAGE</div>
      <div className="mfpHeader__scheduleDuration">
        <div className="mfpHeader__schedule">SCHEDULE</div>
        <div className="mfpHeader__schedule">Duration</div>
      </div>
      <div className="mfpHeader__frequency">FREQUENCY</div>
      <div className="mfpHeader__instructionsStatus">
        <div className="mfpHeader__schedule">
            Instructions
        </div>
        <div className="mfpHeader__duration">Status</div>
      </div>
    </div>
  );
};

export default MFPHeader;
