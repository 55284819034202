import React, { Fragment, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../Header";
import RashChart from "../../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/BinaryChart/RashChart";
import RangeChart from "../../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/RangeChart/RangeChart";
import NumberScaleChart from "../../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/NumberScaleChart/NumberScaleChart";
import ThreePointScaleChart from "../../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/ThreePointScaleChart/PainChart";
import BinaryChart from "../../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/BinaryChart/RashChart";
import "../../MonitoringStatusCharts/MonitoringStatusCharts.css";
import NoChartData from "../../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/NoChartData/NoChartData";
import noChartMeasureErrorImage from "../../../../../../AllIcons/noChartMeasureErrorImage.png";
import "./PatientVitalNSymptomChart.css";
import Footer from "../Footer";
const PatientVitalNSymptomChart = (props) => {
  const [clinicalEntities, setClinicalEntities] = useState([]);
  useEffect(() => {
    setClinicalEntities(props.ClinicalEntities);
    return () => {
      setClinicalEntities([]);
    };
  }, []);

  return (
    <div className="patientVitalNSymptomChart">
      <div className="patientVitalNSymptomChart__headerNChart">
        <Header title={props.header} />
        {Array.isArray(clinicalEntities) &&
          clinicalEntities.map((entity, index) => {
            // on basis of measure_type
            if (entity.measure_type == "NUMBER_SCALE") {
              let unitOfMeasurement = entity.unit_of_measurement;
              if (entity.name === "fever") {
                unitOfMeasurement = "Temperature, °F";
              }
              if (entity.name === "temperature") {
                unitOfMeasurement = "Temperature, °F";
              }

              if (entity.name === "oxygen-saturation") {
                unitOfMeasurement = "SPO2";
              }
              if (entity.name === "blood pressure") {
                return (
                  <div key={index} index={index} className="symptomChartActive">
                    <RangeChart
                      unitOfMeasurement={entity.unit_of_measurement}
                      name={entity.name}
                      height={160}
                      downloadRangeChart={true}
                      identifier={props.identifier}
                    />
                  </div>
                );
              }

              return (
                <div key={index} index={index} className="symptomChartActive">
                  <NumberScaleChart
                    key={`${entity.name}-${index}`}
                    name={entity.name.toLowerCase()}
                    unitOfMeasurement={unitOfMeasurement}
                    time={+index + 1000}
                    height={160}
                    identifier={props.identifier}
                  />
                </div>
              );
            } else if (entity.measure_type === "THREE_POINT_SCALE") {
              return (
                <div key={index} index={index} className="symptomChartActive">
                  <ThreePointScaleChart
                    unitOfMeasurement={entity.unit_of_measurement}
                    name={entity.name}
                    bodyHeight={"55%"}
                    containerHeight={"165px"}
                    dotWidth="8px"
                    dotHeight="8px"
                    downloadPainChart={true}
                    identifier={props.identifier}
                  />
                </div>
              );
            } else if (entity.measure_type === "BINARY") {
              return (
                <div key={index} index={index} className="symptomChartActive">
                  <BinaryChart
                    name={entity.name}
                    unitOfMeasurement={entity.unit_of_measurement}
                    padding={"5px"}
                    containerHeight={"155px"}
                    bodyBodyPadding={"0px"}
                    iconWidth="10"
                    iconHeight="10"
                    downloadBinaryChart={true}
                    identifier={props.identifier}
                  />
                </div>
              );
            } else {
              return (
                <div className="symptomChart">
                  <div className="signsSymptomsCharts__noChartMeasureType">
                    <NoChartData
                      message="There was an error. Please contact the System Administrator"
                      imgsrc={noChartMeasureErrorImage}
                      size="120px"
                    />
                  </div>
                </div>
              );
            }
          })}
      </div>
      <div className="patientVitalNSymptomChart__footer">
        <Footer hospitalNo={props.hospitalNo} />
        {/*<h3 className="page-break"></h3>*/}
      </div>
    </div>
  );
};

export default PatientVitalNSymptomChart;
