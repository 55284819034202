import React from "react";
import "./ButtonWBorder.css";
const ButtonWBorder = (props) => {
  return (
    <button
      disabled={props.disabled}
      className={`buttonWBorder ${props.active ? "activeButton" : ""}  ${
        props.pdfButton ? "buttonWBorderPdf" : ""
      } ${props.disabled ? "buttonWBorderDisabledButton" : ""}
      ${props.passedClass ? props.passedClass : ""}
      `}
      onClick={props.clicked}
      style={{ width: `${props.width}` }}
    >
      <p className={`buttonWBorder__buttonName  ${props.dischargeActive ? "dischargeActive" : ""} ${props.disabled ? "buttonWBorderDisabledButtonText" : ""}`}>{props.name}</p>
    </button>
  );
};

export default ButtonWBorder;
