import React, { useState, useEffect } from "react";
import "./PainChart.css";
import PainChartSidebar from "./PainChartSidebar/PainChartSidebar";
import { getPainChartEndPoint } from "../../../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import PainChartBody from "./PainChartBody/PainChartBody";
import { connect, useSelector } from "react-redux";
import ButtonForChart from "../../../../../../Components/Buttons/ButtonForChart/ButtonForChart";
const PainChart = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [fetchedData, setFetchedData] = useState([]);
  const convertSeverityInNumber = (stringValue) => {
    if (stringValue == "mild") {
      return ["mild", "not", "not"];
    } else if (stringValue == "moderate") {
      return ["not", "moderate", "not"];
    } else if (stringValue == "severe") {
      return ["not", "not", "severe"];
    } else {
      return ["not", "not", "not"];
    }
  };
  const fetchedDataFunc = async () => {
    let fetchedValue = await getFetchFunction(
      `${getPainChartEndPoint}/${props.qrCodeFromStore}/${props.name}`,
      accessToken
    );
    let temp = [];
    var days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    if (Array.isArray(fetchedValue?.daywise_data) == true) {
      fetchedValue.daywise_data.map((item, index) => {
        let dailySeverityStatuses = [];

        item.data.map((severityStatus, index) => {
          //testing

          let painStatus = {
            time: severityStatus.time,
            severity: convertSeverityInNumber(severityStatus.measure),
            date: severityStatus.date,
            site: severityStatus.site,
            onset: severityStatus.onset,
            character: severityStatus.character,
          };
          dailySeverityStatuses.push(painStatus);
        });
        let data = {
          takenTimes: dailySeverityStatuses,
          dayNumber: item.day_number,
          dayName: new Date(item.date).toString().split(" ")[0],
        };
        temp.push(data);
      });
    }
    setFetchedData(temp);
  };
  let renderedData = null;
  // if (fetchedData.length == 0) {
  //   renderedData = <div className="noDataFoundForChart"></div>;
  // } else {
  // }
  renderedData =
    fetchedData &&
    fetchedData.map((item, index) => {
      return (
        <PainChartBody
          key={index}
          takenTimes={item.takenTimes}
          day_number={item.dayNumber}
          dayName={item.dayName}
          width="calc(100%/7)"
          dotWidth={props.dotWidth}
          dotHeight={props.dotHeight}
          downloadPainChart={props.downloadPainChart}
          identifier={props.identifier}
        />
      );
    });

  useEffect(() => {
    fetchedDataFunc();
  }, []);
  return (
    <React.Fragment>
      {fetchedData.length > 0 && (
        <div
          className={
            props.downloadPainChart
              ? "painChart__container__download"
              : "painChart__container"
          }
          style={{ height: props.containerHeight && props.containerHeight }}
          id={props.id}
        >
          <div className="painChart__header">
            <div
              className={
                props.downloadPainChart
                  ? `painChart__headerText__download painChart__headerText`
                  : "painChart__headerText"
              }
            >
              {props.name}
            </div>
          </div>
          <div
            className="painChart__body"
            style={{ height: props.bodyHeight && props.bodyHeight }}
          >
            <div className="painChart__bodySidebar">
              <PainChartSidebar downloadPainChart={props.downloadPainChart} />
            </div>
            <div className="painChart__leftSidebarBottom">
              {/* <ButtonForChart>Mar 18</ButtonForChart> */}
            </div>
            <div className="painChart__bodyBody">{renderedData} </div>
            <div className="painChart__bodyRightSidebarBottom">
              {/* <ButtonForChart>Mar 24</ButtonForChart> */}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};

export default connect(mapStateToProps, null)(PainChart);
