import React, { useState, useEffect, useRef, Fragment } from "react";
import RangeChart from "../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/RangeChart/RangeChart";
import NumberScaleChart from "../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/NumberScaleChart/NumberScaleChart";
import ThreePointScaleChart from "../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/ThreePointScaleChart/PainChart";
import BinaryChart from "../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/BinaryChart/RashChart";
import SymptomIndexList from "../../../../../Components/screensComponent/MonitoringStatus/SymptomIndexList/SymptomIndexList";
// import "../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/SignsSymptomsCharts.css";
import { useFetch } from "../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { useFetchFunc } from "../../../../../HighOrderFunctions/FetchMethod/FetchFunction";

import {
  getClinicalEntitiesEndPoint,
  getAllVitals,
  getHospitalDetails,
  getPatientDetailsEndPoint,
} from "../../../../../HighOrderFunctions/APIs/allGetAPI";
import NoChartData from "../../../MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/NoChartData/NoChartData";
import { connect } from "react-redux";

import noChartMeasureErrorImage from "../../../../../AllIcons/noChartMeasureErrorImage.png";
import ReactToPrint from "react-to-print";
import "./MonitoringStatusCharts.css";
import MSNurseNotes from "../../../MonitoringStatusSidebar/MSNurseNotes/MSNurseNotes";
import PatientDetails from "./PatientDetails";
import Footer from "./Footer";
import { Medication } from "@mui/icons-material";
import MedicationChart from "../../../MonitoringStatusBody/SymptomComplianceCharts/ComplianceCharts/MedicationChart/MedicationChart";
import Header from "./Header";
import PatientVitalNSymptomChart from "./PatientVitalNSymptomChart/PatientVitalNSymptomChart";
import * as actions from "../../../../../Store/actions/MonitoringStatusAction";
import PdfLastPage from "./PdfLastPage/PdfLastPage";
const MonitoringStatusCharts = (props) => {
  const [chartsForAlertLength, setChartsForAlertLength] = useState(0);
  const [allVitals, setAllVitals] = useState([]);
  const [allSymptoms, setAllSymptoms] = useState([]);
  const [hospitalNo, setHospitalNo] = useState("");

  //Fetching the clinicalEntities on page mount
  const { response: clinicalEntities, isLoading } = useFetch(
    `${getClinicalEntitiesEndPoint}/${props.qrCodeFromStore}`,
    false
  );

  const { response: allVitalList, isLoading: isLoadingVitals } = useFetchFunc(
    `${getAllVitals}`,
    false
  );

  const { response: allHospitalDetails, isLoading: isLoadingHospitalDetails } =
    useFetchFunc(`${getHospitalDetails}`, false);

  useEffect(() => {
    if (allHospitalDetails && allHospitalDetails.emergency_number) {
      let number = allHospitalDetails.emergency_number.split(
        allHospitalDetails.isd
      );

      setHospitalNo(number[1]);
    }
    if (Array.isArray(clinicalEntities) && clinicalEntities.length > 0) {
      setChartsForAlertLength(clinicalEntities.length);
      props.setTotalGraphShouldRender(clinicalEntities.length);
    }
    //Collecting the all vitals name
    let vitalsName = null;
    if (Array.isArray(clinicalEntities) && clinicalEntities.length > 0) {
      vitalsName = allVitalList.map((item) => item.common_name);
    }

    if (vitalsName) {
      //all vitals data
      let vitals = clinicalEntities.filter((item) => {
        return vitalsName.includes(item.name) && item;
      });
      let allVitalData = [];
      let j = 0;
      if (vitals) {
        for (let i = 0; i < vitals.length; i = i + 4) {
          let slicedVitalData = null;

          if (vitals.length - i < 4) {
            slicedVitalData = vitals.slice(i);
            allVitalData.push({ vital: slicedVitalData });
          } else {
            slicedVitalData = vitals.slice(i + j, i + 4);
            allVitalData.push({ vital: slicedVitalData });
          }
        }

        setAllVitals(allVitalData);
      }

      // Spliting the symptoms data
      let symptoms = clinicalEntities.filter((item) => {
        return !vitalsName.includes(item.name) && item;
      });

      let allSymptomData = [];
      let k = 0;

      if (symptoms) {
        for (let i = 0; i < symptoms.length; i = i + 4) {
          let slicedSymptomData = null;
          if (symptoms.length - i < 4) {
            slicedSymptomData = symptoms.slice(i);
            allSymptomData.push({ symptom: slicedSymptomData });
          } else {
            slicedSymptomData = symptoms.slice(i + k, i + 4);
            allSymptomData.push({ symptom: slicedSymptomData });
          }
        }
        setAllSymptoms(allSymptomData);
      }
    }
    return () => {
      setAllVitals([]);
      setAllSymptoms([]);
      setHospitalNo("");
      props.cleanNoOfVitalsChartRendered(0);
      props.cleanNoOfSymptomsChartRendered(0);
    };
  }, [props.qrCodeFromStore, clinicalEntities.length, clinicalEntities]);

  if (isLoading) {
    return (
      <div className="signsNSymptoms__loading">
        <p>Loading.....</p>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div
        className="monitoringStatusCharts"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          id="html-2-pdfwrapper"
          style={{
            // border: "1px solid red",
            // height: "200vh",
            width: "32vw",
            fontSize: "20px !important",
            overflow: "scroll",
          }}
        >
          <PatientDetails
            setPatientData={props.setPatientData}
            hospitalNo={hospitalNo}
          />

          {allVitals &&
            allVitals.map((item, index) => (
              <PatientVitalNSymptomChart
                key={index}
                ClinicalEntities={item.vital}
                header={"Patient Vitals Chart"}
                hospitalNo={hospitalNo}
                identifier="vitals_charts"
              />
            ))}

          {allSymptoms &&
            allSymptoms.map((item, index) => (
              <PatientVitalNSymptomChart
                key={index}
                ClinicalEntities={item.symptom}
                header={"Patient Symptom Chart"}
                hospitalNo={hospitalNo}
                identifier="symptoms_charts"
              />
            ))}

          <div className="monitoringStatusCharts__compliance">
            <div className="monitoringStatusCharts__compliance__chart">
              <Header title=" Medication Compliance" />
              <MedicationChart
                minWidth="calc(100%/21)"
                capsuleWidth="20px"
                capsuleHeight="10px"
                height="180px"
                complianceLegendDownload={true}
              />
            </div>
            <div className="monitoringStatusCharts__compliance__footer">
              <Footer hospitalNo={hospitalNo} />
              <h3 className="page-break"></h3>
            </div>
          </div>

          <div className="monitoringStatusCharts__nursenotes__container">
            <div className="monitoringStatusCharts__nursenotes">
              <Header title="Nursing Notes" />
              <MSNurseNotes
                heading1="20px"
                heading2="17px"
                backgroundColor="lightgrey"
              />
            </div>
            <div className="monitoringStatusCharts__nursenotes__footer">
              <Footer hospitalNo={hospitalNo} paddingBottom="5px" />
              <h3 className="page-break"></h3>
            </div>
          </div>

          <PdfLastPage hospitalNo={hospitalNo} />
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cleanNoOfVitalsChartRendered: (count) =>
      dispatch(actions.getVitalsChartRendered(count)),
    cleanNoOfSymptomsChartRendered: (count) =>
      dispatch(actions.getSymptomsChartRendered(count)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitoringStatusCharts);
