
import React, { Component, Fragment } from "react";
import { symptomAttributes } from "../data";
import AttributesQuestionaire from "./AttributesQuestionaire";

export default class AttributesConversationTemplate extends Component {
  constructor(props) {
    super(props);
    this.datasource = props.data.data.conversation_template;
    this.attributes = props.data.data.attributes;
    this.symptom_question_id = props.data.data.symptom_question_id
    this.state = {};
    this.types = ["DEFAULT", "ELICIT_FROM_PATIENT"];

    // this.attributeTypeSelectBoxOptions = {
    //   items: { symptomAttributes },
    //   onValueChanged: (e) => {
    //   },
    // };
    // this.attributeUtteranceTypeSelectBoxOptions = {
    //   items: this.types,
    //   onValueChanged: (e) => {
    //   },
    // };
  }

  render() {
    return (
      <Fragment>
        {this.datasource.map((item, index) => {
          return (
            <AttributesQuestionaire
              attribute_name={item.name}
              type={item.type}
              value={item.value}
              bot_utterances={item.bot_utterances}
              symptom_question_id={this.symptom_question_id}
              key={index}
            ></AttributesQuestionaire>
          );
        })}
      </Fragment>
    );
  }
}
