export const stringToDateFunc = (dischargedDate) => {
  if (
    dischargedDate === "-" ||
    dischargedDate === "" ||
    dischargedDate === " " || 
    dischargedDate === null ||
    dischargedDate === undefined
    ) {
      // dischargedDate = new Date().toString();
      dischargedDate = new Date().toString();
      return "-";
    }
    dischargedDate = new Date(dischargedDate).toString();
    dischargedDate = dischargedDate.split(" ");
    if(dischargedDate[3].includes("-")){
      dischargedDate[3] = dischargedDate[3].toString().substring(1,5)
    }
  let dischargedDateRequired = `${dischargedDate[2]}-${dischargedDate[1]}-${dischargedDate[3]}`;
  return dischargedDateRequired;
};
export const stringToDateWithoutHiphenFunc = (dischargedDate) => {
  if (
    dischargedDate === "-" ||
    dischargedDate === "" ||
    dischargedDate === " " || 
    dischargedDate === null ||
    dischargedDate === undefined
  ) {
    // dischargedDate = new Date().toString();
    dischargedDate = new Date().toString();
    return "-";
  }
  dischargedDate = new Date(dischargedDate).toString();
  dischargedDate = dischargedDate.split(" ");
  let dischargedDateRequired = `${dischargedDate[2]} ${dischargedDate[1]} ${dischargedDate[3]}`;
  return dischargedDateRequired;
};
