import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const postFetchFunction = async (
  urlEndPoint,
  requestBody,
  accessToken,
  method = "POST"
) => {
  if (!navigator.onLine) {
    return;
  }
  try {
    let valueFetched = fetch(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${urlEndPoint}`,
      {
        method: method,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      });
    return valueFetched;
  } catch (err) {
    console.log("ERROR OCCURED", err);
    return err;
  }
};

export const usePostData = (urlEndpoint, requestBody) => {
  const { getAccessTokenSilently } = useAuth0();
  const [response, setResponse] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const getAuthToken = async () => {
    const token = await getAccessTokenSilently();
    return token;
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const token = await getAuthToken();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);
      let requestOptions = {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
            "Content-Type": "application/json",
          Authorization: `Bearer ${token} `,
        },
      };
      setIsLoading(true);
      try {
        const res = await fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/${urlEndpoint}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            setIsLoading(false);
            setResponse(result);
            return result;
          });
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return { response, error, isLoading };
};
