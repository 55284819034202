import React, { useState, useEffect } from "react";
import "./EditableCareGiver.css";
// import { updatePatientMedicineDetails } from "../../HighOrderFunctions/APIs/allPostAPI";
// import { postFetchFunction } from "../../HighOrderFunctions/FetchMethod/postFetchFunction";
import { useSelector } from "react-redux";
const Editable = ({
  text,
  type,
  placeholder,
  children,
  childRef,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  useEffect(() => {
    
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus();
      // alert("focues")
    }
  }, [isEditing, childRef]);
  
  const handleBlur = () => {
    setEditing(false);
    if (type == "input") {
      props.updateCareGiverDetailToBackend(props.arrIndex);
      // call function on coming out of  editing
    }
  };
  const handleKeyDown = (event, type) => {
    const { key } = event;
    const keys = ["Escape", "Tab"];
    const enterKey = "Enter";
    const allKeys = [...keys, enterKey];
    if (
      (type === "textarea" && keys.indexOf(key) > -1) ||
      (type !== "textarea" && allKeys.indexOf(key) > -1)
      ) {
        if (type == "input") {
          props.updateCareGiverDetailToBackend(props.arrIndex);
          // call function on coming out of  editing
        }
        setEditing(false);
      }
    };
    
    return (
    <section {...props}>
      {isEditing ? (
        <div
          onBlur={() => handleBlur()}
          onKeyDown={(e) => handleKeyDown(e, type)}
        >
          {children}
        </div>
      ) : (
        <button onClick={() => setEditing(true)}
        disabled={props.disabled}
        className="editableCareGiver__button"
        >
          <span className={`${text ? "text-black" : "text-gray-500"}`}>
            {text || placeholder || ""}
          </span>
        </button>
      )}
    </section>
  );
};

export default Editable;
