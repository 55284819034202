import React, { useEffect, useState } from "react";
import "./MFPBodyRender.css";
import MFPBody from "../MFPBody/MFPBody";
import { v4 as uuidv4 } from "uuid";
import { deleteMedicineEndPoint, enableMedicineEndPoint } from "../../../../HighOrderFunctions/APIs/allPostAPI";
import axios from "axios";
import { useSelector, connect } from "react-redux";
import { updateMedicineDetailsEndPoint } from "../../../../HighOrderFunctions/APIs/allPostAPI";
import { postFetchFunction } from "../../../../HighOrderFunctions/FetchMethod/postFetchFunction";
import { dayDifference } from "../../../../HighOrderFunctions/shared/dayDifference";
import { setMedicationNavlinkApproveStatus } from "../../../../Store/actions";
import { setUpdatedMedicineAfterApproved } from "../../../../Store/actions/MonitoringCapsule";
import { useDispatch } from "react-redux";
import { showNotificationWithMessage } from "../../../../Store/actions";
// Component containing multiple frequencies and schedule duration for 1 medicine
const MFPBodyRender = (props) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.accessToken);
    const qrCodeFromStore = useSelector((state) => state.patient.QRCode);

    const [medicineArray, setMedicineArray] = useState([]);

    const [errorInSavingMedicineArray, setErrorInSavingMedicineArray] = useState(
        []
    );
    const [formForExistingMedicineIsValid, setFormForExistingMedicineIsValid] =
        useState(false);
    const [medicineForPatientRender, setMedicineForPatientRender] = useState({
        brandName: {
            value: "",
        },
        genericName: {
            value: "",
        },
        duration: {
            value: "",
        },
        data: [],
    });
    useEffect(() => {
        let tempMedicineForPatientRender = {
            ...medicineForPatientRender,
            brandName: {
                value: props.brandName ? props.brandName : "",
            },
            genericName: {
                value: props.genericName ? props.genericName : "",
            },
            duration: {
                value: props.duration ? props.duration : "",
            },
            data: props.data,
        };
        setMedicineForPatientRender(tempMedicineForPatientRender);
        let tempArray = props.data && new Array(props.data.length).fill(false);
        let tempErrorInMedicineArray =
            props.data && new Array(props.data.length).fill(false);
        setErrorInSavingMedicineArray(tempErrorInMedicineArray);
        setMedicineArray(tempArray);
        return () => {
            setMedicineForPatientRender({});
        };
    }, []);

    // function to add new frequency and duration for existing 1 medicine
    const addNewExistingMedicineFunc = (brandName, generiName) => {
        let newData = {
            index: `${medicineForPatientRender["data"].length + 1}`,
            brand_name: brandName,
            generic_name: generiName,
            duration: medicineForPatientRender["duration"].value,
            day_end: "",
            day_start: "",
            dosage: "",
            frequency: "",
            instruction: { text: "", value: "" },
            entity: { symptomName: "", measureType: "" },
            medicine_id: "newMedicine-" + uuidv4(),
            newMedicine: true,
        };
        // array for checking whether all fields filled or not
        setMedicineArray((prev) => [...prev, false]);

        // adding new Data for same medicine
        let tempData = [...medicineForPatientRender["data"]];
        tempData.push(newData);
        let tempAddedMedicine = {
            brandName: medicineForPatientRender["brandName"].value,
            genericName: medicineForPatientRender["genericName"].value,
            duration: medicineForPatientRender["duration"].value,
            data: tempData,
        };
        props.updateAllMedicinesList(tempAddedMedicine, props.medicineIndex);
        setMedicineForPatientRender((prev) => {
            return { ...tempAddedMedicine };
        });
    };

    // function for validating non null value in medicine
    const checkMedicineFieldsValidated = (index, status, error = false) => {
        let temp = medicineArray;
        let tempErrorInMedicineArray = errorInSavingMedicineArray;
        tempErrorInMedicineArray[index] = error;
        let tempApproveErrorInMedicineArray = tempErrorInMedicineArray.every(
            (item) => {
                return item == false;
            }
        );
        temp[index] = status;

        let tempApproveCheck = temp.every((item) => {
            return item == true;
        });
        setMedicineArray(temp);

        setErrorInSavingMedicineArray(tempErrorInMedicineArray);
        setFormForExistingMedicineIsValid(tempApproveCheck);
        let tempApproveCheckWithoutError =
            tempApproveCheck && tempApproveErrorInMedicineArray;

        props.checkAllMedicineFieldsValidated(
            props.medicineIndex,
            tempApproveCheckWithoutError
        );
    };

    // function to save different frequencies ,schedules etc. of same medicine.
    const saveMedicine = async (updatedMedicineData, medicineId, index) => {
        props.setMedicationNavlinkApproveStatusFunc(false);

        // console.log("updatedMedicineData", updatedMedicineData, medicineId);

        let requestBody = null;
        if (updatedMedicineData["frequency"].value == "SOS") {
            if (!Number.isInteger(+medicineId)) {
                // for new medicine
                requestBody = {
                    qr_code: qrCodeFromStore,
                    is_insert: true,
                    are_medicine_in_edit_mode: props.isMonitoringApproved ? true : false,
                    medicineList: [
                        {
                            brand_name: updatedMedicineData["brandName"].value,
                            generic_name: updatedMedicineData["genericName"].value,
                            dosage: updatedMedicineData["dosage"].value,
                            entity_id: updatedMedicineData["entity"].value["id"],
                            frequency: {
                                schedule: updatedMedicineData["frequency"].value,
                                time_slot: updatedMedicineData["timeSlots"].value,
                                time_slot_id: updatedMedicineData["timeSlotsId"].value,
                            },

                            // instruction: updatedMedicineData["instruction"].value["value"],
                        },
                    ],
                };
            } else {
                requestBody = {
                    qr_code: qrCodeFromStore,
                    is_insert: false,
                    are_medicine_in_edit_mode: props.isMonitoringApproved ? true : false,
                    medicineList: [
                        {
                            brand_name: updatedMedicineData["brandName"].value,
                            generic_name: updatedMedicineData["genericName"].value,
                            dosage: updatedMedicineData["dosage"].value,
                            entity_id: updatedMedicineData["entity"].value["id"],
                            frequency: {
                                schedule: updatedMedicineData["frequency"].value,
                                time_slot: updatedMedicineData["timeSlots"].value,
                                time_slot_id: updatedMedicineData["timeSlotsId"].value,
                            },
                            medicine_id: medicineId,

                            // instruction: updatedMedicineData["instruction"].value["value"],
                        },
                    ],
                };
            }
        } else {
            if (!Number.isInteger(+medicineId)) {
                // for new medicine
                requestBody = {
                    qr_code: qrCodeFromStore,
                    is_insert: true,
                    are_medicine_in_edit_mode: props.isMonitoringApproved ? true : false,

                    medicineList: [
                        {
                            medicine_id: medicineId,
                            brand_name: updatedMedicineData["brandName"].value,
                            generic_name: updatedMedicineData["genericName"].value,
                            dosage: updatedMedicineData["dosage"].value,
                            frequency: {
                                schedule: updatedMedicineData["frequency"].value,
                                time_slot: updatedMedicineData["timeSlots"].value,
                                time_slot_id: updatedMedicineData["timeSlotsId"].value,
                            },

                            instruction: updatedMedicineData["instruction"].value["value"],
                            day_start: +updatedMedicineData["startDay"].value,
                            day_end: +updatedMedicineData["endDay"].value,
                            medicine_id: medicineId,
                        },
                    ],
                };
            } else {
                requestBody = {
                    qr_code: qrCodeFromStore,
                    is_insert: false,
                    are_medicine_in_edit_mode: props.isMonitoringApproved ? true : false,
                    medicineList: [
                        {
                            medicine_id: medicineId,
                            brand_name: updatedMedicineData["brandName"].value,
                            generic_name: updatedMedicineData["genericName"].value,
                            dosage: updatedMedicineData["dosage"].value,
                            frequency: {
                                schedule: updatedMedicineData["frequency"].value,
                                time_slot: updatedMedicineData["timeSlots"].value,
                                time_slot_id: updatedMedicineData["timeSlotsId"].value,
                            },

                            instruction: updatedMedicineData["instruction"].value["value"],
                            day_start: +updatedMedicineData["startDay"].value,
                            day_end: +updatedMedicineData["endDay"].value,
                            medicine_id: medicineId,
                        },
                    ],
                };
            }
        }

        let errorMessage = "";
        const updateMedicineDetailsToBackend = async (requestBody) => {
            try {
                const medicineUpdationResponse = await postFetchFunction(
                    updateMedicineDetailsEndPoint,
                    requestBody,
                    token
                );
                return medicineUpdationResponse;
            } catch (err) {
                console.log("Error: ", err);
                errorMessage = "Failed to update Medicine";
                dispatch(
                    showNotificationWithMessage({
                        variant: "warning",
                        message: "Failed to update Medicine",
                    })
                );
            }
        };

        let updatedMedicineResponse = await updateMedicineDetailsToBackend(
            requestBody
        );
        // console.log(updatedMedicineResponse);
        let error = false;
        let updatedMedicineId = "";
        if (updatedMedicineResponse) {
            if (updatedMedicineResponse.status == "200") {
                updatedMedicineId = updatedMedicineResponse["id"][0];
                errorMessage = "";
                error = false;
                dispatch(
                    showNotificationWithMessage({
                        variant: "success",
                        message: "Medicine updation Successful!",
                    })
                );
            } else if (updatedMedicineResponse.status !== "200") {
                errorMessage = updatedMedicineResponse["message"];
                // errorMessage = "";

                error = true;
                dispatch(
                    showNotificationWithMessage({
                        variant: "warning",
                        message: errorMessage,
                    })
                );
            }
        }
        let updatedMedicine = {
            key: `${uuidv4()}`,
            index: index,
            brand_name: updatedMedicineData["brandName"].value,
            generic_name: updatedMedicineData["genericName"].value,
            dosage: updatedMedicineData["dosage"].value,
            duration: dayDifference(
                0,
                +updatedMedicineData["startDay"].value,
                +updatedMedicineData["endDay"].value
            ),
            frequency: {
                schedule: updatedMedicineData["frequency"].value,
                time_slot: updatedMedicineData["timeSlots"].value,
                time_slot_id: updatedMedicineData["timeSlotsId"].value,
            },
            instruction: updatedMedicineData["instruction"].value,
            day_start: +updatedMedicineData["startDay"].value,
            day_end: +updatedMedicineData["endDay"].value,
            entity: updatedMedicineData["entity"].value,
            medicine_id: `${updatedMedicineId.toString().length > 0 ? updatedMedicineId : medicineId
                }`,
            errorMessage: errorMessage,
            error: error,
        };
        let tempMedicineForPatientRender = { ...medicineForPatientRender };
        let tempMedicineForPatientRenderData = tempMedicineForPatientRender["data"];
        tempMedicineForPatientRenderData[index] = updatedMedicine;

        let updatedTempMedicineForPatientRender = {
            brandName: {
                value: updatedMedicineData["brandName"].value,
            },
            genericName: {
                value: updatedMedicineData["genericName"].value,
            },
            duration: {
                value: updatedMedicineData["duration"].value,
            },
            data: tempMedicineForPatientRenderData,
        };
        setMedicineForPatientRender((prev) => {
            return {
                ...prev,
                updatedTempMedicineForPatientRender,
            };
        });
        console.log("errorMessage", errorMessage);
        let status = true && errorMessage.replaceAll(" ", "").length === 0;
        // console.log("Status : ", errorMessage);
        // checking medicine validation
        checkMedicineFieldsValidated(
            index,
            status,
            errorMessage.replace(" ", "").length > 0
        );
    };

    // function for deleting existing medicine if single medicine
    // else delete medicine with different frequencies on basis of their medicineId
    const deleteMedicine = (medicineId, index) => {
        let tempFilteredMedicinesListData = medicineForPatientRender["data"].filter(
            (item, indexOfMedicine) => {
                return index !== indexOfMedicine;
            }
        );
        let tempData = [...medicineForPatientRender["data"]];
        tempData = tempFilteredMedicinesListData;
        let tempAddedMedicine = {
            brandName: medicineForPatientRender["brandName"].value,
            genericName: medicineForPatientRender["genericName"].value,
            duration: medicineForPatientRender["duration"].value,
            data: tempData,
        };
        props.updateAllMedicinesList(tempAddedMedicine, props.medicineIndex);
        setMedicineForPatientRender((prev) => {
            return { ...tempAddedMedicine };
        });

        // array check for medicine
        let tempMedicineCheckArray = medicineArray.filter((item, itemIndex) => {
            return index !== itemIndex;
        });
        let tempApproveCheck = tempMedicineCheckArray.every((item) => {
            return item == true;
        });
        setFormForExistingMedicineIsValid(tempApproveCheck);
        if (
            props.medicineForPatientArrayCheck[props.medicineIndex] !==
            tempApproveCheck
        ) {
            props.checkAllMedicineFieldsValidated(
                props.medicineIndex,
                tempApproveCheck
            );
        }
        setMedicineArray((prev) => [...tempMedicineCheckArray]);
        var requestBody = {
            are_medicines_in_edit_mode: props.isMonitoringApproved ? true : false,
        };
        let are_medicines_in_edit_mode = props.isMonitoringApproved ? true : false;

        if (Number.isInteger(medicineId)) {
            axios.delete(
                `${window["getConfig"].BACKEND_SERVICE_URL}/${deleteMedicineEndPoint}/${medicineId}/${are_medicines_in_edit_mode}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token} `,
                    },
                    body: JSON.stringify(requestBody),
                }
            );
        }
        dispatch(
            showNotificationWithMessage({
                variant: "success",
                message: "Medicine Deletion, Successful!",
            })
        );
    };

    // function for deleting existing medicine if single medicine
    // else delete medicine with different frequencies on basis of their medicineId
    const disableMedicine = (medicineId, index) => {
        var requestBody = {
            are_medicines_in_edit_mode: props.isMonitoringApproved ? true : false,
        };
        let are_medicines_in_edit_mode = props.isMonitoringApproved ? true : false;

        if (Number.isInteger(medicineId)) {
            axios.delete(
                `${window["getConfig"].BACKEND_SERVICE_URL}/${deleteMedicineEndPoint}/${medicineId}/${are_medicines_in_edit_mode}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token} `,
                    },
                    body: JSON.stringify(requestBody),
                }
            );
        }
        dispatch(
            showNotificationWithMessage({
                variant: "success",
                message: "Medicine Disabled, Successful!",
            })
        );
    };

    const enableMedicine = (medicineId, index) => {
        var requestBody = {
            are_medicines_in_edit_mode: props.isMonitoringApproved ? true : false,
        };
        let are_medicines_in_edit_mode = props.isMonitoringApproved ? true : false;

        if (Number.isInteger(medicineId)) {
            axios.put(
                `${window["getConfig"].BACKEND_SERVICE_URL}/${enableMedicineEndPoint}/${medicineId}/${are_medicines_in_edit_mode}`,
                requestBody,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                }
            );
        }
        dispatch(
            showNotificationWithMessage({
                variant: "success",
                message: "Medicine Enabled, Successful!",
            })
        );
    };
    // rendering one or multiple data of single medicine
    let renderedData = [];

    // console.log("medicineForPatientRender", medicineForPatientRender["data"]);
    renderedData = medicineForPatientRender["data"].map((item, index) => {
        return (
            <MFPBody
                index={index}
                errorInSavingMedicineArray={errorInSavingMedicineArray}
                key={`${index + uuidv4()}`}
                brandName={item["brand_name"]}
                genericName={item["generic_name"]}
                duration={
                    medicineForPatientRender["duration"].value
                        ? medicineForPatientRender["duration"].value
                        : dayDifference(0, +item["day_start"].value, +item["day_end"].value)
                }
                dosage={item["dosage"] ? item["dosage"] : ""}
                startDay={item["day_start"] ? item["day_start"] : ""}
                endDay={item["day_end"] ? item["day_end"] : ""}
                // frequency={item["frequency"] ? item["frequency"] : ""}
                frequency={item["frequency"].schedule ? item["frequency"].schedule : ""}
                entity={
                    item["entity"]
                        ? item["entity"]
                        : { symptomName: "", measureType: "", id: "" }
                }
                instruction={item["instruction"]}
                showMedicine={index == 0}
                showAddNewMedicine={
                    index == medicineForPatientRender["data"].length - 1
                }
                medicineId={item["medicine_id"]}
                deleteMedicine={deleteMedicine}
                disableMedicine={disableMedicine}
                enableMedicine={enableMedicine}
                checkMedicineFieldsValidated={checkMedicineFieldsValidated}
                saveMedicine={saveMedicine}
                frequencyData={props.frequencyData}
                frequencyDataWithTimeSlot={props.frequencyDataWithTimeSlot}
                timeSlots={item.frequency.time_slot}
                timeSlotId={item.frequency.time_slot_id}
                instructionData={props.instructionData}
                clinicalEntitiesData={props.clinicalEntitiesData}
                errorMessage={item["errorMessage"]}
                keyForRenderingMedicine={
                    props.renderingForAlternateArray &&
                    props.renderingForAlternateArray?.[index]
                }
                mfpBodyRenderDataLength={medicineForPatientRender?.["data"].length}
                formForExistingMedicineIsValid={formForExistingMedicineIsValid}
                addNewExistingMedicineFunc={addNewExistingMedicineFunc}
                is_enabled={item.is_enabled}
            />
        );
    });
    return (
        <div className={`mfpBodyRender__container `}>
            <div className="mfpBodyRender__body">{renderedData}</div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,
        medicineUpdateAfterApproved:
            state.monitoringCapsule.medicineUpdateAfterApproved,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setMedicationNavlinkApproveStatusFunc: (status) =>
            dispatch(setMedicationNavlinkApproveStatus(status)),
        setUpdatedMedicineAfterApprovedFunc: (status) =>
            dispatch(setUpdatedMedicineAfterApproved(status)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MFPBodyRender);
