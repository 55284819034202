import {
  SYMPTOMLIST,
  SETDISCHARGECONTEXT_APPROVE_BUTTON,
  SETALERTTHRESHOLD_APPROVE_BUTTON,
  SETFOLLOWUPSCHEDULES_APPROVE_BUTTON,
  SETMEDICATION_APPROVE_BUTTON,
  SETDISCHARGE_NAVLINK_APPROVESTATUS,
  SETALERTTHRESHOLD_NAVLINK_APPROVESTATUS,
  SETFOLLOWUPSCHEDULES_NAVLINK_APPROVESTATUS,
  SETMEDICATION_NAVLINK_APPROVESTATUS,
  RESET_NAVLINK_APPROVESTATUS,
  IS_MONITORING_APPROVED,
  IS_MEDICATION_SCHEDULE_PAGE_RENDERED,
  SHALL_CREATE_REMINDER,
} from "../actions/actionTypes";

const initialState = {
  dischargeApproveButton: false,
  alertThresholdApproveButton: false,
  followUpSchedulesApproveButton: false,
  monitoringSchedulesApproveButton: false,
  dischargeNavlinkApproveStatus: false,
  alertThresholdNavlinkApproveStatus: false,
  followUpSchedulesNavlinkApproveStatus: false,
  medicationSchedulesNavlinkApproveStatus: false,
  isMonitoringApproved: false,
  medicineUpdateAfterApproved: false,
  shallCreateReminder: false,
};
// approve navlink card

const MonitoringCapsuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETDISCHARGECONTEXT_APPROVE_BUTTON:
      return {
        ...state,
        dischargeApproveButton: action.status,
      };

    case SETALERTTHRESHOLD_APPROVE_BUTTON:
      return {
        ...state,
        alertThresholdApproveButton: action.status,
      };
    case SETDISCHARGE_NAVLINK_APPROVESTATUS:
      return {
        ...state,
        dischargeNavlinkApproveStatus: action.status,
      };
    case SETFOLLOWUPSCHEDULES_NAVLINK_APPROVESTATUS:
      return {
        ...state,
        followUpSchedulesNavlinkApproveStatus: action.status,
      };

    case SETMEDICATION_NAVLINK_APPROVESTATUS:
      return {
        ...state,
        medicationSchedulesNavlinkApproveStatus: action.status,
      };

    case SETALERTTHRESHOLD_NAVLINK_APPROVESTATUS:
      return {
        ...state,
        alertThresholdNavlinkApproveStatus: action.status,
      };
    case RESET_NAVLINK_APPROVESTATUS:
      return {
        ...state,
        dischargeApproveButton: false,
        alertThresholdApproveButton: false,
        followUpSchedulesApproveButton: false,
        monitoringSchedulesApproveButton: false,
        dischargeNavlinkApproveStatus: false,
        alertThresholdNavlinkApproveStatus: false,
        followUpSchedulesNavlinkApproveStatus: false,
        medicationSchedulesNavlinkApproveStatus: false,
      };

    case IS_MONITORING_APPROVED:
      return {
        ...state,
        isMonitoringApproved: action.approveStatus,
        // isMonitoringApproved: true,
      };
    case IS_MEDICATION_SCHEDULE_PAGE_RENDERED:
      return {
        ...state,
        medicineUpdateAfterApproved: action.payload,
      };
    case SHALL_CREATE_REMINDER:
      return {
        ...state,
        shallCreateReminder: action.payload,
      };

    default: {
      return state;
    }
  }
};
export default MonitoringCapsuleReducer;
