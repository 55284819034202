import React, { Component } from 'react'
import ErrorModal from "../modals/ErrorModal/ErrorModal";
import './ErrorBoundary.css';
 class ErrorBoundary extends Component {
     constructor(props) {
         super(props)
     
         this.state = {
              hasError:false
         }
     }
     static getDerivedStateFromError(error){
         return {
             hasError:true
         }
     }
     
    render() {
       if(this.state.hasError){
          return  <div className="errorBoundary__error">
              <div className="errorBoundary__text">
              SomeThing went wrong!!!

              </div>
              </div> 
       }
       return this.props.children
    }
}

export default ErrorBoundary
