import React from "react";
import OnCallRegistration from "./OnCallRegistration";
import NavbarOnCallRegistrationScreen from "../../Components/Navbar/NavbarScreens/NavbarOnCallRegistrationScreen/NavbarOnCallRegistrationScreen";
import './OnCallRegistrationWithNavbar.css'
const OnCallRegistrationWithNavbar = () => {
  return (
    <div className="onCallRegistrationWithNavbar__container">
      <div className="onCallRegistrationWithNavbar__vEngageNavbarHeader">
        <NavbarOnCallRegistrationScreen />
      </div>
      <div className="onCallRegistrationWithNavbar__bodyContent">
        <OnCallRegistration />
      </div>
      <div className="onCallRegistrationWithNavbar__footer"></div>
    </div>
  );
};

export default OnCallRegistrationWithNavbar;
