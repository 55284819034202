import React, { useState, useEffect } from "react";
import "./Editable.css";
import { updatePatientMedicineDetails } from "../../HighOrderFunctions/APIs/allPostAPI";
import { postFetchFunction } from "../../HighOrderFunctions/FetchMethod/postFetchFunction";
import { useSelector } from "react-redux";
const Editable = ({
  text,
  type,
  placeholder,
  children,
  childRef,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  const accessToken = useSelector(state=>state.auth.accessToken)
  useEffect(() => {
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus();
    }
  }, [isEditing, childRef]);
  const updateMedicineDetails = async () => {
    try {
      let urlEndPoint = `${updatePatientMedicineDetails}/${props.medicineid}`;
      let formToBeSend = null;
      if (props.keyName == "brand_name") {
        formToBeSend = {
          brand_name: text,
        };
      } else if (props.keyName == "generic_name") {
        formToBeSend = {
          generic_name: text,
        };
      } else if (props.keyName == "dosage") {
        formToBeSend = {
          dosage: text,
        };
      }
      if (formToBeSend !== null) {
        await postFetchFunction(urlEndPoint, formToBeSend, accessToken);
      }
    } catch (err) {}
  };
  const handleBlur = () => {
    setEditing(false);
    if (type == "input") {
      if (props.medicineid) {
        updateMedicineDetails();
      }
    }
  };
  const handleKeyDown = (event, type) => {
    const { key } = event;
    const keys = ["Escape", "Tab"];
    const enterKey = "Enter";
    const allKeys = [...keys, enterKey];
    if (
      (type === "textarea" && keys.indexOf(key) > -1) ||
      (type !== "textarea" && allKeys.indexOf(key) > -1)
    ) {
      if (type == "input") {
        if (props.medicineid) {
          updateMedicineDetails();
        }
      }
      setEditing(false);
    }
  };

  return (
    <section {...props}>
      {isEditing ? (
        <div
          onBlur={() => handleBlur()}
          onKeyDown={(e) => handleKeyDown(e, type)}
        >
          {children}
        </div>
      ) : (
        <div onClick={() => setEditing(true)}>
          <span className={`${text ? "text-black" : "text-gray-500"}`}>
            {text || placeholder || ""}
          </span>
        </div>
      )}
    </section>
  );
};

export default Editable;
