import React,{useState} from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  RequiredRule,
  FormItem,


} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import {measureTypes} from '../../data';
import { useDispatch } from "react-redux";
import { showNotificationWithMessage } from "../../../Store/actions/DashboardAction";





// This component will apply CRUD in (t_ref_sns_entity)

const SNSEntity = () => {
  const [searchText,setSearchText] = useState("");
  const dispatch = useDispatch();
  function sendRequest(url, method, data) {
    method = method || "GET";
    data = data || {};

    if (method === "GET") {
      return fetch(url, {
        method: method,
        credentials: "include",
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) {
            console.log(result);
            return json.data;
          }
          throw json.Message;
        })
      );
    }

    // const params = Object.keys(data).map((key) => {
    //   return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
    // }).join('&');

    return fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          let temp = JSON.parse(text);
          if(temp.error){
            dispatch(
              showNotificationWithMessage({
                variant: "warning",
                message: temp.error,
              })
            );
          }
          return text && JSON.parse(text);
        });
      } else {
        return result.json().then((json) => {
          console.log("RESULT1: ", json);

          throw json.Message;
        });
      }
    });
  }
  const store = new CustomStore({
    key: "t_ref_sns_entity_id",
    load: function (loadOptions) {
      if (loadOptions.take !==undefined ) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/sns-entity?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`

      )
        .then((response) => response.json())
        .then((data) => {
          console.log("SNS Entity Data",data)
          return {
            data: data.data,
            totalCount: data.totalCount,
            //totalCount: 56,
            // summary: data.summary,
            // groupCount: data.groupCount,
            // {data:[{},{}],
            // totalCount:10}
          };
        })
        .catch(() => {
          throw "Data Loading Error";
        });
      }
    },
    insert: (values) =>
      sendRequest(`${window["getConfig"].BACKEND_SERVICE_URL}/sns-entity`, "POST", values),
    update: (key, values) =>{

        sendRequest(
            `${window["getConfig"].BACKEND_SERVICE_URL}/sns-entity/${key}`,
            "PUT",
            values
            )   
        },
    remove: (key) =>
      sendRequest(`${window["getConfig"].BACKEND_SERVICE_URL}/sns-entity/${key}`, "DELETE"),
  });
  const calculateCellValueFunc = data=>{
      if(data.sns_entity_measure_type ==1 ){
          return "NUMBER_SCALE"
        }
        else if (data.sns_entity_measure_type ==2){
          return "BINARY"

      }else if(data.sns_entity_measure_type ==3){
          return "THREE_POINT_SCALE"
      }
  }
  const onOptionChanged = (e) => {
    console.log("EVent on option changed: ",e)
    if(e.fullName === "searchPanel.text") {
        setSearchText(e.value);
    }
  }
  return (
    <DataGrid
      // width={"50%"}
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnResizing={true}
      allowColumnReordering={false}
      onOptionChanged={onOptionChanged}

    >
       <SearchPanel visible={true} 
      text={searchText}
      />
      <Editing
        refreshMode="reshape"
        mode="popup"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />

      <Column
      width={90}
        dataField="t_ref_sns_entity_id"
        dataType="number"
        caption="sns_id"
        
        allowEditing={false}
      ></Column>
      <Column dataField="sns_entity_name" dataType="string"></Column>
      <Column dataField="sns_entity_measure_type" dataType="string">
        <Lookup
          dataSource={measureTypes}
          displayExpr="name"
          valueExpr="name"
        />
        <RequiredRule />

        <FormItem colSpan={2}></FormItem>
      </Column>
      <Paging defaultPageSize={15} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[12, 15, 20]} />
    </DataGrid>
  );
};
// calculateCellValue={calculateCellValueFunc}
export default SNSEntity;
