import React, { useState, useEffect } from "react";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import Image from 'react-bootstrap/Image'
import "./PreviewPopup.scss";
const PreviewPopup = (props) => {
  console.log("PreviewPopup props",props)
  const [showEditMode, setShowEditMode] = useState(false);
  const [previewState, setPreviewState] = useState({
    currentJson: {},
    popupVisible: false,
    positionOf: "",
  });
  useEffect(() => {
    setPreviewState((prev) => {
      return {
        ...prev,
        currentJson: props.value && props.value,
        popupVisible: props.visibility && props.visibility,
      };
    });
    return () => {};
  }, [props.visibility]);
  const showPopup = () => {
    setPreviewState((prev) => {
      return {
        ...prev,
        currentJson: props.value,
        popupVisible: true,
      };
    });
  };
  useEffect(() => {
    if (props.visibility == true) {
      showPopup();
    }
  }, [props.visibility]);
  const hidePopup = () => {
    props.setVisibility(false);
    setPreviewState((prev) => {
      return {
        ...prev,
        currentJson: {},
        popupVisible: false,
      };
    });
  };

  const closeButtonOptions = {
    text: "Close",
    onClick: hidePopup,
  };

  return (
    <Popup
      visible={previewState.popupVisible}
      onHiding={hidePopup}
      dragEnabled={true}
      closeOnOutsideClick={true}
      showCloseButton={true}
      container=".dx-viewport"
      width={"50vw"}
      height={"70vh"}
      title={props.imageDetails.creation_date}
    >
      <Position at="center" my="center" />

      {/* <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        options={closeButtonOptions}
      /> */}

      <div className="previewPopup__container">
        <Image
          style={{width:"25vw", height: "50vh", textAlign: "center" }}
          src={props.imageDetails.attachment_details}
          alt="image uploaded"
          fluid
        />
      </div>
      <div className="previewPopup__imageBox">
        <div className="previewPopup__imageBoxForText">
          <div className="previewPopup__imageBoxText">
            {props.imageDetails?.utterance_before_translation}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default PreviewPopup;
