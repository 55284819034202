import React, { useState, useEffect } from "react";
import "./SummaryOfSymptoms.css";
import{connect,useSelector} from 'react-redux'
import { getSymptomSummaryEndPoint } from "../../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";

const SummaryOfSymptoms = (props) => {
  const [loading,setLoading] = useState(false);
  const accessToken = useSelector(state=>state.auth.accessToken)
  const [fetchedData, setFetchedData] = useState([]);

  const fetchedDataFunc = async () => {
    setLoading(true);
    let fetchedValue = await getFetchFunction(
      `${getSymptomSummaryEndPoint}/${props.qrCodeFromStore}/${props.duration}`,accessToken
    );
    setFetchedData(fetchedValue);
    setLoading(false)
  };
  useEffect(() => {
    fetchedDataFunc();
    
  }, []);
  let qrCodeFromStore = props.qrCodeFromStore;
  let durationPassed = props.duration;
  useEffect(() => {
    fetchedDataFunc();
  }, [durationPassed, qrCodeFromStore]);
  let renderedData = null;
  renderedData = Object.keys(fetchedData).map((item,index) => {
    let itemData = fetchedData[`${item}`];
    return (
      <div
      key={index}
        className="summaryOfSymptoms__bodyText"
        dangerouslySetInnerHTML={{ __html: `${itemData}` }}
      />
    );
  });
  if (Object.keys(fetchedData).length == 0 && !loading) {
    return (
      <div className="summaryOfEvents__container">
        <div className="summaryOfEvents__header">Current Symptoms
        <span className="summaryOfSymptoms__lastDuration">
           (last 72 hours)
          </span>
           </div>
        <div className="summaryOfEvents__body">
          {" "}
          <div className="summaryOfEvents__noData">
            No symptoms logged so far
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="summaryOfSymptoms__container">
      <div className="summaryOfSymptoms__header">Current Symptoms  
       <span className="summaryOfSymptoms__lastDuration">
            (last 72 hours)
          </span>
      </div>
      <div className="summaryOfSymptoms__body">
       
        {renderedData}
        </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};

export default connect(mapStateToProps, null)(SummaryOfSymptoms);

