import React, { useState, useEffect } from "react";
import DischargeDetailsMainContainer from "../../../../Components/screensComponent/patientAlerts/PatientAlertsBody/DischargeDetails/dischargeDetailsMainContainer/DischargeDetailsMainContainer";
import DischargeDetailsFooter from "../../../../Components/screensComponent/patientAlerts/PatientAlertsBody/DischargeDetails/DischargeDetailsFooter/DischargeDetailsFooter";
import { connect } from "react-redux";
import * as actions from "../../../../Store/actions/index";
import { stringToDateFunc } from "../../../../HighOrderFunctions/stringToDateFunc";
import { getExerciseDetailsEndPoint } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import "./CaseDetails.css";
import { setDischargeContextApproveButton } from "../../../../Store/actions/index";
const CaseDetails = (props) => {

  // local state used for showing message on basis of if each fields are filled or not
  const [allParametersChecked,setAllParametersChecked] = useState(false);

  // calling api for fetching diet,diagnosis,exercise, etc. all details
  const { response, isLoading } = useFetch(
    `${getExerciseDetailsEndPoint}/${props.qrCodeFromStore}`
  );

  let renderedData = [];


  // DischargeDetailsMainContainer component contains diagnosis, diet , exercise 
  renderedData =
    Array.isArray(response) &&
    response.map((item, index) => {
      props.setDateDurationToStoreFunc(
        stringToDateFunc(item["dischargeDate"]),
        item["duration"]
      );
      let filteredMedicalDevices =item["medicalDevices"] && item["medicalDevices"].filter((item,index)=>item !=="None");
      return (
        <DischargeDetailsMainContainer
          key={index}
          diagnosis={item["diagnosis"]}
          dischargeContext={item["dischargeContext"]}
          medicalDevices={item["medicalDevices"]? [...new Set([...filteredMedicalDevices])]:[]}
          diet={item["diet"]}
          exercise={item["Exercise"]}
          recoveryTool={item["recoveryTool"]}
          setAllParametersChecked = {setAllParametersChecked}
        />
      );
    });

  return (
    <div className="caseDetails">
      <div className="caseDetails__header">
        <p className="caseDetails__name">Case details</p>
        {!props.dischargeNavlinkApproveStatus && !isLoading && allParametersChecked && (
          <div className="error-message">
            Some important details are missing. Please complete the details.
          </div>
        )}
      </div>
      <div className="caseDetails__mainContainer">{renderedData}</div>

      <div className="caseDetails__appointmentCall">
        <DischargeDetailsFooter />
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDateDurationToStoreFunc: (date, duration) =>
      dispatch(actions.setDateDuration(date, duration)),
    setDischargeContextApproveButton: (status) =>
      dispatch(setDischargeContextApproveButton(status)),
  };
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    dischargeNavlinkApproveStatus:
      state.monitoringCapsule.dischargeNavlinkApproveStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetails);
