import React from "react";
import './Capsule.css';
const Capsule = (props) => {
  return (
    <div className="capsule__container" key={Math.random(1,1000)} style={{backgroundColor:props.bgcolor}}>
      <div className="capsule" >
       {props.name.split("_").map((item,index)=> <p key={index} className="capsule__text">{item}</p>)}
      </div>
    </div>
  );
};

export default Capsule;
