import React, { useState, useEffect } from "react";
import "./RashChart.css";
import rashIcon from "../../../../../../AllIcons/rashIcon.png";
import { getRashChartEndPoint } from "../../../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import RashChartBody from "./RashChartBody/RashChartBody";
import { connect, useSelector } from "react-redux";
import ButtonForChart from "../../../../../../Components/Buttons/ButtonForChart/ButtonForChart";
import { capitalizeFirstLetter } from "../../../../../../HighOrderFunctions/shared/capitalizeFirstLetter";
import wrongDataIcon from "../../../../../../AllIcons/wrongDataWarning.png";
const RashChart = (props) => {
  const [fetchedData, setFetchedData] = useState([]);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [showWrongDataLabel, setShowWrongDataLabel] = useState(false);
  const fetchedDataFunc = async () => {
    let fetchedValue = await getFetchFunction(
      `${getRashChartEndPoint}/${props.qrCodeFromStore}/${props.name}`,
      accessToken
    );
    let temp = [];
    var days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    if (Array.isArray(fetchedValue?.daywise_data) == true) {
      fetchedValue.daywise_data.map((item, index) => {
        let takenTimes = [];
        item.data.map((severityStatus) => {
          if (severityStatus.measure == "is_present") {
            let rashStatus = {
              time: severityStatus.time,
              date: severityStatus.date,
              severity: severityStatus.measure,
              onset: severityStatus.onset,
              site: severityStatus.site,
              character: severityStatus.character,
            };
            return takenTimes.push(rashStatus);
          } else {
            setShowWrongDataLabel(true);
            let rashStatus = {
              time: severityStatus.time,
              date: severityStatus.date,
              severity: "wrong_data",
              onset: severityStatus.onset,
              site: severityStatus.site,
              character: severityStatus.character,
            };
            return takenTimes.push(rashStatus);
          }
        });
        let data = {
          takenTimes: takenTimes,
          dayNumber: item.day_number,
          dayName: new Date(item.date).toString().split(" ")[0],
        };
        temp.push(data);
      });
    }
    setFetchedData(temp);
  };

  let renderedData = null;
  renderedData =
    fetchedData &&
    fetchedData.map((item, index) => {
      return (
        <RashChartBody
          key={index}
          takenTimes={item.takenTimes}
          day_number={item.dayNumber}
          dayName={item.dayName}
          iconWidth={props.iconWidth && props.iconWidth}
          iconHeight={props.iconHeight && props.iconHeight}
          downloadBinaryChart={props.downloadBinaryChart}
          identifier={props.identifier}
        />
      );
    });

  useEffect(() => {
    fetchedDataFunc();
    return () => {
      setFetchedData([]);
    };
  }, []);
  return (
    <React.Fragment>
      {fetchedData.length > 0 && (
        <div
          className="rashChart__container"
          style={{ height: props.containerHeight && props.containerHeight }}
          id={props.id}
        >
          <div
            className="rashChart__header"
            style={{
              paddingTop: props.padding && props.padding,
              paddingBottom: props.padding && props.padding,
            }}
          >
            <div
              className={
                props.downloadBinaryChart
                  ? `rashChart__headerHeading__download rashChart__headerHeading`
                  : "rashChart__headerHeading"
              }
            >
              {props.name}
            </div>
            <div className="rashChart__headerBody">
              <div className="rashChart__headerBodyIcons">
                <div className="rashChart__headerBodyIcon">
                  <img
                    src={rashIcon}
                    width={props.iconWidth ? props.iconWidth : "30"}
                    height={props.iconHeight ? props.iconHeight : "30"}
                  />
                </div>
                <div
                  className={
                    props.downloadBinaryChart
                      ? `rashChart__headerBodyText__download rashChart__headerBodyText`
                      : "rashChart__headerBodyText"
                  }
                >
                  {capitalizeFirstLetter(props.name)}{" "}
                  <span>
                    {" "}
                    {props.downloadBinaryChart ? "reported" : "occured"}
                  </span>
                </div>
              </div>
              {showWrongDataLabel && (
                <div className="rashChart__headerBodyIcons">
                  <div className="rashChart__headerBodyIcon">
                    <img
                      src={wrongDataIcon}
                      width={props.iconWidth ? props.iconWidth : "30"}
                      height={props.iconHeight ? props.iconHeight : "30"}
                    />
                  </div>
                  <div className="rashChart__headerBodyText">Wrong data</div>
                </div>
              )}
            </div>
          </div>
          <div className="rashChart__body">
            <div className="rashChart__bodySidebar"></div>
            <div className="rashChart__leftSidebarBottom">
              {/* <ButtonForChart>Mar 18</ButtonForChart> */}
            </div>
            <div
              className="rashChart__bodyBody"
              style={{
                paddingTop: props.bodyBodyPadding && props.bodyBodyPadding,
              }}
            >
              {renderedData}{" "}
            </div>
            <div className="rashChart__bodyRightSidebarBottom">
              {/* <ButtonForChart>Mar 24</ButtonForChart> */}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};

export default connect(mapStateToProps, null)(RashChart);
