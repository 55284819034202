
import React, { Component, Fragment } from "react";
import { symptomAttributes } from "../../data";
import MonitoringAttributesQuestionaire from "./MonitoringAttributesQuestionaire";

export default class AttributesConversationTemplate extends Component {
  constructor(props) {
    super(props);
    this.datasource = props.data.data.conversation_template;
    this.monitoring_question_id = props.data.data.monitoring_question_id
    this.state = {};
    this.dayConversationUtterAttributes = this.dayConversationUtterAttributes.bind(this);

  }
  dayConversationUtterAttributes = ()=>{

   return this.datasource.map((item, index) => {
      return (
        <MonitoringAttributesQuestionaire
        attribute_name={item.name}
        type={item.type}
        value={item.value}
        bot_utterances={item.bot_utterances}
        monitoring_question_id={this.monitoring_question_id}
        key={index}
        dayNumber ={item.day_number}
        ></MonitoringAttributesQuestionaire>
        );
      })
    } 

  render() {
    let renderedDayConversationUtterAttributes = this.dayConversationUtterAttributes();
    return (
      <Fragment>
       {/* {this.dayConversationUtterAttributes()} */}
       {renderedDayConversationUtterAttributes}
      </Fragment>
    );
  }
}
