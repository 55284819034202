import React,{useState} from "react";
import "./ChatScreen.css";
import ChatHeader from "./ChatHeader/ChatHeader";
import ChatBody from "./ChatBody/ChatBody";
import ChatSidebar from "./ChatSidebar/ChatSidebar";

// This component will be used to show All Chat Transcripts 
const ChatScreen = (props) => {
  const [scrollChatToTop,setScrollChatToTop] = useState(false);
  const [chatShowLanguage,setChatShowLanguage] = useState("original");
  
  return (
    <div className="chatScreen__container">
      <div className="chatScreen__header">
        <ChatHeader
         chatShowLanguage={chatShowLanguage}
         setChatShowLanguage={setChatShowLanguage}
        />
      </div>
      <div className="chatScreen__body">
        <ChatBody 
        scrollChatToTop={scrollChatToTop}
        scrollChatToBottom={props.scrollChatToBottom}
        setScrollChatToTop={setScrollChatToTop}
        setScrollChatToBottom={props.setScrollChatToBottom}
        chatShowLanguage={chatShowLanguage}
       
        />
      </div>
      <div className="chatScreen__sidebar">
        <ChatSidebar />
      </div>
    </div>
  );
};

export default ChatScreen;
