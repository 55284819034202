import React, { useState, useEffect} from "react";

import FollowupActionsCard from "../../../../Components/Card/FollowupActionsCard/FollowupActionsCard";
import ButtonWBorder from "../../../../Components/Buttons/ButtonWBorder/ButtonWBorder";
import { v4 as uuidv4 } from "uuid";

import { connect, useSelector } from "react-redux";
import { getFollowUpDetailsEndPoint,getPatientDetailsEndPoint } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { setFollowUpSchedulesNavlinkApproveStatus } from "../../../../Store/actions/index";
import Loader from "../../../../Loader/LoaderThreeDot";
import {
  deleteFollowupEndPoint,
  insertUpdateFollowupEndPoint,
} from "../../../../HighOrderFunctions/APIs/allPostAPI";
import axios from "axios";
import "./FollowUpActions.css";
const FollowUpActions = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkArray, setCheckArray] = useState([]);
  const [followUpApproved, setFollowUpApproved] = useState(false);
  const [followUpFetched, setFollowUpFetched] = useState(false);
  const [saveAlertActive, setSaveAlertActive] = useState(true);
  const [rejectedSavedId, setRejectedSavedId] = useState(-1);
  
   
  // function to fetch list of all followUps 
  const fetchedDataFunc = async () => {
    setLoading(true);
    let fetchedValue = await getFetchFunction(
      `${getFollowUpDetailsEndPoint}/${props.qrCodeFromStore}`,
      accessToken
    );
    setLoading(false);
    if (Array.isArray(fetchedValue) == true) {
      setFetchedData(fetchedValue);
      let tempArray = new Array(fetchedValue.length).fill(true);
      setCheckArray(tempArray);

      setFollowUpFetched(true);
      if (fetchedValue.length == 0) {
        setFollowUpApproved(true);
        props.setFollowUpSchedulesNavlinkApproveStatusFunc(true);
      }
    }
  };
  useEffect(() => {
    fetchedDataFunc();
  }, []);

  // function for validating non null value in follow up
  const checkArrayUpdate = (index, status) => {
    let temp = checkArray;
    temp[index] = status;
    let tempApproveCheck = temp.every((item) => {
      return item == true;
    });
    setCheckArray(temp);
    setFollowUpApproved(tempApproveCheck);
    props.setFollowUpSchedulesNavlinkApproveStatusFunc(tempApproveCheck);
  };


  // function to add new Followup Card
  const addNewScheduleCardFunc = () => {
    let newScheduleForm = {
      type: "",
      followupLabel: "",
      followupDetails: "",
      date: "",
      id: "new-" + uuidv4(),
      newScheduleCard: true,
    };

    setFetchedData((prev) => {
      let currentArray = prev;
      currentArray.push({
        ...newScheduleForm,
        index: `${prev.length + 1}`,
      });
      return [...currentArray];
    });
    setCheckArray((prev) => [...prev, false]);
    setSaveAlertActive(true);
  };

  // function to save and update new or existing individual followup  card
  const saveFollowupFunc = (value, id, index) => {
    setFetchedData((prev) => {
      let currentArray = prev;
      currentArray[index] = value;
      return currentArray;
    });
    let valueToBeSaved = value;
    if (id?.toString().includes("new") === true) delete valueToBeSaved.id;
    let requestBody = {
      ...valueToBeSaved,
      qr_code: `${props.qrCodeFromStore}`,
    };
    axios
      .post(
        `${window["getConfig"].BACKEND_SERVICE_URL}/${insertUpdateFollowupEndPoint}`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken} `,
          },
        }
      )
      .then(function (response) {

        setFetchedData((prev) => {
          let currentArray = prev;
          let tempModifiedArrayElement = value;
          tempModifiedArrayElement["id"] = response.data.id;
          currentArray[index] = tempModifiedArrayElement;
          return [...currentArray];
        });
        if (response.data.status == "100") {
          setRejectedSavedId(index);
        } else {
          setRejectedSavedId(-1);
        }
        checkArrayUpdate(index, true);
        return response.data;
      });
  };

  // function to edit existing followup card
  const deleteScheduleCardFunc = (itemId, itemIndex) => {
    let tempFollowupsArray = fetchedData.filter((item, index) => {
      return index !== itemIndex;
    });
    let tempCheckArray = checkArray.filter((item, index) => {
      return index !== itemIndex;
    });
    setCheckArray(tempCheckArray);
    if (Number.isInteger(+itemId)) {
      axios.delete(
        `${window["getConfig"].BACKEND_SERVICE_URL}/${deleteFollowupEndPoint}/${itemId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken} `,
          },
        }
      );
    }
    setFetchedData(tempFollowupsArray);
  };


  // rendering followup cards 
  let renderedData = [];
  if (loading == true) {
    renderedData = (
      <div className="noFollowupActions">
        <Loader />
      </div>
    );
  } else if (fetchedData.length == 0 && followUpFetched && !loading) {
    renderedData = <div className="noFollowupActions">No Followup Available</div>;
  } else {
  renderedData =
    followUpFetched &&
    fetchedData.map((item, index) => {
      return (
        <FollowupActionsCard
          key={`${uuidv4()}`}
          type={item["type"]}
          followupLabel={item["followupLabel"]}
          followupDetails={item["followupDetails"]}
          date={item["date"] !== "1900-01-01T10:00:00" ? item["date"] : ""}
          followupId={item["id"]}
          newScheduleCard={item["newScheduleCard"]}
          checkArrayUpdate={checkArrayUpdate}
          index={index}
          deleteScheduleCardFunc={deleteScheduleCardFunc}
          rejectedSaving={index == rejectedSavedId}
          saveFollowupFunc={saveFollowupFunc}
        />
      );
    });
  }

  return (
    <div className="followUpActions">
      <div className="followUpActions__followUpActionText">
        <div className="followUpActions__textName">Follow-up actions</div>
        <div className="followUpActions__errorMessage">
          {!followUpApproved && fetchedData.length > 0 && (
            <div className="error-message">
              Some important details are missing. Please complete the details.
            </div>
          )}
        </div>
        <div className="followUpActions__addFollowup">
          <ButtonWBorder
            name="Add New Followup"
            clicked={addNewScheduleCardFunc}
            disabled={!followUpApproved || props.isMonitoringApproved}
            width="130px"
            passedClass={`${props.isMonitoringApproved && "button__disabledDisplayNone"}`}

          />
        </div>
      </div>

      <div className="followUpActions__followUpContainer">
        {renderedData}
        <div style={{ marginTop: "30px" }}></div>{" "}
      </div>
      <div className="followUpActions__followUpContainerEmpty"> </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setFollowUpSchedulesNavlinkApproveStatusFunc: (status) =>
      dispatch(setFollowUpSchedulesNavlinkApproveStatus(status)),
  };
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpActions);
