import React, { useState, useEffect } from "react";
import SearchDevExtreme from "./SearchDevExtreme";
import { getAllSymptomsEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { dynamicSort } from "../../../HighOrderFunctions/dynamicSort";
import {useSelector} from 'react-redux';
function SymptomsListDropdown(props) {

  const accessToken = useSelector(state=>state.auth.accessToken);
  let {response,isLoading} = useFetch(`${getAllSymptomsEndPoint}`,false)
  if(Array.isArray(response) && response.length > 0){
    let sortedData = response.sort(dynamicSort("symptomName"));
    response = sortedData;
  }else{
    response = [];
  }

  const updateSymptom = (updatedSymptom) => {

    props.setValue(updatedSymptom);
   
  };
  return (
    <div className="dropdownMainFile" style={{ width: props.width }}>
      <SearchDevExtreme
        placeholder="Select new Alert"
        optionsFetched={response}
        displayName="symptomName"
        displayExpr="symptomName"
        valueExpr="symptomName"
        updatedSelectedValue={updateSymptom}
      />
    </div>
  );
}

export default SymptomsListDropdown;
