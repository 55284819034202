import React, { useEffect, useState, useCallback } from "react";
import DischargePatientAlert from "./DischargePatientAlert/DischargePatientAlert";
import ApproveMonitoringSchedules from "./ApproveMonitoringSchedules/ApproveMonitoringSchedules";
import ReviewCallForDay from "./ReviewCallForDay/ReviewCallForDay";
import PatientUnderMonitoring from "./PatientUnderMonitoring/PatientUnderMonitoring";
import "./Dashboard.css";
import ErrorModal from "../../modals/ErrorModal/ErrorModal";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import { connect } from "react-redux";
import * as actions from '../../Store/actions/index'
const Dashboard = (props) => {
  const [dashboardOnlineStatus, setDashboardOnlineStatus] = useState(
    navigator.onLine
  );

  useEffect(() => {
    // redux function to reset patient details and reseting Approve Button to false
    props.resetPatientStore();
      props.resetNavLinkApproveStatus();
  }, [])
  if (!dashboardOnlineStatus) {
    return (
      <ErrorModal
        setOnlineStatus={setDashboardOnlineStatus}
        message="Please check your Network Connection !!"
      />
    );
  }

  return (
    <div className="dashboard">
      <div className="dashboard__dischargePatientAlert">
         {/* Component for showing list of patients whose alerts has raised */}
        <ErrorBoundary>
          <DischargePatientAlert />
        </ErrorBoundary>
      </div>
      <div className="dashboard__approveMonitoringSchedules">
        {/* Components showing list of patients whose discharge summary has been parsed but approval is pending  */}
        <ErrorBoundary>
          <ApproveMonitoringSchedules />
        </ErrorBoundary>
      </div>
      <div className="dashboard__reviewCallForDay">
        {/* Component showing list of patients ,who are supposed to be called today on a checkup basis  */}
        <ErrorBoundary>
          <ReviewCallForDay />
        </ErrorBoundary>
      </div>
      <div className="dashboard__patientUnderMonitoring">
        {/* Component showing list of those patients whose approval has been done and are under monitoring by nurse  */}
        <ErrorBoundary>
          <PatientUnderMonitoring />
        </ErrorBoundary>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPatientStore: () => dispatch(actions.resetPatientState()),
    resetNavLinkApproveStatus: () =>
      dispatch(actions.resetNavLinkApproveStatus()),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(Dashboard);
