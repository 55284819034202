import React from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};


  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }

  // const params = Object.keys(data).map((key) => {
  //   return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
  // }).join('&');

  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
const DischargeContextRecommendations = () => {
  const store = new CustomStore({
    key: "discharge_context_recommendation_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-context-recommendations?limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          return {
            data: data.data,
            totalCount: data.totalCount,
            // summary: data.summary,
            // groupCount: data.groupCount,
            // {data:[{},{}],
            // totalCount:10}
          };
        })
        .catch((error) => {
          throw "Data Loading Error";
        });
    },
    insert: (values) =>
      sendRequest(`${window["getConfig"].BACKEND_SERVICE_URL}/discharge-context-recommendations`, "POST", values),
    update: (key, values) =>{

        sendRequest(
            `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-context-recommendations/${key}`,
            "PUT",
            values
            ).catch((error) => {
              throw "Data Loading Error";
            });   

        },
    remove: (key) =>
      sendRequest(`${window["getConfig"].BACKEND_SERVICE_URL}/discharge-context-recommendations/${key}`, "DELETE"),
  });
  const calculateCellValueFunc = data=>{
      if(data.sns_entity_measure_type ==1 ){
          return "NUMBER_SCALE"
        }
        else if (data.sns_entity_measure_type ==2){
          return "BINARY"

      }else if(data.sns_entity_measure_type ==3){
          return "THREE_POINT_SCALE"
      }
  }
  return (
    <DataGrid
      // width={"50%"}
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnResizing={true}
      allowColumnReordering={false}
      sorting={false}
    >
      <Editing
        refreshMode="reshape"
        mode="row"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />

      <Column
      width={40}
        dataField="discharge_context_recommendation_id"
        dataType="number"
        caption="#"
        
        allowEditing={false}
      ></Column>
      
      <Column dataField="discharge_context" dataType="string" allowEditing={true}></Column>
      <Column dataField="recommendation" dataType="string"></Column>
      
      
      <Paging defaultPageSize={15} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[15, 25, 50]} />
    </DataGrid>
  );
};
// calculateCellValue={calculateCellValueFunc}
export default DischargeContextRecommendations;
