import React, { useState, useEffect } from "react";
import FileUploader from "devextreme-react/file-uploader";
import ProgressBar from "devextreme-react/progress-bar";
import "./DragDrop.css";
import FileSelectPageLogo from "./FileSelectPageLogo/FileSelectPageLogo";
import { connect } from "react-redux";
import * as actions from "../../../../Store/actions/index";
import doneS2 from "../../../../AllIcons/doneS2.png";
import imgUpload from "../../../../AllIcons/uploadS2.png";
import LoaderBallTriangle from "../../../../Loader/LoaderBallTriangle";
import DischargeContextSearchDropdown from "../../../../Components/screensComponent/SearchDropdown/dischargeContextSearchDropdown";
import { useHistory } from "react-router-dom";
import ErrorModal from "../../../../modals/ErrorModal/ErrorModal";
import { useAuth0 } from "@auth0/auth0-react";
import '../../../../dx.common.css';
import '../../../../dx.light.css';
const DragDrop = (props) => {
    const history = useHistory();
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const [textVisible, setTextVisible] = useState(true);
    const [progressVisible, setProgressVisible] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [dischargeContext, setDischargeContext] = useState(null);
    const [files, setFiles] = useState([]);
    const [uploadingStatus, setUploadingStatus] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState("Files not found");
    const [showError, setShowError] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const GetAuthToken = async () => {
        const token = await getAccessTokenSilently();
        return token;
    };
    const showErrorFunc = () => {
        setShowError(false);
        setIsDropZoneActive(false);
        setDischargeContext(null);
        setFiles([]);
        setTextVisible(true);
    };



    const allowedFileExtensions = [".docx", ".pdf", ".txt"];

    function onDropZoneEnter(e) {
        if (e.dropZoneElement.id === "dropzone-external") {
            setIsDropZoneActive(true);
        }
    }

    function onDropZoneLeave(e) {
        if (e.dropZoneElement.id === "dropzone-external") {
            setIsDropZoneActive(false);
        }
    }

    function onUploaded(e) {
        const file = e.file;
        setFiles((currentFiles) => [...currentFiles, file]);
        props.setDischargeSummarySelectedToRedux(file);
        setTextVisible(false);
        setProgressVisible(false);
        history.push("/ds-upload/dischargeContextUpload");
        setProgressValue(0);
    }

    function onProgress(e) {
        setProgressValue((e.bytesLoaded / e.bytesTotal) * 100);
    }

    function onUploadStarted() {
        setProgressVisible(true);
    }


    return (
        <>
            {!showError && (
                <div className="widget-container flex-box dragDrop_fileUpload">
                    <div
                        id="dropzone-external"
                        className={`flex-box ${isDropZoneActive
                                ? "dx-theme-accent-as-border-color dropzone-active"
                                : "dx-theme-border-color"
                            }`}
                    >

                        {textVisible && (
                            <div id="dropzone-text" className="dragDrop__fileSelectLogo">
                                <FileSelectPageLogo />
                            </div>
                        )}
                        <ProgressBar
                            id="upload-progress"
                            min={0}
                            max={100}
                            width="100%"
                            showStatus={false}
                            visible={progressVisible}
                            value={progressValue}
                        ></ProgressBar>
                    </div>
                    <FileUploader
                        id="file-uploader"
                        dialogTrigger="#dropzone-external"
                        dropZone="#dropzone-external"
                        multiple={true}
                        accept=".docx,.pdf,.txt"
                        allowedFileExtensions={allowedFileExtensions}
                        uploadMode="instantly"
                        uploadUrl={`${window["getConfig"].BACKEND_SERVICE_URL}/api/v1/upload`}
                        visible={false}
                        onDropZoneEnter={onDropZoneEnter}
                        onDropZoneLeave={onDropZoneLeave}
                        onUploaded={onUploaded}
                        onProgress={onProgress}
                        onUploadStarted={onUploadStarted}
                    ></FileUploader>


                </div>
            )}

            <div>
                {showError && (
                    <div>
                        <ErrorModal
                            message={modalText}
                            setOnlineStatus={showErrorFunc}
                        ></ErrorModal>
                    </div>
                )}
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
        setDischargeSummarySelectedToRedux: dischargeSummary => dispatch(actions.setDischargeSummarySelected(dischargeSummary)),
        setQrCodeOnUpload: (qrCode, status) =>
            dispatch(actions.setQRCodeOnUpload(qrCode, status)),
    };
};

export default connect(null, mapDispatchToProps)(DragDrop);


