import {
  SET_QRCODE,
  SET_CURRENTCARDNUMBER,
  SET_REPLACEDMEDICINE,
  SET_DATEDURATION,
  SET_CURRENTMEDICINE,
  RESET_PATIENT_STATE,
  SET_APPROVE_BUTTON_STATUS,
  SET_DISCHARGE_CONTEXT,
  FETCH_AGAIN,
  SET_PRIMARY_CONTACT_NUMBER,
  SHOW_NAVIGATION,
  SET_PATIENTDETAILS,
  FETCHTHRESHOLDAGAIN,
  FETCHMEDICINEAGAIN,
  FETCHDISCHARGEPATIENTALERT,
  FETCHAPPROVEMONITORINGSCHEDULES,
  FETCHREVIEWCALLFORDAY,
  FETCHPATIENTUNDERMONITORING,
  SETQRCODEONUPLOAD,
  MEDICATIONDATES,
  WN_ROW_SELECTION,
  SET_DISCHARGE_SUMMARY_SELECTED,
  FETCH_PATIENT_DETAILS
} from "./actionTypes";

export const setQRCodeFunc = (QRCODE) => {
  return {
    type: SET_QRCODE,
    updatedQRCode: QRCODE,
  };
};

export const setCurrentCardNumberFunc = (number) => {
  return {
    type: SET_CURRENTCARDNUMBER,
    updatedCardNumber: number,
  };
};
export const setDateDuration = (date, duration) => {
  return {
    type: SET_DATEDURATION,
    date: date,
    duration: duration,
  };
};

export const setCurrentMedicineFunc = (medicineName) => {
  return {
    type: SET_CURRENTMEDICINE,
    currentMedicine: medicineName,
  };
};
export const setReplacedMedicinceFunc = (medicineName) => {
  return {
    type: SET_REPLACEDMEDICINE,
    newReplacedMedicine: medicineName,
  };
};

export const resetPatientState = () => {
  return {
    type: RESET_PATIENT_STATE,
  };
};

export const approveButtonStatusFunc = (approveStatus) => {
  return {
    type: SET_APPROVE_BUTTON_STATUS,
    buttonStatus: approveStatus,
  };
};
export const setDischargeContextFunc = (dischargeContext) => {
  return {
    type: SET_DISCHARGE_CONTEXT,
    dischargeContext: dischargeContext,
  };
};

export const fetchingAgain = (fetchingStatus) => {
  return {
    type: FETCH_AGAIN,
    fetchingStatus: fetchingStatus,
  };
};


export const setPrimaryContactNumber = (number) => {
  return {
    type: SET_PRIMARY_CONTACT_NUMBER,
    primaryContactNumber: number,
  };
};

export const showNavigationFunc = (status) => {
  return {
    type: SHOW_NAVIGATION,
    showNavigationStatus: status,
  };
};
export const setPatientDetails = (details) => {
  return {
    type: SET_PATIENTDETAILS,
    patientDetails: details,
  };
};
export const fetchThresholdAgain = (status) => {
  return {
    type: FETCHTHRESHOLDAGAIN,
    fetchStatus: status,
  };
};

export const fetchMedicineAgain = (status)=>{
  return {
    type:FETCHMEDICINEAGAIN,
    fetchStatus: status
  }
}

// Dashboard 
// 1. dischargePatientAlert 
export const fetchDischargePatientAlert = (status)=>{
  return{
    type:FETCHDISCHARGEPATIENTALERT,
    fetchingStatus:status
  }
}

// 2. approveMonitoringSchedules 
export const fetchApproveMonitoringSchedules = (status)=>{
  return {
    type: FETCHAPPROVEMONITORINGSCHEDULES,
    fetchingStatus:status
  }
}

// 3. casesForReview 
export const fetchReviewCallForDay =(status)=>{
  return{
    type:FETCHREVIEWCALLFORDAY,
    fetchingStatus:status
  }
}

//4. routineScheduleCalls
export const fetchPatientUnderMonitoring =(status)=>{
  return{
    type:FETCHPATIENTUNDERMONITORING,
    fetchingStatus:status
  }
}

export const setQRCodeOnUpload = (qrCode,status)=>{
  return{
    type:SETQRCODEONUPLOAD,
    updateQRCodeOnUpload:qrCode,
    savedClicked:status
  }
}

export const setWardNurseRowNumber = (selectedRow)=>{
  return{
    type:WN_ROW_SELECTION,
    rowSelected:selectedRow
  }
}

export const setDischargeSummarySelected = (dischargeSummary)=>{
  return{
    type:SET_DISCHARGE_SUMMARY_SELECTED,
    dischargeSummary:dischargeSummary
  }
}


export const fetchPatientDetails = (status)=>{
  return{
      type:FETCH_PATIENT_DETAILS,
      status:status
  }
}