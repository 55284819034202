import { SET_ACCESS_TOKEN } from "../actions/actionTypes";

const initialState = {
  accessToken: "",
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken,
      };
    default:
      return state;
  }
};

export default AuthReducer;
