import React from "react";
import SearchIcon from "../../AllIcons/mdi_search.png";
import "./Searchbar.css";
const Searchbar = (props) => {
  return (
    <div
      className="searchbar"
      style={{
        borderColor: props.BRColor,
        padding: props.padding,
      }}
    >
      <img src={SearchIcon} />
      <input
        className="searchbar__input"
        type="search"
        placeholder={props.placeholder}
        onChange={(e)=>{props.setInputFunc && props.setInputFunc(e)}}
        onKeyDown={props.onKeyDown}
        value={props.value}
        autoFocus={props.allowAutoFocus}    />
    </div>
  );
};

export default Searchbar;
