import React from 'react'
import './ButtonBorder.css';
const ButtonBorder = (props) => {
    return (
        <button className="buttonBorder__container" onClick={props.clicked}
        style={{
            backgroundColor:props.bgColor,
            border:props.border,
            borderColor:props.borderColor,
            width:props.width,
            height:props.height
        }}>
            <div className="buttonBorder__text">{props.text}</div>
        </button>
    )
}

export default ButtonBorder
