import React, { useState, useEffect } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  RequiredRule,
  FormItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { measureTypes } from "../../data";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { getAllVitalsEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import axios from "axios";
function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};

  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }

  // const params = Object.keys(data).map((key) => {
  //   return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
  // }).join('&');

  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
const MediaMapping = () => {
  const [searchText, setSearchText] = useState("");
  const [allSNSEntity, setAllSNSEntity] = useState([]);

  const fetchAll = async () => {
    axios
      .get(`${window["getConfig"].BACKEND_SERVICE_URL}/sns-entity/all`)
      .then((responseArray) => {
        setAllSNSEntity(responseArray.data);
      });
  };

  useEffect(() => {
    fetchAll();
  }, []);

  let { response: allVitalsList } = useFetch(`${getAllVitalsEndPoint}`, false);

  const store = new CustomStore({
    key: "sns_vital_mapping_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/sns-vital-mapping?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Task conversation Template", data);
          return {
            data: data.data,
            totalCount: data.totalCount,
          };
        })
        .catch(() => {
          throw "Data Loading Error";
        });
    },
    insert: (values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/sns-vital-mapping`,
        "POST",
        values
      ),
    update: (key, values) => {
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/sns-vital-mapping/${key}`,
        "PUT",
        values
      );
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/sns-vital-mapping/${key}`,
        "DELETE"
      ),
  });

  const onOptionChanged = (e) => {
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  return (
    <>
      <DataGrid
        // width={"50%"}
        dataSource={store}
        showBorders={true}
        remoteOperations={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={false}
        onOptionChanged={onOptionChanged}
      >
        <SearchPanel visible={true} text={searchText} />
        <Editing
          refreshMode="reshape"
          mode="popup"
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Column
          width={35}
          dataField="sns_vital_mapping_id"
          dataType="number"
          caption="id"
          allowEditing={false}
        >
          <FormItem colSpan={1}></FormItem>
        </Column>
        <Column dataField="vitals_name" dataType="string">
          <Lookup
            dataSource={allVitalsList}
            displayExpr="name"
            valueExpr="name"
          />
        </Column>
        <Column dataField="sns_entity_name" dataType="string">
          <Lookup
            dataSource={allSNSEntity}
            displayExpr="sns_entity_name"
            valueExpr="sns_entity_name"
          />
        </Column>

        <Paging defaultPageSize={15} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[12, 15, 20]} />
      </DataGrid>
    </>
  );
};
// calculateCellValue={calculateCellValueFunc}
export default MediaMapping;
