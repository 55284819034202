import React from "react";
import ButtonWBorder from "../../Buttons/ButtonWBorder/ButtonWBorder";
import './NavbarDashboardDischargeNavigateButtons.css'
const NavbarDashboardDischargeNavigateButtons = (props) => {
  return (
    <div className="navbarWardNavigate__postDischargeDashboardTextRouting">
      <div className="navbarWardNavigate__postDischargeDashboardText">
        {props.header}

      </div>
      <div className="navbarWardNavigate__postDischargeDashboardRouting">
        <ButtonWBorder
          name={props.text1}
          width="150px"
          clicked={props.clicked1}
        />
        <ButtonWBorder
          name={props.text2}
          width="150px"
          clicked={props.clicked2}
        />
        {props.showButton3 && <ButtonWBorder
          name={props.text3}
          width="150px"
          clicked={props.clicked3}
          dischargeActive ={props.dischargeActive}
        />}
      </div>
    </div>
  );
};

export default NavbarDashboardDischargeNavigateButtons;
