import React from 'react';
import './NavbarPostAdmin.css';
const NavbarPatientPanel = () => {
    return (
        <div className="navbarPostAdmin__container">
            Admin Panel
        </div>
    )
}

export default NavbarPatientPanel
