import {
  SET_QRCODE,
  SET_CURRENTCARDNUMBER,
  SET_REPLACEDMEDICINE,
  SET_DATEDURATION,
  SET_CURRENTMEDICINE,
  RESET_PATIENT_STATE,
  SET_DISCHARGE_CONTEXT,
  SET_APPROVE_BUTTON_STATUS,
  FETCH_AGAIN,
  SET_PRIMARY_CONTACT_NUMBER,
  SHOW_NAVIGATION,
  SET_PATIENTDETAILS,
  FETCHTHRESHOLDAGAIN,
  FETCHMEDICINEAGAIN,
  SETQRCODEONUPLOAD,
  MEDICATIONDATES,
  WN_ROW_SELECTION,
  SET_DISCHARGE_SUMMARY_SELECTED,
  FETCH_PATIENT_DETAILS


} from "../actions/actionTypes";

const initialState = {
  currentCardNumber: 0,
  QRCode: "",
  currentMedicine: "",
  replacedMedicine: "",
  dateDuration: {
    date: "",
    duration: "",
  },
  dischargeContext: null,

  fetchAgain: false,

  cardNumberArray: [false, false, false, false],
  approveButtonStatus: false,
  primaryContactNumber: "",
  showNavigation: false,
  patientDetails: [],
  fetchThreshold: false,
  fetchMedicine: false,
  wardNurseRowSelected:0,
  dischargeSummary:null,
  qrCodeOnUpload: {
    code: "",
    saved: false,
  },
  uploadDischargeRowNo:0,
  fetchPatientStatus:false
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QRCODE: {
      return {
        ...state,
        QRCode: action.updatedQRCode,
      };
    }
    case SET_CURRENTCARDNUMBER:
      return {
        ...state,
        currentCardNumber: action.updatedCardNumber,
      };
    case SET_CURRENTMEDICINE: {
      return {
        ...state,
        currentMedicine: action.currentMedicine,
      };
    }
    case SET_REPLACEDMEDICINE: {
      return {
        ...state,
        replacedMedicine: action.replacedMedicine,
      };
    }
    case FETCH_AGAIN:
      return {
        ...state,
        fetchAgain: action.fetchingStatus,
      };
    case SET_DATEDURATION: {
      return {
        ...state,
        dateDuration: {
          date: action.date,
          duration: action.duration,
        },
      };
    }
    case RESET_PATIENT_STATE: {
      return {
        currentCardNumber: 1,
        QRCode: "",
        currentMedicine: "",
        replacedMedicine: "",
        dateDuration: {
          date: "",
          duration: "",
        },
      };
    }
    case SET_DISCHARGE_CONTEXT: {
      return {
        ...state,
        dischargeContext: action.dischargeContext,
      };
    }

    case SET_APPROVE_BUTTON_STATUS: {
      return {
        ...state,
        approveButtonStatus: action.buttonStatus,
      };
    }
    case SET_PRIMARY_CONTACT_NUMBER: {
      return {
        ...state,
        primaryContactNumber: action.primaryContactNumber,
      };
    }
    case SHOW_NAVIGATION: {
      return {
        ...state,
        showNavigation: action.showNavigationStatus,
      };
    }
    case SET_PATIENTDETAILS:
      return {
        ...state,
        patientDetails: action.patientDetails,
      };

    case FETCHTHRESHOLDAGAIN:
      return {
        ...state,
        fetchThreshold: action.fetchStatus,
      };
    case FETCHMEDICINEAGAIN:
      return {
        ...state,
        fetchMedicine: action.fetchStatus,
      };
    case SETQRCODEONUPLOAD:
      return {
        ...state,
        qrCodeOnUpload: {
          code: action.updateQRCodeOnUpload,
          saved: action.savedClicked,
        },
      };
    
    case MEDICATIONDATES:
      return{
        ...state,
        medicationDates:action.medicationDates
      }
    case WN_ROW_SELECTION:
      return{
        ...state,
        wardNurseRowSelected:action.rowSelected
      }
    case SET_DISCHARGE_SUMMARY_SELECTED:
      return{
        ...state,
        dischargeSummary:action.dischargeSummary
      }
    case FETCH_PATIENT_DETAILS:
      return{
        ...state,
        fetchPatientStatus:action.status
      }
    default:
      return state;
  }
};

export default patientReducer;


