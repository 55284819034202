import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { dynamicSort } from "../../../HighOrderFunctions/dynamicSort";
import { medicalInsertsEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import {updateMedicalDevicesEndPoint} from '../../../HighOrderFunctions/APIs/allPostAPI'
import TagBox from 'devextreme-react/tag-box';
import {useSelector} from 'react-redux';
import {postFetchFunction} from '../../../HighOrderFunctions/FetchMethod/postFetchFunction';
import * as actions from '../../../Store/actions/index';
function MedicalDeviceSearchDropdownNew(props) {
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector(state=>state.auth.accessToken);
  
  const fetchedDataFunc = async () => {
    setLoading(true);

    let fetchedValue = await getFetchFunction(`${medicalInsertsEndPoint}`,token);
    if (Array.isArray(fetchedValue) == true) {
      let data = [];
      fetchedValue.map((item, index) => {
        data.push({
          name: item.medicalDevices,
          value: item.medicalDevices,
        });
      });
      let sortedData = data.sort(dynamicSort("name"));

      setFetchedData(sortedData);
    }
  };

  useEffect(() => {
    fetchedDataFunc();
  }, []);

  const updateMedicalDevices = async(medicalVal) => {
    console.log("MEDICAL VALUE: ",medicalVal)
    
      props.setValue([...new Set([...medicalVal])])
    
    if(props.allowSendingToBackend ){
      
      try {
        let requestOptions = {
          qr_code: props.qrCodeFromStore,
          medical_devices: medicalVal && medicalVal,
        }
        
       await  postFetchFunction(updateMedicalDevicesEndPoint, requestOptions,token);
      }
      catch(err){
        
      }
      props.fetchPatientDetails(true);
    }
  };
  return (
    <div
    // className="devextremeDropdown__container medicalDevicesSearchDropdownNew__container"
    style={{ width: props.width,
    height:props.height }}
    disabled={props.disabled}
    >
      <TagBox
      className="searchDropdown__container widget-container"
      searchEnabled={true}
        placeholder="Please Select Medical Devices,if any"
        items={fetchedData}
        displayName="name"
        displayExpr="name"
        valueExpr="value"
        defaultValue={props.value}
        onValueChange={updateMedicalDevices}
        width={props.width}
        height={props.height}
        disabled={props.disabled && props.disabled}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPatientDetails: (status) =>
      dispatch(actions.fetchPatientDetails(status)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalDeviceSearchDropdownNew);
