import React from "react";
import "./RashChartCapsule.css";
import RashIcon from "../../../../../../../AllIcons/rashIcon.png";
import wrongDataIcon from "../../../../../../../AllIcons/wrongDataWarning.png";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { Fade } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 110,
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    fontSize: "bold",
  },
}));
const RashChartCapsule = (props) => {
  const longText = (
    <div style={{ textAlign: "center" }}>
      <div style={{ fontSize: "bold", fontWeight: 800 }}>{props.date}</div>
      {props.onset && (
        <div>
          onset:{" "}
          <span style={{ fontSize: "bold", fontWeight: 800 }}>
            {props.onset}
          </span>
        </div>
      )}

      {props.site && (
        <div>
          {" "}
          site:{" "}
          <span style={{ fontSize: "bold", fontWeight: 800 }}>
            {props.site}
          </span>
        </div>
      )}
      {props.character && (
        <div>
          {" "}
          character:{" "}
          <span style={{ fontSize: "bold", fontWeight: 800 }}>
            {props.character}
          </span>
        </div>
      )}
    </div>
  );

  const classes = useStyles();

  return (
    <Tooltip
      title={longText}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      classes={{ tooltip: classes.customWidth }}
      placement="top"
    >
      <div className="rashChartCapsule__container">
        <div
          className={
            props.downloadBinaryChart
              ? `rashChartCapsule__time__download rashChartCapsule__time`
              : "rashChartCapsule__time"
          }
        >
          {props.time ? props.time : "time-mis."}
        </div>
        <div
          className={`rashChartCapsule__icon ${
            props.severity == "wrong_data" && "rashNotPresent"
          } `}
        >
          {props.severity == "is_present" ? (
            <img
              src={RashIcon}
              width={props.iconWidth ? props.iconWidth : "30"}
              height={props.iconHeight ? props.iconHeight : "30"}
            />
          ) : (
            <img
              src={wrongDataIcon}
              width={props.iconWidth ? props.iconWidth : "30"}
              height={props.iconHeight ? props.iconHeight : "30"}
            />
          )}
        </div>
      </div>
    </Tooltip>
  );
};

export default RashChartCapsule;
