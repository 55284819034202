import React, { useState, useEffect } from "react";
import { connect,useSelector } from "react-redux";
import SearchDevExtreme from "./SearchDevExtreme";
import { dietEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { postFetchFunction } from "../../../HighOrderFunctions/FetchMethod/postFetchFunction";
import { dynamicSort } from "../../../HighOrderFunctions/dynamicSort";
import {updateDietEndPoint} from '../../../HighOrderFunctions/APIs/allPostAPI';
function DietSearchDropdown(props) {

  const accessToken = useSelector(state=>state.auth.accessToken);
  let {response,isLoading} = useFetch(`${dietEndPoint}`,false)
  if(Array.isArray(response) && response.length > 0){
    let sortedData = response.sort(dynamicSort("diet_name"));
    response = sortedData;
  }else{
    response = [];
  }

  const updateDiet = (updatedDiet) => {
    let requestBody = {
      qr_code: props.qrCodeFromStore,
      diet: updatedDiet && updatedDiet["diet_name"],
    }
    const result = postFetchFunction(`${updateDietEndPoint}`,requestBody,accessToken);
    props.setValue(updatedDiet);
   
  };
  return (
    <div
      className="devextremeDropdown__container"
      style={{ width: props.width }}
    >
      <SearchDevExtreme
        placeholder="Select diet"
        optionsFetched={response}
        displayName="diet_name"
        displayExpr="diet_name"
        valueExpr="diet_name"
        defaultValue={props.value}
        updatedSelectedValue={updateDiet}
        disabled={props.disabled && props.disabled}
        

      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(DietSearchDropdown);
