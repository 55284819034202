import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../Components/screensComponent/Dashboard/DashboardHeader/DashboardHeader";
import DashboardNameHeader from "../../../Components/screensComponent/Dashboard/DashboardNameHeader/DashboardNameHeader";
import PUMNameBody from "../../../Components/screensComponent/Dashboard/PatientUnderMonitoring/PUMNameBody/PUMNameBody";
import * as actions from "../../../Store/actions/index";
import { connect } from "react-redux";
import { formatDate } from "../../../HighOrderFunctions/formatDate";
import { getPatientUnderMonitoringEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import Loader from '../../../Loader/LoaderThreeDot';
import "./PatientUnderMonitoring.css";
const PatientUnderMonitoring = (props) => {

  // function to fetch list of patients whose approval has been done and are under monitoring
  const {response,isLoading} = useFetch(`${getPatientUnderMonitoringEndPoint}/${formatDate(new Date())}`,props.patientUnderMonitoring);
  useEffect(() => {
    if (props.patientUnderMonitoring === true) {
      props.fetchPatientUnderMonitoring(false);
    }
  }, [props.patientUnderMonitoring]);
 
  let renderedData = null;
  renderedData = Array.isArray(response) && response.map((item, index) => {
    return (
      <PUMNameBody
        key={index}
        rowNumber={index}
        name={item["name"]}
        dischargeDate={item["discharge_date"]}
        dischargeDiagnosis={item["context"]}
        qrCode={item["qr_code"]}
        hospitalId={item["hospital_id"]}
        timeSinceDischarge={item["time_since_discharge"]}
        age={item["age"]}
        gender={item["gender"]}
        summaryStatus={item["summary_status"]}
      />
    );
  });

  return (
    <div className="patientUnderMonitoring__container">
      <div className="patientUnderMonitoring__header">
        <DashboardHeader headerName="Other patients under monitoring"
        otherPatientsNumber={`(${Array.isArray(response) && response.length})`}
        />
      </div>
      <div className="patientUnderMonitoring__nameHeader">
        <DashboardNameHeader status="Monitoring Status" 
        arrowUp={true}
        />
      </div>
      <div className="patientUnderMonitoring__nameBody">
        {isLoading ? <div className="loaderbar"> <Loader /> </div> : <div>{renderedData}</div>}

      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    patientUnderMonitoring: state.dashboard.patientUnderMonitoring,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    
    fetchPatientUnderMonitoring: (status) =>
      dispatch(actions.fetchPatientUnderMonitoring(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientUnderMonitoring);
