import React from 'react'
import './OnCallRegistrationHeader.css';
import Searchbar from '../../../Searchbar/Searchbar';
const OnCallRegistrationHeader = (props) => {
    return (
        <div className="onCallRegistrationHeader__container">
            <div className="onCallRegistrationHeader__blankSpace"></div>
            <div className="onCallRegistrationHeader__currentlyRegisteredText">Currently Registered</div>
            <div className="onCallRegistrationHeader__search">
              <Searchbar 
              placeholder="Search"
              BRcolor="white"
              setInputFunc={props.setInputFunc}
              value={props.value}
              />
            </div>
        </div>
    )
}

export default OnCallRegistrationHeader
