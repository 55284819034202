import React from "react";
import "./UploadDischargeNameHeader.css";
import arrowDown from '../../../../AllIcons/arrowDown.png';
const UploadDischargeNameHeader = () => {
  return (
    <div className="uploadDischargeNameHeader__container">
     <div className="uploadDischargeNameHeader__item">
         <div className="uploadDischargeNameHeader__name">PATIENT NAME</div>
         <div className="uploadDischargeNameHeader__dateOfBirth">DATE OF BIRTH</div>
     </div>
     <div className="uploadDischargeNameHeader__item">
         <div className="uploadDischargeNameHeader__qrCode">QR CODE</div>
         <div className="uploadDischargeNameHeader__hospitalId">HOSPITAL ID</div>
     </div>
     <div className="uploadDischargeNameHeader__item">
         <div className="uploadDischargeNameHeader__dateOfDischarge">DATE OF DISCHARGE<span className="uploadDischargeNameHeader__arrow"></span></div>
         <div className="uploadDischargeNameHeader__mobileNumber">PATIENT MOBILE NO.</div>
     </div>
     <div className="uploadDischargeNameHeader__item">
         <div className="uploadDischargeNameHeader__dischargeStatus">DISCHARGE STATUS</div>
     </div>
     <div className="uploadDischargeNameHeader__item uploadDischargeNameHeader__ItemActions">
         <div className="uploadDischargeNameHeader__actions">ACTIONS</div>
     </div>
   
    </div>
  );
};

export default UploadDischargeNameHeader;
