import React from 'react';
import './SubmitButton.css';
const SubmitButton = (props) => {
    return (
        <button className="submitButton__container" style={{width:props.width}}
        onClick={props.clicked}
        >
            <div className="submitButton__text">
                {props.name}
            </div>
        </button>
    )
}

export default SubmitButton
