import React from 'react';
import './SendInvitationButton.css';
const SendInvitationButton = (props) => {
    return (
        <div className={`sendInvitationButton__container ${props.disabled ? "disabledSendInvitationButton":
        ""}`} onClick={props.clicked}>
            <div className="sendInvitationButton__text">{props.name}</div>
        </div>
    )
}

export default SendInvitationButton
