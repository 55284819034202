import React, { Fragment } from "react";
import List from "devextreme-react/list";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";

export default class RemedialSuggestionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remedial_suggestions: this.props.data.value,
    };

    this.addButton = {
      text: "+ Add",
      type: "default",
      onClick: (e) => {
        let temp = this.state.remedial_suggestions;
        temp.push(this.textBoxRef.current.instance.option("value"));
        this.setState({
          remedial_suggestions: temp,
        });
        this.listRef.current.instance.dataSource = this.state.remedial_suggestions;
        this.listRef.current.instance.reload();
        this.textBoxRef.current.instance.option("value", "");
      },
    };

    this.listRef = React.createRef();
    this.textBoxRef = React.createRef();
    this.onValueChanged = this.onValueChanged.bind(this);
  }
  onValueChanged(e) {
    this.props.data.setValue(this.state.remedial_suggestions);
  }

  render() {
    return (
      <Fragment>
        <TextBox
          placeholder="Add a new suggestion to the list."
          ref={this.textBoxRef}
        >
          <TextBoxButton name="add" location="after" options={this.addButton} />
        </TextBox>
        <List
          ref={this.listRef}
          dataSource={this.state.remedial_suggestions}
          allowItemDeleting={true}
          onContentReady={this.onValueChanged}
          noDataText="No Remedial suggestions present.."
        />
      </Fragment>
    );
  }
}
