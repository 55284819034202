import React, { useState, useEffect } from "react";
import "./AlertThresholdMainContainer.css";
import AlertThresholdMainContainerBottom from "./AlertThresholdMainContainerBottom/AlertThresholdMainContainerBottom";
import AlertThresholdMainContainerTop from "./AlertThresholdMainContainerTop/AlertThresholdMainContainerTop";
import SymptomsListDropdown from "../../../../SearchDropdown/SymptomsListDropdown";
const AlertThresholdMainContainer = (props) => {
  const [showMinimiseButton,setShowMinimiseButton] = useState(true);
  const [symptomName, setSymptomName] = useState(props.symptomName);
  const [measureType, setMeasureType] = useState(props.measureType);
  const [isAvailable, setIsAvailable] = useState(props.isAvailable);
  const [editMode, setEditMode] = useState(false);
  const [showMode, setShowMode] = useState(false);
  const [alertConditions, setAlertConditions] = useState(props.alertConditions);
  const [allowEditNewCard, setAllowEditNewCard] = useState(props.newCard);
  const [showMeasuretypeSelector,setShowMeasureTypeSelector] = useState(true);

  // function to toggle between two components inside AlertThresholdMainContainerBottom.js to show Symptoms relations in edit and non-edit mode
  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  
  useEffect(() => {}, [symptomName]);


  // function to add new Entry in alert Threshold
  const setSymptomNameFunc= (val)=>{
    
    setMeasureType(val["measureType"]);
    setSymptomName(val["symptomName"]);
    
    let relation =
    val["measureType"] === "THREE_POINT_SCALE"
    ? "EQUAL_TO"
    : val["measureType"] === "BINARY"
    ? "EQUAL_TO"
    : "";
    let threshold = val["measureType"] === "BINARY" ? "Present" : " ";
    let tempAlertConditionsArray0 = {
      day_end: "",
      day_start: "",
      id: props.alertConditions[0]["id"],
      isAvailable: 1,
      measure_type: val["measureType"],
      relation: relation,
      symptom: val["symptomName"],
      threshold: threshold,
    };
    setAlertConditions([{...tempAlertConditionsArray0}]);
    setIsAvailable(true);
    setShowMeasureTypeSelector(false)
    setEditMode(true);
    // props.checkAlertThresholdValidity(false,props.index); 

  }

  // function to replace parsed symptom with already symptom existing in database using dropdown 
  const setSelectedSymptom = (val) => {
    setShowMeasureTypeSelector(false)
    val["symptomName"] && setSymptomName(val["symptomName"]);
    val["measureType"] && setMeasureType(val["measureType"]);
    setIsAvailable(true)
    // props.checkAlertThresholdValidity(false,props.index); 
    setEditMode(true);
    setAllowEditNewCard(false);
  };
    
  let symptomValue = {
    symptomName: props.symptomName,
    measureType: "",
  };

  return (
    <div className="alertThresholdMainContainer">
      <div className="alertThresholdMainContainer__top">
        {!allowEditNewCard  ? (
          <AlertThresholdMainContainerTop
            name={`${
              props.symptomName !== "" ? props.symptomName : symptomName
            }`}
            editButtonClickedFunc={toggleEditMode}
            editMode={editMode}
            showMode={showMode}
            isAvailable={props.isAvailable}
            newCard={props.newCard}
            symptomName={symptomName}
            setSymptomNameFunc={setSymptomNameFunc}
            alertConditions={alertConditions}
            symptomIndex={props.symptomIndex}
            deleteSymptomFromList={props.deleteSymptomFromList}
            showMinimiseButton={showMinimiseButton}
          />
        ) : (
          <div className="select__newCardList">
            <SymptomsListDropdown
              width="200px"
              value={symptomValue}
              setValue={setSelectedSymptom}
            />
          </div>
        )}
      
      </div>
      <div>
        <p className="notification__alertCondition ">
          {props.alertNotification}
        </p>
        <div className="alertThresholdMainContainer__bottom">
          {symptomName && measureType && isAvailable && (
            <AlertThresholdMainContainerBottom
              editButtonClicked={editMode}
              showMode={showMode}
              alertConditions={alertConditions}
              symptomName={symptomName}
              measureType={measureType}
              newNotification={symptomName}
              newCard={props.newCard}
              monitoringDuration={props.monitoringDuration}
              setShowMinimiseButton={setShowMinimiseButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertThresholdMainContainer;
