import React, { useState, useEffect, useRef, useCallback } from "react";
import "./ChatBody.css";
import { getConversationEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { formatDate } from "../../../HighOrderFunctions/formatDate";
import { connect, useSelector } from "react-redux";
import BotResponse from "./BotResponse/BotResponse";
import UserResponse from "./UserResponse/UserResponse";
import { useAuth0 } from "@auth0/auth0-react";
import NoChat from "./NoChat/NoChat";
import Loader from "../../../Loader/LoaderThreeDot";
import { dynamicSortDesc } from "../../../HighOrderFunctions/dynamicSort";
import { v4 as uuidv4 } from "uuid";

const ChatBody = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const { user } = useAuth0();
  const [fetchedData, setFetchedData] = useState([]);
  const messagesStartRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(false);
  // const [renderedData,setRenderedData] = useState(null);

  const fetchedDataFunc = async () => {
    setFetchedData([]);
    setLoading(true);
    let fetchedValue = await getFetchFunction(
      `${getConversationEndPoint}/${props.qrCodeFromStore}`,
      accessToken
    );

    if (Array.isArray(fetchedValue) == true) {
      setLoading(false);
      setFetchedData(fetchedValue);

      window.scrollTo(0, 0);
    }
  };
  //console.log("Chat Resonse ",fetchedData)
  useEffect(() => {
    fetchedDataFunc();
  }, []);

  let renderedData = null;

  const displayChat = () => {
    renderedData = fetchedData.map((item, index) => {
      return (
        <div key={uuidv4()}>
          <div>
            {item.conversation_details.map((chatItem, index) => {
              let utterance = "";
              if (props.chatShowLanguage == "original") {
                utterance = chatItem.utterance_before_translation;
              } else {
                utterance = chatItem.utterance;
              }
              if (chatItem.speaker == "USER") {
                return (
                  <div className="chatBody__userResponse" key={index}>
                    <UserResponse
                      key={uuidv4()}
                      userPicture={user.picture}
                      utterance={utterance}
                      time={chatItem.time}
                      dayNumber={chatItem.day_number}
                      date={chatItem.date}
                      user_contact={chatItem.user_contact}
                    />
                  </div>
                );
              } else if (chatItem.speaker == "BOT") {
                return (
                  <div className="chatBody__botResponse" key={index}>
                    <BotResponse
                      utterance={utterance}
                      key={uuidv4()}
                      time={chatItem.time}
                      dayNumber={chatItem.day_number}
                      date={chatItem.date}
                      status={chatItem.status}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    });
    return renderedData;
  };
  if (props.chatShowLanguage == "original") {
    renderedData = displayChat();
  } else {
    renderedData = displayChat();
  }
  useEffect(() => {
    displayChat();
  }, [props.chatShowLanguage]);

  if (loading) {
    return (
      <div className="chatBody__loading">
        <Loader />
      </div>
    );
  }
  return (
    <div className="chatBody__container">
      {fetchedData.length > 0 ? (
        <>{renderedData}</>
      ) : (
        <div className="chatBody__noChat">
          <NoChat />
        </div>
      )}
      <div ref={messagesEndRef}></div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};

export default connect(mapStateToProps, null)(ChatBody);

// const executeScroll = () => messagesEndRef.current.scrollIntoView();
{
  /* <div className="chatBody__alertActivity">
<ChatNotificationCard
date={item.creation_date}
conversation_type={item.conversation_type}
/>
</div> */
}
{
  /* <div className="chatBody__scrollToBottom" ref={messagesStartRef}></div> */
}
// const observer = useRef();
// const lastBookElementRef = useCallback((node) => {
//   if (observer.current) observer.current.disconnect();
//   observer.current = new IntersectionObserver((entries) => {
//     if (entries[0].isIntersecting) {
//       console.log("Visible");
//       setScrollChatToBottom(true);
//     }
//   });
//   if (node) {
//     observer.current.observe(node);
//     console.log("node", node);
//   }
// }, []);
// <div ref={lastBookElementRef} key={10}></div>

// const scrollToTop = () => {
//   messagesStartRef.current?.scrollIntoView({ behavior: "smooth" });
// };
// const scrollToBottom = () => {
//   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
// };
// let { scrollChatToTop, scrollChatToBottom } = props;
// useEffect(() => {
//   if (scrollChatToTop == true) {
//     scrollToTop();
//     props.setScrollChatToTop(false);
//   }
// }, [scrollChatToTop]);
// useEffect(() => {
//   if (scrollChatToBottom == true) {
//     scrollToBottom();
//     props.setScrollChatToBottom(false);
//   }
// }, [scrollChatToBottom]);
