import React from "react";
import "./OnCallRegistrationBodyNameHeader.css";
const OnCallRegistrationBodyNameHeader = () => {
  return (
    <div className="onCallRegistrationBodyNameHeader__container">
      <div className="onCallRegistrationBodyNameHeader__name">NAME</div>
      <div className="onCallRegistrationBodyNameHeader__registrationDate">
        Registration Date
      </div>
      <div className="onCallRegistrationBodyNameHeader__consent">Consent</div>
      <div className="onCallRegistrationBodyNameHeader__phoneNumber">
        PHONE NUMBER
      </div>
      <div className="onCallRegistrationBodyNameHeader__actions">ACTIONS</div>
    </div>
  );
};

export default OnCallRegistrationBodyNameHeader;
