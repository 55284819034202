import React, { useState, useEffect } from "react";
import "./OnCallRegistrationSidebar.css";
import ModalCustomButton from "../../../Buttons/ModalCustomButton/ModalCustomButton";
import {
  checkValidity,
  updateObject,
} from "../../../../HighOrderFunctions/shared/utility";
import { capitalizeFirstLetter } from "../../../../HighOrderFunctions/shared/capitalizeFirstLetter";
import { addNewCovidNurseEndPoint } from "../../../../HighOrderFunctions/APIs/allPostAPI";
import * as actions from "../../../../Store/actions/index";
import { connect,useSelector } from "react-redux";
import { useHistory } from "react-router";

// This component is for Adding New Nurse
const OnCallRegistrationSidebar = (props) => {
  const accessToken = useSelector(state => state.auth.accessToken);
  const [formIsValid, setFormIsValid] = useState(false);
  const [error,setError] = useState("");

  //initial state to store fullName and contact number of nurse , who so ever is being registered.!!
  const [onCallRegistrationForm, setOnCallRegistrationForm] = useState({
    fullName: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
      },
    },
    contactNumber: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
        isNumeric: true,
        maxLength: 10,
        minLength: 10,
      },
    },
  });

  const history = useHistory();

  // function to update fullName and contact Number of Nurse
  const inputChangedHandler = (value, inputIdentifier) => {
    let valuePassed = value;
    let valueForValidityCheck = value;
    if (inputIdentifier == "contactNumber") {
      if (Number.isInteger(+value) && value.length <= 10) {
        valuePassed = value;
      } else {
        valueForValidityCheck = onCallRegistrationForm["contactNumber"].value;
        valuePassed = onCallRegistrationForm["contactNumber"].value;
      }
    }
    if (inputIdentifier == "fullName") {
      valuePassed = capitalizeFirstLetter(valuePassed);
    }

    const updatedFormElement = updateObject(
      onCallRegistrationForm[inputIdentifier],
      {
        value: valuePassed,
        clicked: true,
        valid: checkValidity(
          valueForValidityCheck,
          onCallRegistrationForm[inputIdentifier].validations
        ),
      }
    );
    const updatedCovidPatientForm = updateObject(onCallRegistrationForm, {
      [inputIdentifier]: updatedFormElement,
    });

    // checking validation of covid Form

    let formIsValid = true;
    for (let inputIdentifier in updatedCovidPatientForm) {
      formIsValid =
        updatedCovidPatientForm[inputIdentifier].valid && formIsValid;
    }
    setOnCallRegistrationForm(updatedCovidPatientForm);
    setFormIsValid(formIsValid);
  };

  // function to update new Nurse having fullName and contactNumber to the backend 
  const updatePatientDetailsFunc = () => {
    
    const requestBody = JSON.stringify({
      site_id: parseInt(`${window["getConfig"].SITE_ID}`),
      nurse_name: onCallRegistrationForm["fullName"].value,
      phone_no: onCallRegistrationForm["contactNumber"].value,
    });
    let requestOptions = {
      method: "POST",
      body: requestBody,
      redirect: "follow",
      headers: {
        Authorization:
          `Bearer ${accessToken}`,
          "Content-Type": "application/json",
      },

    };
    try {
      fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/${addNewCovidNurseEndPoint}`,
        requestOptions
      ).then((res) => {
       
       
        return res.json();
      }).then(result=>{
        if(result.ERROR){
          setError(result.ERROR);
        }else{
            props.fetchingOnCallRegistrationList(true);
            props.fetchingNurseListOnCallRegistrationList(true);
            setOnCallRegistrationForm({ fullName: {
              value: "",
              valid: false,
              clicked: false,
              validations: {
                required: true,
              },
            },
            contactNumber: {
              value: "",
              valid: false,
              clicked: false,
              validations: {
                required: true,
                isNumeric: true,
                maxLength: 10,
                minLength: 10,
              },
            }
          });
  
          
          setError("");
        }
      });
    } catch (err) {

    }
  };

  // function to navigate back to Dashboard Screen
  const navigateBackToDashboardScreen = () => {
    history.push("/dashboard");
    props.setShowCallRegistrationToStore(true);
  };
  return (
    <div className="onCallRegistrationSidebar__container">
      <div className="onCallRegistrationSidebar__nameContact">
        <div className="onCallRegistrationSidebar__fullName">
          <input
            type="text"
            className="onCallRegistrationSidebar__fullNameInput"
            placeholder="Full Name"
            value={onCallRegistrationForm["fullName"].value}
            onChange={(e) => inputChangedHandler(e.target.value, "fullName")}
          />
        </div>
        <div className="onCallRegistrationSidebar__contactNumber">
          <input
            type="text"
            className="onCallRegistrationSidebar__contactNumberInput"
            placeholder="Phone Number"
            value={onCallRegistrationForm["contactNumber"].value}
            onChange={(e) =>
              inputChangedHandler(e.target.value, "contactNumber")
            }
          />
        </div>
      </div>
      <div className="onCallRegistrationSidebar__cancelConfirmButtons">
        <div className="covidRegistrationForm__button">
          <ModalCustomButton
            key="1"
            name="Cancel"
            status={false}
            bgcolor="white"
            textColor="#E94444"
            clicked={navigateBackToDashboardScreen}
          />
        </div>
        <div className="covidRegistrationForm__button">
          <ModalCustomButton
            name="Confirm"
            status={true}
            key="2"
            bgcolor="rgba(57, 129, 233, 0.68)"
            textColor="white"
            clicked={updatePatientDetailsFunc}
            disabled={!formIsValid}
          />
        </div>
      </div>
      {error !=="" && <div className="error-message">{error}</div>}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchingOnCallRegistrationList: (status) =>
      dispatch(actions.fetchOnCallRegistrationAgain(status)),
    fetchingNurseListOnCallRegistrationList: (status) =>
      dispatch(actions.fetchNurseListOnCallRegistrationAgain(status)),
    setShowCallRegistrationToStore: (status) =>
      dispatch(actions.showRegistrationInOnCallRegistration(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnCallRegistrationSidebar);
