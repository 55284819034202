import React from "react";
import "./ComplianceIndexList.css";
import ChartIndexButton from "../../../Buttons/ChartIndexButton/ChartIndexButton";
import { connect } from "react-redux";


// component for showing list of compliances
const ComplianceIndexList = (props) => {
  return (
    <div className="chartIndexingList__container">
      <div className="chartIndexingList__button">
        <ChartIndexButton
          clicked={() => props.toggleTab(1)}
          active={true}
          name={props.medication}
          active = {props.toggleState === 1 ? true : false}

        />
      </div>
  
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(ComplianceIndexList);
