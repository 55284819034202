import React, { useState, useEffect } from "react";
// import InputCard from "../../../Card/InputCard/InputCard";
import "./PatientHospitalDetails.css";
import ModalCustomButton from "../../../Buttons/ModalCustomButton/ModalCustomButton";
import { updatePatientInfoEndPoint } from "../../../../HighOrderFunctions/APIs/allPostAPI";
import MedicalDeviceSearchDropdownNew from "../../SearchDropdown/MedicalDeviceSearchDropdownNew";
import * as actions from "../../../../Store/actions/index";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalDelete from "../../../../modals/ModalDelete/ModalDelete";
import { getFetchFunction } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import {
  checkValidity,
  updateObject,
} from "../../../../HighOrderFunctions/shared/utility";
import { showNotificationWithMessage } from "../../../../Store/actions/DashboardAction";
import {
  getOverRideMobileNumberEndPoint,
  getCancelOverRidingNumberEndPoint,
} from "../../../../HighOrderFunctions/APIs/allGetAPI";
import LanguageSelectSearchDropdown from "../../SearchDropdown/LanguageSelectSearchDropdown";
import ButtonBorder from "../../../Buttons/ButtonBorder/ButtonBorder";
import NewDischargeHeader from "../../../../screens/UploadDischargeScreen/NewDischargeHeader/NewDischargeHeader";
import { useFetch } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { getPatientDetailsEndPoint } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import CareGiversList from "../../CareGiverMonitoringStatus/CareGivers/CareGiversList/CareGiversList";
import CheckBox from "../../../../Components/CheckBox/CheckBox";
import PhoneNumberInput from "../../../PhoneNumberInput/PhoneNumberInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import ToastNotificationWithoutAuto from "../../../Notification/ToastNotificationManualClose";
import { v4 as uuidv4 } from "uuid";
const PatientHospitalDetails = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.accessToken);
  const [countryCode, setCountryCode] = useState("+91");
  const [patientPrimaryNumber, setPatientPrimaryNumber] = useState("");
  const [showOverRideNumberModel, setShowOverRideNumberModel] = useState(false);
  const history = useHistory();
  const [formIsValid, setFormIsValid] = useState(false);
  
  // care Giver Updation
  const [renderedData, setRenderedData] = useState(null);
  const [
    manageCarePatientCheckBoxEnabled,
    setManageCarePatientCheckBoxEnabled,
  ] = useState(false);
  const [isCareGiversDetailsValid, setIsCareGiversDetailsValid] = useState([]);
  const [careGiversDetails, setCareGiversDetails] = useState([]);
  const [patientHospitalDetails, setPatientHospitalDetails] = useState({
    medicalDevices: {
      value: "",
      valid: true,
      clicked: false,
      validations: {
        // required: true,
        // isArray:true
      },
    },
    language: {
      value: {},
      valid: false,
      clicked: false,
      validations: {
        isObject: true,
      },
    },

    contactNumber: {
      value: props.primaryContactNumberFromStore
        ? props.primaryContactNumberFromStore
        : undefined,
      valid: false,
      clicked: false,
      validations: {
        required: true,
        isNumeric: true,
        // maxLength: countryCode =="+91"? 10:9,
        // minLength: countryCode =="+91"? 10:9,
      },
    },
  });

  const updatePatientHospitalDetails = (value, inputIdentifier) => {
    let valuePassed = value;
    let valueForValidityCheck = value;
    if (inputIdentifier == "medicalDevices") {
      valuePassed = valuePassed;
    }
    if (inputIdentifier == "language") {
      valuePassed = valuePassed;
    }

    let tempObj = {};
    if (inputIdentifier == "contactNumber") {
      // console.log("COntact Number", value);
      if (value !== undefined) {
        valuePassed = value["nationalNumber"];
        setPatientPrimaryNumber(valuePassed);
        console.log(value)
        setCountryCode(`+${value["countryCallingCode"]}`);

        valueForValidityCheck = value["number"];
        tempObj = {
          value: valueForValidityCheck,
          clicked: true,
          valid: `${
            valueForValidityCheck && valueForValidityCheck !== undefined
              ? isValidPhoneNumber(valueForValidityCheck)
              : false
          }`,
        };
      } else {
        tempObj = {
          value: undefined,
          clicked: true,
          valid: Boolean(false),
        };
      }
    } else {
      tempObj = {
        value: valuePassed,
        clicked: true,
        valid: checkValidity(
          valueForValidityCheck,
          patientHospitalDetails[inputIdentifier].validations
        ),
      };
    }

    const updatedFormElement = updateObject(
      patientHospitalDetails[inputIdentifier],
      tempObj
    );
    const updatedPatientHospitalDetails = updateObject(patientHospitalDetails, {
      [inputIdentifier]: updatedFormElement,
    });

    // checking validation of PatientForm
    let formIsValid = true;
    for (let inputIdentifier in updatedPatientHospitalDetails) {
      formIsValid =
        updatedPatientHospitalDetails[inputIdentifier].valid && formIsValid;
    }
    setPatientHospitalDetails(updatedPatientHospitalDetails);

    setFormIsValid(formIsValid);
  };

  const navigateToFileUploadPage = () => {
    props.setDischargeContextToStore(null);
    history.push(`/ds-upload/fileUpload`);
  };
  const updateMedicalInsertsContactNumber = () => {
    let requestData = null;
    requestData = {
      qr_code: `${props.qrCodeFromStore}`,
      medical_devices: patientHospitalDetails["medicalDevices"].value,
      primary_contact: patientPrimaryNumber,
      isd_code: countryCode,
      language_id: patientHospitalDetails["language"].value["id"],
      is_demographics_approved: true,
      is_care_at_home: false,
    };
    if (patientHospitalDetails["medicalDevices"].value == "") {
      delete requestData["medical_devices"];
    }
    if (manageCarePatientCheckBoxEnabled) {
      requestData["is_care_at_home"] = true;
      let tempCareGiversDetails = [];
      isCareGiversDetailsValid.forEach((value, index) => {
        if (value) {
          let careGiverContactDetails = {
            name: careGiversDetails[index].fullName.value,
            number: careGiversDetails[index].contactNumber.value,
            is_currently_on_duty: `${index == 0 ? true : false}`,
          };
          tempCareGiversDetails.push(careGiverContactDetails);
        }
      });
      requestData["care_giver_details"] = tempCareGiversDetails;
    }

    let requestOptions = {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/${updatePatientInfoEndPoint}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 1000) {
            dispatch(
              showNotificationWithMessage({
                variant: "success",
                message: "Insertion Successful!",
              })
            );
          } else if (result.status === 1001) {
            setShowOverRideNumberModel(true);

            careGiverErrorNotification(result.caregiver, result.patient);

            return;
          } else if (result.status === 1002) {
            careGiverErrorNotification(result.caregiver, result.patient);
            return;
          } else if (result.status === 1003) {
            setShowOverRideNumberModel(true);
            careGiverErrorNotification(result.caregiver, result.patient);
            return;
          }
          props.setQrCodeOnUpload(props.qrCodeOnUploadCode, true);
          props.fetchAgainFunc();
          navigateToFileUploadPage();
        });
    } catch (err) {}
  };
  const navigateToPatientDemographicDetailsPage = () => {
    history.push(
      `/ds-upload/UDPatientPersonalDetails/${props.qrCodeFromStore}`
    );
  };
  const cancelNumberOverRide = async () => {
    let fetchedValue = await getFetchFunction(
      `${getCancelOverRidingNumberEndPoint}/${props.qrCodeFromStore}/${patientHospitalDetails["contactNumber"].value}`,
      token
    );

    setShowOverRideNumberModel(false);
    navigateToFileUploadPage();
  };
  const confirmNumberOverRide = async () => {
    try {
      let fetchedValue = await getFetchFunction(
        `${getOverRideMobileNumberEndPoint}/${props.qrCodeFromStore}/${patientHospitalDetails["contactNumber"].value}`,
        token
      );
      setShowOverRideNumberModel(false);
      props.fetchAgainFunc();
      props.setQrCodeOnUpload(props.qrCodeOnUploadCode, true);

      navigateToFileUploadPage();
    } catch (err) {
      console.log("ERROR OCCURED: ", err);
    }
  };

  const formValidatingForDisable = () => {
    // will return true if data incomplete
    if (!manageCarePatientCheckBoxEnabled) {
      return !formIsValid;
    } else {
      let tempIsCareGiversDetailsValid = isCareGiversDetailsValid.some(
        (item) => item == true
      );
      if (!tempIsCareGiversDetailsValid) {
        return true;
      } else {
        return false;
      }
    }
  };

  const careGiverErrorNotification = (
    listOfCareGiversError,
    listOfPatientNumberUpdationEror
  ) => {
    let renderedData = [];
    if (Array.isArray(listOfCareGiversError)) {
      let renderedData1 = listOfCareGiversError.map((item, index) => {
        return (
          <ToastNotificationWithoutAuto
            key={uuidv4()}
            messageType="warning"
            message={item}
            index={index}
          />
        );
      });
      renderedData.push(...renderedData1);
    }
    if (Array.isArray(listOfPatientNumberUpdationEror)) {
      let renderedData2 = listOfPatientNumberUpdationEror.map((item, index) => {
        return (
          <ToastNotificationWithoutAuto
            key={uuidv4()}
            messageType="warning"
            message={item}
            index={`${index - 1}`}
          />
        );
      });
      renderedData.push(...renderedData2);
    }
    console.log(renderedData);
    setRenderedData((prev) => [...renderedData]);
  };

  // useEffect(() => {
  //   if (showNotificationErrorElements) {
  //     setShowNotificationErrorElements((prev) => !prev);
  //     careGiverErrorNotification(listOfCareGiversError,listOfPatientNumberUpdationEror);
  //   }
  // }, [showNotificationErrorElements]);

  return (
    <div className="patientHospitalDetails__container">
      <div className="patientHospitalDetails__newDischargeHeader">
        <NewDischargeHeader />
      </div>
      {renderedData && renderedData}
      <div className="patientHospitalDetails__details">
        <div className="patientHospitalDetails__enterDischargeHeader">
          Enter Discharge Details
        </div>
        <div className="patientHospitalDetails__medicalInserts">
          <div className="patientHospitalDetails__medicalInsertsText">
            Medical inserts at time of discharge
          </div>
          <div className="patientHospitalDetails__medicalInsertsInputPlus">
            <div className="patientHospitalDetails__medicalInsertsInput">
              <MedicalDeviceSearchDropdownNew
                allowSendingToBackend={false}
                value={patientHospitalDetails["medicalDevices"].value}
                setValue={(val) =>
                  updatePatientHospitalDetails(val, "medicalDevices")
                }
              />
            </div>
          </div>
        </div>
        <div className="patientHospitalDetails__preferredLanguage">
          <div className="patientHospitalDetails__preferredLanguageText">
            Patient’s preferred language
          </div>
          <div className="patientHospitalDetails__preferredLanguageInput">
            <LanguageSelectSearchDropdown
              value={patientHospitalDetails["language"].value}
              setValue={(val) => updatePatientHospitalDetails(val, "language")}
              touchedNotValid={!patientHospitalDetails["language"]["valid"]}
            />
          </div>
        </div>
        <div className="patientHospitalDetails__patientNumber">
          <div className="patientHospitalDetails__patientNumberText">
            Enter patient number
          </div>
          <div className="patientHospitalDetails__patientNumberInput">
            {/* <InputCard
              className="patientHospitalDetails__patientContactInput"
              placeHolder="Enter Primary Contact Number"
              width="100%"
              bgcolor="#FFFFFF"
              value={patientHospitalDetails["contactNumber"].value}
              onChange={(val) =>updatePatientHospitalDetails(val,"contactNumber")}
              touchedNotValid={!patientHospitalDetails["contactNumber"]["valid"]}
              /> */}
            <PhoneNumberInput
              value={patientHospitalDetails["contactNumber"].value}
              setValue={(val) =>
                updatePatientHospitalDetails(val, "contactNumber")
              }
              touchedNotValid={
                patientHospitalDetails["contactNumber"]["valid"] == "false"
              }
            />
          </div>
          <div className="info-message patientDetailsBody__infoMessage">
            Ensure number is correct
          </div>
        </div>
        <CheckBox
          setValue={setManageCarePatientCheckBoxEnabled}
          value={manageCarePatientCheckBoxEnabled}
          showManagedCareText
        />

        {manageCarePatientCheckBoxEnabled && (
          <CareGiversList
            countryCode={countryCode}
            careGiversDetails={careGiversDetails}
            updateCareGivers={(value) =>
              setCareGiversDetails((prev) => {
                return { ...prev, ...value };
              })
            }
            isCareGiversDetailsValid={isCareGiversDetailsValid}
            updateIsCareGiversDetailsValid={(value) =>
              setIsCareGiversDetailsValid((prev) => [...value])
            }
          />
        )}
        <div className="patientHospitalDetails__border"></div>
      </div>

      <div className="patientHospitalDetails__buttons">
        <div className="patientHospitalDetails__button">
          <ButtonBorder
            clicked={navigateToPatientDemographicDetailsPage}
            text="Back"
          />
        </div>
        <div className="patientHospitalDetails__button">
          <ModalCustomButton
            name="Finish"
            status={true}
            bgcolor="rgba(57, 129, 233, 0.68)"
            textColor="white"
            clicked={updateMedicalInsertsContactNumber}
            disabled={formValidatingForDisable()}
          />
        </div>
        {showOverRideNumberModel && (
          <div className="patientHospitalDetails__overRideModal">
            <ModalDelete
              cancel="No"
              select="Yes"
              messageHeading="Phone number already exists"
              messageBody="A user with the same phone number already exists. Do you want to create a new user with same phone number. This will delete all records of the existing user?"
              cancelNurseDelete={cancelNumberOverRide}
              confirmNurseDelete={confirmNumberOverRide}
              showCrossIcon={true}
              closeModal={() => setShowOverRideNumberModel((prev) => !prev)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAgainFunc: () => dispatch(actions.fetchingAgain(true)),
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
    setDischargeContextToStore: (dischargeContext) =>
      dispatch(actions.setDischargeContextFunc(dischargeContext)),
    setQrCodeOnUpload: (qrCode, status) =>
      dispatch(actions.setQRCodeOnUpload(qrCode, status)),
  };
};
const mapStateToProps = (state) => {
  return {
    dischargeContextFromStore: state.patient.dischargeContext,
    qrCodeFromStore: state.patient.QRCode,
    primaryContactNumberFromStore: state.patient.primaryContactNumber,
    qrCodeOnUploadCode:
      state.patient.qrCodeOnUpload && state.patient.qrCodeOnUpload["code"],
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientHospitalDetails);
