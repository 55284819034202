export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

function validatedate(dateString) {
  // dd/mm/yyyy
  let dateformat = /(0?[1-9]|[1-2][0-9]|3[01])[\/](0?[1-9]|1[0-2])[\/]\d{4}$/;
  
  // Match the date format through regular expression
  if (dateString.match(dateformat)) {
    let operator = dateString.split("/");

    // Extract the string into month, date and year
    let datepart = [];
    if (operator.length > 1) {
      datepart = dateString.split("/");
    }
    let month = parseInt(datepart[1]);
    let day = parseInt(datepart[0]);
    let year = parseInt(datepart[2]);

    // Create list of days of a month
    let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (month == 1 || month > 2) {
      if (day > ListofDays[month - 1]) {
        ///This check is for Confirming that the date is not out of its range
        return false;
      }
    } else if (month == 2) {
      let leapYear = false;
      if ((!(year % 4) && year % 100) || !(year % 400)) {
        leapYear = true;
      }
      if (leapYear == false && day >= 29) {
        return false;
      } else if (leapYear == true && day > 29) {
        return false;
      }
    }
  } else {
    return false;
  }
  return true;
}
export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.toString().trim() !== "" && isValid;
  }
 if(rules.isArray){
   isValid = Array.isArray(value)&& value.length > 0 && isValid;
 }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }
  if (rules.dateValid) {
    isValid = validatedate(value) && isValid && (value.toString().trim() !=="01/01/1900");
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }
  if (rules.isAlphaNumeric) {
    const pattern =/^[a-zA-Z0-9:-\s\/]+$/;
    isValid = pattern.test( value ) && isValid
  }

  if(rules.isObject){

    isValid =(value !==null) &&  (typeof value === "object")  && isValid
  }

  return isValid;
};
