import React from "react";
import PatientHospitalDetails from "../../../Components/screensComponent/uploadDischarge/patientHospitalDetails/PatientHospitalDetails";

import "./UDPatientHospitalDetails.css";
const UDPatientHospitalDetails = () => {
  return (
    <div className="udPatientHospitalDetails__container">
        <PatientHospitalDetails ></PatientHospitalDetails>
    </div>
  );
};

export default UDPatientHospitalDetails;
