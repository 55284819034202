import React from "react";
import hamburgerIcon from "../../../../AllIcons/hamburger.png";
import filterS2 from "../../../../AllIcons/filterS2.png";
import "./DashboardHeader.css";
const DashboardHeader = (props) => {
  return (
    <div className="dashboardHeader">
      <div className="  dashboardHeader__name">{props.headerName} {props.otherPatientsNumber &&<span className="dashboardHeader__otherPatients">{props.otherPatientsNumber}</span>}</div>
      <div className="  dashboardHeader__items">
        {props.allPatientInfo && (
          <div className="dashboardHeader__patientNumber" style={{color:props.color}}>
            {props.allPatientInfo} 
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardHeader;
