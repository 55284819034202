import React, { useState, useEffect } from "react";
import UploadDischargeNameHeader from "../../Components/screensComponent/uploadDischarge/UploadDischargeNameHeader/UploadDischargeNameHeader";
import UploadDischargeNameBody from "../../Components/screensComponent/uploadDischarge/UploadDischargeNameBody/UploadDischargeNameBody";
import UploadDischargeHeader from "../../Components/screensComponent/uploadDischarge/UploadDischargeHeader/UploadDischargeHeader";
import UDPatientPersonalDetails from "./UDPatientPersonalDetails/UDPatientPersonalDetails";
import UDPatientHospitalDetails from "./UDPatientHospitalDetails/UDPatientHospitalDetails";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import * as actions from "../../Store/actions/index";
import "./UploadDischargeScreen.css";
import DragDrop from "./FileUpload/DRAGnDROP/DragDrop";
import { getFetchFunction } from "../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { getDischargeDetailsEndPoint } from "../../HighOrderFunctions/APIs/allGetAPI";
import PdfViewReactPdf from "../PdfViewReactPdf/PdfViewReactPdf";
import { dynamicSortDesc } from "../../HighOrderFunctions/dynamicSort";
import monthChange from "../../HighOrderFunctions/MonthChange";
import { useHistory } from "react-router";
import FileUpload from "./FileUpload/FileUpload";
import DischargeContextUpload from "./DischargeContextUpload/DischargeContextUpload";
import ReadingThroughSummary from "./ReadingThroughSummary/ReadingThroughSummary";
import ReachedTextScreen from "./RichedTextScreen/RichedTextScreen";
import LoaderThreeDot from "../../Loader/LoaderThreeDot";
import { v4 as uuidv4 } from "uuid";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
const position = { of: "#IdForUploadDischargeBody" };
const UploadDischarge = (props) => {
  const token = useSelector((state) => state.auth.accessToken);
  const history = useHistory();
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedSendInvitation, setSelectedSendInvitation] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCurrentPatientWardNurse, setTotalCurrentPatientWardNurse] =
    useState("");
  const [currentPatientName, setCurrentPatientName] = useState("");
  const [searchColumns, setSearchColumns] = useState([
    "patientName",
    "qrCode",
    "messageStatus",
    "registrationDate",
    "dob",
    "messageTime",
    "contactNumber",
    "age",
    "hospitalId",
    "dischargeStatus",
  ]);
  const [q, setQ] = useState("");

  // function to fetch list of patients whose discharge summary has been parsed but not approved
  const fetchedDataFunc = async () => {
    setIsLoading(true);
    let fetchedValue = await getFetchFunction(
      `${getDischargeDetailsEndPoint}`,
      token
    );
    setIsLoading(false);
    props.setWardNurseRowNumber(-1);
    if (Array.isArray(fetchedValue["data_list"]) == true) {
      if (fetchedValue["data_list"].length == 0) {
        history.push("/ds-upload/fileUpload");
      }
      setTotalCurrentPatientWardNurse(fetchedValue["total_current_patients"]);
      let sortedData = fetchedValue.data_list.sort(
        dynamicSortDesc("creation_date")
      );
      let tempSortedData = sortedData.map((item) => {
        return {
          ...item,
          ["dob"]: item["dob"] && monthChange(item["dob"], "covidGetDOB"),
          ["registrationDate"]:
            item["registrationDate"] &&
            monthChange(item["registrationDate"], "covidGetDOB"),
        };
      });
      if (tempSortedData && tempSortedData[0]) {
        setFetchedData(tempSortedData);
        setCurrentPatientName(tempSortedData[0]["patientName"]);
        props.setQRCodeToStoreFunc(tempSortedData[0]["qrCode"]);
        props.setPatientDetailsToStore(tempSortedData[0]);
      }
    }
  };
  // props.fetchingStatus  is state stored in redux to re-fetch list of patients and props.
  useEffect(() => {
    if (props.fetchingStatus === true) {
      fetchedDataFunc();
      props.fetchingStatusToFalseFunc(false);
    }
  }, [props.fetchingStatus]);

  useEffect(() => {
    fetchedDataFunc();
  }, []);
  let renderedData = [];

  // function to allow filtering in the  list of patient
  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] &&
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }
  //function to set searched value to allow filtering
  const setInputValueFunc = (e) => {
    setQ(e.target.value);
  };


  // function to delete user from list in uploadDischarge screen
  const deleteUploadDischargeUser = async (itemIndex) => {
    let tempFollowupsArray = fetchedData.filter((item, index) => {
      return index !== itemIndex;
    });
    setFetchedData(tempFollowupsArray);
  };

  // rendering list of patients
  renderedData = search(fetchedData).map((item, index) => {
    return (
      <UploadDischargeNameBody
        key={uuidv4()}
        selectedSendInvitation={selectedSendInvitation}
        setSelectedSendInvitation={setSelectedSendInvitation}
        index={index}
        name={item["patientName"]}
        qrCode={item["qrCode"]}
        hospitalId={item["hospitalId"]}
        dischargeStatus={item["dischargeStatus"]}
        age={item["age"]}
        rowNumber={index}
        dob={item["dob"]}
        messageStatus={item["message_status"]}
        messageTime={item["message_time"]}
        contactNumber={item["contact"]}
        registrationDate={item["registrationDate"]}
        gender={item["gender"]}
        isResend={item["is_resend"]}
        deleteUploadDischargeUser={deleteUploadDischargeUser}
      />
    );
  });

  return (
    <div className="uploadDischarge__container">
      <div className="uploadDischarge__header">
        <UploadDischargeHeader
          setInputFunc={setInputValueFunc}
          totalCurrentPatientWardNurse={totalCurrentPatientWardNurse}
        />
      </div>
      <div className="uploadDischarge__body">
        <div className="uploadDischarge__bodyNameHeader">
          <UploadDischargeNameHeader />
        </div>
        <div className="uploadDischarge__bodyNameBody">
          {isLoading ? (
            <div className="loaderbar">
              {" "}
              <LoaderThreeDot />{" "}
            </div>
          ) : (
            <div>{renderedData}</div>
          )}
        </div>
      </div>
      <div className="uploadDischarge__sidebar">{routes}</div>
    </div>
  );
};
let routes = (
  <Switch>
    <Route
      path="/ds-upload/dischargeSummary"
      render={() => <PdfViewReactPdf height="calc(100vh - 300px)" />}
    />
    <Route
      // path={`/ds-upload/UDPatientPersonalDetails?name=:id`}
      path="/ds-upload/UDPatientPersonalDetails/:id"
      render={()=><ErrorBoundary>

        <UDPatientPersonalDetails />
      </ErrorBoundary>
    }
    />
    <Route
      exact
      path={`/ds-upload/UDPatientHospitalDetails/:id`}
      render={()=><ErrorBoundary><UDPatientHospitalDetails /></ErrorBoundary>}
    />
    <Route exact path="/ds-upload/fileUpload" render={()=><ErrorBoundary><FileUpload /></ErrorBoundary>} />
    <Route exact path="/ds-upload/richedText" render={()=><ErrorBoundary><ReachedTextScreen /></ErrorBoundary>} />
    <Route
      exact
      path="/ds-upload/dischargeContextUpload"
      render={()=><ErrorBoundary><DischargeContextUpload/></ErrorBoundary>}
    />
    <Route
      exact
      path="/ds-upload/readingThroughSummary"
      render={()=><ErrorBoundary><ReadingThroughSummary /></ErrorBoundary>}
    />
    <Redirect to="/ds-upload/fileUpload" />
  </Switch>
);

const mapStateToProps = (state) => {
  return {
    fetchingStatus: state.patient.fetchAgain,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchingStatusToFalseFunc: () => dispatch(actions.fetchingAgain(false)),
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
    setPatientDetailsToStore: (details) =>
      dispatch(actions.setPatientDetails(details)),
    setWardNurseRowNumber: (rowNumber) =>
      dispatch(actions.setWardNurseRowNumber(rowNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDischarge);
