import React, { useState, useEffect } from "react";
import callDischargeContextIcon from "../../../../../../../AllIcons/callDischargeContext.png";
import triangleBottom from "../../../../../../../AllIcons/triangleBottom.png";
import AppointmentCard from "../AppointmentCard/AppointmentCard";
import { connect } from "react-redux";
import "./AppointmentNurseDate.css";
import "./AppointmentNurseDate.css";
import { newDateFromNumberFunc } from "../../../../../../../HighOrderFunctions/newDateFromNumberFunc";
import { stringToDateFunc } from "../../../../../../../HighOrderFunctions/stringToDateFunc";
const AppointmentNurseDateBottom = (props) => {

  const [date, setDate] = useState(props.item["schedule_date"]);
  const [isNurseCall, setIsNurseCall] = useState(props.item["is_nurse_call"]);
  const [toggle, setToggle] = useState(false);
  let modalRef = null;
  let myInput = null;
  const hiddenFileInput = React.useRef(null);
 
  const deleteDateFromList = (id) => {
    props.deleteDate(id);
    setToggle(false);
  };

  const updateDateFunc = (event) => {
    props.addCallDateToList(event.target.value);

    setToggle(false)
  };
  const toggleFunc = () => {
    setToggle((prev) => !prev);
  };
  const handleKeyDown = (event) => {
    const { key } = event;
    const keys = ["Escape", "Tab"];
    const enterKey = "Enter";
    const allKeys = [...keys, enterKey];
    if (keys.indexOf(key) > -1 || allKeys.indexOf(key) > -1) {
      setToggle(false);
    }
  };
  useEffect(() => {}, []);
  return (
    <div
      className={`appointmentNurseDate__container ${
        props.cardIndexNumber == props.indexNumber ? "nurseDateChosenCSS" : ""
      } ${toggle ? "chosenNurseDateToggle" : ""}`}
    >
      <div
        className="appointmentNurseDate__dateCard"
        ref={(node) => (modalRef = node)}
      >
        {toggle && (
          <AppointmentCard
            dateSelected={props.date}
            updateDateFunc={(e) => updateDateFunc(e)}
            editCall={() => props.updateDate()}
            deleteCall={() => deleteDateFromList(props.item.id)}
          />
        )}
      </div>
      <div className="appointmentNurseDate__arrowDownIcon">
        <img src={triangleBottom} style={{ width: "10px", height: "10px" }} />
      </div>
      <div
        className={`appointmentNurseDate__mainBlock 
         `}
        onClick={toggleFunc}
        onBlur={() => toggleFunc(false)}
        onKeyDown={(e) => handleKeyDown(e)}
      >
        <div className="appointmentNurseDate__textIcon">
          <div className="appointmentNurseDate__callIcon">
            <img
              src={callDischargeContextIcon}
              style={{ width: "10px", height: "10px" }}
            />
          </div>

          <div className="appointmentNurseDate__text">Nurse Call</div>
        </div>

        <div className="appointmentNurseDate__date">
          {/* <input
            // id="dateInput"
            className="dateInput"
            style={{ display: "none" }}
            type="date"
            className="appointmentNurseDate__inputField"
            name="appointmentdate"
            placeholder="dd-mm-yyyy"
            min={newDateFromNumberFunc(props.dischargeDate, 0)}
            max={newDateFromNumberFunc(props.dischargedDate, 21)}
            value={date}
            // ref={hiddenFileInput}
            ref={(input) => (myInput = input)}
            onChange={updateDateFunc}
          /> */}
          {stringToDateFunc(date)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // monitoringDuration:
    //   state.patient.patientDetails &&
    //   state.patient.patientDetails[0]?.["daysToMonitor"],
    // dischargeDate:
    //   state.patient.patientDetails &&
    //   state.patient.patientDetails[0]?.["dischargeDate"],
  };
};
export default connect(mapStateToProps, null)(AppointmentNurseDateBottom);

// [
//   {
//     "id": 1,
//     "day_number": 1,
//     "schedule_date": "2021-02-03",
//     "schedule_time": "08:00:00"
//   },
//   {
//     "id": 2,
//     "day_number": 2,
//     "schedule_date": "2021-02-04",
//     "schedule_time": "08:00:00"
//   }]
