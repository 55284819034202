import React from "react";
import Capsule from "../../Capsule";
import CircularIcon from "../../../CircularIcon/CircularIcon";
import './DischargeCapsuleStatus.css';
const DashboardCapsuleStatus = (props) => {
  return (
    <div className="dischargeCapsuleStatus__container">
      <div className="dischargeCapsuleStatus__element1">
        <Capsule name={props.name} bgcolor={props.bgcolor} />
      </div>
      {/* <div className="dischargeCapsuleStatus__element2">
        <CircularIcon imgsrc={props.imgsrcIcon} />
      </div> */}
    </div>
  );
};

export default DashboardCapsuleStatus;
