import React, { useEffect, useState } from "react";
import "./NavbarDashboard.css";
import Searchbar from "../../../Components/Searchbar/Searchbar";
import { connect } from "react-redux";
import * as actions from "../../../Store/actions/index";
import { useHistory } from "react-router-dom";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { getTotalPatientUnderMonitoringEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import NavbarDashboardDischargeNavigateButtons from "../NavbarDashboardDischargeNavigateButtons/NavbarDashboardDischargeNavigateButtons";
const NavbarDashboard = (props) => {
  const [totalPatient, setTotalPatient] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const {response:totalPatientsUnderMonitoring,isLoading} = useFetch(getTotalPatientUnderMonitoringEndPoint,false);
  const history = useHistory();
  
  const setInputFunc = (e) => {
    setSearchedValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    const { key } = event;
    if (event.keyCode == 13) {
      props.setSearchedInputFunc(searchedValue);

      props.showNavigationFuncToStore(true);
      history.push("/dischargeUnderMonitoring");
    }
    const keys = ["Escape", "Tab"];
    const enterKey = "Enter";
    const allKeys = [...keys, enterKey];
    if (keys.indexOf(key) > -1 || allKeys.indexOf(key) > -1) {
    }
  };
  const navigateToDischargeUnderMonitoring = () => {
    history.push("/dischargeUnderMonitoring");
    props.setSearchedInputFunc("");
  };
  const navigateToWardNurseScreen = () => {
    props.setWardNurseRowNumber(-1)
    history.push("/ds-upload");
  };
  return (
    <div className="navbarDashboard__container">
      <NavbarDashboardDischargeNavigateButtons
        header="Post Discharge Nurse Dashboard"
        text1="Worklist"
        clicked1={navigateToDischargeUnderMonitoring}
        text2="Enrol Patient"
        clicked2={navigateToWardNurseScreen}
      />

      <div className="navbarDashboard__monitoringUpdateRefresh">
        <div className="navbarDashboard__monitoring">
          <div className="navbarDashboard__monitoringText">
            Total current patients:
          </div>
          <div className="navbarDashboard__monitoringTextValue">
            {totalPatientsUnderMonitoring["patient_under_monitoring"]}
          </div>
        </div>
        <div className="navbarDashboard__search">
          <Searchbar
            placeholder="Search"
            BRcolor="white"
            setInputFunc={setInputFunc}
            searchedValue={searchedValue}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </div>
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    showNavigationFuncToStore: (status) =>
    dispatch(actions.showNavigationFunc(status)),
    setSearchedInputFunc: (value) => dispatch(actions.setInputFunc(value)),
    setWardNurseRowNumber: (rowNumber) =>
    dispatch(actions.setWardNurseRowNumber(rowNumber)),
  };
};

export default connect(null, mapDispatchToProps)(NavbarDashboard);
