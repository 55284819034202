import React from "react";
import camera from "../../../../AllIcons/fileIcon.svg";

const FileInputButton = ({ fileInput, setFileInput, CaptureHandler }) => {
  return (
    <div>
      <button
        className="capture"
        onClick={() => fileInput.click()}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={camera} alt="camera" width="50px" height="50px" />
        Select File
        <input
          type="file"
          ref={(fileInputref) => setFileInput(fileInputref)}
          multiple
          onChange={CaptureHandler}
          style={{ display: "none" }}
        />
      </button>
    </div>
  );
};

export default FileInputButton;
