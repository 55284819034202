import React, { useState, useEffect } from "react";
import medicalHistoryIcon from "../../../../AllIcons/patientAlertsNavigationMedicalHistoryIcon.png";
import medicalActive from "../../../../AllIcons/medicalActive.png";
import detailsS3 from "../../../../AllIcons/detailsS3.png";
import detailsActive from "../../../../AllIcons/detailsActive.png";
import electrocardiogramIcon from "../../../../AllIcons/electrocardiogram.png";
import electrocardiogramActive from "../../../../AllIcons/electrocardiogramActive.png";
import phoneCallIcon from "../../../../AllIcons/patientAlertsNavigationPhoneCallIcon.png";
import phoneCallActive from "../../../../AllIcons/phoneCallActive.png";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../Store/actions/index";
import Card from "../../../Card/Card";
import "./PatientAlertsNavigation.css";
import { useHistory } from "react-router";

const PatientAlertsNavigation = (props) => {
  const history = useHistory();
  const [cardArray, setCardArray] = useState([1, 0, 0, 0]);
  const [chosenCard, setChosenCard] = useState(0);
  const [cardSumStatus, setCardSumStatus] = useState(0);
  //state for recalling function checkForAllScreenApproval

  const cardClicked = (number) => {
    let currArray = cardArray;
    currArray[number - 1] = 1;
    setCardArray(currArray);
    props.setCurrentCardNumberToStore(number);
    setChosenCard(number);
    checkTotalCardClicked();
  };
  const checkForAllScreensApproval = () => {
    let tempApproval = true;
    if (!props.dischargeNavlinkApproveStatus) {
      tempApproval = false;
    }
    if (!props.followUpSchedulesNavlinkApproveStatus) {
      tempApproval = false;
    }
    if (!props.alertThresholdNavlinkApproveStatus) {
      tempApproval = false;
    }
    if (!props.medicationSchedulesNavlinkApproveStatus) {
      tempApproval = false;
    }

    if (tempApproval) {
      props.approveButtonStatusFuncToStore(true);
    }else{
      props.approveButtonStatusFuncToStore(false)
    }
  };
  const checkTotalCardClicked = () => {
    let status = cardArray.reduce((acc, number) => acc + number, 0);
    setCardSumStatus(status);
    if (status === 4) {
      checkForAllScreensApproval();
    }
  };

  useEffect(() => {
    checkTotalCardClicked();
  }, [
    chosenCard,
    cardSumStatus,
    props.dischargeNavlinkApproveStatus,
    props.alertThresholdNavlinkApproveStatus,
    props.followUpSchedulesNavlinkApproveStatus,
    props.medicationSchedulesNavlinkApproveStatus,
  ]);

  useEffect(() => {
    if (
      props.dischargeNavlinkApproveStatus &&
      props.alertThresholdNavlinkApproveStatus &&
      props.followUpSchedulesNavlinkApproveStatus &&
      props.medicationSchedulesNavlinkApproveStatus
    ) {
      props.approveButtonStatusFuncToStore(true);
    }else{
      props.approveButtonStatusFuncToStore(false);
    }
  }, [
    props.followUpSchedulesNavlinkApproval,
    props.alertThresholdNavlinkApproval,
    props.followUpSchedulesNavlinkApproval,
    props.medicationSchedulesNavlinkApproval,
  ]);
  let pathName = history.location.pathname ? history.location.pathname : "";
  useEffect(() => {
    updateApproveButton(pathName);
  }, [pathName])

  const updateApproveButton = (pathName) => {
    if (pathName.toString().includes("DischargeDetails")) {
      cardClicked(1);
    } else if (pathName.toString().includes("AlertThreshold")) {
      cardClicked(2);
    } else if (pathName.toString().includes("FollowUpSchedules")) {
      cardClicked(3);
    } else if (pathName.toString().includes("MedicationSchedules")) {
      cardClicked(4);
    } else {
      cardClicked(0);
    }
  };
 
  return (
    <div className="patientAlertsNavigation__container">
      <div
        className="patientAlertsNavigationCard"
        onClick={() => cardClicked(1)}
      >
        <NavLink
          to={"/patientAlerts/DischargeDetails/" + props.qrCodeFromStore}
        >
          <Card
            SRC={detailsS3}
            activeSrc={detailsActive}
            name="Discharge Details"
            cardNumber="1"
            key={1}
            navContains="DischargeDetails"
            approved={props.dischargeNavlinkApproveStatus || props.isMonitoringApproved}
          />
        </NavLink>
      </div>
      <div
        className="patientAlertsNavigationCard"
        onClick={() => cardClicked(2)}
      >
        <NavLink to={"/patientAlerts/AlertThreshold/" + props.qrCodeFromStore}>
          <Card
            SRC={medicalHistoryIcon}
            activeSrc={medicalActive}
            name="Alert Threshold"
            cardNumber="2"
            navContains="AlertThreshold"
            key={2}
            approved={props.alertThresholdNavlinkApproveStatus || props.isMonitoringApproved}
          />
        </NavLink>
      </div>
      <div
        className="patientAlertsNavigationCard"
        onClick={() => cardClicked(3)}
      >
        <NavLink
          to={"/patientAlerts/FollowUpSchedules/" + props.qrCodeFromStore}
        >
          <Card
            SRC={electrocardiogramIcon}
            activeSrc={electrocardiogramActive}
            name="Follow-up Schedules"
            cardNumber="3"
            key={3}
            navContains="FollowUpSchedules"
            approved={props.followUpSchedulesNavlinkApproveStatus || props.isMonitoringApproved}
          />
        </NavLink>
      </div>
      <div
        className="patientAlertsNavigationCard"
        onClick={() => cardClicked(4)}
      >
        <NavLink
          to={"/patientAlerts/MedicationSchedules/" + props.qrCodeFromStore}
        >
          <Card
            SRC={phoneCallIcon}
            activeSrc={phoneCallActive}
            name="Medication Schedules"
            cardNumber="4"
            key={4}
            navContains="MedicationSchedules"
            approved={props.medicationSchedulesNavlinkApproveStatus || props.isMonitoringApproved}
          />
        </NavLink>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    dischargeNavlinkApproveStatus:
      state.monitoringCapsule.dischargeNavlinkApproveStatus,
    followUpSchedulesNavlinkApproveStatus:
      state.monitoringCapsule.followUpSchedulesNavlinkApproveStatus,
    alertThresholdNavlinkApproveStatus:
      state.monitoringCapsule.alertThresholdNavlinkApproveStatus,
    medicationSchedulesNavlinkApproveStatus:
      state.monitoringCapsule.medicationSchedulesNavlinkApproveStatus,
     isMonitoringApproved: state.monitoringCapsule.isMonitoringApproved,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCardNumberToStore: (number) =>
      dispatch(actions.setCurrentCardNumberFunc(number)),
    approveButtonStatusFuncToStore: (status) =>
      dispatch(actions.approveButtonStatusFunc(status)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientAlertsNavigation);
