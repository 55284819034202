import React from "react";
import MonitoringStatus from "./MonitoringStatus";
import NavbarMonitoringStatusScreen from "../../Components/Navbar/NavbarScreens/NavbarMonitoringStatusScreen/NavbarMonitoringStatusScreen";
import './MonitoringStatusWithNavbar.css'
const MonitoringStatusWithNavbar = () => {
  return (
    <div className="monitoringStatusWithNavbar__container">
      <div className="monitoringStatusWithNavbar__vEngageNavbarHeader">
        <NavbarMonitoringStatusScreen />
      </div>
      <div className="monitoringStatusWithNavbar__bodyContent">
        <MonitoringStatus />
      </div>
      <div className="monitoringStatusWithNavbar__footer"></div>
    </div>
  );
};

export default MonitoringStatusWithNavbar;
