import React from 'react';
import'./RegisterCovidPatient.css';
import covidDoctorImage from '../../../AllIcons/covidDoctorImage.png';
import addCovidIcon from '../../../AllIcons/addCovidIcon.png';
import ButtonWImgLeft from '../../../Components/Buttons/ButtonWImgLeft/ButtonWImgLeft';
import {useHistory} from 'react-router-dom';
const RegisterCovidPatient = (props) => {
   const history = useHistory();
   const navigateToCovidRegistrationForm= () =>{
       history.push("/covid-registration/covidRegistrationForm/")
   }
    return (
        <div className="registerCovidPatient__container">
            <div className="registerCovidPatient__header">Register a new Case</div>
            <div className="registerCovidPatient__covidDoctorsImage">
                <img src ={covidDoctorImage} alt ="Covid Doctors" />
            </div>
            <div className="registerCovidPatient__registerPatientText">
            To add a new patient, kindly enter their details below
            </div>
            <div className="registerCovidPatient__addCovidPatientButton">
                <ButtonWImgLeft  imgsrc={addCovidIcon} text="Add patient" 
                clicked={navigateToCovidRegistrationForm}
                />
            </div>
        </div>
    )
}

export default RegisterCovidPatient
