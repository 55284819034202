import React from "react";
import "./ModalCustomButton.css";
const ModalCustomButton = (props) => {
  return (
    <button
      className={`modalCustomButton
        ${
          props.status == true
            ? "modalCustomButtonConfirm"
            : "modalCustomButtonCancel"
        }
        ${props.passedClass ? props.passedClass : ""}
       
      `}
      style={{
        marginLeft: props.marginLeft && props.marginLeft,
        color: props.color && props.color,
        ...props.customStyle,
      }}
      disabled={props.disabled}
      onClick={props.clicked}
      title={(props.title && props.title.length) > 0 ? `${props.title}` : ""}
    >
      <div
        style={{
          color: props.textColor,
        }}
        className="modalCustomButton__text"
      >
        {props.name}
      </div>
    </button>
  );
};

export default ModalCustomButton;
