import React, { useState, useEffect } from "react";
import ApprovalDone from "../../../AllIcons/ApprovalDone.gif";
import "./ApprovePage.css";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../Store/actions/index";

const ApprovePage = (props) => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => getBackTodashboardPageFunc(), 3000);
  }, []);
  const getBackTodashboardPageFunc = () => {
    props.resetPatientStore();
    history.push("/dashboard");
  };
  return (
    <div className="approvePage__container">
      <img src={ApprovalDone} height="200px" width="200px" />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    approveButtonStatusFuncToStore: (status) =>
      dispatch(actions.approveButtonStatusFunc(status)),
    
    resetPatientStore: () => dispatch(actions.resetPatientState()),
  };
};

export default connect(null, mapDispatchToProps)(ApprovePage);
