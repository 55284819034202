import React from "react";
import PatientAlerts from "./PatientAlerts";
import NavbarPatientAlertsScreen from "../../Components/Navbar/NavbarScreens/NavbarPatientAlertsScreen/NavbarPatientAlertsScreen";
import './PatientAlertsWithNavbar.css'
const PatientAlertsWithNavbar = () => {
  return (
    <div className="patientAlertsWithNavbar__container">
      <div className="patientAlertsWithNavbar__vEngageNavbarHeader">
        <NavbarPatientAlertsScreen />
      </div>
      <div className="patientAlertsWithNavbar__bodyContent">
        <PatientAlerts />
      </div>
      <div className="patientAlertsWithNavbar__footer"></div>
    </div>
  );
};

export default PatientAlertsWithNavbar;
