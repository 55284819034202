import React from "react";
import DischargeUnderMonitoring from "./DischargeUnderMonitoring";
import './DischargeUnderMonitoringWithNavbar.css';
import NavbarDischargeUnderMonitoringScreen from "../../Components/Navbar/NavbarScreens/NavbarDischargeUnderMonitoringScreen/NavbarDischargeUnderMonitoringScreen";
const DischargeUnderMonitoringWithNavbar = () => {
  return (
    <div className="dischargeUnderMonitoringWithNavbar__container">
      <div className="dischargeUnderMonitoringWithNavbar__vEngageNavbarHeader">
        <NavbarDischargeUnderMonitoringScreen />
      </div>
      <div className="dischargeUnderMonitoringWithNavbar__bodyContent">
        <DischargeUnderMonitoring />
      </div>
      <div className="dischargeUnderMonitoringWithNavbar__footer"></div>
    </div>
  );
};

export default DischargeUnderMonitoringWithNavbar;
