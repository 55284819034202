import React, { useEffect, useState } from "react";
import "./OnCallRegistration.css";
import OnCallRegistrationHeader from "../../Components/screensComponent/OnCallRegistration/OnCallRegistrationHeader/OnCallRegistrationHeader";
import OnCallRegistrationSidebar from "../../Components/screensComponent/OnCallRegistration/OnCallRegistrationSidebar/OnCallRegistrationSidebar";
import OnCallRegistrationBody from "../../Components/screensComponent/OnCallRegistration/OnCallRegistrationBody/OnCallRegistrationBody";

// Component to register new Nurse 
const OnCallRegistration = () => {
  const [q, setQ] = useState("");

  const setInputValueFunc = (e) => {
    setQ(e.target.value);
  };
  return (
    <div className="onCallRegistration__container">
      <div className="onCallRegistration__header">
       <OnCallRegistrationHeader 
       setInputFunc={setInputValueFunc} value={q} />
      </div>
      <div className="onCallRegistration__SidebarBody">
        <div className="onCallRegistration__sidebar">
            <OnCallRegistrationSidebar />
        </div>
        <div className="onCallRegistration__body">
            <OnCallRegistrationBody 
            searchedValue = {q}
            />
        </div>
      </div>
    </div>
  );
};

export default OnCallRegistration;
