import React, { useState } from "react";
import { connect,useSelector } from "react-redux";

import "./StopMonitoring.css";
import NurseUpdatingCard from "../../../../Card/MonitoringStatusCards/NurseUpdatingCard/NurseUpdatingCard";
import { postFetchFunction } from "../../../../../HighOrderFunctions/FetchMethod/postFetchFunction";
import { postSendPatientNotesEndPoint } from "../../../../../HighOrderFunctions/APIs/allPostAPI";
import {useHistory} from 'react-router-dom';
import * as actions from "../../../../../Store/actions/index";

const StopMonitoring = (props) => {
  const accessToken = useSelector(state=>state.auth.accessToken)

  const history = useHistory();

  const [reasonToStopMonitoring, setReasonToStopMonitoring] = useState("");
  const [noteForPatient, setNoteForPatient] = useState("");
  const [noteForMedicalRecord, setNoteForMedicalRecord] = useState("");
  const [errorOccured,setErrorOccured] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");
  const updateStopMonitoringDetails = async () => {
    try {
      let urlEndPoint = `${postSendPatientNotesEndPoint}`;
      let formToBeSend = {
        qr_code: `${props.qrCodeFromStore}`,
        date: new Date().toISOString().split("T")[0],
        source: "STOP_MONITORING",
        reason_to_visit_hospital: reasonToStopMonitoring,
        notes_text: noteForPatient,
        medical_records: noteForMedicalRecord,
      };
      await postFetchFunction(urlEndPoint, formToBeSend,accessToken);
      setReasonToStopMonitoring("");
      setNoteForMedicalRecord("");
      setNoteForPatient("");
      setErrorOccured(false);
      props.showNavigationFuncToStore(true);
      history.push("/dashboard");
      setErrorMessage("")
      props.clicked();
    } catch (err) {
      setErrorMessage(err.detail)
      setErrorOccured(true);
    }
  };
  return (
    <div className="stopMonitoring__container">
      <NurseUpdatingCard
        header="Stop Monitoring"
        nameHeader1="Reason to visit hospital"
        placeholder1="please specify"
        value1={reasonToStopMonitoring}
        setValue1={setReasonToStopMonitoring}
        nameHeader2="Note for patient"
        placeholder2="Start Typing  here..."
        value2={noteForPatient}
        setValue2={setNoteForPatient}
        nameHeader3="Note for Medical Record"
        placeholder3="Type here"
        value3={noteForMedicalRecord}
        setValue3={setNoteForMedicalRecord}
        key="2"
        submitFunc={updateStopMonitoringDetails}
        clicked={props.clicked}
        errorOccured={errorOccured}
        errorMessage={errorMessage}
      />
    </div>
  );  
};

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StopMonitoring);
