import React from "react";
import { connect } from "react-redux";
// import { FeverDataTypes, feverDataset } from "../Data";
import { getFeverChartEndPoint } from "../../HighOrderFunctions/APIs/allGetAPI";
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  // Export,
  Title,
  Tooltip,
  ValueAxis,
  Size,
  Legend,
  Label,
  Font,
  Connector,
  // TickInterval,
  ConstantLine,
  Grid,
  // Tick,
} from "devextreme-react/chart";
import "./LineChart.css";
import { getFetchFunction } from "../../HighOrderFunctions/FetchMethod/getFetchFunction";
import LoaderThreeDot from "../../Loader/LoaderThreeDot";
import NoChartData from "../../screens/MonitoringStatus/MonitoringStatusBody/SymptomComplianceCharts/SignsSymptomsCharts/NoChartData/NoChartData";
import noChartDataImage from "../../AllIcons/noChartDataImage.png";
import * as actions from "../../Store/actions/MonitoringStatusAction";
class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lowAverage: 101.5,
      width: "100%",
      type: "line",
      feverDataSets: [],
      feverAttributesKeys: [
        { value: "yValue", name: `${this.props.name}`, time: "time" },
      ],
      loading: false,
      height: props.height,
      identifier: props.identifier,
    };
    this.handleChange = this.handleChange.bind(this);
    this.customizeLabel = this.customizeLabel.bind(this);
    this.customizePoint = this.customizePoint.bind(this);
    this.customizeTooltip = this.customizeTooltip.bind(this);
  }

  async getfeverDataSets() {
    this.setState((prev) => {
      return { ...prev, loading: true };
    });
    const fetchFunc = async () => {
      const response = await getFetchFunction(
        `${getFeverChartEndPoint}/${this.props.qrCodeFromStore}/${this.props.name}`,
        `${this.props.accessToken}`
      );
      return response;
    };
    let result = await fetchFunc();
    console.log("RESULT FEVER: ", result);
    this.setState((prev) => {
      return { ...prev, loading: false };
    });

    let temp = [];
    let idforEachField = 0;
    // console.log("FEVER CHART: ", result);
    Array.isArray(result?.daywise_data) &&
      result?.daywise_data.map((item, index) => {
        let dayNumber = item.day_number;
        // let date = item.date;
        let daywiseArray = [];
        item.data.map((itemData) => {
          daywiseArray.push({
            day: `D${dayNumber}`,
            yValue: parseFloat(itemData.measure),
            date: itemData.date,
            time: itemData.time,
            onset: itemData.onset,
            site: itemData.site,
            character: itemData.character,
            id: ++idforEachField,
          });
        });
        let earlierArray = [...temp];
        temp = [...earlierArray, ...daywiseArray];
      });

    this.setState({
      ...this.state,
      feverDataSets: temp,
    });
    if (this.state.identifier === "vitals_charts") {
      this.props.collectRenderedVitalsCharts(
        this.props.noOfVitalsChartRendered + 1
      );
    }
    if (this.state.identifier === "symptoms_charts") {
      this.props.collectRenderedSymptomsCharts(
        this.props.noOfSymptomsChartRendered + 1
      );
    }
    return result;
  }
  componentDidMount() {
    this.getfeverDataSets();
    // window.resizeTo("59.9vw", "100vh");
  }
  componentWillUnmount() {
    this.setState({
      ...this.state,
      feverDataSets: [],
    });
  }
  customizeText(e) {
    return ` ${e.value}
    `;
  }
  customizeValueText(e) {
    return ` ${e.value} `;
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="lineChart__loadingData">
          <LoaderThreeDot />
        </div>
      );
    }
    // console.log("this.customizeValueText", this.customizePoint());
    // this.state.feverAttributesKeys &&
    return (
      <React.Fragment>
        <div className="lineChart__container">
          {this.state.feverDataSets &&
          this.state.feverAttributesKeys &&
          this.state.feverDataSets.length > 0 ? (
            <Chart
              className="lineChart__chart"
              // palette="blue"
              dataSource={this.state.feverDataSets}
              customizePoint={this.customizePoint}
              customizeLabel={this.customizeLabel}
              customizeTooltip={this.customizeTooltip}
              // width="100%"
            >
              {/* <Chart palette="blue" dataSource={feverDataset}> */}
              <Label visible={true} text="day">
                <Connector visible={true} />
              </Label>
              <Size
                height={this.state.height ? this.state.height : 210}
                width={`${this.state.width}`}
              />

              <CommonSeriesSettings
                argumentField="id"
                type="spline"
                visible={true}
              />
              {this.state.feverAttributesKeys.map(function (item) {
                return (
                  <Series
                    key={item.value}
                    valueField={item.value}
                    name={item.name}
                    visible={true}
                  />
                );
              })}
              <Legend visible={false} />
              <ArgumentAxis
                type="discrete"
                argumentType="string"
                strokeWidth="1px"
                // axisDivisionFactor={20}
              >
                <Grid visible={false}></Grid>
                <Title text={this.props.name.toString().toUpperCase()}>
                  <Font color="whitesmoke" size={this.state.height && "10"} />
                </Title>
                <Label customizeText={this.customizeText} />
              </ArgumentAxis>
              <ValueAxis>
                <Grid visible={false}></Grid>
                <Title text={this.props.unitOfMeasurement}>
                  <Font
                    color="rgb(251,230,244)"
                    size={this.state.height && "12"}
                  />
                </Title>
                {this.props.name == "fever" && (
                  <ConstantLine
                    width={2}
                    value="98.6"
                    color="green"
                    dashStyle="dash"
                  >
                    <Label text="" />
                  </ConstantLine>
                )}
                {this.props.name == "oxygen saturation" && (
                  <ConstantLine
                    width={2}
                    value="97"
                    color="green"
                    dashStyle="dash"
                  >
                    <Label text="" />
                  </ConstantLine>
                )}
                <Label customizeText={this.customizeValueText} />
              </ValueAxis>
              <Tooltip
                enabled={true}
                customizeTooltip={this.customizeTooltip}
              />
            </Chart>
          ) : (
            <div className="lineChart__noChartData">
              <NoChartData
                message="No Data Found for the symptom/vital"
                imgsrc={noChartDataImage}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
  customizePoint(arg) {
    if (arg.value) {
      return {
        color: "whitesmoke",
        size: this.state.height && "10",
        hoverStyle: { color: "#ff7c7c" },
      };
    } else if (arg.value < this.state.lowAverage) {
      return {
        color: "#8c8cff",
        size: this.state.height && "10",
        hoverStyle: { color: "#8c8cff" },
      };
    }
  }

  customizeLabel(arg) {
    // if (arg.value && +arg.argument % 2 == 1)
    if (arg.value) {
      return {
        visible: true,
        backgroundColor: "transparent",
        // size: this.state.height && "5",
        customizeText: function (e) {
          let valueText = `${e.valueText} \n ${arg.data.day}`;
          if (e.seriesName == "fever") {
            valueText = `${e.valueText}&#176F \n ${arg.data.day}`;
          }
          if (e.seriesName == "temperature") {
            valueText = `${e.valueText}&#176F \n ${arg.data.day}`;
          }
          return valueText;
        },
      };
    }
  }
  customizeTooltip(pointInfo) {
    let valueForLabel = pointInfo.value;
    console.log("POINT INFO: ", pointInfo);
    if (pointInfo.seriesName == "fever") {
      valueForLabel = `${valueForLabel} F `;
    }
    return {
      text: ` ${pointInfo.point.data.time}<br/>${pointInfo.point.data.date}${
        pointInfo.point.data.site && "<br/><b>site: </b> "
      } ${pointInfo.point.data.site} ${
        pointInfo.point.data.onset && "<br/><b>onset: </b>"
      } ${pointInfo.point.data.onset}${
        pointInfo.point.data.character && "<br/><b>character: </b>"
      } ${pointInfo.point.data.character}`,
    };
  }
  handleChange(e) {
    this.setState({ type: e.value });
  }
}

const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    accessToken: state.auth.accessToken,
    noOfVitalsChartRendered: state.monitoringStatus.noOfVitalsChart,
    noOfSymptomsChartRendered: state.monitoringStatus.noOfSymptomsChart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    collectRenderedVitalsCharts: (count) =>
      dispatch(actions.getVitalsChartRendered(count)),
    collectRenderedSymptomsCharts: (count) =>
      dispatch(actions.getSymptomsChartRendered(count)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LineChart);
