import React, { useState } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  RequiredRule,
  FormItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { measureTypes } from "../../data";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import {
  getAllLanguagesEndPoint,
  getAllTimeScheduleSequencesEndPoint,
  getAllTaskCategoriesEndPoint,
} from "../../../HighOrderFunctions/APIs/allGetAPI";
import { DisplayFormat } from "devextreme-react/date-box";
import {useDispatch} from 'react-redux';
import { showNotificationWithMessage } from "../../../Store/actions/DashboardAction";

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}


const SNSEntity = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch  = useDispatch();
  let { response: languageList, isLoading } = useFetch(
    `${getAllLanguagesEndPoint}`,
    false
  );
  let { response: allTimeScheduleSequencesList,  } = useFetch(
    `${getAllTimeScheduleSequencesEndPoint}`,
    false
  );
  let { response: allTaskCategoriesList,  } = useFetch(
    `${getAllTaskCategoriesEndPoint}`,
    false
  );

  function sendRequest(url, method, data) {
    method = method || "GET";
    data = data || {};

    if (method === "GET") {
      return fetch(url, {
        method: method,
        credentials: "include",
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) {
            console.log(result);
            return json.data;
          }
          throw json.Message;
        })
      );
    }

  

    return fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          let temp = JSON.parse(text);
          if(temp.error){
            dispatch(
              showNotificationWithMessage({
                variant: "warning",
                message: temp.error,
                time:10000
              })
            );
          }
          return text && JSON.parse(text);
        });
      } else {
        return result.json().then((json) => {
          console.log("RESULT1: ", json);

          throw json.Message;
        });
      }
    });
  }

  const store = new CustomStore({
    key: "task_conversation_template_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/task-conversation-template?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Task conversation Template", data);
          return {
            data: data.data,
            totalCount: data.totalCount,
          };
        })
        .catch(() => {
          throw "Data Loading Error";
        });
    },
    insert: (values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/task-conversation-template`,
        "POST",
        values
      ),
    update: (key, values) => {
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/task-conversation-template/${key}`,
        "PUT",
        values
      );
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/task-conversation-template/${key}`,
        "DELETE"
      ),
  });

  const onOptionChanged = (e) => {
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  return (
    <>
      <DataGrid
        // width={"50%"}
        dataSource={store}
        showBorders={true}
        remoteOperations={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={false}
        onOptionChanged={onOptionChanged}
      >
        <SearchPanel visible={true} text={searchText} />
        <Editing
          refreshMode="reshape"
          mode="popup"
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Column
          width={35}
          dataField="task_conversation_template_id"
          dataType="number"
          caption="id"
          allowEditing={false}
        >
          <FormItem colSpan={1}></FormItem>
        </Column>
        <Column
          dataField="before"
          caption="header before food"
          dataType="string"
        ></Column>
        <Column
          dataField="after"
          caption="header after food"
          dataType="string"
        ></Column>
        <Column dataField="bot_utterance" dataType="string"></Column>
        <Column dataField="time_schedule_sequence"
         dataType="string"
        >
        <Lookup
            dataSource={allTimeScheduleSequencesList}
            displayExpr="name"
            valueExpr="name"
          />

        </Column>
        <Column
          dataField="task_conversation_template_name"
          dataType="string"
        >
        <RequiredRule />

        </Column>
        <Column
          dataField="ref_language_id"
          caption="Language"
          dataType="string"
        >
          <Lookup
            dataSource={languageList}
            displayExpr="language_name"
            valueExpr="id"
          />
          <RequiredRule />
        </Column>
        <Column dataField="time_schedule_delta" dataType="string"></Column>
        <Column dataField="task_type" dataType="string">
        <Lookup
            dataSource={allTaskCategoriesList}
            displayExpr="name"
            valueExpr="name"
          />
          <RequiredRule />
        </Column>
      

        <Paging defaultPageSize={15} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[12, 15, 20]} />
      </DataGrid>
    </>
  );
};
// calculateCellValue={calculateCellValueFunc}
export default SNSEntity;
