import React, { useState } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { measureTypes } from "../../data";
import { useDispatch } from "react-redux";
import { showNotificationWithMessage } from "../../../Store/actions/DashboardAction";


// This component will apply CRUD in t_ref_clinical_entity 
const ClinicalEntity = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  function sendRequest(url, method, data) {
    method = method || "GET";
    data = data || {};

    if (method === "GET") {
      return fetch(url, {
        method: method,
        credentials: "include",
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) {
            console.log(result);
            return json.data;
          }
          throw json.Message;
        })
      );
    }

    // const params = Object.keys(data).map((key) => {
    //   return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
    // }).join('&');

    return fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          let temp = JSON.parse(text);
          if(temp.error){
            dispatch(
              showNotificationWithMessage({
                variant: "warning",
                message: temp.error,
              })
            );
          }
          return text && JSON.parse(text);
        });
      } else {
        return result.json().then((json) => {
          console.log("RESULT1: ", json);

          throw json.Message;
        });
      }
    });
  }
  // custom Store
  const store = new CustomStore({
    key: "t_ref_clinical_entity_id",
    load: function (loadOptions) {
      if(loadOptions.take !==undefined){

        console.log("SearchText", searchText);
        return fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
          )
          .then((response) => response.json())
          .then((data) => {
            return {
              data: data.data,
              totalCount: data.totalCount,
              // totalCount: 56,
              // summary: data.summary,
              // groupCount: data.groupCount,
              // {data:[{},{}],
              // totalCount:10}
            };
          })
          .catch(() => {
            throw "Data Loading Error";
          });
        }
    },
    insert: (values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity`,
        "POST",
        values
      ),
    update: (key, values) => {
      console.log("sns-entity-update", key, values);
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity/${key}`,
        "PUT",
        values
      );
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity/${key}`,
        "DELETE"
      ),
  });
  const calculateCellValueFunc = (data) => {
    if (data.measure_type == "NUMBER_SCALE") {
      return "NUMBER_SCALE";
    } else if (data.measure_type == "BINARY") {
      return "BINARY";
    } else if (data.measure_type == "THREE_POINT_SCALE") {
      return "THREE_POINT_SCALE";
    }
  };
  const onOptionChanged = (e) => {
    console.log("EVent on option changed: ", e);
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };

  return (
    <DataGrid
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnResizing={true}
      // allowColumnReordering={false}
      sorting={false}
      onOptionChanged={onOptionChanged}
    >
      <SearchPanel visible={true} text={searchText} />
      <Editing
        refreshMode="reshape"
        mode="popup"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />
      <Column
        width={40}
        dataField="t_ref_clinical_entity_id"
        dataType="number"
        caption="id"
        allowEditing={false}
      ></Column>

      <Column dataField="entity_name" dataType="string"></Column>
      <Column dataField="measure_type" dataType="string">
      <Lookup
          dataSource={measureTypes}
          displayExpr="name"
          valueExpr="name"
        />
      </Column>
      <Paging defaultPageSize={20} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[12, 15, 20]} />
    </DataGrid>
  );
};
export default ClinicalEntity;
