import React from 'react'
import './SymptomComplianceCharts.css';
import SignsSymptomsCharts from './SignsSymptomsCharts/SignsSymptomsCharts';
import ComplianceCharts from './ComplianceCharts/ComplianceCharts';

const SymptomComplianceCharts = () => {
    return (
        <div className="SymptomComplianceCharts__container">
            <div className="SymptomComplianceCharts__signsSymptoms">
                <SignsSymptomsCharts />
            </div>
            <div className="SymptomComplianceCharts__compliance">
                <ComplianceCharts />
            </div>
        </div>
    )
}

export default SymptomComplianceCharts
