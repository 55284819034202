import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ToastNotificationManualClose(props) {
  
  const [status, setStatus] = useState([
    "info",
    "success",
    "error",
    "warning",
    "default",
  ]);
  const notify = () => {
    let variant = props.messageType
      ? props.messageType
      : "success";
    let message=props?.message ? `${props.message}`:"";
    if (status.indexOf(variant) > -1) {
      toast[`${variant}`](message, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

     
    }
  };
  useEffect(() => {
    notify();
  }, []);
  return (
    
      <ToastContainer
       position="top-right"
       autoClose={false}
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable={false}
      />
    
  );
}
