import React from "react";
import "./PhoneNumberInput.css";
import PhoneInput, { isValidPhoneNumber,formatPhoneNumber,parsePhoneNumber } from "react-phone-number-input";
// import 'react-phone-number-input/style.css';
import './react-phone-number-input-css.css';

const PhoneNumberInput = (props) => {
    const updatePhoneNumber = (value) =>{
       

        if(value !==undefined && value ){
            value && props.setValue(parsePhoneNumber(value));

        }
        
    }

  return (
    <div 
    className={`phoneNumberInput__container ${
        props.touchedNotValid ? "inputTouchedNotValid" : ""
      } ${props.className} `}
    >
      <PhoneInput
        className="phoneNumberInput__input"
        international
        countryCallingCodeEditable={false}
        defaultCountry="IN"
        value={props.value}
        onChange={(value)=>updatePhoneNumber(value)}
        // error={props.value ? (isValidPhoneNumber(props.value) ? undefined : 'Invalid phone number') : 'Phone number required'}
        />
        
      
    </div>
  );
};

export default PhoneNumberInput;
