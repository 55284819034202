import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../Components/screensComponent/Dashboard/DashboardHeader/DashboardHeader";
import DashboardNameHeader from "../../../Components/screensComponent/Dashboard/DashboardNameHeader/DashboardNameHeader";
import DPANameBody from "../../../Components/screensComponent/Dashboard/DischargePatientAlert/DPANameBody/DPANameBody";
import * as actions from "../../../Store/actions/index";
import { connect } from "react-redux";
import "./DischargePatientAlert.css";
import {getDischargePatientAlertsEndPoint} from '../../../HighOrderFunctions/APIs/allGetAPI';
import {useFetch} from '../../../HighOrderFunctions/FetchMethod/getFetchFunction';
import Loader from '../../../Loader/LoaderThreeDot';

const DischargePatientAlert = (props) => {

  // function to fetch list of those patients whose alerts has raised 
  const {response,isLoading} = useFetch(getDischargePatientAlertsEndPoint,props.dischargePatientAlert);
  useEffect(() => {
    if (props.dischargePatientAlert === true) {
      props.fetchDischargePatientAlert(false);
    }
    return ()=>{
      
    }
  }, [props.dischargePatientAlert]);

  let renderedData = null;
  renderedData = Array.isArray(response) && response.map((item, index) => {
    return (
      <DPANameBody
        key={index}
        name={item["name"]}
        dischargeDate={item["discharge_date"]}
        dischargeDiagnosis={item["context"]}
        qrCode={item["qr_code"]}
        rowNumber={index}
        symptom={item["symptom"]}
        age={item["age"]}
        gender={item["gender"]}
        hospitalId={item["hospital_id"]}
        relation={item["relation"]}
        threshold={item["threshold"]}
        timeSinceAlert={item["time_since_alert"]}
      />
    );
  });

  return (
    <div className="dischargePatientAlert__container">
      <div className="dischargePatientAlert__header">
        <DashboardHeader
          headerName="Patient alerts"
          allPatientInfo={`${response.length} patients require your attention`}
        color="rgba(238, 59, 59, 0.68)"
        />
      </div>
      <div className="dischargePatientAlert__nameHeader">
        <DashboardNameHeader status="Alert" />
      </div>
      <div className="dischargePatientAlert__nameBody">
        {isLoading ? <div className="loaderbar"> <Loader /> </div> : <div>{renderedData}</div>}
       
        
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    dischargePatientAlert: state.dashboard.dischargePatientAlert,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
   
      fetchDischargePatientAlert: (status) =>
      dispatch(actions.fetchDischargePatientAlert(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DischargePatientAlert);
