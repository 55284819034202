import React, { useState } from "react";
import "./ComplianceCharts.css";
import ComplianceIndexList from "../../../../../Components/screensComponent/MonitoringStatus/ComplianceIndexList/ComplianceIndexList";
import MedicationChart from "./MedicationChart/MedicationChart";


// this component will work same as SignsNSymptoms for showing charts for (Medication,exercise etc.)
// Note:- try to make it like SignsNSymptoms for dynamic addition of multiple charts
const ComplianceCharts = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="complianceCharts__container">
      <div className="complianceCharts__header">
        <div className="complianceCharts__headerText">Compliance</div>
      </div>
      <div className="complianceCharts__body">
        <div
          className={
            toggleState === 1
              ? "complianceChart complianceChartActive"
              : "complianceChart"
          }
        >
          <MedicationChart />
        </div>
      </div>
      <div className="complianceCharts__indexing">
        <ComplianceIndexList
          medication="Medication"
          diet="Diet"
          exercise="Exercise"
          aiCalls="AI Calls"
          toggleTab={toggleTab}
          toggleState={toggleState}
        />
      </div>
    </div>
  );
};

export default ComplianceCharts;
