import React from 'react';
import './ChartIndexButton.css';
const ChartIndexButton = (props) => {
    return (
        <div className={`chartIndexButton__container ${props.active ? "activeButton":""}`}
        onClick={props.clicked}>
            <div className="chartIndexButton__text">
                {props.name}
            </div>
        </div>
    )                                           
}

export default ChartIndexButton

