import React, { Fragment, useState, useEffect } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import TimePicker from "react-time-picker";
import RenderTimePicker from "./RenderTimePicker";
import "./CustomTimePicker.css";
const CustomTimePicker = ({
  time,
  selectedAmPm,
  onEditModeHandler,
  index,
  timeList,
  onTimeListHandler,
  selectedtimeSlotHandler,
}) => {
  const [isEditingMode, setIsEditingMode] = useState(false);

  const TimePickerRender = ({
    time,
    selectedAmPm,
    onEditmodeHandler,
    index,
    timeList,
    onTimeListHandler,
  }) => {
    return (
      <RenderTimePicker
        time={time}
        selectedAmPm={selectedAmPm}
        onEditmodeHandler={onEditmodeHandler}
        index={index}
        timeList={timeList}
        onTimeListHandler={onTimeListHandler}
        selectedtimeSlotHandler={selectedtimeSlotHandler}
      />
    );
  };

  return (
    <Fragment>
      {isEditingMode && (
        <RenderTimePicker
          time={time}
          selectedAmPm={selectedAmPm}
          onEditmodeHandler={(val) => setIsEditingMode(val)}
          index={index}
          timeList={timeList}
          onTimeListHandler={onTimeListHandler}
          selectedtimeSlotHandler={selectedtimeSlotHandler}
        />
      )}

      {!isEditingMode && (
        <button
          className="inlineEditable__button"
          onClick={() => setIsEditingMode(true)}
          style={{ width: "72px", color: "black" }}
        >
          {`${time} ${selectedAmPm}`}
        </button>
      )}
    </Fragment>
  );
};

export default CustomTimePicker;
