import React, { useEffect, useState } from "react";
import "./CareGiversList.css";
import { capitalizeFirstLetter } from "../../../../../HighOrderFunctions/shared/capitalizeFirstLetter";
import {
    updateObject,
    checkValidity,
} from "../../../../../HighOrderFunctions/shared/utility";
import InputCard from "../../../../Card/InputCard/InputCard";
import { Radio } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { connect, useDispatch, useSelector } from "react-redux";
import { SelectBox, TextBox } from "devextreme-react";

const CareGiversList = (props) => {
    const [formIsValidList, setFormIsValidList] = useState([false, false, false]);
    const [error, setError] = useState("");
    const token = useSelector((state) => state.auth.accessToken);
    const [provisionedCareGivers, setProvisionedCareGivers] = useState(null)
    const [caregiverOneOtherSelected, setCaregiverOneOtherSelected] = useState(false);
    const [caregiverTwoOtherSelected, setCaregiverTwoOtherSelected] = useState(false);
    const [caregiverThreeOtherSelected, setCaregiverThreeOtherSelected] = useState(false);

    //initial state to store fullName and contact number of nurse , who so ever is being registered.!!
    const [careGiversListInfo, setCareGiversListInfo] = useState([
        {
            fullName: {
                value: "",
                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    minLength: 3,
                },
            },
            contactNumber: {
                value: "",
                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    isNumeric: true,
                    maxLength: props.countryCode == "+91" ? 10 : 9,
                    minLength: props.countryCode == "+91" ? 10 : 9,
                },
            },
        },
        {
            fullName: {
                value: "",
                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    minLength: 3,
                },
            },
            contactNumber: {
                value: "",
                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    isNumeric: true,
                    maxLength: props.countryCode == "+91" ? 10 : 9,
                    minLength: props.countryCode == "+91" ? 10 : 9,
                },
            },
        },
        {
            fullName: {
                value: "",
                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    minLength: 3,
                },
            },
            contactNumber: {
                value: "",
                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    isNumeric: true,
                    maxLength: props.countryCode == "+91" ? 10 : 9,
                    minLength: props.countryCode == "+91" ? 10 : 9,
                },
            },
        },
    ]);

    useEffect(() => {
        props.updateCareGivers(careGiversListInfo);
        props.updateIsCareGiversDetailsValid(formIsValidList);

        let requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        fetch(`${window["getConfig"].BACKEND_SERVICE_URL}/care-giver/all`, requestOptions).then((response) => response.json()).then((result) => {
            console.log(result);
            result.push({ name: "Other", contact_number: "" })
            setProvisionedCareGivers(result)
        })

    }, [])

    const careGiverSelected = (selectedItem, index) => {
        if (selectedItem.name !== "Other") {
            inputChangedHandler(selectedItem.name, "fullName", index);
            inputChangedHandler(selectedItem.contact_number, "contactNumber", index);
            careGiversListInfo[index]["fullName"].value = ""
            careGiversListInfo[index]["contactNumber"].value = ""
            if (index === 0) {
                setCaregiverOneOtherSelected(false);
            }
            if (index === 1) {
                setCaregiverTwoOtherSelected(false);
            }
            if (index === 2) {
                setCaregiverThreeOtherSelected(false);
            }
            
        } else {
            if (index === 0) {
                setCaregiverOneOtherSelected(true);
            }
            if (index === 1) {
                setCaregiverTwoOtherSelected(true);
            }
            if (index === 2) {
                setCaregiverThreeOtherSelected(true);
            }
            
        }
    }

    // function to update fullName and contact Number of Nurse
    const inputChangedHandler = (value, inputIdentifier, index) => {
        let valuePassed = value;
        let valueForValidityCheck = value;
        if (inputIdentifier == "fullName") {
            valuePassed = capitalizeFirstLetter(valuePassed);
        }

        if (inputIdentifier == "contactNumber") {
            if (Number.isInteger(+value) && value.length <= 10) {
                valuePassed = value;
            } else {
                valueForValidityCheck =
                    careGiversListInfo[index]["contactNumber"].value;
                valuePassed = careGiversListInfo[index]["contactNumber"].value;
            }
        }


        const updatedFormElement = updateObject(
            careGiversListInfo[index][inputIdentifier],
            {
                value: valuePassed,
                clicked: true,
                valid: checkValidity(
                    valueForValidityCheck,
                    careGiversListInfo[index][inputIdentifier].validations
                ),
            }
        );
        const updatedCareGiverForm = updateObject(careGiversListInfo[index], {
            [inputIdentifier]: updatedFormElement,
        });

        let tempFormIsValid = true;
        for (let inputIdentifier in updatedCareGiverForm) {
            tempFormIsValid =
                updatedCareGiverForm[inputIdentifier].valid && tempFormIsValid;
        }
        let tempCareGiversListInfo = careGiversListInfo;
        tempCareGiversListInfo[index] = updatedCareGiverForm;
        setCareGiversListInfo((prev) => [...tempCareGiversListInfo]);
        let tempFormIsValidList = formIsValidList;
        tempFormIsValidList[index] = tempFormIsValid;
        setFormIsValidList((prev) => [...tempFormIsValidList]);

        props.updateCareGivers(tempCareGiversListInfo);
        props.updateIsCareGiversDetailsValid(tempFormIsValidList);
    };
    const renderField = (data) => {
        console.log(data);
        return (
            <div>
                <TextBox style={{ display: 'inline-block' }}
                    readOnly={true}
                    defaultValue={data && data.name + " [" + data.contact_number + "]"} />
            </div>
        );
    }

    return (
        <div className="careGiversList__container">
            On duty care giver:
            <div className="careGiverInfo__container" >
                <div className="careGiverInfo__details">
                    <div className="careGiverInfo__name">
                        <SelectBox displayExpr={"name"} dataSource={provisionedCareGivers}
                            onSelectionChanged={(e) => { careGiverSelected(e.selectedItem, 0) }}
                            fieldRender={renderField}
                            itemRender={(item) => (
                                <div>
                                    {item["name"]} [{item["contact_number"]}]
                                </div>
                            )}
                        ></SelectBox>
                    </div>
                    {caregiverOneOtherSelected && <div className="careGiverInfo__name">
                        <InputCard
                            className="careGiverInfo__inputTag"
                            placeHolder="Name of Nurse "
                            width="100%"
                            bgcolor="#FFFFFF"
                            value={careGiversListInfo[0]["fullName"].value}
                            onChange={(value) => inputChangedHandler(value, "fullName", 0)}
                            touchedNotValid={
                                !careGiversListInfo[0]["fullName"]["valid"] &&
                                careGiversListInfo[0]["fullName"]["clicked"]
                            }
                        />
                    </div>}
                    {caregiverOneOtherSelected && <div className="careGiverInfo__contactNumber">
                        <InputCard
                            className="careGiverInfo__inputTag"
                            placeHolder="Contact Number"
                            width="100%"
                            bgcolor="#FFFFFF"
                            value={careGiversListInfo[0]["contactNumber"].value}
                            onChange={(value) => inputChangedHandler(value, "contactNumber", 0)}
                            touchedNotValid={
                                !careGiversListInfo[0]["contactNumber"]["valid"] &&
                                careGiversListInfo[0]["contactNumber"]["clicked"]
                            }
                        />
                    </div>}
                </div>
                <div className="careGiver__border"></div>
            </div>
            Other care givers:
            <div className="careGiverInfo__container" >
                <div className="careGiverInfo__details">
                    <div className="careGiverInfo__name">
                        <SelectBox displayExpr={"name"} dataSource={provisionedCareGivers}
                            onSelectionChanged={(e) => { careGiverSelected(e.selectedItem, 1) }}

                            fieldRender={renderField}
                            itemRender={(item) => (
                                <div>
                                    {item["name"]} [{item["contact_number"]}]
                                </div>
                            )}
                        ></SelectBox>

                    </div>
                    {caregiverTwoOtherSelected && <div className="careGiverInfo__name"><InputCard
                        className="careGiverInfo__inputTag"
                        placeHolder="Name of Nurse "
                        width="100%"
                        bgcolor="#FFFFFF"
                        value={careGiversListInfo[1]["fullName"].value}
                        onChange={(value) => inputChangedHandler(value, "fullName", 1)}
                        touchedNotValid={
                            !careGiversListInfo[1]["fullName"]["valid"] &&
                            careGiversListInfo[1]["fullName"]["clicked"]
                        }
                    // disabled={!formIsValidList[0]}
                    /></div>}
                    {caregiverTwoOtherSelected && <div className="careGiverInfo__contactNumber">
                        <InputCard
                            className="careGiverInfo__inputTag"
                            placeHolder="Contact Number"
                            width="100%"
                            bgcolor="#FFFFFF"
                            value={careGiversListInfo[1]["contactNumber"].value}
                            onChange={(value) => inputChangedHandler(value, "contactNumber", 1)}
                            touchedNotValid={
                                !careGiversListInfo[1]["contactNumber"]["valid"] &&
                                careGiversListInfo[1]["contactNumber"]["clicked"]
                            }
                        // disabled={!formIsValidList[0]}
                        />
                    </div>}

                </div>
                <div className="careGiver__border"></div>
            </div>
            <div className="careGiverInfo__container" >
                <div className="careGiverInfo__details">
                    <div className="careGiverInfo__name">
                        <SelectBox displayExpr={"name"} dataSource={provisionedCareGivers}
                            onSelectionChanged={(e) => { careGiverSelected(e.selectedItem, 2) }}

                            fieldRender={renderField}
                            itemRender={(item) => (
                                <div>
                                    {item["name"]} [{item["contact_number"]}]
                                </div>
                            )}
                        ></SelectBox>


                    </div>
                    {caregiverThreeOtherSelected && <div className="careGiverInfo__name"><InputCard
                        className="careGiverInfo__inputTag"
                        placeHolder="Name of Nurse "
                        width="100%"
                        bgcolor="#FFFFFF"
                        value={careGiversListInfo[2]["fullName"].value}
                        onChange={(value) => inputChangedHandler(value, "fullName", 2)}
                        touchedNotValid={
                            !careGiversListInfo[2]["fullName"]["valid"] &&
                            careGiversListInfo[2]["fullName"]["clicked"]
                        }
                        disabled={!formIsValidList[1]}
                    /></div>}
                    {caregiverThreeOtherSelected && <div className="careGiverInfo__contactNumber">
                        <InputCard
                            className="careGiverInfo__inputTag"
                            placeHolder="Contact Number"
                            width="100%"
                            bgcolor="#FFFFFF"
                            value={careGiversListInfo[2]["contactNumber"].value}
                            onChange={(value) => inputChangedHandler(value, "contactNumber", 2)}
                            touchedNotValid={
                                !careGiversListInfo[2]["contactNumber"]["valid"] &&
                                careGiversListInfo[2]["contactNumber"]["clicked"]
                            }
                            disabled={!formIsValidList[1]}
                        />
                    </div>}

                </div>
                <div className="careGiver__border"></div>
            </div>
        </div>
    );
};

export default CareGiversList;
