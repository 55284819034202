import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import RenderToIndex from "./RenderToIndex";
import { Auth0Provider } from "@auth0/auth0-react";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Store/Store";

ReactDOM.render(
  <Auth0Provider
    domain={window["getConfig"].AUTH0_DOMAIN}
    clientId={window["getConfig"].AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    audience={window["getConfig"].AUTH0_AUDIENCE}
    responseType="id_token token"
    scope="read:current_user update:current_user_metadata "
  >
    <HashRouter>
      <Provider store={store}>
        <RenderToIndex />
      </Provider>
    </HashRouter>
  </Auth0Provider>,
  document.getElementById("root")
);
