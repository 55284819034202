import React from "react";
import "./ComplianceCapsule.css";
const ComplianceCapsule = (props) => {
  return (
    <div
      className={`complianceCapsule__container ${
        props.doseStatus == "SUCCESS" ? "complianceCapsule__active" : ""
      } ${
        props.doseStatus == "notApplicable"
          ? "complianceCapsule__notApplicable"
          : ""
      } ${props.doseStatus == "PENDING" ? "complianceCapsule__pending" : ""}`}
      style={{
        width: props.capsuleWidth && props.capsuleWidth,
        height: props.capsuleHeight && props.capsuleHeight,
      }}
    ></div>
  );
};

export default ComplianceCapsule;
