export function capitalizeFirstLetter(string) {
  // string = string.toLowerCase();
  if (string.length > 1) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else if (string.length == 1) {
    return string.charAt(0).toUpperCase();
  } else {
    return string;
  }
}
