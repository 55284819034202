import React from "react";
import "./PUMNameBody.css";
import { useHistory } from "react-router-dom";
import { stringToDateFunc } from "../../../../../HighOrderFunctions/stringToDateFunc";
import { connect } from "react-redux";
import CircularIcon from "../../../../CircularIcon/CircularIcon";
import whatsappIcon from "../../../../../AllIcons/whatsappIcon.png";
import * as actions from "../../../../../Store/actions/index";
import { returnYearOnly ,ageModifier} from "../../../../../HighOrderFunctions/ageModifier";
import ButtonWBorder from "../../../../Buttons/ButtonWBorder/ButtonWBorder";
import DashboardButton from "../../../../Buttons/DashboardButton/DashboardButton";

const PUMNameBody = (props) => {
  const history = useHistory();
  const nameClicked = () => {
    props.showNavigationFuncToStore(true);
    props.setQRCodeToStoreFunc(props.qrCode);
    props.setCardNumberToStoreFunc(1);
    props.approveButtonStatusFuncToStore(false);
    history.push("/monitoringStatus/" + props.qrCode);
  };
  let ageToBeShown = props.age && ageModifier(props.age);

  let status =
    props.status &&
    props.status.split("").map((item) => {
      return <div>{item}</div>;
    });

  return (
    <div className={`pumNameBody__container  `}>
      <div className="pumNameBody__patientName" onClick={nameClicked}>
        {props.name},{ageToBeShown} ,
        {props.gender ? ` ${props.gender.charAt(0)}` : "M"}
        <div className="pumNameBody__patientQRCode">
          {props.hospitalId}
        </div>
      </div>
      <div className="pumNameBody__discharge">
        <div className="pumNameBody__dischargeDateDiagnosis">
          {props.dischargeDiagnosis}
        </div>
        <div className="pumNameBody__dischargeDate">
          {stringToDateFunc(props.dischargeDate)}
        </div>
      </div>
      <div className="pumNameBody__status">
        <DashboardButton
          bgcolor="rgba(184, 217, 255, 0.68)"
          boxShadow="2px 2px 8px rgba(57, 129, 233, 0.24)"
            //  name={props.status}
          name={`${props.summaryStatus}`}
        />
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
    setCardNumberToStoreFunc: (number) =>
      dispatch(actions.setCurrentCardNumberFunc(number)),
    approveButtonStatusFuncToStore: (status) =>
      dispatch(actions.approveButtonStatusFunc(status)),
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(null, mapDispatchToProps)(PUMNameBody);
