import React from "react";
import "./ErrorPage.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const ErrorPage = (props) => {
  const history = useHistory();
  const navigateToDashboardPage = () => {
    history.push("/");
  };
  return (
    <div
      style={{
        backgroundColor: "#2F3242",
        marginTop: "0px",
        width: "100%",
        height: "100%",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <img src ={vEngageLogoAnimated} /> */}
      <div>
        <svg
          width="500px"
          height="600px"
          id="Polygon-2"
          viewBox="0 0 451 415"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M126.366 356.219L38.4969 305.358C29.0078 299.854 30.3363 290.365 41.1538 284.103L88.5992 256.774C99.4167 250.511 116.117 249.752 125.607 255.256L213.475 306.117C222.964 311.621 221.636 321.11 210.818 327.373L163.183 354.701C152.366 360.964 135.665 361.723 126.366 356.219Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M91.6359 148.789C91.2563 141.767 80.4388 136.263 67.1541 136.263C53.8694 136.263 43.0519 141.956 42.6724 148.789C42.6724 148.978 42.6724 148.978 42.6724 149.168C42.6724 156.38 53.6797 162.263 67.1541 162.263C80.6286 162.263 91.6359 156.38 91.6359 149.168C91.6359 149.168 91.6359 148.978 91.6359 148.789Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M127.314 395.125C126.935 388.672 117.066 383.548 104.731 383.548C92.3947 383.548 82.526 388.672 82.1465 395.125C82.1465 395.314 82.1465 395.314 82.1465 395.504C82.1465 402.147 92.2049 407.46 104.731 407.46C117.256 407.46 127.314 402.147 127.314 395.504C127.314 395.314 127.314 395.125 127.314 395.125Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M367.768 311.621C367.388 305.168 357.519 300.044 345.184 300.044C332.848 300.044 322.979 305.168 322.6 311.621C322.6 311.811 322.6 311.811 322.6 312C322.6 318.643 332.658 323.957 345.184 323.957C357.709 323.957 367.768 318.643 367.768 312C367.957 311.811 367.768 311.811 367.768 311.621Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M162.993 185.037C162.424 176.307 149.139 169.285 132.439 169.285C115.928 169.285 102.453 176.307 101.884 185.037C101.884 185.227 101.884 185.416 101.884 185.606C101.884 194.526 115.548 201.927 132.439 201.927C149.329 201.927 162.993 194.716 162.993 185.606C162.993 185.416 162.993 185.227 162.993 185.037Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M432.103 328.511C431.724 320.541 419.198 314.088 404.016 314.088C388.833 314.088 376.308 320.541 375.928 328.511C375.928 328.701 375.928 328.891 375.928 328.891C375.928 337.241 388.454 343.884 404.016 343.884C419.578 343.884 432.103 337.241 432.103 328.891C432.293 328.891 432.293 328.701 432.103 328.511Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M71.329 382.219C71.1393 378.044 64.4969 374.628 56.5261 374.628C48.5552 374.628 41.9129 378.044 41.7231 382.219V382.409C41.7231 386.774 48.3655 390.19 56.5261 390.19C64.6867 390.19 71.329 386.584 71.329 382.409C71.329 382.409 71.329 382.409 71.329 382.219Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M35.081 377.095C34.8912 372.92 28.2489 369.504 20.2781 369.504C12.3073 369.504 5.66488 372.92 5.4751 377.095V377.285C5.4751 381.65 12.1175 385.066 20.2781 385.066C28.4387 385.066 35.081 381.46 35.081 377.285V377.095Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M79.8691 367.037C79.6793 362.862 73.0369 359.446 65.0661 359.446C57.0953 359.446 50.453 362.862 50.2632 367.037V367.227C50.2632 371.592 56.9055 375.198 65.0661 375.198C73.2267 375.198 79.8691 371.592 79.8691 367.227C79.8691 367.227 79.8691 367.227 79.8691 367.037Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M450.702 352.234C450.322 345.402 439.694 339.709 426.599 339.709C413.504 339.709 402.877 345.212 402.497 352.234C402.497 352.424 402.497 352.424 402.497 352.614C402.497 359.636 413.315 365.519 426.599 365.519C439.884 365.519 450.702 359.825 450.702 352.614C450.702 352.424 450.702 352.234 450.702 352.234Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M204.745 163.212L365.49 256.395L429.636 219.197L269.271 126.394L204.745 163.212Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M233.592 345.402L351.446 414.293L408.191 382.03L290.527 313.519L233.592 345.402Z"
            fill="#80C6FF"
          />
          <path
            opacity="0.5"
            d="M51.9714 199.65L23.694 183.329C22.1758 182.38 20.278 182 18.3802 181.81L41.913 168.146C45.3291 166.248 45.7086 163.212 42.6721 161.504C39.6356 159.796 34.5115 159.986 31.0955 162.073L2.81807 178.394C-0.597989 180.292 -0.977541 183.329 2.05896 185.037C4.14655 186.175 7.18302 186.555 10.0297 185.796C10.0297 187.124 10.9786 188.453 12.8765 189.592L41.1539 205.913C44.57 207.811 49.8838 208.19 52.7305 206.482C55.767 204.584 55.3875 201.548 51.9714 199.65Z"
            fill="#80C6FF"
          />
          <path
            d="M365.49 251.081L213.475 163.212V31.5037L365.49 119.372V251.081Z"
            fill="#69DBFF"
          />
          <path
            d="M358.658 239.694L218.979 159.037V40.6132L358.658 121.27V239.694ZM222.016 157.329L355.811 234.57V122.788L222.016 45.5475V157.329Z"
            fill="#416FE5"
          />
          <path
            d="M420.716 87.4892L365.49 119.372V251.081L420.716 219.197V87.4892Z"
            fill="#53A7F4"
          />
          <path
            d="M390.921 104.759V236.467L396.425 233.241V101.533L390.921 104.759Z"
            fill="#416FE5"
          />
          <path
            d="M213.475 31.5037L365.49 119.372L420.716 87.4892L268.891 0L213.475 31.5037Z"
            fill="#E6EEFF"
          />
          <path
            d="M239.665 16.7007L391.68 104.38L396.994 101.153L244.979 13.6643L239.665 16.7007Z"
            fill="#53A7F4"
          />
          <path
            d="M359.986 97.3578L389.402 80.4673L377.636 73.6351L348.22 90.7154L359.986 97.3578Z"
            fill="#685050"
          />
          <path
            d="M356.76 91.095L378.964 78.1899L376.307 76.6716L354.103 89.5768L356.76 91.095Z"
            fill="white"
          />
          <path
            d="M361.694 93.9417L383.898 81.0366L381.241 79.3286L359.037 92.4235L361.694 93.9417Z"
            fill="white"
          />
          <path
            d="M359.986 97.3578V101.153L389.402 84.2629V80.4673L359.986 97.3578Z"
            fill="#493231"
          />
          <path
            d="M348.22 90.7154V94.5111L359.986 101.153V97.3578L348.22 90.7154Z"
            fill="#594040"
          />
          <path
            d="M256.366 37.5767L285.972 20.4964L274.205 13.6643L244.789 30.7446L256.366 37.5767Z"
            fill="#685050"
          />
          <path
            d="M253.329 31.3139L275.534 18.4088L272.877 16.7007L250.672 29.6059L253.329 31.3139Z"
            fill="white"
          />
          <path
            d="M258.263 33.9709L280.468 21.0657L277.621 19.5475L255.417 32.4526L258.263 33.9709Z"
            fill="white"
          />
          <path
            d="M256.366 37.5767V41.3723L285.972 24.292V20.4964L256.366 37.5767Z"
            fill="#493231"
          />
          <path
            d="M244.789 30.7446V34.5402L256.366 41.3723V37.5767L244.789 30.7446Z"
            fill="#594040"
          />
          <path
            d="M255.417 100.394L253.898 98.6863L260.351 95.2702C260.351 95.2702 260.541 95.2702 260.541 95.0804L260.92 94.8907C273.066 89.1972 286.161 114.438 287.679 117.285L290.336 115.956C290.526 115.956 290.716 115.767 290.716 115.767H290.906C296.409 113.11 307.986 110.832 317.855 132.088C325.446 148.219 322.03 157.898 316.716 163.402C316.336 163.781 315.957 164.161 315.577 164.351C313.869 165.869 312.161 167.008 310.453 167.767L301.344 172.321L300.774 170.993C294.891 172.701 287.679 175.927 287.679 175.927C287.679 175.927 277.052 159.037 271.168 151.066C268.891 147.84 263.957 140.438 259.781 132.088C259.402 131.329 259.022 130.57 258.643 129.81C254.278 119.562 251.241 107.986 255.417 100.394Z"
            fill="#FFCAE5"
          />
          <path
            className="Heart-beat"
            d="M267.753 112.73L274.015 156L278.001 148.789L282.745 151.445L287.11 123.737L293.373 167.008L297.359 159.796L309.315 166.818C314.629 161.314 318.045 151.635 310.453 135.504C296.789 106.467 280.278 121.27 280.278 121.27C280.278 121.27 263.767 87.4892 250.103 100.774C242.512 108.175 245.738 121.46 251.242 133.416L263.198 140.248L267.753 112.73Z"
            fill="white"
          />
          <path
            className="Heart-beat"
            d="M292.993 171.562L287.3 132.278L283.884 153.913L278.949 151.066L273.636 160.365L267.942 121.08L264.526 142.716L252.57 135.883C256.745 144.234 261.49 151.635 263.957 154.862C269.84 162.832 280.468 179.723 280.468 179.723C280.468 179.723 291.095 175.168 296.979 173.84C299.256 173.27 304.19 171.752 308.366 167.956L298.117 162.073L292.993 171.562Z"
            fill="white"
          />
          <path
            d="M297.359 160.175L293.373 167.387L287.111 124.117L282.746 151.635L278.001 148.978L274.016 156.19L267.753 112.92L263.388 140.628L251.432 133.796C251.811 134.555 252.191 135.314 252.57 136.073L264.527 142.905L267.943 121.27L273.636 160.555L278.95 151.256L283.884 154.102L287.3 132.467L292.994 171.752L298.308 162.453L308.556 168.336C308.935 167.956 309.315 167.577 309.695 167.387L297.359 160.175Z"
            fill="#FF69A6"
          />
          <path
            d="M359.607 113.679C357.14 115.956 354.103 115.007 350.877 112.161C347.651 109.314 341.578 101.343 337.023 99.4454C330.191 96.7884 326.205 93.752 322.22 93.3724C317.286 92.803 315.388 88.4381 318.235 85.2118C321.081 81.9855 345.753 85.4016 355.811 93.9417C365.87 102.102 362.264 111.212 359.607 113.679Z"
            fill="#649FF4"
          />
          <path
            d="M338.351 47.6351C336.264 52.7592 333.607 66.0439 333.607 67.5621C333.986 70.9782 339.68 74.3942 340.819 71.7373C341.957 68.511 344.235 61.1096 344.994 59.2118C346.892 54.2774 349.359 45.3577 349.549 43.6497C349.928 37.9563 352.775 28.2774 356.381 24.4818C356.381 24.4818 353.154 22.7738 350.687 25.0511C349.169 26.3796 345.943 29.9854 344.424 33.2117C343.855 34.9198 340.059 43.6497 338.351 47.6351Z"
            fill="#649FF4"
          />
          <path
            d="M308.745 135.124C306.278 137.591 305.519 138.161 303.242 140.438C301.913 141.767 303.052 142.905 305.519 142.905C307.797 142.905 312.921 141.767 315.767 140.628C317.855 139.869 321.461 137.781 323.359 136.643C325.256 135.504 325.636 133.796 325.636 133.796V129.81L313.49 130.38C313.49 130.19 311.402 132.467 308.745 135.124Z"
            fill="#3D2927"
          />
          <path
            d="M330.191 147.65C327.724 150.117 326.964 150.686 324.687 152.964C323.359 154.292 324.497 155.431 326.964 155.431C329.242 155.431 334.366 154.292 337.213 153.153C339.3 152.394 342.906 150.307 344.804 149.168C346.702 148.029 347.081 146.321 347.081 146.321V139.299L334.935 142.905C334.745 142.905 332.848 145.183 330.191 147.65Z"
            fill="#3D2927"
          />
          <path
            d="M373.461 77.6206L349.169 68.3213C349.169 68.3213 345.183 72.1169 337.972 76.4819C333.607 79.1388 322.03 85.9709 318.234 88.6279C315.957 90.3359 314.818 93.752 314.059 101.723C312.541 114.818 311.402 129.81 311.402 129.81C311.402 129.81 314.059 133.416 319.943 133.416C324.497 133.416 327.913 131.898 327.913 131.898L328.862 101.533L348.789 90.1461C348.789 90.1461 337.592 97.168 335.884 103.241C334.556 108.175 334.176 111.971 333.986 118.234C333.607 131.518 333.417 140.438 333.417 140.438C333.417 140.438 334.366 144.803 340.249 144.803C344.804 144.803 348.599 141.007 348.599 141.007L349.738 110.263C349.738 110.263 366.818 98.1169 371.373 92.8031C376.308 87.679 373.461 77.6206 373.461 77.6206Z"
            fill="#9165D1"
          />
          <path
            d="M353.534 26.0001C353.534 26.0001 346.322 31.8833 345.374 40.2337C344.994 43.6497 342.906 54.4673 342.906 63.387C342.906 65.2848 340.06 70.0293 337.782 71.5476C331.14 75.9125 318.235 85.2118 318.235 85.2118C318.235 85.2118 329.242 82.7447 337.023 90.1461C340.249 88.8177 344.994 88.0585 344.994 88.0585C344.994 88.0585 343.666 89.7666 341.958 93.3724C344.235 93.9418 349.549 92.6133 354.483 98.3067C362.644 108.175 359.987 113.679 359.987 113.679C359.987 113.679 372.133 101.723 374.79 96.7885C379.155 88.6279 379.344 80.8469 378.965 78.1899C378.206 73.2556 376.687 67.5622 377.067 64.5257C378.206 55.4162 377.447 39.4745 377.067 37.0074C376.498 33.022 367.198 28.4672 367.198 28.4672C367.198 28.4672 360.366 23.3431 353.534 26.0001Z"
            fill="#99C7FF"
          />
          <path
            d="M357.709 25.2409C357.709 25.2409 353.534 25.2409 349.928 32.2628C348.6 34.73 347.84 36.0585 347.84 36.0585L349.928 38.146L346.892 39.6643C346.892 39.6643 345.753 50.292 346.702 54.657C347.651 59.5913 352.585 68.3213 352.585 68.3213C352.585 68.3213 355.811 58.4527 357.14 55.9855C358.468 53.5183 363.782 46.6862 363.782 46.6862L361.125 42.7008L365.49 41.3723C365.49 41.3723 371.563 33.7811 370.994 31.3139C369.855 26.949 365.68 26.1898 365.68 26.1898L357.709 25.2409Z"
            fill="#649FF4"
          />
          <path
            d="M352.775 33.2117C352.775 33.2117 351.067 38.5256 350.118 43.0804C349.169 47.8249 351.826 63.1972 351.826 63.1972C351.826 63.1972 354.103 48.2044 357.33 43.8395C360.935 38.7154 365.68 33.7811 365.68 33.7811C365.68 33.7811 355.811 31.8833 352.775 33.2117Z"
            fill="#E8D6D6"
          />
          <path
            d="M354.483 34.1606L353.913 38.146C353.913 38.146 351.446 40.4234 351.067 43.2701C350.497 46.1168 350.687 56.5548 350.687 56.5548L351.636 63.1972L352.964 58.6424C352.964 58.6424 354.103 52.3796 355.052 48.584C355.242 46.876 355.242 45.5475 355.432 44.9782C356.001 43.0804 356.191 39.0949 356.191 39.0949L358.658 36.0585L356.191 33.2117L354.483 34.1606Z"
            fill="#FF69A6"
          />
          <path
            d="M356.001 25.8103L355.622 32.2628C355.622 32.2628 356.381 33.5913 358.658 35.2993C360.935 34.5402 366.249 29.6059 366.249 29.6059L364.541 19.7373L356.001 25.8103Z"
            fill="#FFB4AF"
          />
          <path
            d="M365.49 8.72994C363.023 5.69344 356.191 3.0365 351.636 9.67885C346.891 16.7008 351.446 28.0876 355.432 27.3285C357.709 26.9489 363.023 24.8613 365.3 21.0657C367.957 17.0803 367.388 11.1971 365.49 8.72994Z"
            fill="#FFC3C2"
          />
          <path
            d="M356.001 27.5183L356.571 33.4015L352.395 35.1096C352.206 35.1096 351.826 28.8468 356.001 27.5183Z"
            fill="white"
          />
          <path
            d="M365.68 26.0001C365.68 26.0001 364.352 28.0876 361.695 30.5548C359.797 32.4526 356.381 33.4015 356.381 33.4015L357.899 38.146C357.899 38.146 363.782 35.4891 365.87 33.2117C366.06 33.2117 368.527 28.8468 365.68 26.0001Z"
            fill="white"
          />
          <path
            d="M349.928 12.146C350.877 13.6643 352.396 15.3723 356.191 15.7518C359.417 15.9416 361.885 14.9927 361.885 14.9927L361.695 19.1679C361.695 19.1679 362.264 19.1679 362.834 18.9781C363.213 17.4599 363.972 17.0803 364.731 17.6497C365.68 18.4088 365.49 21.2555 365.49 21.2555C365.49 21.2555 368.906 18.4088 368.717 13.6643C368.717 10.6278 366.06 4.1752 360.556 3.41607C355.052 2.65695 351.067 5.69344 349.739 8.54016C349.169 10.0584 349.549 11.3869 349.928 12.146Z"
            fill="#33211F"
          />
          <path
            d="M362.264 84.8323H361.315L339.49 72.1169C338.351 71.3578 336.264 71.5476 334.935 72.3067L320.133 80.8468C319.943 81.0366 319.753 81.0366 319.753 81.2264H319.184V82.5549C319.184 82.9344 319.373 83.314 319.943 83.6936L341.957 96.4089C343.096 97.168 345.184 96.9783 346.512 96.2191L361.315 87.679C362.074 87.2994 362.454 86.7301 362.454 86.1607V84.8323H362.264Z"
            fill="#EA81C0"
          />
          <path
            d="M341.956 95.0804L319.942 82.3651C318.803 81.606 318.993 80.4673 320.321 79.7082L335.124 71.168C336.453 70.4089 338.54 70.2191 339.679 70.9782L361.693 83.6935C362.832 84.4527 362.642 85.5914 361.314 86.3505L346.511 94.8907C345.182 95.6498 343.095 95.6498 341.956 95.0804Z"
            fill="#FFB0D8"
          />
          <path
            d="M337.592 71.3578L322.03 80.4673L345.184 93.752L360.935 84.8322L337.592 71.3578Z"
            fill="#EA81C0"
          />
          <path
            d="M336.264 71.3578L335.125 71.9271L358.278 85.2118L359.607 84.6425L336.264 71.3578Z"
            fill="white"
          />
          <path
            d="M353.913 83.6935L352.774 84.4527L356.38 86.3505L357.519 85.7812L353.913 83.6935Z"
            fill="white"
          />
          <path
            d="M350.118 81.4162L348.979 82.1753L351.446 83.6935L352.585 82.9344L350.118 81.4162Z"
            fill="white"
          />
          <path
            d="M347.082 79.7082L345.943 80.4673L348.41 81.7957L349.549 81.2264L347.082 79.7082Z"
            fill="white"
          />
          <path
            d="M344.045 78.0001L342.906 78.7593L345.373 80.0877L346.512 79.5184L344.045 78.0001Z"
            fill="white"
          />
          <path
            d="M351.446 87.1096L350.118 87.679L351.826 88.8176L353.154 88.0585L351.446 87.1096Z"
            fill="white"
          />
          <path
            d="M348.79 85.5914L347.651 86.3505L349.359 87.2994L350.687 86.5403L348.79 85.5914Z"
            fill="white"
          />
          <path
            d="M345.753 83.8833L344.614 84.4527L347.081 85.9709L348.22 85.2118L345.753 83.8833Z"
            fill="white"
          />
          <path
            d="M349.358 88.2483L348.22 88.8176L349.928 89.9563L351.066 89.1972L349.358 88.2483Z"
            fill="white"
          />
          <path
            d="M346.892 86.7301L345.563 87.4892L347.271 88.4381L348.6 87.8687L346.892 86.7301Z"
            fill="white"
          />
          <path
            d="M347.082 89.387L345.943 89.9563L347.651 91.095L348.979 90.3359L347.082 89.387Z"
            fill="white"
          />
          <path
            d="M344.614 87.8687L343.476 88.6279L345.184 89.5768L346.322 89.0074L344.614 87.8687Z"
            fill="white"
          />
          <path
            d="M343.665 85.022L342.526 85.5914L344.994 87.1096L346.132 86.3505L343.665 85.022Z"
            fill="white"
          />
          <path
            d="M342.716 81.9855L341.578 82.7446L344.045 84.2629L345.183 83.5038L342.716 81.9855Z"
            fill="white"
          />
          <path
            d="M339.68 80.2775L338.541 81.0366L341.008 82.3651L342.147 81.7957L339.68 80.2775Z"
            fill="white"
          />
          <path
            d="M351.256 84.4527L350.118 85.2118L354.103 87.4892L355.432 86.9198L351.256 84.4527Z"
            fill="white"
          />
          <path
            d="M348.03 82.5549L346.892 83.314L349.359 84.8322L350.497 84.0731L348.03 82.5549Z"
            fill="white"
          />
          <path
            d="M344.994 80.8468L343.666 81.606L346.133 82.9344L347.461 82.3651L344.994 80.8468Z"
            fill="white"
          />
          <path
            d="M341.957 79.1388L340.629 79.8979L343.096 81.2264L344.425 80.6571L341.957 79.1388Z"
            fill="white"
          />
          <path
            d="M351.636 74.584C351.636 74.584 348.03 76.4819 345.753 77.6206C343.475 78.7592 336.643 84.6424 338.161 85.9709C339.68 87.2994 343.096 88.8177 344.044 88.6279C344.993 88.4381 347.271 85.9709 347.65 85.4016C348.03 84.8323 352.395 83.1242 353.723 81.2264C355.052 79.3286 351.636 74.584 351.636 74.584Z"
            fill="#FFC3C2"
          />
          <path
            d="M345.753 92.8031V74.0147C345.753 73.4454 345.374 72.6863 344.804 72.4965L322.6 59.7811C322.03 59.4016 321.271 59.4016 320.702 59.7811L319.943 60.1607H320.322C320.133 60.5403 319.943 60.73 319.943 61.2994V80.0877C319.943 80.6571 320.322 81.4162 320.892 81.606L343.096 94.3213C343.286 94.5111 343.476 94.5111 343.666 94.5111L344.045 94.8907L345.184 94.1315C345.374 93.752 345.753 93.3724 345.753 92.8031Z"
            fill="#F495C9"
          />
          <path
            d="M344.804 74.3943V93.1826C344.804 94.5111 343.286 95.46 342.147 94.7009L319.943 81.9855C319.373 81.606 318.994 81.0366 318.994 80.4673V61.6789C318.994 60.3505 320.512 59.4016 321.651 60.1607L343.855 72.876C344.424 73.2556 344.804 73.8249 344.804 74.3943Z"
            fill="#FFB0D8"
          />
          <path
            d="M326.016 87.1096L320.892 84.0731V81.7957L326.016 84.8322V87.1096Z"
            fill="#F495C9"
          />
          <path
            d="M340.249 95.2702L335.125 92.2337V89.9563L340.249 92.9928V95.2702Z"
            fill="#F495C9"
          />
          <path
            d="M333.038 78.1899C333.038 79.3286 332.089 79.5184 330.95 78.949C329.811 78.1899 328.862 76.8614 328.862 75.7227C328.862 74.584 329.811 74.3943 330.95 74.9636C332.089 75.533 333.038 77.0512 333.038 78.1899Z"
            fill="white"
          />
          <path
            d="M380.863 56.9344C382.191 47.0658 382.191 38.5256 376.877 35.1095C373.082 32.6424 370.614 42.8906 369.665 50.6716C369.476 52.5694 367.578 61.1096 367.198 63.1972C364.541 63.7665 350.498 73.0658 348.79 74.584C349.169 75.5329 352.585 78.0001 353.534 83.314C359.417 81.9855 368.717 76.2921 374.79 73.0658C379.155 70.9782 380.104 61.6789 380.863 56.9344Z"
            fill="#649FF4"
          />
          <path
            d="M373.651 362.482C378.395 365.139 381.432 364.57 384.658 365.329L334.176 394.555C331.14 393.986 327.913 394.176 323.359 391.519C323.169 391.519 323.169 391.329 322.979 391.329L373.081 362.292C373.461 362.292 373.461 362.482 373.651 362.482Z"
            fill="#C4ADAD"
          />
          <path
            d="M246.497 347.11L296.979 318.263C296.979 318.263 307.227 321.679 312.921 324.336L262.628 353.373C256.745 350.526 246.497 347.11 246.497 347.11Z"
            fill="#C4ADAD"
          />
          <path
            d="M337.212 341.417C341.198 343.504 345.753 344.263 350.307 345.971L300.015 375.008C295.65 373.49 291.096 372.541 286.92 370.453L337.212 341.417Z"
            fill="#C4ADAD"
          />
          <path
            d="M320.132 328.891L270.03 357.738C268.512 356.409 266.993 355.46 265.665 354.701C264.906 354.322 263.767 353.752 262.628 353.183L312.92 324.146C313.869 324.526 314.629 324.906 315.198 325.285C316.716 326.234 318.424 327.562 320.132 328.891Z"
            fill="#E8D6D6"
          />
          <path
            d="M354.862 348.249C355.622 348.628 356.191 349.008 356.95 349.577L306.848 378.614C306.278 378.234 305.709 377.855 304.95 377.475C303.242 376.526 301.724 375.767 300.016 375.198L350.308 346.161C351.826 346.541 353.344 347.3 354.862 348.249Z"
            fill="#E8D6D6"
          />
          <path
            d="M387.694 366.657C389.402 367.606 390.92 368.745 392.439 369.884L342.337 398.92C341.008 397.782 339.49 396.643 337.972 395.884C336.643 395.125 335.504 394.745 334.366 394.555L384.658 365.519C385.607 365.709 386.556 365.898 387.694 366.657Z"
            fill="#E8D6D6"
          />
          <path
            d="M400.789 378.234L350.308 407.081C350.308 407.081 346.702 402.526 342.147 398.73L392.249 369.694C397.184 373.869 400.789 378.234 400.789 378.234Z"
            fill="#D1BDBD"
          />
          <path
            d="M373.271 362.292L323.169 391.329C317.855 388.103 312.541 382.409 306.848 378.424L356.95 349.387C362.643 353.373 367.957 359.066 373.271 362.292Z"
            fill="#D1BDBD"
          />
          <path
            d="M286.351 370.074C279.899 366.468 274.585 361.344 270.22 357.928L320.322 329.081C324.687 332.687 330.001 337.431 336.074 341.037C336.453 341.227 336.833 341.417 337.213 341.606L286.921 370.643C286.731 370.263 286.541 370.263 286.351 370.074Z"
            fill="#D1BDBD"
          />
          <path
            d="M237.577 308.774L283.884 282.395L301.344 321.489L255.417 347.869L237.577 308.774Z"
            fill="#E8D6D6"
          />
          <path
            d="M232.453 320.351C232.453 330.599 238.716 342.555 246.497 347.11C254.278 351.665 260.541 346.92 260.541 336.482C260.541 326.234 254.278 314.278 246.497 309.723C238.716 305.358 232.453 310.103 232.453 320.351Z"
            fill="#D1BDBD"
          />
          <path
            d="M234.541 321.679C234.541 330.599 239.854 340.847 246.687 344.833C253.329 348.628 258.833 344.643 258.833 335.723C258.833 326.803 253.519 316.555 246.687 312.57C239.854 308.584 234.541 312.76 234.541 321.679Z"
            fill="#E8D6D6"
          />
          <path
            d="M236.249 322.438C236.249 329.84 240.804 338.57 246.497 341.796C252.191 345.022 256.745 341.606 256.745 334.015C256.745 326.614 252.191 317.884 246.497 314.657C240.804 311.621 236.249 315.037 236.249 322.438Z"
            fill="#D1BDBD"
          />
          <path
            d="M238.526 323.957C238.526 329.84 242.132 336.672 246.497 339.139C250.862 341.606 254.467 338.949 254.467 333.066C254.467 327.183 250.862 320.351 246.497 317.884C242.132 315.416 238.526 318.073 238.526 323.957Z"
            fill="#E8D6D6"
          />
          <path
            d="M241.183 325.285C241.183 329.271 243.65 333.825 246.497 335.533C249.344 337.241 251.811 335.343 251.811 331.548C251.811 327.562 249.344 323.008 246.497 321.3C243.65 319.592 241.183 321.489 241.183 325.285Z"
            fill="#D1BDBD"
          />
          <path
            d="M243.081 326.424C243.081 328.891 244.599 331.738 246.497 332.876C248.394 334.015 249.913 332.876 249.913 330.409C249.913 327.942 248.394 325.095 246.497 323.957C244.599 322.818 243.081 323.957 243.081 326.424Z"
            fill="#E8D6D6"
          />
          <path
            d="M278.57 293.971C278.57 304.219 284.833 316.176 292.614 320.73C300.395 325.285 306.657 320.541 306.657 310.103C306.657 299.854 300.395 287.898 292.614 283.343C284.833 278.789 278.57 283.533 278.57 293.971Z"
            fill="#E8D6D6"
          />
          <path
            d="M146.483 173.27C146.483 173.27 148.381 175.358 150.848 177.635C153.125 179.913 153.884 180.482 155.972 182.57C157.3 183.708 156.162 184.847 153.884 184.847C151.797 184.847 147.052 183.708 144.395 182.759C142.497 182 139.081 180.102 137.373 179.154C135.665 178.015 135.286 176.497 135.286 176.497V172.701L146.483 173.27Z"
            fill="#3D2927"
          />
          <path
            d="M120.293 186.365C120.293 186.365 120.672 190.161 121.052 194.905C121.241 199.65 120.672 204.015 119.154 204.394C117.636 204.964 113.46 204.774 111.183 198.132C108.716 191.489 110.234 185.037 110.234 185.037L120.293 186.365Z"
            fill="#3D2927"
          />
          <path
            d="M144.015 117.854C144.774 122.599 145.533 127.343 146.103 131.139C148.38 145.942 147.431 172.321 147.431 172.321C147.431 172.321 147.241 175.548 141.548 175.548C137.373 175.548 133.767 172.132 133.767 172.132L133.387 141.197L129.592 122.789C134.716 121.84 139.65 120.132 144.015 117.854Z"
            fill="#9165D1"
          />
          <path
            d="M113.84 152.015C114.22 145.372 114.979 137.591 115.358 130.949C120.483 132.278 126.176 132.278 131.68 131.518C131.11 138.73 130.161 150.307 128.074 154.672C122.38 166.628 122.38 187.883 122.38 187.883C122.38 187.883 115.169 190.351 110.234 188.073C105.3 185.606 106.249 182.949 106.249 182.949C106.249 182.949 107.577 166.248 113.84 152.015Z"
            fill="#9165D1"
          />
          <path
            d="M149.899 80.0877C148.95 77.4308 147.431 71.7373 145.913 67.7519C141.738 57.5037 139.081 54.4672 134.906 56.1753C129.782 60.3505 137.942 68.8906 138.322 75.7227C140.22 83.314 143.826 89.5768 145.534 90.7155L145.723 90.9052C147.621 91.6644 151.417 79.3286 149.899 80.0877Z"
            fill="#649FF4"
          />
          <path
            d="M151.417 74.2045C151.417 74.2045 146.672 79.8979 144.964 82.5549C143.256 85.022 144.395 90.9052 144.775 92.9928C145.154 94.8907 150.658 93.1826 153.315 89.9563C155.972 86.7301 158.059 77.0512 158.059 77.0512L151.417 74.2045Z"
            fill="#649FF4"
          />
          <path
            d="M150.278 74.7738C149.898 75.9125 151.606 78.949 155.212 78.5694C158.818 78.3797 158.818 75.7227 156.351 74.7738C153.884 74.0147 150.278 74.7738 150.278 74.7738Z"
            fill="#4EC5D8"
          />
          <path
            d="M112.891 98.1169C114.409 90.9052 110.045 83.1242 110.804 74.7738C110.045 67.7519 121.811 61.1096 121.811 61.1096L133.008 54.657C133.008 54.657 138.322 58.6424 141.548 63.0074C147.621 71.3578 145.154 89.0074 146.103 96.5987C146.672 100.584 152.556 140.438 152.556 140.438C152.556 140.438 136.614 155.81 122.38 149.168C117.826 147.08 104.92 138.92 104.92 138.92C104.92 138.92 111.563 105.139 112.891 98.1169Z"
            fill="#99C7FF"
          />
          <path
            d="M137.753 61.6789C138.702 63.387 141.169 68.8906 141.169 68.8906L138.322 71.3578L141.738 72.876C141.738 72.876 142.687 81.7958 141.738 86.3505C140.789 91.095 137.942 98.6863 137.942 98.6863C137.942 98.6863 135.286 71.5475 134.526 67.9417C133.767 64.3359 131.11 62.8176 131.11 62.8176L131.869 58.8322C131.68 58.6424 136.234 58.6424 137.753 61.6789Z"
            fill="#649FF4"
          />
          <path
            d="M131.49 61.4891C131.49 61.4891 135.855 67.9417 136.994 73.4454C138.132 78.949 138.322 97.168 138.322 97.168L136.424 99.2556C136.424 99.2556 132.059 78.7592 129.402 74.3943C126.745 70.0293 123.709 66.0439 123.709 66.0439L131.49 61.4891Z"
            fill="#A179E0"
          />
          <path
            d="M141.358 74.7738L142.117 74.584C142.117 74.584 140.22 65.095 139.65 61.4891C138.891 58.0731 136.614 56.365 135.285 55.7957C134.526 55.4161 133.387 55.0366 133.008 55.2264L133.387 55.9855C133.767 55.9855 138.132 57.1242 138.891 61.6789C139.46 65.2848 141.358 74.7738 141.358 74.7738Z"
            fill="#33211F"
          />
          <path
            d="M131.869 59.2118L133.198 66.0439C133.198 66.0439 133.198 67.7519 132.249 68.7008C130.92 69.8395 128.074 69.6497 125.796 67.7519C122.19 64.5256 121.621 63.1972 121.621 63.1972L123.519 53.1388L131.869 59.2118Z"
            fill="#FFB4AF"
          />
          <path
            d="M122.191 41.9417C124.658 38.9052 131.68 36.2482 136.235 42.8906C140.979 49.9125 136.424 61.4891 132.249 60.9198C129.972 60.5402 124.658 58.4526 122.381 54.657C119.724 50.4818 120.293 44.4088 122.191 41.9417Z"
            fill="#FFC3C2"
          />
          <path
            d="M138.322 45.3577C137.373 46.876 135.096 48.7738 131.3 49.1534C128.074 49.5329 125.417 48.584 125.417 48.584L125.986 51.8103C125.986 51.8103 125.417 51.8103 124.847 51.6205C124.278 49.9125 122.95 50.1023 122.38 51.0512C121.811 52.1899 122.38 54.657 122.38 54.657C122.38 54.657 118.585 52.5694 118.395 47.6351C118.205 44.5986 120.672 37.7665 126.366 36.8176C132.059 35.8687 136.424 38.7154 137.942 41.7519C138.512 43.2701 138.702 44.7884 138.322 45.3577Z"
            fill="#33211F"
          />
          <path
            d="M122.001 59.7811C122.001 59.7811 128.074 67.5621 130.541 72.876C135.096 82.9344 136.424 99.0658 136.424 99.0658C136.424 99.0658 131.87 89.7665 130.161 86.5403C128.833 84.2629 124.468 77.0512 124.468 77.0512L128.453 74.2045L123.14 73.4454L118.395 63.387C118.395 63.387 119.344 61.1096 122.001 59.7811Z"
            fill="#649FF4"
          />
          <path
            d="M127.125 112.92L129.403 116.146L118.395 125.635L116.877 122.029L127.125 112.92Z"
            fill="#649FF4"
          />
          <path
            d="M139.651 148.789L140.41 148.409C140.41 148.219 138.132 121.27 138.132 116.336C138.132 111.402 137.753 97.9272 137.753 97.7374H136.994C136.994 97.7374 137.373 111.212 137.373 116.336C137.373 121.46 139.651 148.599 139.651 148.789Z"
            fill="#649FF4"
          />
          <path
            d="M140.03 76.1023C140.03 77.4308 140.789 78.7592 141.738 78.7592C142.687 78.7592 143.446 77.6205 143.446 76.1023C143.446 74.7738 142.687 73.4454 141.738 73.4454C140.789 73.4454 140.03 74.7738 140.03 76.1023Z"
            fill="#AFAFAF"
          />
          <path
            d="M143.256 74.584C143.066 74.3943 142.497 73.8249 141.928 74.2045C141.169 74.3943 140.789 75.1534 140.789 76.2921C140.789 77.4308 141.358 78.3797 142.307 78.3797C142.497 78.3797 142.497 78.3797 142.687 78.3797C142.877 78.3797 143.446 78.1899 143.826 77.4308C144.205 76.1023 143.636 75.1534 143.256 74.584Z"
            fill="#5B4242"
          />
          <path
            d="M141.358 76.1023C141.358 77.0512 141.927 78.0001 142.686 78.0001C143.446 78.0001 144.015 77.241 144.015 76.1023C144.015 75.1534 143.446 74.2045 142.686 74.2045C141.927 74.2045 141.358 75.1534 141.358 76.1023Z"
            fill="white"
          />
          <path
            d="M119.534 61.4891C120.293 59.7811 122.001 59.022 122.001 59.022V59.9709C122.001 59.9709 120.862 60.5402 120.293 61.8687C119.723 63.0074 119.534 65.4745 121.811 70.0293C123.709 74.0147 125.037 77.241 125.796 79.7082C125.607 79.7082 125.417 79.7081 125.227 79.8979C125.417 79.8979 125.417 79.7081 125.227 79.8979H125.037C124.278 77.4308 123.14 74.2045 121.242 70.4089C119.154 66.4234 118.585 63.387 119.534 61.4891Z"
            fill="#33211F"
          />
          <path
            d="M121.621 88.4381L128.453 85.4016L127.694 83.6935L120.862 86.7301L121.621 88.4381Z"
            fill="#649FF4"
          />
          <path
            d="M126.745 96.2191L127.504 95.6498C127.504 95.6498 124.847 92.0439 123.329 86.9198C122.76 85.2118 122.76 83.6936 122.95 82.5549C123.139 81.2264 123.899 80.4673 125.037 80.0877C126.745 79.5184 128.833 79.7082 130.731 85.2118C132.628 90.7155 131.49 94.3213 131.49 94.3213L132.439 94.7009C132.439 94.5111 133.577 90.7155 131.68 85.022C130.161 80.2775 127.884 78.3797 124.847 79.1388C123.329 79.5184 122.38 80.6571 122.001 82.3651C121.621 83.6936 121.811 85.4016 122.38 87.2994C123.899 92.4235 126.555 96.0294 126.745 96.2191Z"
            fill="white"
          />
          <path
            d="M131.11 94.7009C131.11 95.2702 131.49 95.6498 132.059 95.6498C132.629 95.6498 133.008 95.2702 133.008 94.7009C133.008 94.1315 132.629 93.752 132.059 93.752C131.49 93.752 131.11 94.1315 131.11 94.7009Z"
            fill="#8C8C8C"
          />
          <path
            d="M126.176 96.0293C126.176 96.5987 126.555 96.9782 127.125 96.9782C127.694 96.9782 128.074 96.5987 128.074 96.0293C128.074 95.46 127.694 95.0804 127.125 95.0804C126.555 95.0804 126.176 95.46 126.176 96.0293Z"
            fill="#8C8C8C"
          />
          <path
            d="M132.439 94.7009C132.439 94.7009 137.753 92.6133 140.789 90.7154C143.826 88.8176 146.673 88.0585 145.724 89.5768C144.775 91.2848 142.877 91.2848 142.308 92.6133C141.738 93.9417 144.585 92.803 147.242 92.6133C149.709 92.4235 154.833 93.1826 151.417 96.7885C148.001 100.584 144.205 99.6352 140.03 99.8249C135.855 100.015 133.957 100.394 133.957 100.394L132.439 94.7009Z"
            fill="#FFC3C2"
          />
          <path
            d="M105.869 87.4892C106.249 78.949 104.92 72.1169 113.46 67.7519C117.825 68.3213 120.672 85.9709 119.344 92.6133C120.482 92.6133 136.044 91.8541 136.044 91.8541C136.044 91.8541 138.511 100.774 135.855 102.482C131.11 103.051 122.38 104.38 118.395 104.38C114.409 104.38 107.957 104.759 106.249 100.205C105.3 98.1169 105.679 89.9563 105.869 87.4892Z"
            fill="#649FF4"
          />
          <path
            d="M148.95 76.4818L151.227 66.6132C151.417 65.8541 151.986 65.095 152.745 64.9052L157.3 63.5767C157.49 63.5767 157.869 63.5767 157.869 63.7665L158.439 64.3359L158.059 65.095L155.972 74.7738C155.782 75.533 155.212 76.2921 154.453 76.4818L150.088 77.8103L149.709 78.3797L149.139 77.8103C148.95 77.241 148.95 76.8614 148.95 76.4818Z"
            fill="#2143AF"
          />
          <path
            d="M150.278 78.0001L154.833 76.6716C155.402 76.4819 156.161 75.7228 156.351 74.9636L158.629 65.095C158.818 64.3359 158.439 63.9563 157.87 64.1461L153.315 65.4746C152.745 65.6643 151.986 66.4235 151.796 67.1826L149.519 77.0512C149.329 77.8103 149.709 78.1899 150.278 78.0001Z"
            fill="#3371D3"
          />
          <path
            d="M149.709 70.9782C149.329 70.2191 149.519 69.46 150.088 69.2702C150.468 68.1315 150.468 67.9417 151.607 68.3213C153.125 68.8906 155.213 70.0293 155.782 69.8395C156.731 69.4599 157.869 67.1826 157.869 67.1826C157.869 67.1826 158.439 69.2702 158.249 71.7373C158.059 73.0658 156.921 77.4308 155.592 78.5695C154.264 78.949 152.935 78.5694 152.176 78.0001C152.366 77.6205 152.745 76.8614 152.745 76.4818C151.417 75.5329 150.848 74.3943 149.899 73.6351C149.329 73.2556 149.329 73.2556 149.329 72.3067C148.95 71.7373 149.14 71.168 149.709 70.9782Z"
            fill="#FFC3C2"
          />
          <path
            d="M204.556 258.862C202.278 259.621 201.14 260 201.14 260C201.14 260 201.329 247.095 202.278 241.781C202.658 240.073 194.118 239.884 194.118 239.884C193.738 241.022 193.548 251.84 194.118 258.482C194.307 260.759 194.118 262.088 194.118 263.227C194.687 263.416 195.826 263.986 196.775 264.365C197.913 264.935 200.001 264.935 202.278 263.796C204.745 262.657 207.023 261.139 206.264 259.811C206.074 259.241 205.315 258.862 204.556 258.862Z"
            fill="#FFC5B3"
          />
          <path
            d="M205.694 258.482C205.315 258.672 204.935 258.672 204.555 258.862C205.315 259.051 206.074 259.241 206.453 259.811C207.212 261.139 204.935 262.657 202.468 263.796C200.001 264.935 198.103 264.935 196.964 264.365C196.015 263.986 194.877 263.416 194.307 263.227C194.117 264.745 193.738 265.504 194.117 266.643C194.877 268.541 196.774 268.351 200.57 267.022C203.986 265.884 206.643 264.555 208.92 262.657C211.198 260.949 211.388 260.759 211.577 259.431C212.147 258.103 209.68 257.154 205.694 258.482Z"
            fill="#33211F"
          />
          <path
            d="M217.081 263.416C214.804 264.176 213.665 264.555 213.665 264.555C213.665 264.555 214.804 243.679 215.753 238.365C216.132 236.657 207.023 239.884 207.023 239.884C206.643 241.022 206.074 256.584 206.643 263.227C206.833 265.504 206.643 266.832 206.643 267.971C207.213 268.161 208.351 268.73 209.3 269.11C210.439 269.679 212.526 269.679 214.804 268.541C217.271 267.402 219.548 265.884 218.789 264.555C218.599 263.796 217.84 263.606 217.081 263.416Z"
            fill="#FFC5B3"
          />
          <path
            d="M218.22 263.037C217.84 263.227 217.461 263.227 217.081 263.416C217.84 263.606 218.6 263.796 218.979 264.365C219.738 265.694 217.461 267.212 214.994 268.351C212.527 269.489 210.629 269.489 209.49 268.92C208.541 268.541 207.402 267.971 206.833 267.781C206.643 269.3 206.264 270.059 206.643 271.197C207.402 273.095 209.3 272.906 213.096 271.577C216.512 270.438 219.169 269.11 221.446 267.212C223.724 265.504 223.913 265.314 224.103 263.986C224.673 262.847 222.205 261.898 218.22 263.037Z"
            fill="#33211F"
          />
          <path
            d="M194.877 239.694C194.877 239.694 195.067 255.446 194.877 256.205C194.687 256.964 194.877 260.949 194.877 260.949C194.877 260.949 197.913 263.796 201.14 261.898C204.366 260 206.453 257.913 206.453 257.913L204.745 230.584L194.877 239.694Z"
            fill="#33211F"
          />
          <path
            d="M206.074 244.249C206.074 244.249 206.264 260 206.074 260.759C205.884 261.519 206.074 265.504 206.074 265.504C206.074 265.504 209.111 268.351 212.337 266.453C215.563 264.555 217.651 262.467 217.651 262.467L216.322 233.431L206.074 244.249Z"
            fill="#33211F"
          />
          <path
            d="M127.125 351.095L44.1902 303.081C35.2705 297.957 36.4092 288.847 46.6574 282.964L91.4458 257.154C101.694 251.27 117.446 250.511 126.366 255.635L209.3 303.65C218.22 308.774 217.081 317.884 206.833 323.767L162.044 349.577C151.606 355.46 136.044 356.219 127.125 351.095Z"
            fill="#E54193"
          />
          <path
            d="M127.125 346.92L44.1902 298.905C35.2705 293.781 36.4092 284.672 46.6574 278.789L91.4458 252.978C101.694 247.095 117.446 246.336 126.366 251.46L209.3 299.475C218.22 304.599 217.081 313.708 206.833 319.592L162.044 345.402C151.606 351.475 136.044 352.044 127.125 346.92Z"
            fill="#69DBFF"
          />
          <path
            d="M38.1172 249.562V294.351L215.183 312V268.541L38.1172 249.562Z"
            fill="#69DBFF"
          />
          <path
            d="M38.1172 236.657V249.562L215.183 268.541V254.307L38.1172 236.657Z"
            fill="#D1BDBD"
          />
          <path
            d="M127.125 307.066L44.1902 259.051C35.2705 253.927 36.4092 244.818 46.6574 238.935L91.4458 213.124C101.694 207.241 117.446 206.482 126.366 211.606L209.3 259.621C218.22 264.745 217.081 273.854 206.833 279.738L162.044 305.548C151.606 311.431 136.044 312.19 127.125 307.066Z"
            fill="#493231"
          />
          <path
            d="M127.125 303.27L44.1902 255.256C35.2705 250.132 36.4092 241.022 46.6574 235.139L91.4458 209.329C101.694 203.446 117.446 202.686 126.366 207.811L209.3 255.825C218.22 260.949 217.081 270.059 206.833 275.942L162.044 301.752C151.606 307.635 136.044 308.395 127.125 303.27Z"
            fill="#D1BDBD"
          />
          <path
            d="M127.125 293.402L44.1907 245.387C35.271 240.263 36.4097 231.154 46.6579 225.27L91.4463 199.46C101.694 193.577 117.446 192.818 126.366 197.942L209.3 245.957C218.22 251.081 217.081 260.19 206.833 266.073L162.045 291.884C151.607 297.957 136.045 298.526 127.125 293.402Z"
            fill="#E8D6D6"
          />
          <path
            d="M164.891 241.022C164.891 252.219 147.811 261.329 126.745 261.329C105.679 261.329 88.5991 252.219 88.5991 241.022C88.5991 229.825 105.679 220.716 126.745 220.716C147.811 220.526 164.891 229.635 164.891 241.022Z"
            fill="#69DBFF"
          />
          <path
            d="M168.307 334.774L172.292 337.052V310.292L168.307 308.015V334.774Z"
            fill="#E8D6D6"
          />
          <path
            d="M192.789 320.541L196.774 322.818V296.059L192.789 293.781V320.541Z"
            fill="#E8D6D6"
          />
          <path
            d="M196.774 296.059V317.694L176.088 329.65V308.205L172.292 310.292V337.052L200.57 320.73V293.971L196.774 296.059Z"
            fill="#D1BDBD"
          />
          <path
            d="M176.088 329.65L196.775 317.694L192.789 315.227L176.088 324.906V329.65Z"
            fill="white"
          />
          <path
            d="M176.088 308.205L172.292 310.292L168.307 308.015L171.913 305.738L176.088 308.205Z"
            fill="white"
          />
          <path
            d="M200.57 293.971L196.774 296.059L192.789 293.781L196.585 291.504L200.57 293.971Z"
            fill="white"
          />
          <path
            d="M110.614 252.409L103.402 248.234L140.03 226.978L147.241 231.154L110.614 252.409Z"
            fill="white"
          />
          <path
            d="M100.555 240.263L104.161 234L149.898 239.314L146.292 245.387L100.555 240.263Z"
            fill="white"
          />
          <path
            d="M135.285 251.84L124.467 253.927L115.358 227.548L126.175 225.46L135.285 251.84Z"
            fill="white"
          />
          <path
            d="M173.811 225.46C171.534 226.409 165.65 233.811 166.599 241.022C167.548 248.044 174.76 253.927 185.767 255.066C196.585 256.395 213.855 249.942 213.855 249.942C213.855 249.942 213.665 248.044 206.264 244.059C196.964 239.314 173.811 225.46 173.811 225.46Z"
            fill="#D1BDBD"
          />
          <path
            d="M173.052 191.11C169.826 194.146 168.118 202.117 173.432 206.672C178.746 211.227 180.644 191.869 180.644 191.869C178.556 191.11 176.848 187.504 173.052 191.11Z"
            fill="#649FF4"
          />
          <path
            d="M187.475 169.475C185.008 167.767 180.264 168.336 178.176 172.701C177.037 175.168 178.555 181.621 178.935 186.175C179.125 189.781 177.986 191.869 177.986 191.869C177.986 191.869 177.986 191.869 181.212 193.197C184.439 194.526 187.665 193.197 187.665 193.197L187.475 187.694C187.475 187.694 189.753 187.504 190.322 187.124C191.84 185.796 193.358 183.518 192.789 178.584C191.84 170.803 188.424 170.044 187.475 169.475Z"
            fill="#FFC5B3"
          />
          <path
            d="M196.016 224.701C192.22 225.84 187.096 226.599 182.921 227.358V245.957H183.111C192.03 244.628 201.709 239.504 205.315 237.227C206.833 230.015 206.264 221.854 196.016 224.701Z"
            fill="#33211F"
          />
          <path
            d="M206.074 229.635C198.483 231.723 185.388 233.431 185.388 233.431C185.388 233.431 173.811 227.548 172.103 232.672C169.067 241.971 173.431 245.957 177.986 248.424C180.453 249.752 182.921 252.789 191.84 251.46C200.76 250.132 212.716 239.314 216.132 237.037C217.081 229.256 216.322 226.789 206.074 229.635Z"
            fill="#33211F"
          />
          <path
            d="M212.337 227.548C212.337 227.548 214.614 229.825 213.855 234C213.096 238.176 213.096 239.504 213.475 241.022C214.045 242.541 214.424 243.869 213.475 245.577C212.526 247.285 211.577 247.854 211.577 247.854C211.577 247.854 207.972 247.095 205.504 248.234C203.227 249.373 199.811 250.132 197.534 250.891C195.066 251.65 192.789 237.037 192.789 237.037C192.789 237.037 208.92 227.548 212.337 227.548Z"
            fill="#99C7FF"
          />
          <path
            d="M218.22 208.38L198.483 196.993C198.103 196.803 197.724 196.993 197.724 197.562V213.314C197.724 213.883 198.103 214.643 198.483 214.832L218.22 226.219C218.6 226.409 218.979 226.219 218.979 225.65V209.898C218.979 209.329 218.6 208.57 218.22 208.38Z"
            fill="#2143AF"
          />
          <path
            d="M217.65 226.789L218.789 226.219L217.84 225.65L217.65 226.789Z"
            fill="#2143AF"
          />
          <path
            d="M196.776 197.373L198.294 198.891L197.915 196.803L196.776 197.373Z"
            fill="#2143AF"
          />
          <path
            d="M217.081 208.949L197.344 197.562C196.965 197.373 196.585 197.562 196.585 198.132V213.884C196.585 214.453 196.965 215.212 197.344 215.402L217.081 226.789C217.461 226.978 217.84 226.789 217.84 226.219V210.467C217.84 209.898 217.651 209.139 217.081 208.949Z"
            fill="#3371D3"
          />
          <path
            d="M197.154 213.694L217.271 225.081V209.898L197.154 198.321V213.694Z"
            fill="#33211F"
          />
          <path
            d="M205.884 219.197C205.694 219.197 205.694 219.197 205.694 219.387C205.694 219.577 205.884 219.767 205.884 219.767L208.351 221.285C208.541 221.285 208.541 221.285 208.541 221.095C208.541 220.905 208.351 220.716 208.351 220.716L205.884 219.197Z"
            fill="#472E2C"
          />
          <path
            d="M198.293 214.263L216.132 224.511V210.657L198.293 200.219V214.263Z"
            fill="white"
          />
          <path
            d="M216.702 228.307L197.534 217.3C196.965 216.92 196.206 217.11 195.446 217.3L180.833 225.65C180.264 226.03 180.264 226.599 180.643 226.789L199.811 237.796C200.381 238.176 201.14 237.986 201.899 237.796L216.512 229.446C217.271 229.256 217.271 228.686 216.702 228.307Z"
            fill="#2143AF"
          />
          <path
            d="M217.081 227.548H215.753V228.876H217.081V227.548Z"
            fill="#2143AF"
          />
          <path
            d="M181.972 225.27H180.644V226.599H181.972V225.27Z"
            fill="#2143AF"
          />
          <path
            d="M216.702 227.168L197.534 216.161C196.965 215.781 196.206 215.971 195.446 216.161L180.833 224.511C180.264 224.891 180.264 225.46 180.643 225.65L199.811 236.657C200.381 237.037 201.14 236.847 201.899 236.657L216.512 228.307C217.271 227.927 217.271 227.548 216.702 227.168Z"
            fill="#3371D3"
          />
          <path
            opacity="0.5"
            d="M186.906 222.424L205.884 233.241L215.563 227.738L196.585 216.92L186.906 222.424Z"
            fill="#B9B8F1"
          />
          <path
            opacity="0.5"
            d="M188.045 228.876L194.687 232.672L198.293 230.584L191.651 226.789L188.045 228.876Z"
            fill="#B9B8F1"
          />
          <path
            d="M212.337 227.548L213.665 228.307L214.614 227.738L213.286 226.978L212.337 227.548Z"
            fill="#2143AF"
          />
          <path
            d="M211.008 228.307L212.337 229.066L213.286 228.497L211.957 227.738L211.008 228.307Z"
            fill="#2143AF"
          />
          <path
            d="M209.111 228.876L211.009 229.825L211.958 229.256L210.06 228.307L209.111 228.876Z"
            fill="#2143AF"
          />
          <path
            d="M191.651 220.336L192.41 220.905L194.877 219.577L193.928 219.008L191.651 220.336Z"
            fill="#2143AF"
          />
          <path
            d="M207.782 229.635L209.49 230.774L210.439 230.205L208.731 229.066L207.782 229.635Z"
            fill="#2143AF"
          />
          <path
            d="M205.884 230.205L208.162 231.533L209.111 230.964L206.833 229.635L205.884 230.205Z"
            fill="#2143AF"
          />
          <path
            d="M190.132 221.095L193.738 223.183L194.687 222.613L191.081 220.526L190.132 221.095Z"
            fill="#2143AF"
          />
          <path
            d="M205.505 231.533L206.833 232.292L207.782 231.723L206.454 230.964L205.505 231.533Z"
            fill="#2143AF"
          />
          <path
            d="M210.819 226.599L212.147 227.358L213.096 226.789L211.768 226.03L210.819 226.599Z"
            fill="#2143AF"
          />
          <path
            d="M209.3 227.358L210.629 228.117L211.578 227.548L210.249 226.789L209.3 227.358Z"
            fill="#2143AF"
          />
          <path
            d="M209.111 225.65L210.439 226.409L211.388 225.84L210.06 225.081L209.111 225.65Z"
            fill="#2143AF"
          />
          <path
            d="M207.782 226.409L208.921 227.168L210.06 226.599L208.731 225.84L207.782 226.409Z"
            fill="#2143AF"
          />
          <path
            d="M207.402 224.701L208.731 225.46L209.68 224.891L208.351 224.132L207.402 224.701Z"
            fill="#2143AF"
          />
          <path
            d="M206.074 225.46L207.403 226.219L208.352 225.65L207.023 224.891L206.074 225.46Z"
            fill="#2143AF"
          />
          <path
            d="M205.694 223.752L207.023 224.511L207.972 223.942L206.643 223.183L205.694 223.752Z"
            fill="#2143AF"
          />
          <path
            d="M204.366 224.511L205.695 225.27L206.644 224.701L205.315 223.942L204.366 224.511Z"
            fill="#2143AF"
          />
          <path
            d="M204.176 222.803L205.505 223.562L206.454 222.993L205.125 222.234L204.176 222.803Z"
            fill="#2143AF"
          />
          <path
            d="M202.658 223.562L203.987 224.321L204.936 223.752L203.607 222.993L202.658 223.562Z"
            fill="#2143AF"
          />
          <path
            d="M202.468 221.854L203.797 222.613L204.746 222.044L203.417 221.285L202.468 221.854Z"
            fill="#2143AF"
          />
          <path
            d="M201.14 222.613L202.468 223.373L203.417 222.803L202.089 222.044L201.14 222.613Z"
            fill="#2143AF"
          />
          <path
            d="M207.402 227.927L208.731 228.686L209.68 228.117L208.351 227.358L207.402 227.927Z"
            fill="#2143AF"
          />
          <path
            d="M206.074 228.686L207.403 229.446L208.352 228.876L207.023 228.117L206.074 228.686Z"
            fill="#2143AF"
          />
          <path
            d="M205.884 226.978L207.213 227.738L208.162 227.168L206.833 226.409L205.884 226.978Z"
            fill="#2143AF"
          />
          <path
            d="M204.366 227.738L205.695 228.497L206.644 227.927L205.315 227.168L204.366 227.738Z"
            fill="#2143AF"
          />
          <path
            d="M204.176 226.029L205.505 226.789L206.454 226.219L205.125 225.46L204.176 226.029Z"
            fill="#2143AF"
          />
          <path
            d="M202.848 226.789L204.176 227.548L205.125 226.978L203.797 226.219L202.848 226.789Z"
            fill="#2143AF"
          />
          <path
            d="M202.468 225.081L203.797 225.84L204.746 225.27L203.417 224.511L202.468 225.081Z"
            fill="#2143AF"
          />
          <path
            d="M201.14 225.84L202.468 226.599L203.417 226.029L202.089 225.27L201.14 225.84Z"
            fill="#2143AF"
          />
          <path
            d="M200.76 224.132L202.089 224.891L203.038 224.321L201.709 223.562L200.76 224.132Z"
            fill="#2143AF"
          />
          <path
            d="M199.432 224.891L200.761 225.65L201.709 225.081L200.381 224.321L199.432 224.891Z"
            fill="#2143AF"
          />
          <path
            d="M199.242 223.183L200.57 223.942L201.519 223.373L200.191 222.613L199.242 223.183Z"
            fill="#2143AF"
          />
          <path
            d="M197.724 223.942L199.052 224.701L200.001 224.132L198.673 223.373L197.724 223.942Z"
            fill="#2143AF"
          />
          <path
            d="M197.534 222.044L198.863 222.803L199.812 222.424L198.483 221.665L197.534 222.044Z"
            fill="#2143AF"
          />
          <path
            d="M196.206 222.993L197.534 223.752L198.483 223.183L197.154 222.424L196.206 222.993Z"
            fill="#2143AF"
          />
          <path
            d="M203.986 229.066L205.315 229.825L206.264 229.256L204.935 228.497L203.986 229.066Z"
            fill="#2143AF"
          />
          <path
            d="M202.468 228.117L203.797 228.876L204.746 228.307L203.417 227.548L202.468 228.117Z"
            fill="#2143AF"
          />
          <path
            d="M200.76 227.168L202.089 227.927L203.038 227.358L201.709 226.599L200.76 227.168Z"
            fill="#2143AF"
          />
          <path
            d="M199.052 226.219L200.381 226.978L201.33 226.409L200.001 225.65L199.052 226.219Z"
            fill="#2143AF"
          />
          <path
            d="M197.344 225.27L198.673 226.029L199.622 225.46L198.293 224.701L197.344 225.27Z"
            fill="#2143AF"
          />
          <path
            d="M195.826 224.321L197.154 225.081L198.103 224.511L196.775 223.752L195.826 224.321Z"
            fill="#2143AF"
          />
          <path
            d="M194.118 223.373L195.446 224.132L196.395 223.562L195.067 222.803L194.118 223.373Z"
            fill="#2143AF"
          />
          <path
            d="M195.826 221.095L197.154 221.854L198.103 221.475L196.775 220.716L195.826 221.095Z"
            fill="#2143AF"
          />
          <path
            d="M194.497 222.044L195.826 222.803L196.774 222.234L195.446 221.475L194.497 222.044Z"
            fill="#2143AF"
          />
          <path
            d="M194.118 220.146L195.446 220.905L196.395 220.336L195.067 219.577L194.118 220.146Z"
            fill="#2143AF"
          />
          <path
            d="M192.79 221.095L194.118 221.854L195.067 221.285L193.738 220.526L192.79 221.095Z"
            fill="#2143AF"
          />
          <path
            d="M200.76 220.905L202.089 221.665L203.038 221.095L201.709 220.336L200.76 220.905Z"
            fill="#2143AF"
          />
          <path
            d="M199.432 221.665L200.761 222.424L201.709 221.854L200.381 221.095L199.432 221.665Z"
            fill="#2143AF"
          />
          <path
            d="M199.052 219.767L200.381 220.526L201.33 220.146L200.001 219.387L199.052 219.767Z"
            fill="#2143AF"
          />
          <path
            d="M197.724 220.716L199.052 221.475L200.001 220.905L198.673 220.146L197.724 220.716Z"
            fill="#2143AF"
          />
          <path
            d="M197.344 218.818L198.673 219.577L199.622 219.197L198.483 218.438L197.344 218.818Z"
            fill="#2143AF"
          />
          <path
            d="M196.016 219.767L197.344 220.526L198.293 219.956L196.965 219.197L196.016 219.767Z"
            fill="#2143AF"
          />
          <path
            d="M195.826 217.869L197.154 218.628L198.103 218.059L196.775 217.3L195.826 217.869Z"
            fill="#2143AF"
          />
          <path
            d="M194.308 218.818L195.636 219.577L196.585 219.008L195.257 218.248L194.308 218.818Z"
            fill="#2143AF"
          />
          <path
            d="M203.797 230.584L205.125 231.343L206.074 230.774L204.746 230.015L203.797 230.584Z"
            fill="#2143AF"
          />
          <path
            d="M202.089 229.635L203.417 230.394L204.366 229.825L203.038 229.066L202.089 229.635Z"
            fill="#2143AF"
          />
          <path
            d="M193.548 224.701L194.877 225.46L195.826 224.891L194.497 224.132L193.548 224.701Z"
            fill="#2143AF"
          />
          <path
            d="M191.841 223.562L193.169 224.511L194.118 223.942L192.79 223.183L191.841 223.562Z"
            fill="#2143AF"
          />
          <path
            d="M190.132 222.613L191.461 223.373L192.41 222.993L191.081 222.234L190.132 222.613Z"
            fill="#2143AF"
          />
          <path
            d="M188.804 221.854L189.942 222.424L190.891 222.044L189.753 221.285L188.804 221.854Z"
            fill="#2143AF"
          />
          <path
            d="M195.067 225.65L201.899 229.446L202.848 228.876L196.016 225.081L195.067 225.65Z"
            fill="#2143AF"
          />
          <path
            d="M214.045 230.774L215.943 229.635V229.066L214.045 230.205V230.774Z"
            fill="white"
          />
          <path
            d="M211.768 232.103L213.665 230.964V230.394L211.768 231.533V232.103Z"
            fill="white"
          />
          <path
            d="M195.256 214.643C191.461 213.883 188.994 215.212 188.994 215.212L190.891 222.993C190.891 222.993 192.979 223.373 196.016 223.942C199.052 224.511 199.621 224.701 199.621 224.701C199.621 224.701 200.001 224.321 199.432 223.373C198.672 222.424 196.964 222.044 196.964 222.044L200.001 219.767C200.001 219.956 199.052 215.402 195.256 214.643Z"
            fill="#FFC5B3"
          />
          <path
            d="M196.016 209.519C197.914 205.533 193.738 198.321 192.41 195.854C191.271 193.197 187.476 191.679 187.476 191.679L177.797 190.54C177.797 190.54 171.914 192.438 171.914 201.168C171.914 206.482 172.863 210.467 173.622 217.11C173.811 219.008 172.483 222.803 169.826 230.774C168.308 235.139 168.118 239.314 170.775 244.628C172.863 248.803 181.213 252.03 185.198 252.599C190.702 253.358 197.914 251.46 200.381 249.752C202.848 248.044 199.622 237.416 196.775 231.533C196.206 230.015 192.22 217.679 196.016 209.519Z"
            fill="#99C7FF"
          />
          <path
            d="M187.476 190.161C187.476 190.161 184.629 191.3 181.403 190.161C178.176 189.022 178.176 189.022 178.176 189.022L177.038 191.3C177.038 191.3 177.607 192.818 180.643 193.956C183.87 194.905 186.716 194.716 189.943 193.197C189.943 191.11 187.476 190.161 187.476 190.161Z"
            fill="#649FF4"
          />
          <path
            d="M181.782 167.008C177.607 167.956 173.052 170.613 174.57 179.723C175.329 182 176.468 184.088 178.176 185.796C178.935 186.745 180.074 187.504 180.264 188.832C183.68 188.453 186.906 182.949 188.804 181.051C190.702 179.154 192.789 178.394 192.979 175.737C193.548 170.613 189.753 165.489 181.782 167.008Z"
            fill="#33211F"
          />
          <path
            d="M188.045 179.533C187.285 182.57 189.942 183.708 189.942 183.708L190.891 179.533C190.891 179.533 188.804 176.117 188.045 179.533Z"
            fill="#FFC5B3"
          />
          <path
            d="M203.607 213.314C201.329 209.519 200.38 209.139 198.103 203.256C195.826 197.373 193.359 193.577 189.943 194.905C189.943 194.905 187.665 196.234 187.475 201.358C187.286 206.482 188.804 209.708 191.651 214.832C192.979 217.11 197.534 222.803 200.381 223.183C203.227 223.562 211.578 221.665 214.804 218.248C217.081 215.971 215.373 213.314 214.804 211.986C213.286 211.606 206.833 212.365 203.607 213.314Z"
            fill="#649FF4"
          />
          <path
            d="M218.979 209.898C218.41 209.898 217.271 210.278 217.271 210.278C217.271 210.278 217.081 209.329 216.512 208.38C216.132 207.81 215.563 207.431 215.753 208.759C215.942 209.898 214.804 212.175 214.804 212.175L215.942 217.11C215.942 217.11 220.307 217.11 221.256 213.884C222.395 210.657 219.548 209.708 218.979 209.898Z"
            fill="#FFC5B3"
          />
          <path
            d="M213.286 211.986C213.286 211.986 214.424 212.935 214.994 215.212C215.373 217.11 214.994 218.248 214.994 218.248C214.994 218.248 217.081 217.869 217.461 216.161C217.651 214.453 216.702 210.088 213.286 211.986Z"
            fill="#99C7FF"
          />
          <path
            d="M179.315 172.891C179.315 172.891 175.329 170.044 174.95 172.511C174.381 174.789 172.862 179.343 171.724 180.482C170.585 181.621 169.256 183.329 170.016 185.227C170.775 187.314 171.913 189.592 170.585 190.54C169.446 191.3 166.599 194.716 167.548 196.424C168.497 197.942 169.826 198.511 168.877 201.168C168.118 202.686 168.118 204.584 170.016 205.913C171.724 207.051 174.57 208.759 174.381 210.088C174.191 211.416 171.724 211.227 171.724 211.227C171.724 211.227 174.001 214.832 177.227 212.555C180.643 210.088 177.986 206.862 179.315 205.343C180.643 203.635 183.3 203.446 183.3 200.599C183.3 197.752 182.541 197.562 183.87 196.044C185.198 194.526 186.147 195.475 188.424 192.628C191.271 189.212 189.183 186.745 189.373 183.329C189.373 182.38 192.41 179.913 193.169 177.066C193.548 174.029 184.629 169.664 179.315 172.891Z"
            fill="#33211F"
          />
          <path
            d="M68.103 208.759C68.103 208.759 64.8768 213.884 62.5994 217.489C59.5629 217.489 55.7673 217.11 53.3001 216.73C53.1103 216.73 52.9205 216.73 52.7307 216.73C50.6431 216.54 48.7454 215.971 47.4169 215.971C47.4169 215.971 45.8986 215.971 43.811 215.781C41.9132 215.781 41.7235 215.592 40.9643 215.971C40.5848 216.161 40.395 216.92 41.5337 216.92C42.103 216.92 42.6723 217.11 43.2417 217.3C42.103 217.679 40.7745 218.059 39.0665 219.197C36.7891 220.716 36.9789 223.183 38.6869 223.373C40.7745 223.562 44.7599 221.854 47.2271 220.905C49.3147 219.957 50.4534 220.905 52.7307 221.665C53.1103 221.854 53.4899 221.854 54.0592 222.044C55.5775 222.424 61.8403 225.081 65.6359 224.891C65.8257 224.891 65.8256 224.701 66.0154 224.701C67.5337 223.183 72.8476 213.314 72.8476 213.314L68.103 208.759Z"
            fill="#FFA8A6"
          />
          <path
            d="M57.6656 280.687C55.7677 280.687 53.8699 280.497 52.1619 280.307C52.1619 281.446 52.1619 282.395 52.1619 283.154C52.1619 287.329 49.6948 291.314 48.5561 292.833C47.4174 294.351 50.6437 296.818 52.921 294.541C55.1984 292.263 56.7167 291.125 58.0451 290.555C59.3736 289.796 59.1838 289.037 58.0451 284.292C58.0451 283.154 57.8553 282.015 57.6656 280.687Z"
            fill="#FF9784"
          />
          <path
            d="M74.366 290.365C72.4681 290.745 70.5703 290.555 68.6725 290.176C68.6725 290.365 68.6725 290.745 68.6725 290.935C68.6725 295.11 66.2053 299.095 65.0666 300.614C64.1177 302.132 67.1543 304.599 69.4316 302.322C71.709 300.044 71.8988 299.665 73.2273 298.906C74.5557 298.146 76.2638 297.387 75.1251 293.971C75.3149 293.592 74.366 290.935 74.366 290.365Z"
            fill="#FFC3C2"
          />
          <path
            d="M51.4039 287.708C51.4039 287.708 49.3163 290.745 47.4185 292.832C45.3309 295.3 44.382 295.869 43.8126 297.198C43.2433 298.526 45.7104 301.752 49.8856 299.285C54.0608 296.818 54.82 296.249 56.1484 294.92C58.0462 293.212 59.9441 292.073 60.1338 289.606C60.3236 287.519 58.8053 286.19 58.8053 286.19C58.8053 286.19 58.4258 287.708 55.9586 289.037C54.0608 290.176 51.4039 287.708 51.4039 287.708Z"
            fill="white"
          />
          <path
            d="M43.8113 297.197C43.8113 297.197 46.658 298.906 46.8478 299.095C49.3149 300.234 51.5923 297.577 53.8696 296.059C56.147 294.541 57.4755 292.833 58.4244 291.314C59.3733 289.606 60.1325 288.847 60.1325 288.847C60.1325 288.847 61.4609 292.073 59.5631 293.212C57.2857 294.73 53.3003 297.387 52.3514 298.146C51.4025 298.716 46.8477 302.132 44.1908 299.475C43.6214 298.905 43.4317 298.146 43.8113 297.197Z"
            fill="#D1BDBD"
          />
          <path
            d="M49.6944 289.986C49.6944 289.986 50.833 290.365 51.7819 291.504C52.5411 292.453 53.1104 293.212 53.1104 293.212L52.3513 293.781C52.3513 293.781 50.833 292.263 50.2637 291.694C50.0739 291.504 49.125 290.935 49.125 290.935L49.6944 289.986Z"
            fill="#D1BDBD"
          />
          <path
            d="M48.1761 291.884C48.1761 291.884 49.3148 292.263 50.2637 293.592C51.0228 294.541 51.4024 294.92 51.4024 294.92L50.6433 295.679C50.6433 295.679 49.3148 294.351 48.7455 293.592C48.5557 293.402 47.417 292.643 47.417 292.643L48.1761 291.884Z"
            fill="#D1BDBD"
          />
          <path
            d="M67.9132 295.489C67.9132 295.489 65.8256 298.526 63.9278 300.614C61.8402 303.081 60.8913 303.65 60.3219 304.978C59.7526 306.307 62.2198 309.533 66.3949 307.066C70.5701 304.599 71.3292 304.03 72.6577 302.701C74.5555 300.993 76.4533 299.854 76.6431 297.387C76.8329 295.3 75.3147 293.971 75.3147 293.971C75.3147 293.971 74.9351 295.489 72.468 296.818C70.5702 297.957 67.9132 295.489 67.9132 295.489Z"
            fill="white"
          />
          <path
            d="M60.322 304.978C60.322 304.978 63.1687 306.687 63.3585 306.876C65.8257 308.015 68.1031 305.358 70.3804 303.84C72.6578 302.322 73.9863 300.614 74.9352 299.095C75.8841 297.387 76.6432 296.628 76.6432 296.628C76.6432 296.628 77.9717 299.854 76.0739 300.993C73.7965 302.511 69.8111 305.168 68.8622 305.927C67.9132 306.497 63.3585 309.913 60.7016 307.256C60.1322 306.687 60.1322 305.927 60.322 304.978Z"
            fill="#D1BDBD"
          />
          <path
            d="M66.395 297.767C66.395 297.767 67.5337 298.146 68.4826 299.285C69.2417 300.234 69.811 300.993 69.811 300.993L69.0519 301.562C69.0519 301.562 67.5337 300.044 66.9643 299.475C66.7745 299.285 65.8257 298.716 65.8257 298.716L66.395 297.767Z"
            fill="#D1BDBD"
          />
          <path
            d="M64.8771 299.665C64.8771 299.665 66.0158 300.044 66.9647 301.373C67.7238 302.322 67.9136 302.701 67.9136 302.701L67.1545 303.46C67.1545 303.46 65.826 302.132 65.2567 301.373C65.0669 301.183 63.9282 300.424 63.9282 300.424L64.8771 299.665Z"
            fill="#D1BDBD"
          />
          <path
            d="M96.7602 237.416C96.7602 237.416 82.9062 227.358 75.315 232.862C69.6215 236.847 61.0814 246.526 56.3368 249.942C53.8697 251.65 51.5923 255.446 51.5923 260.38C51.5923 263.416 50.4536 286.57 50.4536 286.57C50.4536 286.57 53.3003 288.278 56.1471 288.278C58.9938 288.278 60.7018 285.051 60.7018 285.051C60.7018 285.051 63.7383 268.161 64.1178 259.431C66.7748 255.825 76.2639 250.891 76.2639 250.891C76.2639 250.891 73.6069 253.358 69.8113 256.584C67.3441 258.672 66.0157 262.467 66.2054 266.263C66.3952 272.336 65.6361 293.781 65.6361 293.781C65.6361 293.781 68.293 295.489 71.1398 295.489C73.9865 295.489 77.4025 292.643 77.4025 292.643C77.4025 292.643 79.1106 278.599 78.1617 264.935C79.1106 263.606 89.3588 256.774 92.0157 253.927C99.9865 245.957 96.7602 237.416 96.7602 237.416Z"
            fill="#3371D3"
          />
          <path
            d="M83.665 180.102C83.665 180.102 85.7526 175.737 90.3074 177.635C94.8621 179.533 93.3439 186.555 92.2052 188.643C91.2563 190.73 91.6358 196.613 93.5336 196.803C95.4314 196.993 96.9497 195.095 96.9497 195.095C96.9497 195.095 96.5702 201.168 90.1176 199.84C83.8548 198.511 83.665 180.102 83.665 180.102Z"
            fill="#3D2927"
          />
          <path
            d="M72.0883 216.54C69.4314 215.402 67.3438 213.314 65.6357 210.847C68.1029 206.102 71.519 200.599 73.0372 199.46C75.8839 197.562 78.3511 198.511 78.3511 198.511L72.4679 215.592C72.4679 215.781 72.2781 216.161 72.0883 216.54Z"
            fill="#649FF4"
          />
          <path
            d="M78.1614 197.562C78.1614 197.562 78.1613 200.599 77.7818 202.497C77.4022 203.446 76.4533 208.57 77.2124 210.847C78.3511 210.088 81.3876 205.913 82.3365 204.584C83.4752 203.066 86.7015 200.029 86.7015 200.029C86.7015 200.029 85.1833 199.081 84.8037 196.044C84.2344 191.869 83.665 188.263 83.665 188.263L78.1614 197.562Z"
            fill="#FFA8A6"
          />
          <path
            d="M71.7093 242.161C71.7093 242.161 71.5186 242.161 71.7093 242.161C71.5186 242.352 71.7093 242.352 71.7093 242.161Z"
            fill="#F38282"
          />
          <path
            d="M93.344 223.373C93.344 223.373 96.76 212.365 93.9133 205.723C93.1542 203.635 85.9424 200.029 85.9424 200.029C85.9424 200.029 82.7162 203.446 81.5775 204.584C81.3877 204.774 81.198 204.964 81.0082 205.154C80.249 205.913 77.7819 209.139 76.833 210.088C76.833 208.949 77.4023 204.584 78.1615 201.358C78.3512 200.599 78.7308 198.891 78.541 197.942C78.3512 197.373 78.3512 196.993 78.3512 196.993C78.3512 196.993 77.7819 196.993 77.0228 196.993C76.0738 198.321 74.3658 201.927 73.4169 203.446C72.468 204.964 69.8111 209.329 70.3804 213.694C71.7089 222.234 73.4169 239.314 73.4169 241.212C73.9863 241.592 81.5775 247.095 87.8403 248.044C96.76 249.373 96.5702 242.351 96.5702 242.351C96.5702 242.351 93.7235 226.978 93.344 223.373Z"
            fill="#E2E2E2"
          />
          <path
            d="M86.5118 214.453L80.249 212.555L80.8184 211.037L86.8914 212.935L86.5118 214.453Z"
            fill="white"
          />
          <path
            d="M71.7084 259.051C67.9128 257.343 66.2048 257.533 64.1172 257.154C64.6865 256.584 68.2924 253.548 69.8106 247.854C72.8471 235.519 75.5041 215.592 76.6428 209.708C76.8325 208.38 77.0224 210.847 77.2121 210.088C78.161 209.519 78.7303 208.759 79.2997 208C82.3362 204.205 84.8034 200.599 87.6501 200.599C89.1684 200.599 92.964 204.015 92.964 204.015C92.964 204.015 94.672 213.124 94.672 216.161C94.8618 225.65 97.329 233.241 99.037 237.416C100.555 241.781 99.9859 248.044 95.0516 255.256C93.7231 257.154 85.7523 266.643 81.5771 268.92C79.6793 263.416 76.6428 261.329 71.7084 259.051Z"
            fill="#99C7FF"
          />
          <path
            d="M75.8841 238.935C76.0739 240.643 74.7454 247.285 69.6213 250.132C66.0155 252.219 63.9279 253.927 62.4096 256.584C59.1833 254.497 55.1979 251.46 53.1104 251.27C55.1979 248.993 67.7235 240.073 70.3805 234.38C72.0885 230.584 72.2782 226.409 70.9498 218.628C70.0009 213.694 69.2418 212.555 70.1907 208.949C70.9498 205.723 75.5045 199.27 75.5045 199.27L77.5921 200.409C77.5921 200.409 77.4024 205.533 77.0228 210.088C76.833 211.416 76.4534 212.365 76.4534 213.694C76.2636 217.3 75.5045 230.964 75.8841 238.935Z"
            fill="#99C7FF"
          />
          <path
            d="M69.6211 249.183C70.1904 245.387 73.7963 242.351 74.3656 239.884C75.3145 235.329 74.9349 227.738 74.7452 223.562C74.7452 221.095 75.694 212.745 76.4532 209.708C77.0225 210.088 77.5919 210.088 77.5919 210.088C77.5919 210.088 76.2634 216.73 76.0736 222.424C75.8839 226.978 76.4532 236.088 76.2634 237.227C75.3145 241.212 73.2269 247.095 69.6211 249.183Z"
            fill="#649FF4"
          />
          <path
            d="M79.1104 199.27C79.1104 199.27 78.9206 202.307 78.7308 203.825C78.5411 205.343 77.0228 210.088 77.0228 210.088C77.0228 210.088 75.6943 209.519 75.6943 206.482C75.6943 203.446 76.4534 197.942 76.4534 197.942L79.1104 199.27Z"
            fill="#649FF4"
          />
          <path
            d="M87.2703 248.993H86.3214L64.4966 236.278C63.3579 235.519 61.2703 235.708 59.9418 236.467L45.1388 245.008C44.9491 245.197 44.7593 245.197 44.7593 245.387H44.1899V246.716C44.1899 247.095 44.3798 247.475 44.9491 247.854L66.9637 260.57C68.1024 261.329 70.19 261.139 71.5185 260.38L86.3214 251.84C87.0805 251.46 87.4601 250.891 87.4601 250.322V248.993H87.2703Z"
            fill="#EA81C0"
          />
          <path
            d="M66.9625 259.431L44.9479 246.716C43.8092 245.957 43.999 244.818 45.3274 244.059L60.1304 235.519C61.4589 234.759 63.5464 234.57 64.6851 235.329L86.6998 248.044C87.8385 248.803 87.6487 249.942 86.3202 250.701L71.5173 259.241C70.1888 260 68.1012 260 66.9625 259.431Z"
            fill="#FFB0D8"
          />
          <path
            d="M62.5987 235.708L47.0366 244.628L70.19 257.913L85.752 248.993L62.5987 235.708Z"
            fill="#EA81C0"
          />
          <path
            d="M61.27 235.519L60.1313 236.278L83.2846 249.562L84.4233 248.803L61.27 235.519Z"
            fill="white"
          />
          <path
            d="M78.9199 248.044L77.7812 248.613L81.3871 250.701L82.5258 249.942L78.9199 248.044Z"
            fill="white"
          />
          <path
            d="M75.1241 245.767L73.9854 246.526L76.4525 247.854L77.5912 247.285L75.1241 245.767Z"
            fill="white"
          />
          <path
            d="M72.0879 244.059L70.9492 244.628L73.4164 246.146L74.5551 245.387L72.0879 244.059Z"
            fill="white"
          />
          <path
            d="M69.0511 242.351L67.7227 242.92L70.1898 244.438L71.5183 243.679L69.0511 242.351Z"
            fill="white"
          />
          <path
            d="M76.2632 251.27L75.1245 252.03L76.8325 252.978L78.161 252.409L76.2632 251.27Z"
            fill="white"
          />
          <path
            d="M73.7959 249.752L72.6572 250.511L74.3653 251.46L75.6937 250.891L73.7959 249.752Z"
            fill="white"
          />
          <path
            d="M70.7596 248.044L69.4312 248.803L71.8983 250.132L73.2268 249.562L70.7596 248.044Z"
            fill="white"
          />
          <path
            d="M74.3651 252.409L73.0366 253.168L74.9344 254.117L76.0731 253.548L74.3651 252.409Z"
            fill="white"
          />
          <path
            d="M71.708 251.081L70.5693 251.65L72.2774 252.789L73.6058 252.03L71.708 251.081Z"
            fill="white"
          />
          <path
            d="M72.0879 253.548L70.9492 254.307L72.6572 255.256L73.9857 254.686L72.0879 253.548Z"
            fill="white"
          />
          <path
            d="M69.6206 252.219L68.4819 252.789L70.19 253.927L71.3287 253.168L69.6206 252.219Z"
            fill="white"
          />
          <path
            d="M68.6719 249.183L67.5332 249.942L70.0003 251.46L71.139 250.701L68.6719 249.183Z"
            fill="white"
          />
          <path
            d="M67.7227 246.336L66.584 247.095L69.0511 248.424L70.1898 247.854L67.7227 246.336Z"
            fill="white"
          />
          <path
            d="M64.6861 244.628L63.5474 245.197L66.0145 246.716L67.1532 245.957L64.6861 244.628Z"
            fill="white"
          />
          <path
            d="M76.2632 248.803L75.1245 249.562L79.1099 251.84L80.4384 251.081L76.2632 248.803Z"
            fill="white"
          />
          <path
            d="M73.0365 246.905L71.708 247.665L74.1752 248.993L75.5036 248.424L73.0365 246.905Z"
            fill="white"
          />
          <path
            d="M70.0003 245.197L68.6719 245.767L71.139 247.285L72.4675 246.526L70.0003 245.197Z"
            fill="white"
          />
          <path
            d="M66.774 243.489L65.6353 244.059L68.1024 245.577L69.4309 244.818L66.774 243.489Z"
            fill="white"
          />
          <path
            d="M76.6426 237.037C76.6426 237.037 70.1901 239.124 68.1025 240.643C65.8251 242.161 62.0295 245.767 67.5332 246.716C73.2266 247.665 78.1609 241.592 78.5404 241.212C78.5404 238.935 76.6426 237.037 76.6426 237.037Z"
            fill="#FFC3C2"
          />
          <path
            d="M70.7594 256.964V238.176C70.7594 237.606 70.3799 236.847 69.8105 236.657L47.6061 223.942C47.0368 223.562 46.2777 223.562 45.7083 223.942L44.9492 224.322H45.3287C45.139 224.701 44.9492 224.891 44.9492 225.27V244.059C44.9492 244.628 45.3288 245.387 45.8981 245.577L68.1025 258.292C68.2923 258.482 68.4821 258.482 68.6719 258.482L69.0514 258.862L70.1901 258.103C70.3799 258.103 70.7594 257.533 70.7594 256.964Z"
            fill="#F495C9"
          />
          <path
            d="M69.8107 238.745V257.533C69.8107 258.862 68.2925 259.811 67.1538 259.051L44.9494 246.336C44.3801 245.957 44.0005 245.387 44.0005 244.818V226.029C44.0005 224.701 45.5187 223.752 46.6574 224.511L68.8618 237.227C69.4311 237.416 69.8107 238.175 69.8107 238.745Z"
            fill="#FFB0D8"
          />
          <path
            d="M51.0225 251.27L45.8984 248.424V246.146L51.0225 248.993V251.27Z"
            fill="#F495C9"
          />
          <path
            d="M65.2559 259.621L60.1318 256.584V254.307L65.2559 257.343V259.621Z"
            fill="#F495C9"
          />
          <path
            d="M58.0439 242.351C58.0439 243.489 57.0949 243.679 55.9563 243.11C54.8176 242.351 53.8687 241.022 53.8687 239.884C53.8687 238.745 54.8176 238.555 55.9563 239.124C57.0949 239.884 58.0439 241.212 58.0439 242.351Z"
            fill="white"
          />
          <path
            d="M98.0882 217.869C98.0882 217.869 97.8984 217.489 98.0882 218.248C98.278 219.387 97.8984 221.095 97.7086 223.942C97.5189 226.03 97.5188 229.256 97.3291 230.774C96.9495 232.292 95.8108 232.672 93.1539 234.57C90.497 236.467 78.5407 241.212 78.5407 241.212L75.8838 237.606C75.8838 237.606 83.0955 232.103 84.2342 231.343C85.3728 230.584 88.5992 228.117 88.5992 228.117L89.1685 218.059L98.0882 217.869Z"
            fill="#FFC3C2"
          />
          <path
            d="M88.5995 222.044C88.2199 216.73 88.4097 209.898 89.1688 208.38C90.3075 206.292 91.8257 204.205 93.1542 204.584C96.0009 205.343 97.7089 207.051 98.468 211.606C99.0374 214.643 99.0374 218.628 99.0374 221.475C95.8111 223.183 92.0155 223.183 88.5995 222.044Z"
            fill="#649FF4"
          />
          <path
            d="M85.3728 198.701C85.3728 198.701 88.2195 199.46 88.0298 200.978C87.84 203.256 86.8911 205.343 83.2852 207.811C79.8692 210.278 77.0225 210.088 77.0225 210.088C77.0225 210.088 79.6794 206.292 82.1465 203.825C84.8035 201.358 85.3728 198.701 85.3728 198.701Z"
            fill="#649FF4"
          />
          <path
            d="M83.665 180.672C83.665 180.672 86.7015 175.927 89.3585 178.964C92.2052 182 88.4096 184.278 88.4096 184.278L83.665 180.672Z"
            fill="#649FF4"
          />
          <path
            d="M83.8544 183.139C81.1975 181.051 74.9346 180.482 72.6573 186.935C70.1901 193.767 76.4529 202.117 79.6792 200.789C81.577 200.029 84.6135 198.701 85.7522 195.285C86.8909 191.3 85.7522 184.847 83.8544 183.139Z"
            fill="#FFC3C2"
          />
          <path
            d="M86.5116 180.862C85.9423 179.723 84.424 178.774 83.4751 178.394C80.6284 177.256 77.0226 178.774 75.1248 180.292C71.1394 183.329 70.7598 188.073 70.7598 190.161C70.7598 190.161 73.9861 191.489 76.2635 190.351C76.2635 191.489 77.7817 193.008 78.351 193.387C80.4386 194.716 82.716 193.197 84.0445 193.387C84.9934 193.577 85.3729 194.716 85.3729 195.854C86.3219 194.905 88.0299 193.197 89.1686 190.54C90.497 187.883 91.446 183.139 86.5116 180.862Z"
            fill="#33211F"
          />
          <path
            d="M88.4097 345.781V393.606C88.4097 398.541 96.0009 402.716 105.49 402.716C114.979 402.716 122.57 398.73 122.57 393.606V345.781H88.4097Z"
            fill="#E8D6D6"
          />
          <path
            d="M105.49 365.139C96.0009 365.139 88.4097 361.154 88.4097 356.03V386.584C88.4097 391.519 96.0009 395.694 105.49 395.694C114.979 395.694 122.57 391.708 122.57 386.584V356.03C122.57 361.154 114.979 365.139 105.49 365.139Z"
            fill="#9165D1"
          />
          <path
            d="M105.49 369.504C96.0009 369.504 88.4097 365.519 88.4097 360.395V382.789C88.4097 387.723 96.0009 391.898 105.49 391.898C114.979 391.898 122.57 387.913 122.57 382.789V360.395C122.57 365.329 114.979 369.504 105.49 369.504Z"
            fill="#B292EF"
          />
          <path
            d="M117.826 366.657C114.789 368.365 110.235 369.504 105.49 369.504C96.0009 369.504 88.4097 365.519 88.4097 360.395V364.57C88.4097 369.504 96.0009 373.679 105.49 373.679C110.424 373.679 114.789 372.541 117.826 370.833V366.657Z"
            fill="#33211F"
          />
          <path
            d="M122.57 345.781C122.57 350.716 114.979 354.891 105.49 354.891C96.0009 354.891 88.4097 350.906 88.4097 345.781C88.4097 340.847 96.0009 336.672 105.49 336.672C114.979 336.862 122.57 340.847 122.57 345.781Z"
            fill="#F4EFEF"
          />
          <path
            d="M97.1396 332.117V347.869C97.8988 348.249 98.6579 348.628 99.6068 348.818V332.497H101.694V349.387C102.454 349.577 103.402 349.577 104.351 349.577V332.497H106.439V349.577C107.388 349.577 108.147 349.387 108.906 349.387V332.497H110.994V348.818C111.943 348.628 112.702 348.249 113.651 347.869V332.117H97.1396Z"
            fill="#A179E0"
          />
          <path
            d="M93.5337 332.118V343.125C93.5337 344.264 94.1031 345.403 95.2418 346.352V332.118H93.5337Z"
            fill="#A179E0"
          />
          <path
            d="M117.446 343.125C117.446 342.935 117.446 342.745 117.446 342.555V332.117H115.928V346.351C116.877 345.402 117.446 344.453 117.446 343.125Z"
            fill="#A179E0"
          />
          <path
            d="M95.0522 332.117V346.351C95.6216 346.92 96.3807 347.3 97.1399 347.679V331.927H95.0522V332.117Z"
            fill="#448CE2"
          />
          <path
            d="M95.0522 332.117V346.351C95.6216 346.92 96.3807 347.3 97.1399 347.679V331.927H95.0522V332.117Z"
            fill="#9165D1"
          />
          <path
            d="M99.7969 332.497V348.818C100.366 349.008 101.125 349.198 101.884 349.387V332.497H99.7969Z"
            fill="#448CE2"
          />
          <path
            d="M99.7969 332.497V348.818C100.366 349.008 101.125 349.198 101.884 349.387V332.497H99.7969Z"
            fill="#9165D1"
          />
          <path
            d="M104.542 332.497V349.577C104.921 349.577 105.111 349.577 105.49 349.577C105.87 349.577 106.25 349.577 106.629 349.577V332.497H104.542Z"
            fill="#448CE2"
          />
          <path
            d="M104.542 332.497V349.577C104.921 349.577 105.111 349.577 105.49 349.577C105.87 349.577 106.25 349.577 106.629 349.577V332.497H104.542Z"
            fill="#9165D1"
          />
          <path
            d="M109.096 332.497V349.387C109.855 349.198 110.614 349.198 111.183 349.008V332.687H109.096V332.497Z"
            fill="#448CE2"
          />
          <path
            d="M109.096 332.497V349.387C109.855 349.198 110.614 349.198 111.183 349.008V332.687H109.096V332.497Z"
            fill="#9165D1"
          />
          <path
            d="M113.84 332.117V347.869C114.599 347.49 115.359 346.92 115.928 346.541V332.307H113.84V332.117Z"
            fill="#448CE2"
          />
          <path
            d="M113.84 332.117V347.869C114.599 347.49 115.359 346.92 115.928 346.541V332.307H113.84V332.117Z"
            fill="#9165D1"
          />
          <path
            d="M117.446 332.117C117.446 335.723 112.132 338.57 105.49 338.57C98.8476 338.57 93.5337 335.723 93.5337 332.117C93.5337 328.511 98.8476 325.665 105.49 325.665C112.132 325.665 117.446 328.701 117.446 332.117Z"
            fill="#B292EF"
          />
          <path
            d="M114.41 330.789L110.994 314.468H99.9863L96.5702 330.789C96.5702 330.979 96.3804 331.358 96.3804 331.548C96.3804 334.205 100.366 336.482 105.49 336.482C110.614 336.482 114.599 334.395 114.599 331.548C114.599 331.358 114.599 330.979 114.41 330.789Z"
            fill="#9165D1"
          />
          <path
            d="M110.994 314.468C110.994 316.176 108.526 317.314 105.49 317.314C102.453 317.314 99.9863 315.986 99.9863 314.468C99.9863 312.76 102.453 311.621 105.49 311.621C108.526 311.431 110.994 312.76 110.994 314.468Z"
            fill="#B292EF"
          />
          <path
            d="M108.716 313.898C108.716 314.847 107.198 315.606 105.49 315.606C103.782 315.606 102.264 314.847 102.264 313.898C102.264 312.949 103.782 312.19 105.49 312.19C107.198 312.19 108.716 312.949 108.716 313.898Z"
            fill="#A179E0"
          />
          <path
            d="M313.11 193.197L322.599 187.883C325.066 186.365 325.826 188.073 326.774 189.971L335.125 205.913C336.074 207.811 336.833 208.949 334.745 210.278L325.636 215.592C324.118 216.54 323.169 216.73 322.22 214.832L313.49 198.321C312.541 196.234 311.212 194.336 313.11 193.197Z"
            fill="#2143AF"
          />
          <path
            d="M313.87 192.628L323.359 187.314C325.826 185.796 326.585 187.504 327.534 189.402L335.884 205.343C336.833 207.241 337.592 208.38 335.505 209.708L326.395 215.022C324.877 215.971 323.928 216.161 322.979 214.263L314.249 197.752C313.3 195.854 311.782 193.956 313.87 192.628Z"
            fill="#3371D3"
          />
          <path
            d="M335.125 206.482L323.928 212.935L313.87 194.336L325.257 187.883L335.125 206.482Z"
            fill="#33211F"
          />
          <path
            d="M331.899 210.467L329.242 211.986L328.862 211.037L331.329 209.519L331.899 210.467Z"
            fill="white"
          />
          <path
            d="M325.636 193.008C326.206 193.197 326.016 193.387 326.206 194.905C326.585 196.234 326.585 196.234 326.585 196.234L326.016 196.613C326.016 196.613 325.446 195.854 325.446 194.905C325.067 193.008 325.636 193.008 325.636 193.008Z"
            fill="#9A9AD6"
          />
          <path
            d="M326.775 196.993C326.775 196.993 327.344 198.321 327.724 199.081C327.344 199.081 326.964 199.081 326.964 199.081L326.395 197.373L326.775 196.993Z"
            fill="#9A9AD6"
          />
          <path
            d="M322.599 192.248C322.979 192.628 322.979 192.818 323.358 193.577C323.548 194.336 323.738 194.905 323.738 194.905H323.169C323.169 194.905 322.599 193.577 322.599 193.197C322.41 192.628 322.41 192.059 322.599 192.248Z"
            fill="#9A9AD6"
          />
          <path
            d="M324.118 195.285L325.067 197.183L324.307 197.562L323.548 195.285H324.118Z"
            fill="#9A9AD6"
          />
          <path
            d="M325.446 197.752L326.205 199.27L325.446 199.65L324.687 198.132L325.446 197.752Z"
            fill="#9A9AD6"
          />
          <path
            d="M320.701 192.818C321.081 192.438 321.271 193.197 321.461 193.767C321.65 194.336 322.03 195.095 322.03 195.095L321.461 195.664C321.461 195.664 321.081 194.526 320.701 193.956C320.512 193.197 320.512 193.008 320.701 192.818Z"
            fill="#9A9AD6"
          />
          <path
            d="M328.103 199.46C328.103 199.46 328.672 200.789 329.052 201.548C329.431 202.307 330.001 204.015 329.621 204.584C329.242 205.343 328.482 206.482 327.344 206.672C326.205 207.051 324.877 205.913 324.117 204.964C323.358 203.825 322.789 202.497 322.789 202.497C322.789 202.497 323.358 201.358 323.928 200.978C324.497 200.599 325.256 200.029 326.205 199.84C327.344 199.65 328.103 199.46 328.103 199.46Z"
            fill="#9A9AD6"
          />
          <path
            d="M322.22 195.665L323.169 197.562L322.599 198.132L321.65 196.234L322.22 195.665Z"
            fill="#9A9AD6"
          />
          <path
            d="M323.548 198.321L324.307 199.84L323.738 200.409L322.789 198.701L323.548 198.321Z"
            fill="#9A9AD6"
          />
          <path
            d="M320.701 196.234C320.701 196.234 320.132 194.716 319.942 194.526C319.753 194.336 319.183 194.146 319.563 194.905C319.942 195.664 320.322 196.803 320.322 196.803L320.701 196.234Z"
            fill="#9A9AD6"
          />
          <path
            d="M320.891 196.993L321.84 198.511L321.271 199.081L320.512 197.373L320.891 196.993Z"
            fill="#9A9AD6"
          />
          <path
            d="M322.22 199.46L323.169 200.789L322.599 201.358L321.65 199.84L322.22 199.46Z"
            fill="#9A9AD6"
          />
          <path
            d="M322.79 203.256L320.512 201.737L319.943 202.307C319.943 202.307 320.892 203.256 321.461 203.635C322.03 204.015 323.169 204.584 323.169 204.584L322.79 203.256Z"
            fill="#9A9AD6"
          />
          <path
            d="M319.943 201.168C319.943 201.168 319.753 200.599 319.183 200.409C318.804 200.219 318.424 200.409 318.424 200.409L319.183 201.548L319.943 201.168Z"
            fill="#9A9AD6"
          />
          <path
            d="M371.184 222.234C371.184 222.234 369.286 224.321 363.403 221.285C356.57 217.869 352.585 217.11 352.585 217.11L353.724 211.986L365.111 212.555L371.184 222.234Z"
            fill="#649FF4"
          />
          <path
            d="M355.432 192.628C355.432 192.628 358.278 192.628 361.125 195.095C363.972 197.562 370.614 211.416 371.563 213.314C372.512 215.022 375.169 219.008 371.373 222.044C368.337 224.701 364.541 218.818 363.402 216.73C361.884 214.073 357.709 206.102 357.709 206.102L355.432 192.628Z"
            fill="#649FF4"
          />
          <path
            d="M350.308 308.774C347.841 307.635 345.563 306.117 346.322 304.789C346.702 304.219 347.461 303.84 348.22 303.84C350.497 304.599 351.636 304.979 351.636 304.979C351.636 304.979 351.446 292.073 350.497 286.76C350.118 285.052 358.658 284.862 358.658 284.862C359.038 286 359.227 297.008 358.658 303.46C358.468 305.738 358.658 307.066 358.658 308.205C358.089 308.395 356.95 308.964 356.001 309.344C354.862 309.913 352.775 309.913 350.308 308.774Z"
            fill="#FFC3C2"
          />
          <path
            d="M358.658 311.811C357.899 313.708 356.001 313.519 352.205 312.19C348.789 311.052 346.132 309.723 343.665 307.825C341.388 306.117 341.198 305.927 341.008 304.599C340.629 302.891 342.906 301.942 347.081 303.271C347.461 303.46 347.84 303.46 348.22 303.65C347.461 303.84 346.702 304.03 346.322 304.599C345.563 305.927 347.84 307.446 350.308 308.584C352.775 309.723 354.672 309.723 355.811 309.154C356.76 308.774 357.899 308.205 358.468 308.015C358.848 309.913 359.227 310.672 358.658 311.811Z"
            fill="#33211F"
          />
          <path
            d="M347.081 318.643C346.322 320.541 344.424 320.351 340.629 319.022C337.213 317.884 334.556 316.555 332.089 314.657C329.811 312.949 329.621 312.76 329.432 311.431C329.052 309.723 331.329 308.774 335.505 310.103C335.884 310.292 336.264 310.292 336.643 310.482C335.884 310.672 335.125 310.862 334.745 311.431C333.986 312.76 336.264 314.278 338.731 315.416C341.198 316.555 343.096 316.555 344.234 315.986C345.183 315.606 346.322 315.037 346.891 314.847C347.081 316.745 347.461 317.504 347.081 318.643Z"
            fill="#33211F"
          />
          <path
            d="M334.745 311.621C335.125 311.052 335.884 310.672 336.643 310.672C338.92 311.431 340.059 311.811 340.059 311.811C340.059 311.811 339.49 301.373 338.731 293.402C337.592 286.38 336.453 277.84 336.643 273.475C336.643 269.489 336.074 256.964 337.212 248.044C339.49 248.613 341.767 248.993 344.044 249.373C349.738 250.132 356.19 250.701 361.884 248.803C361.884 249.183 362.074 249.752 362.074 250.132C362.453 252.789 360.935 255.825 360.555 259.431C359.986 264.176 360.176 267.781 359.796 271.767C358.847 282.395 358.847 290.365 358.847 290.365C358.847 290.365 355.431 293.781 350.877 291.124C349.928 286.57 349.928 275.183 349.928 272.716C349.738 270.249 349.928 256.964 349.928 256.964C349.928 256.964 346.701 272.526 346.891 275.562C347.081 279.927 346.322 286.76 346.701 291.124C346.891 296.438 347.081 305.548 346.701 310.292C346.512 312.57 346.701 313.898 346.701 315.037C346.132 315.227 344.993 315.796 344.044 316.176C342.906 316.745 340.818 316.745 338.541 315.606C336.263 314.468 333.986 312.949 334.745 311.621Z"
            fill="#FFC3C2"
          />
          <path
            d="M346.132 171.942C345.183 172.511 341.767 173.27 340.818 180.862C340.249 185.796 341.767 188.073 343.285 189.402C343.855 189.971 346.132 189.971 346.132 189.971L345.942 195.664C345.942 195.664 349.169 196.993 352.395 195.664C355.621 194.336 355.621 194.336 355.621 194.336C355.621 194.336 354.482 192.248 354.672 188.453C354.862 183.898 356.57 177.445 355.431 174.978C353.344 170.613 348.599 170.234 346.132 171.942Z"
            fill="#FFC3C2"
          />
          <path
            d="M346.132 194.146C346.132 194.146 342.336 195.665 341.198 198.321C340.059 200.978 335.884 208.19 337.592 212.175C341.388 220.336 338.92 226.03 338.351 227.358C331.329 245.387 332.847 270.628 332.847 270.628C332.847 270.628 337.971 279.738 347.271 276.701C356.57 273.665 363.592 267.971 363.592 267.971C363.592 267.971 364.351 239.124 362.263 233.811C359.227 225.84 359.796 221.475 359.986 219.767C360.745 213.124 360.555 211.037 359.796 205.723C359.037 200.599 355.811 194.526 355.811 194.526L346.132 194.146Z"
            fill="#99C7FF"
          />
          <path
            d="M356.57 193.767C356.57 193.767 356.001 195.285 352.964 196.424C349.738 197.373 346.891 197.183 343.665 195.665C343.665 193.577 346.132 192.628 346.132 192.628C346.132 192.628 348.979 193.767 352.205 192.628C355.431 191.489 355.431 191.489 355.431 191.489L356.57 193.767Z"
            fill="#649FF4"
          />
          <path
            d="M340.628 178.205C340.818 180.862 342.906 181.621 344.804 183.519C346.701 185.416 347.65 187.314 351.826 190.351C353.344 191.489 352.964 199.65 355.811 205.533C358.847 211.796 357.139 212.935 357.139 212.935C357.139 212.935 365.869 210.088 362.264 200.409C360.935 196.803 356.001 193.956 355.811 190.161C355.811 188.453 358.088 186.745 358.847 183.519C361.125 173.27 356.38 170.424 352.015 169.475C343.855 167.767 340.059 172.891 340.628 178.205Z"
            fill="#33211F"
          />
          <path
            d="M342.716 181.81C342.716 181.81 344.614 178.394 345.563 181.81C346.322 185.037 343.665 185.986 343.665 185.986L342.716 181.81Z"
            fill="#FFC3C2"
          />
          <path
            d="M356.57 188.263C356.57 188.263 356.76 190.351 356.191 190.92C355.621 191.489 353.344 192.248 352.585 192.059C351.826 191.869 350.877 190.351 350.687 189.591C353.534 189.212 356.57 188.263 356.57 188.263Z"
            fill="#649FF4"
          />
          <path
            d="M323.928 211.606C323.928 211.606 322.22 208 321.081 206.102C319.942 204.205 319.752 203.446 319.183 202.686C318.803 202.117 317.855 203.066 318.424 204.964C319.183 206.862 319.183 206.862 319.183 206.862C319.183 206.862 316.716 204.584 315.767 202.497C314.059 203.635 313.869 208 315.957 210.278C318.234 212.555 320.322 214.832 320.322 214.832L323.928 211.606Z"
            fill="#FFC3C2"
          />
          <path
            d="M342.716 197.373C344.614 196.803 346.702 201.548 346.132 206.292C345.563 210.467 341.767 216.54 339.49 220.146C338.162 222.234 334.366 226.409 330.76 225.65C324.877 224.511 318.424 215.022 318.424 215.022C318.424 215.022 318.424 211.037 323.169 209.329C326.964 212.555 330.191 215.022 330.191 215.022C330.191 215.022 335.505 199.84 342.716 197.373Z"
            fill="#649FF4"
          />
          <path
            d="M32.6137 375.577V369.125H8.13184V375.577C8.13184 375.767 8.13184 375.767 8.13184 375.957C8.5114 379.373 13.8253 382.03 20.2779 382.03C27.11 382.03 32.6137 379.183 32.6137 375.577Z"
            fill="#9165D1"
          />
          <path
            d="M20.2779 362.482C13.4458 362.482 8.13184 365.329 8.13184 368.935C8.13184 369.125 8.13184 369.125 8.13184 369.314C8.5114 372.73 13.8253 375.387 20.2779 375.387C27.11 375.387 32.4239 372.541 32.4239 368.935C32.6137 365.329 27.11 362.482 20.2779 362.482Z"
            fill="#B292EF"
          />
          <path
            d="M27.6795 374.059L11.1685 364.57C11.1685 364.57 11.7378 364.19 12.1174 364C12.4969 363.811 13.0663 363.621 13.0663 363.621L29.5773 373.11C29.5773 373.11 29.0079 373.49 28.8182 373.49C28.2488 373.869 27.6795 374.059 27.6795 374.059Z"
            fill="#9165D1"
          />
          <path
            d="M77.2123 366.088V359.636H52.7305V366.088C52.7305 366.278 52.7305 366.278 52.7305 366.468C53.11 369.884 58.424 372.541 64.8765 372.541C71.7086 372.541 77.2123 369.694 77.2123 366.088Z"
            fill="#9165D1"
          />
          <path
            d="M64.8765 352.993C58.0444 352.993 52.7305 355.84 52.7305 359.446C52.7305 359.636 52.7305 359.636 52.7305 359.825C53.11 363.241 58.424 365.898 64.8765 365.898C71.7086 365.898 77.0225 363.052 77.0225 359.446C77.2123 355.84 71.7086 352.993 64.8765 352.993Z"
            fill="#B292EF"
          />
          <path
            d="M72.2781 364.57L55.7671 355.081C55.7671 355.081 56.3364 354.701 56.716 354.511C57.0956 354.322 57.6649 354.132 57.6649 354.132L74.1759 363.621C74.1759 363.621 73.6066 364.001 73.4168 364.001C72.8474 364.38 72.2781 364.57 72.2781 364.57Z"
            fill="#9165D1"
          />
          <path
            d="M68.8617 381.271V374.818H44.3799V381.271C44.3799 381.46 44.3799 381.46 44.3799 381.65C44.7594 385.066 50.0733 387.723 56.5259 387.723C63.358 387.723 68.8617 384.876 68.8617 381.271Z"
            fill="#9165D1"
          />
          <path
            d="M56.7168 368.176C49.8847 368.176 44.5708 371.022 44.5708 374.628C44.5708 374.818 44.5708 374.818 44.5708 375.008C44.9504 378.424 50.2642 381.081 56.7168 381.081C63.5489 381.081 68.8628 378.234 68.8628 374.628C68.8628 371.022 63.3591 368.176 56.7168 368.176Z"
            fill="#B292EF"
          />
          <path
            d="M63.9275 379.752L47.4165 370.263C47.4165 370.263 47.9858 369.884 48.3654 369.694C48.745 369.504 49.3143 369.314 49.3143 369.314L65.8253 378.803C65.8253 378.803 65.2559 379.183 65.0661 379.183C64.6866 379.563 63.9275 379.752 63.9275 379.752Z"
            fill="#9165D1"
          />
          <path
            d="M41.9127 156.57C40.0149 153.343 36.0295 152.205 32.8032 154.102L20.0879 161.504C20.0879 161.504 23.6937 169.095 25.7813 173.46L39.4455 165.679C42.6718 163.781 43.8106 159.796 41.9127 156.57Z"
            fill="#FF69A6"
          />
          <path
            d="M27.2997 170.044C27.2997 166.818 25.2121 163.212 22.9347 161.694C21.796 160.935 20.8471 160.935 20.088 161.314L4.71569 170.234C1.48941 172.132 0.350743 176.117 2.24856 179.343C4.14637 182.57 8.13175 183.708 11.358 181.81C11.358 181.81 25.5916 173.65 25.7814 173.46C26.9201 173.081 27.2997 171.752 27.2997 170.044Z"
            fill="#9165D1"
          />
          <path
            d="M13.2559 178.774C15.1537 175.548 19.1391 174.409 22.3654 176.307L35.0807 183.708C35.0807 183.708 31.4749 191.3 29.3873 195.665L15.723 187.883C12.4967 185.986 11.3581 182 13.2559 178.774Z"
            fill="#FF69A6"
          />
          <path
            d="M27.8691 192.248C27.8691 189.022 29.9567 185.416 32.2341 183.898C33.3728 183.139 34.3217 183.139 35.0808 183.519L50.4531 192.438C53.6794 194.336 54.818 198.321 52.9202 201.548C51.0224 204.774 47.037 205.913 43.8108 204.015C43.8108 204.015 29.5771 195.854 29.3874 195.665C28.2487 195.095 27.8691 193.956 27.8691 192.248Z"
            fill="#9165D1"
          />
          <path
            d="M381.432 267.971V325.854C381.432 332.497 391.49 337.811 404.016 337.811C416.541 337.811 426.6 332.497 426.6 325.854V267.971H381.432Z"
            fill="#D1BDBD"
          />
          <path
            d="M381.432 267.971C381.432 274.614 391.49 279.927 404.016 279.927C416.541 279.927 426.6 274.614 426.6 267.971C426.6 261.329 416.541 256.015 404.016 256.015C391.49 255.825 381.432 261.329 381.432 267.971Z"
            fill="#E8D6D6"
          />
          <path
            d="M385.417 267.971C385.417 273.475 393.767 277.84 404.016 277.84C414.264 277.84 422.614 273.475 422.614 267.971C422.614 262.467 414.264 258.103 404.016 258.103C393.767 257.913 385.417 262.467 385.417 267.971Z"
            fill="#D1BDBD"
          />
          <path
            d="M389.402 267.971C389.402 272.336 396.045 275.752 404.205 275.752C412.366 275.752 419.008 272.336 419.008 267.971C419.008 263.606 412.366 260.19 404.205 260.19C396.045 260 389.402 263.606 389.402 267.971Z"
            fill="#E8D6D6"
          />
          <path
            d="M393.958 267.971C393.958 271.008 398.512 273.285 404.016 273.285C409.52 273.285 414.074 270.818 414.074 267.971C414.074 264.935 409.52 262.657 404.016 262.657C398.512 262.468 393.958 264.935 393.958 267.971Z"
            fill="#D1BDBD"
          />
          <path
            d="M397.942 267.971C397.942 269.679 400.599 271.197 404.015 271.197C407.431 271.197 410.088 269.679 410.088 267.971C410.088 266.263 407.431 264.745 404.015 264.745C400.789 264.745 397.942 266.073 397.942 267.971Z"
            fill="#E8D6D6"
          />
          <path
            d="M67.1534 114.059C57.2848 114.059 49.314 109.883 49.314 104.569V145.942C49.314 151.256 57.2848 155.431 67.1534 155.431C77.022 155.431 84.9928 151.256 84.9928 145.942V104.569C84.9928 109.883 77.022 114.059 67.1534 114.059Z"
            fill="#D1BDBD"
          />
          <path
            d="M67.1534 95.0804C57.2848 95.0804 49.314 99.2556 49.314 104.569C49.314 109.883 57.2848 114.059 67.1534 114.059C77.022 114.059 84.9928 109.883 84.9928 104.569C84.9928 99.2556 77.022 95.0804 67.1534 95.0804Z"
            fill="#F4E6E6"
          />
          <path
            opacity="0.7"
            d="M67.1539 106.467C56.1466 106.467 47.0371 101.723 47.0371 95.8396V147.081C47.0371 152.964 55.9568 157.708 67.1539 157.708C78.1613 157.708 87.2708 152.964 87.2708 147.081V95.8396C87.2708 101.723 78.351 106.467 67.1539 106.467Z"
            fill="#E8D6D6"
          />
          <path
            opacity="0.5"
            d="M87.2708 95.46C86.8912 89.7665 78.1613 85.022 67.1539 85.022C56.3364 85.022 47.4167 89.5768 47.0371 95.46C47.0371 95.6498 47.0371 95.6498 47.0371 95.8396C47.0371 101.723 55.9568 106.467 67.1539 106.467C78.1613 106.467 87.2708 101.723 87.2708 95.8396C87.2708 95.8396 87.2708 95.6498 87.2708 95.46Z"
            fill="#E8D6D6"
          />
          <path
            opacity="0.7"
            d="M80.0593 86.5403C79.8695 82.9344 74.176 79.8979 67.1541 79.8979C60.1322 79.8979 54.4388 82.9344 54.249 86.5403V86.7301C54.249 90.5257 59.9424 93.5622 67.1541 93.5622C74.176 93.5622 80.0593 90.5257 80.0593 86.7301C80.0593 86.7301 80.0593 86.73 80.0593 86.5403Z"
            fill="#E8D6D6"
          />
          <path
            d="M67.1539 122.409C56.1466 122.409 47.0371 117.664 47.0371 111.781V130.57C47.0371 136.453 55.9568 141.197 67.1539 141.197C78.1613 141.197 87.2708 136.453 87.2708 130.57V111.781C87.2708 117.664 78.351 122.409 67.1539 122.409Z"
            fill="#698AFF"
          />
          <path
            d="M84.9927 82.7446V82.5549V73.8249H80.438V89.0074C83.2847 87.2994 84.9927 85.2118 84.9927 82.7446Z"
            fill="#BCA4A4"
          />
          <path
            d="M73.2266 73.8249C73.0368 73.8249 72.847 73.8249 72.6572 73.8249V91.8541C75.6937 91.2848 78.3507 90.3359 80.4383 89.1972V74.0147H73.2266V73.8249Z"
            fill="#AF9797"
          />
          <path
            d="M54.6284 73.8249V89.387C55.3875 89.7665 56.1467 90.1461 57.0956 90.5257V73.8249H54.6284Z"
            fill="#BCA4A4"
          />
          <path
            d="M49.5044 73.8249V82.3651V82.5549C49.5044 84.2629 50.4533 85.7812 51.7817 87.2994V73.8249H49.5044Z"
            fill="#BCA4A4"
          />
          <path
            d="M67.154 73.2556C66.0153 73.2556 65.0664 73.2556 63.9277 73.4454V92.0439C64.8766 92.2337 66.0153 92.2337 67.154 92.2337C69.0518 92.2337 70.7599 92.0439 72.4679 91.8541V73.8249C70.9496 73.4454 69.0518 73.2556 67.154 73.2556Z"
            fill="#BCA4A4"
          />
          <path
            d="M51.7817 73.8249V87.4892C52.5409 88.2483 53.4898 89.0074 54.8183 89.5768V73.8249H51.7817Z"
            fill="#BCA4A4"
          />
          <path
            opacity="0.3"
            d="M51.7817 73.8249V87.4892C52.5409 88.2483 53.4898 89.0074 54.8183 89.5768V73.8249H51.7817Z"
            fill="white"
          />
          <path
            d="M61.271 73.8249H57.2856V90.5257C59.3732 91.2848 61.6506 91.8542 64.1177 92.0439V73.4454C63.1688 73.4454 62.2199 73.6351 61.271 73.8249Z"
            fill="#BCA4A4"
          />
          <path
            opacity="0.3"
            d="M61.271 73.8249H57.2856V90.5257C59.3732 91.2848 61.6506 91.8542 64.1177 92.0439V73.4454C63.1688 73.4454 62.2199 73.6351 61.271 73.8249Z"
            fill="white"
          />
          <path
            d="M84.9928 72.876C84.803 67.7519 76.8322 63.7665 67.1534 63.7665C57.4745 63.7665 49.6935 67.9417 49.314 72.876C49.314 73.0658 49.314 73.0658 49.314 73.0658C49.314 78.3797 57.2848 82.5549 67.1534 82.5549C77.022 82.5549 84.9928 78.3797 84.9928 73.0658C84.9928 73.0658 84.9928 73.0658 84.9928 72.876Z"
            fill="#F4E6E6"
          />
          <path
            d="M67.1536 69.0804C71.7083 69.0804 75.504 70.9782 75.8835 73.4454C75.8835 73.2556 76.0733 72.876 76.0733 72.6862C76.0733 72.6862 76.0733 72.6862 76.0733 72.4965C75.8835 70.0293 72.0879 67.9417 67.1536 67.9417C62.409 67.9417 58.4237 70.0293 58.2339 72.4965C58.2339 72.4965 58.2339 72.4964 58.2339 72.6862C58.2339 72.876 58.2339 73.0658 58.2339 73.2556C58.993 70.7884 62.5988 69.0804 67.1536 69.0804Z"
            fill="#AF9797"
          />
          <path
            d="M67.1538 77.4308C71.5188 77.4308 75.3144 75.7227 75.8837 73.4454C75.5042 70.9782 71.7085 69.0804 67.1538 69.0804C62.599 69.0804 58.9932 70.9782 58.4238 73.2556C58.9932 75.5329 62.599 77.4308 67.1538 77.4308Z"
            fill="#D1BDBD"
          />
          <path
            d="M394.716 335.154C388.264 340.278 385.038 345.781 385.038 345.781V363.052C385.038 363.052 388.074 357.548 394.716 352.424C401.169 347.3 412.746 342.935 412.746 342.935V325.665C412.556 325.665 401.169 330.219 394.716 335.154Z"
            fill="#9165D1"
          />
          <path
            d="M447.286 333.256H406.862V350.336C406.862 350.526 406.862 350.906 406.862 351.095C406.862 356.979 415.972 361.913 427.169 361.913C438.366 361.913 447.475 357.168 447.475 351.095C447.475 350.716 447.475 350.526 447.475 350.336V333.256H447.286Z"
            fill="#A179E0"
          />
          <path
            d="M447.286 333.256C447.286 339.139 438.177 344.073 426.979 344.073C415.782 344.073 406.673 339.329 406.673 333.256C406.673 327.373 415.782 322.438 426.979 322.438C438.177 322.438 447.286 327.183 447.286 333.256Z"
            fill="#B292EF"
          />
          <path
            d="M436.658 333.256C436.658 336.103 432.293 338.38 426.979 338.38C421.665 338.38 417.3 336.103 417.3 333.256C417.3 330.409 421.665 328.132 426.979 328.132C432.293 328.132 436.658 330.409 436.658 333.256Z"
            fill="#33211F"
          />
        </svg>
      </div>

      <div class="message-box">
        <h1>404</h1>
        <p>Page not found</p>
        <div class="buttons-con" onClick={() => navigateToDashboardPage()}>
          <div class="action-link-wrap">
            <Link to="/">Return to DashBoard</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
