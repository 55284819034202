import React,{useState,useEffect} from "react";
import "./UploadDischargeHeader.css";
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../../../Store/actions/index';
import Searchbar from "../../../Searchbar/Searchbar";
import refreshFigma from '../../../../AllIcons/refreshFigma.png';
import ButtonWBorder from "../../../Buttons/ButtonWBorder/ButtonWBorder";
const UploadDischargeHeader = (props) => {
  var currentTime = new Date();
  // const [dischargeSummaryActive,setDischargeSummaryActive] = useState(false);
  const [RefreshTime, setRefreshTime] = useState(
    currentTime.toLocaleTimeString()
  );
  const history = useHistory();

  const refreshWardNurseScreen = () => {
    currentTime = new Date();
    setRefreshTime(currentTime.toLocaleTimeString());
    props.fetchWardNurseNameList(true);
  };
  // const navigateToUploadScreen = () =>{
  //   props.setWardNurseRowNumber(-1)
  //   history.push("/ds-upload/fileUpload")
  //   setDischargeSummaryActive(true)
  // }
  return (
    <div className="uploadDischargeHeader">
      <div className="uploadDischargeHeader__name">
        <div className="uploadDischargeHeader__nameText">
          Discharges under Progress
        </div>
        <div className="uploadDischargeHeader__nameListOrderText">
          {/* Showing results in descending order of discharge date */}
        </div>
      </div>
      <div className="uploadDischargeHeader__search">
        <Searchbar placeholder="Search" padding="20px" {...props} />
      </div>
      {/* <div className="uploadDischargeHeader__newDischarge"
      onClick={navigateToUploadScreen}
      >
        <ButtonWBorder
          name="New Discharge"
          width="150px"
          clicked={navigateToUploadScreen}
          dischargeActive={dischargeSummaryActive}
        />
      </div> */}
      <div className="uploadDischargeHeader__updatedItems">
        <div className="uploadDischargeHeader__updateRefresh">
          <div className="uploadDischargeHeader__update">
            <div className="uploadDischargeHeader__updateText">Last updated</div>
            <div className="uploadDischargeHeader__updateTextValue">{RefreshTime}</div>
          </div>
          <div
            className="uploadDischargeHeader__refresh"
            onClick={refreshWardNurseScreen}
          >
            <div className="uploadDischargeHeader__refreshIcon">
              <img src={refreshFigma} className="uploadDischargeHeader__refreshHover" />
            </div>
            <div className="uploadDischargeHeader__refreshText">Refresh</div>
          </div>
        </div>
        <div className="uploadDischargeHeader__update">
          <div className="uploadDischargeHeader__updateText">
            Total current Patients
          </div>
          <div className="uploadDischargeHeader__updateTextValue">
            {props.totalCurrentPatientWardNurse}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWardNurseNameList: (status) => dispatch(actions.fetchingAgain(status)),
    setWardNurseRowNumber:(rowNumber)=>dispatch(actions.setWardNurseRowNumber(rowNumber))
  };
};

export default connect(null, mapDispatchToProps)(UploadDischargeHeader);
