import React from "react";
import "./DPANameBody.css";
import DischargeCapsules from "../../../../Capsule/DischargeCapsules/DischargeCapsules";
import { useHistory } from "react-router-dom";
import { stringToDateFunc } from "../../../../../HighOrderFunctions/stringToDateFunc";
import { connect } from "react-redux";
import { dischargeSinceDate } from "../../../../../HighOrderFunctions/dischargeSinceDate";

import * as actions from "../../../../../Store/actions/index";
import {
  returnYearOnly,
  ageModifier,
} from "../../../../../HighOrderFunctions/ageModifier";
import DashboardButton from "../../../../Buttons/DashboardButton/DashboardButton";
import { relationType } from "../../../../../HighOrderFunctions/RelationConversion/RelationConversion";
import Moment from "react-moment";
const DPANameBody = (props) => {
  const history = useHistory();
  const nameClicked = () => {
    props.setQRCodeToStoreFunc(props.qrCode);
    props.setCardNumberToStoreFunc(1);
    props.approveButtonStatusFuncToStore(false);
    props.showNavigationFuncToStore(true);
    history.push("/monitoringStatus/" + props.qrCode);
  };

  // key={index}
  // name={item["name"]}
  // dischargeDate={item["discharge_date"]}
  // dischargeDiagnosis={item["diagnosis"]}
  // qrCode ={item["qrCode"]}
  // rowNumber={index}
  // symptom={item["symptom"]}
  // age={item["age"]}
  // gender={item["gender"]}
  let { seconds, minutes, hours, days, months, years } = props.timeSinceAlert;
  let timeSincePatientDischarge = dischargeSinceDate(
    seconds,
    minutes,
    hours,
    days,
    months,
    years
  );
  let ageToBeShown = props.age && ageModifier(props.age);
  let symptomStatus = (
    <div>
      <span>{props.symptom}</span>{" "}
      <div>
        {props.threshold && relationType(props.relation)}
        <span>{props.threshold}</span>
      </div>
    </div>
  );
  return (
    <div
      className={`dpaNameBody__container 
      `}
      // ${props.rowNumber % 2 !== 0 ? "rowColor" : "rowColorWhite"}
    >
      <div className="dpaNameBody__patientName" onClick={nameClicked}>
        {props.name},{ageToBeShown},
        {props.gender ? ` ${props.gender.charAt(0)}` : "M"}
        <div className="dpaNameBody__patientQRCode">{props.hospitalId}</div>
      </div>
      <div className="dpaNameBody__discharge">
        <div className="dpaNameBody__dischargeDateDiagnosis">
          {props.dischargeDiagnosis}
        </div>
        <div className="dpaNameBody__dischargeDate">
          {stringToDateFunc(props.dischargeDate)}
        </div>
      </div>
      <div className="dpaNameBody__status">
        <DashboardButton
          bgColor="#FFDC99"
          name={symptomStatus}
          // height="25px"
        />
        <div className="dpaNameBody__lastTimeFromNow">
          {/* <Moment fromNow>{props.timeSinceAlert}</Moment> */}
          {timeSincePatientDischarge}
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
    setCardNumberToStoreFunc: (number) =>
      dispatch(actions.setCurrentCardNumberFunc(number)),
    approveButtonStatusFuncToStore: (status) =>
      dispatch(actions.approveButtonStatusFunc(status)),
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(null, mapDispatchToProps)(DPANameBody);
