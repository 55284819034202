import React, { useState } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  RequiredRule,
  FormItem,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { measureTypes } from "../../data";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import {
  getAllReminderModesEndPoint,
  getAllDischargeContextsEndPoint,
  getAllTasksCategoryEndPoint,
} from "../../../HighOrderFunctions/APIs/allGetAPI";
import { DisplayFormat } from "devextreme-react/date-box";
import { useDispatch } from "react-redux";
import { showNotificationWithMessage } from "../../../Store/actions/DashboardAction";

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

const DischargeAllTasks = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  let { response: allReminderModesList } = useFetch(
    `${getAllReminderModesEndPoint}`,
    false
  );
  let { response: taskCategoryList } = useFetch(
    `${getAllTasksCategoryEndPoint}`,
    false
  );
  let { response: dischargeContextList } = useFetch(
    `${getAllDischargeContextsEndPoint}`,
    false
  );
  function sendRequest(url, method, data) {
    method = method || "GET";
    data = data || {};

    if (method === "GET") {
      return fetch(url, {
        method: method,
        credentials: "include",
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) {
            console.log(result);
            return json.data;
          }
          throw json.Message;
        })
      );
    }

    // const params = Object.keys(data).map((key) => {
    //   return `${encodeURIComponent(key) }=${ encodeURIComponent(data[key])}`;
    // }).join('&');

    return fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          let temp = JSON.parse(text);
          if (temp.error) {
            dispatch(
              showNotificationWithMessage({
                variant: "warning",
                message: temp.error,
                time: 10000,
              })
            );
          }
          return text && JSON.parse(text);
        });
      } else {
        return result.json().then((json) => {
          console.log("RESULT1: ", json);

          throw json.Message;
        });
      }
    });
  }
  const store = new CustomStore({
    key: "discharge_task_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-tasks?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Task conversation Template", data);
          return {
            data: data.data,
            totalCount: data.totalCount,
          };
        })
        .catch(() => {
          throw "Data Loading Error";
        });
    },
    insert: (values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-tasks`,
        "POST",
        values
      ),
    update: (key, values) => {
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-tasks/${key}`,
        "PUT",
        values
      );
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-tasks/${key}`,
        "DELETE"
      ),
  });

  const onOptionChanged = (e) => {
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  return (
    <>
      <DataGrid
        // width={"50%"}
        dataSource={store}
        showBorders={true}
        remoteOperations={true}
        showColumnLines={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={false}
        onOptionChanged={onOptionChanged}
      >
        <SearchPanel visible={true} text={searchText} />
        <Editing
          refreshMode="reshape"
          mode="popup"
          allowAdding={true}
          allowDeleting={true}
          allowUpdating={true}
        />

        <Column
          width={35}
          dataField="discharge_task_id"
          dataType="number"
          caption="id"
          allowEditing={false}
        >
          <FormItem colSpan={1}></FormItem>
        </Column>
        <Column dataField="task_name" dataType="string"></Column>
        <Column dataField="reminder_mode" dataType="string">
          <Lookup
            dataSource={allReminderModesList}
            displayExpr="name"
            valueExpr="name"
          />
        </Column>
        <Column dataField="context" dataType="string">
          <Lookup
            dataSource={dischargeContextList}
            displayExpr="name"
            valueExpr="name"
          />
        </Column>
        <Column dataField="time_of_task" dataType="string"></Column>
        <Column dataField="reminder_time_in_seconds" dataType="number"></Column>
        <Column dataField="task_schedule_day_range" dataType="string"></Column>
        <Column
          dataField="task_conversation_template_name"
          dataType="string"
        ></Column>
        <Column dataField="task_category" dataType="string">
          <Lookup
            dataSource={taskCategoryList}
            displayExpr="name"
            valueExpr="name"
          />
        </Column>
        <Column dataField="activity_name" dataType="string"></Column>
        <Column dataField="remind_on_failure" dataType="number">
          <Lookup
            dataSource={[
              { id: "No", number: 0 },
              { id: "Yes", number: 1 },
            ]}
            displayExpr="id"
            valueExpr="number"
          />
        </Column>

        <Paging defaultPageSize={15} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[12, 15, 20]} />
      </DataGrid>
    </>
  );
};
// calculateCellValue={calculateCellValueFunc}
export default DischargeAllTasks;
