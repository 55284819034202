// 1. Monitoring Status Screen 


export const postSendPatientNotesEndPoint = "api/v1/send-patient-notes";

//2. FollowupCard.js
export const insertUpdateFollowupEndPoint = "api/v1/insert-update-followup";

//3. ExerciseSearchDropdown.js
export const insertExerciseEndPoint = "api/v1/insert-exercise";

//4. MedicalDeviceSearchDropdown.js                 ....?
export const updateMedicalDevicesEndPoint = "api/v1/updatePatientInfo";


//5. PatientDetailsContainer.js
export const updatePatientDetailsEndPoint = "api/v1/updatePatientDetails";

// ChatScheduler.js 
export const insertUpdateTaskEndPoint ="api/v1/insert-update-task"




//6. PatientHospitalDetails.js
export const updatePatientInfoEndPoint = "api/v1/updatePatientInfo";

// 7. DragDrop.js
export const uploadDocxEndPoint = "api/v1/parsedocx";

// 8. AppointmentCallBody.js 
export const updateNurseCallScheduleEndPoint ='api/v1/update-nurse-call-schedule';

// 9. Editable.js 
export const updatePatientMedicineDetails = "api/v1/update-patient-medicine-details";

// CovidRegistrationForm.js 
export const addNewCovidPatientEndPoint = "api/v1/init-covid";

// OnCallRegistrationSidebar.js
export const addNewCovidNurseEndPoint = "api/v1/nurse/add";

// ONCallRegistrationBodyNameBody.js
export const removeCovidNurseEndPoint = "api/v1/nurse/remove";



export const sentWhatsappMessageEndPoint = "pd-wa/send_message";

// UploadDischargeNameBody.js 
export const resendWhatsappMessageEndPoint = "api/v1/resend-patient-confirmation";
export const inlineUpdateEndPoint = "api/v1/InLineUpdate";

// DietSearchDropdown.js
export const updateDietEndPoint = "api/v1/insert-diet";

// AlertThresholdMainContainerBottom.js 
export const insertAlertEndPoint = "api/v1/insertAlert";
export const deleteAlertEndPoint  ="api/v1/deleteAlert";

export const updateMedicineDetailsEndPoint ="api/v1/update-patient-medicine-details";

// delete medicine 
export const deleteMedicineEndPoint="api/v1/delete-medication"
export const enableMedicineEndPoint="api/v1/enable-medication"


//delete followup
export const deleteFollowupEndPoint="api/v1/delete-followup"

// api to translate ref table's default language to desired Language
export const translateLanguageInTable = "api/v1/insert-in-db";

// api to change active care giver /api/v1/change-care-giver-on-duty/{qr_code}/{care_giver_id}
export const changeActiveCareTaker = "api/v1/change-care-giver-on-duty";

// api to delete care giver /api/v1/delete-caregiver/{care_giver_id}
export const deleteCareGiver = "api/v1/delete-caregiver";

// api to insert new care giver api/v1/insert-caregiver/{summary_id}
export const insertNewCareGiver ="api/v1/insert-caregiver";

// api to update existing care giver api/v1/update-care-giver-details
export const updateCareGiver="api/v1/update-care-giver-details";

// api to update diagnosis
export const updateDiagnosisEndPoint="api/v1/update-diagnosis";

