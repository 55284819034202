import React, { useState, useEffect, useRef, useCallback } from "react";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { connect, useSelector } from "react-redux";
import ButtonWBorder from "../../Components/Buttons/ButtonWBorder/ButtonWBorder";
import {
  getDischargeSummaryEndPoint,
  getPatientDetailsEndPoint,
} from "../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../HighOrderFunctions/FetchMethod/getFetchFunction";
import "./PdfViewReactPdf.css";
import LoaderThreeDot from "../../Loader/LoaderThreeDot";
const PdfViewReactPdf = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [totalPages, setTotalPages] = useState(1);
  const [name, setName] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [number, setNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [pdfFetched,setPdfFetched] = useState(false);
  function fileLoadSuccess({ numPages }) {
    setTotalPages(numPages);
    setNumber(numPages);
  }

  const fetchedDataFunc = async () => {
    setLoading(true);
    if (props.qrCodeFromStore) {
      let fetchedValue = await getFetchFunction(
        `${getPatientDetailsEndPoint}/${props.qrCodeFromStore}`,
        accessToken
      );
      if (Array.isArray(fetchedValue) == true) {
        setName(fetchedValue[0]["patientName"]);
      }
    }

    let fetchingPdf = async () => {
      try {
        await fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/${getDischargeSummaryEndPoint}/${props.qrCodeFromStore}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
          .then((response) => {
            return response.blob();
          })
          .then((fileFetched) => {
            const file = fileFetched;
            const fileReader = new FileReader();
            fileReader.onload = () => {
              setPdfFile(fileReader.result);
            };
            file && fileReader.readAsDataURL(file);
            return fileFetched;
          });
          setPdfFetched(true);
      } catch (err) {
        setPdfFile("");
        return null;
      }
    };
    await fetchingPdf();
    setLoading(false);
  };
  let renderedData = null;
  if (loading) {
    renderedData = (
      <div className="noPdfAvailable">
        <LoaderThreeDot />
      </div>
    );
  } else {
    if (pdfFile) {
      renderedData = (
        <Document onLoadSuccess={fileLoadSuccess} file={pdfFile}>
          <Page pageNumber={pageNo}></Page>
        </Document>
      );
    } else {
      if(pdfFetched){

        renderedData = <div className="noPdfAvailable">No Pdf Available!</div>;
      }
    }
  }

  useEffect(() => {
    if (props.qrCodeFromStore) {
      fetchedDataFunc();
    }
  }, [props.qrCodeFromStore]);

  return (
    <div className="pdfReader_container">
      <div className="pdfReader__nameHeader">
        <div className="pdfReader__nameHeaderName">
          {name && `${name}'s `} Discharge Summary
        </div>
      </div>
      <div className="pdfReader__pdfControl">
        <ButtonWBorder
          disabled={pageNo === 1}
          clicked={() =>
            setPageNo((prev) => {
              return prev > 1 ? prev - 1 : 1;
            })
          }
          name="Previous"
          pdfButton="true"
        >
          <i className="fas fa-arrow-left"></i>Prev
        </ButtonWBorder>
        <div className="pdf-info">
          Page <span id="page-no">{pageNo} </span>
          Of <span id="total-pages">{totalPages}</span>
        </div>
        <ButtonWBorder
          disabled={pageNo === totalPages}
          className="btn"
          clicked={() =>
            setPageNo((prev) => {
              return prev < totalPages ? prev + 1 : prev;
            })
          }
          pdfButton="true"
          name="Next"
        >
          Next <i className="fas fa-arrow-right"></i>
        </ButtonWBorder>
      </div>

      <div
        className="pdfReader__PdfPagesDisplay"
        style={{ height: props.height, width: props.width }}
      >
        {renderedData}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(PdfViewReactPdf);
