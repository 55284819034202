import React, { useState, useEffect, useRef } from "react";
import "./NurseUpdatingCard.css";
import SubmitButton from "../../../Buttons/SubmitButton/SubmitButton";
import crossCircle from "../../../../AllIcons/crossWhiteIcon.png";
const NurseUpdatingCard = (props) => {
  let modalRef = null;
  const handleClose = (e) => {
    if (modalRef && !modalRef.contains(e.target)) {
      props.closeModal && props.closeModal();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClose);
    return () => {
      document.removeEventListener("click", handleClose);
    };
  }, []);
  const currentTimeFunc = () => {
    return (new Date()).toLocaleTimeString("en-US");
  };
  let currentTime = currentTimeFunc();
  return (
    <div
      className="nurseUpdatingCard__container"
      ref={(node) => (modalRef = node)}
    >
      <div className="nurseUpdatingCard__header">
        <div className="nurseUpdatingCard__headerText">{props.header}</div>
        <div
          className="nurseUpdatingCard__cancelbutton"
          onClick={props.clicked}
        >
          <img src={crossCircle} alt="cancel" />
        </div>
      </div>
      <div className="nurseUpdatingCard__body">
        <textarea
          className="nurseUpdatingCard__bodyNoteForPatientBodyInput"
          type="text"
          placeholder={props.placeholder2}
          value={props.value2}
          onChange={(e) => props.setValue2(e.target.value)}
        />
        <div className="nurseUpdatingCard__bodyNoteForPatientCurrentTime">
          {currentTime}
        </div>
      </div>
      <div className="nurseUpdatingCard__submit">
        <SubmitButton width="100px" name="Submit" clicked={props.submitFunc} />
        {props.errorOccured && (
          <div className="nurseUpdatingCard__error">
            <div className="error-message">
              {props.errorMessage}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NurseUpdatingCard;
