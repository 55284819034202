import React from "react";
import "./ApprovalCustomButton.css";
const ApprovalCustomButton = (props) => {
  return (
   
      <button
        className={`customButton1 ${props.approvalErrorOccured ? "approvalCustomButton__errorApprovalCanceled" :""}`}
        style={{
          borderColor: props.BRDRColor,
          backgroundColor: props.disabled ? "lightgrey" : "white",
        }}
        onClick={props.clicked}
        disabled={props.disabled}
      >
        <p className="customButton1__text">{props.name}</p>
      </button>
  );
};

export default ApprovalCustomButton;
