import React from 'react';
import './PatientMessages.css';
import { getPatientMessage } from "../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction,useFetch } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { connect } from "react-redux";
import { dynamicSortDesc } from "../../../../HighOrderFunctions/dynamicSort";
import MSNurseNoteComponent from '../../../../Components/screensComponent/MonitoringStatus/MSNurseNoteComponent/MSNurseNoteComponent';
import noNurseNoteRecordedIcon from '../../../../AllIcons/noNurseNoteRecordedIcon.png'

const PatientMessages = (props) => {
  console.log("QR CODE", props)
 
    let {response,isLoading} = useFetch( `${getPatientMessage}/${props.qrCodeFromStore}`)
  
    if(Array.isArray(response) && response.length > 0){
      let sortedData = response.sort(dynamicSortDesc("day_number"));
      response = sortedData
  
    }
   
    let renderedData = null;
    console.log("renderedData",response)
    renderedData = Array.isArray(response) && response.map((item, index) => {
      return (
        <MSNurseNoteComponent
          key={`${item} - ${index}`}
          dayNumber={item["day_number"]}
          data={item["data"]}
          day={item["day"]}
        />
      );
    });
    if(response.length ===0){
        return (
          <div className="msNurseNotes__container">
          <div className="msNurseNotes__header">Patient Messages</div>
          <div className="msNurseNotes__noNurseNote">
              <img src = {noNurseNoteRecordedIcon} />
              </div>
        </div>
        )
    }
    return (
      <div className="msNurseNotes__container">
        <div className="msNurseNotes__header">Patient Messages</div>
        <div className="msNurseNotes__body">
            {renderedData} 
            </div>
      </div>
    );
  };
  
  const mapStateToProps = (state) => {
    return {
      qrCodeFromStore: state.patient.QRCode,
    };
  };
  
  export default  connect(mapStateToProps)(PatientMessages)