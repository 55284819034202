import React from "react";
import "./FileUpload.css";
import NewDischargeHeader from "../NewDischargeHeader/NewDischargeHeader";
import DragDrop from "./DRAGnDROP/DragDrop";
import RichedTextUploadButton from "./RichedTextUploadButton/RichedTextUploadButton";
import { useHistory } from "react-router-dom";
const FileUpload = () => {
  const history = useHistory();
  const navigateToRichedTextScreen = () =>{
    history.push("/ds-upload/richedText")
  }
  return (
    <div className="fileUpload__container">
      <div className="fileUpload__newDischargeText">
        <NewDischargeHeader />
      </div>
      <div className="fileUpload__dischargeUpload">
        <div className="fileUpload__dischargeUploadText">
          1. Upload a document
        </div>
        <div className="fileUpload__dragDropFile">
          <DragDrop />
        </div>
      </div>
      {/* <div className="fileUpload__orText"><div>or</div></div>
      <div className="fileUpload__richedTextFileUpload">
        <div className="fileUpload__richedTextUploadText">
          2. Copy \& Paste Text
        </div>
        <div className="fileUpload__pasteRichedText">
          <RichedTextUploadButton 
          clicked={navigateToRichedTextScreen}
          />
        </div>
      </div> */}
    </div>
  );
};

export default FileUpload;
