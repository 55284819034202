export function relationType(value) {
  if (value === "GREATER_THAN") {
    return <span>&gt;</span>;
  } else if (value === "LESS_THAN") {
    return <span>&lt;</span>;
  } else if (value === "EQUAL_TO") {
    return <span style={{textTransform:"lowercase"}}>is </span>;
  } else {
    return <span>`${value}`</span>;
  }
}
