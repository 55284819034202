import React from "react";
import "./ComplianceLegend.css";
const ComplianceLegend = (props) => {
  return (
    <div
      className={
        props.complianceLegendDownload
          ? "complianceLegend__circle__downlaod"
          : "complianceLegend__container"
      }
    >
      <div
        className={
          props.complianceLegendDownload
            ? "complianceLegend__circle__download"
            : "complianceLegend__circle"
        }
        style={{ backgroundColor: props.bgColor, border: props.border }}
      ></div>
      <div
        className={
          props.complianceLegendDownload
            ? "complianceLegend__text__download"
            : "complianceLegend__text"
        }
      >
        {props.text}
      </div>
    </div>
  );
};

export default ComplianceLegend;
