import React,{useState,useEffect} from 'react';
import './ChatSidebar.css';
import PdfViewReactPdf from '../../PdfViewReactPdf/PdfViewReactPdf';
const ChatSidebar = (props) => {
  
    return (
        <div className="chatSidebar__container">
          <PdfViewReactPdf/>
        </div>
    )
}

export default ChatSidebar
