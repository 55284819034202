import React, { useEffect, useRef } from "react";
import ComplianceCapsule from "./ComplianceCapsule/ComplianceCapsule";
import "./ComplianceSchedule.css";
const ComplianceSchedule = (props) => {
  const messagesEndRef = useRef(null);

  const scrollToRight = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  let excludedStatus = ["FAILURE", "SUCCESS", "PENDING"];
  let capsules = null;
  let takenTimeStatus =
    props.takenTimes &&
    props.takenTimes.filter((item) => excludedStatus.includes(item));
  capsules = takenTimeStatus.map((item, index) => {
    return (
      <div className="complianceSchedule__capsule" key={index}>
        <ComplianceCapsule
          doseStatus={item}
          capsuleWidth={props.capsuleWidth}
          capsuleHeight={props.capsuleHeight}
        />
      </div>
    );
  });
  useEffect(() => {
    scrollToRight();
  }, []);
  return (
    <div
      className="complianceSchedule__container"
      style={{ minWidth: props.minWidth && props.minWidth }}
    >
      <div className="complianceSchedule__capsules">{capsules}</div>
      <div className="complianceSchedule__dayAxis">
        <div className="complianceSchedule__borderLine"></div>
        <div
          className={
            props.complianceLegendDownload
              ? "complianceSchedule__dayNumber__download"
              : "complianceSchedule__dayNumber"
          }
        >
          {" "}
          D{props.day_number}
        </div>
        <div
          className={
            props.complianceLegendDownload
              ? "complianceSchedule__weekDay__download"
              : "complianceSchedule__weekDay"
          }
        >
          {props.dayName}
        </div>
      </div>
    </div>
  );
};

export default ComplianceSchedule;
