import React from "react";
import "./PictureButtonItem.css";
const PictureButtonItem = (props) => {
  return (
    <div className="pictureButtonItem__containerList">
      <div
        className="pictureButtonItem__container"
        onClick={() => props.updateImageUrl(props.imageDetail)}
        style={{ backgroundImage: `url(${props.imageDetail?.attachment_details})`,width:"100%",height:"100px",objectFit:"cover" ,backgroundSize: "cover"}}
      >
        {props.number}
      </div>
      <div className="pictureButtonItem__creationDate">
        {props.imageDetail?.creation_date}
      </div>
    </div>
  );
};

export default PictureButtonItem;
