import React from "react";
import CircularIcon from "../../../CircularIcon/CircularIcon";
import VengageLogo from "../../../../AllIcons/transparentVEngageLogo.png";
import { connect } from "react-redux";
import "./NavbarPatientAlertsScreen.css";
import NavigationApproval from "../../NavigationApproval/NavigationApproval";
import NavbarUserLogout from "../../NavbarUserLogout/NavbarUserLogout";
const NavbarPatientAlertsScreen = (props) => {
  return (
    <div className="vEngageNavbar__container">
      <div className="vEngageNavbar__Logo">
        <CircularIcon imgsrc={VengageLogo} imgsize="50px" size="60px" />
      </div>
      <div className="navBarNavigationApproval">
        <NavigationApproval />
      </div>
       <NavbarUserLogout />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showNavigationFromStore: state.patient.showNavigation,
  };
};

export default connect(mapStateToProps, null)(NavbarPatientAlertsScreen);
