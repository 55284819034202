import React from "react";
import "./NewDischargeHeader.css";
import uploadIcon from "../../../AllIcons/uploadIcon.png";
const NewDischargeHeader = () => {
  return (
    <div className="newDischargeHeader__container">
      <div className="newDischargeHeader__iconText">
        <div className="newDischargeHeader__icon">
          <img src={uploadIcon} alt="upload" />
        </div>
        <div className="newDischargeHeader__textHeader">
          New Discharge Summary
        </div>
      </div>
    </div>
  );
};  

export default NewDischargeHeader;
