import React, { useState, useEffect } from "react";
import CovidRegistrationNameHeader from "../../Components/screensComponent/CovidRegistration/CovidRegistrationNameHeader/CovidRegistrationNameHeader";
import CovidRegistrationNameBody from "../../Components/screensComponent/CovidRegistration/CovidRegistrationNameBody/CovidRegistrationNameBody";
import CovidRegistrationHeader from "../../Components/screensComponent/CovidRegistration/CovidRegistrationHeader/CovidRegistrationHeader";
import CovidRegistrationForm from "./CovidRegistrationForm/CovidRegistrationForm";
import RegisterCovidPatient from "./RegisterCovidPatient/RegisterCovidPatient";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../Store/actions/index";
import "./CovidRegistrationScreen.css";
import { getFetchFunction } from "../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { getCovidPatientListEndPoint } from "../../HighOrderFunctions/APIs/allGetAPI";
import Loader from "../../Loader/LoaderThreeDot";
import { dynamicSortDesc } from "../../HighOrderFunctions/dynamicSort";
import monthChange from "../../HighOrderFunctions/MonthChange";
const CovidRegistration = (props) => {
  const [selectedSendInvitation,setSelectedSendInvitation] = useState(-1);
  const [fetchedData, setFetchedData] = useState([]);
  const [totalCovidPatients, setTotalCovidPatients] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchColumns, setSearchColumns] = useState([
    "patientName",
    "qrCode",
    "age",
    "gender",
    "hospitalId",
    "contact",
    "dob",
    "registrationDate",
  ]);
  const [q, setQ] = useState("");

  const fetchedDataFunc = async () => {
    setLoading(true);
    let fetchedValue = await getFetchFunction(`${getCovidPatientListEndPoint}`);
    if (Array.isArray(fetchedValue) == true) {
      let sortedData = fetchedValue.sort(dynamicSortDesc("registrationDate"));
      let tempSortedData = sortedData.map((item) => {
        return {
          ...item,
          ["dob"]: item["dob"] && monthChange(item["dob"], "covidGetDOB"),
          ["registrationDate"]:
            item["registrationDate"] &&
            monthChange(item["registrationDate"], "covidGetDOB"),
        };
      });
      setFetchedData(tempSortedData);

      setTotalCovidPatients(fetchedValue.length);
    }
    setLoading(false);
  };
  let fetchingStatus = props.fetchingCovidList;
  useEffect(() => {
    if (props.fetchingCovidList === true) {
      fetchedDataFunc();
      props.fetchingCovidListReduxFlag(false);
    }
  }, [fetchingStatus]);

  useEffect(() => {
    fetchedDataFunc();
    props.showNavigationFuncToStore(false);
  }, []);
  let renderedData = [];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] &&
          row[column]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("-", "")
            .indexOf(q.toLowerCase().replaceAll(" ", "").replaceAll("-", "")) >
            -1
      )
    );
  }
  const setInputValueFunc = (e) => {
    setQ(e.target.value);
  };

  renderedData = search(fetchedData).map((item, index) => {
    return (
      <CovidRegistrationNameBody
        key={index}
        index={index}
        selectedSendInvitation = {selectedSendInvitation}
        setSelectedSendInvitation = {setSelectedSendInvitation}
        name={item["patientName"]}
        QRCode={item["qrCode"]}
        hospitalId={item["hospitalId"]}
        contact={item["contact"]}
        age={item["age"]}
        gender={item["gender"]}
        // dob={item["dob"]}
        // registrationDate={item["registrationDate"]}
        rowNumber={index}
        whatsappId={item["whatsapp_id"]}
        whatsappNo = {item["whatsapp_no"]}
        summaryStatus = {item["summary_status"]}
        messageTime ={item["message_time"]}
        messageStatus = {item["message_status"]}
      />
    );
  });
 


  return (
    <div className="covidRegistration__container">
      <div className="covidRegistration__header">
        <CovidRegistrationHeader
          setInputFunc={setInputValueFunc}
          totalCovidPatients={totalCovidPatients}
        />
      </div>
      <div className="covidRegistration__body">
        <div className="covidRegistration__bodyNameHeader">
          <CovidRegistrationNameHeader />
        </div>
        <div className="covidRegistration__bodyNameBody">
          {loading ? (
            <div className="loaderbar">
              {" "}
              <Loader />{" "}
            </div>
          ) : (
            <div>{renderedData}</div>
          )}
        </div>
      </div>
      <div className="covidRegistration__sidebar">{routes}</div>
      {/* <div className="covidRegistrationScreen__background"></div> */}
    </div>
  );
};
let routes = (
  <Switch>
    <Route
      // path={`/ds-upload/UDPatientPersonalDetails?name=:id`}
      path="/covid-registration/covidRegistrationForm/"
      component={CovidRegistrationForm}
    />
    <Route
      exact
      path={`/covid-registration/registerCovidPatient/`}
      component={RegisterCovidPatient}
    />
    <Redirect to="/covid-registration/registerCovidPatient" />
  </Switch>
);

const mapStateToProps = (state) => {
  return {
    fetchingCovidList: state.covidPatient.fetchCovidPatientList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchingCovidListReduxFlag: (status) =>
      dispatch(actions.fetchCovidPatientListAgain(status)),
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CovidRegistration);
