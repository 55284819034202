import React, { Fragment } from "react";
import TagBox from "devextreme-react/tag-box";


export default class AttributesTagBoxComponent extends React.Component {
  constructor(props) {
    super(props);
    let temp = this.props.data.value===undefined? []:this.props.data.value
    // let conversation_data = this.props.data.value===undefined? []:this.props.data.value
    this.state={
      attributes: temp
      // conversation_data:
    }
    
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.renderAttributesQuestionaire = this.renderAttributesQuestionaire.bind(this);
    this.renderQuestionaire = this.renderQuestionaire.bind(this);
    this.onContentReady=this.onContentReady.bind(this);
    this.TagBoxRef = React.createRef();

  }

  onContentReady(){
    this.renderQuestionaire();

  }

  onValueChanged(e) {
    this.props.data.setValue(e.value);
    this.setState({...this.state, attributes:e.value});

  }

  onSelectionChanged() {
    this.props.data.component.updateDimensions();

  }

  
  renderAttributesQuestionaire(){
    
  }
  
  renderQuestionaire(){
    // if(this.TagBoxRef.current!==null)

  };

  render() {
    // let attributes = this.props.data.value;
    // attributes.map(item=>{
    //   return <AttributesQuestionaire></AttributesQuestionaire>
    // });

    
  //   const renderAttributesComponent =() => this..map((item, index) => {
  //   return <AttributesQuestionaire key={index} name={item} symptom_name={selectedSymptom}/>;
  // });

    return (
      <Fragment>
        <TagBox
          ref={this.TagBoxRef}
          dataSource={this.props.data.column.lookup.dataSource}
          defaultValue={this.props.data.value}
          valueExpr="name"
          displayExpr="name"
          showSelectionControls={true}
          maxDisplayedTags={20}
          showMultiTagOnly={true}
          applyValueMode="useButtons"
          searchEnabled={true}
          onValueChanged={this.onValueChanged}
          onSelectionChanged={this.onSelectionChanged}
          onContentReady={this.onContentReady}
        />
      </Fragment>
    );
  }
}
