import React, { useState, useEffect, useRef } from "react";
import "./NavbarOnDuty.css";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import * as actions from "../../../Store/actions/index";
import { getFetchFunction } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { postFetchFunction } from "../../../HighOrderFunctions/FetchMethod/postFetchFunction";
import {
  getNurseListNamesEndPoint,
  updateSelectedNurseEndPoint,
} from "../../../HighOrderFunctions/APIs/allGetAPI";
import TagBox from "devextreme-react/tag-box";
import { useSelector } from "react-redux";
const NavbarOnDuty = (props) => {
  const token = useSelector((state) => state.auth.accessToken);

  const [nurseNamesList, setNurseNamesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const ref1 = useRef(null);
  const history = useHistory();
  let hashPath = window.location.hash;

  // fetching Nurse Details
  const fetchedDataFunc = async () => {
    setLoading(true);
    let fetchedValue = await getFetchFunction(
      `${getNurseListNamesEndPoint}`,
      token
    );

    if (Array.isArray(fetchedValue) == true) {
      setNurseNamesList(fetchedValue);
      let tempSelectedValues = fetchedValue
        .filter((item) => item.is_on_duty == 1)
        .map((item) => item.nurse_duty_mapping_id);
      setSelectedValues(tempSelectedValues);
    }
    setLoading(false);
  };

  // fetching data on entering page
  useEffect(() => {
    fetchedDataFunc();
  }, []);

  // fetching again on basis of redux key fetchNurseListOnCallRegistration
  useEffect(() => {
    if (props.fetchNurseList == true) {
      fetchedDataFunc();
    }
  }, [props.fetchNurseList]);

  // update selected nurse to backend
  const updateSelectedNurse = async (chosenValue) => {
    // await fetchedDataFunc();
    if (chosenValue.length !== 0 && chosenValue.length <= 2) {
      setSelectedValues(chosenValue);
      let formToBeSend = {
        active_ids: chosenValue,
      };
      await postFetchFunction(updateSelectedNurseEndPoint, formToBeSend, token);
      props.showNotificationWithMessage({
        variant:"success",
        message:"Active nurse updated."
      })
    }else{
      props.showNotificationWithMessage({
        variant:"warning",
        message:"Minimum 1 nurse and maximum 2 allowed."
      })
    }

    // let fetchedValue = await getFetchFunction(
    //   `${updateSelectedNurseEndPoint}/${mapping_id}`,
    //   token
    // );
  };

 
  // navigate to onCallRegistration Screen
  const navigateToOnCallRegistrationScreen = () => {
    history.push("/onCallRegistration");
    props.showNavigationFuncToStore(true);
    props.setShowCallRegistrationToStore(false);
  };
  useEffect(() => {}, [hashPath]);

  return (
    <div className="navbarOnDuty__container">
      <div className="navbarOnDuty__onDutyRegistration">
        <div className="navbarOnDuty__onDutyText">ON DUTY</div>
      </div>
      <div className="navbarOnDuty__nursesOnDuty">
        <div className="navbarOnDuty__nurse1OnDuty">
          <TagBox
            style={{maxWidth:"300px"}}
            displayExpr="display_value"
            dataSource={nurseNamesList}
            onValueChange={(values) => updateSelectedNurse(values)}
            valueExpr="nurse_duty_mapping_id"
            multiline
            value={selectedValues}
            placeholder="Select  Nurse..."
            onFocusIn={fetchedDataFunc}
          />
        </div>
        {!(hashPath.split("/")[1] == "onCallRegistration") && (
          <div
            className="navbarOnDuty__newRegistration"
            onClick={navigateToOnCallRegistrationScreen}
          >
            Register for on-call
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showRegistration: state.covidPatient.showRegistrationOnCallRegistration,
    fetchNurseList: state.covidPatient.fetchNurseListOnCallRegistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),

    setShowCallRegistrationToStore: (status) =>
      dispatch(actions.showRegistrationInOnCallRegistration(status)),
    showNotificationWithMessage:(message)=>dispatch(actions.showNotificationWithMessage(message))
    // fetchingNurseListOnCallRegistrationList: (status) =>
    // dispatch(actions.fetchNurseListOnCallRegistrationAgain(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarOnDuty);

{
  /* <div
className="navbarOnDuty__nurse1onDutyName"
ref={ref1}
onClick={toggle1}
onBlur={toggle1}
>
{selectedValue1}
</div>
<div
className={`navbarOnDutyDropdownOptions ${
  open1 ? "showOptions" : "dontShowOptions"
}`}
>
{nurseNamesList.map((option, index) => (
  <div
    key={index}
    className={`navbarOnDutyDropdownOption ${
      selectedOption1 === option["nurse_duty_mapping_id"]
        ? "navbarOnDutyDropdownSelected"
        : null
    }`}
    onClick={() => selectOption1(option)}
    onTouchEnd={() => selectOption1(option)}
  >
    <div className="dropdownOption__imageAndText">
      {`${option["nurse_name"]} | ${option["phone_no"]} `}
    </div>
  </div>
))}
</div> */
}

// export default function Item(data) {
//   return (
//     <div className="custom-item">
//       <img src={data.ImageSrc} />
//       <div className="product-name">{data.Name}</div>
//     </div>
//   );
// }
