import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

import AttributesQuestionaire from "./AttributesQuestionaire";
import { Button, SelectBox } from "devextreme-react";
import { symptomAttributes } from "../../data";

const SnSConversationBuilder = (props) => {
  const [symptoms, setSymptoms] = useState([]);
  const [selectedSymptom,setSelectedSymptom] = useState("");
  const [attributes, setAttributes] = useState([]);
  const fetchAll = async () => {
    axios
      .get(`${window["getConfig"].BACKEND_SERVICE_URL}/symptoms/all`)
      .then((response) => {
        setSymptoms(response.data);
      });
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const symptomSelected = (e) => {
    setSelectedSymptom(e.selectedItem.symptom_name);
    setAttributes(e.selectedItem.attributes);
  };

  const renderAttributesComponent = attributes.map((item, index) => {
    return <AttributesQuestionaire key={index} name={item} symptom_name={selectedSymptom}/>;
  });
  return (
    <Fragment>
      <SelectBox
        dataSource={symptoms}
        valueExpr="symptom_question_id"
        displayExpr="symptom_name"
        placeholder="Please select a symptom to start building conversation..."
        onSelectionChanged={symptomSelected}
      ></SelectBox>

      {renderAttributesComponent}
      <Button text="Save" type="default" stylingMode="contained"></Button>
    </Fragment>
  );
};

export default SnSConversationBuilder;
