import {
  SETDISCHARGECONTEXT_APPROVE_BUTTON,
  SETALERTTHRESHOLD_APPROVE_BUTTON,
  SETFOLLOWUPSCHEDULES_APPROVE_BUTTON,
  SETMEDICATION_APPROVE_BUTTON,
  SETDISCHARGE_NAVLINK_APPROVESTATUS,
  SETALERTTHRESHOLD_NAVLINK_APPROVESTATUS,
  SETFOLLOWUPSCHEDULES_NAVLINK_APPROVESTATUS,
  SETMEDICATION_NAVLINK_APPROVESTATUS,
  RESET_NAVLINK_APPROVESTATUS,
  IS_MONITORING_APPROVED,
  IS_MEDICATION_SCHEDULE_PAGE_RENDERED,
  SHALL_CREATE_REMINDER,
} from "./actionTypes";

export const setDischargeContextApproveButton = (status) => {
  return {
    type: SETDISCHARGECONTEXT_APPROVE_BUTTON,
    status: status,
  };
};
export const setAlertThresholdApproveButton = (status) => {
  return {
    type: SETALERTTHRESHOLD_APPROVE_BUTTON,
    status: status,
  };
};
export const setFollowUpSchedulesApproveButton = (status) => {
  return {
    type: SETFOLLOWUPSCHEDULES_APPROVE_BUTTON,
    status: status,
  };
};
export const setMedicationApproveButton = (status) => {
  return {
    type: SETMEDICATION_APPROVE_BUTTON,
    status: status,
  };
};
export const setDischargeNavlinkApproveStatus = (status) => {
  return {
    type: SETDISCHARGE_NAVLINK_APPROVESTATUS,
    status: status,
  };
};
export const setAlertThresholdNavlinkApproveStatus = (status) => {
  return {
    type: SETALERTTHRESHOLD_NAVLINK_APPROVESTATUS,
    status: status,
  };
};
export const setFollowUpSchedulesNavlinkApproveStatus = (status) => {
  return {
    type: SETFOLLOWUPSCHEDULES_NAVLINK_APPROVESTATUS,
    status: status,
  };
};
export const setMedicationNavlinkApproveStatus = (status) => {
  return {
    type: SETMEDICATION_NAVLINK_APPROVESTATUS,
    status: status,
  };
};

export const resetNavLinkApproveStatus = () => {
  return {
    type: RESET_NAVLINK_APPROVESTATUS,
  };
};

export const setIsMonitoringApproved = (approveStatus) => {
  return {
    type: IS_MONITORING_APPROVED,
    approveStatus: approveStatus,
  };
};

export const setUpdatedMedicineAfterApproved = (status) => {
  return {
    type: IS_MEDICATION_SCHEDULE_PAGE_RENDERED,
    payload: status,
  };
};

export const setCreateReminders = (shallCreateReminder) => {
  return {
    type: SHALL_CREATE_REMINDER,
    payload: shallCreateReminder,
  };
};
