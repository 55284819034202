import React from "react";
import "./PainChartSidebar.css";
const PainChartSidebar = (props) => {
  return (
    <div className="painChartSidebar__container">
      <div
        className={
          props.downloadPainChart
            ? `painChartSidebar__download painChartSidebar__text`
            : "painChartSidebar__text"
        }
      >
        Severe
      </div>
      <div
        className={
          props.downloadPainChart
            ? `painChartSidebar__download painChartSidebar__text`
            : "painChartSidebar__text"
        }
      >
        Moderate
      </div>
      <div
        className={
          props.downloadPainChart
            ? `painChartSidebar__download painChartSidebar__text`
            : "painChartSidebar__text"
        }
      >
        Mild
      </div>
    </div>
  );
};

export default PainChartSidebar;
