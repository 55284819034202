import React, { useState, useEffect } from "react";
import CareGiversCards from "./CareGivers/CareGiversCards/CareGiversCards";
import careGiverIcon from "../../../AllIcons/careGiverIcon.png";
import { useRef } from "react";
import {
  useFetch,
  getFetchFunction,
} from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import "./CareGiverMonitoringStatus.css";
import { useSelector } from "react-redux";
import { getCareGiversDetails } from "../../../HighOrderFunctions/APIs/allGetAPI";
const CareGiverMonitoringStatus = ({countryCode,setCountryCode}) => {
  const qrCodeFromStore = useSelector((state) => state.patient.QRCode);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [careGiversDetails, setCareGiversDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef();
  const [showCareGiversList, setShowCareGiversList] = useState(false);
  const [activeCareGiverContact, setActiveCareGiverContact] = useState(null);
  const [activeCareGiverContactDisplayItem, setActiveCareGiverContactDisplayItem] = useState(null);

  const fetchCareGiverDetails = async () => {
    let careGiverDetailsResponse = await getFetchFunction(
      `${getCareGiversDetails}/${qrCodeFromStore}`,
      accessToken
    );
    setCareGiversDetails((prev) => [...careGiverDetailsResponse]);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchCareGiverDetails();
    setIsLoading(false);
  }, [showCareGiversList]);

  useEffect(() => {
    if (Array.isArray(careGiversDetails)) {
      careGiversDetails.forEach((item, index) => {
        if (item["is_currently_on_duty"] == true) {
          let tempContactWithIsd = `${item["isd_code"]} ${item["care_giver_phone"]}`;
          setActiveCareGiverContact((prev) => tempContactWithIsd);
          setActiveCareGiverContactDisplayItem(item);
          setCountryCode(prev=>item["isd_code"]);
        }
      });
    }
  }, [careGiversDetails]);

  return (
    <div className="careGiverMonitoringStatus__container">
      <div
        className="careGiverMonitoringStatus__activeContacts"
        ref={ref}
        onClick={() => setShowCareGiversList((prev) => !prev)}
      >
        <div className="careGiverMonitoringStatus__image">
          <img src={careGiverIcon} alt="careGiverIcon" />
        </div>
        <div className="careGiverMonitoringStatus__contactNumber">
        {activeCareGiverContactDisplayItem?.care_giver_name} <span style={{fontWeight:"normal"}}>({activeCareGiverContactDisplayItem?.isd_code}-{activeCareGiverContactDisplayItem?.care_giver_phone})</span>
        
        </div>
        
      </div>
      <div className="careGiverMonitoringStatus__cards">
        {showCareGiversList && (
          <CareGiversCards
            setActiveCareGiverContact={setActiveCareGiverContact}
            careGiversDetails={careGiversDetails}
            isLoading={isLoading}
            showCareGiversList={showCareGiversList}
            countryCode={countryCode}
          />
        )}
      </div>
    </div>
  );
};

export default CareGiverMonitoringStatus;

// function to toggle opening and closing dropdown option
// function toggle(e) {
//   setShowCareGiversList(e && e.target === ref.current);
//   if (e && e.target === ref.current) {
//     alert(e.target == ref.current);
//   }
// }
// close on clicking outside
// useEffect(() => {
//   ["click", "touchEnd"].forEach((e) => {
//     document.addEventListener(e, toggle);
//     return () => {
//       document.removeEventListener(e, toggle);
//     };
//   });
// }, []);
