import React from "react";
import DischargeCapsuleStatus from "./DischargeCapsuleStatus/DischargeCapsuleStatus";
import dischargeAlert1 from "../../../AllIcons/dischargeAlert1.png";
import dischargeAlert2 from "../../../AllIcons/dischargeAlert2.png";
import dischargeCall1 from "../../../AllIcons/dischargeCall1.png";
import dischargeMedicalHistory1 from "../../../AllIcons/dischargeMedicalHistory1.png";
import dischargePill1 from "../../../AllIcons/dischargePill1.png";
import { v4 as uuidv4 } from "uuid";

const DischargeCapsules = (props) => {
  let capsule = null;
  if (props.capsuleName) {
    switch (props.capsuleName.toLowerCase()) {
      case "pending approval":
        capsule = (
          <DischargeCapsuleStatus
          key={uuidv4()}
            bgcolor="rgba(182, 255, 185, 0.68)"
            name={props.capsuleName}
            imgsrcIcon={dischargePill1}
          />
        );
        break;
      case "alert raised":
        capsule = (
          <DischargeCapsuleStatus
          key={uuidv4()}

            // name="Alert Raised"
            name={props.capsuleName}
            bgcolor="#FFDC99"
            imgsrcIcon={dischargeAlert1}
          />
        );
        break;
      case "visit hospital":
        capsule = (
          <DischargeCapsuleStatus
          key={uuidv4()}

            name={props.capsuleName}
            // name="Visit Hospital"
            bgcolor="#FDBCB9"
            imgsrcIcon={dischargeAlert2}
          />
        );
        break;
      // case "patient_calls":
      //   capsule = (
      //     <DischargeCapsuleStatus
      //       // name="Patient Calls"
      //       name={props.capsuleName}
      //       name={props.capsuleName}
      //       bgcolor="rgba(184, 217, 255, 0.68)"
      //       imgsrcIcon={dischargeCall1}
      //     />
      //   );
      //   break;
      // case "review":
      //   capsule = (
      //     <DischargeCapsuleStatus
      //       name="Review"
      //       bgcolor="rgba(184, 217, 255, 0.68)"
      //       imgsrcIcon={dischargeMedicalHistory1}
      //     />
      //   );
      //   break;
      default:
        capsule = (
          <DischargeCapsuleStatus
          key={uuidv4()}

            // name="Review"
            name={props.capsuleName}
            bgcolor="rgba(184, 217, 255, 0.68)"
            imgsrcIcon={dischargeMedicalHistory1}
          />
        );
      // capsule =(<div>No Status</div>)
    }
  }
  return <div>{capsule}</div>;
};

export default DischargeCapsules;
