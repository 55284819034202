import React from "react";
import "./AlertThresholdNotificatinNonEdit.css";
const AlertThresholdNotificationNonEdit = (props) => {

  // function to provide sign in place of name for relations
  function relationType(value) {
    if (value === "GREATER_THAN") {
      return <span>&gt;</span>;
    } else if (value === "LESS_THAN") {
      return <span>&lt;</span>;
    } else if (value === "EQUAL_TO") {
      return <span>=</span>;
    } else {
      return <span>`${value}`</span>;
    }
  }
 let relationalValue = relationType(props.relation)
  return (
    <div className="AlertThresholdNotificationNonEdit__container" 
    style={{backgroundColor:((props.index % 2 !==0)?"#F9F7FD":"white")}}
    >
      {props.day_start && (
        <div className="AlertThresholdNotificationNonEdit">
          <span className="alertThreshold__nonEditField">Day </span>
          <span className="alertThreshold__nonEditField">{props.day_start}</span>
          <span className="alertThreshold__nonEditField"> to  </span>
          <span className="alertThreshold__nonEditField"> Day </span>
          <span className="alertThreshold__nonEditField">{props.day_end}</span>
          <span className="alertThreshold__nonEditField"> is </span>
          {props.measure_type === "NUMBER_SCALE" && (
            <span className="alertThreshold__nonEditField">{relationalValue} </span>
          )}
          <span className="alertThreshold__nonEditField">{props.threshold}</span>
        </div>
      )}
    </div>
  );
};

export default AlertThresholdNotificationNonEdit;
