import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import App from "./App";
import Login from "./Authentication/Login";
import LoaderBar from "./Loader/LoaderBar";
import CardForLogin from "./Components/CardForLogin/CardForLogin";
import ErrorModal from "./modals/ErrorModal/ErrorModal";
import CircularIcon from "./Components/CircularIcon/CircularIcon";
import VengageLogo from "./AllIcons/transparentVEngageLogo.png";
import "./dx.common.css";
import "./dx.light.css";
import "./RenderToIndex.css";
import NavigatorOnline from "react-navigator-online";

const RenderToIndex = () => {
  const { isLoading, isAuthenticated, error } = useAuth0();

  const [loading, setLoading] = useState(isLoading);
  const [errorOccured, setErrorOccured] = useState(error);

  const [networkStatus, setNetworkStatus] = useState(false);
  useEffect(() => {
    if (!navigator.onLine) {
      return (
        <ErrorModal
          message="Please check your Network Connection !!"
          setOnlineStatus={setNetworkStatus}
        />
      );
    }
    return () => {};
  }, [navigator.onLine, networkStatus]);
  const showOnlineMessage = (status) => {
    if (!status) {
      return (
        <ErrorModal
          message="Please check your Network Connection !!"
          setOnlineStatus={setNetworkStatus}
        />
      );
    }
  };
  if (isLoading) {
    if (!navigator.onLine) {
      return (
        <ErrorModal
          message="Please check your Network Connection !!"
          setOnlineStatus={setNetworkStatus}
        />
      );
    }
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div className="vEngageLogo__forLoading">
          <CircularIcon imgsrc={VengageLogo} imgsize="50px" size="60px" />
        </div>
        <CardForLogin>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderBar />
            <div className="renderToIndex__pleaseWait">Please Wait...</div>
          </div>
        </CardForLogin>
      </div>
    );
  }
  if (errorOccured) {
    return <div style="error__occured">Error occured</div>;
  }

  if (isAuthenticated) {
    return (
      <div>
        <App />
      </div>
    );
  } else {
    return (
      <div style={{ height: "100vh", width: "100v" }}>
        <Login />
      </div>
    );
  }
  return (
    <div>
      <NavigatorOnline onChange={(status) => showOnlineMessage(status)} />

      <App />
    </div>
  );
};

export default RenderToIndex;
