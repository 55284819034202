import React, { Fragment, useState } from "react";
import "./MonitoringStatusChartDownload.css";
import { Route, Switch, Redirect } from "react-router-dom";

import { useSelector } from "react-redux";
import MonitoringStatusCharts from "./MonitoringStatusCharts/MonitoringStatusCharts";
import HtmlToPdfDownload from "./MonitoringStatusCharts/HtmlToPdfDownload";
const MonitoringStatusChartDownload = () => {
  const [showAllCharts, setShowAllCharts] = useState(false);
  const qrCodeFromStore = useSelector((state) => state.patient.QRCode);
  const pdfDownloadHamdler = () => {
    // download_patient_report;
    window.open("/#/download_patient_report");
  };
  return (
    <Fragment>
      <div
        className="monitoringOtherTasks__container"
        onClick={pdfDownloadHamdler}
      >
        Download Report{" "}
        <span className="monitoringOtherTasks__borderRight">|</span>
      </div>
    </Fragment>
  );
};

export default MonitoringStatusChartDownload;
