import React from "react";
import './HomeWithNavbar.css';
import NavbarPostAdminScreen from "../Components/Navbar/NavbarScreens/NavbarPostAdminScreen/NavbarPostAdminScreen";
import {Home} from './Home';
const PatientPanelWithNavbar = () => {
  return (
    <div className="homeWithNavbar__container">
      <div className="homeWithNavbar__vEngageNavbarHeader">
        <NavbarPostAdminScreen />
      </div>
      <div className="homeWithNavbar__bodyContent">
        <Home />
      </div>
      <div className="homeWithNavbar__footer"></div>
    </div>
  );
};

export default PatientPanelWithNavbar;
