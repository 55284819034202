import React from 'react';
import './NavbarCovidRegistration.css';
const NavbarCovidRegistration = () => {
    return (
        <div className="navbarCovidRegistration__container">
            Patients Enrolled in COVID Monitoring Program
        </div>
    )
}

export default NavbarCovidRegistration
