import React from "react";
import CircularIcon from "../CircularIcon/CircularIcon";
import CloseIcon from "../../AllIcons/mdiCloseS3.png";
import "./SendInvitation.css";
import InputCard from "../Card/InputCard/InputCard";
import SendInvitationButton from "../Buttons/SendInvitationButton/SendInvitationButton";
const SendInvitation = (props) => {
  return (
    <div className="sendInvitation">
      <div className="sendInvitation__header"></div>
      <div className="sendInvitation__sendInvitation">
        <div></div>
        <div className="sendInvitation__sendInvitationTextName">
          <div className="sendInvitation__sendInvitationText">Send Invitation</div>
        </div>
        <div className="sendInvitation__crossIcon">
          <CircularIcon imgsrc={CloseIcon} size="24px"
          clicked={props.toggleSendInvitation}
          />

        </div>
      </div>
      <div className="sendInvitation__contactNumber">
        <div>
          <p className="sendInvitation__contactText">Contact number</p>
          <InputCard placeHolder="Contact number" 
          value={props.contactNumber}
          disabled={props.disabled}
          onChange = {(value)=>props.setContactNumberEntered(value)}
          touchedNotValid={props.touchedNotValid}
          />
        </div>
      </div>
      <div className="sendInvitation__sendButton">
        <SendInvitationButton
          name="send"
          bgcolor="rgba(57, 129, 233, 0.68)"
          textColor="white"
          clicked={props.resendMessageToPatient}
          disabled={!props.disabled}
        />
      </div>
      <div className="sendInvitation__footer"></div>
    </div>
  );
};

export default SendInvitation;
