import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SearchDevExtreme from "./SearchDevExtreme";
import { getTablesToBeTranslatedEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { dynamicSort } from "../../../HighOrderFunctions/dynamicSort";
function TableNameDropdown(props) {
  let { response, isLoading } = useFetch(
    `${getTablesToBeTranslatedEndPoint}`,
    false
  );
  if (Array.isArray(response) && response.length > 0) {
    let sortedData = response.sort(dynamicSort("name"));
    response = sortedData;
  } else {
    response = [];
  }

  const updateTablesName =(tableName) => {
    props.setValue(tableName)
  console.log(tableName)
  };
  return (
    <div
      className="devextremeDropdown__container"
      style={{ width: props.width }}
      disabled={props.disabled}
    >
      <SearchDevExtreme
        placeholder="Select TableName"
        optionsFetched={response}
        displayName={props.displayName}
        displayExpr={props.displayExpr}
        valueExpr={props.valueExpr}
        defaultValue={props.value}
        updatedSelectedValue={updateTablesName}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(TableNameDropdown);
