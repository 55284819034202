import React from "react";
import "./DashboardButton.css";
const DashboardButton = (props) => {
  return (
    <div className={`dashboardButton `} style={{backgroundColor:props.bgColor,width:props.width,height:props.height,boxShadow:props.boxShadow}} onClick={props.clicked}>
      <div className="dashboardButton__buttonName">{props.name}</div>
    </div>
  );
};

export default DashboardButton;
