import React from 'react'
import './DUMNameHeader.css'
const DUMNameHeader = (props) => {
    return (
        <div className="dumNameHeader__container">
            {/* <div className="dumNameHeader__textShowingOrder">Showing results in descending order of discharge date </div> */}
            <div className="dumNameHeader__patientName">
             PATIENT NAME
             <div className="dumNameHeader__hospitalId">(Hospital ID)</div>

            </div>
            <div className="dumNameHeader__dischargeDate">
                <div className="dumNameHeader__dischargeDateDate">DISCHARGE DATE <span className="dumNameHeader__arrow"></span></div>
                <div className="dumNameHeader__dischargeDateTimeSinceDischarge">(time since discharge)</div>

            </div>
            <div className="dumNameHeader__dischargeDiagnosis">DISCHARGE DIAGNOSIS</div>
            <div   className="dumNameHeader__department">DEPARTMENT</div>
            <div className="dumNameHeader__status">
            STATUS
            </div>
        </div>
    )
}

export default DUMNameHeader
