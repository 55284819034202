import React, { useState, useEffect } from "react";
import Clock from "react-live-clock";
import { getTaskEndPoint } from "../../HighOrderFunctions/APIs/allGetAPI";
import { insertUpdateTaskEndPoint } from "../../HighOrderFunctions/APIs/allPostAPI";
import DataGrid, { Column } from "devextreme-react/data-grid";
import "./ChatScheduler.css";
import { useSelector } from "react-redux";
// This component will be used to schedule task out of available tasks for any qr code on given day at
//  desired time for running  template in whatsapp chat (it's a group schedule)
const ChatScheduler = () => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [qrCode, setQRCode] = useState("");
  const [dayNumber, setDayNumber] = useState("");
  const [taskType, setTaskType] = useState("");
  const [taskName, setTaskName] = useState("");
  const [taskId, setTaskId] = useState("");
  const [conversationTemp, setconversationTemp] = useState("");
  const [taskDateTime, setTaskDateTime] = useState("");
  const [fetchedData, setFetchedData] = useState([]);
  const [callNumber, setCallNumber] = useState("");
  const [isRowSelected, setIsRowSelected] = useState(null);
  console.log("fetchedData", fetchedData);
  const [showDropDown, setShowDropdown] = useState(false);
  const updateQRcode = (e) => {
    setQRCode(e.target.value.toString().toUpperCase());
  };
  const updateDayNumber = (e) => {
    setDayNumber(e.target.value);
  };

  useEffect(() => {
    return () => {
      setIsRowSelected(null);
    };
  }, []);

  // function to fetch list of tasks using qr Code and dayNumber
  const fetchTaskFunc = () => {
    const fetchAllTask = async () => {
      try {
        if (!qrCode || !dayNumber) {
          alert("Please complete required fields");
          throw Error("Incomplete request");
        }

        fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/${getTaskEndPoint}/${qrCode}?day_number=${dayNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken} `,
            },
          }
        )
          .then((response) => response.json())
          .then((result) => {
            let temp = [];
            Array.isArray(result) &&
              result.map((item) => {
                // console.log("Item", item);
                let data = {
                  taskId: item.task_id,
                  taskName: item.task_name,
                  taskType: item.task_type,
                  taskStatus: item.task_status,
                  dayNumber: item.day_number,
                  taskDateTime: item.task_date_time,
                  callNumber: item.call_number,
                  conversationTemplate:
                    typeof item.task_conversation_template_name === "string"
                      ? item.task_conversation_template_name
                      : "Not editable",
                };
                temp.push(data);
              });
            setFetchedData(temp);
            setShowDropdown(true);
          });
      } catch (err) {
        console.log("Error occured! ", err);
      }
    };

    fetchAllTask();
  };

  const setTaskTypeFunc = (e) => {
    setTaskType(e.target.value);
  };

  const setTimeFunc = (e) => {
    setTaskDateTime(e.target.value);
  };

  // function to update individual task time for schedule
  const updateStatusTimeNameFunc = (queryType) => {
    let task_date_time = taskDateTime;
    if (queryType === "schedule") {
      // Adding a 15 second into the current time
      let date = new Date();
      date.setSeconds(date.getSeconds() + 15);
      task_date_time = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}T${date.toLocaleTimeString('en-GB')}`;
    }
    let updateJSONData = {
      task_id: taskId,
      qr_code: qrCode,
      day_number: dayNumber,
      task_date_time: task_date_time,
      task_status: "PENDING",
      task_name: taskName,
      task_conversation_template_name: conversationTemp,
    };

    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken} `,
      },
      body: JSON.stringify(updateJSONData),
    };
    fetch(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${insertUpdateTaskEndPoint}`,
      requestOptions
    ).then((result) => {
      fetchTaskFunc();
    });
  };

  const dataClickedFromGrid = (value) => {
    setIsRowSelected(value.data.taskDateTime);
    setTaskId(value.data.taskId);
    setTaskDateTime(value.data.taskDateTime);
    setTaskType(value.data.taskType);
    setDayNumber(value.data.dayNumber);
    setTaskName(value.data.taskName);
    setCallNumber(value.data.callNumber);
    setconversationTemp(value.data.conversationTemplate);
  };

  return (
    <div style={{ height: "82vh", overflowY: "scroll" }}>
      <div className="clockTimeAddDummyData">
        <div className="clockTime">
          <Clock format={"DD-MM-YYYY, HH:mm:ss "} ticking={true} />
        </div>
      </div>
      <div className="qrCodeUpdateTime">
        <div className="qrcodeDaySubmit">
          <div className="qrCodeDay">
            <div>
              <div className="qrcode__text">QR code</div>
              <div className="qrCode">
                <input
                  type="text"
                  className="chatScheduler__qrCodeInput"
                  value={qrCode}
                  onChange={updateQRcode}
                  placeholder="Enter QRCODE"
                />
              </div>
            </div>
            <div>
              <div className="dayNumber__text">Day Number: </div>
              <div className="dayNumber">
                <input
                  type="number"
                  className="chatScheduler__dayNumberInput"
                  value={dayNumber}
                  onChange={updateDayNumber}
                  placeholder="Enter Day Number"
                />
              </div>
            </div>
          </div>
          <div className="qrCodeDaySubmit">
            <button
              className="submit_Button"
              onClick={fetchTaskFunc}
              disabled={qrCode.length !== 6 && dayNumber.length == 0}
            >
              Submit
            </button>
          </div>
        </div>
        {showDropDown && (
          <div className="updateTask_taskTime">
            <div className="taskType__taskType">
              <div className="taskType__text">Task type</div>
              <input
                type="text"
                className="chatScheduler__nameInput"
                value={taskType}
                placeholder="Enter Task Type..."
                onChange={setTaskTypeFunc}
                disabled
              />
            </div>
            <div className="taskType__dateTime">
              <div className="dateTime__text">DateTime</div>
              <input
                placeholder="Enter DateTime..."
                value={taskDateTime}
                className="chatScheduler__nameInput"
                onChange={setTimeFunc}
              />
            </div>
            <div className="taskType__dateTime">
              <div className="dateTime__text">Conversation Template</div>
              <input
                placeholder="Enter conversation temp.."
                value={conversationTemp}
                className="chatScheduler__nameInput"
                onChange={(e) => setconversationTemp(e.target.value)}
                disabled={
                  taskType === "MEDICATION" || !isRowSelected ? true : false
                }
              />
            </div>
            <div className="updateButtonClass">
              <button
                className="updateButton"
                onClick={() => updateStatusTimeNameFunc("update")}
              >
                Update
              </button>
            </div>
          </div>
        )}
        <div className="chatScheduler__schedule">
          <button
            className="chatScheduler__schedule__button"
            onClick={() => updateStatusTimeNameFunc("schedule")}
            disabled={isRowSelected ? false : true}
          >
            Auto Schedule
          </button>
        </div>
        <div className="updateButtonClass"></div>
      </div>
      <div className="chatScheduler__datagrid">
        <DataGrid
          dataSource={fetchedData}
          onRowClick={dataClickedFromGrid}
          selection={{ mode: "single" }}
          showBorders={true}
          hoverStateEnabled={true}
          keyExpr="taskId"
        >
          <Column
            dataField="taskId"
            caption="Task Id"
            dataType="string"
          ></Column>
          <Column
            dataField="taskName"
            caption="task Name"
            dataType="string"
          ></Column>
          <Column
            dataField="taskStatus"
            caption="task Status"
            dataType="string"
          ></Column>
          <Column
            dataField="callNumber"
            caption="Cell Number"
            dataType="string"
          ></Column>
          <Column
            dataField="taskType"
            caption="task Type"
            dataType="string"
          ></Column>
          <Column
            dataField="taskDateTime"
            caption="task Date Time"
            dataType="string"
          ></Column>
          <Column
            dataField="conversationTemplate"
            caption="Conversation Template"
            dataType="string"
          ></Column>
        </DataGrid>
      </div>
    </div>
  );
};

export default ChatScheduler;
