import React from "react";
import { useHistory } from "react-router-dom";
import { stringToDateFunc } from "../../../../../HighOrderFunctions/stringToDateFunc";
import { connect } from "react-redux";
import ButtonWBorder from "../../../../Buttons/ButtonWBorder/ButtonWBorder";
import * as actions from "../../../../../Store/actions/index";
import { returnYearOnly,ageModifier } from "../../../../../HighOrderFunctions/ageModifier";

import "./RCFDNameBody.css";
const RCFDNameBody = (props) => {
  const history = useHistory();

  const nameClicked = () => {
    props.setQRCodeToStoreFunc(props.qrCode);
    props.showNavigationFuncToStore(true);

    history.push("/monitoringStatus/" + props.qrCode);
  };
  // key={index}
  // rowNumber={index}
  // name={item["name"]}
  // dischargeDate={item["discharge_date"]}
  // dischargeDiagnosis={item["diagnosis"]}
  // qrCode={item["qr_code"]}
  // hospitalId={item["hospital_id"]}
  // timeSinceDischarge={item["time_since_discharge"]}
  // age={item["age"]}
  // gender={item["gender"]}
  let ageToBeShown = props.age && ageModifier(props.age);

  return (
    <div
      className={`rcfdNameBody__container 
      `}
    >
      <div className="rcfdNameBody__patientName" onClick={nameClicked}>
        {props.name},{ageToBeShown} {props.gender && ` ${props.gender.charAt(0)}`}
        <div className="rcfdNameBody__patientQRCode">{props.hospitalId}</div>
      </div>
      <div className="rcfdNameBody__discharge">
        <div className="rcfdNameBody__dischargeDateDiagnosis">
          {props.dischargeDiagnosis}
        </div>
        <div className="rcfdNameBody__dischargeDate">
          {stringToDateFunc(props.dischargeDate)}
        </div>
      </div>
      <div className="rcfdNameBody__statuses">
        {props.status.map((item,index)=>{
          return <div key={index} className="rcfdNameBody__status">{item}</div>
        })}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(null, mapDispatchToProps)(RCFDNameBody);
