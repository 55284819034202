import React, { useState, useEffect } from "react";
import "./AlertThresholdMainContainerBottom.css";
import AlertThresholdNotificationEdit from "./AlertThresholdNotificationEdit/AlertThresholdNotificationEdit";

import AlertThresholdNotificatinNonEdit from "./AlertThresholdNotificationNonEdit/AlertThresholdNotificationNonEdit";
import CircularIcon from "../../../../../../CircularIcon/CircularIcon";
import plusIcon from "../../../../../../../AllIcons/plus.png";
import axios from "axios";
import { connect,useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { insertAlertEndPoint,deleteAlertEndPoint } from "../../../../../../../HighOrderFunctions/APIs/allPostAPI";
const AlertThresholdMainContainerBottom = (props) => {
  const accessToken = useSelector(state=>state.auth.accessToken);
  const [alertConditions, setAlertConditions] = useState(props.alertConditions);
  const [saveAlertActive, setSaveAlertActive] = useState(true);
  const [rejectedSavedId, setRejectedSavedId] = useState(-1);
  
  // function to add new Alert Timeline for existing symptom in Alert Threshold
  const addNewNotificationAlertsTimeline = () => {
    let NewAlertTimelineForm = {
      symptom: props.symptomName,
      measure_type: props.measureType,

      day_start: "",
      day_end: "",
      relation: `${
        props.measureType === "THREE_POINT_SCALE"
          ? "EQUAL_TO"
          : props.measureType === "BINARY"
          ? "EQUAL_TO"
          : ""
      }`,
      threshold: `${props.measureType === "BINARY" ? "Present" : " "}`,
      id: "new-" + uuidv4(),
    };
    setAlertConditions((prev) => {
      let currentArray = prev;
      currentArray.push({
        ...NewAlertTimelineForm,
        index: `${prev.length + 1}`,
      });
      return [...currentArray];
    });
    setSaveAlertActive(true);
  };

  // function to save or update alert Threshold for existing symptom 
  const saveAlertFunc = (value, id, index) => {
    props.setShowMinimiseButton(false);
    setAlertConditions((prev) => {
      let currentArray = prev;
      currentArray[index] = value;
      return currentArray;
    });
    let valueToBeSaved = value;
    delete valueToBeSaved.index;
    if (id?.toString().includes("new") === true) delete valueToBeSaved.id;
    let requestBody = {
      ...valueToBeSaved,
      qr_code: `${props.qrCodeFromStore}`,
    };
    axios.post(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${insertAlertEndPoint}`,requestBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken} `,
        },
      }
      )
      .then(function (response) {
        if (response.data.status == "100") {
          setRejectedSavedId(index);
        } else {
          setRejectedSavedId(-1);
        }
      });
    };
    
    // function to delete alert Threshold for existing symptom from backend
    const deleteAlertFunc = (itemId, itemIndex) => {
      props.setShowMinimiseButton(false);
    let tempArray = alertConditions.filter((item, index) => {
      return index !== itemIndex;
    });
    axios.delete(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${deleteAlertEndPoint}/${itemId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken} `,
        },
      }
    );
    setAlertConditions(tempArray);
  };
  
  // rendering Alert Threshold in nonEditable Mode
  let notificationAlertsValue = alertConditions
  .map((item, index) => (
    <AlertThresholdNotificatinNonEdit
    key={index}
    day_start={item?.day_start}
    day_end={item?.day_end}
    relation={item?.relation}
    threshold={item?.threshold}
    measure_type={item?.measure_type}
    symptom={item?.symptom}
    id={item?.id}
    index={index}
    monitoringDuration={props.monitoringDuration}
      />
    ));

 // rendering Alert Threshold in editable Mode
  let AlertThresholdNotificationEditedValue = alertConditions.map(
    (item, index) => {
      return (
        <AlertThresholdNotificationEdit
          key={index}
          day_start={item.day_start}
          day_end={item.day_end}
          relation={item.relation}
          threshold={item.threshold}
          measure_type={item.measure_type}
          symptom={item.symptom}
          saveAlertFunc={saveAlertFunc}
          deleteAlertFunc={deleteAlertFunc}
          id={item?.id}
          index={index}
          saveAlertActive={saveAlertActive}
          setSaveAlertActive={setSaveAlertActive}
          newCard={props.newCard}
          rejectedSaving={index == rejectedSavedId}
          monitoringDuration={props.monitoringDuration}

        />
      );
    }
  );
// this useEffect will run to add new row in AlertThreshold for selected symptom, when red colored symptom(symptom caught in parser) replaced with new existing symptom in database
  let newCard = props.newCard;
  useEffect(() => {
    if (newCard) {
      addNewNotificationAlertsTimeline();
      newCard = false;
    }
  }, [newCard]);

  return (
    <div className="alertThresholdMainContainerBottom">
      <div style={{ width: "100%" }}>
        {props.editButtonClicked && (
          <div className="alertThresholdMainContainerBottom__editing">
            {AlertThresholdNotificationEditedValue}

            <div className="alertThresholdMainContainerBottom__addition">
              <CircularIcon
                imgsrc={plusIcon}
                clicked={addNewNotificationAlertsTimeline}
              />{" "}
            </div>
          </div>
        )}

        {!props.editButtonClicked && (
          <div className="notification__nonEditValues">
            <div className="notification__nonEditValue">
              {notificationAlertsValue}
            </div>
        
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(
  mapStateToProps,
  null
)(AlertThresholdMainContainerBottom);
