import React, { useRef, useState, useEffect } from "react";
import "./SignsSymptomsCharts.css";
import { Route, Switch, Redirect } from "react-router-dom";

import SymptomIndexList from "../../../../../Components/screensComponent/MonitoringStatus/SymptomIndexList/SymptomIndexList";
import NumberScaleChart from "./NumberScaleChart/NumberScaleChart";
import BinaryChart from "./BinaryChart/RashChart";
import ThreePointScaleChart from "./ThreePointScaleChart/PainChart";
import { getClinicalEntitiesEndPoint } from "../../../../../HighOrderFunctions/APIs/allGetAPI";
import NoChartData from "./NoChartData/NoChartData";
import Loader from "../../../../../Loader/LoaderThreeDot";
import { connect } from "react-redux";
import noChartDataImage from "../../../../../AllIcons/noChartDataImage.png";
import noChartMeasureErrorImage from "../../../../../AllIcons/noChartMeasureErrorImage.png";
import RangeChart from "./RangeChart/RangeChart";
import { useFetch } from "../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
const SignsSymptomsCharts = (props) => {
  const [toggleSymptomState, setToggleSymptomState] = useState(0);
  const [chartsForAlertLength, setChartsForAlertLength] = useState(0);

  // working of this function: 
  // we will run the map of clinicalEntities and will use index in clinicalEntities.map((item,index)) for showing and hiding charts
  // one in this component and other in SymptomIndexList
  
  // api to fetch list of names of  clinicalEntities to show charts in SignsSymptoms.js
  const { response: clinicalEntities, isLoading } = useFetch(
    `${getClinicalEntitiesEndPoint}/${props.qrCodeFromStore}`,
    false
  );

  useEffect(() => {
    if (Array.isArray(clinicalEntities) && clinicalEntities.length > 0) {
      setChartsForAlertLength(clinicalEntities.length);
    }
  }, [props.qrCodeFromStore, clinicalEntities.length]);

  // function to be passed in SymptomIndexList.js for showing list of symptoms and update index for showing and hiding charts
  const toggleSymptomTab = (index) => {
    setToggleSymptomState(index);
  };

  if (isLoading) {
    return (
      <div className="signsNSymptoms__loading">
        <Loader />
      </div>
    );
  }
  return (
    <React.Fragment>
      {chartsForAlertLength > 0 ? (
        <div className="signsSymptomsCharts__container">
          <div className="signsSymptomsCharts__header">
            <div className="signsSymptomsCharts__headerText">
              Signs & Symptoms
            </div>
          </div>
          <div className="signsSymptomsCharts__body">
            <div className="signsSymptomsCharts__bodyChart">
              {Array.isArray(clinicalEntities) &&
                clinicalEntities.map((entity, index) => {
                  // on basis of measure_type
                  if (entity.measure_type == "NUMBER_SCALE") {
                    let unitOfMeasurement = entity.unit_of_measurement;
                    if (entity.name === "fever") {
                      unitOfMeasurement = "Temperature, °F";
                    }
                    if (entity.name === "temperature") {
                      unitOfMeasurement = "Temperature, °F";
                    }

                    if (entity.name === "oxygen-saturation") {
                      unitOfMeasurement = "SPO2";
                    }
                    if (entity.name === "blood pressure") {
                      return (
                        <div
                          key={index}
                          index={index}
                          className={`symptomChart ${
                            toggleSymptomState === index && "symptomChartActive"
                          }`}
                        >
                          {toggleSymptomState === index && (
                            <RangeChart
                              unitOfMeasurement={entity.unit_of_measurement}
                              name={entity.name}
                            />
                          )}
                        </div>
                      );
                    }

                    return (
                      <div
                        key={index}
                        index={index}
                        className={`symptomChart ${
                          toggleSymptomState === index && "symptomChartActive"
                        }`}
                      >
                        {toggleSymptomState === index && (
                          <NumberScaleChart
                            key={`${entity.name}-${index}`}
                            name={entity.name.toLowerCase()}
                            unitOfMeasurement={unitOfMeasurement}
                            time={+index + 1000}
                          />
                        )}
                      </div>
                    );
                  } else if (entity.measure_type === "THREE_POINT_SCALE") {
                    return (
                      <div
                        key={index}
                        index={index}
                        className={`symptomChart ${
                          toggleSymptomState === index && "symptomChartActive"
                        }`}
                      >
                        <ThreePointScaleChart
                          unitOfMeasurement={entity.unit_of_measurement}
                          name={entity.name}
                        />
                      </div>
                    );
                  } else if (entity.measure_type === "BINARY") {
                    return (
                      <div
                        key={index}
                        index={index}
                        className={`symptomChart ${
                          toggleSymptomState === index && "symptomChartActive"
                        }`}
                      >
                        <BinaryChart
                          name={entity.name}
                          unitOfMeasurement={entity.unit_of_measurement}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        className={`symptomChart ${
                          toggleSymptomState === index && "symptomChartActive"
                        }`}
                      >
                        <div className="signsSymptomsCharts__noChartMeasureType">
                          <NoChartData
                            message="There was an error. Please contact the System Administrator"
                            imgsrc={noChartMeasureErrorImage}
                            size="120px"
                          />
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div className="signsSymptomsCharts__indexing">
            <SymptomIndexList
              toggleSymptomTab={toggleSymptomTab}
              toggleSymptomState={toggleSymptomState}
              clinicalEntities={clinicalEntities}
            />
          </div>
        </div>
      ) : (
        <div className="signsSymptomsCharts__noChartData">
          <NoChartData
            message=" No symptoms/vitals  logged so far"
            imgsrc={noChartDataImage}
          />
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(SignsSymptomsCharts);
