import React from "react";
import FollowUpActions from "./FollowUpActions/FollowUpActions";
import { Switch, Route, Redirect } from 'react-router-dom'

const FollowUpSchedules = () => {
  return (
    <div className="followUpSchedules">
      {routes }
    </div>
  );
};
let routes = (
  <Switch>
    <Route
      path="/patientAlerts/FollowUpSchedules/FollowUpActions/:td"
      component={FollowUpActions}
      />
    <Redirect to="/patientAlerts/FollowUpSchedules/FollowUpActions/:td" />
  </Switch>
);

export default FollowUpSchedules;
