import React, { useState, Fragment } from "react";
import RadioGroup from "devextreme-react/radio-group";
import { RequiredRule } from "devextreme-react/form";
import { Button, TextBox } from "devextreme-react";

const BotUtteranceControl = ({
  botId,
  removeBotUtterance,
  value,
  saveBotResponse,
}) => {
  return (
    <Fragment>
      <TextBox
        key={botId}
        botId={botId}
        name="bot_utterance"
        placeholder="Please provide a bot utterance..."
        value={value}
        onValueChanged={(event) => saveBotResponse(event.value, botId)}
      />
      <Button
        text="Remove"
        type="default"
        stylingMode="contained"
        onClick={() => removeBotUtterance(botId)}
      ></Button>
    </Fragment>
  );
};

const AttributesQuestionaire = (props) => {
  const [botUtterances, setBotUtterances] = useState([]);
  const [utteranceType, setUtteranceType] = useState(null);
  const [botForm, setBotForm] = useState({
    attributes: [
      {
        type: "ELICIT_FROM_PATIENT",
        bot_utterances: botUtterances,
        value: null,
        name: props.name.replace(/\b(\w)/g, (s) => s.toUpperCase()),
      },
    ],
    associated_symptoms: [],
    remedial_suggestions: [
      "Stay Hydrated. Ensure you are drinking a lot of water and juices.",
    ],
    symptom: props.symptom_name,
    utterance:
      "It is common to have fatigue after Chemotherapy. Are you feeling tired and exhausted?",
  });

  const utteranceTypeChanged = (e) => {
    setUtteranceType(e.value);
  };

  const addNewBotUtterance = () => {
    let temp = "";
    setBotUtterances((prev) => [...prev, temp]);
  };
  const saveBotResponse = (valuePassed, id) => {
    setBotUtterances((prev) => {
      let tempPrevValue = prev;
      tempPrevValue[id] = valuePassed;

      return tempPrevValue;
    });
    //updating botForm
    setBotForm((prev) => {
      let botAttributes = botUtterances;
      botAttributes[id] = valuePassed;
      return {
        ...prev,
        ["attributes"]: [
          
            ...prev["attributes"],

        ],
      };
    });
  };
  const removeBotUtterance = (botId) => {
    const botUtterancesFilter = botUtterances.filter((item, id) => {
      return id !== botId;
    });
    setBotUtterances(botUtterancesFilter);
  };

  // rendering bot uttrances in ui
  let observedBotUtterance = null;
  observedBotUtterance = botUtterances.map((item, index) => (
    <BotUtteranceControl
      key={index}
      removeBotUtterance={removeBotUtterance}
      saveBotResponse={saveBotResponse}
      value={item}
      botId={index}
    />
  ));
  return (
    <Fragment>
      <div
        style={{ margin: "10px", padding: "10px", border: "1px solid gray" }}
      >
        <div style={{ marginBottom: "10px" }}>
          Attribute: <b>{props.name}</b>
        </div>
        <div>
          Utterance:
          <RadioGroup
            layout="horizontal"
            items={["DEFAULT", "ELICIT_FROM_PATIENT"]}
            onValueChanged={utteranceTypeChanged}
          >
            <RequiredRule></RequiredRule>
          </RadioGroup>
        </div>
        {utteranceType === "DEFAULT" && (
          <TextBox
            name="utterance"
            placeholder="Please provide an utterance..."
          />
        )}

        {utteranceType === "ELICIT_FROM_PATIENT" && (
          <div>
            <div>{observedBotUtterance}</div>

            <Button
              text="+ ADD"
              type="default"
              stylingMode="contained"
              onClick={addNewBotUtterance}
            ></Button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default AttributesQuestionaire;
