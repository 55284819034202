import React from "react";
import CircularIcon from "../../../CircularIcon/CircularIcon";
import VengageLogo from "../../../../AllIcons/transparentVEngageLogo.png";
import "./NavbarMonitoringStatusScreen.css";
import NavbarPatientDetails from "../../NavbarPatientDetails/NavbarPatientDetails";
import NavbarUserLogout from "../../NavbarUserLogout/NavbarUserLogout";
const NavbarMonitoringStatusScreen = (props) => {
  return (
    <div className="vEngageNavbar__container">
      <div className="vEngageNavbar__Logo">
        <CircularIcon imgsrc={VengageLogo} imgsize="50px" size="60px" />
      </div>
      <div className="navBarNavigationApproval">
        <NavbarPatientDetails />
      </div>
     <NavbarUserLogout />
    </div>
  );
};



export default NavbarMonitoringStatusScreen;
