import React, { useEffect, useState } from "react";
import "./CovidRegistrationForm.css";
import InputCard from "../../../Components/Card/InputCard/InputCard";
import ModalCustomButton from "../../../Components/Buttons/ModalCustomButton/ModalCustomButton";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../Store/actions/index";
import { addNewCovidPatientEndPoint } from "../../../HighOrderFunctions/APIs/allPostAPI";
import businessman1 from "../../../AllIcons/businessman1.png";
import woman1 from "../../../AllIcons/woman1.png";
import DateCardCovid from "../../../Components/Card/InputCardForCovid/DateCardCovid/DateCardCovid";
import calendarImage from "../../../AllIcons/calendarImage.png";
import monthChange from "../../../HighOrderFunctions/MonthChange";
import { capitalizeFirstLetter } from "../../../HighOrderFunctions/shared/capitalizeFirstLetter";
import "./fade-transition.css";
import DateBox from "devextreme-react/date-box";
import SelectBox from "devextreme-react/select-box";
import {
  checkValidity,
  updateObject,
} from "../../../HighOrderFunctions/shared/utility";
import '../../../dx.common.css';
import '../../../dx.light.css';
const options = [
  {
    id: 1,
    name: "Male",
    value: "Male",
    imagesrc: `${businessman1}`,
  },
  {
    id: 2,
    name: "Female",
    value: "Female",
    imagesrc: `${woman1}`,
  },
];

const CovidRegistrationForm = (props) => {
  const [toggleDateOfBirth, setToggleDateOfBirth] = useState(false);
  const history = useHistory();
  const [formIsValid, setFormIsValid] = useState(false);
  const [currentDateValue, setCurrentDateValue] = useState(new Date());
  const [todaysDate, setTodaysDate] = useState(new Date());

  const [covidPatientForm, setCovidPatientForm] = useState({
    firstName: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
      },
    },
    lastName: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
      },
    },
    dateOfBirth: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        // required: true,
        dateValid: false,
      },
    },
    gender: {
      value: {},
      valid: false,
      clicked: false,
      validations: {
        required: true,
      },
    },
    hospitalId: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
        maxLength: 16,
        isAlphaNumeric: true,
      },
    },
    contactNumber: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
        isNumeric: true,
        maxLength: 10,
        minLength: 10,
      },
    },
  });
  const renderItem = (data) => {
    let imgsrc = `${businessman1}`;
    if (data.name == "Female") {
      imgsrc = `${woman1}`;
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={`${imgsrc}`} alt="gender" />
        <div className="covidRegistration__bodyGenderRenderItem">
          {data.name}{" "}
        </div>
      </div>
    );
  };

  // function to update covid Form
  const inputChangedHandler = (value, inputIdentifier) => {
    let valuePassed = value;
    let valueForValidityCheck = value;
    if (inputIdentifier == "contactNumber") {
      if (Number.isInteger(+value) && value.length <= 10) {
        valuePassed = value;
      } else {
        valueForValidityCheck = covidPatientForm["contactNumber"].value;
        valuePassed = covidPatientForm["contactNumber"].value;
      }
    }
    if (inputIdentifier == "dateOfBirth") {
      setCurrentDateValue(value);
      valuePassed = monthChange(value, "covidRegistrationForm");

      valueForValidityCheck = monthChange(value, "covidRegistrationForm");

      setToggleDateOfBirth(false);
    }
    if (inputIdentifier == "firstName") {
      valuePassed = capitalizeFirstLetter(valuePassed);
    }
    if (inputIdentifier == "lastName") {
      valuePassed = capitalizeFirstLetter(valuePassed);
    }
    if (inputIdentifier == "gender") {
      valueForValidityCheck = value;
    }
    if (inputIdentifier == "hospitalId") {
      valuePassed = valuePassed.toString().toUpperCase();
      valueForValidityCheck = valuePassed.toString().toUpperCase();
    }
    const updatedFormElement = updateObject(covidPatientForm[inputIdentifier], {
      value: valuePassed,
      clicked: true,
      valid: checkValidity(
        valueForValidityCheck,
        covidPatientForm[inputIdentifier].validations
      ),
    });
    const updatedCovidPatientForm = updateObject(covidPatientForm, {
      [inputIdentifier]: updatedFormElement,
    });

    // checking validation of covid Form

    let formIsValid = true;
    for (let inputIdentifier in updatedCovidPatientForm) {
      formIsValid =
        updatedCovidPatientForm[inputIdentifier].valid && formIsValid;
    }
    setCovidPatientForm(updatedCovidPatientForm);
    setFormIsValid(formIsValid);
  };

  const updatePatientDetailsFunc = () => {
    // "first_name":"Utkarsh","last_name":"Kushagra","dob":"1999-07-05","gender":"Male","hospital_id":"1317","contact":"9908732649"
    let dateForSend = new Date(covidPatientForm["dateOfBirth"].value);
    const requestBody = JSON.stringify({
      first_name: covidPatientForm["firstName"].value,
      last_name: covidPatientForm["lastName"].value,
      dob: monthChange(currentDateValue, "updateCovidDOB"),
      gender: covidPatientForm["gender"].value,
      hospital_id: covidPatientForm["hospitalId"].value,
      contact: covidPatientForm["contactNumber"].value,
    });
    let formData = new FormData();
    formData.append("metadata", requestBody);
    let requestOptions = {
      method: "POST",

      body: formData,

      redirect: "follow",
    };
    try {
      fetch(
        `${window["getConfig"].FILEUPLOAD_URL}/${addNewCovidPatientEndPoint}`,
        requestOptions
      ).then((res) => {
        if (res.status === 200) {
          props.fetchingCovidListReduxFlag(true);
          history.push("/covid-registration/registerCovidPatient");
        }
      });
    } catch (err) {}
  };

  const toggleDateOfBirthFunc = () => {
    setToggleDateOfBirth((prev) => !prev);
  };

  const navigateBackToRegisterCovidPatient = () => {
    history.replace("/covid-registration/registerCovidPatient/");
  };
  return (
    <div className="covidRegistrationForm">
      <div className="covidRegistrationForm__containerName">
        <div className="covidRegistrationForm__name">Enter patient details</div>
      </div>
      <div className="covidRegistrationForm__fullName">
        <div className="covidRegistrationForm__patientName">
          <div className="covidRegistrationForm__patientNameText">
            First name
          </div>
          <InputCard
            className="covidRegistrationForm__patientNameInput"
            value={covidPatientForm["firstName"].value}
            onChange={(val) => inputChangedHandler(val, "firstName")}
            width="100%"
            placeHolder="eg. Gerry"
            bgcolor="#FFFFFF"
            touchedNotValid={
              covidPatientForm["firstName"].clicked &&
              !covidPatientForm["firstName"].valid
            }
          />
        </div>
        <div className="covidRegistrationForm__patientName">
          <div className="covidRegistrationForm__patientNameText">
            Last name
          </div>
          <InputCard
            className="covidRegistrationForm__patientNameInput"
            value={covidPatientForm["lastName"].value}
            onChange={(val) => inputChangedHandler(val, "lastName")}
            width="100%"
            placeHolder="eg. Kelly  "
            bgcolor="#FFFFFF"
            touchedNotValid={
              covidPatientForm["lastName"].clicked &&
              !covidPatientForm["lastName"].valid
            }
          />
        </div>
      </div>

      <div className="covidRegistrationForm__hospitalName">
        <div className="covidRegistrationForm__dob">DOB</div>

        <div
          className="covidRegistrationForm__dobText"
          onClick={toggleDateOfBirthFunc}
        >
          
          <DateBox
            type="date"
            id="selected-date"
            value={currentDateValue}
            max={todaysDate}
            width="100%"
            height="100%"
            style={{ padding: "0px" }}
            placeholder="dd/mm/YYYY"
            displayFormat="dd/MM/yyyy"
            // defaultValue={new Date()}

            onValueChanged={(e) => inputChangedHandler(e.value, "dateOfBirth")}
          />
          <div className="warning-message">
            Please ensure that the Date of Birth entered is correct.
          </div>
        </div>
        {/* {toggleDateOfBirth && (
        <div
          className={`covidRegistrationForm__dobCalendar ${
            covidPatientForm["dateOfBirth"].clicked &&
            !covidPatientForm["dateOfBirth"].valid
              ? "touchedNotValidField"
              : ""
          }}`}
        >
          <DateCardCovid
            currentValue={currentDateValue}
            onDateChange={(value) => inputChangedHandler(value, "dateOfBirth")}
          />
        </div>
        )} */}
      </div>
      <div className="covidRegistrationForm__hospitalName">
        <div className="covidRegistrationForm__gender">Gender</div>
        {/* <SearchDropdown
          options={options}
          widthpassed="100%"
          label="name"
          id="name"
          value={covidPatientForm["gender"].value}
          onChangePassed={(value) => inputChangedHandler(value, "gender")}
          touchedNotValid={
            covidPatientForm["gender"].clicked &&
            !covidPatientForm["gender"].valid
          }
        /> */}

        <SelectBox 
        className="covidRegistrationForm__genderSelect"
          dataSource={options}
          displayExpr="name"
          placeholder="Select Gender ..."
          valueExpr="name"
          // defaultValue=""
          itemRender={renderItem}
          onValueChange={(value) => {
            return inputChangedHandler(value, "gender");
          }}
        />
      </div>
      <div className="covidRegistrationForm__hospitalName">
        <div className="covidRegistrationForm__hospitalId">Hospital ID</div>
        <InputCard
          placeHolder="eg. ABCDE9629"
          width="100%"
          bgcolor="#FFFFFF"
          value={covidPatientForm["hospitalId"].value}
          onChange={(val) => inputChangedHandler(val, "hospitalId")}
          touchedNotValid={
            covidPatientForm["hospitalId"].clicked &&
            !covidPatientForm["hospitalId"].valid
          }
        />
      </div>
      <div className="covidRegistrationForm__hospitalName">
        <div className="covidRegistrationForm__contactDetails">
          Contact Details
        </div>
        <InputCard
          placeHolder="eg. 9181549263"
          width="100%"
          bgcolor="#FFFFFF"
          value={covidPatientForm["contactNumber"].value}
          onChange={(val) => inputChangedHandler(val, "contactNumber")}
          touchedNotValid={
            covidPatientForm["contactNumber"].clicked &&
            !covidPatientForm["contactNumber"].valid
          }
        />
        <div className="warning-message">
          Please ensure that the Phone Number entered is correct.
        </div>
      </div>
      <div className="covidRegistrationForm__buttons">
        <div className="covidRegistrationForm__button">
          <ModalCustomButton
            key="1"
            status={false}
            name="Cancel"
            bgcolor="white"
            textColor="#E94444"
            clicked={navigateBackToRegisterCovidPatient}
          />
        </div>
        <div className="covidRegistrationForm__button">
          <ModalCustomButton
          status={true}
            name="Confirm"
            key="2"
            bgcolor="rgba(57, 129, 233, 0.68)"
            textColor="white"
            clicked={updatePatientDetailsFunc}
            disabled={!formIsValid}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchingCovidListReduxFlag: (status) =>
      dispatch(actions.fetchCovidPatientListAgain(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CovidRegistrationForm);
