import React from "react";
import CaseDetails from "./CaseDetails/CaseDetails";
import { Route, Switch, Redirect } from "react-router-dom";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";
import "./DischargeDetails.css";

const DischargeDetails = () => {
  return <div className="dischargeDetails">
    {routes}
    </div>;
};

let routes = (
  <Switch>
    {/* CaseDetails component contains all diets,exercise,medicalDevices dropdown and nurse schedule call  */}
    <Route
      path="/patientAlerts/DischargeDetails/caseDetails/:td"
      render={()=><ErrorBoundary><CaseDetails/></ErrorBoundary>}
    />
 
  
    <Redirect to="/patientAlerts/DischargeDetails/caseDetails/:td" />
  </Switch>
);
export default DischargeDetails;
