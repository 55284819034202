import {
  SUMMARYOFSYMPTOMS,
  SUMMARYOFEVENTS,
  SUMMARYOFCOMPLIANCE,
  RENDERED_VITALS_CHARTS,
  RENDERED_SYMPTOMS_CHARTS,
} from "../actions/actionTypes";

const initialState = {
  summaryOfSymptoms: false,
  summaryOfCompliance: false,
  summaryOfEvents: false,
  noOfVitalsChart: 0,
  noOfSymptomsChart: 0,
};

const fileuploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUMMARYOFSYMPTOMS:
      return {
        ...state,
        summaryOfSymptoms: action.fetchingStatus,
      };
    case SUMMARYOFEVENTS:
      return {
        ...state,
        summaryOfEvents: action.fetchingStatus,
      };
    case SUMMARYOFCOMPLIANCE:
      return {
        ...state,
        summaryOfCompliance: action.fetchingStatus,
      };
    case RENDERED_VITALS_CHARTS:
      return {
        ...state,
        noOfVitalsChart: action.renderedChart,
      };
    case RENDERED_SYMPTOMS_CHARTS:
      return {
        ...state,
        noOfSymptomsChart: action.renderedChart,
      };

    default:
      return state;
  }
};

export default fileuploadReducer;
