import React, { useState, useEffect } from "react";
import singlePatientDetails__medicalHistoryIcon from "../../../../../AllIcons/singlePatientDetails__medicalHistoryIcon.png";
import CircularIcon from "../../../../CircularIcon/CircularIcon";
import BackbuttonIcon from "../../../../../AllIcons/BackButtonIcon.png";
import * as actions from "../../../../../Store/actions/index";
import { useHistory } from "react-router-dom";
import { stringToDateFunc } from "../../../../../HighOrderFunctions/stringToDateFunc";
import { connect } from "react-redux";
import { ageModifier } from "../../../../../HighOrderFunctions/ageModifier";
import { getPatientDetailsEndPoint } from "../../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";

import "./SinglePatientDetails.css";
const SinglePatientDetails = (props) => {
  const history = useHistory();
  const [patientData, setPatientData] = useState([]);

  const fetchFunc = async () => {
    const response = await getFetchFunction(
      `${getPatientDetailsEndPoint}/${props.qrCodeFromStore}`,
      `${props.accessToken}`
    );
    return response;
  };
  useEffect(async () => {
    let result = await fetchFunc();
    // console.log("result : ", result[0]?.["is_monitoring_capsule_approved"]);
    // props.setIsMonitoringApproved(false);

    props.setIsMonitoringApproved(
      result[0]?.["is_monitoring_capsule_approved"]
    );
    setPatientData(result);
  }, []);

  useEffect(() => {
    if (Array.isArray(patientData) && patientData.length > 0) {
      props.setPatientDetailsToStore(patientData);
      props.setPrimaryContactNumberFunc(patientData[0]["contacts"][0]);
    }
  }, []);

  // useEffect for fetching Single Patient Details on the basis of redux state fetchPatientStatus
  useEffect(async () => {
    if (props.fetchPatientStatus == true) {
      let result = await fetchFunc();
      setPatientData(result);
      if (Array.isArray(patientData) && patientData.length > 0) {
        // props.setPatientDetailsToStore(patientData);
        props.setPrimaryContactNumberFunc(patientData[0]["contacts"][0]);
      }
      props.fetchPatientDetails(false);
    }
  }, [props.fetchPatientStatus]);

  const getBackTodashboardPageFunc = () => {
    props.resetPatientStore();
    props.resetNavLinkApproveStatus();
    history.push("/dashboard");
  };

  return (
    <div className="singlePatientDetails__container">
      <div className="singlePatientDetails__medicalHistoryIcon">
        <CircularIcon
          imgsrc={singlePatientDetails__medicalHistoryIcon}
          imgsize="30px"
          size="50px"
        />
      </div>
      <div className="singlePatientDetails__details1">
        <div className="patientAlign">
          <div className="singlePatientDetailsNameHeader__name">
            Full Name:{" "}
          </div>
          <div className="singlePatientDetailsNameBody__fullName">
            <p>{patientData[0]?.patientName}</p>
          </div>
        </div>

        <div className="patientAlign">
          <div className="singlePatientDetailsNameHeader__dob">Age: </div>
          <div className="singlePatientDetailsNameBody__dob">
            <p>{ageModifier(patientData[0]?.age)}</p>
          </div>
        </div>
        <div className="patientAlign">
          <div className="singlePatientDetailsNameHeader__mrn">MRN: </div>
          <div className="singlePatientDetailsNameBody__mrn">
            {patientData[0]?.hospitalId}
          </div>
        </div>
        <div className="patientAlign">
          <div className="singlePatientDetailsNameHeader__contactNumber">
            Contact:
          </div>
          <div className="singlePatientDetailsNameBody__contactNumber">
            {patientData[0]?.["contacts"][0]}
          </div>
        </div>
      </div>
      <div className="singlePatientDetails__details2">
        <div className="patientAlign">
          <div className="singlePatientDetailsNameHeader__dischargeContext">
            Discharge Context:
          </div>
          <div className="singlePatientDetailsNameBody__dischargeContext">
            {patientData[0]?.dischargeContext}
          </div>
        </div>
        <div className="patientAlign">
          <div className="singlePatientDetailsNameHeader__medicalDevices">
            Medical Devices:
          </div>
          <div className="singlePatientDetailsNameBody__medicalDevices">
            {Array.isArray(patientData[0]?.medicalDevices) &&
              patientData[0]?.medicalDevices.map((item, index) => {
                if (index == 0) {
                  return <span key={index}>{item}</span>;
                } else {
                  return <span key={index}> , {item} </span>;
                }
              })}
          </div>
        </div>
        <div className="patientAlign">
          <div className="singlePatientDetailsNameHeader__medicalDevices">
            Discharge Date:
          </div>
          <div className="singlePatientDetailsNameBody__medicalDevices">
            {stringToDateFunc(patientData[0]?.dischargeDate)}
          </div>
        </div>
        <div className="patientAlign">
          <div className="singlePatientDetailsNameHeader__medicalDevices">
            Monitoring Duration:
          </div>
          <div className="singlePatientDetailsNameBody__medicalDevices">
            {patientData[0]?.daysToMonitor}
          </div>
        </div>
      </div>
      <div className="backbuttonTodashboard">
        <CircularIcon
          imgsrc={BackbuttonIcon}
          size="38px"
          imgsize="38px"
          clicked={getBackTodashboardPageFunc}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    accessToken: state.auth.accessToken,
    fetchPatientStatus: state.patient.fetchPatientStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPatientStore: () => dispatch(actions.resetPatientState()),
    setPrimaryContactNumberFunc: (number) =>
      dispatch(actions.setPrimaryContactNumber(number)),
    setPatientDetailsToStore: (details) =>
      dispatch(actions.setPatientDetails(details)),
    resetNavLinkApproveStatus: () =>
      dispatch(actions.resetNavLinkApproveStatus()),
    fetchPatientDetails: (status) =>
      dispatch(actions.fetchPatientDetails(status)),
    setIsMonitoringApproved: (status) =>
      dispatch(actions.setIsMonitoringApproved(status)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SinglePatientDetails);
