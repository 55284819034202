import React from "react";
import './NavigationApproval.css'
import PatientAlertsApproval from "../../screensComponent/patientAlerts/PatientAlertsHeader/PatientAlertsApproval/PatientAlertsApproval";
import PatientAlertsNavigation from "../../screensComponent/patientAlerts/PatientAlertsNavigation/PatientAlertsNavigation";

const NavigationApproval = () => {
  return (
      <div className="navigationApproval__container">
        <div className="navBarNavigation">
          <PatientAlertsNavigation />
        </div>
        <div className="navBarApproval">
          <PatientAlertsApproval />
        </div>
      </div>
  );
};

export default NavigationApproval;
