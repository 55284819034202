import React, { useState, useEffect } from "react";
import SelectBox from "devextreme-react/select-box";
import Clock from "react-live-clock";
import DateBox from "devextreme-react/date-box";
import DateConversion from "../../HighOrderFunctions/MonthChange";
import { useSelector } from "react-redux";
import {
  getAllTaskNameEndPoint,
  getTaskByNameDayWiseEndPoint,
} from "../../HighOrderFunctions/APIs/allGetAPI";
import { insertUpdateTaskEndPoint } from "../../HighOrderFunctions/APIs/allPostAPI";
import DataGrid, { Column } from "devextreme-react/data-grid";
import "./GetTask.css";

// This Component will be used to fetch information about all the tasks of users or group of users 
const GetTask = () => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [dateTimeForAll, setDateTimeForAll] = useState("2021-07-12T08:15:00");
  const [dataGridSelectionMode, setDataGridSelectionMode] = useState("single");
  const [allTasksForDropdown, setAllTasksForDropDown] = useState([]);
  const [selectedLists, setSelectedLists] = useState({
    selectedRowKeys: [],
    selectedRowNamesList: [],
  });
  const [taskSelectedFromDropdown, setTaskSelectedFromDropdown] = useState({
    name: {
      value: "",
    },
    type: {
      value: "",
    },
    template: {
      value: "",
    },
  });

  const [currentDateValue, setCurrentDateValue] = useState(new Date());
  const [fetchedAllListForDataGrid, setFetchedAllListForDataGrid] = useState(
    []
  );
 
  const [selectedListFromDataGrid, setSelectedListFromDataGrid] = useState({
    summary_id: {
      value: "",
    },
    task_date_time: {
      value: "",
    },
    task_id: {
      value: "",
    },
    task_name: {
      value: "",
    },
    task_status: {
      value: "",
    },
    day_number: {
      value: "",
    },
  });
  const [selectedDateForSendingToBackend, setSelectedDateForSendingToBackend] =
    useState(DateConversion(new Date(), "updateCovidDOB"));
  const fetchAllTasksForDropdown = () => {
    const fetchAllTask = async () => {
      try {
        fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/${getAllTaskNameEndPoint}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken} `,
            },
          }
        )
          .then((response) => response.json())
          .then((result) => {
            let tempAllTasks = [];
            result.map((item, index) => {
              let task = {
                key: index,
                name: item["name"],
                type: item["type"],
                tempLate: item["template"] === null ? "NULL" : item["template"],
              };
              tempAllTasks.push(task);
            });

            setAllTasksForDropDown(tempAllTasks);
          });
      } catch (err) {
        console.log("Error occured! ", err);
      }
    };

    fetchAllTask();
  };
  useEffect(() => {
    fetchAllTasksForDropdown();
  }, []);
  const selectedTaskFromDropdownFunc = (selectedValue) => {
    setTaskSelectedFromDropdown({
      ...taskSelectedFromDropdown,
      ["name"]: { value: selectedValue["name"] },
      ["type"]: {
        value: selectedValue["type"],
      },
      ["template"]: {
        value: selectedValue["tempLate"],
      },
    });
  };


  const updateDateEntered = (dateSelected) => {
    setSelectedDateForSendingToBackend(
      DateConversion(dateSelected, "updateCovidDOB")
    );
    setCurrentDateValue(dateSelected);
  };
  const resetSelectedListFromDataGrid = () => {
    setSelectedListFromDataGrid({
      ...selectedListFromDataGrid,
      ["summary_id"]: {
        value: "",
      },
      ["task_date_time"]: {
        value: "",
      },
      ["task_id"]: {
        value: "",
      },
      ["task_status"]: {
        value: "",
      },
      ["task_name"]: {
        value: "",
      },
      ["day_number"]: {
        value: "",
      },
    });
  };
  const sendToBackendSelectedTaskFromDropDown = (condition) => {
    fetch(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${getTaskByNameDayWiseEndPoint}/${taskSelectedFromDropdown["name"]["value"]}/${selectedDateForSendingToBackend}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken} `,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setFetchedAllListForDataGrid(result);
        if (condition !== "updated") {
          resetSelectedListFromDataGrid();
        }
      });
  };
  // updating the single selected value in grid
  const updateSelectListFromDataGrid = (selectedListElement) => {
    setSelectedListFromDataGrid({
      ...selectedListFromDataGrid,
      ["summary_id"]: {
        value: selectedListElement["data"]["summary_id"],
      },
      ["task_date_time"]: {
        value: selectedListElement["data"]["task_date_time"],
      },
      ["task_id"]: {
        value: selectedListElement["data"]["task_id"],
      },
      ["task_status"]: {
        value: selectedListElement["data"]["task_status"],
      },
      ["task_name"]: {
        value: selectedListElement["data"]["task_name"],
      },
      ["day_number"]: {
        value: selectedListElement["data"]["day_number"],
      },
    });
  };
  const updateSelectListDateTimeFromDataGrid = (updatedDateTimeEntered) => {
    setSelectedListFromDataGrid({
      ...selectedListFromDataGrid,

      ["task_date_time"]: {
        value: updatedDateTimeEntered,
      },
    });
  };

  const updateDateTimeForAll = (updatedDateTimeEntered) => {
    setDateTimeForAll(updatedDateTimeEntered);
  };
  const sendSelectedValueInDataGridToBackend = () => {
    let updateJSONData = {
      day_number: selectedListFromDataGrid["day_number"]["value"],
      qr_code: selectedListFromDataGrid["summary_id"]["value"],
      task_date_time: selectedListFromDataGrid["task_date_time"]["value"],
      task_status: "PENDING",
      task_name: selectedListFromDataGrid["task_name"]["value"],
      task_id: selectedListFromDataGrid["task_id"]["value"],
    };
    let requestOptions = {
      method: "POST",
      body: JSON.stringify(updateJSONData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken} `,
      },
    };
    fetch(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${insertUpdateTaskEndPoint}`,
      requestOptions
    ).then((result) => {
      sendToBackendSelectedTaskFromDropDown("updated");
    });
  };

  // updating multiple date time in backend after selecting using checkbox.
  const updateMultipleValueInBackend = () => {
    let tempArray = [...selectedLists["selectedRowNamesList"]];
    let arrayRequired = [];
    tempArray.map((item) => {
      let updateJSONData = {
        day_number: item["day_number"],
        qr_code: item["summary_id"],
        task_date_time: dateTimeForAll,
        task_status: "PENDING",
        task_name: item["task_name"],
        task_id: item["task_id"],
      };
      arrayRequired.push(updateJSONData);
      return updateJSONData;
    });
    let requestOptions = {
      method: "POST",
      // body: JSON.stringify(updateJSONData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken} `,
      },
    };
    fetch(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${insertUpdateTaskEndPoint}`,
      requestOptions
    ).then((result) => {
      sendToBackendSelectedTaskFromDropDown("updated");
    });
  };


  const selectedValuesFromList = (value) => {
    setSelectedLists({
      selectedRowKeys: value.selectedRowKeys,
      selectedRowNamesList: value.selectedRowsData,
    });
  };
  return (
    <div style={{ height: "82vh", overflowY: "scroll" }}>
     
      <div className="allTasks__nameTypeTemplate">
        <div className="allTasks__name">
          <div className="allTasks__nameText">Task name</div>
          <SelectBox
            dataSource={allTasksForDropdown}
            searchEnabled={true}
            searchExpr="summary_id"
            className="allTasks__nameInput"
            displayExpr="name"
            width="245"
            onValueChange={(value) => selectedTaskFromDropdownFunc(value)}
            itemRender={(item) => {
              return item.name;
            }}
          ></SelectBox>
        </div>
        <div className="allTasks__type">
          <div className="allTasks__typeText">Task Type</div>
          <input
            type="text"
            className="allTasks__typeInput"
            value={taskSelectedFromDropdown["type"]["value"]}
            disabled
          />
        </div>
        <div className="allTasks__template">
          <div className="allTasks__templateText">Task Template</div>
          <input
            className="allTasks__templateInput"
            value={taskSelectedFromDropdown["template"]["value"]}
            disabled
          ></input>
        </div>

        <div className="allTasks__dayNumber">
          <div className="allTasks__dayNumberText">Date</div>
          <DateBox
            className="allTasks__dateBox"
            type="date"
            id="selected-date"
            value={currentDateValue}
            width="200px"
            height="100%"
            style={{ padding: "0px" }}
            placeholder="dd/mm/YYYY"
            displayFormat="dd/MM/yyyy"
            // defaultValue={new Date()}
            height="35px"
            onValueChanged={(e) => updateDateEntered(e.value)}
          />
        </div>

        <div className="allTasks__updateSelectedTask">
          <button
            className="allTasks__updateSelectedTaskButton"
            onClick={() => sendToBackendSelectedTaskFromDropDown("notUpdated")}
          >
            <span className="allTasks__updateSelectedTaskButtonText">
              Submit
            </span>
          </button>
        </div>
      </div>
      <div className="allTasks__nameTypeTemplate">
        <div className="select_selectionMode">
          <div className="select_serverUrl">Selection Mode: </div>
          <SelectBox
            dataSource={[
              { id: 1, mode: "single" },
              { id: 2, mode: "multiple" },
            ]}
            // searchEnabled={true}
            // searchExpr="name"
            className="allTasks__nameInput selectionModeInDatagrid"
            displayExpr="mode"
            value={dataGridSelectionMode}
            valueExpr="mode"
            width="120"
            onValueChange={(value) => {
              setDataGridSelectionMode(value);
            }}
          ></SelectBox>
        </div>
        {dataGridSelectionMode == "single" && (
          <>
            <div className="allTasks__type">
              <div className="allTasks__typeText">Summary Id</div>
              <input
                type="text"
                className="allTasks__summaryIdInput"
                value={selectedListFromDataGrid["summary_id"]["value"]}
                disabled
              />
            </div>
            <div className="allTasks__template">
              <div className="allTasks__templateText">Task Date Time</div>
              <input
                className="allTasks__taskDateTime"
                value={selectedListFromDataGrid["task_date_time"]["value"]}
                onChange={(event) => {
                  updateSelectListDateTimeFromDataGrid(event.target.value);
                }}
              ></input>
            </div>

            <div className="allTasks__updateSelectedTask">
              <button
                className="selectedTask__updateSelectedTaskButton"
                onClick={sendSelectedValueInDataGridToBackend}
              >
                <span className="selectedTask__updateSelectedTaskButtonText">
                  Update
                </span>
              </button>
            </div>
          </>
        )}
        {dataGridSelectionMode == "multiple" && (
          <>
            <div className="allTasks__MultipleDateTime">
              <div className="allTasks__templateText">
                Multiple Task {/*  */}Time
              </div>
              <input
                className="allTasks__templateInput"
                value={dateTimeForAll}
                onChange={(event) => {
                  updateDateTimeForAll(event.target.value);
                }}
              ></input>
            </div>

            <div className="allTasks__updateSelectedTask">
              <button
                className="selectedTask__updateSelectedTaskButton updateAllTogether"
                onClick={updateMultipleValueInBackend}
              >
                <span className="selectedTask__updateSelectedTaskButtonText">
                  Update Multiple
                </span>
              </button>
            </div>
          </>
        )}
        <div className="clockRunning">
          <Clock format={"DD-MM-YYYY, h:mm:ss A"} ticking={true} />
        </div>
      </div>
      <div className="getTaskForDemo__dataGrid">
        <DataGrid
          dataSource={fetchedAllListForDataGrid}
          onRowClick={updateSelectListFromDataGrid}
          onSelectionChanged={selectedValuesFromList}
          selectedRowKeys={selectedLists["selectedRowKeys"]}
          selection={{ mode: `${dataGridSelectionMode}` }}
          showBorders={true}
          hoverStateEnabled={true}
          keyExpr="task_id"
        >
          <Column
            dataField="task_id"
            caption="Task Id"
            dataType="string"
          ></Column>
          <Column
            dataField="summary_id"
            caption="Summary Id"
            dataType="string"
          ></Column>
          <Column
            dataField="task_name"
            caption="Task Name"
            dataType="string"
          ></Column>
          <Column
            dataField="day_number"
            caption="Day"
            dataType="string"
            width="80px"
          ></Column>
          {/* <Column
            dataField="conversation_template"
            caption="Conversation Template"
            dataType="string"
          ></Column> */}
          <Column
            dataField="call_number"
            caption="Call Number"
            dataType="string"
          ></Column>
          <Column
            dataField="task_date_time"
            caption="Task Date Time"
            dataType="string"
          ></Column>
          <Column
            dataField="task_status"
            caption="Task Status"
            dataType="string"
          ></Column>
        </DataGrid>
      </div>
    </div>
  );
};

export default GetTask;
