export function formatDate(date) {
  var d = new Date(date);

  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if(year.toString().includes("-")){
    year = year.toString().substring(1,5)
}
  date = [year, month, day].join("-");
  
  return date;
}
