import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MSNurseNotes from "./MSNurseNotes/MSNurseNotes";
import MSAlertRaisedList from "./MSAlertRaisedList/MSAlertRaisedList";
import PatientMessages from "./PatientMessages/PatientMessages";
import PicturesUploaded from "./PicturesUploaded/PicturesUploaded";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "& .MuiAccordionSummary-content": {
    padding: "0px 10px",
    marginLeft: theme.spacing(1),
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.68)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1bh-content" id="panel1d-header">
          <Typography>
            <div className="monitoringStatusSidebar__textHeader">
            Nursing Notes
            </div>
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <MSNurseNotes />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
          <div className="monitoringStatusSidebar__textHeader">
          List of Alerts Raised
            </div>
            
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <MSAlertRaisedList />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
          <div className="monitoringStatusSidebar__textHeader">
            Patient Messages
          
            </div>
            
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <PatientMessages />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography>
          <div className="monitoringStatusSidebar__textHeader">
            Pictures Uploaded

            </div>
            
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <PicturesUploaded />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
