import React, { useState, useEffect } from "react";
import "./ReadingThroughSummary.css";
import NewDischargeHeader from "../NewDischargeHeader/NewDischargeHeader";
import { ProgressBar } from "devextreme-react/progress-bar";
import { uploadDocxEndPoint } from "../../../HighOrderFunctions/APIs/allPostAPI";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/actions/index";
import { connect } from "react-redux";
import ErrorModal from "../../../modals/ErrorModal/ErrorModal";
import { showNotificationWithMessage } from "../../../Store/actions/DashboardAction";
const ReadingThroughSummary = (props) => {
  const dispatch = useDispatch();

  const file = useSelector((state) => state.patient.dischargeSummary);
  const token = useSelector((state) => state.auth.accessToken);
  const dischargeContext = useSelector(
    (state) => state.patient.dischargeContext
  );
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [modalText, setModalText] = useState("Files not found");
  const [showError, setShowError] = useState(false);

  const history = useHistory();
  const showErrorFunc = () => {
    setShowError(false);
    history.push("/ds-upload/fileUpload");
  };

  const [seconds, setSeconds] = useState(0);
  const timer = () => {
    if (seconds == 120) {
      setSeconds((prev) => 0);
    } else {
      setSeconds((prev) => +prev + 4);
    }
  };
  const fileSubmitting = () => {
    let intervalId = setInterval(timer, 1000);
    if (file && dischargeContext) {
      console.log(file);
      if (file.type == "application/pdf") {
        dispatch(
          showNotificationWithMessage({
            variant: "info",
            message:
              "This process can take few minutes. Please be patient",
          })
        );
      }
      var formdata = new FormData();
      formdata.append("file", file);
      formdata.append("dischargeContext", JSON.stringify(dischargeContext));
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
        headers: {
          Authorization: `Bearer ${token} `,
        },
      };
      try {
        fetch(
          `${window["getConfig"].FILEUPLOAD_URL}/${uploadDocxEndPoint}`,
          requestOptions
        )
          .then((response) => {
            setUploadingStatus(false);

            return response.json();
          })
          .then((result) => {
            setSeconds(120);
            clearInterval(intervalId);
            if (result.status == 406) {
              setShowError(true);
              setModalText(result.message);
            } else if (result.status == 422) {
              setShowError(true);
              setModalText(result.message);
            }
            if (result.status == 500) {
              setShowError(true);
              setModalText(result.message);
            }

            setUploadingStatus(false);
            if (result.status == 201) {
              props.setQrCodeOnUpload(result["qr_code"], false);
              props.setQRCodeToStoreFunc(result["qr_code"]);
              history.push(
                `/ds-upload/UDPatientPersonalDetails/${result["qr_code"]}`
              );
            }
          });
      } catch (error) {
        clearInterval(intervalId);
        setUploadingStatus(false);
        console.warn(error);
        setShowError(true);
        return;
      }
    }
  };
  useEffect(() => {
    if (dischargeContext && file && token) {
      fileSubmitting();
    }
  }, []);
  const secondForProgressBar = (seconds) => {
    seconds = seconds == 116 ? 0 : seconds;
    return seconds;
  };

  return (
    <>
      <div className="readingThroughSummary__container">
        <div className="readingThroughSummary__newDischargeHeader">
          <NewDischargeHeader />
        </div>
        <div className="readingThroughSummary__body">
          <div className="readingThroughSummary__bodyText">
            Reading through Discharge Summary
          </div>
          <div className="readingThroughSummary__progressBar">
            <ProgressBar
              className={seconds == 120 ? "complete" : ""}
              min={0}
              max={120}
              value={secondForProgressBar()}
              showStatus={false}
            />
          </div>
        </div>
      </div>
      <div>
        {showError && (
          <ErrorModal
            message={modalText}
            setOnlineStatus={showErrorFunc}
          ></ErrorModal>
        )}
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchingStatusToFalseFunc: () => dispatch(actions.fetchingAgain(false)),
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
    setPatientDetailsToStore: (details) =>
      dispatch(actions.setPatientDetails(details)),
    setQrCodeOnUpload: (qrCode, status) =>
      dispatch(actions.setQRCodeOnUpload(qrCode, status)),
  };
};

export default connect(null, mapDispatchToProps)(ReadingThroughSummary);
