import React, { useState, useEffect } from "react";
import { jsonModification } from "./jsonToTableFunction";
import { JsonToTable } from "react-json-to-table";
import { getMongoDataEndPoint } from "../../HighOrderFunctions/APIs/allGetAPI";
import "./JsonToTable.css";
import { useSelector } from "react-redux";
// This component will be used to display mongoDB Data in table format 
const JsonToTableComponent = () => {
  const accessToken = useSelector(state=>state.auth.accessToken);
  const [mongoJSON, setMongoJSON] = useState([]);
  const [qrCode, setQRCode] = useState("");
  const [emptyData, setEmptyData] = useState(false);
 
//  function to fetch mongo Data 
  const fetchMongoData = async () => {
    if (qrCode.length == 6) {
      try {
        const response = await fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/${getMongoDataEndPoint}/${qrCode}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken} `,
      
            },
          }
        );
        const result = await response.json();
        let tempJSON = [];
        result.map((item, index) => {
          tempJSON.push(jsonModification(item));
        });

        setMongoJSON(tempJSON);
        if (result.length == 0) {
          setEmptyData(true);
        } else {
          setEmptyData(false);
        }
        return result;
      } catch (err) {
        setEmptyData(true);
        console.log(err);
      }
    } else {
      alert("Enter valid QR-code");
    }
  };
 
  // parameters which can be used to filter data 
  const [searchColumns, setSearchColumns] = useState([
    "symptom",
    "day_number",
    "clinical_entity"
  ]);
  const [q, setQ] = useState("");

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column] &&
          row[column]
            .toString()
            .toLowerCase()
            .replaceAll(" ", "")
            .replaceAll("-", "")
            .indexOf(q.toLowerCase().replaceAll(" ", "").replaceAll("-", "")) >
            -1
      )
    );
  }
  const setInputValueFunc = (e) => {
    setQ(e.target.value);
  };
  let renderedData = null;
  
  // the component JsonToTable  will be used to create table from JSON 
  renderedData = search(mongoJSON).map((item, index) => {
    return (
      <div style={{ paddingTop: "20px" }} key={index}>
        <h3>Data: - {index + 1}</h3>
        <JsonToTable json={item} />
      </div>
    );
  });

  return (
    <div>
 
      <div className="allTasks__nameTypeTemplate">
        <div className="allTasks__template">
          <div className="allTasks__templateText">Enter QR - code: </div>
          <input
            className="allTasks__templateInput"
            value={qrCode}
            onChange={(e) => setQRCode(e.target.value.toUpperCase())}
          ></input>
        </div>

        <div className="allTasks__updateSelectedTask">
          <button
            className="allTasks__updateSelectedTaskButton patientPanel__submitButton"
            onClick={fetchMongoData}
            disabled={qrCode.length !== 6}
          >
            <span className="allTasks__updateSelectedTaskButtonText">
              Submit
            </span>
          </button>
        </div>

        <div className="allTasks__searchSymptoms">
          <div className="allTasks__templateText">Search Symptoms/DayNumber: </div>
          <input
            className="allTasks__templateInput"
            value={q}
            onChange={setInputValueFunc}
          ></input>
        </div>
      </div>
      {!emptyData ? (
        <div>{renderedData}</div>
      ) : (
        <div
          className="error-message"
          style={{ textAlign: "center", width: "100%" }}
        >
          No Data Available.!
        </div>
      )}
    </div>
  );
};

export default JsonToTableComponent;
