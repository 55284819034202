import React, { useState, useEffect } from "react";
import { connect,useSelector } from "react-redux";
import SearchDevExtreme from "./SearchDevExtreme";
import { postFetchFunction } from "../../../HighOrderFunctions/FetchMethod/postFetchFunction";

import { exerciseEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { dynamicSort } from "../../../HighOrderFunctions/dynamicSort";
import {insertExerciseEndPoint} from '../../../HighOrderFunctions/APIs/allPostAPI'
function ExerciseSearchDropdown(props) {
  const accessToken = useSelector(state=>state.auth.accessToken);
  let {response,isLoading} = useFetch(`${exerciseEndPoint}`,false)
  useEffect(()=>{


    if(Array.isArray(response) && response.length > 0){
      let sortedData = response.sort(dynamicSort("exercise_name"));
      response = sortedData;
    }else{
      response = [];
    }
    return ()=>{
      response=[]
    }
  },[])

  const updateExercise = (updatedExercise) => {
    let requestBody = {
      qr_code: props.qrCodeFromStore,
      exercise: updatedExercise && updatedExercise["exercise_name"],
    }
    const result = postFetchFunction(`${insertExerciseEndPoint}`,requestBody,accessToken);
    props.setValue(updatedExercise);
   
  };
  return (
    <div
      className="devextremeDropdown__container"
      style={{ width: props.width }}
      disabled={props.disabled}
    >
     
      <SearchDevExtreme
        placeholder="Select Exercise"
        optionsFetched={response}
        displayName="exercise_name"
        displayExpr="exercise_name"
        valueExpr="exercise_name"
        defaultValue={props.value}
        updatedSelectedValue={updateExercise}
        disabled={props.disabled && props.disabled}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(ExerciseSearchDropdown);
