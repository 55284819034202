import React, { useState, useRef, useEffect ,useCallback} from "react";
import MonitoringStatusHeader from "./MonitoringStatusHeader/MonitoringStatusHeader";
import MonitoringStatusSidebar from "./MonitoringStatusSidebar/MonitoringStatusSidebar";
import MonitoringStatusBody from "./MonitoringStatusBody/MonitoringStatusBody";
import "./MonitoringStatus.css";
import ChatScreen from "../ChatScreen/ChatScreen";

// Nurse Screen and Chat Screen combined 
const MonitoringStatus = () => {
  const [showChat, setShowChat] = useState(true);
  const [scrollChatToBottom,setScrollChatToBottom] = useState(false);

 
  return (
    <div
      className={`monitoringStatus__Graphchat ${
        showChat ? "monitoringStatus__scrollToBottom" : ""
      }`}
    >
      <div className="monitoringStatus__container">
        <div className="monitoringStatus__header">
          <MonitoringStatusHeader />
        </div>
        <div className="monitoringStatus__sidebar">
          <MonitoringStatusSidebar />
        </div>

        <div className="monitoringStatus__body">
          <MonitoringStatusBody />
        </div>
      </div>
     {/* displaying chat screen  */}
      <div className="monitoringStatus__chatScreen">
        <ChatScreen 
        scrollChatToBottom ={scrollChatToBottom}
        setScrollChatToBottom ={setScrollChatToBottom}
        />
      </div>
    </div>
  );
};

export default MonitoringStatus;


// const observer = useRef();
// const lastBookElementRef = useCallback((node) => {
  //   if (observer.current) observer.current.disconnect();
  //   observer.current = new IntersectionObserver((entries) => {
    //     if (entries[0].isIntersecting) {
      //       setScrollChatToBottom(true);
      //     }
      //   });
      //   if (node) {
        //     observer.current.observe(node);
        //   }
        // }, []);
        
{/* <div ref={lastBookElementRef} key={`${Math.random(0,100)}`}></div> */}