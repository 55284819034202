import React, { useState, useEffect } from "react";
import "./MSAlertRaisedListComponent.css";
const MSAlertRaisedListComponent = (props) => {
  let renderedData = null;
  renderedData = props.data.map((item, index) => {
    return (
      <div
        className="msAlertRaisedListComponent__timeMessage"
        key={`${item} - ${index}`}
      >
        <div className="msAlertRaisedListComponent__time">{item.time}</div>
        <div className="msAlertRaisedListComponent__message">{item.notification}</div>
      </div>
    );
  });
  return (
    <div className="msAlertRaisedListComponent__container">
      <div className="msAlertRaisedListComponent__dayNumberDay">
        <div className="msAlertRaisedListComponent__dayNumber">
          Day {props.dayNumber}{" "}
          <span className="msNurseComponent__day">({props.day})</span>
        </div>
      </div>
      <div className="msAlertRaisedListComponent__timeAndMessage">{renderedData}</div>
    </div>
  );
};

export default MSAlertRaisedListComponent;
