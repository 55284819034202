import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { beautifyUserName } from "../../../HighOrderFunctions/beautifyUserName";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
const NavbarUserLogout = () => {
  const { logout, user } = useAuth0();

  return (
    <div className="vEnageNavbar__postDischargeNurseDetails">
      <div>
        <div className="vEnageNavbar__postDischargeNurse">
          <div className="vEnageNavbar__postDischargeText">
            <p> {user.name && beautifyUserName(user.name)}</p>
          </div>
          <div className="vEnageNavbar__logout">
            <p
              onClick={() => {
                localStorage.removeItem("state");
                logout({ returnTo: window.location.origin });
              }}
              className="vEnageNavbar__logoutText"
            >
              <PowerSettingsNewIcon />
            </p>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default NavbarUserLogout;
