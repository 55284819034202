export function dayDifference(currentValue,startDay, endDay) {
  let diff = -1;
  if(startDay =="" || startDay == undefined || startDay == null){
      return 0;
  } 
  if(endDay =="" || endDay == undefined || endDay == null){
      return 0;
  }
  currentValue = +currentValue
  startDay = +startDay;
  endDay = +endDay;
  if(!Number.isInteger(currentValue) || !Number.isInteger(startDay) || !Number.isInteger(endDay)){
     return 0;
  }
  else{

      
      if (startDay < 0) {
          return currentValue;
        } else if (startDay < 0 && endDay >= 0) {
            return currentValue;
        } else if (startDay >= 0 && endDay >= 0) {
            diff = +endDay - +startDay;
            if (diff < 0) {
                return currentValue;
            } else {
                return +currentValue + diff + 1;
            }
        }else{
            return currentValue;
        }
    }
    
}