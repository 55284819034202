import React from 'react';
import './NavbarOnCallRegistration.css';
import triangleBack from '../../../AllIcons/triangleBack.png';
import { useHistory } from 'react-router';
const NavbarOnCallRegistration = () => {
    const history = useHistory();
    const navigateBackToDashboardScreen = () => {
        history.push("/dashboard");
      };
    return (
        <div className="navbarOnCallRegistration__container">
        <div className="navbarOnCallRegistration__text">
            On-Call Registration
        </div>
        <div className="navbarOnCallRegistration__navigateToDashboard"
        onClick={navigateBackToDashboardScreen}>
            <div className="navbarOnCallRegistration__navigateToBackIcon">
                <img src={triangleBack} width="10px" alt="back" />
            </div>
            <div className="navbarOnCallRegistration__navigateToDashboardText">Nurse Dashboard</div>
        </div>
          
        </div>
    )
}

export default NavbarOnCallRegistration
