import React from 'react'
import './CircularIconWTextBelow.css'
const CircularIconWTextBelow = (props) => {
    return (
        <div className="circularIconWTextBelow__container" onClick={props.clicked}>
        <div className="circularIconWTextBelow__circularIcon">

            {/* <CircularIcon 
            imgsrc={props.imgsrc}
            size={props.size}
            clicked={props.clicked}
            /> */}
            <img src={props.imgsrc}
            style={{width:props.size,height:props.size}}
            />
            </div>
            <div className="circularIconWTextBelow__textBottom">
             {props.text}
            </div>
        </div>
    )
}

export default CircularIconWTextBelow
