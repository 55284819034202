import React from "react";
import "./ChatHeader.css";
import CircularIcon from "../../../Components/CircularIcon/CircularIcon";
import doubleArrowUp from '../../../AllIcons/doubleArrowUp.png';
import doubleArrowDown from '../../../AllIcons/arrowDown.png';
import ToggleSwitch from '../../../Components/Buttons/ToggleSwitch/ToggleSwitch';
// import ToggleSwitchSlider from '../../../Components/Buttons/ToggleSwitchSlide/ToggleSwitchSlide';
const ChatHeader = (props) => {
  return (
    <div className="chatHeader__container">
      <div className="chatHeader__header">
        <div className="chatHeader__headerText">Chat transcripts</div>
        <div className="chatHeader__date"></div>
      </div>
      {/* <div className="chatHeader__scrolling">
        <CircularIcon
          bgcolor="#F6F6F6"
          name="scrollToTop"
          clicked={props.scrollChatToTopFunc}
          imgsrc={doubleArrowDown}
          rotateImgY="true"
        />
        <CircularIcon
          bgcolor="#F6F6F6"
          name="scrollToBottom"
          clicked={props.scrollChatToBottomFunc}
          imgsrc={doubleArrowDown}
        />
      </div> */}
      <ToggleSwitch {...props} />
      {/* <ToggleSwitchSlider {...props} /> */}
    </div>
  );
};

export default ChatHeader;
