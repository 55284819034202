import React from "react";
import triangleUpside from '../../../../../../../AllIcons/triangleTop.png';
import "./AppointmentCallMonitoringDate.css";
const AppointmentCallMonitoringDate = ({ discharge, Date, day }) => {

  return (
    <div className="appointmentCallMonitoringDate__container">
      <p className="appointmentCallMonitoringDate__dischargeStatus">{discharge}</p>
      <p className="appointmentCallMonitoringDate__date">{Date}</p>
      <p className="appointmentCallMonitoringDate__arrowUpIcon">
      <img
            src={triangleUpside}
            style={{ width: "10px", height: "10px" }}
          /> 
      </p>
    </div>
  );
};

export default AppointmentCallMonitoringDate;
