import React from "react";
import maxHomeLogo from "../../../../../../AllIcons/maxhomelogo.png";
// import AsterLogo from "../../../../../AllIcons/aster_logo.png";
import AsterLogo from "../../../../../../AllIcons/aster_logo.png";

import Footer from "../Footer";
import "./PdfLastPage.css";
const PdfLastPage = (props) => {
  return (
    <div className="pdfLastPage">
      <div className="pdfLastPage__mainContainer">
        <img
          src={maxHomeLogo}
          // src={AsterLogo}
          alt="maxHome_logo"
          className="pdfLastPage__logo"
        />
      </div>
      <div className="pdfLastPage__footer"></div>
      <Footer
        hospitalNo={props.hospitalNo}
        showMaxLogo={true}
        class_name="center_mobileno"
      />
    </div>
  );
};

export default PdfLastPage;
