import React, { useState, useEffect } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  RequiredRule,
  FilterRow,
} from "devextreme-react/data-grid";
import axios from "axios";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { relationTypes } from "../data";



function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};


  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }



  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}
// using (t_ref_clinical_entity and t_ref_discharge_context table ) for Lookup 
const MonitoringSymptoms = () => {
  const [searchText,setSearchText] = useState("");
  const [allDischargeContexts, setAllDischargeContexts] = useState([]);
  const [allSymptoms, setAllSymptoms] = useState([]);

  const fetchAll = async () => {
    axios
      .all([
        axios.get(`${window["getConfig"].BACKEND_SERVICE_URL}/discharge-context/all?searchtext=${searchText}`),
        axios.get(`${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity/all?searchtext=${searchText}`),
      ])
      .then((responseArray) => {
        setAllDischargeContexts(responseArray[0].data);
        setAllSymptoms(responseArray[1].data);
      });
  };

  const store = new CustomStore({
    key: "alert_symptoms_id",
    load: function (loadOptions) {
      return fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/monitoring-symptoms?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
      )
        .then((response) => response.json())
        .then((data) => {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount,
          };
        })
        .catch(() => {
          throw "Data Loading Error";
        });
    },
    insert: (values) =>
      sendRequest(`${window["getConfig"].BACKEND_SERVICE_URL}/monitoring-symptoms`, "POST", values),
    update: (key, values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/monitoring-symptoms/${key}`,
        "PUT",
        values
      ),
    remove: (key) =>
      sendRequest(`${window["getConfig"].BACKEND_SERVICE_URL}/monitoring-symptoms/${key}`, "DELETE"),
    
  });
  const calculateCellValueFunc = (data) => {
    if (data.measure_type == 1) {
      return "NUMBER_SCALE";
    } else if (data.measure_type == 2) {
      return "BINARY";
    } else if (data.measure_type == 3) {
      return "THREE_POINT_SCALE";
    }
  };
  const onOptionChanged = (e) => {
    if(e.fullName === "searchPanel.text") {
        setSearchText(e.value);
    }
}


  useEffect(() => {
    fetchAll();
  }, []);
  
  if (allSymptoms.length === 0 && allDischargeContexts.length === 0)
    return null;
  return (
    <DataGrid
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnResizing={true}
      allowColumnReordering={false}
      sorting={false}
      onOptionChanged={onOptionChanged}

    >
      <SearchPanel visible={true} width={240} placeholder="Search..." text={searchText} />
      <Editing
        refreshMode="reshape"
        mode="popup"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />

      
      <Column
        caption="Discharge context"
        dataField="discharge_context_id"
        dataType="string"
        allowFiltering={true}
      >
        <Lookup dataSource={allDischargeContexts} displayExpr="context" valueExpr="discharge_context_id" />
        <RequiredRule />
      </Column>
    
      <Column
        caption="Clinical Symptom"
        dataField="t_ref_clinical_entity_id"
        dataType="string"
        
        
      >
        <Lookup dataSource={allSymptoms} displayExpr="entity_name" valueExpr="t_ref_clinical_entity_id" />
        <RequiredRule />
      </Column>
      <Column
        dataField="day_start"
        width="80"
        dataType="number"
      ></Column>
      <Column
        dataField="day_end"
        width="100"
        dataType="number"
      ></Column>
      <Column dataField="relation" dataType="string" width="150">
      <Lookup dataSource={relationTypes} displayExpr="name" valueExpr="name" />

      </Column>
      <Column dataField="threshold" dataType="string" ></Column>

      <Paging defaultPageSize={15} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[10, 15, 20]} />
    </DataGrid>
  );
};

export default MonitoringSymptoms;
