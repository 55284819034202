import React, { useState, useEffect } from "react";
import SearchDevExtreme from "./SearchDevExtreme";
import { dynamicSort } from "../../../HighOrderFunctions/dynamicSort";
import {getDischargeContextEndPoint} from '../../../HighOrderFunctions/APIs/allGetAPI';
import {useFetch} from '../../../HighOrderFunctions/FetchMethod/getFetchFunction'
function DischargeContextSearchDropdown(props) {
  let { response, isLoading } = useFetch(
    getDischargeContextEndPoint,
    false
  );

  response = Array.isArray(response)? response.sort(dynamicSort("context")):[] 

  return (
    <div
      className="devextremeDropdown__container"
      style={{ width: props.width }}
      disabled={props.disabled}
    >
      <SearchDevExtreme
        placeholder="Select DischargeContext"
        optionsFetched={Array.isArray(response)? response:[] }
        displayName="context"
        displayExpr="context"
        valueExpr="id"
        updatedSelectedValue={props.setValue}
      />
    </div>
  );
}

export default DischargeContextSearchDropdown;
