import React from "react";

import ChatSchedulerStaging from "./ChatScheduler/ChatScheduler";
import GetTaskForStaging from "./ChatScheduler/GetTask";
import JsonToTableComponent from "./JsonToTable/JsonToTable";
import { TabPanel, Item } from "devextreme-react/tab-panel";

class App extends React.Component {
  render() {

    return (
      <div style={{ padding: "2px" }}>
        <TabPanel>
          <Item
            title={"ChatScheduler"}
            component={ChatSchedulerStaging}
          />
          <Item title={"GetTask"} component={GetTaskForStaging} />
          <Item title={"JsonToTable"} component={JsonToTableComponent} />
        </TabPanel>
      </div>
    );
  }
}

export default App;
