import React from "react";
import Dashboard from "./Dashboard";
import NavbarDashboardScreen from "../../Components/Navbar/NavbarScreens/NavbarDashboardScreen/NavbarDashboardScreen";
import './DashboardWithNavbar.css'
const DashboardWithNavbar = () => {
  return (
    <div className="dashboardWithNavbar__container">
      <div className="dashboardWithNavbar__vEngageNavbarHeader">
        <NavbarDashboardScreen />
      </div>
      <div className="dashboardWithNavbar__bodyContent">
        <Dashboard />
      </div>
      <div className="dashboardWithNavbar__footer"></div>
    </div>
  );
};

export default DashboardWithNavbar;
