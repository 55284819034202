import React, { useState, useEffect, useRef } from "react";
import "./UploadDischargeNameBody.css";
import mobilephoneS2 from "../../../../AllIcons/mobilephoneS2.png";
import verifyS2 from "../../../../AllIcons/verifyS2.png";
import StatusTwoIcons from "../../../StatusTwoIcons/StatusTwoIcons";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import * as actions from "../../../../Store/actions/index";
import { ageModifier } from "../../../../HighOrderFunctions/ageModifier";
import SendInvitation from "../../../sendInvitation/SendInvitation";
import DateBox from "devextreme-react/date-box";

import CircularIcon from "../../../CircularIcon/CircularIcon";

import editIcon from "../../../../AllIcons/editIcon.png";
import closeCrossIcon from "../../../../AllIcons/closeCrossIcon.png";

import saveFileIcon from "../../../../AllIcons/saveFileIcon.png";
import { inlineUpdateEndPoint } from "../../../../HighOrderFunctions/APIs/allPostAPI";
import {
  checkValidity,
  updateObject,
} from "../../../../HighOrderFunctions/shared/utility";
import { resendWhatsappMessageEndPoint } from "../../../../HighOrderFunctions/APIs/allPostAPI";
import monthChange from "../../../../HighOrderFunctions/MonthChange";
import ModalDelete from "../../../../modals/ModalDelete/ModalDelete";
import {
  getOverRideMobileNumberEndPoint,
  discardDischargeSummaryEndPoint,
} from "../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { capitalizeFirstLetter } from "../../../../HighOrderFunctions/shared/capitalizeFirstLetter";
import deleteIcon from "../../../../AllIcons/deleteIcon.png";
const UploadDischargeNameBody = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [selectedIndex, setSelectedIndex] = useState(props.index);
  const history = useHistory();
  const [showSendInvitation, setShowSendInvitation] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(props.name);
  const [showDeleteConfirmationModel, setShowDeleteConfirmationModel] =
    useState(false);
  const [currentDateValue, setCurrentDateValue] = useState(
    props.dob && new Date(props.dob)
  );
  const [todaysDate, setTodaysDate] = useState(new Date());
  const [tempPatientDetails, setTempPatientDetails] = useState({});
  const [showOverRideNumberModel, setShowOverRideNumberModel] = useState(false);
  const [patientDetails, setPatientDetails] = useState({
    fullName: {
      value: props.name ? props.name : "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
        minLength: 3,
      },
    },
    gender: {
      value: props.gender ? props.gender : "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
      },
    },
    contactNumber: {
      value: props.contactNumber,
      valid: true,
      clicked: false,
      validations: {
        required: true,
        isNumeric: true,
        maxLength: 10,
        minLength: 10,
      },
    },
    dateOfBirth: {
      value: `${props.dob ? props.dob : ""}`,
      valid: false,
      clicked: false,
      validations: {
        // required: true,
        // dateValid: false,
      },
    },
    hospitalId: {
      value: props.hospitalId ? props.hospitalId : "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
        maxLength: 16,
        isAlphaNumeric: true,
      },
    },
  });

  const toggleShowSendInvitation = () => {
    setShowSendInvitation((prev) => !prev);
    props.setSelectedSendInvitation(props.index);
  };
  const setPatientDetailsEntered = (value, inputIdentifier) => {
    let valuePassed = value;
    let valueForValidityCheck = value;
    if (inputIdentifier == "fullName") {
      valuePassed = capitalizeFirstLetter(valuePassed);
    }
    if (inputIdentifier == "gender") {
      valuePassed = valuePassed;
    }
    if (inputIdentifier == "dateOfBirth") {
      setCurrentDateValue(value);
      valuePassed = monthChange(value, "covidGetDOB");
      valueForValidityCheck = monthChange(value, "covidGetDOB");
    }
    if (inputIdentifier == "contactNumber") {
      if (Number.isInteger(+value) && value.length <= 10) {
        valuePassed = value;
      } else {
        valueForValidityCheck = patientDetails["contactNumber"].value;
        valuePassed = patientDetails["contactNumber"].value;
      }
    }
    if (inputIdentifier == "hospitalId") {
      valuePassed = valuePassed.toString().toUpperCase();
      valueForValidityCheck = valuePassed.toString().toUpperCase();
    }
    const updatedFormElement = updateObject(patientDetails[inputIdentifier], {
      value: valuePassed,
      clicked: true,
      valid: checkValidity(
        valueForValidityCheck,
        patientDetails[inputIdentifier].validations
      ),
    });
    const updatedCovidPatientDetails = updateObject(patientDetails, {
      [inputIdentifier]: updatedFormElement,
    });

    let formIsValid = true;
    for (let inputIdentifier in updatedCovidPatientDetails) {
      formIsValid =
        updatedCovidPatientDetails[inputIdentifier].valid && formIsValid;
    }
    setPatientDetails(updatedCovidPatientDetails);
    setFormIsValid(formIsValid);
  };

  const updatePatientDetailsFunc = () => {
    let requestData = {
      patient_name: patientDetails["fullName"].value,
      gender: patientDetails["gender"].value,
      qr_code: props.qrCode,
      dob: monthChange(currentDateValue, "updateCovidDOB"),
      primary_contact: patientDetails["contactNumber"].value,
      hospital_id: patientDetails["hospitalId"].value,
    };
    let requestOptions = {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken} `,
      },
    };
    try {
      fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/${inlineUpdateEndPoint}`,
        requestOptions
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.status === 500) {
            setShowOverRideNumberModel(true);
            return;
          }
        });
    } catch (err) {}
  };
  const cancelNumberOverRide = async () => {
    // let fetchedValue = await getFetchFunction(
    //   `${getCancelOverRidingNumberEndPoint}/${props.qrCode}`
    // );

    setShowOverRideNumberModel(false);
    // props.fetchingStatusToFalseFunc(true);
  };

  const confirmNumberOverRide = async () => {
    try {
      let fetchedValue = await getFetchFunction(
        `${getOverRideMobileNumberEndPoint}/${props.qrCode}/${patientDetails["contactNumber"].value}`,
        accessToken
      );
      setShowOverRideNumberModel(false);
      props.fetchingStatusToFalseFunc(true);
    } catch (err) {
      console.log("ERROR OCCURED: ", err);
    }
  };
  const resendMessageToPatient = () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken} `,
      },
    };
    fetch(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${resendWhatsappMessageEndPoint}/${props.qrCode}/${props.contactNumber}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setShowSendInvitation(false);
      })
      .catch((error) => console.log("error", error));
  };

  const cancelUserDeletion = () => {
    setShowDeleteConfirmationModel(false);
  };
  const confirmUserDeletion = async () => {
    props.deleteUploadDischargeUser(props.index);
    setShowDeleteConfirmationModel(false);
    try {
      let fetchedValue = await getFetchFunction(
        `${discardDischargeSummaryEndPoint}/${props.qrCode}`,
        accessToken
      );
      props.fetchingStatusToFalseFunc(true);
    } catch (err) {}
  };

  const openPatientPersonalDetailsFunc = () => {
    props.setWardNurseRowNumber(props.index);
    props.setQRCodeToStoreFunc(props.qrCode);
    props.setPatientDetailsToStore({
      patientName: name,
    });

    history.replace(`/ds-upload/dischargeSummary`);
  };
  const editingDemography = () => {
    if (isEditing == false) {
      openPatientPersonalDetailsFunc();
      const temporaryPatientDetails = { ...patientDetails };
      setTempPatientDetails(temporaryPatientDetails);
      setIsEditing(true);
    } else {
      if (!formIsValid) {
      } else {
        updatePatientDetailsFunc();
        setIsEditing((prev) => !prev);
      }
    }
  };

  useEffect(() => {
    if (props.name || props.hospitalId || props.dob) {
      let tempWardPatientForm = { ...patientDetails };
      let tempWardPatientForm1 = {
        ...tempWardPatientForm,
        fullName: {
          ...tempWardPatientForm["fullName"],
          value: props.name,
          valid:
            props.name &&
            checkValidity(
              props.name,
              tempWardPatientForm["fullName"].validations
            ),
        },

        dateOfBirth: {
          ...tempWardPatientForm["dateOfBirth"],
          value: props.dob,
          valid:
            monthChange(props.dob, "covidGetDOB") &&
            checkValidity(
              props.dob,
              tempWardPatientForm["dateOfBirth"].validations
            ),
        },
        gender: {
          ...tempWardPatientForm["gender"],
          value: props.gender,
          valid:
            props.gender &&
            checkValidity(
              props.gender,
              tempWardPatientForm["gender"].validations
            ),
        },
        contactNumber: {
          ...tempWardPatientForm["contactNumber"],
          value: props.contactNumber,
          valid:
            props.contactNumber &&
            checkValidity(
              props.contactNumber,
              tempWardPatientForm["contactNumber"].validations
            ),
        },
        hospitalId: {
          ...tempWardPatientForm["hospitalId"],
          value: props.hospitalId,
          valid:
            props.hospitalId &&
            checkValidity(
              props.hospitalId,
              tempWardPatientForm["hospitalId"].validations
            ),
        },
      };
      setPatientDetails(tempWardPatientForm1);
      if (props.dob) {
        setCurrentDateValue(props.dob);
      }
      let tempFormIsValid = true;
      for (let inputIdentifier in tempWardPatientForm1) {
        tempFormIsValid =
          tempWardPatientForm1[inputIdentifier].valid && tempFormIsValid;
      }
      setFormIsValid(tempFormIsValid);
    }
  }, [props.qrCode, props.hospitalId]);
  const handleClose = (event) => {
    if (event.keyCode === 27) {
      if (isEditing == true) {
        setIsEditing(false);
        let formIsValid = true;
        for (let inputIdentifier in tempPatientDetails) {
          formIsValid =
            tempPatientDetails[inputIdentifier].valid && formIsValid;
        }
        setPatientDetails(tempPatientDetails);
        setFormIsValid(formIsValid);
      }
    }
    // else if (event.keyCode == 13) {
    //   if (isEditing == true) {
    //     if (formIsValid==true) {
    //       setIsEditing(false);
    //       updatePatientDetailsFunc();
    //     }else{

    //     }
    //   }
    // }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleClose, false);
    return () => {
      document.removeEventListener("keydown", handleClose, false);
    };
  }, [isEditing]);

  return (
    <div
      className={`uploadDischargeNameBody__container ${
        props.rowNumber === 0 &&
        props.qrCodeOnUpload &&
        props.qrCodeOnUpload.code !== "" &&
        props.qrCodeOnUpload.saved == true
          ? "highlightRow"
          : ""
      }
      ${
        props.rowNumber % 2 == 0 && selectedIndex !== props.wardNurseRowSelected
          ? "uploadDischargeNameBody__containerodd"
          : ""
      }
      ${
        selectedIndex == props.wardNurseRowSelected
          ? "uploadDischargeNameBody__selectedRow"
          : ""
      }
      `}
      onClick={openPatientPersonalDetailsFunc}
    >
      <div className="uploadDischargeNameBody__item">
        <div className="uploadDischargeNameBody__name">
          {!isEditing ? (
            <span className="uploadDischargeNameBody__nameHover">
              {patientDetails["fullName"].value},
            </span>
          ) : (
            <span>
              <input
                className={`uploadDischargeNameBody__inputName ${
                  !patientDetails["fullName"].valid &&
                  "uploadDischargeNameBody__numberInValid"
                }`}
                type="text"
                value={patientDetails["fullName"].value}
                onChange={(e) =>
                  setPatientDetailsEntered(e.target.value, "fullName")
                }
              ></input>
            </span>
          )}

          {!isEditing ? (
            <span>{patientDetails["gender"]["value"].charAt(0)}</span>
          ) : (
            <span>
              <select
                className="uploadDischargeNameBody__inputGender"
                type="text"
                value={capitalizeFirstLetter(patientDetails["gender"]["value"])}
                defaultValue={capitalizeFirstLetter(
                  patientDetails["gender"]["value"]
                )}
                onChange={(e) =>
                  setPatientDetailsEntered(e.target.value, "gender")
                }
              >
                <option value="Male">M</option>
                <option value="Female">F</option>
              </select>
            </span>
          )}
        </div>
        <div className="uploadDischargeNameBody__dateOfBirth">
          {!isEditing ? (
            <span>{patientDetails["dateOfBirth"].value}</span>
          ) : (
            <DateBox
              className="uploadDischargeNameBody__inputDateOfBirth"
              type="date"
              id="selected-date"
              value={currentDateValue}
              max={todaysDate}
              style={{ padding: "0px" }}
              placeholder="dd/mm/YYYY"
              displayFormat="dd/MM/yyyy"
              onValueChanged={(e) =>
                setPatientDetailsEntered(e.value, "dateOfBirth")
              }
            />
          )}
        </div>
      </div>
      <div className="uploadDischargeNameBody__item">
        <div
          className={`uploadDischargeNameBody__qrCode ${
            isEditing ? "itemEditingMargin" : ""
          }`}
        >
          {props.qrCode}
        </div>
        <div className="uploadDischargeNameBody__hospitalId">
          {!isEditing ? (
            <span>{patientDetails["hospitalId"].value}</span>
          ) : (
            <span>
              <input
                className={`uploadDischargeNameBody__inputhospitalId ${
                  !patientDetails["hospitalId"].valid &&
                  "uploadDischargeNameBody__numberInValid"
                }`}
                type="text"
                value={patientDetails["hospitalId"].value}
                onChange={(e) =>
                  setPatientDetailsEntered(e.target.value, "hospitalId")
                }
              ></input>
            </span>
          )}
        </div>
      </div>
      <div className="uploadDischargeNameBody__item">
        <div
          className={`uploadDischargeNameBody__dateOfDischarge ${
            isEditing ? "itemEditingMargin" : ""
          }`}
        >
          {props.registrationDate}
        </div>
        <div className="uploadDischargeNameBody__mobileNumber">
          {!isEditing ? (
            <span>{patientDetails["contactNumber"].value}</span>
          ) : (
            <span>
              <input
                className={`uploadDischargeNameBody__inputContactNumber ${
                  !patientDetails["contactNumber"].valid &&
                  "uploadDischargeNameBody__numberInValid"
                }`}
                type="text"
                value={patientDetails["contactNumber"].value}
                onChange={(e) =>
                  setPatientDetailsEntered(e.target.value, "contactNumber")
                }
              ></input>
            </span>
          )}
        </div>
      </div>
      <div className="uploadDischargeNameBody__item uploadDischargeNameBody__itemStatus">
        <div className="uploadDischargeNameBody__dischargeStatus">
          {props.dischargeStatus}
        </div>
      </div>
      <div className="uploadDischargeNameBody__item uploadDischargeNameBody__ItemActions">
        {props.isResend && (
          <>
            {" "}
            <CircularIcon
              size="30px"
              imgsrc={mobilephoneS2}
              clicked={toggleShowSendInvitation}
            />
            <div className="uploadDischargeNameBody__messageStatus">
              {props.messageStatus}
            </div>
            <div className="uploadDischargeNameBody__messageTime">
              {props.messageTime}
            </div>
          </>
        )}
      </div>
      <div className="uploadDischargeNameBody__editItem">
        {!isEditing ? (
          <CircularIcon
            imgsrc={editIcon}
            alt="edit"
            clicked={editingDemography}
            passedClass={`${"uploadDischargeNameBody__toggleButtonContainer"}`}
            imgsize="20px"
            size="30px"
          />
        ) : (
          <CircularIcon
            passedClass={!formIsValid ? "formNotValid" : ""}
            disabled={!formIsValid}
            imgsrc={saveFileIcon}
            alt="save"
            clicked={editingDemography}
            passedClass={`${"uploadDischargeNameBody__toggleButtonContainer"}`}
            imgsize="20px"
            size="30px"
          />
        )}
        <CircularIcon
          imgsrc={deleteIcon}
          alt="save"
          clicked={() => setShowDeleteConfirmationModel(true)}
          passedClass={`${"uploadDischargeNameBody__deleteUserConfirmationModel"}`}
          alt="delete"
          imgsize="30px"
          size="30px"
        />
      </div>

      {showSendInvitation && props.index == props.selectedSendInvitation && (
        <div className="uploadDischargeNameBody__sendInvitation">
          <SendInvitation
            toggleSendInvitation={toggleShowSendInvitation}
            contactNumber={patientDetails["contactNumber"].value}
            setContactNumberEntered={(value) =>
              setPatientDetailsEntered(value, "contactNumber")
            }
            touchedNotValid={
              patientDetails["contactNumber"].clicked &&
              !patientDetails["contactNumber"].valid
            }
            // disabled={!formIsValid}
            disabled={true}
            resendMessageToPatient={resendMessageToPatient}
          />
        </div>
      )}
      {showOverRideNumberModel && (
        <div className="uploadDischargeNameBody__overRideModal">
          <ModalDelete
            cancel="No"
            select="Yes"
            messageHeading="Phone number already exists"
            messageBody="A user with the same phone number already exists. Do you want to create a new user with same phone number. This will delete all records of the existing user?"
            cancelNurseDelete={cancelNumberOverRide}
            confirmNurseDelete={confirmNumberOverRide}
          />
        </div>
      )}
      {showDeleteConfirmationModel && (
        <div className="uploadDischargeNameBody__overRideModalUserDelete">
          <ModalDelete
            cancel="No"
            select="Yes"
            messageHeading="Record Deletion"
            messageBody={`This will set this record to 'Monitoring stopped' status and the patient will not recieve any communication.`}
            cancelNurseDelete={cancelUserDeletion}
            confirmNurseDelete={confirmUserDeletion}
          />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeOnUpload: state.patient.qrCodeOnUpload,
    wardNurseRowSelected: state.patient.wardNurseRowSelected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),

    setPatientDetailsToStore: (details) =>
      dispatch(actions.setPatientDetails(details)),
    fetchingStatusToFalseFunc: (status) =>
      dispatch(actions.fetchingAgain(status)),
    setWardNurseRowNumber: (rowNumber) =>
      dispatch(actions.setWardNurseRowNumber(rowNumber)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadDischargeNameBody);
