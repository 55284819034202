import React from "react";
import "./DUMHeader.css";
import Searchbar from "../../../Searchbar/Searchbar";
import { connect } from "react-redux";


import * as actions from "../../../../Store/actions/index";

const DUMHeader = (props) => {
  return (
    <div className="dumHeader">
      <div className="dumHeader__name">List of Discharges</div>
      <div className="dumHeader__items">
        <Searchbar
          placeholder="Search"
          BRcolor="white"
          setInputFunc={props.setInputFunc}
          value={props.value}
          allowAutoFocus ={true}
        />
      </div>

     
      {/* <div className="dumHeader__backHamburger">
       
        <div className="dumHeader__icon">
          <img
            style={{ width: "100px", height: "100px" }}
            src={hamburgerIcon}
            alt="hamburgerIcon"
          />
        </div>
      </div> */}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    showNavigationFuncToStore: (status) =>
      dispatch(actions.showNavigationFunc(status)),
  };
};

export default connect(null, mapDispatchToProps)(DUMHeader);
