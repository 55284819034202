import React from "react";
import AlertThresholdConditions from "./AlertThresholdConditions/AlertThresholdConditions";
import { Route, Switch, Redirect } from "react-router-dom";

import "./AlertThreshold.css";
const AlertThreshold = () => {
  return <div className="alertThreshold__container">{routes}</div>;
};
let routes = (
  <Switch>
  
    <Route
      path="/patientAlerts/AlertThreshold/AlertThresholdConditions/:td"
      component={AlertThresholdConditions}
    />
    <Redirect to="/patientAlerts/AlertThreshold/AlertThresholdConditions/:td" />
  </Switch>
);

export default AlertThreshold;
