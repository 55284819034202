import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AlertThreshold from "./AlertThreshold/AlertThreshold";
import DischargeDetails from "./DischargeDetails/DischargeDetails";
import FollowUpSchedules from "./FollowUpSchedules/FollowUpSchedules";
import MedicationForPatient from "./MedicationForPatient/MedicationForPatient";
import ApprovePage from "./ApprovePage/ApprovePage";
import PdfViewReactPdf from "../PdfViewReactPdf/PdfViewReactPdf";
import SinglePatientDetails from "../../Components/screensComponent/patientAlerts/PatientAlertsHeader/SinglePatientDetails/SinglePatientDetails";
import "./PatientAlerts.css";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
const PatientAlerts = (props) => {
  return (
    <div className="patientAlerts__container">
      <div className="patientAlerts__header">
        <SinglePatientDetails />
      </div>

      <div className="patientAlerts__body">{routes}</div>
      <div className="patientAlerts__pdfView">
        <div className="patientAlerts__pdfHeaderView"></div>
        <div className="patientAlerts__pdfBodyView">
          <PdfViewReactPdf />
        </div>
      </div>
    </div>
  );
};

let routes = (
  <Switch>
    
   {/* Component to show diagnosis,diet,exercise etc. and Nurse Calls  */}
    <Route
      path="/patientAlerts/DischargeDetails/:td"
      render={() => (
        <ErrorBoundary>
          <DischargeDetails />
        </ErrorBoundary>
      )}
    />

    {/* Component to show sns-entity symptoms, which caught in discharge summary as well as those available in database. */}
     <Route
      path="/patientAlerts/AlertThreshold/:td"
      render={() => (
        <ErrorBoundary>
          <AlertThreshold />
        </ErrorBoundary>
      )}
    />

    {/* Component to show Followups recommended by doctor to the patient like dressing, IV Line,etc.  */}
    <Route
      path="/patientAlerts/FollowUpSchedules/:td"
      render={() => (
        <ErrorBoundary>
          <FollowUpSchedules />
        </ErrorBoundary>
      )}
    />
    {/* Component to show medicines caught by discharge summary parser, allowing addition,updatioin and deletion of existing as well as new medicines. */}
    <Route
      path="/patientAlerts/MedicationSchedules/:td"
      render={() => (
        <ErrorBoundary>
          <MedicationForPatient />
        </ErrorBoundary>
      )}

    />
    <Route path="/patientAlerts/UserApproved" component={ApprovePage} />
  </Switch>
);

export default PatientAlerts;
