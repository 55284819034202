import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../Components/screensComponent/Dashboard/DashboardHeader/DashboardHeader";
import DashboardNameHeader from "../../../Components/screensComponent/Dashboard/DashboardNameHeader/DashboardNameHeader";
import AMSNameBody from "../../../Components/screensComponent/Dashboard/ApprovalMonitoringSchedules/AMSNameBody/AMSNameBody";
import * as actions from "../../../Store/actions/index";
import { connect } from "react-redux";
import { getPatientsToBeApprovedEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import Loader from "../../../Loader/LoaderThreeDot";

import "./ApproveMonitoringSchedules.css";
const ApproveMonitoringSchedules = (props) => {

  // function to fetch list of those patients whose approval is pending
  // here props.approveMonitoringSchedules can be used for refreshing the contents in this screen using redux
  const { response, isLoading } = useFetch(
    getPatientsToBeApprovedEndPoint,
    props.approveMonitoringSchedules
  );
  useEffect(() => {
    if (props.approveMonitoringSchedules === true) {
      props.fetchApproveMonitoringSchedule(false);
    }
  }, [props.approveMonitoringSchedules]);

  let renderedData = null;
  renderedData =
    Array.isArray(response) &&
    response.map((item, index) => {
      return (
        <AMSNameBody
          key={index}
          name={item["name"]}
          dischargeDate={item["discharge_date"]}
          dischargeDiagnosis={item["context"]}
          qrCode={item["qr_code"]}
          rowNumber={index}
          hospitalId={item["hospital_id"]}
          timeSinceDischarge={item["time_since_discharge"]}
          age={item["age"]}
          gender={item["gender"]}
        />
      );
    });

  return (
    <div className="approveMonitoringSchedules__container">
      <div className="approveMonitoringSchedules__header">
        <DashboardHeader
          headerName="Monitoring capsules for approval"
          allPatientInfo={`${response.length} Capsules require approval`}
        />
      </div>
      <div className="approveMonitoringSchedules__nameHeader">
        <DashboardNameHeader status="Time Since Discharge" 
        // arrowUp={true}
        />
      </div>
      <div className="approveMonitoringSchedules__nameBody">
        {isLoading ? (
          <div className="loaderbar">
            {" "}
            <Loader />{" "}
          </div>
        ) : (
          <div>{renderedData}</div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    approveMonitoringSchedules: state.dashboard.approveMonitoringSchedules,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchApproveMonitoringSchedule: (status) =>
      dispatch(actions.fetchApproveMonitoringSchedules(status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveMonitoringSchedules);
