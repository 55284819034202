import React, { useState, useEffect } from "react";
import "./CovidRegistrationHeader.css";
import Searchbar from "../../../Searchbar/Searchbar";
import { connect } from "react-redux";
import refreshFigma from "../../../../AllIcons/refreshFigma.png";
import { useHistory } from "react-router-dom";
import * as actions from "../../../../Store/actions/index";
import ButtonWBorder from "../../../Buttons/ButtonWBorder/ButtonWBorder";
const CovidRegistrationHeader = (props) => {
  var currentTime = new Date();
  const [RefreshTime, setRefreshTime] = useState(
    currentTime.toLocaleTimeString()
  );
  const history = useHistory();

  const refreshCovidRegistrationScreen = () => {
    currentTime = new Date();
    setRefreshTime(currentTime.toLocaleTimeString());
    props.fetchingCovidListRedux(true);
  };
  const navigateToNurseDashboard = () =>{
    history.push("/dashboard")
  }
  return (
    <div className="crHeader">
      <div className="crHeader__items">
        <Searchbar
          placeholder="Search"
          BRcolor="white"
          setInputFunc={props.setInputFunc}
          value={props.value}
        />
         <ButtonWBorder
          name="Dashboard"
          width="150px"
          clicked={navigateToNurseDashboard}
        />
          </div>

      {/* code to refresh  */}
      <div>
        <div className="crHeader__updateRefresh">
          <div className="crHeader__update">
            <div className="crHeader__updateText">Last updated</div>
            <div className="crHeader__updateTextValue">{RefreshTime}</div>
          </div>
          <div
            className="crHeader__refresh"
            onClick={refreshCovidRegistrationScreen}
          >
            <div className="crHeader__refreshIcon">
              <img src={refreshFigma} className="crHeader__refreshHover" />
            </div>
            <div className="crHeader__refreshText">Refresh</div>
          </div>
        </div>
        <div className="crHeader__update">
          <div className="crHeader__updateText">
            Total patients under monitoring
          </div>
          <div className="crHeader__updateTextValue">
            {props.totalCovidPatients}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchingCovidListRedux: (status) =>
      dispatch(actions.fetchCovidPatientListAgain(status)),

  };
};

export default connect(null, mapDispatchToProps)(CovidRegistrationHeader);
