import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../../../../HighOrderFunctions/shared/capitalizeFirstLetter";
import {
    updateObject,
    checkValidity,
} from "../../../../../HighOrderFunctions/shared/utility";
import InputCard from "../../../../Card/InputCard/InputCard";
import "./CareGiversCards.css";
import { useRef } from "react";
import EditableCareGiver from "./EditableCareGiver/EditableCareGiver";
import CheckBox from "../../../../CheckBox/CheckBox";
import {
    changeActiveCareTaker,
    deleteCareGiver,
    updateCareGiver,
    insertNewCareGiver,
} from "../../../../../HighOrderFunctions/APIs/allPostAPI";
import { useDispatch } from "react-redux";
import { postFetchFunction } from "../../../../../HighOrderFunctions/FetchMethod/postFetchFunction";
import {
    getFetchFunction,
    useFetch,
} from "../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { showNotificationWithMessage } from "../../../../../Store/actions";
const CareGiversCards = ({ careGiversDetails, isLoading, ...props }) => {
    const dispatch = useDispatch();
    const qrCodeFromStore = useSelector((state) => state.patient.QRCode);
    const accessToken = useSelector((state) => state.auth.accessToken);
    const [formIsValidList, setFormIsValidList] = useState([false, false, false]);
    const [activeNurseArray, setActiveNurseArray] = useState([
        false,
        false,
        false,
    ]);

    let fullName1 = useRef();
    let contactNumber1 = useRef();
    let fullName2 = useRef();
    let contactNumber2 = useRef();
    let fullName3 = useRef();
    let contactNumber3 = useRef();
    //initial state to store fullName and contact number of nurse , who so ever is being registered.!!
    const [careGiversCardsInfo, setCareGiversCardsInfo] = useState([
        {
            fullName: {
                value: "",

                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    minLength: 3,
                },
            },
            contactNumber: {
                value: "",
                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    isNumeric: true,
                    maxLength: props.countryCode == "+91" ? 10 : 9,
                    minLength: props.countryCode == "+91" ? 10 : 9,
                },
            },
            careGiverId: {
                value: uuidv4().toString(),
                valid: true,
            },
            isActiveForPatient: {
                value: true,
                valid: true,
            },
            isCurrentlyOnDuty: {
                value: true,
                valid: true,
            },
            isdCode: {
                value: "+91",
                valid: true,
            },
        },
        {
            fullName: {
                value: "",

                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    minLength: 3,
                },
            },
            contactNumber: {
                value: "",
                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    isNumeric: true,
                    maxLength: props.countryCode == "+91" ? 10 : 9,
                    minLength: props.countryCode == "+91" ? 10 : 9,
                },
            },
            careGiverId: {
                value: uuidv4().toString(),
                valid: true,
            },
            isActiveForPatient: {
                value: true,
                valid: true,
            },
            isCurrentlyOnDuty: {
                value: false,
                valid: true,
            },
            isdCode: {
                value: "+91",
                valid: true,
            },
        },
        {
            fullName: {
                value: "",

                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    minLength: 3,
                },
            },
            contactNumber: {
                value: "",
                valid: false,
                clicked: false,
                validations: {
                    required: true,
                    isNumeric: true,
                    maxLength: props.countryCode == "+91" ? 10 : 9,
                    minLength: props.countryCode == "+91" ? 10 : 9,
                },
            },
            careGiverId: {
                value: uuidv4().toString(),
                valid: true,
            },
            isActiveForPatient: {
                value: true,
                valid: true,
            },
            isCurrentlyOnDuty: {
                value: false,
                valid: true,
            },
            isdCode: {
                value: "+91",
                valid: true,
            },
        },
    ]);

    useEffect(() => {
        if (Array.isArray(careGiversDetails)) {
            let tempCareGiversCardInfo = [...careGiversCardsInfo];
            let lengthOfCareGiversDetailsFetched = careGiversDetails.length;
            if (lengthOfCareGiversDetailsFetched == 0) {
                return;
            }
            let updatedCareGiversCardInfoArray = [];
            if (careGiversDetails && careGiversDetails.length > 0) {
                careGiversDetails.forEach((item, index) => {
                    let tempCareGiverForm = { ...tempCareGiversCardInfo[index] };
                    let tempCurrentCareGiverForm = { ...tempCareGiverForm };
                    if (item["is_currently_on_duty"] == true) {
                        let tempContactWithIsd = `${item["isd_code"]} ${item["care_giver_phone"]}`;
                        props.setActiveCareGiverContact(tempContactWithIsd);
                        let tempActiveNurseArray = [...activeNurseArray];
                        tempActiveNurseArray[index] = true;
                        setActiveNurseArray((prev) => [...tempActiveNurseArray]);
                    }
                    tempCurrentCareGiverForm = {
                        ...tempCareGiverForm,
                        fullName: {
                            ...tempCareGiverForm["fullName"],
                            value: item["care_giver_name"],
                            valid: checkValidity(
                                item["care_giver_name"],
                                tempCareGiversCardInfo[index]["fullName"].validations
                            ),
                        },
                        contactNumber: {
                            ...tempCareGiverForm["contactNumber"],
                            value: item["care_giver_phone"],
                            valid: checkValidity(
                                item["care_giver_phone"],
                                tempCareGiversCardInfo[index]["contactNumber"].validations
                            ),
                        },
                        careGiverId: {
                            ...tempCareGiverForm["careGiverId"],
                            value: item["care_giver_id"],
                        },
                        isActiveForPatient: {
                            ...tempCareGiverForm["isActiveForPatient"],
                            value: item["is_active_for_patient"],
                        },
                        isCurrentlyOnDuty: {
                            ...tempCareGiverForm["isCurrentlyOnDuty"],
                            value: item["is_currently_on_duty"],
                        },
                        isdCode: {
                            ...tempCareGiverForm["isdCode"],
                            value: item["isd_code"],
                        },
                    };
                    updatedCareGiversCardInfoArray.push(tempCurrentCareGiverForm);
                });
            }
            let tempFormIsValidList = new Array(
                lengthOfCareGiversDetailsFetched
            ).fill(true);

            if (
                lengthOfCareGiversDetailsFetched < 3 &&
                lengthOfCareGiversDetailsFetched > 0
            ) {
                updatedCareGiversCardInfoArray = [
                    ...updatedCareGiversCardInfoArray,
                    ...tempCareGiversCardInfo.slice(lengthOfCareGiversDetailsFetched, 4),
                ];

                tempFormIsValidList = [
                    ...tempFormIsValidList,
                    ...formIsValidList.slice(lengthOfCareGiversDetailsFetched, 4),
                ];
            }

            setCareGiversCardsInfo((prev) => [...updatedCareGiversCardInfoArray]);
            setFormIsValidList((prev) => [...tempFormIsValidList]);
        }
    }, [isLoading, careGiversDetails]);

    // function to update fullName and contact Number of Nurse
    const inputChangedHandler = (value, inputIdentifier, index) => {
        let valuePassed = value;
        let valueForValidityCheck = value;
        if (inputIdentifier == "fullName") {
            valuePassed = capitalizeFirstLetter(valuePassed);
        }

        if (inputIdentifier == "contactNumber") {
            if (Number.isInteger(+value) && value.length <= 10) {
                valuePassed = value;
            } else {
                valueForValidityCheck =
                    careGiversCardsInfo[index]["contactNumber"].value;
                valuePassed = careGiversCardsInfo[index]["contactNumber"].value;
            }
        }

        const updatedFormElement = updateObject(
            careGiversCardsInfo[index][inputIdentifier],
            {
                value: valuePassed,
                clicked: true,
                valid: checkValidity(
                    valueForValidityCheck,
                    careGiversCardsInfo[index][inputIdentifier].validations
                ),
            }
        );
        const updatedCareGiverForm = updateObject(careGiversCardsInfo[index], {
            [inputIdentifier]: updatedFormElement,
        });

        let tempFormIsValid = true;
        for (let inputIdentifier in updatedCareGiverForm) {
            tempFormIsValid =
                updatedCareGiverForm[inputIdentifier].valid && tempFormIsValid;
        }

        //  storing name and contact details of caregivers
        let tempCareGiversListInfo = careGiversCardsInfo;
        tempCareGiversListInfo[index] = updatedCareGiverForm;
        setCareGiversCardsInfo((prev) => [...tempCareGiversListInfo]);
        // storing boolean value of formList
        let tempFormIsValidList = formIsValidList;
        tempFormIsValidList[index] = tempFormIsValid;
        setFormIsValidList((prev) => [...tempFormIsValidList]);
    };

    const changeCareGiverNurse = (value, index) => {
        let tempActiveNurseArray = [...activeNurseArray];
        let previousActiveNurseIndex = tempActiveNurseArray.indexOf(true);
        if (previousActiveNurseIndex == index) {
            alert("can't remove existing nurse!!!");
            return;
        }

        
        if (Number.isInteger(+careGiversCardsInfo[index]["careGiverId"].value)) {
            let { response } = getFetchFunction(
                `${changeActiveCareTaker}/${qrCodeFromStore}/${careGiversCardsInfo[index]["careGiverId"].value}`,
                accessToken,
                "PUT"
            ).then(result => {
                console.log(result);
                if (result["status"] === "200") {
                    tempActiveNurseArray = tempActiveNurseArray.map((item) => false == true);
                    tempActiveNurseArray[index] = true;
                    setActiveNurseArray((prev) => [...tempActiveNurseArray]);
                    let tempContactWithIsd = `${careGiversCardsInfo[index]["isdCode"].value} ${careGiversCardsInfo[index]["contactNumber"].value}`;
                    props.setActiveCareGiverContact(tempContactWithIsd);
                    dispatch(
                        showNotificationWithMessage({
                            variant: "success",
                            message: "Caregiver updated, successfully!",
                        })
                    );

                }
                if (result["status"] === 1002) {
                    dispatch(
                        showNotificationWithMessage({
                            variant: "warning",
                            message: result["error"],
                        })
                    );
                }
            });
        }
    };
    const updateCareGiverDetailToBackend = async (arrIndex) => {
        if (!formIsValidList[arrIndex]) {
            if (
                careGiversCardsInfo[arrIndex]["fullName"].value == "" &&
                careGiversCardsInfo[arrIndex]["contactNumber"].value == ""
            ) {
                try {
                    let responseAfterDeletingCareGiver = await getFetchFunction(
                        `${deleteCareGiver}/${careGiversCardsInfo[arrIndex]["careGiverId"].value}`,
                        accessToken,
                        "DELETE"
                    );
                    dispatch(
                        showNotificationWithMessage({
                            variant: "success",
                            message: "Deletion successful!!!",
                        })
                    );
                } catch (err) {
                    dispatch(
                        showNotificationWithMessage({
                            variant: "warning",
                            message: "Error occured while deleting!",
                        })
                    );
                }
                let tempCareGiversCardInfo = [...careGiversCardsInfo];
                let tempCareGiversCardInfoForm = {
                    ...tempCareGiversCardInfo[arrIndex],
                };
                tempCareGiversCardInfoForm["careGiverId"] = {
                    ...tempCareGiversCardInfo[arrIndex]["careGiverId"],
                    value: uuidv4(),
                };
                tempCareGiversCardInfo[arrIndex] = tempCareGiversCardInfoForm;
                setCareGiversCardsInfo((prev) => [...tempCareGiversCardInfo]);
            } else {
                dispatch(
                    showNotificationWithMessage({
                        variant: "warning",
                        message: "Please don't fill wrong details!",
                    })
                );
            }
        } else {
            let postData = {
                care_giver_id: careGiversCardsInfo[arrIndex]["careGiverId"].value,
                name: careGiversCardsInfo[arrIndex]["fullName"].value,
                number: careGiversCardsInfo[arrIndex]["contactNumber"].value,
                is_currently_on_duty:
                    careGiversCardsInfo[arrIndex]["isCurrentlyOnDuty"].value,
            };
            if (
                Number.isInteger(+careGiversCardsInfo[arrIndex]["careGiverId"].value)
            ) {
                let responseAfterUpdatingExisting = await postFetchFunction(
                    `${updateCareGiver}/${qrCodeFromStore}`,
                    postData,
                    accessToken,
                    "PUT"
                );
                if (responseAfterUpdatingExisting.status == 200) {
                    dispatch(
                        showNotificationWithMessage({
                            variant: "success",
                            message: responseAfterUpdatingExisting.message,
                        })
                    );
                }
            } else {
                delete postData["care_giver_id"];
                let responseAfterInsertingNewCareGiver = await postFetchFunction(
                    `${insertNewCareGiver}/${qrCodeFromStore}`,
                    postData,
                    accessToken,
                    "POST"
                );
                if (responseAfterInsertingNewCareGiver.status == 200) {
                    dispatch(
                        showNotificationWithMessage({
                            variant: "success",
                            message: responseAfterInsertingNewCareGiver.message,
                        })
                    );
                }
            }
        }
    };

    return (
        <div className="careGiversCards__container">
            {/* first Record  */}
            <div className="careGiversCard__container">
                <div className="careGiversCard__information">
                    <div className="careGiversCard__detailsBorder">
                        <div className="careGiversCard__details">
                            <div className="careGiversCard__name">
                                <EditableCareGiver
                                    text={careGiversCardsInfo[0]["fullName"].value}
                                    placeholder="name"
                                    childRef={fullName1}
                                    type="input"
                                    disabled={activeNurseArray[0] == true}
                                    arrIndex={0}
                                    updateCareGiverDetailToBackend={
                                        updateCareGiverDetailToBackend
                                    }
                                >
                                    <input
                                        ref={fullName1}
                                        className="careGiversCard__inputTag"
                                        placeholder="Name of Nurse "
                                        width="100%"
                                        bgcolor="#FFFFFF"
                                        value={careGiversCardsInfo[0]["fullName"].value}
                                        onChange={(event) =>
                                            inputChangedHandler(event.target.value, "fullName", 0)
                                        }
                                    />
                                </EditableCareGiver>
                            </div>
                            <div className="careGiversCard__contactNumber">
                                <div className="careGiversCard__isdCode">
                                    {props.countryCode}
                                </div>
                                <EditableCareGiver
                                    text={careGiversCardsInfo[0]["contactNumber"].value}
                                    placeholder="contact Number"
                                    childRef={contactNumber1}
                                    type="input"
                                    disabled={activeNurseArray[0] == true}
                                    arrIndex={0}
                                    updateCareGiverDetailToBackend={
                                        updateCareGiverDetailToBackend
                                    }
                                >
                                    <input
                                        className="careGiversCard__inputTag"
                                        placeholder="Contact Number"
                                        ref={contactNumber1}
                                        width="100%"
                                        bgcolor="#FFFFFF"
                                        value={careGiversCardsInfo[0]["contactNumber"].value}
                                        onChange={(event) =>
                                            inputChangedHandler(
                                                event.target.value,
                                                "contactNumber",
                                                0
                                            )
                                        }
                                    />
                                </EditableCareGiver>
                            </div>
                        </div>
                        <div className="careGiver__border"></div>
                    </div>
                    <div className="careGiverCards__checkbox">
                        <CheckBox
                            value={activeNurseArray[0]}
                            disabled={!formIsValidList[0]}
                            setValue={(value) => changeCareGiverNurse(value, 0)}
                        />
                    </div>
                </div>
            </div>
            {/* Second Record  */}
            <div className="careGiversCard__container">
                <div className="careGiversCard__information">
                    <div className="careGiversCard__detailsBorder">
                        <div className="careGiversCard__details">
                            <div className="careGiversCard__name">
                                <EditableCareGiver
                                    text={careGiversCardsInfo[1]["fullName"].value}
                                    placeholder="name"
                                    childRef={fullName2}
                                    type="input"
                                    disabled={activeNurseArray[1] == true}
                                    arrIndex={1}
                                    updateCareGiverDetailToBackend={
                                        updateCareGiverDetailToBackend
                                    }
                                >
                                    <input
                                        ref={fullName2}
                                        className="careGiversCard__inputTag"
                                        placeholder="Name of Nurse "
                                        width="100%"
                                        bgcolor="#FFFFFF"
                                        value={careGiversCardsInfo[1]["fullName"].value}
                                        onChange={(event) =>
                                            inputChangedHandler(event.target.value, "fullName", 1)
                                        }
                                    />
                                </EditableCareGiver>
                            </div>
                            <div className="careGiversCard__contactNumber">
                                <div className="careGiversCard__isdCode">
                                    {props.countryCode}
                                </div>

                                <EditableCareGiver
                                    text={careGiversCardsInfo[1]["contactNumber"].value}
                                    placeholder="Contact Number"
                                    childRef={contactNumber2}
                                    type="input"
                                    disabled={activeNurseArray[1] == true}
                                    arrIndex={1}
                                    updateCareGiverDetailToBackend={
                                        updateCareGiverDetailToBackend
                                    }
                                >
                                    <input
                                        className="careGiversCard__inputTag"
                                        placeholder="Contact Number"
                                        ref={contactNumber2}
                                        width="100%"
                                        bgcolor="#FFFFFF"
                                        value={careGiversCardsInfo[1]["contactNumber"].value}
                                        onChange={(event) =>
                                            inputChangedHandler(
                                                event.target.value,
                                                "contactNumber",
                                                1
                                            )
                                        }
                                    />
                                </EditableCareGiver>
                            </div>
                        </div>
                        <div className="careGiver__border"></div>
                    </div>
                    <div className="careGiverCards__checkbox">
                        <CheckBox
                            // value={careGiversCardsInfo[1]["isCurrentlyOnDuty"].value}
                            value={activeNurseArray[1]}
                            disabled={!formIsValidList[1]}
                            setValue={(value) => changeCareGiverNurse(value, 1)}
                        />
                    </div>
                </div>
            </div>
            {/* Third Record  */}
            <div className="careGiversCard__container">
                <div className="careGiversCard__information">
                    <div className="careGiversCard__detailsBorder">
                        <div className="careGiversCard__details">
                            <div className="careGiversCard__name">
                                <EditableCareGiver
                                    text={careGiversCardsInfo[2]["fullName"].value}
                                    placeholder="name"
                                    childRef={fullName3}
                                    type="input"
                                    disabled={activeNurseArray[2] == true}
                                    arrIndex={2}
                                    updateCareGiverDetailToBackend={
                                        updateCareGiverDetailToBackend
                                    }
                                >
                                    <input
                                        ref={fullName3}
                                        className="careGiversCard__inputTag"
                                        placeholder="Name of Nurse "
                                        width="100%"
                                        value={careGiversCardsInfo[2]["fullName"].value}
                                        onChange={(event) =>
                                            inputChangedHandler(event.target.value, "fullName", 2)
                                        }
                                    />
                                </EditableCareGiver>
                            </div>
                            <div className="careGiversCard__contactNumber">
                                <div className="careGiversCard__isdCode">
                                    {props.countryCode}
                                </div>

                                <EditableCareGiver
                                    text={careGiversCardsInfo[2]["contactNumber"].value}
                                    placeholder="Contact Number"
                                    childRef={contactNumber3}
                                    type="input"
                                    disabled={activeNurseArray[2] == true}
                                    arrIndex={2}
                                    updateCareGiverDetailToBackend={
                                        updateCareGiverDetailToBackend
                                    }
                                >
                                    <input
                                        className="careGiversCard__inputTag"
                                        placeholder="Contact Number"
                                        ref={contactNumber3}
                                        width="100%"
                                        bgcolor="#FFFFFF"
                                        value={careGiversCardsInfo[2]["contactNumber"].value}
                                        onChange={(event) =>
                                            inputChangedHandler(
                                                event.target.value,
                                                "contactNumber",
                                                2
                                            )
                                        }
                                    />
                                </EditableCareGiver>
                            </div>
                        </div>
                        <div className="careGiver__border"></div>
                    </div>
                    <div className="careGiverCards__checkbox">
                        <CheckBox
                            // value={careGiversCardsInfo[2]["isCurrentlyOnDuty"].value}
                            value={activeNurseArray[2]}
                            disabled={!formIsValidList[2]}
                            setValue={(value) => changeCareGiverNurse(value, 2)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CareGiversCards;
