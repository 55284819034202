// all get APIs
// ${window["getConfig"].BACKEND_SERVICE_URL}
// without qrCode
//################################################################################

import TranslateUtterance from "../../Admin/Components/TranslateUtterance/TranslateUtterance";
import PicturesUploaded from "../../screens/MonitoringStatus/MonitoringStatusSidebar/PicturesUploaded/PicturesUploaded";
import MFPDropdown from "../../screens/PatientAlertsScreen/MedicationForPatient/MFPDropdown/MFPDropdown";
import UDPatientHospitalDetails from "../../screens/UploadDischargeScreen/UDPatientHospitalDetails/UDPatientHospitalDetails";

//dischargeUnderMonitoring
export const dischargeUnderMonitoringEndPoint = "api/v1/get-discharge-list";

// Dashboard  \\
// _________________\\

//ApproveMonitoringSchedules
export const getPatientsToBeApprovedEndPoint =
  "api/v1/get-patients-to-be-approved";

// ReviewCallForDay   /${formatDate(new Date())}  --> Review Calls for the day
export const getPatientToBeCalledEndPoint = "api/v1/get-patients-to-be-called";

//PatientUnderMonitoring  /${formatDate(new Date())} --> why ? "using for review calls for the day" --> Patient Under Monitoring
export const getPatientUnderMonitoringEndPoint =
  "api/v1/get-patients-under-monitoring";

// Discharge PatientAlerts
export const getDischargePatientAlertsEndPoint = "api/v1/get-patients-alerts";
// ---------------------------------------------------------------------------------------

// Upload Discharge Screen
export const getDischargeDetailsEndPoint = "api/v1/getDischargeDetails";

// --------------------------------------------------------------------------------------------

// searchbarDropdownList  \\

// PATIENT - ALERTS SCREEN
// 1. Discharge Details

// medicalDevices  (MedicalDeviceSearchDropdown.js)
export const medicalInsertsEndPoint = "api/v1/getMedicalInserts";

//diet   (DietSearchDropdown.js)
export const dietEndPoint = "api/v1/get-diet-details";

//exercise (ExerciseSearchDropdown.js)
export const exerciseEndPoint = "api/v1/get-exercise-details";

// 2. Alert Threshold

// getAllSymptoms   (AlertThresholdNewCardList.js)
export const getAllSymptomsEndPoint = "api/v1/get-all-symptoms";

export const getAllMeasureTypeEndPoint = "api/v1/get-measure-types";

// 3. Follow-up Schedules
// getFollowups   (FollowupScheduleSearchDropdown.js)
export const getFollowupsEndPoint = "api/v1/get-followups";

// API requiring QR Code For Fetching
// ------------------------------------------------------

// 1. PatientAlerts Screen (Monitoring Capsule)

// A. SinglePatientDetails,NavbarPatientDetails.js ,UDPatientPersonalDetails.js

export const getPatientDetailsEndPoint = "api/v1/getPatientDetails";

// B. Discharge Details

// a) (caseDetails.js)  ---> why this api Name
export const getExerciseDetailsEndPoint = "api/v1/getExerciseDetails";

// b) Get Monitoring Dates  (AppointmentCallBody.js)
export const getMonitoringDatesEndPoint = "api/v1/get-monitoring-dates";

// C. Alert Threshold (AlertThresholdConditions.js)
export const getAlertsEndPoint = "api/v1/getAlerts";

// D. Follow-up Schedules (FollowUpActions.js)
export const getFollowUpDetailsEndPoint = "api/v1/getFollowUpDetails";

// E. Medication Schedules (PatientMedicineDetails.js)  --> Pending
export const getPatientMedicineDetailsEndPoint =
  "api/v1/getPatientMedicineDetails";

// 2. Monitoring Status

// APIs requiring qr_code and duration  (/{qr_code}/{duration})

//a). Last RecordedVitals (MSLastRecordedVitals.js)

// {
//   "Temperature": "102.5",
//   "Fasting Blood Glucose": "82 mg/dl",
//   "Heart Rate / Pulse": "72 BPM",
//   "Oxygen Saturation": "95%",
//   "Blood Pressure": "120/80 mmHg"
// }
export const getLastVitalsEndPoint = "api/v1/get-last-vitals";

// b). SummaryOfSymptoms.js,  SummaryOfCompliance.js,SummaryOfEvents.js

export const getSymptomSummaryEndPoint = "api/v1/get-symptom-summary";
export const getComplianceSummaryEndPoint = "api/v1/get-compliance-summary";
export const getSEventsSummaryEndPoint = "api/v1/get-events-summary";

// ChatBody.js
export const getConversationEndPoint = "api/v1/get-conversation";

// MSSituationHighlights.js
// {
//   "label": "ALERT SITUATION",
//   "message": "The patient’s last response was at <b>15:14:58.821000</b> on text. Reported <b>fever</b> of <b>101</b>. Alert raised at <b>15:14:58.821000</b> no recorded action thus far."
// }
export const getCurrentSituation = "api/v1/get-current-situation";

// MedicationCompliance Chart
export const getMedicationChartEndPoint = "api/v1/get-medication-compliance";

// Fever Line Chart  LineChart.js
export const getFeverChartEndPoint = "api/v1/get-conversation-attributes";

// Pain Chart   PainChart.js
export const getPainChartEndPoint = "api/v1/get-conversation-attributes";

// Rash Chat RashChart.js
export const getRashChartEndPoint = "api/v1/get-conversation-attributes";

// SignsNSymptoms
export const getClinicalEntitiesEndPoint = "api/v1/get-clinical-entities";

//Get All vitals list
export const getAllVitals = "api/v1/get-all-vitals";

//Get hospital details
export const getHospitalDetails = "api/v1/get-hospital-details";

// PatientAlertsApproval.js
export const getApproveDischargeSummaryEndPoint =
  "api/v1/approve-discharge-summary";
export const getInitiatePhoneCallEndPoint = "api/v1/start-call";

// DischargeContextSearchDropdown.js
export const getDischargeContextEndPoint = "api/v1/getDischargeContexts";

// ChatSidebar.js
export const getDischargeSummaryEndPoint = "api/v1/dischargesummary";

export const getTotalPatientUnderMonitoringEndPoint =
  "api/v1/get-total-patients-under-monitoring";

export const getCovidPatientListEndPoint = "api/v1/get-covid-patients";

// OnCallRegistrationBody.js
export const getRegisteredNurseListEndPoint =
  "api/v1/nurse/get-registered-list";

// NavbarOnDuty.js
export const getNurseListNamesEndPoint = "api/v1/nurse/get-duty-list/1";

//PatientCallChat.js
export const getPatientContactDetails = "api/v1/get-patient-contact-details";

// MSNurseNotes.js
export const getNurseNotes = "api/v1/get-nursing-notes";

//MSAlertRaisedList.js
export const getAlertRaisedLists = "api/v1/get-alerts-list";

//MSAlertRaisedList.js
export const getPatientMessage = "api/v1/patient-messages";

// NavbarOnDuty.js (for update)
export const updateSelectedNurseEndPoint = "api/v1/nurse/update-active-nurse";

// JsonToTableComponent.js
export const getMongoDataEndPoint = "api/v1/get-mongo-data";

// PatientHospitalDetails.js
export const getOverRideMobileNumberEndPoint = "api/v1/override-data/overwrite";
export const getCancelOverRidingNumberEndPoint = "api/v1/override-data/cancel";

//MedicineSearchDropdown.js
export const getMedicinesListEndPoint = "api/v1/get-ref-medicines";

// ChatScheduler.js
export const getTaskEndPoint = "api/v1/get-task";

// GetTaskForDemo.js
export const getAllTaskNameEndPoint = "api/v1/get-all-task-name";
export const getTaskByNameDayWiseEndPoint = "api/v1/get-tasks-by-name-daywise";

// UDPatientHospitalDetails.js
export const getAllLanguagesEndPoint = "api/v1/get-all-languages";

// MFPDropdown.js
export const getAllInstructionsEndPoint = "api/v1/get-all-instructions";
export const getAllFrequenciesEndPoint = "api/v1/get-all-frequencies";

//UploadDischargeScreen.js
export const discardDischargeSummaryEndPoint =
  "api/v1/discard-discharge-summary";

// TableNamesDropdown.js
export const getTablesToBeTranslatedEndPoint =
  "api/v1/get-tables-to-be-translated";

// TranslateUtterance.js
export const getTableDataGroupedByLanguageEndPoint =
  "api/v1/get-data-grouped-by-language";

// TaskConversationTemplate.js (Admin Screen) not using "api/v1"
export const getAllTimeScheduleSequencesEndPoint =
  "task-conversation-template/get-all-time-schedule-sequences";
export const getAllTaskCategoriesEndPoint =
  "task-conversation-template/get-all-task-categories";

// MediaMapping.js (Admin Screen) not using "api/v1"
export const getAllSitesEndPoint = "site-media/get-all-sites";
export const getAllSiteMediaFileTypesEndPoint =
  "site-media/get-all-site-media-file-types";

// AllTasks.js (Admin Screen ) not using "api/v1"
export const getAllReminderModesEndPoint =
  "discharge-tasks/get-all-reminder-modes";
export const getAllDischargeContextsEndPoint =
  "discharge-tasks/get-all-discharge-contexts";
export const getAllTasksCategoryEndPoint =
  "discharge-tasks/get-all-task-categories";

// DischargeAlertVitals.js (Admin Screen) not using "api/v1"
export const getAllVitalsEndPoint = "discharge-alert-vitals/all-vitals";

//CareGiversCards.js /api/v1/get-care-givers-for-a-patient/{qr_code}
export const getCareGiversDetails = "api/v1/get-care-givers-for-a-patient";

// PicturesUploaded.js  api/v1/pictures-uploaded
export const getPicturesUploaded = "api/v1/pictures-uploaded";

// For creating  medicines

export const createMedicines = "api/v1/create-medication-task-after-edit";

// Get the last modified medicine date by nurse

export const getLastModifiedDetails =
  "api/v1/get-last-edited-datetime-medicines";
