import { RadioGroup } from "devextreme-react";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { RequiredRule } from "devextreme-react/data-grid";
import React, { Component } from "react";
import { Button as SaveButton } from "devextreme-react/button";
import List from "devextreme-react/list";
import axios from "axios";

export class AttributesQuestionaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utteranceType: this.props.type,
      botUtterances: this.props.bot_utterances,
      value: this.props.value,
      dayNumber: this.props.dayNumber,
    };

    this.addButton = {
      text: "+ Add",
      type: "default",
      onClick: (e) => {
        let temp = this.state.botUtterances;
        temp.push(this.textBoxRef.current.instance.option("value"));
        this.setState({
          botUtterances: temp,
        });
        this.listRef.current.instance.dataSource = this.state.botUtterances;
        this.listRef.current.instance.reload();
        this.textBoxRef.current.instance.option("value", "");
      },
    };

    this.utteranceTypeChanged = this.utteranceTypeChanged.bind(this);
    this.botUtterancesChanged = this.botUtterancesChanged.bind(this);
    this.saveConversationTemplate = this.saveConversationTemplate.bind(this);
    this.valueChanged = this.valueChanged.bind(this);
    this.dayNumberChanged = this.dayNumberChanged.bind(this);

    this.valueTextBoxRef = React.createRef();
    this.textBoxRef = React.createRef();
    this.listRef = React.createRef();
  }

  saveConversationTemplate() {
    let conversation_template_data = {
      day_number:this.state.dayNumber,
      type: this.state.utteranceType,
      bot_utterances: this.state.botUtterances,
      value:
        this.valueTextBoxRef.current === null
          ? ""
          : this.valueTextBoxRef.current.instance.option("value"),
      name: this.props.attribute_name,
    };
    let url = `${window["getConfig"].BACKEND_SERVICE_URL}/monitoring-questions/update_conversation_template/${this.props.monitoring_question_id}`;
    axios.put(url, conversation_template_data).catch((error) => {});
  }

  utteranceTypeChanged(e) {
    this.setState({ ...this.state, utteranceType: e.value });
  }
  dayNumberChanged(e) {
    console.log("DAY NUMBER CHANGED: ",e.value);
    console.log(this.state)
    this.setState({ ...this.state, dayNumber: e.value });
  }

  botUtterancesChanged(e) {}
  valueChanged(e) {}

  render() {
    return (
      <div
        style={{ border: "1px solid #dddddd", margin: "5px", padding: "5px" }}
      >
        <div style={{ display: "flex", alignItems: "center",
        justifyContent:"space-between",padding:"5px 10px"
      
      }}>
          <b style={{ textTransform: "capitalize", marginRight: "30px" }}>
            {this.props.attribute_name}
          </b>
         
          {/* <div
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "grey",
              display: "flex",
              alignItems: "center",
            }}
          >
            {" "}
            <span style={{ marginRight: "10px" }}> day_number: </span>{" "}
            <RadioGroup
              layout="horizontal"
              items={["1", "n"]}
              defaultValue={this.state.dayNumber}
              onValueChanged={this.dayNumberChanged}
            >
              <RequiredRule></RequiredRule>
            </RadioGroup>
          </div> */}
          <div style={{ fontSize: "15px", fontWeight: "bold", color: "grey",marginLeft:"44%"}}>
            {" "}
            day_number: {this.state.dayNumber}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            margin: "2px",
            padding: "2px",
          }}
        >
          <p style={{ marginRight: "5px" }}>Utterance Type:</p>
          <RadioGroup
            layout="horizontal"
            items={["DEFAULT", "ELICIT_FROM_PATIENT"]}
            // value={this.utteranceType}
            defaultValue={this.state.utteranceType}
            onValueChanged={this.utteranceTypeChanged}
          >
            <RequiredRule></RequiredRule>
          </RadioGroup>
        </div>

        <div style={{ margin: "2px", padding: "2px" }}>
          {this.state.utteranceType === "DEFAULT" && (
            <div>
              Value:
              <TextBox
                ref={this.valueTextBoxRef}
                defaultValue={this.state.value}
                placeholder="Please provide the default value for this attribute.."
                // valueChangeEvent="keyup"
                // onValueChanged={this.valueChanged}
              ></TextBox>
            </div>
          )}

          {this.state.utteranceType === "ELICIT_FROM_PATIENT" && (
            <div style={{ margin: "2px", padding: "2px" }}>
              Utterances:
              <List
                ref={this.listRef}
                dataSource={this.state.botUtterances}
                allowItemDeleting={true}
                // onContentReady={this.onValueChanged}
                noDataText="No bot utterance present.."
              />
              <TextBox
                placeholder="Add a new bot utterance to the list."
                ref={this.textBoxRef}
              >
                <TextBoxButton
                  name="add"
                  location="after"
                  options={this.addButton}
                />
              </TextBox>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <SaveButton
            width={120}
            text="Save"
            type="success"
            stylingMode="contained"
            onClick={this.saveConversationTemplate}
          />
        </div>
      </div>
    );
  }
}

export default AttributesQuestionaire;
