import {
  FETCH_COVIDPATIENTLIST_AGAIN,
  FETCH_ONCALLREGISTRATION_AGAIN,
  SHOW_REGISTRATION_ONCALLREGISTRATION,
  FETCH_NURSELIST_AGAIN
} from "../actions/actionTypes";

const initialState = {
  fetchCovidPatientList: false,
  fetchOnCallRegistrationList: false,
  showRegistrationOnCallRegistration:true,
  fetchNurseListOnCallRegistration:false
};

const covidPatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COVIDPATIENTLIST_AGAIN:
      return {
        ...state,
        fetchCovidPatientList: action.status,
      };
    case FETCH_ONCALLREGISTRATION_AGAIN:
      return {
        ...state,
        fetchOnCallRegistrationList: action.status,
      };
    case FETCH_NURSELIST_AGAIN:
      return {
        ...state,
        fetchNurseListOnCallRegistration: action.status,
      };
    case SHOW_REGISTRATION_ONCALLREGISTRATION:
      return {
        ...state,
        showRegistrationOnCallRegistration: action.status,
      };
    default:
      return state;
  }
};
export default covidPatientReducer;
