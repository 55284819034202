import React, { useEffect, useState, useRef } from "react";
import "./PatientDetailsBody.css";
import InputCard from "../../../../Card/InputCard/InputCard";
import ModalCustomButton from "../../../../Buttons/ModalCustomButton/ModalCustomButton";
import PatientDetailsButton from "../../../../Buttons/PatientDetailsButton/PatientDetailsButton";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../../../Store/actions/index";
import DateBox from "devextreme-react/date-box";
import NewDischargeHeader from "../../../../../screens/UploadDischargeScreen/NewDischargeHeader/NewDischargeHeader";
import { updatePatientDetailsEndPoint } from "../../../../../HighOrderFunctions/APIs/allPostAPI";
import { capitalizeFirstLetter } from "../../../../../HighOrderFunctions/shared/capitalizeFirstLetter";
import {
  updateObject,
  checkValidity,
} from "../../../../../HighOrderFunctions/shared/utility";
import monthChange from "../../../../../HighOrderFunctions/MonthChange";
import ButtonBorder from "../../../../Buttons/ButtonBorder/ButtonBorder";
import { useSelector } from "react-redux";
import { ageCalculator } from "../../../../../HighOrderFunctions/ageCalculator";
const PatientDetailsBody = (props) => {
  const token = useSelector((state) => state.auth.accessToken);
  const dayRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();
  const [formIsValid, setFormIsValid] = useState(false);
  const [currentDateValue, setCurrentDateValue] = useState(new Date());
  const [todaysDate, setTodaysDate] = useState(new Date());
  const [wardPatientForm, setWardPatientForm] = useState({
    fullName: {
      value: props.name ? props.name : "",
      valid: false,
      clicked: false,
      validations: {
        required: true,
        minLength: 3,
      },
    },
    age: {
      value: props.age ? props.age : "",
      valid: true,
      clicked: false,
      validations: {
        // required: true,
      },
    },

    dateOfBirth: {
      value: "",
      valid: props.patientVerificationIdentifier !== "DOB",
      clicked: false,
      validations: {
        // required: true,
        // dateValid: false,
      },
    },
    createdDateOfBirth: {
      value: {
        day: "",
        month: "",
        year: "",
      },
      valid: props.patientVerificationIdentifier !== "DOB",
      clicked: false,
      validations: {
        required: props.patientVerificationIdentifier == "DOB",
        dateValid: props.patientVerificationIdentifier == "DOB",
      },
    },

    hospitalId: {
      value: props.hospitalId ? props.hospitalId : "",
      valid: props.patientVerificationIdentifier !== "MRN",
      clicked: false,
      validations: {
        required: props.patientVerificationIdentifier == "MRN",
        maxLength: 16,
        isAlphaNumeric: props.patientVerificationIdentifier == "MRN",
      },
    },
    inPatientId: {
      value: props.inPatientId ? props.inPatientId : "",
      valid: props.patientVerificationIdentifier !== "IP_NUMBER",
      clicked: false,
      validations: {
        required: props.patientVerificationIdentifier == "IP_NUMBER",
        // maxLength: 16,
        isAlphaNumeric: props.patientVerificationIdentifier == "IP_NUMBER",
      },
    },
  });

  useEffect(() => {
    if (props.name || props.hospitalId || props.dob) {
      let tempWardPatientForm = { ...wardPatientForm };
      let tempWardPatientForm1 = {
        ...tempWardPatientForm,
        fullName: {
          ...tempWardPatientForm["fullName"],
          value: props.name,
          valid:
            props.name &&
            checkValidity(
              props.name,
              tempWardPatientForm["fullName"].validations
            ),
        },
        age: {
          ...tempWardPatientForm["age"],
          value: props.age,
          valid: true,
        },

        dateOfBirth: {
          ...tempWardPatientForm["dateOfBirth"],
          value: monthChange(props.dob, "covidRegistrationForm"),
          valid:
            props.patientVerificationIdentifier !== "DOB"
              ? true
              : monthChange(props.dob, "covidRegistrationForm") &&
                checkValidity(
                  props.dob,
                  tempWardPatientForm["dateOfBirth"].validations
                ),
        },
        createdDateOfBirth: {
          ...tempWardPatientForm["createdDateOfBirth"],
          value: {
            day: props.day,
            month: props.month,
            year: props.year,
          },
          valid:
            props.patientVerificationIdentifier !== "DOB"
              ? true
              : props.day &&
                props.month &&
                props.year &&
                checkValidity(
                  `${props.day}/${props.month}/${props.year}`,
                  tempWardPatientForm["createdDateOfBirth"].validations
                ),
        },
        hospitalId: {
          ...tempWardPatientForm["hospitalId"],
          value: props.hospitalId,
          valid:
            props.patientVerificationIdentifier !== "MRN"
              ? true
              : props.hospitalId &&
                checkValidity(
                  props.hospitalId,
                  tempWardPatientForm["hospitalId"].validations
                ),
        },
        inPatientId: {
          ...tempWardPatientForm["inPatientId"],
          value: props.inPatientId,
          valid: (props.patientVerificationIdentifier !== "IP_NUMBER") ? true :( props.inPatientId &&
          checkValidity(
            props.inPatientId,
            tempWardPatientForm["inPatientId"].validations
          ))
        },
      };
      if (
        props.day &&
        props.month &&
        props.year &&
        checkValidity(
          `${props.day}/${props.month}/${props.year}`,
          tempWardPatientForm["createdDateOfBirth"].validations
        )
      ) {
        tempWardPatientForm1 = {
          ...tempWardPatientForm1,
          age: {
            ...tempWardPatientForm1["age"],
            value: ageCalculator(`${props.year}/${props.month}/${props.day}`),
          },
        };
      }
      setWardPatientForm(tempWardPatientForm1);
      if (props.dob) {
        setCurrentDateValue(props.dob);
      }
      let tempFormIsValid = true;
      for (let inputIdentifier in tempWardPatientForm1) {
        tempFormIsValid =
          tempWardPatientForm1[inputIdentifier].valid && tempFormIsValid;
      }
      setFormIsValid(tempFormIsValid);
    }
  }, [props.qrCodeFromStore, props.hospitalId]);

  const history = useHistory();
  const inputChangedHandler = (value, inputIdentifier) => {
    let valuePassed = value;
    let valueForValidityCheck = value;
    let valueForAgeCalculator = `${wardPatientForm["createdDateOfBirth"]["value"].year}-${wardPatientForm["createdDateOfBirth"]["value"].month}-${wardPatientForm["createdDateOfBirth"]["value"].day}`;
    if (inputIdentifier == "fullName") {
      valuePassed = capitalizeFirstLetter(valuePassed);
    }

    if (inputIdentifier == "dateOfBirth") {
      setCurrentDateValue(value);
      valuePassed = monthChange(value, "covidRegistrationForm");
      valueForValidityCheck = monthChange(value, "covidRegistrationForm");
    }
    if (inputIdentifier == "day") {
      let updatedDay = value;
      let monthId = document.getElementById("patientDetailsBody__monthId");
      if (Number.isInteger(+value) && value.length <= 2) {
        updatedDay = value;
        if (value.length == 2) {
          monthId?.focus();
        }
      } else {
        updatedDay = wardPatientForm["createdDateOfBirth"]["value"].day;
      }

      valuePassed = {
        day: updatedDay,
        month: wardPatientForm["createdDateOfBirth"]["value"].month,
        year: wardPatientForm["createdDateOfBirth"]["value"].year,
      };
      valueForValidityCheck = `${updatedDay}/${wardPatientForm["createdDateOfBirth"]["value"].month}/${wardPatientForm["createdDateOfBirth"]["value"].year}`;
      inputIdentifier = "createdDateOfBirth";

      //  for age calculator (YYYY-MM-DD)
      valueForAgeCalculator = `${wardPatientForm["createdDateOfBirth"]["value"].year}-${wardPatientForm["createdDateOfBirth"]["value"].month}-${updatedDay}`;
    }
    if (inputIdentifier == "month") {
      let updatedMonth = value;
      let yearId = document.getElementById("patientDetailsBody__yearId");
      if (Number.isInteger(+value) && value.length <= 2) {
        updatedMonth = value;
        if (value.length == 2) {
          yearId?.focus();
        }
      } else {
        updatedMonth = wardPatientForm["createdDateOfBirth"]["value"].month;
      }
      valuePassed = {
        day: wardPatientForm["createdDateOfBirth"]["value"].day,
        month: updatedMonth,
        year: wardPatientForm["createdDateOfBirth"]["value"].year,
      };
      valueForValidityCheck = `${wardPatientForm["createdDateOfBirth"]["value"].day}/${updatedMonth}/${wardPatientForm["createdDateOfBirth"]["value"].year}`;
      inputIdentifier = "createdDateOfBirth";

      // for age calculator (YYYY-MM-DD)
      valueForAgeCalculator = `${wardPatientForm["createdDateOfBirth"]["value"].year}-${updatedMonth}-${wardPatientForm["createdDateOfBirth"]["value"].day}`;
    }
    if (inputIdentifier == "year") {
      let updatedYear = value;
      if (Number.isInteger(+value) && value.length <= 4) {
        updatedYear = value;
      } else {
        updatedYear = wardPatientForm["createdDateOfBirth"]["value"].year;
      }

      valuePassed = {
        day: wardPatientForm["createdDateOfBirth"]["value"].day,
        month: wardPatientForm["createdDateOfBirth"]["value"].month,
        year: updatedYear,
      };
      valueForValidityCheck = `${wardPatientForm["createdDateOfBirth"]["value"].day}/${wardPatientForm["createdDateOfBirth"]["value"].month}/${updatedYear}`;
      inputIdentifier = "createdDateOfBirth";

      // for age calculator (YYYY-MM-DD)
      valueForAgeCalculator = `${updatedYear}-${wardPatientForm["createdDateOfBirth"]["value"].month}-${wardPatientForm["createdDateOfBirth"]["value"].day}`;
    }

    if (inputIdentifier == "hospitalId") {
      valuePassed = valuePassed.toString().toUpperCase();
      valueForValidityCheck = valuePassed.toString().toUpperCase();
    }
    if (inputIdentifier == "inPatientId") {
      valuePassed = valuePassed.toString().toUpperCase();
      valueForValidityCheck = valuePassed.toString().toUpperCase();
    }
    const updatedFormElement = updateObject(wardPatientForm[inputIdentifier], {
      value: valuePassed,
      clicked: true,
      valid: checkValidity(
        valueForValidityCheck,
        wardPatientForm[inputIdentifier].validations
      ),
    });
    let updatedWardPatientForm = updateObject(wardPatientForm, {
      [inputIdentifier]: updatedFormElement,
    });

    if (inputIdentifier == "createdDateOfBirth") {
      if (
        checkValidity(
          valueForValidityCheck,
          wardPatientForm[inputIdentifier].validations
        )
      ) {
        updatedWardPatientForm = updateObject(wardPatientForm, {
          [inputIdentifier]: updatedFormElement,
          age: {
            ...wardPatientForm["age"],
            value: `${ageCalculator(valueForAgeCalculator)}`,
          },
        });
      }
    }
    // checking validation of ward Nurse Form
    let formIsValid = true;
    for (let inputIdentifier in updatedWardPatientForm) {
      formIsValid =
        updatedWardPatientForm[inputIdentifier].valid && formIsValid;
    }
    setWardPatientForm(updatedWardPatientForm);
    setFormIsValid(formIsValid);
  };
  // updating Patient Form Details...
  const updatePatientDetailsFunc = () => {
    let valueForValidityCheck = `${wardPatientForm["createdDateOfBirth"]["value"].day}/${wardPatientForm["createdDateOfBirth"]["value"].month}/${wardPatientForm["createdDateOfBirth"]["value"].year}`;
    let dobIsValid =  checkValidity(
      valueForValidityCheck,{
        required: true,
        dateValid:true ,
      }
    )
    let dobForSending =`${wardPatientForm["createdDateOfBirth"]["value"]["year"]}-${wardPatientForm["createdDateOfBirth"]["value"]["month"]}-${wardPatientForm["createdDateOfBirth"]["value"]["day"]}`;

    let requestData = {
      patient_name: wardPatientForm["fullName"].value,
      hospital_id: wardPatientForm["hospitalId"].value,
      age: `${wardPatientForm["age"].value=="NaN" ? props.tempAge : wardPatientForm["age"].value}`,
      qr_code: props.qrCodeFromStore,
      ip_number: wardPatientForm["inPatientId"].value,
      dob: `${dobIsValid ? dobForSending : props.tempDOB}`,
    };
    let requestOptions = {
      method: "POST",
      body: JSON.stringify(requestData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/${updatePatientDetailsEndPoint}`,
        requestOptions
      ).then((res) => {
        if (res.status === 200) {
          history.push(
            `/ds-upload/UDPatientHospitalDetails/${props.qrCodeFromStore}`
          );
        }
      });
    } catch (err) {}
  };

  const openPatientHospitalDetails = () => {
    updatePatientDetailsFunc();
  };

  const goBackToPersonalDetails = () => {
    if (props.qrCodeOnUploadCode === props.qrCodeFromStore) {
      props.setQrCodeOnUpload("", false);
    }

    history.replace("/ds-upload/fileUpload");
  };

  return (
    <div className="patientDetailsBody__container">
      <div className="patientDetailsBody__newDischargeHeader">
        <NewDischargeHeader />
      </div>
      <div className="patientDetailsBody__details">
        <div className="patientDetailsBody__patientHeaderText">
          Confirm Patient Details
        </div>
        <div className="patientDetailsBody__patientName">
          <div className="patientDetailsBody__patientNameText">Full name</div>
          <InputCard
            className={`patientDetailsBody__patientNameInput ${
              !wardPatientForm["fullName"].valid && ""
            }`}
            touchedNotValid={!wardPatientForm["fullName"].valid}
            value={wardPatientForm["fullName"].value}
            onChange={(val) => inputChangedHandler(val, "fullName")}
            width="100%"
            height="45px"
            placeHolder="Enter Patient Name"
            bgcolor="#FFFFFF"
          />
          {!wardPatientForm["fullName"].valid && (
            <div className="error-patientMessage">
              Unable to Identify Patient Name
            </div>
          )}
        </div>
        <div className="patientDetailsBody__patientAgeDOBContainer">
          <div className="patientDetailsBody__patientAge">
            <div className="patientDetailsBody__patientAgeText">Age</div>
            <div className="patientDetailsBody__patientAgeInput">
              <div className="patientDetailsBody__ageValue">
                {wardPatientForm["age"].value=="NaN" ? "" : `${wardPatientForm["age"].value} Y`} 
              </div>
            </div>
          </div>
          <div className="patientDetailsBody__patientDOB">
            <div className="patientDetailsBody__patientDOBText">
              Date of birth
            </div>
            <div className="patientDetailsBody__patientDOBInputs">
              <InputCard
                id="patientDetailsBody__dayId"
                className="patientDetailsBody__patientDOBInput"
                placeHolder="DD"
                width="60px"
                bgcolor="#FFFFFF"
                value={wardPatientForm["createdDateOfBirth"]["value"].day}
                onChange={(val) => inputChangedHandler(val, "day")}
                touchedNotValid={!wardPatientForm["createdDateOfBirth"].valid}
              />
              <InputCard
                id="patientDetailsBody__monthId"
                placeHolder="MM"
                className="patientDetailsBody__patientDOBInput"
                width="60px"
                bgcolor="#FFFFFF"
                value={wardPatientForm["createdDateOfBirth"]["value"].month}
                onChange={(val) => inputChangedHandler(val, "month")}
                touchedNotValid={!wardPatientForm["createdDateOfBirth"].valid}
              />
              <InputCard
                id="patientDetailsBody__yearId"
                placeHolder="YYYY"
                className="patientDetailsBody__patientDOBInput"
                width="60px"
                bgcolor="#FFFFFF"
                value={wardPatientForm["createdDateOfBirth"]["value"].year}
                onChange={(val) => inputChangedHandler(val, "year")}
                touchedNotValid={!wardPatientForm["createdDateOfBirth"].valid}
              />
            </div>

            <div className="info-message patientDetailsBody__infoMessage">
              Please ensure date of birth is correct
            </div>
          </div>
        </div>
        <div className="patientDetailsBody__hospitalInPatientId">
          <div className="patientDetailsBody__hospitalName">
            <div className="patientDetailsBody__hospitalIdText">
              Hospital ID
            </div>
            <InputCard
              className="patientDetailsBody__hospitalIdInput"
              placeHolder="Enter Hospital ID"
              width="100%"
              height="45px"
              bgcolor="#FFFFFF"
              value={wardPatientForm["hospitalId"].value}
              onChange={(val) => inputChangedHandler(val, "hospitalId")}
              touchedNotValid={!wardPatientForm["hospitalId"].valid}
            />
            {!wardPatientForm["hospitalId"].valid && (
              <div className="error-patientMessage">
                Unable to Identify Hospital ID
              </div>
            )}
          </div>
          <div className="patientDetailsBody__hospitalName">
            <div className="patientDetailsBody__hospitalIdText">
              Inpatient ID
            </div>
            <InputCard
              className="patientDetailsBody__hospitalIdInput"
              placeHolder="Enter Inpatient ID"
              width="100%"
              height="45px"
              bgcolor="#FFFFFF"
              value={wardPatientForm["inPatientId"].value}
              onChange={(val) => inputChangedHandler(val, "inPatientId")}
              touchedNotValid={!wardPatientForm["inPatientId"].valid}
            />
             {!wardPatientForm["inPatientId"].valid && (
              <div className="error-patientMessage">
                Unable to Identify InPatient ID
              </div>
            )}
          </div>
        </div>
        <div className="patientDetailsBody__border"></div>
        <div className="patientDetailsBody__buttons">
          <ButtonBorder text="Discard" clicked={goBackToPersonalDetails} />
          <ModalCustomButton
            name="Next"
            status={true}
            bgcolor="rgba(57, 129, 233, 0.68)"
            textColor="white"
            disabled={!formIsValid}
            clicked={() => {
              openPatientHospitalDetails();
            }}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
    qrCodeOnUploadCode:
      state.patient.qrCodeOnUpload && state.patient.qrCodeOnUpload["code"],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setQrCodeOnUpload: (qrCode, status) =>
      dispatch(actions.setQRCodeOnUpload(qrCode, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetailsBody);
