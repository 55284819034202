import React, { useEffect, useState } from "react";
import "./MSActionsMenu.css";
import CircularIconWTextBelow from "../../../CircularIcon/CircularIconWTextBelow/CircularIconWTextBelow";
import crossCircle from "../../../../AllIcons/crossCircle.png";
import nurseNoteMenuIcon from "../../../../AllIcons/nurseNoteMenuIcon.png";
import { connect, useStore } from "react-redux";
import CircularIcon from "../../../CircularIcon/CircularIcon";
import BackbuttonIcon from "../../../../AllIcons/BackButtonIcon.png";
import sendMessagetoPatientIcon from "../../../../AllIcons/sendpatientmessage.png";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import MSContinue from "./MSContinue/MSContinue";
import StopMonitoring from "./StopMonitoring/StopMonitoring";
const MSActionsMenu = (props) => {
  const [stopMonitoring, setStopMonitoring] = useState(false);
  const [continueProcess, setContinueProcess] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);

  // function to get back to Dashboard Screen 
  const getBackTodashboardPageFunc = () => {
    history.push("/dashboard");
  };

 // function to open and close stopMonitoring dialog box and Nurse Note
  const toggleStopMonitoring = () => {
    setStopMonitoring((prev) => !prev);
    setSelectedItem(0);
  };
 
  const toggleSendPatientMessage = () => {
    setContinueProcess((prev) => !prev);
    setSelectedItem(2);
  };

  const toggleContinueProcess = () => {
    setContinueProcess((prev) => !prev);
    setSelectedItem(1);
  };

  const history = useHistory();

  // function to open Monitoring Capsule Screen (Patient Alerts Screen ) in new Tab 
  const navigateToMonitoringCapsules = () => {
    window.open(
      "/#/patientAlerts/DischargeDetails/caseDetails/" + props.qrCodeFromStore
    );
  };

  return (
    <div className="msActionsMenu__container">
      {/* <Button
        onClick={navigateToMonitoringCapsules}
        variant="contained"
        backgroundColor="primary"
        style={{
          marginRight: "20px",
        }}
      >
        Monitoring Capsule
      </Button> */}

      <div className="msActionsMenu__stopMonitoring">
        <CircularIcon
          imgsrc={BackbuttonIcon}
          imgsize="25px"
          size={25}
          clicked={getBackTodashboardPageFunc}
          text="Go Back"
        />
      </div>
      {props.showCareGiver &&<div className="msActionsMenu__continue">
        <CircularIconWTextBelow
          size={25}
          imgsrc={sendMessagetoPatientIcon}
          text="Patient Message"
          clicked={toggleSendPatientMessage}
        />
        <div className="msActionsMenu__stopMonitoringCard">
          {continueProcess && selectedItem == 2 && (
            <MSContinue clicked={toggleContinueProcess} header="Message to patient" Identifier="DM_PATIENT" />
          )}
        </div>
      </div>}
      <div className="msActionsMenu__continue">
        <CircularIconWTextBelow
          size={25}
          imgsrc={nurseNoteMenuIcon}
          text="Nurse Note"
          clicked={toggleContinueProcess}
        />
        <div className="msActionsMenu__stopMonitoringCard">
          {continueProcess && selectedItem == 1 && (
            <MSContinue clicked={toggleContinueProcess}  header="Nursing Notes" Identifier="NURSING_NOTE"/>
          )}
        </div>
      </div>
      <div className="msActionsMenu__stopMonitoring">
        <CircularIconWTextBelow
          size={25}
          imgsrc={crossCircle}
          text="Stop monitoring"
          clicked={toggleStopMonitoring}
        />
        <div className="msActionsMenu__stopMonitoringCard">
          {stopMonitoring && selectedItem == 0 && (
            <StopMonitoring clicked={toggleStopMonitoring} />
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};


export default connect(mapStateToProps, null)(MSActionsMenu);
