import "./App.css";
import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ErrorPage from "./screens/ErrorPage/ErrorPage";
// import Navbar from "./Components/Navbar/Navbar";
import { useAuth0 } from "@auth0/auth0-react";
import "devextreme/dist/css/dx.light.css";
import "./dx.common.css";
import "./dx.light.css";
import HomeWithNavbar from "./Admin/HomeWithNavbar";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import DashboardWithNavbar from "./screens/Dashboard/DashboardWithNavbar";
import DischargeUnderMonitoringWithNavbar from "./screens/DischargeUnderMonitoring/DischargeUnderMonitoringWithNavbar";
import MonitoringStatusWithNavbar from "./screens/MonitoringStatus/MonitoringStatusWithNavbar";
import CovidRegistrationScreenWithNavbar from "./screens/CovidRegistrationScreen/CovidRegistrationScreenWithNavbar";
import PatientAlertsWithNavbar from "./screens/PatientAlertsScreen/PatientAlertsWithNavbar";
import OnCallRegistrationWithNavbar from "./screens/OnCallRegistration/OnCallRegistrationWithNavbar";
import UploadDischargeWithNavbar from "./screens/UploadDischargeScreen/UploadDischargeWithNavbar";
import PatientPanelWithNavbar from "./PatientPanel/PatientPanelWithNavbar";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken } from "./Store/actions/index";
import ToastNotification from "./Components/Notification/ToastNotification";
import HtmlToPdfDownload from "./screens/MonitoringStatus/MonitoringStatusHeader/MonitoringStatusChartDownload/MonitoringStatusCharts/HtmlToPdfDownload";

function App() {
  const notificationState = useSelector(
    (state) => state.dashboard.notification
  );
  const dispatch = useDispatch();

  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const getAuthToken = async () => {
    const getTokenAfterAuth = async () => {
      const token = await getAccessTokenSilently();
      return token;
    };
    const token = await getTokenAfterAuth();
    let tokenFound = token;
    console.log("TOKEN FOUND: ", tokenFound);
    dispatch(setAccessToken(token));
    return token;
  };
  let routes = null;
  if (isAuthenticated) {
    getAuthToken();

    /* Auth0 contains profile, set by the person defining profile, roles there  */
    if (user.profile === "pd-nurse") {
      routes = (
        <Switch>
          {/* containing component for uploading,parsing Discharge Summary, demographic details and contacts editing   */}
          <Route
            path="/ds-upload"
            render={() => (
              <ErrorBoundary>
                <UploadDischargeWithNavbar />
              </ErrorBoundary>
            )}
          />
          {/* Patient Alerts Screen also known as ( Monitoring Capsule Screen)  containing: 1. Discharge Details, 2. Alert Threshold, 3. Followup Schedules, 4. Medication Schedules and approval of parsed discharge summary of patient is done */}
          <Route
            path="/patientAlerts/:id"
            render={() => (
              <ErrorBoundary>
                <PatientAlertsWithNavbar />
              </ErrorBoundary>
            )}
          />
          <Route
            path="/download_patient_report"
            render={() => (
              <ErrorBoundary>
                <HtmlToPdfDownload />
              </ErrorBoundary>
            )}
          />
          {/* This is the landing page as Dashboard in pd-nurse login  containig 
         1. ApproveMonitoringSchedules: -For patient approving
         2. DischargePatientAlerts:list of patients, whose alerts has raised in all time during their monitoring period
         3.ReviewCallForDay: list of patients,to whom nurse will call today 
         4. PatientUnderMonitoring: patients, whose approval has been done */}
          <Route
            exact
            path="/dashboard"
            render={() => (
              <ErrorBoundary>
                <DashboardWithNavbar />
              </ErrorBoundary>
            )}
          />

          {/* Component containing list of all patients  */}
          <Route
            path="/dischargeUnderMonitoring"
            render={() => (
              <ErrorBoundary>
                <DischargeUnderMonitoringWithNavbar />
              </ErrorBoundary>
            )}
          />

          {/* Also known as Nurse Screen, Component containing Graphs, Chat, alerts, Nurse Notes etc. */}
          <Route
            path="/monitoringStatus/:id"
            render={() => <MonitoringStatusWithNavbar />}
          />
          {/* Component to register new nurse, these nurses will be using this project  */}
          <Route
            path="/onCallRegistration"
            render={() => (
              <ErrorBoundary>
                <OnCallRegistrationWithNavbar />
              </ErrorBoundary>
            )}
          />

          <Route
            exact
            path="/"
            render={() => (
              <ErrorBoundary>
                <DashboardWithNavbar />
              </ErrorBoundary>
            )}
          />

          <Redirect to="/dashboard" />
          {/* Error Page if wrong address in url  */}
          <Route component={ErrorPage}></Route>
        </Switch>
      );
    } else if (user.profile === "admin") {
      routes = (
        <Switch>
          <Route path="/admin/home" component={HomeWithNavbar} />
          <Redirect to="/admin/home"></Redirect>
          <Route component={ErrorPage}></Route>
        </Switch>
      );
    } else if (user.profile === "pd-patient") {
      //  When login  as pd-patient then this component will be shown
      // used For scheduling task , chat and to see mongo Data in table format.
      routes = (
        <Switch>
          <Route
            exact
            path="/pd-patient"
            render={() => (
              <ErrorBoundary>
                <PatientPanelWithNavbar />
              </ErrorBoundary>
            )}
          />
          <Route
            exact
            path="/"
            render={() => (
              <ErrorBoundary>
                <PatientPanelWithNavbar />
              </ErrorBoundary>
            )}
          />
          <Route component={ErrorPage}></Route>
        </Switch>
      );
    } else {
      routes = (
        <div>
          <div>USER PROFILE NOT SET</div>
        </div>
      );
    }
  }
  return (
    <div className="">
      {notificationState !== null && (
        <ToastNotification notification={notificationState} />
      )}
      {routes}
    </div>
  );
}

export default App;
