import React, { useState, useEffect } from "react";
import "./MedicationChart.css";
import ComplianceLegend from "./ComplianceLegend/ComplianceLegend";
import ComplianceSchedule from "./ComplianceSchedule/ComplianceSchedule";
import { getMedicationChartEndPoint } from "../../../../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { connect, useSelector } from "react-redux";

const MedicationComplianceChart = (props) => {
  const [compliances, setCompliances] = useState([]);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const fetchListOfCompliances = async () => {
    let fetchedValue = await getFetchFunction(
      `${getMedicationChartEndPoint}/${props.qrCodeFromStore}`,
      accessToken
    );
    let temp = [];
    if (fetchedValue.length > 0 && Array.isArray(fetchedValue) == true) {
      fetchedValue.map((item, index) => {
        let takenTimes = [];

        item.data.map((takenStatusItem) => {
          return takenTimes.push(takenStatusItem.task_status);
        });
        let data = {
          takenTimes: takenTimes,
          dayNumber: item.day_number,
          dayName: new Date(item.date).toString().split(" ")[0],
        };
        temp.push(data);
      });
    }
    setCompliances(temp);
  };
  let renderedData = null;
  renderedData =
    compliances &&
    compliances.map((item, index) => {
      return (
        <ComplianceSchedule
          key={`${item} - ${index}`}
          takenTimes={item.takenTimes}
          day_number={item.dayNumber}
          dayName={item.dayName}
          minWidth={props.minWidth && props.minWidth}
          capsuleWidth={props.capsuleWidth}
          capsuleHeight={props.capsuleHeight}
          complianceLegendDownload={props.complianceLegendDownload}
        />
      );
    });

  useEffect(() => {
    fetchListOfCompliances();
  }, []);

  return (
    <div
      className="medicationComplianceChart__container"
      style={{ height: props.height && props.height }}
    >
      <div className="medicationComplianceChart__header">
        <div
          className={
            props.complianceLegendDownload
              ? "medicationComplianceChart__headerText__download"
              : "medicationComplianceChart__headerText"
          }
        >
          Medication Compliance
        </div>
      </div>
      <div className="medicationComplianceChart__complianceLegends">
        <div className="medicationComplianceChart__complianceLegend">
          <ComplianceLegend
            bgColor="#A1FF9F"
            text="Completed"
            complianceLegendDownload={props.complianceLegendDownload}
          />
        </div>
        <div className="medicationComplianceChart__complianceLegend">
          <ComplianceLegend
            bgColor="#D1D1D1"
            text="Missed"
            complianceLegendDownload={props.complianceLegendDownload}
          />
        </div>
        <div className="medicationComplianceChart__complianceLegend">
          <ComplianceLegend
            border="1px solid #FFFFFF"
            text="Scheduled"
            bgColor="#2c2828"
            complianceLegendDownload={props.complianceLegendDownload}
          />
        </div>
      </div>
      <div className="medicationComplianceChart__body">{renderedData}</div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(MedicationComplianceChart);
