import React from 'react';
import './ButtonWImgLeft.css';
const ButtonWImgLeft = (props) => {
    return (
        <div className="buttonWImgLeft__container" onClick={props.clicked}>
            <div className="buttonWImgLeft__image">
                <img src={props.imgsrc} alt="image" />
            </div>
            <div className="buttonWImgLeft__text">{props.text}</div>
        </div>
    )
}

export default ButtonWImgLeft
