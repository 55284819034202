import React, { useState, useEffect } from "react";
import "./NavbarWardNurse.css";
import triangleBack from "../../../AllIcons/triangleBack.png";
import { useHistory } from "react-router";
import NavbarDashboardDischargeNavigateButtons from "../NavbarDashboardDischargeNavigateButtons/NavbarDashboardDischargeNavigateButtons";
import ButtonWBorder from "../../Buttons/ButtonWBorder/ButtonWBorder";
import * as actions from "../../../Store/actions/index";
import { connect } from "react-redux";
const NavbarWardNurse = (props) => {
  const [dischargeSummaryActive, setDischargeSummaryActive] = useState(
    +props.wardNurseRowSelected == -1
  );

  const history = useHistory();
  const navigateBackToDashboardScreen = () => {
    history.push("/dashboard");
  };
  const navigateToDischargeUnderMonitoring = () => {
    history.push("/dischargeUnderMonitoring");
  };
  const navigateToUploadScreen = () => {
    props.setWardNurseRowNumber(-1);
    history.push("/ds-upload/fileUpload");
    setDischargeSummaryActive(true);
  };
  useEffect(() => {
    if (props.wardNurseRowSelected !== -1) {
      setDischargeSummaryActive(  +props.wardNurseRowSelected == -1)
    }
  }, [props.wardNurseRowSelected]);
  return (
    <div className="navbarWardNurse__container">
      <NavbarDashboardDischargeNavigateButtons
        header="Patient Enrolment Screen"
        text1="Worklist"
        clicked1={navigateToDischargeUnderMonitoring}
        text2="Dashboard"
        clicked2={navigateBackToDashboardScreen}
        text3="New Discharge"
        clicked3={() => navigateToUploadScreen()}
        showButton3={true}
        dischargeActive={dischargeSummaryActive}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    wardNurseRowSelected: state.patient.wardNurseRowSelected,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setWardNurseRowNumber: (rowNumber) =>
      dispatch(actions.setWardNurseRowNumber(rowNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarWardNurse);
// export default NavbarWardNurse;
