
import dashboardReducer from './reducers/dashboardReducer';
import patientReducer from './reducers/PatientReducer';
import MonitoringStatusReducer from './reducers/MonitoringStatusReducer';
import MonitoringCapsuleReducer from './reducers/MonitoringCapsuleReducer';
import AdminReducer from './reducers/AdminReducer';
import { createStore, applyMiddleware, compose ,combineReducers} from 'redux';
import thunk from 'redux-thunk';
import covidPatientReducer from './reducers/covidPatientReducer';
import AuthReducer from './reducers/AuthReducer';
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
const rootReducer = combineReducers({
    dashboard: dashboardReducer,
    patient: patientReducer,
    monitoringStatus:MonitoringStatusReducer,
    monitoringCapsule:MonitoringCapsuleReducer,
    covidPatient:covidPatientReducer,
    auth:AuthReducer,
    admin:AdminReducer
});
    
function saveToLocalStorage(state){
    try{
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state',serializedState)
    } catch(e){
    }
}
function loadFromLocalStorage(){
    try{
        const serializedState  = localStorage.getItem('state')
        if(serializedState === null) return undefined
        return JSON.parse(serializedState);
    }catch(e){
        return undefined
    }
    
}
const persistedState = loadFromLocalStorage();
export const store = createStore(rootReducer, 
    persistedState,
    composeEnhancers(
    applyMiddleware(thunk)
    ));

    
store.subscribe(()=>saveToLocalStorage(store.getState()))