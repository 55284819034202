import React, { useState } from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { useDispatch } from "react-redux";
import { showNotificationWithMessage } from "../../Store/actions/DashboardAction";

// This component will be used to insert, update, delete discharge context from database's table (t_ref_discharge_context)
const DischargeContext = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  function sendRequest(url, method, data) {
    method = method || "GET";
    data = data || {};

    if (method === "GET") {
      return fetch(url, {
        method: method,
        credentials: "include",
      }).then((result) =>
        result.json().then((json) => {
          if (result.ok) {
            console.log(result);
            return json.data;
          }
          throw json.Message;
        })
      );
    }



    return fetch(url, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }).then((result) => {
      if (result.ok) {
        return result.text().then((text) => {
          let temp = JSON.parse(text);
          if (temp.error) {
            dispatch(
              showNotificationWithMessage({
                variant: "warning",
                message: temp.error,
              })
            );
          }
          return text && JSON.parse(text);
        });
      } else {
        return result.json().then((json) => {
          console.log("RESULT1: ", json);

          throw json.Message;
        });
      }
    });
  }

  // store handling data fetch, insert,update and delete  and being passed to DataGrid component
  const store = new CustomStore({
    key: "discharge_context_id",
    load: function (loadOptions) {
      if (loadOptions.take !==undefined ) {
        return fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-context?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
        )
          .then((response) => response.json())
          .then((data) => {
            return {
              data: data.data,
              totalCount: data.totalCount,
            };
          })
          .catch(() => {
            throw "Data Loading Error";
          });
      }
    },
    insert: (values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-context`,
        "POST",
        values
      ),
    update: (key, values) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-context/${key}`,
        "PUT",
        values
      ),
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/discharge-context/${key}`,
        "DELETE"
      ),
  });
  const onOptionChanged = (e) => {
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  return (
    <>
    <DataGrid
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnReordering={false}
      onOptionChanged={onOptionChanged}
      hoverStateEnabled={true}
      >
      <SearchPanel visible={true} text={searchText} />
      <Editing
        refreshMode="reshape"
        mode="popup"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
        />

      <Column dataField="speciality" dataType="string"></Column>
      <Column dataField="context" dataType="string"></Column>
      <Column
        width={100}
        dataField="number_of_days_to_monitor"
        dataType="number"
        caption="No. of days"
        ></Column>
      <Paging defaultPageSize={15} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[15, 25, 35]} />
    </DataGrid>
        </>
  );
};

export default DischargeContext;
