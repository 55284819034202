import React, { useState, useEffect,useRef } from "react";
import AppointmentCallMonitoringDate from "./AppointmentCallMonitoringDate/AppointmentCallMonitoringDate";
import { newDateFromNumberFunc } from "../../../../../../HighOrderFunctions/newDateFromNumberFunc";
import AppointmentNurseDateBottom from "./AppointmentNurseDate/AppointmentNurseDateBottom";
import { connect, useSelector } from "react-redux";
import { getMonitoringDatesEndPoint } from "../../../../../../HighOrderFunctions/APIs/allGetAPI";
import { updateNurseCallScheduleEndPoint } from "../../../../../../HighOrderFunctions/APIs/allPostAPI";
import { getFetchFunction } from "../../../../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { postFetchFunction } from "../../../../../../HighOrderFunctions/FetchMethod/postFetchFunction";
import arrowRight from "../../../../../../AllIcons/rightArrow.png";
import "./AppointmentCallBody.css";
const AppointmentCallBody = (props) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  
  let monitoringDate = newDateFromNumberFunc(props.date, props.duration);
  const [fetchedData, setFetchedData] = useState([]);
  const [scheduleCallData, setScheduleCallData] = useState([]);
  const [deleted, setDeleted] = useState(true);
  const [cardIndexNumber, setCardIndexNumber] = useState(-1);
  const referenceOfLeftItem = useRef();
  const referenceOfRightItem = useRef();
  // [
  //   {
  //     "id": 1,
  //     "day_number": 1,
  //     "schedule_date": "2021-02-03",
  //     "schedule_time": "08:00:00"
  //   },
  //   {
  //     "id": 2,
  //     "day_number": 2,
  //     "schedule_date": "2021-02-04",
  //     "schedule_time": "08:00:00"
  //   }]
  const fetchedDataFunc = async () => {
    let fetchedValue = await getFetchFunction(
      `${getMonitoringDatesEndPoint}/${props.qrCodeFromStore}`,
      accessToken
    );
    if (Array.isArray(fetchedValue) == true) {
      setFetchedData(fetchedValue);
      let tempSchedulesValue = fetchedValue.filter(
        (item) => item.is_nurse_call === true
      );
      setScheduleCallData(tempSchedulesValue);
    }
  };

  const deleteDate = async (id) => {
    try {
      fetch(
        `${window["getConfig"].BACKEND_SERVICE_URL}/${updateNurseCallScheduleEndPoint}/${id}/0`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken} `,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {});
    } catch (err) {}

    // /api​/v1​/update-nurse-call-schedule​/{id}​/{is_nurse_call}
    const dateData = fetchedData.filter((item) => item.id === id);
    let tempMonitoringDates = fetchedData;

    tempMonitoringDates[dateData[0].day_number - 1]["is_nurse_call"] = false;
    let tempScheduledCallDate = tempMonitoringDates.filter(
      (item) => item.is_nurse_call === true
    );
    setScheduleCallData((prev) => tempScheduledCallDate);
    setFetchedData((prev) => tempMonitoringDates);
  };
  const addDate = (date) => {
    const dateData = fetchedData.filter((item) => item.schedule_date === date);
    let tempMonitoringDates = fetchedData;
    if (dateData.length > 0) {
      tempMonitoringDates[dateData[0].day_number - 1]["is_nurse_call"] = true;
      let tempScheduledCallDate = tempMonitoringDates.filter(
        (item) => item.is_nurse_call === true
      );
      setScheduleCallData((prev) => tempScheduledCallDate);
      setFetchedData((prev) => tempMonitoringDates);
      try {
        fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/${updateNurseCallScheduleEndPoint}/${dateData[0]["id"]}/1`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken} `,
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {});
      } catch (err) {}
    } else {
      alert("date not allowed");
    }
  };
  const updateDate = (date, id) => {};
  useEffect(() => {
    fetchedDataFunc();
  }, []);
  let nurseCallDates = null;
  nurseCallDates = scheduleCallData.map((item, index) => {
    return (
      <div key={index}>
        <div className="appointmentCallBody__NurseDateBottom" key={item.id}>
          <AppointmentNurseDateBottom
            item={item}
            deleteDate={deleteDate}
            addCallDateToList={addDate}
            updateDate={updateDate}
            index={index}
            cardIndexNumber={cardIndexNumber}
            indexNumber={index}
            clicked={() => setCardIndexNumber(index)}
          />
        </div>
      </div>
    );
  });
  const scrollLeft = () =>{
    referenceOfLeftItem.current?.scrollIntoView({ behavior: "smooth" });

    // referenceOfLeftItem.current.scrollBy(50,0)
  }
  const scrollRight = () =>{
    // alert("scrollRight")
    referenceOfRightItem.current?.scrollIntoView({ behavior: "smooth" });


  }
  return (
    <div className="appointmentCallBody__container">
      <div className="appointmentCallBody__allDates">
        <div className="appointmentCallBody__dischargeDate">
          <AppointmentCallMonitoringDate
            discharge="Discharged"
            Date={props.date}
            day="1"
          />
          <div onClick={()=>scrollLeft()}>
            <img src={arrowRight} style={{transform:"rotate(180deg)",filter:"invert(30%)"}} className="appointmentCallBody__leftScroll" alt="" />
          </div>
        </div>
        <div className="appointmentCallBody__NurseDates">
          <div className="lineBetweenAppointmentNurseDate"></div>
          <div className="appointmentCallBody__nurseDate" >
            <div style={{ paddingLeft: "20px" }} ref={referenceOfLeftItem}></div>
            {nurseCallDates}
            <div style={{ paddingLeft: "20px" }} ref={referenceOfRightItem}></div>
          </div>
        </div>
        <div className="appointmentCallBody__monitoringDate">
          <AppointmentCallMonitoringDate
            discharge="Monitoring ends"
            Date={monitoringDate}
            day={props.duration}
          />
           <div onClick={()=>scrollRight()}>
            <img src={arrowRight} className="appointmentCallBody__rightScroll" alt=""  />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(AppointmentCallBody);
