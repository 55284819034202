import {
  FETCHDISCHARGEPATIENTALERT,
  FETCHAPPROVEMONITORINGSCHEDULES,
  FETCHREVIEWCALLFORDAY,
  FETCHPATIENTUNDERMONITORING,
  SET_INPUT,
  NOTIFICATION_WITH_MESSAGE,
} from "../actions/actionTypes";

const initialState = {
  reviewCallForDay: false,
  approveMonitoringSchedules: false,
  dischargePatientAlert: false,
  patientUnderMonitoring: false,
  searchedInput: "",
  notification: null,
};

const fileuploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHDISCHARGEPATIENTALERT:
      return {
        ...state,
        dischargePatientAlert: action.fetchingStatus,
      };
    case FETCHAPPROVEMONITORINGSCHEDULES:
      return {
        ...state,
        approveMonitoringSchedules: action.fetchingStatus,
      };
    case FETCHREVIEWCALLFORDAY:
      return {
        ...state,
        reviewCallForDay: action.fetchingStatus,
      };
    case FETCHPATIENTUNDERMONITORING:
      return {
        ...state,
        patientUnderMonitoring: action.fetchingStatus,
      };

    case SET_INPUT:
      return {
        ...state,
        searchedInput: action.searchedValue,
      };
    case NOTIFICATION_WITH_MESSAGE:
      return {
        ...state,
        notification: action.notification,
      };
    default:
      return state;
  }
};

export default fileuploadReducer;
