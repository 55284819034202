import React, { useState, useEffect, useRef } from "react";
import "devextreme/data/odata/store";
import axios from "axios";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
  Lookup,
  RequiredRule,
  FormItem,
  MasterDetail,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";
import "whatwg-fetch";
import { measureTypes, symptomAttributes } from "../../data";
import AttributesTagBoxComponent from "../AttributesTagBoxComponent";
import RemedialSuggestionComponent from "../RemedialSuggestionComponent";
import MonitoringAttributesConversationTemplate from "./MonitoringAttributesConversationTemplate";
import { useFetch } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { getAllLanguagesEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";

function sendRequest(url, method, data) {
  method = method || "GET";
  data = data || {};

  if (method === "GET") {
    return fetch(url, {
      method: method,
      credentials: "include",
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) return json.data;
        throw json.Message;
      })
    );
  }

  return fetch(url, {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((result) => {
    if (result.ok) {
      return result.text().then((text) => text && JSON.parse(text));
    } else {
      return result.json().then((json) => {
        throw json.Message;
      });
    }
  });
}

/*
Complete flow of Monitoring Questions: 
1. fetch all clinical entites
2. api MonitoringQuestions will contain:- utterance , day Number 






*/

const MonitoringQuestions = () => {
  const [allClinicalEntities, setAllClinicalEntities] = useState([]);
  const [searchText, setSearchText] = useState("");

  let { response: languageList, isLoading } = useFetch(
    `${getAllLanguagesEndPoint}`,
    false
  );
  const fetchAll = async () => {
    axios
      .all([
        axios.get(
          `${window["getConfig"].BACKEND_SERVICE_URL}/clinical-entity/all`
        ),
      ])
      .then((responseArray) => {
        setAllClinicalEntities(responseArray[0].data);
      });
  };

  const store = new CustomStore({
    key: "monitoring_question_id",
    load: function (loadOptions) {
      if (loadOptions.take !== undefined) {
        return fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/monitoring-questions?searchtext=${searchText}&limit=${loadOptions.take}&offset=${loadOptions.skip}`
        )
          .then((response) => response.json())
          .then((data) => {
            return {
              data: data.data,
              totalCount: data.totalCount,
            };
          })
          .catch((error) => {
            // alert(error);
            throw "Data Loading Error";
          });
      }
    },
    insert: (values) => {
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/monitoring-questions`,
        "POST",
        values
      );
    },
    update: (key, values) => {
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/monitoring-questions/${key}`,
        "PUT",
        values
      );
    },
    remove: (key) =>
      sendRequest(
        `${window["getConfig"].BACKEND_SERVICE_URL}/monitoring-questions/${key}`,
        "DELETE"
      ),
  });
  useEffect(() => {
    fetchAll();
  }, []);

  const cellTemplate = (container, options) => {
    var noBreakSpace = "\u00A0",
      text = (options.value || [])
        .map((element) => {
          return options.column.lookup.calculateCellValue(
            element.toLowerCase()
          );
        })
        .join(", ");

    container.textContent = text || noBreakSpace;
    // container.title = text;
  };

  const cellTemplate2 = (container, options) => {
    var noBreakSpace = "\u00A0",
      text = (options.value || []).join("\n")
    .map((element) => {
      return options.column.lookup.calculateCellValue(element);
    })
    .join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  };

  if (allClinicalEntities.length === 0) return null;
  const onOptionChanged = (e) => {
    console.log("EVent on option changed: ", e);
    if (e.fullName === "searchPanel.text") {
      setSearchText(e.value);
    }
  };
  return (
    <DataGrid
      // ref={monitoringQuestionRef}
      dataSource={store}
      showBorders={true}
      remoteOperations={true}
      showColumnLines={true}
      showRowLines={true}
      rowAlternationEnabled={true}
      allowColumnResizing={true}
      allowColumnReordering={false}
      onOptionChanged={onOptionChanged}

      // sorting={false}
    >
      <Editing
        refreshMode="reshape"
        mode="popup"
        allowAdding={true}
        allowDeleting={true}
        allowUpdating={true}
      />
      <SearchPanel visible={true} text={searchText} />

      <Column dataField="t_ref_clinical_entity_id" dataType="string">
        <Lookup
          dataSource={allClinicalEntities}
          displayExpr="entity_name"
          valueExpr="t_ref_clinical_entity_id"
        />
        <RequiredRule />

        <FormItem colSpan={2}></FormItem>
      </Column>
      <Column dataField="measure_type" dataType="string">
        <Lookup dataSource={measureTypes} displayExpr="name" valueExpr="name" />
        <RequiredRule />

        <FormItem colSpan={2}></FormItem>
      </Column>

      <Column
        dataField="attributes_day1"
        dataType="string"
        caption="attribute_day1"
        editCellComponent={AttributesTagBoxComponent}
        cellTemplate={cellTemplate}
      >
        <Lookup
          dataSource={symptomAttributes}
          displayExpr="name"
          valueExpr="name"
        />
        <FormItem colSpan={2}></FormItem>
      </Column>
      <Column
        dataField="attributes_dayn"
        dataType="string"
        editCellComponent={AttributesTagBoxComponent}
        cellTemplate={cellTemplate}
        caption="attribute_dayn"

      >
        <Lookup
          dataSource={symptomAttributes}
          displayExpr="name"
          valueExpr="name"
        />
        <FormItem colSpan={2}></FormItem>
      </Column>
      <Column dataField="ref_language_id" caption="Language" dataType="string">
        <Lookup
          dataSource={languageList}
          displayExpr="language_name"
          valueExpr="id"
        />
        <RequiredRule />

        <FormItem colSpan={2}></FormItem>
      </Column>
      {/* <Column
        dataField="remedial_suggestions"
        dataType="string"
        editCellComponent={RemedialSuggestionComponent}
      >
        <FormItem colSpan={2}></FormItem>
      </Column> */}
      <MasterDetail
        enabled={true}
        component={MonitoringAttributesConversationTemplate}
      />
      <Paging defaultPageSize={15} />
      <Pager showPageSizeSelector={true} allowedPageSizes={[10, 15, 20]} />
    </DataGrid>
  );
};

export default MonitoringQuestions;
