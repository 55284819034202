export const navigation = [
  { id: 1, text: "Discharge Context", route: "/admin/home/discharge-context" },
  { id: 2, text: "SNS Questions", route: "/admin/home/sns-questions" },
  { id: 3, text: "Sns-entity", route: "/admin/home/sns-entity" },
  { id: 4, text: "Clinical-entity", route: "/admin/home/clinical-entity" },
  {
    id: 5,
    text: "SNS2Clinical-Entity",
    route: "/admin/home/sns-to-clinical-entity",
  },
  {
    id: 6,
    text: "ClinicalEntity-Suggestions",
    route: "/admin/home/clinical-entity-suggestions",
  },
  { id: 7, text: "Monitoring Symptoms", route: "/admin/home/alerts" },
  { id: 9, text: "Medical inserts", route: "/admin/home/medical-inserts" },
  { id: 10, text: "Exercise", route: "/admin/home/exercise" },
  { id: 11, text: "Diet", route: "/admin/home/diet" },
  {
    id: 12,
    text: "ActionToSnSEntity",
    route: "/admin/home/action-to-sns-entity",
  },
  { id: 13, text: "Translate", route: "/admin/home/translate" },
  { id: 14, text: "Medicine Task Template", route: "/admin/home/taskConversation" },
  { id: 15, text: "Media Mapping", route: "/admin/home/mediaMapping" },
  {
    id: 16,
    text: "Discharge All Tasks",
    route: "/admin/home/dischargeAllTasks",
  },
  {
    id: 17,
    text: "Vitals",
    route: "/admin/home/vitals",
  },
  {
    id: 18,
    text: "Sns Vital Mapping",
    route: "/admin/home/snsVitalMapping",
  },
  {
    id: 19,
    text: "Discharge Alert Vitals",
    route: "/admin/home/dischargeAlertVitals",
  },
  {
    id: 20,
    text: "Monitoring Questions",
    route: "/admin/home/monitoring-questions",
  },
];

export const measureTypes = [
  {
    id: 1,
    name: "NUMBER_SCALE",
    relations: ["GREATER_THAN", "LESS_THAN", "EQUAL_TO"],
  },
  { id: 2, name: "BINARY", relations: ["EQUAL_TO"] },
  { id: 3, name: "THREE_POINT_SCALE", relations: ["EQUAL_TO"] },
];

export const relationTypes = [
  {
    id: 1,
    name: "GREATER_THAN",
  },
  { id: 2, name: "LESS_THAN" },
  {
    id: 3,
    name: "EQUAL_TO",
  },
  {
    id: 4,
    name: "IS_PRESENT",
  },
];

export const symptomAttributes = [
  { id: 1, name: "onset" },
  { id: 2, name: "measure" },
  { id: 3, name: "site" },
  { id: 4, name: "character" },
  { id: 5, name: "radiation" },
  { id: 6, name: "aleviating_factors" },
  { id: 7, name: "timing" },
  { id: 8, name: "excacerbating" },
  { id: 9, name: "severity" },
];
