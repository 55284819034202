import { ADMIN_DRAWER_BUTTON_ACTIVE } from "../actions/actionTypes";

const initialState ={
    adminActiveDrawerButton:-1
}

const adminReducer = (state=initialState,action)=>{
    switch(action.type){
        case ADMIN_DRAWER_BUTTON_ACTIVE:
      return {
        ...state,
        adminActiveDrawerButton: action.status,
      };
    default:
      return state;
  }
    
}
export default adminReducer;