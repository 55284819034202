import React from "react";
import "./UserResponse.css";
const UserResponse = (props) => {
  return (
    <div className="userResponse__container">
      <div className="userResponse__msgContainer">
        <div className="userResponse__TextContainer">
          <div className="userResponse__text">
           {props.utterance}
          </div>
        </div>
        <div className="userResponse__dateContainer">{props.user_contact}|{props.time} | {props.dayNumber} | ({props.date})</div>
      </div>
      <div className="userResponse__image">
        <img  src={props.userPicture} />
      </div>
    </div>
  );
};

export default UserResponse;
