import React, { useState, useEffect } from "react";
import "./CovidRegistrationNameBody.css";
import { useHistory } from "react-router-dom";
import { stringToDateFunc } from "../../../../HighOrderFunctions/stringToDateFunc";
import { connect } from "react-redux";
import * as actions from "../../../../Store/actions/index";
import { returnYearOnly } from "../../../../HighOrderFunctions/ageModifier";
import { dischargeSinceDate } from "../../../../HighOrderFunctions/dischargeSinceDate";
import monthChange from "../../../../HighOrderFunctions/MonthChange";
import mobilephoneS2 from "../../../../AllIcons/mobilephoneS2.png";
import CircularIcon from "../../../CircularIcon/CircularIcon";
import SendInvitation from "../../../sendInvitation/SendInvitation";
import {
  checkValidity,
  updateObject,
} from "../../../../HighOrderFunctions/shared/utility";
import { resendWhatsappMessageEndPoint } from "../../../../HighOrderFunctions/APIs/allPostAPI";
const CovidRegistrationNameBody = (props) => {
  const [showSendInvitation, setShowSendInvitation] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [covidPatientDetails, setCovidPatientDetails] = useState({
    contactNumber: {
      value: props.contact,
      valid: false,
      clicked: false,
      validations: {
        required: true,
        isNumeric: true,
        maxLength: 10,
        minLength: 10,
      },
    },
  });
  const history = useHistory();

  const toggleShowSendInvitation = () => {
    setShowSendInvitation((prev) => !prev);
    props.setSelectedSendInvitation(props.index);
  };
  const setContactNumberEntered = (value, inputIdentifier) => {
    let valuePassed = value;
    let valueForValidityCheck = value;
    if (Number.isInteger(+value) && value.length <= 10) {
      valuePassed = value;
    } else {
      valueForValidityCheck = covidPatientDetails["contactNumber"].value;
      valuePassed = covidPatientDetails["contactNumber"].value;
    }
    const updatedFormElement = updateObject(
      covidPatientDetails[inputIdentifier],
      {
        value: valuePassed,
        clicked: true,
        valid: checkValidity(
          valueForValidityCheck,
          covidPatientDetails[inputIdentifier].validations
        ),
      }
    );
    const updatedCovidPatientDetails = updateObject(covidPatientDetails, {
      [inputIdentifier]: updatedFormElement,
    });

    // checking validation of covid Form

    let formIsValid = true;
    for (let inputIdentifier in updatedCovidPatientDetails) {
      formIsValid =
        updatedCovidPatientDetails[inputIdentifier].valid && formIsValid;
    }
    setCovidPatientDetails(updatedCovidPatientDetails);
    setFormIsValid(formIsValid);

  };
  const resendMessageToPatient = () => {
    // var formdata = new FormData();
    // formdata.append(
    //   "To",
    //   `whatsapp:+91${covidPatientDetails["contactNumber"].value}`
    // );
    // formdata.append("id", props.whatsappId);

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    fetch(
      `${window["getConfig"].BACKEND_SERVICE_URL}/${resendWhatsappMessageEndPoint}/${props.QRCode}/${props.contact}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setShowSendInvitation(false);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div
      className={`crNameBody__container ${
        props.rowNumber % 2 !== 0 ? "crNameBody__containerodd" : ""
      }`}
    >
      <div className="crNameBody__patientName">
        {props.name}
        {props.gender && `-${props.gender.charAt(0)}`}
        <div className="crNameBody__patientQRCode">{props.dob}</div>
      </div>
      <div className="crNameBody__dischargeDate">
        <div className="crNameBody__dischargeDate__date">
          {props.registrationDate}
        </div>
        <div className="crNameBody__hospitalId">{props.hospitalId}</div>
      </div>
      <div className="crNameBody__status">{props.summaryStatus}</div>
      <div className="crNameBody__mobileNumber">
        <div className="crNameBody__contact">
          <div className="crNameBody__contactNumber">{props.contact}</div>
          <div className="crNameBody__contactMessageResent">
            {props.messageStatus}
          </div>
          <div className="crNameBody__contactLastMessageSentTime">
            {props.messageTime}
          </div>
        </div>
        <div className="crNameBody__messageResentIcon">
          <CircularIcon
            size="30px"
            imgsrc={mobilephoneS2}
            clicked={toggleShowSendInvitation}
          />
          {showSendInvitation && props.index == props.selectedSendInvitation && (
            <div className="crNameBody__sendInvitation">
              <SendInvitation
                toggleSendInvitation={toggleShowSendInvitation}
                contactNumber={covidPatientDetails["contactNumber"].value}
                setContactNumberEntered={(value) =>
                  setContactNumberEntered(value, "contactNumber")
                }
                touchedNotValid={
                  covidPatientDetails["contactNumber"].clicked &&
                  !covidPatientDetails["contactNumber"].valid
                }
                // disabled={!formIsValid}
                disabled={true}
                resendMessageToPatient={resendMessageToPatient}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setQRCodeToStoreFunc: (qrCode) => dispatch(actions.setQRCodeFunc(qrCode)),
  
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CovidRegistrationNameBody);

// key={index}
// name={item["patientName"]}
// QRCode={item["qrCode"]}
// hospitalID={item["hospitalId"]}
// contact={item["contact"]}
// age={item["age"]}
// gender={item["gender"]}
// dob={item["dob"]}
// registrationDate={item["registrationDate"]}
