import {SET_INPUT,NOTIFICATION_WITH_MESSAGE} from './actionTypes';

export const setInputFunc = (value)=>{
    return{
        type:SET_INPUT,
        searchedValue:value
    }
}
export const showNotificationWithMessage = (status) => {
    return {
      type: NOTIFICATION_WITH_MESSAGE,
      notification: status,
    };
  };
  