export function jsonModification(json) {
  if(json==null || json== undefined){
    return {}
  }
  let tempJson = {};
  console.log(json);
  Object.keys(json).map((elementKey, index) => {
    if (json[elementKey] == null) {
      tempJson[elementKey] = "null";
    }
    if (typeof json[elementKey] == "boolean") {
      if (json[elementKey] == true) {
        tempJson[elementKey] = "true";
      } else {
        tempJson[elementKey] = "false";
      }
    } else if (typeof json[elementKey] == "object") {
      tempJson[elementKey]=jsonModification(json[elementKey]);
    } else {
      tempJson[elementKey] = json[elementKey];
    }
  });
  return tempJson;
}
