import React, { useState, useEffect } from "react";
import "./SymptomIndexList.css";
import ChartIndexButton from "../../../Buttons/ChartIndexButton/ChartIndexButton";
import { connect } from "react-redux";
const SymptomIndexList = (props) => {
  
  let renderedData = null;

  renderedData = props.clinicalEntities.map((item, index) => {
    return (
      <div className="chartIndexingList__button" key={index}>
        <ChartIndexButton
          clicked={() => props.toggleSymptomTab(index)}
          name={item.name}
          // active
          active={props.toggleSymptomState === index ? true : false}
        />
      </div>
    );
  });

  return <div className="chartIndexingList__container">
    {renderedData}
    </div>;
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};
export default connect(mapStateToProps, null)(SymptomIndexList);
