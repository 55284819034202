import React, { useState, useEffect } from "react";
import { Button, Modal } from "@mui/material";
import { translateLanguageInTable } from "../../../HighOrderFunctions/APIs/allPostAPI";
import { getTableDataGroupedByLanguageEndPoint,getAllLanguagesEndPoint } from "../../../HighOrderFunctions/APIs/allGetAPI";
import BackDropLoader from "../../../Loader/BackDropLoader";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Editing,
  SearchPanel,
} from "devextreme-react/data-grid";
import "./TranslateUtterance.css";
import {
  updateObject,
  checkValidity,
} from "../../../HighOrderFunctions/shared/utility";
import TablesNamesDropdown from "../../../Components/screensComponent/SearchDropdown/TablesNamesDropdown";
import { getFetchFunction ,useFetch} from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import { useSelector } from "react-redux";
import { showNotificationWithMessage } from "../../../Store/actions/DashboardAction";
import { useDispatch } from "react-redux";
import ModalAdmin from "../../../modals/ModalAdmin/ModalTag";
import SearchDropdown from "../../../Components/screensComponent/SearchDropdown/SearchDropdown";


const TranslateUtterance = (props) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [dropdownDataForm, setDropdownDataForm] = useState({
    tableName: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        // required: true,
        isObject: true,
      },
    },
    language: {
      value: "",
      valid: false,
      clicked: false,
      validations: {
        // required: true,
        isObject: true,
      },
    },
  });
  let { response: languageList, isLoading:languageLoading } = useFetch(
    `${getAllLanguagesEndPoint}`,
    false
  );

  const inputChangedHandler = (value, inputIdentifier) => {
    console.log(value, inputIdentifier);
    let valuePassed = value;
    let valueForValidityCheck = value;

    if (inputIdentifier == "tableName") {
      valuePassed = value;
    }
    if (inputIdentifier == "language") {
      valuePassed = value;
    }

    const updatedFormElement = updateObject(dropdownDataForm[inputIdentifier], {
      value: valuePassed,
      clicked: true,
      valid: checkValidity(
        valueForValidityCheck,
        dropdownDataForm[inputIdentifier].validations
      ),
    });
    const updatedForm = updateObject(dropdownDataForm, {
      [inputIdentifier]: updatedFormElement,
    });

    // checking validation of covid Form

    let formIsValid = true;
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
    }
    setDropdownDataForm(updatedForm);
    setFormIsValid(formIsValid);
  };

   const filterLanguageToRemoveEnglish = (languageLists)=>{
    if(Array.isArray(languageLists) && languageLists.length > 0){
      let tempLanguageLists = [...languageLists];
      tempLanguageLists = tempLanguageLists.filter(item=>item.language_code !=="EN");
      return tempLanguageLists;
    }else {
      return languageLists;
    }
  }

  const translateLanguage = async (overwrite = false) => {
    function translateTableContent() {
      try {
        let requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken} `,
          },
        };
        setLoading(true);
        return fetch(
          `${window["getConfig"].BACKEND_SERVICE_URL}/${translateLanguageInTable}/${dropdownDataForm["tableName"].value["name"]}/${dropdownDataForm["language"].value["language_code"]}/${overwrite}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            // resolve("")
            setLoading(false);
            if (data.status == "202") {
              dispatch(
                showNotificationWithMessage({
                  variant: "warning",
                  message: data.error,
                })
              );
              setShowModal(true);

              return data;
            } else if (data.status === "201") {
              dispatch(
                showNotificationWithMessage({
                  variant: "info",
                  message: data.error,
                })
              );

              return data;
            } else if (data.status == "200") {
              dispatch(
                showNotificationWithMessage({
                  variant: "success",
                  message: data.result,
                })
              );
              return data;
            }
          });
      } catch (err) {
        dispatch(
          showNotificationWithMessage({
            variant: "error",
            message: "Error Occured!",
          })
        );
      }
    }
    return await translateTableContent();
  };

  const getTableDataAfterLanguageConversion = async () => {
    try {
      const tablesData = await getFetchFunction(
        `${getTableDataGroupedByLanguageEndPoint}/${dropdownDataForm["tableName"].value["name"]}/${dropdownDataForm["language"].value["language_code"]}`,
        accessToken
      );
      setTableData(tablesData);
      dispatch(
        showNotificationWithMessage({
          variant: "success",
          message: "API Fetch Successful!!!",
        })
      );
    } catch (err) {}
  };
  useEffect(() => {}, [tableData]);
  const takeActionOnOverWrite = (status) => {
    if (status === false) {
      setShowModal(false);
    } else {
      setShowModal(false);
      translateLanguage(true);
    }
  };


  return (
    <div className="translateUtterance__container">
      <div className="translateUtterance__header">
        <div className="translateUtterance__tableList">
          <div className="">Select Table</div>
          <TablesNamesDropdown
            width="380px"
            displayExpr="name"
            displayName="name"
            value={dropdownDataForm["tableName"].value}
            setValue={(value) => inputChangedHandler(value, "tableName")}
          />
        </div>
        <div className="translateUtterance__language">
          <div className="">Select Language</div>
          <SearchDropdown
            value={dropdownDataForm["language"].value}
            setValue={(value) => inputChangedHandler(value, "language")}
            placeholder="Select Language"
            dataList={filterLanguageToRemoveEnglish(languageList)}
            displayName="language_name"
            displayExpr="language_name"
            valueExpr="language_name"
          />
        </div>
        <div className="translateUtterance__translate">
          <Button
            className="layoutNavbar__button"
            variant="contained"
            color="success"
            disabled={!formIsValid || (formIsValid && loading)}
            onClick={() => translateLanguage(0)}
            style={{
              backgroundColor: "#F6F6F6",
              color: "black",
            }}
          >
            Translate
          </Button>
          {loading && <BackDropLoader showModal={loading}/>}
        </div>
      </div>
      {formIsValid && (
        <Button
          className="layoutNavbar__button"
          variant="contained"
          color="success"
          disabled={!formIsValid}
          onClick={getTableDataAfterLanguageConversion}
          style={{
            backgroundColor: "lightgreen",
            color: "black",
            margin: "10px 5px",
          }}
        >
          Show
        </Button>
      )}

      <div className="translateUtterance__body">
        <DataGrid
          // width={"50%"}
          dataSource={tableData}
          showBorders={true}
          remoteOperations={true}
          showColumnLines={true}
          showRowLines={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={false}
        >
          <Column dataField="id" dataType="string"></Column>
          <Column dataField="utterance" dataType="string"></Column>
        </DataGrid>
        {showModal && (
          <ModalAdmin
            modalHeader="Overwrite Existing Converted Language"
            modalMessage="Are you sure you want to translate Language in Given Table Forcefully?"
            showModal={true}
            setShowModal={setShowModal}
            takeAction={takeActionOnOverWrite}
          />
        )}
      </div>
    </div>
  );
};

export default TranslateUtterance;
