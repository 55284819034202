import React from "react";
import ModalCustomButton from "../../Components/Buttons/ModalCustomButton/ModalCustomButton";
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import crossIcon from '../../AllIcons/crossCircle.png';

import "./ModalDelete.css";

const ModalDelete = (props) => {
 
  return (
    <div className="modalDelete">
      <div className="modalDelete__deleteMedication">{props.messageHeading}</div>
     {props.showCrossIcon && <div className="modalDelete__crossIcon"
     onClick={props.closeModal}
     ><img src={crossIcon} /></div>}
      <div className="modalDelete__textToDeleteMedicine">
        {props.messageBody}
        {props.newRowElement && <div className="modalDelete__newRowElement">{props.newRowElement}</div>}
      </div>
      <div className="modalDelete__buttons">
        <div className="modalDelete__modalCustomButton">
          <ModalCustomButton
            key="1"
            status={false}
            name={props.cancel}
            bgcolor="white"
            // textColor="#E94444"
            textColor="rgba(57, 129, 233, 0.76)"
            clicked={props.cancelNurseDelete}
          />
        </div>
        <div className="modalDelete__modalCustomButton">
          <ModalCustomButton
          status={false}
            name={props.select}
            key="2"
            bgcolor="rgba(57, 129, 233, 0.68)"
            textColor="rgba(57, 129, 233, 0.76)"
            clicked={props.confirmNurseDelete}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state =>{
  return{
    qrCodeFromStore:state.patient.QRCode
  }
}
export default connect(mapStateToProps,null)(ModalDelete);
