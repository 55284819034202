import React, { useState, useEffect } from "react";
import "./MonitoringStatusHeader.css";
import MSActionsMenu from "../../../Components/screensComponent/MonitoringStatus/MSActionsMenu/MSActionsMenu";
import {
  getPatientContactDetails,
  getPatientDetailsEndPoint,
} from "../../../HighOrderFunctions/APIs/allGetAPI";
import { getFetchFunction } from "../../../HighOrderFunctions/FetchMethod/getFetchFunction";
import blackCallIcon from "../../../AllIcons/blackCallIcon.png";
import { connect, useSelector } from "react-redux";
import CareGiverMonitoringStatus from "../../../Components/screensComponent/CareGiverMonitoringStatus/CareGiverMonitoringStatus";
import MonitoringOtherTasks from "../../../Components/screensComponent/MonitoringStatus/MonitoringOtherTasks/MonitoringOtherTasks";
import MonitoringStatusChartDownload from "./MonitoringStatusChartDownload/MonitoringStatusChartDownload";
const MonitoringStatusHeader = (props) => {
  const [countryCode, setCountryCode] = useState("+91");

  const accessToken = useSelector((state) => state.auth.accessToken);
  const [fetchedData, setFetchedData] = useState({});
  const [showCareGiver, setShowCareGiver] = useState(false);

  // function to get Contact Number of Patient
  const fetchedDataFunc = async () => {
    let fetchedValue = await getFetchFunction(
      `${getPatientContactDetails}/${props.qrCodeFromStore}`,
      accessToken
    );
    setFetchedData(fetchedValue);
    let showCareGiverFetch = await getFetchFunction(
      `${getPatientDetailsEndPoint}/${props.qrCodeFromStore}`,
      accessToken
    );
    console.log("SHOW CARE GIVER FETCH: ", showCareGiverFetch);
    let tempShowCareGiver = showCareGiverFetch[0]?.is_care_at_home;
    setShowCareGiver(tempShowCareGiver && tempShowCareGiver);
  };
  useEffect(() => {
    fetchedDataFunc();
  }, []);

  console.log("fetchedData", fetchedData);
  return (
    <div className="monitoringStatusHeader__container">
      <div className="monitoringStatusHeader__heading">Patient Status</div>
      <div className="monitoringStatusheader__patientNumber">
        <div className="monitoringStatusHeader__patientNumberCallIcon">
          <img src={blackCallIcon} alt="blackCallIcon" height="15px" />
        </div>
        <div className="monitoringStatusHeader__patientNumberPrimaryContact">
          {countryCode} {fetchedData?.primary_contact}
        </div>
      </div>
      {showCareGiver && (
        <div className="monitoringStatusHeader__careGiverContact">
          <CareGiverMonitoringStatus
            countryCode={countryCode}
            setCountryCode={setCountryCode}
          />
        </div>
      )}
      <div className="monitoringStatusHeader__actionsMenu">
        <MSActionsMenu showCareGiver={showCareGiver} />
      </div>

      <div className="monitoringStatusHeader__otherTasks">
        <MonitoringStatusChartDownload />
        <MonitoringOtherTasks />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    qrCodeFromStore: state.patient.QRCode,
  };
};

export default connect(mapStateToProps, null)(MonitoringStatusHeader);
