import React, { Fragment, useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { PDFExport } from "@progress/kendo-react-pdf";
import { connect } from "react-redux";

import html2canvas from "html2canvas";
import * as jsPDF from "jspdf";

import Image from "./base64Image";

import MonitoringStatusCharts from "./MonitoringStatusCharts";
import PatientDetails from "./PatientDetails";
import ErrorBoundary from "../../../../../ErrorBoundary/ErrorBoundary";

const HtmlToPdfDownload = (props) => {
  const [patientData, setPatientData] = useState("");
  const [totalNumberGraph, setTotalNumberGraph] = useState(0);
  const [totalRenderedGraph, setTotalRenderedGraph] = useState([]);
  const [totalGraphShouldRender, setTotalGraphShouldRender] = useState(0);

  const pdfExportComponent = React.useRef(null);

  const pdfDownloadHandler = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      console.log("pdfExportComponent", pdfExportComponent);
    }
  };

  useEffect(() => {
    let windowCloseTimeOut = null;
    let pdfDownloadTimeOut = null;
    //Checking that total chart renderd
    if (
      Number(totalGraphShouldRender) ==
      Number(props.noOfVitalsChartRendered + props.noOfSymptomsChartRendered)
    ) {
      pdfDownloadTimeOut = setTimeout(() => {
        pdfDownloadHandler();
        windowCloseTimeOut = setTimeout(() => {
          //closing the window after 1.5 sec when we get the pdf downlaod option
          window.close();
        }, 3500);
      }, 1000);
    }

    return () => {
      clearTimeout(pdfDownloadTimeOut);
      clearTimeout(windowCloseTimeOut);
    };
  }, [props.noOfVitalsChartRendered, props.noOfSymptomsChartRendered]);

  return (
    <Fragment>
      <ErrorBoundary>
        <div className="example-config">
          <button
            className="k-button"
            onClick={pdfDownloadHandler}
            style={{ padding: "10px", marginBottom: "10px" }}
          >
            Export PDF
          </button>
        </div>
        <PDFExport
          paperSize="A4"
          margin="3mm"
          forcePageBreak=".page-break"
          ref={pdfExportComponent}
          fileName={patientData}
        >
          <MonitoringStatusCharts
            setPatientData={(val) => setPatientData(val)}
            setTotalGraphShouldRender={(val) => setTotalGraphShouldRender(val)}
            // setTotalRenderedGraph={setTotalRenderedGraph}
            // totalRenderedGraph={totalRenderedGraph}
          />
        </PDFExport>
      </ErrorBoundary>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    noOfVitalsChartRendered: state.monitoringStatus.noOfVitalsChart,
    noOfSymptomsChartRendered: state.monitoringStatus.noOfSymptomsChart,
  };
};
export default connect(mapStateToProps, null)(HtmlToPdfDownload);
