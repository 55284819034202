import React from "react";
// import styled from "styled-components";
import "./InputCard.css";
const InputCard = (props) => {
  function validate(validations) {
    props.setErrors &&
      props.setErrors(
        validations
          .map((errorsFor) => errorsFor(props.value))
          .filter((errorMsg) => errorMsg.length > 0)
      );
  }
  return (
    <div
      className={`inputCard ${
        props.touchedNotValid ? "inputTouchedNotValid" : ""
      } ${props.className} `}
    >
      <input
      id={props.id}
        disabled={props.disabled}
        className={`inputCard__input ${
          props.disabled && "inputCard__inputDisabled"
        }`}
        type={`${props.type ? props.type : "text"}`}
        value={props.value}
        placeholder={props.placeHolder}
        style={{
          width: props.width,
          height: props.height,
          // background: props.bgcolor,
          color:
            props.hospitalId &&
            (props.hospitalId === props.value
              ? "rgba(0, 0, 0, 0.8)"
              : "#FF7070"),
        }}
        required={props.hospitalId}
        onChange={(e) => props.onChange(e.target.value)}
        onBlur={() => {
          props.validations && validate(props.validations);
        }}
      />
      {props.errors && props.errors.length > 0 ? (
        <div className="has-error">{props.errors.join(",")}</div>
      ) : null}
      {props.contactTypeCheck ? (
        <div className="has-error">Please insert integer Only</div>
      ) : null}
      {props.hospitalIdCheck ? (
        <div className="has-error">
          There is an error in the data mentioned above
        </div>
      ) : null}
    </div>
  );
};

export default InputCard;
