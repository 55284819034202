import React, { useState, useEffect } from "react";
import CircularIcon from "../../../CircularIcon/CircularIcon";
import VengageLogo from "../../../../AllIcons/transparentVEngageLogo.png";
import { useAuth0 } from "@auth0/auth0-react";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { connect } from "react-redux";
import "./NavbarCovidScreen.css";
import NavbarCovidRegistration from "../../NavbarCovidRegistration/NavbarCovidRegistration";
const NavbarCovidScreen = (props) => {
  const { logout, user } = useAuth0();
  return (
    <div className="vEngageNavbar__container">
      {/* <div className="vEngageNavbar__Logo">
        <CircularIcon imgsrc={VengageLogo} imgsize="50px" size="60px" />
      </div> */}
      <div className="navBarNavigationApproval">
        <NavbarCovidRegistration />
      </div>
      <div className="vEnageNavbar__postDischargeNurseDetails">
        <div>
          <div className="vEnageNavbar__postDischargeNurse">
            <div className="vEnageNavbar__postDischargeText">
              <p>{user.name}</p>
            </div>
            <div className="vEnageNavbar__logout">
              <p
                onClick={() => {
                  localStorage.removeItem("state");
                  logout({ returnTo: window.location.origin });
                }}
                className="vEnageNavbar__logoutText"
              >
                <PowerSettingsNewIcon />
              </p>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showNavigationFromStore: state.patient.showNavigation,
  };
};

export default connect(mapStateToProps, null)(NavbarCovidScreen);
